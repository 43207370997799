import GroupwareSideBar from "./GroupwareSideBar";
import {
  groupWareWorksPath,
  groupWareExpensionWorksPath,
} from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom";
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState, useRef } from "react";
import util from "../../util/util";
import IframeResizer from "iframe-resizer-react";

const getAppPathExt = (pathObject) => {
  var src = config.gwUrl + "/";
  var tmp = "?";
  pathObject.map = (key, val) => {
    tmp += "&" + key + "=" + val;
  };
  return src + tmp;
};

const getAppPath = (section, command, doc_type, params) => {
  var src = config.gwUrl + "/";
  let strParam = "";
  if (section == "reservation") {
    switch (command) {
      case "vehicleReserve":
        src += "vehicle.do";
        break;
      case "insBusinessCardForm":
      case "lstBusinessCard":
        src += "rental.auth.do";
        break;
      case "conferenceReserve":
      default:
        src += "conference.do";
        break;
    }
  } else if (section == "extension") {
    switch (command) {
      case "lstCustomerContractor":
        src += "contractor.auth.do";
        break;
      default:
        src += section + ".auth.do";
        break;
    }
  } else if (section == "approval") {
    src += section + ".person.do";
  } else if (section == "vacationList") {
    src += "doc.auth.do";
  } else if (section == "expense") {
    src += "doc.auth.do";
  } else if (section == "docView") {
    let lstPart = params.get("lstPart");
    strParam += "&idx=" + params.get("idx");
    strParam += "&doc_knd=" + params.get("doc_knd");
    strParam += "&doc_status=" + params.get("doc_status");
    strParam += "&doc_status_name=" + params.get("doc_status_name");
    strParam += "&list_mode=" + params.get("list_mode");
    strParam += "&lstPart=" + params.get("lstPart");
    if (lstPart == "doc") {
      src += "doc.auth.do";
    } else if (lstPart == "expense") {
      src += "doc.auth.do";
    } else {
      src += "vacation.auth.do";
    }
  } else if (section == "command") {
    strParam += "&idx=" + params.get("idx");
    strParam += "&method=" + params.get("method");
    src += "command.information.do";
  } else if (section == "docList") {
    src += "doc.auth.do";
  } else {
    src += section + ".auth.do";
  }

  if (section == "vacationList") {
    return src + "?command=" + command + "&lstPart=" + doc_type;
  } else if (section == "board") {
    return src + "?command=" + command + "&boardno=" + doc_type;
  } else if (section == "docView" || section == "command") {
    return src + "?command=" + command + strParam;
  } else {
    switch (command) {
      case "approvalList":
        src += "?command=" + command + "&list_type=" + params.get("list_type");
        break;
      default:
        src +=
          "?command=" + command + "&doc_type=" + doc_type + "&siteFlag=biznavi";
        //src += "?command=" + command + "&doc_type=" + doc_type;
        break;
    }
    return src;
  }
};

export const GroupwareVacationWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [adminMenu, setAdminMenu] = useState([]);
  const iframeRef = useRef(null);
  const dummy = params.get("dummy");

  var isSection = !params.get("section") ? "doc" : params.get("section");
  var isCommand = !params.get("command") ? "writeForm" : params.get("command");
  var isDocType = !params.get("doc_type") ? "draft" : params.get("doc_type");
  var isApp = getAppPath(isSection, isCommand, isDocType, params);
  //var _isApp     = getAppPathExt({"section":isSection, "command":isCommand, "doc_type":isDocType, "lstPart", });
  let sideBarSection = "";
  //alert(isApp);
  if (
    isSection === "approval" ||
    isSection === "docView" ||
    isSection === "docList" ||
    isSection === "doc"
  ) {
    sideBarSection = "approval";
  } else {
    sideBarSection = isSection;
  }

  useEffect(() => {
    getAdminMenu();
  }, []);

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "",
      menuTitle: "관리자",
      isContentWrap: true,
      isOpen: false,
    };
    if (sideBarSection == "approval") {
      param.su_menu_cd = "100006";

      util.getGroupwareAdminMenu(setAdminMenu, param);
    } else if (sideBarSection == "vacation") {
      param.su_menu_cd = "100010";

      util.getGroupwareAdminMenu(setAdminMenu, param);
    }
  };

  console.log(123);
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar
        currentPath={groupWareWorksPath[sideBarSection]}
        adminMenu={adminMenu}
        sidebar={sideBarSection}
      />
      <div className="commute_wrapper">
        {/* <iframe
                    id="contentWrap"
                    src={isApp}
                    width="100%"
                    height="800px"
                    // onLoad={() => {
                    //   resizeIframe("contentWrap");
                    // }}
                    //onLoad={handleOnLoad}
                    ref={iframeRef}
                /> */}
        <IframeResizer
          id="contentWrap"
          src={isApp + `&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyScroll"
        />
      </div>
    </div>
  );
};

export default GroupwareVacationWorks;
