import React, { useEffect, useState } from "react";
import "./css/ResearchTechMyList.scss";
import CommonAPI from "../../../API/common";
import { Link } from "react-router-dom";
import Pagination from "../../common/Pagination";
import ResearchTechMySideBar from "./ResearchTechMySideBar";
import Loading from "../../loading/Loading";

function ResearchTechMyList() {
  const [curPage, setCurPage] = useState(1);
  const [tbody, setTbody] = useState([]);
  const [downloadCnt, setDownloadCnt] = useState(0);
  const [loading, setLoading] = useState(false);
  const count = 20;

  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    let params = {
      page: 1,
      count: count,
    };
    CommonAPI.getResearchDocumentList(params).then((res) => {
      let data = res.data;
      setTbody(data);
    });
  }, [curPage]);

  const download = (idx) => {
    setLoading(true);
    CommonAPI.downloadDocument(idx);
    getCompanyInfo();

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const getCompanyInfo = () => {
    CommonAPI.getCompanyInfo().then((res) => {
      const data = res.data;
      setDownloadCnt(data.research_download_cnt);
    });
  };

  const deleteResearch = (item) => {
    CommonAPI.deleteResearchDoc(item.idx).then((res) => {
      alert("삭제되었습니다.");
      window.location.reload();
    });
  };
  return (
    <div className="researchTechRequestList">
      <ResearchTechMySideBar />
      <div className="researchTechRequestList_content">
        <div className="header">
          <h2>
            선행 기술 정보 작성 리스트
            <br />
            <span>내가 작성한 선행 기술 정보를 수정하고 다운받아 보세요.</span>
          </h2>
          <div className="download_cnt">
            잔여 다운로드 횟수{" "}
            <span>{window.$Global.commaify(downloadCnt)}</span>회
          </div>
        </div>
        <table>
          <colgroup>
            <col width={45} />
            <col width={175} />
            <col width={430} />
            <col width={100} />
            <col width={60} />
            <col width={60} />
            <col width={60} />
          </colgroup>
          <thead>
            <tr>
              <th>순번</th>
              <th>분&emsp;류</th>
              <th>제&emsp;목</th>
              <th>작성 날짜</th>
              <th>수&emsp;정</th>
              <th>다운로드</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {tbody.items?.map((item, idx) => {
              return (
                <tr key={item.idx}>
                  <td>{tbody.total_count - idx}</td>
                  <td>
                    {item?.category1_name
                      ? item.category1_name + ">" + item.category2_name
                      : "키워드검색"}
                  </td>
                  <td>{item.title}</td>
                  <td>{window.$Global.convertDate(item.created_at)}</td>
                  <td>
                    <Link
                      to={`/researchTech/edit?doc_idx=${item.idx}`}
                      className="btn_load"
                    >
                      <button>수정</button>
                    </Link>
                  </td>
                  <td>
                    <button
                      className="icon_download_attached ir_txt"
                      onClick={() => download(item.idx)}
                      disabled={loading}
                    >
                      다운로드
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon_del_trashcan"
                      onClick={() => deleteResearch(item)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          curPage={curPage}
          lastNum={tbody.last}
          onClick={setCurPage}
        />
      </div>
      {loading && <Loading show={loading} />}
    </div>
  );
}

export default ResearchTechMyList;
