import React from 'react';

function RelativeFileRow({row}) {

    return (
        <tr className="row">
            <td>{row.file_name + "." + row.file_type}</td>
            <td><button className="icon_download" onClick={() => row.download(row.t_idx, row.idx, row.oa_idx || row.manage_company_idx, row.manage_company_idx)}/></td>
            <td>{window.$Global.convertDate(row.created_at)}</td>
        </tr>
    );
}

export default RelativeFileRow;
