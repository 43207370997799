import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ContentTableList from "../../common/ContentTableList";
import AdminAPI from "../../../API/admin";
import _ from "lodash";
import memberContext from "../../../store/Member";

function AdminMarketList() {
    const {idx} = useContext(memberContext);
    const isPartTimer = !Boolean(idx);
    const [resData, setResData] = useState({items: []});
    const [tbody, setTbody] = useState([]);

    const [searchDate, setSearchDate] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    const category = 5;
    const count = 10;
    const path = isPartTimer ? "/admin/AlbaMarket/posting" : "/admin/manage/market/posting";

    let payload = {
        count: count,
        page: 1,
        keyword: keyword,
        created_at: searchDate
    };

    useEffect(() => {
        if (isPartTimer) {
            AdminAPI.getAPI().defaults.headers['x-token'] = "a99ff1fa413d55e0f0bc0aab5be3c557:f0b397cd658ccfcebb873f88fd36b374ed90bcdf20739798fc4ed2ade1fee1804f277a966622657b52e864604c9adb6572e56388ee01c83bda4fb2b13b8cebb85a065aed51e3a8c6d662f219ccc9f8989d09557eb6c92a05198844aa455d7774163cacb8cbf54f41f1444e4a470b2fb1127e6cd51418062e46e90c2452bb9a065572135980fbf4936e741c81280a47eab3f98f893c90dc3014547e302c0203526942e8985692e56d2e1725a3456dcf9761112fbd302aae774dd74008bd429ca4dcbac32eb5c06a14d389de7e302e64763402778ecb030a781bf1cfa9b5bcf01491bd57e67855eaa63864fa7154ae6717413306339883ce2b9fa14f7a73ca0d2ec7bde2a26c7775912333596e0f70a5a8";
        }
        getList(payload);
        setCurPage(1);
    }, [searchDate]);

    useEffect(() => {
        drawTable();
    }, [resData]);

    const getList = (payload, content_type) => {
        AdminAPI.getMarketList(payload).then(res => {
            let result = res.data;

            if (content_type == "next") {
                result.items = resData.items.concat(result.items);
            }
            setResData(result);
        });
    };

    const drawTable = () => {
        setTbody(resData.items.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{resData.total_count - index}</td>
                    <td>
                        <Link to={`${path}?idx=${item.idx}&type=view&category=${category}`}>
                            <div className="content">
                                <img src={window.$Global.getCDN(item.img_key)} alt="이미지"/>
                                <div>
                                    <h2>{item.title}</h2>
                                </div>
                            </div>
                        </Link>
                    </td>
                    <td>{window.$Global.convertDate(item.created_at)}</td>
                    <td>
                        <button className="icon_badge_del" onClick={() => onClickPostDelete(index, item.idx)}/>
                    </td>
                </tr>
            );
        }));
        if (resData.page == resData.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    const nextList = () => {
        let local_payload = {
            ...payload,
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getList(local_payload, "next");
    };

    const onClickPostDelete = (depth, idx) => {
        AdminAPI.deleteMarket(idx).then(() => {
            let copy = _.cloneDeep(resData);
            copy.items.splice(depth, 1);
            setResData(copy);
            alert("삭제되었습니다");
        });
    }

    return (
        <div id="AdminService">
            <div className="btns" style={{display: "flex", columnGap: 10}}>
                <input type="text" style={{width: 350, border: "1px solid #d8d8d8"}} value={keyword} onKeyUp={e => e.key === 'Enter' && getList(payload)} onChange={e => setKeyword(e.target.value)} placeholder="검색어를 입력하고 엔터를 눌러주세요"/>
                <input type="date" onChange={e => setSearchDate(new Date(e.target.value).getTime())}/>
                <Link to={`${path}?category=${category}`} className="btn_post">글 작성하기</Link>
            </div>
            <ContentTableList addTheadList={["삭제"]} title={"시장 정보"} data={tbody} content={tbody} drawList={nextList} hasMore={hasMore}/>
        </div>
    )
}

export default AdminMarketList;