import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "./css/MyPageTab.scss";

function MyPageTab({tab, activeDepth1, activeDepth2}) {
	const history = useHistory();
	const [depth1, setDepth1] = useState(activeDepth1);
	const [depth2, setDepth2] = useState(activeDepth2);

	useEffect(() => {
		history.push(`?depth1=${depth1}&depth2=${depth2}`);
	}, [depth1, depth2]);

	const onchangeDepth1 = (idx) => {
		setDepth1(idx)
		setDepth2(0);
	}

	return (
		<div id="MyPageTab">
			<div className="myPage_title">{tab.title}</div>
			<ul className="tab_list">
				{
					tab.list.map((item, idx) => {
						const active = (depth1 == idx);
						return (
							<li key={idx} className={`tab_item ${active ? 'active' : ''}`}>
								<h2 className="tab_title" onClick={() => onchangeDepth1(idx)}><i className={active ? item.activeClassNameIcon : item.classNameIcon}/>{item.title}</h2>
								<ul className="child_tab_list">
									{
										item.child.map((item2, idx2) => <li key={idx2} className={`child_tab_item ${depth2 === idx2 ? 'active' : ''}`} onClick={() => setDepth2(idx2)}>{item2}</li>)
									}
								</ul>
							</li>
						)
					})
				}
			</ul>
		</div>
	)
}

export default MyPageTab;