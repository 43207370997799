import React, {useEffect, useState} from "react";
import "./css/TradeClassification.scss";
import {Link, useParams} from "react-router-dom";
import CommonAPI from "../../API/common";
import _ from "lodash";

function TradeClassification() {
    const {idx} = useParams();
    const [classList, setClassList] = useState([]);
    const [classData, setClassData] = useState([]);
    const [showList, setShowList] = useState([]);

    useEffect(() => {
        CommonAPI.getInfo(idx).then(res => {
            let data = res.data.class;
            let arr = [];

            for (let i = 0; i < data.length; i++) {
                let item = data[i];

                if ((arr.findIndex(item2 => item2 == item.class)) == -1) {
                    arr.push(item.class);
                }
            }

            let check_list = new Array(arr.length).fill(true);

            setShowList(check_list);
            setClassData(data);
            setClassList(arr.sort((a, b) => a - b));
        });
    }, []);

    const onClickShow = (idx) => {
        let copy = _.cloneDeep(showList);
        copy[idx] = !copy[idx];
        setShowList(copy);
    };

    return (
        <div id="TradeClassification">
            <div className="mo">
                <div className="header">
                    <Link to={`/trade/view/${idx}`} className="icon_more_arrow_left_black"/>
                    <h2 className="page_title">상표 거래 권리정보</h2>
                </div>
                <div className="list">
                    {
                        classList.map((item, idx) => {
                            return (
                                <div className="item">
                                    <div className="tab_title">
                                        <h2>{item}류</h2>
                                        <button className={`${showList[idx] ? 'icon_more_arrow_up' : 'icon_more_arrow_down_black'}`} onClick={() => onClickShow(idx)}/>
                                    </div>
                                    <div className={`tab_content ${showList[idx] ? 'active': ''}`}>
                                        {
                                            classData.map(item2 => {
                                                if (item2.class == item) {
                                                    return item2.product
                                                }
                                                return ''
                                            }).filter(item3 => item3.length > 0).join(',')
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default TradeClassification;