import React, { useEffect, useState } from "react";
import "./css/TaskNotifySetting.scss";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import TaskCondition from "./TaskCondition";
import CommonAPI from "../../API/common";
import PopupTaskManage from "./PopupTaskManage";
import _ from "lodash";

function TaskNotifySetting() {
  const [conditionToggle, setConditionToggle] = useState(null);
  const [keywordToggle, setKeywordToggle] = useState(null);
  const [popupShow, setPopupShow] = useState(false);
  const [searchType, setSearchType] = useState({});
  const [defaultSearchType, setDefaultSearchType] = useState({});
  const [keyword, setKeyword] = useState("");
  const [keywordList, setKeywordList] = useState({ items: [] });
  const [field, setField] = useState(0);
  const [last, setLast] = useState(1);
  const [curPage, setCurPage] = useState(1);
  const [count, setCount] = useState(10);
  const [keywordCheck, setKeywordCheck] = useState({
    title: true,
    purpose: false,
    content: false,
  });
  useEffect(() => {
    CommonAPI.getTaskField(0).then((res) => setField(res.data));
    CommonAPI.getFindxCondition().then((res) => {
      let data = res.data;
      setConditionToggle(data.use);

      if (data.use) {
        let copy = _.cloneDeep(data);
        let keys = Object.keys(copy);

        copy.select_start_at = copy.reception_min;
        copy.select_end_at = copy.reception_max;

        if (copy.annual_max == null && copy.annual_min == null) {
          copy.annual_max = 10;
          copy.annual_min = 0;
        }

        if (copy.export_max == null && copy.export_min == null) {
          copy.export_max = 10000000000;
          copy.export_min = 0;
        }

        if (copy.sales_max == null && copy.sales_min == null) {
          copy.sales_max = 10000000000;
          copy.sales_min = 0;
        }

        for (let idx in keys) {
          let key = keys[idx];
          let item = copy[key];

          if (item == null) {
            copy[key] = 1;
          }
        }
        setDefaultSearchType(copy);
      }
    });

    getFindxKeywordList();
  }, []);

  useEffect(() => {
    getFindxKeywordList();
  }, [count, curPage]);

  const getFindxKeywordList = () => {
    let params = {
      count: count,
      page: curPage,
    };
    CommonAPI.getFindxKeywordList(params).then((res) => {
      let data = res.data;
      setKeywordList(data);
      setKeywordToggle(Boolean(data.use));
      setLast(res.data.last);
    });
  };

  const onClickConditionToggle = (value) => {
    CommonAPI.updateFindxCondition({ use: Boolean(value) });
    setConditionToggle(value);

    if (value) {
      alert(
        "모든 조건에 전체를 체크하시면 모든 공고가 올라올떄마다 메일이 발송됩니다."
      );
    }
  };

  const onClickKeywordToggle = (value) => {
    CommonAPI.updateFindxKeywordCondition();
    setKeywordToggle(value);
  };

  const onClickSave = () => {
    updateFindxCondition(searchType);
    setPopupShow(true);
  };

  const updateFindxCondition = (payload) => {
    let copy = _.cloneDeep(payload);
    let keys = Object.keys(copy);

    for (let idx in keys) {
      let key = keys[idx];
      let item = copy[key];

      if (
        key == "annual_min" ||
        key == "annual_max" ||
        key == "export_min" ||
        key == "export_max" ||
        key == "sales_min" ||
        key == "sales_max"
      )
        continue;

      if (item == 1) {
        copy[key] = null;
      }
    }

    if (copy.annual_max == 10 && copy.annual_min == 0) {
      copy.annual_max = null;
      copy.annual_min = null;
    }

    if (copy.export_max == 10000000000 && copy.export_min == 0) {
      copy.export_max = null;
      copy.export_min = null;
    }

    if (copy.sales_max == 10000000000 && copy.sales_min == 0) {
      copy.sales_max = null;
      copy.sales_min = null;
    }

    if (copy.select_start_at) {
      copy.reception_min = copy.select_start_at;
      copy.deadline_min = copy.select_start_at;
    }

    if (copy.select_end_at) {
      copy.reception_max = copy.select_end_at;
      copy.deadline_max = copy.select_end_at;
    }

    copy.use = 1;

    // let condition = [copy.age, copy.annual_max, copy.annual_min, copy.c_q, copy.department_name, copy.export_max, copy.field, copy.industry, copy.region, copy.sales_max, copy.sales_min, copy.select_end_at, copy.select_start_at, copy.state, copy.support_target];
    // let result = true;
    // for (let i = 0; i < condition.length; i++) {
    //     if (condition[i] != null) {
    //         result = false;
    //     }
    // }
    // console.log(result);

    // if (result && window.confirm("모든 조건에 전체를 체크하시면 모든 공고가 올라올떄마다 메일이 발송됩니다.")) {
    CommonAPI.updateFindxCondition(copy);
    // }
  };

  const addKeyword = () => {
    let payload = {
      keyword: keyword,
      content: Number(keywordCheck.content),
      purpose: Number(keywordCheck.purpose),
      title: Number(keywordCheck.title),
    };
    CommonAPI.addFindxKeyword(payload).then(() => {
      setKeyword("");
      getFindxKeywordList();
    });
  };

  const onClickDeleteKeyword = (idx) => {
    CommonAPI.deleteFIndxKeyword(idx).then(() => getFindxKeywordList());
  };

  const handleCurPage = (type) => {
    if (type === "go" && !(curPage === last)) {
      setCurPage(curPage + 1);
    } else if (type === "back" && !(curPage === 1)) {
      setCurPage(curPage - 1);
    }
  };

  const handleClick = (e) => {
    setKeywordCheck({ ...keywordCheck, [e.target.name]: e.target.checked });
  };

  const updateKeywordCheck = (e, name, item) => {
    let payload = {
      ...(name === "title" && { title: e.target.checked }),
      ...(name === "purpose" && { purpose: e.target.checked }),
      ...(name === "content" && { content: e.target.checked }),
    };
    CommonAPI.updateFindxKeyword(item.idx, payload).then((res) => {
      getFindxKeywordList();
    });
  };

  return (
    <div id="TaskNotifySetting">
      <div className="header">
        <h2>맞춤 정부사업 알림</h2>
        <h5>
          맞춤 정부사업 알림받기를 활성화하면 관심 가는 공고의 키워드를 추가 할
          수 있습니다.
          <br />
          설정 후 저장 완료가 되면 선택한 관심 키워드의 공고가 등록될 때 메일로
          공고를 보내 알려드립니다.
        </h5>
      </div>
      <div className={`wrap_keyword ${keywordToggle ? "" : "disabled"}`}>
        <h2>키워드 등록하기</h2>
        <div className="keyword_input">
          <div className="keyword_input_top">
            <p>맞춤 정부 사업 알림받기 {keywordToggle ? "ON" : "OFF"}</p>
            <label className="toggle_btn">
              <input
                type="checkbox"
                defaultChecked={keywordToggle}
                onClick={() => onClickKeywordToggle(!keywordToggle)}
              />
              <span />
            </label>
            <input
              type="text"
              placeholder="키워드를 입력해 추가해주세요"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") addKeyword();
              }}
            />
            <button onClick={addKeyword}>
              <i className="icon_plus_white" />
              <span>키워드 등록하기</span>
            </button>
          </div>
          <div className="keyword_input_bottom">
            <p>분류 설정</p>
            <label>
              <input
                type="checkbox"
                checked={keywordCheck.title}
                name="title"
                onChange={handleClick}
              />
              <span>제목</span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={keywordCheck.purpose}
                name="purpose"
                onChange={handleClick}
              />
              <span>사업 목적</span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={keywordCheck.content}
                name="content"
                onChange={handleClick}
              />
              <span>사업 내용</span>
            </label>
          </div>
        </div>
        <div className="keyword_table">
          <div className="keyword_table_header">
            <p>등록한 키워드</p>
            <select onChange={(e) => setCount(e.target.value)}>
              <option value={10}>10개씩 보기</option>
              <option value={10}>20개씩 보기</option>
              <option value={10}>50개씩 보기</option>
            </select>
          </div>
          {Boolean(keywordList.items.length) && (
            <table>
              <colgroup>
                <col width={100} />
                <col width={600} />
                <col width={70} />
                <col width={70} />
                <col width={70} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th>순번.</th>
                  <th style={{ textAlign: "left" }}>등록한 키워드</th>
                  <th>제목</th>
                  <th>사업목적</th>
                  <th>사업내용</th>
                  <th>삭제하기</th>
                </tr>
              </thead>
              <tbody>
                {keywordList.items.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>No.{(curPage - 1) * count + (idx + 1)}</td>
                      <td style={{ textAlign: "left" }}>{item.keyword}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.title}
                          onChange={(e) => updateKeywordCheck(e, "title", item)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.purpose}
                          onChange={(e) =>
                            updateKeywordCheck(e, "purpose", item)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.content}
                          onChange={(e) =>
                            updateKeywordCheck(e, "content", item)
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="icon_del_small_with_bg_gray"
                          onClick={() => onClickDeleteKeyword(item.idx)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="keyword_pagination">
            <i
              className="icon_more_arrow_left_small_black"
              onClick={() => handleCurPage("back")}
            />
            <p>
              {curPage} / {last}
            </p>
            <i
              className="icon_more_arrow_right_small_black"
              onClick={() => handleCurPage("go")}
            />
          </div>
        </div>
      </div>

      {popupShow && <PopupTaskManage onClose={() => setPopupShow(false)} />}
    </div>
  );
}

export default TaskNotifySetting;
