import React, {useState, useEffect} from 'react';import CheckBox from "../common/CheckBox";
import "./css/PopupIPAutoAdd.scss";
import API from "../../util/api";
import _ from "lodash";

function PopupIPAutoAdd({company_code, onClickSelectIPAdd}) {
    const [allChecked, setAllChecked] = useState(false);

    const [patentList, setPatentList] = useState([]);
    const [tradeMarkList, setTradeMarkList] = useState([]);
    const [designList, setDesignList] = useState([]);

    const [checkedPatentList, setCheckedPatentList] = useState([]);
    const [checkedTradeMarkList, setCheckedTradeMarkList] = useState([]);
    const [checkedDesignList, setCheckedDesignList] = useState([]);

    const [isPatent, setIsPatent] = useState(true);
    const [isTradeMark, setIsTradeMark] = useState(false);
    const [isDesign, setIsDesign] = useState(false);

    const params = {
        company_code: company_code,
    };

    useEffect(() => {
        getPatentList();
        getTradeMarkList();
        getDesignList();
    }, []);

    useEffect(() => {
        setAllChecked(false);
    }, [isPatent, isTradeMark, isDesign]);

    const onClickPrev = () => {
        if (isTradeMark) {
            setIsPatent(true);
            setIsTradeMark(false);
        } else if (isDesign) {
            setIsTradeMark(true);
            setIsDesign(false);
        }
    };

    const onClickNextStep = () => {
        if (isPatent) {
            setIsPatent(false);
            setIsTradeMark(true);
        } else if (isTradeMark) {
            setIsTradeMark(false);
            setIsDesign(true);
        } else if (isDesign) {
            let patent = checkedPatentList.reduce((result, item, index) => {
                if (item) {
                    result.push(patentList[index]);
                }
                return result
            }, []);

            let tradeMark = checkedTradeMarkList.reduce((result, item, index) => {
                if (item) {
                    result.push(tradeMarkList[index]);
                }
                return result
            }, []);

            let design = checkedDesignList.reduce((result, item, index) => {
                if (item) {
                    result.push(tradeMarkList[index]);
                }
                return result
            }, []);

            let payload = {
                patent: patent,
                tradeMark: tradeMark,
                design: design
            }

            onClickSelectIPAdd(payload, company_code);
        }
    };

    const getPatentList = () => {
        API.get('/info/patent', {params: params}).then(res => {
            let data = res.data;

            setPatentList(data);
            if (!checkedPatentList.length) {
                setCheckedPatentList(new Array(data.length).fill(false));
            }
        });
    };

    const getTradeMarkList = () => {
        API.get('/info/trademark', {params: params}).then(res => {
            let data = res.data;

            setTradeMarkList(data);
            if (!checkedTradeMarkList.length) {
                setCheckedTradeMarkList(new Array(data.length).fill(false));
            }
        });
    };

    const getDesignList = () => {
        API.get('/info/design', {params: params}).then(res => {
            let data = res.data;
            setDesignList(data);
            if (!checkedDesignList.length) {
                setCheckedDesignList(new Array(data.length).fill(false));
            }
        });
    };

    const toggleCheckbox = (idx) => {
        let copy = [];

        if (isPatent) {
            copy =  _.cloneDeep(checkedPatentList);
        } else if (isTradeMark) {
            copy = _.cloneDeep(checkedTradeMarkList);
        } else if (isDesign) {
            copy = _.cloneDeep(checkedDesignList);
        }
        copy[idx] = !copy[idx];

        if (isPatent) {
            setCheckedPatentList(copy);
        } else if (isTradeMark) {
            setCheckedTradeMarkList(copy);
        } else if (isDesign) {
            setCheckedDesignList(copy);
        }
    };

    const onClickAllChecked = () => {
        setAllChecked(!allChecked);

        let checkedList = [];

        if (isPatent) {
            checkedList = checkedPatentList;
        } else if (isTradeMark) {
            checkedList = checkedTradeMarkList;
        } else if (isDesign) {
            checkedList = checkedDesignList;
        }
        checkedList = _.cloneDeep(checkedList);

        let value = checkedList.fill(!allChecked);

        if (isPatent) {
            setCheckedPatentList(value);
        } else if (isTradeMark) {
            setCheckedTradeMarkList(value);
        } else if (isDesign) {
            setCheckedDesignList(value);
        }
    };

    let tbody = [];

    if (isPatent) {
        if (patentList.length) {
            tbody =
                patentList.map((item, idx) => {
                    return (
                        <tr key={idx} className={checkedPatentList[idx] ? 'active' : ''}>
                            <td>
                                <CheckBox checked={checkedPatentList[idx] || false}
                                          onChangeChecked={() => toggleCheckbox(idx)}/>
                            </td>
                            <td>{item.invention_title || item.invention_title_en}</td>
                            <td>{item.status}</td>
                            <td>{window.$Global.convertDate(item.register_at)}</td>
                            <td>{item.apply_number}</td>
                            <td>{window.$Global.convertDate(item.apply_at)}</td>
                        </tr>
                    );
                });
        } else {
            tbody = window.$Global.notTd(6, '등록된 특허가 없습니다.');
        }
    } else if (isTradeMark) {
        if (tradeMarkList.length) {
            tbody =
                tradeMarkList.map((item, idx) => {
                    return (
                        <tr key={idx} className={checkedTradeMarkList[idx] ? 'active' : ''}>
                            <td>
                                <CheckBox checked={checkedTradeMarkList[idx] || false}
                                          onChangeChecked={() => toggleCheckbox(idx)}/>
                            </td>
                            <td>{item.title}</td>
                            <td>{item.state}</td>
                            <td>{window.$Global.convertDate(item.register_at)}</td>
                            <td>{item.apply_number}</td>
                            <td>{window.$Global.convertDate(item.apply_at)}</td>
                        </tr>
                    );
                });
        } else {
            tbody = window.$Global.notTd(6, '등록된 상표가 없습니다.');
        }
    } else if (isDesign) {
        if (designList.length) {
            tbody =
                designList.map((item, idx) => {
                    return (
                        <tr key={idx} className={checkedDesignList[idx] ? 'active' : ''}>
                            <td>
                                <CheckBox checked={checkedDesignList[idx] || false}
                                          onChangeChecked={() => toggleCheckbox(idx)}/>
                            </td>
                            <td>{item.article_name}</td>
                            <td>{item.status}</td>
                            <td>{item.register_number}</td>
                            <td>{item.apply_number}</td>
                        </tr>
                    );
                });
        } else {
            tbody = window.$Global.notTd(6, '등록된 디자인이 없습니다.');
        }
    }

    return (
        <div id="PopupIPAutoAdd">
            <div className="steps">
                <div className={`step one ${isPatent ? 'active' : ''}`}>
                    <div className="body">
                        <p>
                            <span>01</span>
                            기존 특허 자동 등록
                        </p>
                    </div>
                    <div className="tri"></div>
                </div>
                <div className={`step two ${isTradeMark ? 'active' : ''}`}>
                    <div className="body">
                        <p>
                            <span>02</span>
                            기존 상표 자동 등록
                        </p>
                    </div>
                    <div className="tri"></div>
                </div>
                <div className={`step three ${isDesign ? 'active' : ''}`}>
                    <div className="body">
                        <p>
                            <span>03</span>
                            기존 디자인 자동 등록
                        </p>
                    </div>
                </div>
            </div>

            <h2 className="title">우리회사 기존 {isPatent ? '특허' : isTradeMark ? '상표' : '디자인'} 선택</h2>
            <p className="desc">
                각 국 특허청으로부터 받은 기존 {isPatent ? '특허' : isTradeMark ? '상표' : '디자인'} 리스트 정보입니다.
                <span> 선택</span>하시면
                <span> 자동 입력</span>이 되며,
                <span> 일부 정보는 누락될 경우</span>가 있으니 특허사무소를 통하여
                <span> 크로스체크</span> 바랍니다.
            </p>

            <div className="table-box-wrap">
                <div id="scrollableDiv" className="wrap_list">
                    <table className="list">
                        <thead>
                            <tr>
                                <th>
                                    <CheckBox checked={allChecked} onChangeChecked={onClickAllChecked}/>
                                </th>
                                {
                                    isPatent &&
                                        <>
                                            <th>발명의 명칭</th>
                                            <th>등록상태</th>
                                            <th>등록일자</th>
                                            <th>출원번호</th>
                                            <th>출원일자</th>
                                        </>
                                }

                                {
                                    isTradeMark &&
                                        <>
                                            <th>상표 명칭</th>
                                            <th>등록상태</th>
                                            <th>등록일자</th>
                                            <th>출원번호</th>
                                            <th>출원일자</th>
                                        </>
                                }

                                {
                                    isDesign &&
                                        <>
                                            <th>디자인 명칭</th>
                                            <th>상태</th>
                                            <th>등록번호</th>
                                            <th>출원번호</th>
                                        </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="btns">
                {
                    !isPatent && <button className="btn_prev btn" onClick={onClickPrev}>이전</button>
                }
                <button className="btn_add btn" onClick={onClickNextStep}>
                    {
                        isDesign ? '완료' : '다음'
                    }
                </button>
            </div>
        </div>
    )
}

export default PopupIPAutoAdd;