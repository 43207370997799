import { Bar, Line } from "react-chartjs-2";
import "./css/CompetitorEmployeePop.scss";

/**
 * @param {String} type 팝업 타입(year:연도별 평균연봉 | month:월별 입/퇴사자 추이)
 * @param {Object} monthData 입퇴사현황 데이터
 * @param {Object} salaryData 평균급여 데이터
 * @param {Function} setIsEmpPopOpen 팝업 open/close
 * @returns
 */
const CompetitorEmployeePop = ({
  type,
  monthData,
  salaryData,
  setIsEmpPopOpen,
}) => {
  // 평균급여 차트 데이터
  const salaryChartData = {
    labels: salaryData?.avg ? salaryData.avg.map((item) => item.year) : "",
    datasets: [
      {
        label: "평균연봉(만원)",
        type: "line",
        data: salaryData?.avg
          ? salaryData.avg.map((item) => item.val / 10000 || 0)
          : "",
        fill: false,
        borderColor: "rgb(0, 120, 241)",
        backgroundColor: "rgba(0, 120, 241, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  // 월별 입/퇴사자 추이 - 입/퇴사 데이터
  const monthChartData = {
    labels: monthData?.month
      ? monthData.month.map((item) => item.year + "." + item.month)
      : "",
    datasets: [
      {
        label: "입사",
        data: monthData?.month
          ? monthData.month.map((item) => item.in || 0)
          : "",
        fill: false,
        backgroundColor: "rgba(0, 120, 241, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
      {
        label: "퇴사",
        data: monthData?.month
          ? monthData.month.map((item) => item.out || 0)
          : "",
        fill: false,
        backgroundColor: "rgba(160, 160, 160, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  // 월별 입/퇴사자 추이 - 총 인원 데이터
  const monthTotalChartData = {
    labels: monthData?.month
      ? monthData.month.map((item) => item.year + "." + item.month)
      : "",
    datasets: [
      {
        label: "총 인원(명)",
        type: "line",
        data: monthData?.month
          ? monthData.total.map((item) => item.val || 0)
          : "",
        fill: false,
        borderColor: "rgb(0, 120, 241)",
        backgroundColor: "rgba(0, 120, 241, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };
  const employeeChartOption = {
    responsive: false,
    plugins: {
      datalabels: {
        display: true,
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  return (
    <div id="CompetitorEmployeePop">
      <div className="tit">
        <strong>
          {type === "year" ? "연도별 평균연봉" : "월별 입/퇴사자 추이"}
        </strong>
      </div>
      <div className="chart_box">
        {type === "year" ? (
          Object.keys(salaryData).length === 0 ? (
            "데이터가 없습니다."
          ) : (
            <>
              <Line
                data={salaryChartData}
                options={employeeChartOption}
                width={1140}
                height={200}
              />
              {/* <p>문구</p> */}
            </>
          )
        ) : Object.keys(monthData).length === 0 ? (
          "데이터가 없습니다."
        ) : (
          <>
            <div className="total">
              <Line
                data={monthTotalChartData}
                options={employeeChartOption}
                width={1140}
                height={200}
              />
            </div>
            <Bar
              data={monthChartData}
              options={employeeChartOption}
              width={1140}
              height={200}
            />
            {/* <p>문구</p> */}
          </>
        )}
      </div>
      <button onClick={() => setIsEmpPopOpen((prev) => !prev)}>
        <i className="icon_exit_gray close"></i>
      </button>
    </div>
  );
};

export default CompetitorEmployeePop;
