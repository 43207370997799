import React from "react";
import "./css/MobileService.scss";
import {Link} from "react-router-dom";
import links from "../../const/links";

function MobileService() {
	return (
		<div id="MobileService">
			<div className="link_list">
				<Link to={links.marketInfo}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-market-info.svg').default} alt="아이콘"/>
					<p>시장 정보 보기</p>
				</Link>
				<Link to={links.docTrade}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-sample-market.svg').default} alt="아이콘"/>
					<p>제안서<br/>샘플 마켓</p>
				</Link>
				<Link to={links.matchingBiz}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-matchingbiz.svg').default} alt="아이콘"/>
					<p>매칭 비즈</p>
				</Link>
				<Link to={links.consortium}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-con.svg').default} alt="아이콘"/>
					<p>컨소시엄</p>
				</Link>
				<Link to={links.exposition}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-edu.svg').default} alt="아이콘"/>
					<p>교육·행사· 경진대회</p>
				</Link>
				<Link to={links.businessDescription}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-bussiness.svg').default} alt="아이콘"/>
					<p>사업설명회</p>
				</Link>
				<Link to={links.taskManageInterest}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-mail.svg').default} alt="아이콘"/>
					<p>관심 공고 알림</p>
				</Link>
				<Link to={links.govSupport}>
					<img className="icon_chat" src={require('../../assets/images/common/icon-gov.svg').default} alt="아이콘"/>
					<p>정부지원 정책알림</p>
				</Link>
			</div>
		</div>
	)
}

export default MobileService;