import React from 'react';

function NormalRow({rowClassName, title, titleClassName, txtType, content, contentClassName}) {
    let value = content;
    if (txtType == "number") {
        value = window.$Global.commaify(content);
    } else if (txtType == "date") {
        value = window.$Global.convertDate(content);
    }
    return (
        <div className={`row ${rowClassName || ''}`}>
            <h2 className={`title ${titleClassName || ''}`}>{title}</h2>
            <p className={contentClassName || ''}>{content ? value : "N/A"}</p>
        </div>
    );
};

export default NormalRow;
