import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContentDetail from "../common/ContentDetail";
import TutorialAPI from "../../API/tutorial";
import "./css/TutorialDetail.scss";

function TutorialDetail({history}) {
    const {idx} = useParams();
    const [postData, setPostData] = useState({});

    useEffect(() => {
        TutorialAPI.getInfo(idx).then(res => setPostData(res.data));
    }, []);

    const fileDownload = (file_idx) => {
        TutorialAPI.download(idx, file_idx);
    };

    return (
        <div id="TutorialDetail">
            <ContentDetail history={history}
                           path="/tutorial"
                           page_title="튜토리얼"
                           title={postData.title}
                           update_at={window.$Global.convertDate(postData.created_at)}
                           content={postData.content}
                           files={postData.file}
                           onClickDownload={fileDownload}
            />
        </div>
    )
}
export default TutorialDetail;
