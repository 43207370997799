import React, {useState, useEffect} from 'react';
import './css/CompanyChoice.scss';
import API from '../../util/api';
import CompanyItem from "./CompanyItem";
import BannerFindX from "../common/BannerFindX";

function CompanyChoice({history}) {

    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        API.get("/agent/company").then(res => setCompanyList(res.data));
    }, []);

    const onClickChoiceCompany = (idx) => {
        localStorage.setItem('manage_company_idx', idx);
        window.location.href = "/";
    };

    let tbody = companyList.map(item => <CompanyItem key={item.idx} title={item.name} onClick={() => onClickChoiceCompany(item.idx)}/>);

    return (
        <>
            <div className="pc">
                <BannerFindX history={history}/>
            </div>
            <div id="CompanyChoice">
                <div className="pc">
                    <h2 className="title">기업 선택하기</h2>
                    <p className="desc">
                        <i className="icon_badge_info_green"/>
                        아이피나우에 거래 기업이 가입되면 자동 등록이 됩니다. 버튼 클릭 시 각 기업의 지재권 등록 현황을 확인할 수 있습니다
                    </p>
                    <div className="list">
                        {tbody}
                    </div>
                </div>
                <div className="mo">
                    <div className="alert">
                        <i className="icon_alert_circle"/>
                        <p>PC로 이용해주시기 바랍니다.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyChoice;
