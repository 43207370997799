import React, { useCallback, useContext, useEffect, useState } from "react";
import SignupInputHead from "./SignupInputHead";
import "../css/SignupPerson.scss";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import config from "../../../const/config";
import SignupTermsPopup from "./SignupTermsPopup";
import MemberAPI from "../../../API/member";
import message from "../../../const/message";
import InfoAPI from "../../../API/info";
import KeywordListWithCompany from "../../common/KeywordListWithCompany";
import SignupPersonKakao from "./SignupPersonKakao";
import CommonContext from "../../../store/Common";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import SignupAlertPopup from "../SignupAlertPopup";
import SignupAlertAddSuccess from "../SignupAlertAddSuccess";
import PopupIPAutoAdd from "../PopupIPAutoAdd";

const { Kakao } = window;

function SignupCompany() {
  const history = useHistory();
  const { isMobile } = useContext(CommonContext);
  const [step, setStep] = useState(1);
  const [mouse, setMouse] = useState(false);
  const [showKeywordList, setShowKeywordList] = useState(false);
  const [keywordList, setKeywordList] = useState([]);
  const [mCheckCompanyCode, setMCheckCompanyCode] = useState(false);
  const [mCheckDetailInfo, setMCheckDetailInfo] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const [isKakao, setIsKakao] = useState(false);
  const [signInfo, setSignInfo] = useState({
    imp_uid: "",
    company: "",
    company_name_en: "",
    company_code: "",
    licence_number: "", // 사업자 등록번호
    corporate_number: "", // 법인번호
    unq_key: "",
    id: "",
    password: "",
    password_confirm: "",
    email: "",
    patent: [],
    tradeMark: [],
    design: [],
    product: [],
    manage_phone: "",
    phone: "",
    location: "", // 특허사무소
    // 투자자
    type_detail: 0,
    manager_name: "",
    company_phone: "",
    company_homepage: "",
    desc: "", // 투자자 회사 소개
    company_file: [],
    // 개인 가입자
    name: "",
    birth_at: "",
    personal_number: "", // 출원인 등록번호
  });
  const [checkList, setCheckList] = useState({
    checkId: false,
    checkEmail: false,
    checkPassword: null,
    checkRegPassword: null,
  });
  const [checkAll, setCheckAll] = useState(false);
  const [popupTerms, setPopupTerms] = useState(false);
  const [termsType, setTermsType] = useState(1);
  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertSuccessShow, setAlertSuccessShow] = useState(false);
  // 팝업
  const [popupShow, setPopupShow] = useState(false);
  useEffect(() => {
    const checkPhone = signInfo.phone?.length === 11;

    if (!isKakao) {
      let checked =
        signInfo.id &&
        checkList.checkId &&
        signInfo.password &&
        checkList.checkPassword &&
        checkList.checkRegPassword &&
        signInfo.name &&
        checkPhone &&
        signInfo.email &&
        checkList.checkEmail &&
        checkList.checkAgree;
      if (toggleCheck) {
        if (checked && signInfo.personal_number) {
          setCheckAll(true);
        }
      } else {
        if (checked) setCheckAll(true);
        else setCheckAll(false);
      }
    } else {
      if (
        checkPhone &&
        signInfo.email &&
        checkList.checkEmail &&
        checkList.checkAgree
      ) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    }
  }, [signInfo, checkList]);

  useEffect(() => {
    setCheckList({ ...checkList, checkId: false });
  }, [signInfo.id]);

  useEffect(() => {
    setCheckList({ ...checkList, checkEmail: false });
  }, [signInfo.email]);

  useEffect(() => {
    if (!signInfo.password) return;

    let copy = _.cloneDeep(checkList);

    if (signInfo.password === signInfo.password_confirm) {
      copy.checkPassword = true;
    } else {
      copy.checkPassword = false;
    }

    if (!signInfo.password.match(config.checkReg)) {
      copy.checkRegPassword = false;
    } else {
      copy.checkRegPassword = true;
    }
    setCheckList(copy);
  }, [signInfo.password, signInfo.password_confirm]);

  const onClickKakaoGo = () => {
    Kakao.Auth.login({
      throughTalk: false,
      success: function (res) {
        let payload = {
          access_token: res.access_token,
          imp_uid: signInfo.imp_uid,
          email: signInfo.email,
          phone: signInfo.phone,
        };
        setSignInfo({ ...signInfo, ...payload });
        setIsKakao(true);
      },
      fail: function (error) {
        alert(error);
      },
    });
  };

  const onClickCheckId = () => {
    if (!signInfo.id) {
      alert("아이디를 입력해주세요");
      return;
    }

    MemberAPI.idCheck(signInfo.id).then(() => {
      alert("사용가능한 아이디입니다");
      setCheckList({ ...checkList, checkId: true });
    });
  };

  const onClickCheckEmail = () => {
    if (!signInfo.email) {
      alert("이메일을 입력해주세요");
      return;
    }

    if (!signInfo.email.match(config.emailReg)) {
      alert(message.errorRegxEmail);
      return;
    }

    MemberAPI.emailCheck(signInfo.email).then(() => {
      alert("사용가능한 이메일입니다");
      setCheckList({ ...checkList, checkEmail: true });
    });
  };

  const onClickTermsShow = (type, e) => {
    e.preventDefault();
    setTermsType(type);
    setPopupTerms(true);
  };

  const onClickSignup = async () => {
    let payload = {
      ...signInfo,
      company_name: signInfo.company,
    };

    delete payload.patent;
    delete payload.tradeMark;
    delete payload.design;

    if (isKakao) {
      await MemberAPI.signupKakao(signInfo).then(() => {
        alert(
          "회원가입되었습니다\n카카오 로그인하기를 통해 서비스 사용 가능합니다"
        );
        history.push("/login");
      });
    } else {
      await MemberAPI.signupPersonal(payload).then(async (res) => {
        if (toggleCheck) {
          await insertIP(res);
        }
        alert(message.successSignup);
        history.push("/login");
      });
      return;
    }
  };

  const insertIP = async (res) => {
    let code = res.data.code;

    let object = {
      company_idx: res.data.company_idx,
      member_idx: res.data.member_idx,
      company_code: signInfo.company_code,
    };
    await setTimeout(() => {
      let patent_length = signInfo.patent.length;
      let td_length = signInfo.tradeMark.length;
      let design_length = signInfo.design.length;

      if (patent_length) {
        let payload = {
          ...object,
        };
        payload.patent_list = signInfo.patent.map((item) => item.idx);
        InfoAPI.insertPatent(payload)
          .then()
          .catch((err) => err);
      }
      if (td_length) {
        let payload = {
          ...object,
        };
        payload.td_list = signInfo.tradeMark.map((item) => item.idx);
        InfoAPI.insertTrademark(payload)
          .then()
          .catch((err) => err);
      }
      if (design_length) {
        let payload = {
          ...object,
        };
        payload.design_list = signInfo.design.map((item) => item.idx);
        InfoAPI.insertDesign(payload)
          .then()
          .catch((err) => err);
      }

      if (patent_length || td_length || design_length) {
        alert(
          "선택하신 IP를 등록되는데에는 일정시간이 소요됩니다\n새로고침 후 정상적으로 이용 가능합니다"
        );
      }
    }, 100);
  };
  const onChangeFindCompany = (value, key) => {
    setSignInfo({ ...signInfo, [key]: value });
    setMCheckCompanyCode(false);
    setAlertShow(false);
    setAlertSuccessShow(false);
    if (!value.replace(/(\s*)/g, "").length) {
      setKeywordList([]);
      return;
    }
    if (value.length < 2) return;
    debounceHandler(value, key, signInfo);
  };

  const debounceHandler = useCallback(
    _.debounce(async (value, key, signInfo) => {
      let data;
      if (key === "company" || key === "company_name") {
        data = await InfoAPI.getCompany(value, 0, 1).then((res) => res.data);
      } else {
        data = await InfoAPI.getCompany(value, 0, 0).then((res) => res.data);
      }

      if (!Boolean(data.length)) {
        setMCheckCompanyCode(true);
      }
      setKeywordList(
        <KeywordListWithCompany
          arr={data}
          signInfo={signInfo}
          onClickAutoInput={onClickAutoInput}
          onClickManualInput={onClickManualInput}
        />
      );
    }, 400),
    []
  );

  const onClickAutoInput = (item, isMobile, signInfo) => {
    if (item.signed_check == 1) {
      setShowKeywordList(false);

      if (isMobile) {
        // setErrorAlready({show: true, company: item.name, email: item.email});
        return;
      } else {
        alert(
          "위 기업은 이미 가입되어 있습니다\n가입 정보를 확인하고 싶으시면 아래 정보로 연락부탁드립니다.\n대표전화: 1551-8442"
        );
        return;
      }
    } else {
      if (!isMobile) {
        setAlertShow(true);
      }
      setSignInfo({
        ...signInfo,
        name: item.name,
        company_code: item.company_code,
        company_name: item.name || "",
        company_name_en: item.name_en || "",
        licence_number: item.license_number || "",
        corporate_number: item.corporate_number || "",
        location: item.location || "",
        unq_key: item.unq_key,
        personal_number: item.company_code,
      });
      setKeywordList([]);
      setMCheckCompanyCode(true);
    }
  };
  const onClickSearchPopupShow = () => {
    setAlertShow(false);
    setPopupShow(true);
  };
  const onClickSelectIPAdd = (json, code) => {
    setSignInfo({ ...signInfo, ...json, company_code: code });
    setPopupShow(false);
    setAlertSuccessShow(true);
  };
  const onClickManualInput = () => {
    setKeywordList([]);
    setMCheckCompanyCode(true);
  };

  const onBlur = () => {
    if (!mouse) {
      setShowKeywordList(false);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  let signTag = [];

  switch (step) {
    case 1:
      signTag = (
        <div className="step">
          <h2 className="step_title">기업 등록</h2>
          <div className="field">
            <p className="f_title">법인명 (단체명)</p>
            <div
              className="wrap_input search"
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <input
                type="text"
                value={signInfo.company_name}
                placeholder="검색하세요"
                onChange={(e) =>
                  onChangeFindCompany(e.target.value, "company_name")
                }
                onFocus={() => setShowKeywordList(true)}
                onBlur={onBlur}
                autoFocus
              />
              <button
                className="icon_m_search"
                onClick={() =>
                  onChangeFindCompany(signInfo.company_name, "company_name")
                }
              />
              {showKeywordList && keywordList}
            </div>
          </div>
          <button
            className={`btn_join ${mCheckCompanyCode ? "" : "btn_disabled"}`}
            onClick={nextStep}
          >
            다음
          </button>
        </div>
      );
      break;
    case 2:
      signTag = (
        <div className="step">
          <h2 className="step_title">상세 정보 입력</h2>
          <div className="field">
            <p className="f_title">법인명 (단체명)*</p>
            <p className="required">*필수입력항목</p>
            <div
              className="wrap_input"
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <p>{signInfo.company_name}</p>
            </div>
          </div>
          <div className="field">
            <p className="f_title">영문 법인명</p>
            <div className="wrap_input">
              <input
                type="text"
                value={signInfo.company_name_en}
                onChange={(e) =>
                  setSignInfo({ ...signInfo, company_name_en: e.target.value })
                }
              />
            </div>
          </div>
          <div className="field">
            <p className="f_title">사업자 등록번호*</p>
            <div className="wrap_input">
              <input
                type="text"
                placeholder="사업자 등록번호를 입력해주세요"
                value={signInfo.licence_number}
                onChange={(e) =>
                  setSignInfo({ ...signInfo, licence_number: e.target.value })
                }
              />
            </div>
          </div>
          <div className="field">
            <p className="f_title">법인번호*</p>
            <div className="wrap_input">
              <input
                type="text"
                placeholder="법인번호를 입력해주세요"
                value={signInfo.corporate_number}
                onChange={(e) =>
                  setSignInfo({ ...signInfo, corporate_number: e.target.value })
                }
              />
            </div>
          </div>
          <p className="notify">
            법인 번호, 사업자 등록번호 중 1개는 필수 기입해 주세요.
          </p>
          <button
            className={`btn_join  ${mCheckDetailInfo ? "" : "btn_disabled"}`}
            onClick={nextStep}
          >
            다음
          </button>
        </div>
      );
      break;
  }

  return (
    <div id="SignupPerson">
      {!isKakao ? (
        <>
          <SignupInputHead title="개인 회원가입" />
          <button className="btn_kakao_signup" onClick={onClickKakaoGo}>
            <i className="icon_kakaotalk" />
            <p>카카오톡 간편 가입하기</p>
          </button>
          <div className="form">
            <div className="left_content">
              <div className="wrap_input">
                <label htmlFor="user_id" className="require">
                  아이디
                </label>
                <div className="input_area">
                  <input
                    type="text"
                    id="user_id"
                    className="has_btn"
                    placeholder="아이디"
                    onChange={(e) =>
                      setSignInfo({ ...signInfo, id: e.target.value })
                    }
                  />
                  <button
                    className={`btn_check ${checkList.checkId ? "btn_no" : ""}`}
                    onClick={onClickCheckId}
                  >
                    중복확인
                  </button>
                </div>
              </div>
              <div className="wrap_input">
                <label htmlFor="user_pw" className="require">
                  비밀번호
                </label>
                <div className="input_area">
                  <input
                    type="password"
                    id="user_pw"
                    placeholder="비밀번호"
                    onChange={(e) =>
                      setSignInfo({ ...signInfo, password: e.target.value })
                    }
                  />
                  {checkList.checkRegPassword != null && (
                    <p className="error">
                      {!checkList.checkRegPassword && config.regxMsg}
                    </p>
                  )}
                </div>
              </div>
              <div className="wrap_input">
                <label htmlFor="user_pw_confirm" className="require">
                  비밀번호 재확인
                </label>
                <div className="input_area">
                  <input
                    type="password"
                    id="user_pw_confirm"
                    placeholder="비밀번호 재확인"
                    onChange={(e) =>
                      setSignInfo({
                        ...signInfo,
                        password_confirm: e.target.value,
                      })
                    }
                  />
                  {checkList.checkPassword != null &&
                    !checkList.checkPassword && (
                      <p className="error">{message.errorNotEqualsPassword}</p>
                    )}
                </div>
              </div>
              <div className="wrap_input">
                <label htmlFor="user_name" className="require">
                  이름
                </label>
                <div className="input_area">
                  <input
                    type="text"
                    id="user_name"
                    placeholder="이름"
                    onChange={(e) =>
                      setSignInfo({ ...signInfo, name: e.target.value })
                    }
                    disabled={toggleCheck ? true : false}
                  />
                </div>
              </div>
              <div className="wrap_input">
                <label htmlFor="user_phone" className="require">
                  가입자 휴대폰
                </label>
                <div className="input_area">
                  <input
                    type="text"
                    id="user_phone"
                    maxLength={11}
                    placeholder="가입자 휴대폰"
                    value={signInfo.phone}
                    onChange={(e) =>
                      setSignInfo({
                        ...signInfo,
                        phone: window.$Global.isNumberInput(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="wrap_input">
                <label htmlFor="user_email" className="require">
                  이메일
                </label>
                <div className="input_area">
                  <input
                    type="text"
                    id="user_email"
                    className="has_btn"
                    placeholder="이메일"
                    onChange={(e) =>
                      setSignInfo({ ...signInfo, email: e.target.value })
                    }
                  />
                  <button
                    className={`btn_check ${
                      checkList.checkEmail ? "btn_no" : ""
                    }`}
                    onClick={onClickCheckEmail}
                  >
                    중복확인
                  </button>
                </div>
              </div>
            </div>
            <div className="right_content">
              <div className="form_ipnow">
                <div className="head">
                  <div
                    className="head_item"
                    onClick={() =>
                      !toggleCheck && onChangeFindCompany(signInfo.name, "name")
                    }
                  >
                    <ToggleButtonWithSpan
                      text=""
                      condition={{ on: "있어요", off: "없어요" }}
                      selected={toggleCheck}
                      toggleSelected={() => setToggleCheck(!toggleCheck)}
                    />
                  </div>
                </div>
                <>
                  {toggleCheck && (
                    <>
                      <div className="wrap_input onOffToggle">
                        <label htmlFor="user_company" className="require">
                          이름
                        </label>
                        <div className="input_area">
                          <div
                            className="wrap_input search"
                            onMouseEnter={() => setMouse(true)}
                            onMouseLeave={() => setMouse(false)}
                          >
                            <input
                              type="text"
                              id="user_company"
                              className="input_person"
                              value={signInfo.name}
                              placeholder="검색하세요"
                              onChange={(e) =>
                                onChangeFindCompany(e.target.value, "name")
                              }
                              onFocus={() => setShowKeywordList(true)}
                              onBlur={onBlur}
                              autoFocus
                              disabled={toggleCheck ? false : true}
                            />
                            <button
                              className="icon_m_search"
                              onClick={() =>
                                onChangeFindCompany(signInfo.name, "name")
                              }
                            />
                            {showKeywordList && keywordList}
                          </div>
                        </div>
                      </div>
                      <div className="wrap_input">
                        <label htmlFor="user_eng_company">
                          출원인 등록번호
                        </label>
                        <div className="input_area">
                          <input
                            type="text"
                            id="user_eng_company"
                            className="input_person"
                            placeholder="출원인 등록번호를 입력하세요"
                            value={signInfo.personal_number}
                            onChange={(e) =>
                              setSignInfo({
                                ...signInfo,
                                personal_number: e.target.value,
                              })
                            }
                            disabled={toggleCheck ? false : true}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SignupPersonKakao
          signInfo={signInfo}
          setSignInfo={setSignInfo}
          checkList={checkList}
          onClickCheckEmail={onClickCheckEmail}
        />
      )}
      <div className="wrap_agree">
        <input
          type="checkbox"
          id="checkbox"
          onChange={(e) =>
            setCheckList({ ...checkList, checkAgree: e.target.checked })
          }
        />
        <label htmlFor="checkbox">
          <span onClick={(e) => onClickTermsShow(1, e)}>이용약관</span> 및{" "}
          <span onClick={(e) => onClickTermsShow(2, e)}>개인정보 처리방침</span>
          에 동의합니다.
        </label>
      </div>
      <button
        className={`btn_join ${checkAll ? "" : "btn_disabled"}`}
        onClick={onClickSignup}
      >
        가입하기
      </button>
      {alertShow && <SignupAlertPopup onClick={onClickSearchPopupShow} />}

      {popupShow && (
        <PopupIPAutoAdd
          company_code={signInfo.company_code}
          onClickSelectIPAdd={onClickSelectIPAdd}
        />
      )}

      {alertSuccessShow && (
        <SignupAlertAddSuccess onClick={() => setAlertSuccessShow(false)} />
      )}
    </div>
  );
}

export default SignupCompany;
