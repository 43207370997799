import React from "react";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function DatePickerEpRow({title, titleClassName, condition, data, onChangeDatePicker, maxDate}) {
    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            <div className="btns">
                <span>{data ? window.$Global.convertDate(data) : 'N/A'} </span>
                {
                    condition
                    &&
                    <DatePickerCustom data={data} onChangeDatePicker={onChangeDatePicker} maxDate={maxDate}/>
                }
            </div>
        </div>
    );
};

export default DatePickerEpRow;
