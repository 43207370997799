import React, {useContext} from "react";
import CommonContext from "../../store/Common";
import "./css/KeywordList.scss";

function KeywordListWithPerson({arr, onClickManualInput, onClickAutoInput}) {
	const {isMobile} = useContext(CommonContext);

	return (
		<ul id="KeywordListWithPerson" className="keyword_list custom_scroll" style={{display: arr.length ? "block" : 'none'}}>
			<li key={0} onClick={onClickManualInput}>
				<h2>직접 입력하기(수동)</h2>
				<p>
					<i className="icon_alert_circle"/>
					<span>원하는 결과가 검색이 되지 않을 경우 직접입력으로 수동입력이 필요합니다.</span>
				</p>
			</li>
			{
				arr.map((item, index) => {
					return (
						<li key={index} onClick={() => onClickAutoInput(item, isMobile)}>
							<h2>{item.name}</h2>
							<p className="pc">
								{item.name_en && item.name_en.replace(/(\s*)/g, "") && `영문명 ${item.name_en}`}
								{item.company_code && ` / 출원인 등록번호 ${item.company_code}`}
								{item.location && ` / 주소 ${item.location}`}
							</p>
							<div className="mo">
								{
									(item.name_en && item.name_en.replace(/(\s*)/g, ""))
									&&
									<p>
										<span>영문명:</span> {item.name_en}
										<br/>
									</p>
								}
								{
									item.company_code
									&&
									<p>
										<span>출원인 등록번호:</span> {item.company_code}
										<br/>
									</p>
								}
								{
									item.location &&
									<p>
										<span>주소:</span> {item.location}
									</p>
								}
							</div>
						</li>
					);
				})
			}
		</ul>
	);
}

export default KeywordListWithPerson;