import React, { useState, useEffect, useCallback } from "react";
import qs from "query-string";
import "./css/SignupInput.scss";
import API from "../../util/api";
import InfoAPI from "../../API/info";
import MemberAPI from "../../API/member";
import SignupAlertPopup from "./SignupAlertPopup";
import MobileAddPopup from "./MobileAddPopup";
import MobileAlertPopup from "./MobileAlertPopup";
import PopupIPAutoAdd from "./PopupIPAutoAdd";
import SignupAlertAddSuccess from "./SignupAlertAddSuccess";
import config from "../../const/config";
import SignupInputCompany from "./accountForm/SignupInputCompany";
import SignupInputPersonal from "./accountForm/SignupInputPersonal";
import SignupInputAgent from "./accountForm/SignupInputAgent";
import SignupInputInvestor from "./accountForm/SignupInputInvestor";
import SignupInputSNS from "./accountForm/SignupInputSNS";
import KeywordListWithCompany from "../common/KeywordListWithCompany";
import message from "../../const/message";
import _ from "lodash";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import InputCustom from "./inputCommon/InputCustom";

const { IMP, Kakao } = window;

function SignupInput({ history, location }) {
  const regPasswordErrorMsg = config.regxMsg;
  const { type } = qs.parse(location.search);
  const [signInfo, setSignInfo] = useState({
    imp_uid: "",
    company: "",
    company_name_en: "",
    company_code: "",
    licence_number: "", // 사업자 등록번호
    corporate_number: "", // 법인번호
    unq_key: "",
    id: "",
    password: "",
    password_confirm: "",
    email: "",
    patent: [],
    tradeMark: [],
    design: [],
    product: [],
    manage_phone: "",
    phone: "",
    location: "", // 특허사무소
    // 투자자
    type_detail: 0,
    manager_name: "",
    company_phone: "",
    company_homepage: "",
    desc: "", // 투자자 회사 소개
    company_file: [],
    // 개인 가입자
    name: "",
    birth_at: "",
    personal_number: "", // 출원인 등록번호
  });

  const [checkId, setCheckId] = useState(false);
  const [checkPassword, setCheckPassword] = useState(null);
  const [checkRegPassword, setCheckRegPassword] = useState(null);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPhone, setCheckPhone] = useState(false);

  const [optionPersonal, setOptionPersonal] = useState(false);

  const [nextButtonState, setNextButtonState] = useState(false);
  const [keywordList, setKeywordList] = useState([]);

  const [mouse, setMouse] = useState(false);
  const [showKeywordList, setShowKeywordList] = useState(false);

  const [editAble, setEditAble] = useState(false);

  // alert
  const [alertShow, setAlertShow] = useState(false);
  const [alertSuccessShow, setAlertSuccessShow] = useState(false);

  // 팝업
  const [popupShow, setPopupShow] = useState(false);

  const [step, setStep] = useState(1);
  const [mCheckCompanyCode, setMCheckCompanyCode] = useState(false);
  const [mCheckDetailInfo, setMCheckDetailInfo] = useState(false);
  const [mCheckDefaultInfo, setMCheckDefaultInfo] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const [errorAlready, setErrorAlready] = useState({
    show: false,
    company: "",
    email: "",
  });

  const isCompany = type === "company";
  const isAgent = type === "agent";
  const isInvestor = type === "investor";
  const isPersonal = type === "personal";
  const isSNS = type === "sns";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    setNextButtonState(false);
    setMCheckDetailInfo(false);
    setMCheckDefaultInfo(false);

    if (isCompany) {
      let checked =
        checkId &&
        checkRegPassword &&
        checkPassword &&
        checkEmail &&
        checkPhone;
      if (
        signInfo.company &&
        (signInfo.licence_number || signInfo.corporate_number) &&
        signInfo.id &&
        checked &&
        signInfo.email &&
        signInfo.phone
      ) {
        setNextButtonState(true);
      }

      // 모바일
      if (
        signInfo.company &&
        (signInfo.licence_number || signInfo.corporate_number)
      ) {
        setMCheckDetailInfo(true);
      }

      if (signInfo.id && signInfo.email && checked && signInfo.phone) {
        setMCheckDefaultInfo(true);
      }
    } else if (isAgent) {
      let checked =
        checkId &&
        checkRegPassword &&
        checkPassword &&
        checkEmail &&
        checkPhone;
      if (
        signInfo.company &&
        signInfo.licence_number &&
        signInfo.location &&
        signInfo.id &&
        checked &&
        signInfo.email
      ) {
        setNextButtonState(true);
      }

      // 모바일
      if (signInfo.company && signInfo.licence_number && signInfo.location) {
        setMCheckDetailInfo(true);
      }

      if (signInfo.id && signInfo.email && checked) {
        setMCheckDefaultInfo(true);
      }
    } else if (isInvestor) {
      let checked =
        checkId &&
        checkRegPassword &&
        checkPassword &&
        checkEmail &&
        checkPhone;

      if (signInfo.type_detail == "0") {
        if (
          signInfo.id &&
          checked &&
          signInfo.manager_name &&
          signInfo.phone &&
          signInfo.email
        ) {
          setNextButtonState(true);
        }
        // 모바일
        if (signInfo.id && checked && signInfo.phone && signInfo.email) {
          setMCheckDefaultInfo(true);
        }
      } else {
        if (
          signInfo.company &&
          signInfo.licence_number &&
          signInfo.id &&
          checked &&
          signInfo.manager_name &&
          signInfo.phone &&
          signInfo.email
        ) {
          setNextButtonState(true);
        }

        // 모바일
        if (signInfo.company && signInfo.licence_number) {
          setMCheckDetailInfo(true);
        }

        // 모바일
        if (
          signInfo.id &&
          checked &&
          signInfo.email &&
          signInfo.manager_name &&
          signInfo.phone
        ) {
          setMCheckDefaultInfo(true);
        }
      }
    } else if (isPersonal) {
      let checked =
        signInfo.id &&
        checkId &&
        checkRegPassword &&
        checkPassword &&
        checkEmail &&
        checkPhone;
      if (toggleCheck) {
        if (checked && signInfo.personal_number) {
          setNextButtonState(true);
          setMCheckDefaultInfo(true);
        }
      } else if (!toggleCheck) {
        if (checked) {
          setNextButtonState(true);
          setMCheckDefaultInfo(true);
        }
      }
    } else if (isSNS) {
      if (checkPhone && checkEmail) {
        setNextButtonState(true);
        setMCheckDefaultInfo(true);
      }
    }
  });

  useEffect(() => {
    if (signInfo.phone.length > 11) {
      setSignInfo({ ...signInfo, phone: signInfo.phone.slice(0, 11) });
    }
  }, [signInfo.phone]);

  useEffect(() => {
    setCheckId(false);
  }, [signInfo.id]);

  useEffect(() => {
    setCheckEmail(false);
  }, [signInfo.email]);

  useEffect(() => {
    if (!signInfo.password) return;
    if (signInfo.password === signInfo.password_confirm) {
      setCheckPassword(true);
    } else {
      setCheckPassword(false);
    }

    const checkReg = config.checkReg;

    if (!signInfo.password.match(checkReg)) {
      setCheckRegPassword(false);
    } else {
      setCheckRegPassword(true);
    }
  }, [signInfo.password, signInfo.password_confirm]);

  const kakaoSignup = () => {
    history.push("?type=sns");
  };

  const debounceHandler = useCallback(
    _.debounce(async (value, key, signInfo) => {
      let data;
      if (key === "company") {
        let type = isCompany ? 0 : isAgent ? 1 : 2;
        data = await InfoAPI.getCompany(value, type, 1).then((res) => res.data);
      } else {
        data = await InfoAPI.getCompany(value, 0, 0).then((res) => res.data);
      }
      if (!Boolean(data.length)) {
        setMCheckCompanyCode(true);
        setEditAble(true);
      }
      setKeywordList(
        <KeywordListWithCompany
          arr={data}
          signInfo={signInfo}
          onClickAutoInput={onClickAutoInput}
          onClickManualInput={onClickManualInput}
        />
      );
    }, 400),
    []
  );

  const onChangeFindCompany = (value, key) => {
    setSignInfo({ ...signInfo, [key]: value });
    setAlertShow(false);
    setAlertSuccessShow(false);
    setEditAble(false);
    setPopupShow(false);
    setMCheckCompanyCode(false);
    if (!value.replace(/(\s*)/g, "").length) {
      setKeywordList([]);
      return;
    }
    if (value.length < 2) return;
    debounceHandler(value, key, signInfo);
  };

  const onClickManualInput = () => {
    setKeywordList([]);
    setMCheckCompanyCode(true);
    setEditAble(true);
  };

  const onClickAutoInput = (item, isMobile, signInfo) => {
    if (item.signed_check == 1) {
      setShowKeywordList(false);

      if (isMobile) {
        setErrorAlready({ show: true, company: item.name, email: item.email });
        return;
      } else {
        alert(
          "위 기업은 이미 가입되어 있습니다\n가입 정보를 확인하고 싶으시면 아래 정보로 연락부탁드립니다.\n대표전화: 1551-8442"
        );
        return;
      }
    } else {
      if ((isCompany || isPersonal) && !isMobile) {
        setAlertShow(true);
      }
      setEditAble(true);
      setSignInfo({
        ...signInfo,
        name: item.name,
        company_code: item.company_code,
        company: item.name || "",
        company_name_en: item.name_en || "",
        licence_number: item.license_number || "",
        corporate_number: item.corporate_number || "",
        location: item.location || "",
        unq_key: item.unq_key,
        personal_number: item.company_code,
      });
      setKeywordList([]);
      setMCheckCompanyCode(true);
    }
  };

  const onClickSearchPopupShow = () => {
    setAlertShow(false);
    setPopupShow(true);
  };

  const onClickCheckId = () => {
    if (!signInfo.id) {
      alert("아이디를 입력해주세요");
      return;
    }

    MemberAPI.idCheck(signInfo.id).then(() => {
      alert("사용가능한 아이디입니다");
      setCheckId(true);
    });
  };

  const onClickCheckEmail = () => {
    if (!signInfo.email) {
      alert("이메일을 입력해주세요");
      return;
    }

    if (!signInfo.email.match(config.emailReg)) {
      alert(message.errorEmailRegx);
      return;
    }

    MemberAPI.emailCheck(signInfo.email).then(() => {
      alert("사용가능한 이메일입니다");
      setCheckEmail(true);
    });
  };

  const onClickSelectIPAdd = (json, code) => {
    setSignInfo({ ...signInfo, ...json, company_code: code });
    setPopupShow(false);
    setAlertSuccessShow(true);
  };

  const onClickPatentSelectAdd = (arr, code) => {
    let add_arr = [];
    for (let i = 0; i < arr.length; i++) {
      add_arr.push(arr[i]);
    }
    setSignInfo({ ...signInfo, company_code: code, patent: add_arr });
  };

  const onClickTradeMarkSelectAdd = (arr, code) => {
    let add_arr = [];
    for (let i = 0; i < arr.length; i++) {
      add_arr.push(arr[i]);
    }
    setSignInfo({ ...signInfo, company_code: code, tradeMark: add_arr });
  };

  const onClickDesignSelectAdd = (arr, code) => {
    let add_arr = [];
    for (let i = 0; i < arr.length; i++) {
      add_arr.push(arr[i]);
    }
    setSignInfo({ ...signInfo, company_code: code, design: add_arr });
  };

  const onClickNext = () => {
    if (isInvestor) {
      let formData = new FormData();

      formData.append("type", "2");
      formData.append("type_detail", signInfo.type_detail);
      formData.append("phone", signInfo.phone);
      formData.append("imp_uid", signInfo.imp_uid);
      formData.append("in_charge", signInfo.manager_name);
      formData.append("url", signInfo.company_homepage);
      formData.append("company_name", signInfo.company);
      formData.append("licence_number", signInfo.licence_number);
      formData.append("id", signInfo.id);
      formData.append("password", signInfo.password);
      formData.append("email", signInfo.email);
      formData.append("desc", signInfo.desc);
      formData.append("file", signInfo.company_file);

      API.post("/member/sign_up", formData).then(() => {
        history.push(`/signup/success?type=${type}`);
      });
    } else if (isSNS) {
      Kakao.Auth.login({
        throughTalk: false,
        success: function (res) {
          let payload = {
            access_token: res.access_token,
            imp_uid: signInfo.imp_uid,
            email: signInfo.email,
            phone: signInfo.phone,
          };
          MemberAPI.signupKakao(payload).then(() => {
            alert(
              "회원가입되었습니다\n카카오 로그인하기를 통해 서비스 사용 가능합니다"
            );
            history.push("/login");
          });
        },
        fail: function (error) {
          alert(error);
        },
      });
    } else {
      onClickSignup();
    }
  };

  const onClickSignup = async () => {
    let payload = {
      ...signInfo,
      company_name: signInfo.company,
    };

    delete payload.patent;
    delete payload.tradeMark;
    delete payload.design;

    if (isCompany) {
      payload.type = 0;
    } else if (isAgent) {
      payload.type = 1;
    } else if (isPersonal) {
      await MemberAPI.signupPersonal(payload).then(async (res) => {
        if (optionPersonal) {
          await insertIP(res);
        } else {
          SignupSuccessEvent();
        }
      });
      return;
    }

    await MemberAPI.signup(payload).then(async (res) => {
      let code = res.data.code;
      if (isCompany) {
        await insertIP(res);
      } else {
        SignupSuccessEvent(code);
      }
    });
  };

  const insertIP = async (res) => {
    let code = res.data.code;

    let object = {
      company_idx: res.data.company_idx,
      member_idx: res.data.member_idx,
      company_code: signInfo.company_code,
    };
    await setTimeout(() => {
      let patent_length = signInfo.patent.length;
      let td_length = signInfo.tradeMark.length;
      let design_length = signInfo.design.length;

      if (patent_length) {
        let payload = {
          ...object,
        };
        payload.patent_list = signInfo.patent.map((item) => item.idx);
        InfoAPI.insertPatent(payload)
          .then()
          .catch((err) => err);
      }
      if (td_length) {
        let payload = {
          ...object,
        };
        payload.td_list = signInfo.tradeMark.map((item) => item.idx);
        InfoAPI.insertTrademark(payload)
          .then()
          .catch((err) => err);
      }
      if (design_length) {
        let payload = {
          ...object,
        };
        payload.design_list = signInfo.design.map((item) => item.idx);
        InfoAPI.insertDesign(payload)
          .then()
          .catch((err) => err);
      }

      if (patent_length || td_length || design_length) {
        alert(
          "선택하신 IP를 등록되는데에는 일정시간이 소요됩니다\n새로고침 후 정상적으로 이용 가능합니다"
        );
      }
      SignupSuccessEvent(code);
    }, 100);
  };

  const SignupSuccessEvent = (code) => {
    let url = `/signup/success?type=${type}&code=${code || ""}&refresh=1`;
    history.replace(url);
  };

  const onBlur = () => {
    if (!mouse) {
      setShowKeywordList(false);
    }
  };

  const nextStep = () => {
    if (isCompany && !signInfo.company_code) {
      if (step == 2 || step == 3) {
        setStep(7);
        return;
      }
    }

    if (isInvestor && signInfo.type_detail == 0 && step == 1) {
      setStep(3);
      return;
    }
    setStep(step + 1);
  };

  const onClickCertification = () => {
    if (signInfo.phone.length != 11) {
      alert(message.errorRequirePhone);
      return;
    }
    IMP.certification(
      {
        // param
        phone: signInfo.phone,
      },
      (rsp) => {
        // callback
        if (rsp.success) {
          setSignInfo({ ...signInfo, imp_uid: rsp.imp_uid });
          setCheckPhone(true);
        } else {
          console.log("실패");
        }
      }
    );
  };

  let idTag = (
    <div className="field">
      <p className="f_title">아이디*</p>
      <p className="required">*필수입력항목</p>
      <div className="wrap_input_validator">
        <input
          type="text"
          value={signInfo.id}
          onChange={(e) => setSignInfo({ ...signInfo, id: e.target.value })}
        />
        <button
          className={`btn_check ${checkId ? "btn_no" : ""}`}
          onClick={onClickCheckId}
        >
          중복확인
        </button>
      </div>
    </div>
  );

  let emailTag = (
    <div className="field">
      <p className="f_title">이메일*</p>
      <div className="wrap_input_validator">
        <input
          type="text"
          value={signInfo.email}
          onChange={(e) => setSignInfo({ ...signInfo, email: e.target.value })}
        />
        <button
          className={`btn_check ${checkEmail ? "btn_no" : ""}`}
          onClick={onClickCheckEmail}
        >
          중복확인
        </button>
      </div>
    </div>
  );

  let pwTag = (
    <div className="field">
      <p className="f_title">비밀번호*</p>
      <div className="wrap_input">
        <input
          type="password"
          value={signInfo.password}
          onChange={(e) =>
            setSignInfo({ ...signInfo, password: e.target.value })
          }
        />
      </div>
      {checkRegPassword != null && (
        <p className={`alert_msg ${!checkRegPassword && "false"}`}>
          {!checkRegPassword && regPasswordErrorMsg}
        </p>
      )}
    </div>
  );

  let pwConfirmTag = (
    <div className="field">
      <p className="f_title">비밀번호 확인*</p>
      <div className="wrap_input">
        <input
          type="password"
          value={signInfo.password_confirm}
          onChange={(e) =>
            setSignInfo({ ...signInfo, password_confirm: e.target.value })
          }
        />
      </div>
      {checkPassword != null && (
        <p className={`alert_msg ${checkPassword ? "true" : "false"}`}>
          비밀번호가 {checkPassword ? "일치합니다" : "일치하지 않습니다"}
        </p>
      )}
    </div>
  );

  const commonAccountInputTag = (type) => {
    return (
      <>
        {idTag}
        {type !== isPersonal && emailTag}
        {pwTag}
        {pwConfirmTag}
      </>
    );
  };

  let signTag = [];
  let type_arr = [
    "개인형 엑셀러레이터 (IA)",
    "법인형 액셀러레이터 (CA)",
    "벤처캐피탈 (VC)",
    "사모펀드 (PEF)",
    "은행",
    "개인투자조합",
    "기술지주회사",
    "기업",
  ];
  let type_option = type_arr.map((item, idx) => (
    <option key={idx} value={idx}>
      {item}
    </option>
  ));

  let commonPwdTag = (
    <>
      <div
        className={`field ${
          isInvestor || isPersonal || editAble ? "" : "action_no"
        }`}
      >
        <div className="f_head">
          <i className="icon_red_dot" />
          비밀번호
        </div>
        <div className="f_content">
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            value={signInfo.password}
            onChange={(e) =>
              setSignInfo({ ...signInfo, password: e.target.value })
            }
          />
          {checkRegPassword != null && (
            <span className={`alert_msg ${!checkRegPassword && "false"}`}>
              {!checkRegPassword && regPasswordErrorMsg}
            </span>
          )}
        </div>
      </div>
      <div
        className={`field ${
          isInvestor || isPersonal || editAble ? "" : "action_no"
        }`}
      >
        <div className="f_head">
          <i className="icon_red_dot" />
          비밀번호 재확인
        </div>
        <div className="f_content">
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            value={signInfo.password_confirm}
            onChange={(e) =>
              setSignInfo({ ...signInfo, password_confirm: e.target.value })
            }
          />
          {checkPassword != null && (
            <span className={`alert_msg ${checkPassword ? "true" : "false"}`}>
              비밀번호가 {checkPassword ? "일치합니다" : "일치하지 않습니다"}
            </span>
          )}
        </div>
      </div>
    </>
  );

  let commonPhoneTag = (
    <div className="field">
      <div className="f_title">가입자 전화번호*</div>
      <div className="wrap_input_validator">
        <input
          type="number"
          maxLength="11"
          value={signInfo.phone}
          onChange={(e) => setSignInfo({ ...signInfo, phone: e.target.value })}
          placeholder="“-” 구분없이 입력"
        />
        <button
          className={`btn_check ${checkPhone ? "btn_no" : ""}`}
          onClick={onClickCertification}
        >
          {checkPhone ? "인증완료" : "본인인증"}
        </button>
      </div>
    </div>
  );

  if (isCompany) {
    switch (step) {
      case 1:
        signTag = (
          <div className="step">
            <h2 className="step_title">기업 등록</h2>
            <div className="field">
              <p className="f_title">법인명 (단체명)</p>
              <div
                className="wrap_input search"
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <input
                  type="text"
                  value={signInfo.company}
                  placeholder="검색하세요"
                  onChange={(e) =>
                    onChangeFindCompany(e.target.value, "company")
                  }
                  onFocus={() => setShowKeywordList(true)}
                  onBlur={onBlur}
                  autoFocus
                />
                <button
                  className="icon_m_search"
                  onClick={() =>
                    onChangeFindCompany(signInfo.company, "company")
                  }
                />
                {showKeywordList && keywordList}
              </div>
            </div>
            <button
              className={`btn ${mCheckCompanyCode ? "" : "btn_no"}`}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        );
        break;
      case 2:
        signTag = (
          <div className="step">
            <h2 className="step_title">상세 정보 입력</h2>
            <div className="field">
              <p className="f_title">법인명 (단체명)*</p>
              <p className="required">*필수입력항목</p>
              <div
                className="wrap_input"
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <p>{signInfo.company}</p>
              </div>
            </div>
            <div className="field">
              <p className="f_title">영문 법인명</p>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.company_name_en}
                  onChange={(e) =>
                    setSignInfo({
                      ...signInfo,
                      company_name_en: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <p className="f_title">사업자 등록번호*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  placeholder="사업자 등록번호를 입력해주세요"
                  value={signInfo.licence_number}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, licence_number: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="field">
              <p className="f_title">법인번호*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  placeholder="법인번호를 입력해주세요"
                  value={signInfo.corporate_number}
                  onChange={(e) =>
                    setSignInfo({
                      ...signInfo,
                      corporate_number: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <button
              className={`btn ${mCheckDetailInfo ? "" : "btn_no"}`}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        );
        break;
      case 3:
        signTag = (
          <MobileAddPopup
            isPatent={true}
            company_code={signInfo.company_code}
            onClickClose={() => setStep(2)}
            onClickSelectAdd={onClickPatentSelectAdd}
            nextStep={nextStep}
          />
        );
        break;
      case 4:
        signTag = (
          <MobileAddPopup
            isTradeMark={true}
            company_code={signInfo.company_code}
            onClickClose={() => setStep(2)}
            onClickSelectAdd={onClickTradeMarkSelectAdd}
            nextStep={nextStep}
          />
        );
        break;
      case 5:
        signTag = (
          <MobileAddPopup
            isDesign={true}
            company_code={signInfo.company_code}
            onClickClose={() => setStep(2)}
            onClickSelectAdd={onClickDesignSelectAdd}
            nextStep={nextStep}
          />
        );
        break;
      case 6:
        signTag = (
          <div className="step">
            <h2 className="step_title">상세 정보 입력</h2>
            {!((isCompany || isPersonal) && signInfo.company_code == "") && (
              <div className="field">
                <p className="f_title">등록한 특허, 상표, 디자인 확인</p>
                <div className="wrap_view">
                  <ul>
                    {signInfo.patent
                      .concat(signInfo.tradeMark)
                      .concat(signInfo.design).length > 0 ? (
                      signInfo.patent
                        .concat(signInfo.tradeMark)
                        .concat(signInfo.design)
                        .map((item, idx) => {
                          return (
                            <li key={idx}>
                              <div className="info">
                                <h2>
                                  {item.invention_title ||
                                    item.invention_title_en ||
                                    item.title}
                                </h2>
                                <p>{item.apply_number}</p>
                              </div>
                              <p className="state">
                                {item.status || item.state}
                              </p>
                            </li>
                          );
                        })
                    ) : (
                      <li>
                        <p className="empty">등록하신 정보가 없습니다.</p>
                      </li>
                    )}
                  </ul>
                  <button className="re" onClick={() => setStep(3)}>
                    다시 선택하기
                  </button>
                </div>
              </div>
            )}
            <button className="btn" onClick={nextStep}>
              다음
            </button>
          </div>
        );
        break;
      case 7:
        signTag = (
          <div className="step">
            <h2 className="step_title">기본 정보</h2>
            {commonAccountInputTag()}
            {commonPhoneTag}
            <button
              className={`btn ${mCheckDefaultInfo ? "" : "btn_no"}`}
              onClick={() => onClickNext()}
            >
              완료
            </button>
          </div>
        );
        break;
    }
  } else if (isAgent) {
    switch (step) {
      case 1:
        signTag = (
          <div className="step">
            <h2 className="step_title">특허사무소 등록</h2>
            <div className="field">
              <p className="f_title">특허사무소 명칭</p>
              <div
                className="wrap_input search"
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <input
                  type="text"
                  value={signInfo.company}
                  placeholder="검색해주세요"
                  onChange={(e) =>
                    onChangeFindCompany(e.target.value, "company", true)
                  }
                  onFocus={() => setShowKeywordList(true)}
                  onBlur={onBlur}
                  autoFocus
                />
                <button
                  className="icon_m_search"
                  onClick={() =>
                    onChangeFindCompany(signInfo.company, "company", true)
                  }
                />
                {showKeywordList && keywordList}
              </div>
            </div>
            <button
              className={`btn ${mCheckCompanyCode ? "" : "btn_no"}`}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        );
        break;
      case 2:
        signTag = (
          <div className="step">
            <h2 className="step_title">상세 정보 입력</h2>
            <div className="field">
              <p className="f_title">특허사무소 명칭</p>
              <p className="required">*필수입력항목</p>
              <div
                className="wrap_input"
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <p>{signInfo.company}</p>
              </div>
            </div>
            <div className="field">
              <p className="f_title">사업자 등록번호*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.licence_number}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, licence_number: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="field">
              <p className="f_title">소재지*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.location}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, location: e.target.value })
                  }
                />
              </div>
            </div>
            <button
              className={`btn ${mCheckDetailInfo ? "" : "btn_no"}`}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        );
        break;
      case 3:
        signTag = (
          <div className="step">
            <h2 className="step_title">기본 정보</h2>
            {commonAccountInputTag()}
            {commonPhoneTag}
            <button
              className={`btn ${mCheckDefaultInfo ? "" : "btn_no"}`}
              onClick={() => onClickNext()}
            >
              완료
            </button>
          </div>
        );
        break;
    }
  } else if (isInvestor) {
    let commonTag = (
      <>
        <div className="field">
          <p className="f_title">회사 홈페이지 주소</p>
          <div className="wrap_input">
            <input
              type="text"
              value={signInfo.company_homepage}
              onChange={(e) =>
                setSignInfo({ ...signInfo, company_homepage: e.target.value })
              }
            />
          </div>
        </div>
        <div className="alert">
          <i className="icon_alert_circle" />
          <p>회사 소개자료는 PC에서 업로드 해주시기 바랍니다.</p>
        </div>
      </>
    );
    switch (step) {
      case 1:
        signTag = (
          <div className="step">
            <h2 className="step_title">투자자 기관 등록</h2>
            <div className="field">
              <p className="f_title">투자자 유형</p>
              <div className="wrap_select">
                <select
                  value={signInfo.type_detail}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, type_detail: e.target.value })
                  }
                >
                  {type_option}
                </select>
              </div>
            </div>
            <button className="btn" onClick={nextStep}>
              다음
            </button>
          </div>
        );
        break;
      case 2:
        signTag = (
          <div className="step">
            <h2 className="step_title">상세 정보</h2>
            <div className="field">
              <p className="f_title">투자자 유형</p>
              <p className="required">*필수입력항목</p>
              <div
                className="wrap_input"
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <p>{type_arr[signInfo.type_detail]}</p>
              </div>
            </div>
            <div className="field">
              <p className="f_title">기관 명칭*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.company}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, company: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="field">
              <p className="f_title">사업자 등록번호*</p>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.licence_number}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, licence_number: e.target.value })
                  }
                />
              </div>
            </div>
            {commonTag}
            <button
              className={`btn ${mCheckDetailInfo ? "" : "btn_no"}`}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        );
        break;
      case 3:
        signTag = (
          <div className="step">
            <h2 className="step_title">기본 정보</h2>
            {commonAccountInputTag()}
            <div className="field">
              <div className="f_title">담당자 성명*</div>
              <div className="wrap_input">
                <input
                  type="text"
                  value={signInfo.manager_name}
                  onChange={(e) =>
                    setSignInfo({ ...signInfo, manager_name: e.target.value })
                  }
                />
              </div>
            </div>
            {commonPhoneTag}
            {signInfo.type_detail == 0 && commonTag}
            <button
              className={`btn ${mCheckDefaultInfo ? "" : "btn_no"}`}
              onClick={() => onClickNext()}
            >
              완료
            </button>
          </div>
        );
        break;
    }
  } else if (isPersonal) {
    signTag = (
      <div className="step">
        <h2 className="step_title">기본 정보</h2>
        {commonAccountInputTag(isPersonal)}
        {emailTag}
        {commonPhoneTag}
        <div className="form_ipnow">
          <div className="head">
            <div className="title_area">
              <p>
                IP 관리서비스를 함께 이용하려면, 아래 추가 정보를 입력해 주세요
              </p>
              <p>
                <i className="icon_badge_info_gray" />
                <span>
                  아래의 정보를 미입력 후 가입했을 경우 BizNavi만 우선 이용이
                  가능하고 IP 관리 서비스는 추후 마이페이지에서 추가 정보 입력
                  후 이용이 가능합니다.
                </span>
              </p>
            </div>
            <ToggleButtonWithSpan
              text="이용하기"
              condition={{ on: "ON", off: "OFF" }}
              selected={toggleCheck}
              toggleSelected={() => setToggleCheck(!toggleCheck)}
            />
          </div>
          <>
            <div>
              <label htmlFor="user_company" className="require">
                이름
              </label>
              <div className="input_area">
                <div
                  className="wrap_input search"
                  onMouseEnter={() => setMouse(true)}
                  onMouseLeave={() => setMouse(false)}
                >
                  <input
                    type="text"
                    id="user_company"
                    className="input_search_data"
                    value={signInfo.name}
                    placeholder="검색하세요"
                    onChange={(e) =>
                      onChangeFindCompany(e.target.value, "name")
                    }
                    onFocus={() => setShowKeywordList(true)}
                    onBlur={onBlur}
                    autoFocus
                    disabled={toggleCheck ? false : true}
                  />
                  {showKeywordList && keywordList}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="user_eng_company">출원인 등록번호</label>
              <div className="input_area">
                <div className="wrap_input search">
                  <input
                    type="text"
                    id="user_eng_company"
                    className="input_search_data"
                    placeholder="출원인 등록번호를 입력하세요"
                    value={signInfo.personal_number}
                    onChange={(e) =>
                      setSignInfo({
                        ...signInfo,
                        personal_number: e.target.value,
                      })
                    }
                    disabled={toggleCheck ? false : true}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
        <button
          className={`btn ${mCheckDefaultInfo ? "" : "btn_no"}`}
          onClick={onClickNext}
        >
          완료
        </button>
        <button className="btn_kakao" onClick={kakaoSignup}>
          <span>카카오 계정으로 가입하기</span>
          <i className="icon_kakao" />
        </button>
      </div>
    );
  } else if (isSNS) {
    signTag = (
      <div className="step">
        <h2 className="step_title">기본 정보</h2>
        {emailTag}
        {commonPhoneTag}
        <button
          className={`btn ${mCheckDefaultInfo ? "" : "btn_no"}`}
          onClick={onClickNext}
        >
          완료
        </button>
      </div>
    );
  }

  let props_common = {
    keywordList,
    checkId,
    checkEmail,
    checkPhone,
    signInfo,
    setSignInfo,
    onClickCheckId,
    onClickCheckEmail,
    onClickCertification,
  };

  let props_company_or_agent_or_investor = {
    ...props_common,
    keywordList,
    commonPwdTag,
    editAble,
    onChangeFindCompany,
  };

  let props_personal = {
    ...props_common,
    commonPwdTag,
    optionPersonal,
    setOptionPersonal,
  };

  return (
    <div
      id="SignupInput"
      className={`${
        (step == 1 || step == 5) && (!isPersonal || (isPersonal && step == 5))
          ? "add_page"
          : ""
      }`}
    >
      <div className="pc">
        <div className="header">
          <h2 className="title">
            {isCompany
              ? "기업·대학·연구소"
              : isAgent
              ? "특허 사무소"
              : isInvestor
              ? "기관 투자자"
              : isPersonal
              ? "개인"
              : isSNS && "SNS"}{" "}
            가입페이지
          </h2>
          {isPersonal && (
            <button onClick={kakaoSignup}>
              <span>카카오 계정으로 가입</span>
              <i className="icon_kakao" />
            </button>
          )}
        </div>
        <div className="input_content">
          {isCompany && (
            <SignupInputCompany {...props_company_or_agent_or_investor} />
          )}
          {isAgent && (
            <SignupInputAgent
              {...props_company_or_agent_or_investor}
              isInvestor={isInvestor}
            />
          )}
          {isInvestor && (
            <SignupInputInvestor
              {...props_company_or_agent_or_investor}
              isInvestor={isInvestor}
            />
          )}
          {isPersonal && (
            <SignupInputPersonal
              {...props_personal}
              onChangeFindCompany={onChangeFindCompany}
            />
          )}
          {isSNS && <SignupInputSNS {...props_common} />}
        </div>

        <div className="wrap_required">
          <p>
            <i className="icon_red_dot" />
            필수 입력항목입니다.
          </p>
        </div>

        <div className="btns">
          <button className={`btn_prev`} onClick={() => history.goBack()}>
            이전
          </button>
          <button
            className={`btn_next_step ${!nextButtonState ? "btn_no" : ""}`}
            onClick={() => onClickNext()}
          >
            가입
          </button>
        </div>
      </div>
      <div className="mo">
        <div className="wrap_step">{signTag}</div>
      </div>
      {errorAlready.show && (
        <MobileAlertPopup
          title={errorAlready.company}
          email={errorAlready.email}
          desc={`위 기업은 이미 가입되어 있으므로,\n관리자에게 문의해주시기 바랍니다.`}
          onConfirm={() => setErrorAlready({ ...errorAlready, show: false })}
        />
      )}

      {alertShow && <SignupAlertPopup onClick={onClickSearchPopupShow} />}

      {popupShow && (
        <PopupIPAutoAdd
          company_code={signInfo.company_code}
          onClickSelectIPAdd={onClickSelectIPAdd}
        />
      )}

      {alertSuccessShow && (
        <SignupAlertAddSuccess onClick={() => setAlertSuccessShow(false)} />
      )}
    </div>
  );
}

export default SignupInput;
