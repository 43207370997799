import GroupwareSideBar from "./GroupwareSideBar";
import React, { useEffect, useRef, useState } from "react";
import "./css/GroupwareApproval.scss";
import { groupWareApprovalPath } from "./groupwarePath";
import {
  groupWareWorksPath,
  groupWareExpensionWorksPath,
} from "./groupwarePath";
import GwAPI from "../../API/groupware";
import util from "../../util/util";

const GroupwareApproval = () => {
  const [unApprovedCnt, setUnApprovedCnt] = useState(0); // 미결재 카운트
  const [approvedCnt, setApprovedCnt] = useState(0); // 결재완료 카운트
  const [myDocCnt, setMyDocCnt] = useState(0); // 상신문서 카운트
  const [tempDocCnt, setTempDocCnt] = useState(0); // 임시저장 문서 카운트
  const [receiveList, setReceiveList] = useState([]); // 수신 리스트
  const [docList, setDocList] = useState([]);
  const [approvlList, setApprovlList] = useState([]);
  const [adminMenu, setAdminMenu] = useState([]);

  useEffect(() => {
    GwAPI.getDocDashBoradInfo().then((res) => {
      setUnApprovedCnt(res.data.docInfo.unApprovedCnt);
      setApprovedCnt(res.data.docInfo.approvedCnt);
      setMyDocCnt(res.data.docInfo.myDocCnt);
      setTempDocCnt(res.data.docInfo.tempDocCnt);
      setDocList(res.data.docInfo.docList);
      setReceiveList(res.data.docInfo.receiveList);
      setApprovlList(res.data.docInfo.docApprovalList);
    });
  }, []);

  useEffect(() => {
    getAdminMenu();
  }, []);

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "100006",
      menuTitle: "관리자",
      isContentWrap: false,
      isOpen: false,
    };
    util.getGroupwareAdminMenu(setAdminMenu, param);
  };

  const onClickPathMenu = (path) => {
    if (path === "works") {
      window.parent.location.href = path + "?mode=default";
    } else if (path === "vacation") {
      path = "works";
      window.parent.location.href =
        path + "?section=vacation&mode=vacation&doc_type=100089";
    } else if (path === "rewrite") {
      path = "works";
      window.parent.location.href = path + "?section=doc&command=myList";
    } else if (path === "expense") {
      path = "works";
      window.parent.location.href = path + "?section=expense&command=expenseWriteForm&doc_type=expense";
    }else {
      window.parent.location.href = path;
    }
  };
  /*
  const docView = (item, lstType) => {
    let strParam = "";
    let listMode = "";
    let path = "works?section=docView&command=viewDoc";
    strParam += "&idx=" + item.idx;
    strParam += "&doc_knd=" + item.table_name;
    strParam += "&doc_status=" + item.status;
    strParam += "&doc_status_name=" + item.status_name;
    if(lstType == "myList"){
      listMode = "myList";
    }
    strParam += "&list_mode=" + listMode;
    strParam += "&lstPart=" + item.lstPart;
    //alert(strParam);
    window.parent.location.href = path + strParam;

  }*/

  const ReceiveDocTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>제목</th>
            <th>문서분류</th>
            <th>문서번호</th>
            <th>기안자</th>
            <th>현황</th>
          </tr>
        </thead>
        {receiveList?.map((item) => {
          return (
            <tbody>
              <tr>
                <td>
                  <a href="#" onClick={() => util.docView(item, "receiveList")}>
                    {item.subject}{" "}
                  </a>
                </td>
                <td>{item.docu_name}</td>
                <td>{item.str_no}</td>
                <td>{item.name_kor}</td>
                <td>{item.status_name}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    );
  };

  const ApprovalTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th style={{width: '35%'}}>제목</th>
            <th>문서분류</th>
            <th>문서번호</th>
            <th>기안자</th>
            <th>현황</th>
          </tr>
        </thead>
        {approvlList?.map((item) => {
          return (
            <tbody>
              <tr>
                <td>
                  <a href="#" onClick={() => util.docView(item, "wait")}>
                    {item.subject}{" "}
                  </a>
                </td>
                <td>{item.docu_name}</td>
                <td>{item.str_no}</td>
                <td>{item.name_kor}</td>
                <td>{item.status_name}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    );
  };

  const MyDocTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th style={{width: '35%'}}>제목</th>
            <th>문서분류</th>
            <th>문서번호</th>
            <th>기안자</th>
            <th>현황</th>
          </tr>
        </thead>
        {docList?.map((item) => {
          return (
            <tbody>
              <tr>
                <td>
                  <a href="#" onClick={() => util.docView(item, "myList")}>
                    {item.subject}{" "}
                  </a>
                </td>
                <td>{item.docu_name}</td>
                <td>{item.str_no}</td>
                <td>{item.name_kor}</td>
                <td>{item.status_name}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    );
  };
  return (
    <div id="GroupwareApproval">
      <form name="docFrm" action="/groupware/approvalMng">
        <input type="hidden" name="idx" value="100" />
      </form>
      <GroupwareSideBar
        currentPath={groupWareWorksPath["approval"]}
        adminMenu={adminMenu}
        sidebar={"approval"}
      />
      <div className="approval_wrapper">
        <h2>결재 관리</h2>
        <div className="approval_grid_items">
          <div className="approval_apply">
            <p>
              <i className="icon_widget_pencil" />
              기안하기
            </p>
            <p className="item_head_title">신규 기안서를 작성하세요</p>
            {/* <p className="item_sub_title">샘플 텍스트</p> */}
            <button onClick={() => onClickPathMenu("works")}>신청하기</button>
          </div>
          <div className="approval_apply">
            <p>
              <i className="icon_widget_parasol" />
              휴가신청
            </p>
            <p className="item_head_title">간편하게 휴가결재를 신청하세요</p>
            {/* <p className="item_sub_title">샘플 텍스트</p> */}
            <button onClick={() => onClickPathMenu("vacation")}>
              신청하기
            </button>
          </div>
          <div className="approval_apply">
            <p>
              <i className="icon_widget_paper" />
              지출결의서
            </p>
            <p className="item_head_title">지출결의서를 작성해보세요</p>
            {/* <p className="item_sub_title">샘플 텍스트</p> */}
            <button onClick={() => onClickPathMenu("expense")}>신청하기</button>
          </div>
          {/*<div className="approval_notification">*/}
          {/*  <h5>결재 알림</h5>*/}
          {/*  <div>*/}
          {/*    <p>상신문서</p>*/}
          {/*    <p>*/}
          {/*      <span>{myDocCnt}</span>개*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <p>미결문서</p>*/}
          {/*    <p>*/}
          {/*      <span>{unApprovedCnt}</span>개*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <p>결재 완료문서</p>*/}
          {/*    <p>*/}
          {/*      <span>{approvedCnt}</span>개*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <p>임시보관함</p>*/}
          {/*    <p>*/}
          {/*      <span>{tempDocCnt}</span>개*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <h5>자주 쓰는 결재라인</h5>*/}
          {/*  <button*/}
          {/*    onClick={() =>*/}
          {/*      util.getGroupwareSrcPath("lstApproval", "", "approval")*/}
          {/*    }*/}
          {/*  >*/}
          {/*    수정/추가*/}
          {/*  </button>*/}
          {/*</div>*/}
          <div className="approval_table">
            <h5>받은 결재</h5>
            <ApprovalTable />
          </div>
          <div className="approval_table">
            <h5>올린 결재</h5>
            <MyDocTable />
          </div>
          <div className="approval_table">
            <h5>수신참조함</h5>
            <ReceiveDocTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupwareApproval;
