import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import CompetitorMain from "../components/competitor/CompetitorMain";
import CompetitorList from "../components/competitor/CompetitorList";
import CompetitorDetails from "../components/competitor/CompetitorDetails";
import CompetitorInfo from "../components/competitor/CompetitorInfo";
import CompetitorIpnowDetails from "../components/competitor/CompetitorIpnowDetails";
import CompetitorDetailsNew from "../components/competitor/CompetitorDetailsNew";
import CompetitorDetailsNew2 from "../components/competitor/CompetitorDetailsNew2";
import CompetitorSearch from "../components/competitor/CompetitorSearch";
import CompetitorStatistic from "../components/competitor/CompetitorStatistic";
import CompetitorTrend from "../components/competitor/CompetitorTrend";
import CompetitorMy from "../components/competitor/CompetitorMy";
import CompetitorRecent from "../components/competitor/my/CompetitorRecent";
import CompetitorInterest from "../components/competitor/my/CompetitorInterest";

const CompetitorRoute = ({ match }) => {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/main`}
        requireAuth={false}
        component={CompetitorMain}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={false}
        component={CompetitorList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/info/:idx`}
        requireAuth={false}
        component={CompetitorInfo}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/details`}
        requireAuth={false}
        // component={CompetitorDetails}
        component={CompetitorDetailsNew2}
      />
      {/* 디자인 변경 */}
      <UserAuthRoute
        exact
        path={`${match.path}/detailsNew`}
        requireAuth={false}
        component={CompetitorDetailsNew2}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/detailsNew2`}
        requireAuth={false}
        component={CompetitorDetailsNew}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/ipnow/details`}
        requireAuth={false}
        component={CompetitorIpnowDetails}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/search`}
        requireAuth={false}
        component={CompetitorSearch}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/statistic`}
        requireAuth={false}
        component={CompetitorStatistic}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/trend`}
        requireAuth={false}
        component={CompetitorTrend}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/clt`}
        requireAuth={true}
        component={CompetitorMy}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/recent`}
        requireAuth={true}
        component={CompetitorRecent}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my/interest`}
        requireAuth={true}
        component={CompetitorInterest}
      />
    </CustomSwitch>
  );
};

export default CompetitorRoute;
