import "../css/GroupwareBoardDetail.scss";
import qs from "query-string";
import CommonAPI from "../../../API/common";
import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import commonFunc from "../../admin/commonFunc/common";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const GroupwareBoardDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const { idx } = useParams();
  const [category, setCategory] = useState([]);
  const [postInput, setPostInput] = useState({
    file: [],
    fileView: [],
    title: "",
    category: 0,
    idx: "",
  });
  const [content, setContent] = useState("");

  useEffect(() => {
    CommonAPI.getNoticeInfo(idx).then((res) => {
      let data = res.data;
      let copy = _.cloneDeep(postInput);
      copy.title = data.title;
      copy.category = data.category;
      copy.fileView = data.files;
      copy.idx = data.idx;
      setPostInput(copy);
      setContent(data.content);
    });
    commonFunc.convertCommunityCategory().then((res) => setCategory(res));
  }, []);

  const downloadFile = (idx) => {
    CommonAPI.downloadNotice(postInput.idx, idx);
  };

  return (
    <div id="GroupwareBoardDetail">
      <div className="articles">
        <div className="field">
          <span>제목</span>
          <p>{postInput.title}</p>
        </div>

        <div className="field">
          <span>첨부파일</span>
          <div>
            {postInput.fileView.length ? (
              postInput.fileView.map((item) => {
                return (
                  <p
                    onClick={() => downloadFile(item.idx)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.file_name + "." + item.file_type}
                  </p>
                );
              })
            ) : (
              <p>첨부파일 없음</p>
            )}
          </div>
        </div>

        <div className="field">
          <span>카테고리</span>
          <p>
            {category.find((item) => item.value === postInput.category)?.name}
          </p>
        </div>

        <div className="content">
          <p
            className="sun-editor-editable"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>

      <div className="btns">
        <button className="btn_list" onClick={() => history.goBack()}>
          목록
        </button>
      </div>
    </div>
  );
};

export default GroupwareBoardDetail;
