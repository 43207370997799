import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import CompetitorIpnowMain from "../components/competitorIpnow/CompetitorIpnowMain";
import CompetitorIpnowList from "../components/competitorIpnow/CompetitorIpnowList";
import CompetitorIpnowInfo from "../components/competitorIpnow/CompetitorIpnowInfo";
import CompetitorIpnowDetails from "../components/competitorIpnow/CompetitorIpnowDetails";

const CompetitorIpnowRoute = ({ match }) => {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/main`}
        requireAuth={true}
        component={CompetitorIpnowMain}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={true}
        component={CompetitorIpnowList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/info/:idx`}
        requireAuth={true}
        component={CompetitorIpnowInfo}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/details/:idx?`}
        requireAuth={false}
        component={CompetitorIpnowDetails}
      />
    </CustomSwitch>
  );
};

export default CompetitorIpnowRoute;
