import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import memberContext from "../../store/Member";
import "./Header.scss";
import UserTab from "./UserTab";
import UnEventContext from "../../store/UnEvent";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";
import CommonAPI from "../../API/common";
import config from "../../const/config";

function Header({ history }) {
  const location = useLocation();
  const unEvent = useContext(UnEventContext);
  const memberInfo = useContext(memberContext);
  const { isMobile } = useContext(CommonContext);
  const { mobilePopup, setMobilePopup } = useContext(MobilePopupContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;
  const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
  const isInvestor = window.$Global.checkAuth("투자자", memberInfo.auth);
  const [moMenuShow, setMoMenuShow] = useState(false);
  const [showMenuTitle, setShowMenuTitle] = useState([
    {
      isShow: true,
      pathname: "/groupware/approval",
      content: "OFFICE",
      color: "#00dd77",
      iconName: "briefcase",
    },
    // {
    //   isShow: true,
    //   pathname: "/bizoffi",
    //   content: "WORKS",
    //   color: "#00dd77",
    //   iconName: "building",
    // },
    {
      isShow: true,
      pathname: "/taskManage/page2",
      content: "정부과제",
      color: "#0078f1",
      iconName: "file",
    },
    {
      isShow: true,
      pathname: "/marketInfo/category",
      content: "시장정보",
      color: "#0078f1",
      iconName: "clock",
    },
    {
      isShow: true,
      pathname: "/researchTech/category",
      content: "특허/기술트랜드조사",
      color: "#0078f1",
      iconName: "folder",
    },
    {
      isShow: true,
      pathname: "ipnow",
      content: "IP관리",
      color: "#ffa600",
      iconName: "lightning",
    },
  ]);
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const isCalcPath = window.location.pathname === "/calc";

  useEffect(() => {
    CommonAPI.log().then(() => {
      console.log("logging success");
    }); // 로깅
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setMoMenuShow(false);
    });
    return () => {
      unlisten();
    };
  }, []);

  const onClickLogout = () => window.$Global.logout("self");

  const redirect = () => (window.parent.location.href = "/"); //window.$Global.redirectHome(auth);

  const onClickChangeView = () => {
    if (!isViewTypeIpnow) {
      window.$Global.toggleViewType();
    }
  };

  const onClickClosePopup = () => {
    setMobilePopup({
      ...mobilePopup,
      mobileShow: false,
      mobileHeaderTitle: mobilePopup.historyMobileHeaderTitle,
    });
  };

  const SettingByHOC = () => {
    return (
      <div
        className={`dimmed ${moMenuShow ? "show" : "hide"}`}
        onClick={() => setMoMenuShow(false)}
      />
    );
  };

  const ComponentWithDimmed = WithPreventScroll(SettingByHOC, moMenuShow);

  let exceptCondition;

  if (isMobile) {
    exceptCondition = !isMobile || isCalcPath;
  } else {
    exceptCondition = (!isLogin && !isMobile) || isCalcPath;
  }

  if (exceptCondition) return null;

  const RenewalHeader = () => {
    return (
      <div className="wrap_header pc">
        <div className="header_left">
          <div onClick={redirect} style={header_h1}>
            {isViewTypeIpnow ? (
              <img
                src={
                  require("../../assets/images/common/logo_ipnow.svg").default
                }
                style={{ position: "relative", width: 84 }}
                onClick={redirect}
                alt="이미지"
              />
            ) : (
              <img
                src={
                  require("../../assets/images/common/logo_findBiz.svg").default
                }
                style={{ width: 96 }}
                onClick={redirect}
                alt="이미지"
              />
            )}
          </div>
          <div className="line" />
          <div className="dashboard" onClick={redirect}>
            <p>DASH board</p>
          </div>
          <div className="btn_wrapper">
            {showMenuTitle.map((el) => {
              return (
                <div
                  className="btn_item"
                  onClick={() => {
                    if (el.pathname == "ipnow") {
                      const token = window.$Global.getToken();
                      window.open(
                        "https://ipnow.co.kr" + "?token=" + token,
                        "_blank"
                      );
                    } else {
                      setShowMenuTitle([...showMenuTitle]);
                      window.parent.location.href = `${el.pathname}`;
                    }
                  }}
                >
                  <div
                    className="menu_item"
                    style={{
                      backgroundColor:
                        (el.isShow ||
                          location.pathname.includes(el.pathname)) &&
                        "rgba(240,240,240,0.4)",
                    }}
                  >
                    <div className="oval" style={{ backgroundColor: el.color }}>
                      <i className={`icon_menu_${el.iconName}`} />
                    </div>
                    {(el.isShow || location.pathname.includes(el.pathname)) && (
                      <p style={{ color: el.color }}>{el.content}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="wrap_header_utill">
          {isLogin ? (
            <UserTab
              member={memberInfo}
              onClickLogout={onClickLogout}
              unEvent={unEvent}
            />
          ) : (
            <div className="user_tab">
              <ul className="header_user_tab_ul">
                <li style={header_user_tab_li}>
                  <Link to="/signup/type" style={{ ...link }}>
                    회원가입
                  </Link>
                </li>
                <li style={header_user_tab_li}>
                  <Link to="/login" style={{ ...link }}>
                    로그인
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {/*<ul className="toolbar-wrap">*/}
          {/*  <li className="toolbar">*/}
          {/*    <button*/}
          {/*      type="button"*/}
          {/*      className="toolbar_btn"*/}
          {/*      onClick={(e) => {*/}
          {/*        onClickChangeView();*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {isViewTypeIpnow ? (*/}
          {/*        <span style={{ color: "#4593f5" }}>*/}
          {/*          BIZNAVI 바로가기 &nbsp; &gt;*/}
          {/*        </span>*/}
          {/*      ) : (*/}
          {/*        <span style={{ color: "#ffa600" }}>*/}
          {/*          IPNOW 바로가기 &nbsp; &gt;*/}
          {/*        </span>*/}
          {/*      )}*/}
          {/*    </button>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
      </div>
    );
  };
  return (
    <div id="Header">
      <RenewalHeader />
      <div className="wrap mo">
        <div className="menu_top">
          {mobilePopup.mobileShow || mobilePopup.historyMobileHeaderTitle ? (
            <>
              {/* X 버튼 및 타이틀 */}
              <button
                type="button"
                className="btnDel"
                onClick={onClickClosePopup}
              >
                창 닫기
              </button>
              <div className="menu_top_title">
                {mobilePopup.mobileHeaderTitle}
              </div>

              {/* < 버튼 및 타이틀 */}
              {/* <button type="button" className="prevDel">이전 페이지로</button>
                                    <div className="menu_top_title">제안서 샘플 마켓</div>
                                    <div className="btns"></div> */}
            </>
          ) : (
            <>
              <button
                className={isViewTypeIpnow ? "m_logo1" : "m_logo2"}
                onClick={redirect}
              >
                홈으로
              </button>
              <div className="btns">
                {
                  // isLogin &&
                  isViewTypeIpnow ? (
                    <button
                      className="btn_change btn_findbiz"
                      onClick={() => onClickChangeView(1)}
                    >
                      BizNavi 전환
                    </button>
                  ) : (
                    <button
                      className="btn_change btn_ipnow"
                      onClick={() => onClickChangeView(0)}
                    >
                      IPNOW 전환
                    </button>
                  )
                }
                {isLogin ? (
                  !isInvestor && (
                    <Link
                      to="/unEvent"
                      className={`icon_m_bell${
                        unEvent.unConfirmEventCnt > 0 ? "_on" : ""
                      }`}
                    />
                  )
                ) : (
                  // <Link to="/login" className="icon_m_login">
                  //   로그인
                  // </Link>

                  <Link className="btn_login" to="/login">
                    로그인
                  </Link>
                )}
                {moMenuShow ? (
                  <button
                    className="icon_exit"
                    onClick={() => setMoMenuShow(false)}
                  />
                ) : (
                  <button
                    className="icon_m_menu"
                    onClick={() => setMoMenuShow(true)}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mo">
        <div className="more_menu">
          <ComponentWithDimmed />
          <div className={`menus ${moMenuShow ? "menu_animation" : ""}`}>
            {isLogin && !isInvestor && (
              <>
                <div className="myinfo">
                  <div className="access_account">
                    <h2>{memberInfo.name}님</h2>
                    {memberInfo.company != "null" && (
                      <p>{memberInfo.company}</p>
                    )}
                  </div>
                  <div className="btns">
                    <Link to="/mypage/mobile" className="btn_mypage">
                      마이페이지
                      <i className="icon_arrow_right_orange" />
                    </Link>
                    {!isInvestor && window.$Global.isIpnow() && (
                      <Link to="/manage/mobile" className="btn_auth">
                        관리
                        <i className="icon_more_arrow_right_white" />
                      </Link>
                    )}
                  </div>
                </div>
                {isCompany && window.$Global.isIpnow(location) && (
                  <>
                    <div className="m_item">
                      <p>
                        IP 관리
                        <i className="icon_more_arrow_down" />
                      </p>
                      <ul>
                        <li>
                          <Link to="/patent/mlist">특허 관리</Link>
                        </li>
                        <li>
                          <Link to="/tradeMark/mlist">상표 관리</Link>
                        </li>
                        <li>
                          <Link to="/design/mlist">디자인권 관리</Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </>
            )}
            {!isInvestor && (
              <div className="m_item">
                <p>
                  기업 정보
                  <i className="icon_more_arrow_down" />
                </p>
                <ul>
                  <li>
                    <Link to="/people">구성원 소개</Link>
                  </li>
                </ul>
              </div>
            )}
            {!isLogin && (
              <>
                <div className="m_item">
                  <Link to="/intro/price">
                    이용가격
                    <i className="icon_more_arrow_right" />
                  </Link>
                </div>
                <div className="m_item">
                  <Link to="/intro/service">
                    고객지원
                    <i className="icon_more_arrow_right" />
                  </Link>
                </div>
              </>
            )}
            {isLogin && (
              <div className="m_item">
                <p onClick={onClickLogout}>
                  로그아웃
                  <i className="icon_more_arrow_right" />
                </p>
              </div>
            )}
            <div className="wrap_agree">
              <Link to="/terms/use">서비스 이용약관</Link>
              <Link to="/terms/private">개인정보 개인정보</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const header_h1 = {
  lineHeight: "40px",
  fontSize: "20px",
  color: "#ffffff",
  textAlign: "right",
  cursor: "pointer",
  display: "flex",
};

const link = {
  display: "block",
  width: "80px",
  textAlign: "center",
};

const margin_left = {
  marginLeft: "18px",
};

const font_size_11 = {
  fontSize: "11px",
};

const pointer = {
  cursor: "pointer",
};

const header_user_tab_li = {
  display: "flex",
  alignItems: "center",
  ...margin_left,
  ...font_size_11,
  ...pointer,
};

export default Header;
