import React, { useEffect, useState } from "react";
import "../css/MarketInfoRequestList.scss";
import CommonAPI from "../../../API/common";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

function MarketInfoRequestList() {
  const [curPage, setCurPage] = useState(1);
  const [tbody, setTbody] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const count = 100;

  useEffect(() => {
    let params = {
      page: 1,
      count: count,
    };
    CommonAPI.getMarketRequestList(params).then((res) => {
      let data = res.data;
      setTbody(data);
    });
  }, []);

  const download = (idx) => {
    CommonAPI.downloadMarketRequest(idx);
  };

  const deleteDoc = (idx, index) => {
    CommonAPI.deleteMarketRequest(idx).then(() => {
      alert("삭제되었습니다.");
      let copy = _.cloneDeep(tbody);
      copy.items.splice(index, 1);
      copy.total_count -= 1;
      setTbody(copy);
    });
  };

  return (
    <div className="marketInfoRequestList">
      <div className="marketInfoEditList_content">
        <h2>시장 정보 작성요청 리스트</h2>
        <table>
          <thead>
            <tr>
              <th>순번</th>
              <th>제목</th>
              <th>가격제안</th>
              <th>거래 제안 상태</th>
              <th>다운로드</th>
              <th>자세히보기</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {tbody.items?.map((item, idx) => {
              return (
                <tr key={item.idx}>
                  <td>{tbody.total_count - idx}</td>
                  <td>{item.title}</td>
                  <td>{window.$Global.commaify(item.price)}</td>
                  <td>{item.proposal ? "요청 대기중" : "제안 거부"}</td>
                  <td>
                    <button
                      className="icon_download_attached not_download ir_txt"
                      onClick={() => download(item.idx)}
                    >
                      다운로드
                    </button>
                  </td>
                  <td>
                    <Link
                      to={`request?idx=${item.idx}`}
                      className="icon_more_arrow_right ir_txt"
                    >
                      자세히보기
                    </Link>
                  </td>
                  <td>
                    <button
                      className="icon_del_trashcan ir_txt"
                      onClick={() => deleteDoc(item.idx, idx)}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketInfoRequestList;
