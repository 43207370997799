import React from 'react';

function ProductStateRow({title, titleClass, condition, contentIdx, onChange}) {

    return (
        <div className="row">
            <h2 className={`title ${titleClass || ""}`}>{title}</h2>
            {
                condition
                    ?
                    <select defaultValue={Number(contentIdx) || 0} onChange={onChange}>
                        <option value={0}>미적용</option>
                        <option value={1}>제품 개발중</option>
                        <option value={2}>제품 적용중</option>
                    </select>
                    : <p>{window.$Global.convertProductStateCodeToState(contentIdx)}</p>
            }
        </div>
    );
}

export default ProductStateRow;
