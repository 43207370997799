import React, {useContext} from "react";
import BankField from "./common/BankField";
import ApplyField from "./common/ApplyField";
import DefaultKRField from "./common/DefaultKRField";
import InputRow from "../row/InputRow";
import BillingContext from "../../../store/Billing";
import NormalRow from "../row/NormalRow";

function CostDetailTradeMarkKR({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    return (
        <div className="form">
            <DefaultKRField edit={edit}/>
            <ApplyField/>
            <div className="field">
                <div className="rows">
                    <InputRow title="출원 기본 수수료" content={billObj.apply_basic_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "apply_basic_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.apply_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "apply_basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="출원 추가 수수료" content={billObj.apply_add_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "apply_add_commission", billing)}/>
                </div>
            </div>
            <div className="field">
                <div className="rows">
                    <InputRow title="자기사용 등 법정요건" content={billObj.s_r_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "s_r_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.s_r_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "s_r_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="전문 조사기관" content={billObj.p_a_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "p_a_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.p_a_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "p_a_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title={"거절이유대응 \n의견서/보정서"} content={billObj.oa_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "oa_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.oa_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "oa_fee", billing)}/>
                </div>
            </div>
            <div className="field">
                <div className="rows">
                    <InputRow title={"등록수수료\n(성공보수금)"} content={billObj.reg_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "reg_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.reg_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "reg_fee", billing)}/>
                </div>
            </div>
            <div className="field">
                <div className="rows">
                    <InputRow title="갱신 기본 수수료" content={billObj.renewal_basic_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "renewal_basic_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.renewal_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "renewal_basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="갱신 추가 수수료" content={billObj.renewal_add_commission} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "renewal_add_commission", billing)}/>
                    <InputRow title="관납료" content={billObj.renewal_add_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "renewal_add_fee", billing)}/>
                </div>
            </div>
            <div className="field">
                <div className="rows">
                    <NormalRow title="수수료 합계" titleClassName="color_blue" content={billObj.total_commision} contentClassName="color_blue" txtType="number"/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={billObj.surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.domestic_fee} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
            <BankField edit={edit}/>
        </div>
    )
}

export default CostDetailTradeMarkKR;
