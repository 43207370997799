import API from '../../util/api';

export default {
    login(payload) {
        return API.post('/member/login', payload);
    },

    loginToken(payload) {
        return API.post('/member/loginToken', payload);
    },

    loginKakao(access_token) {
        return API.post('/member/sns/kakao/login', {access_token: access_token});
    },

    signup(payload) {
        return API.post('/member/sign_up', payload);
    },

    signupKakao(payload) {
        return API.post('/member/sns/kakao/sign_up', payload);
    },

    signupPersonal(payload) {
        return API.post('/member/simple_sign_up', payload);
    },

    findId(payload) {
        return API.post('/member/find_id', payload);
    },

    findPw(payload) {
        return API.post('/member/find_pw', payload);
    },

    idCheck(id) {
        return API.post('/member/id_check', {id: id});
    },

    emailCheck(email) {
        return API.post('/member/email_check', {email: email});
    },

    getInfo() {
        return API.get('/member/info');
    },

    updateInfo(payload) {
        return API.patch('/member', payload);
    },

    deleteInfo() {
        return API.delete('/member');
    },

    inviteJoin(payload) {
        return API.post('/member/request/join', payload);
    },

    getInviteList() {
        return API.get('/member/request');
    },

    requestInviteList(payload) {
        return API.post('/member/request', payload);
    },

    phoneAuthentication(payload) {
        return API.post('/member/authentication', payload);
    },

    connectApplicant(unq_key) {
        return API.post('/member/connect_applicant', {unq_key: unq_key});
    }
};
