import API from "../../util/api";

export default {
  getDeadline() {
    return API.get(`/member/deadline`);
  },
  updateDeadline(payload) {
    return API.post("/member/deadline", { deadline_list: payload });
  },
  getPatentDeadline(payload) {
    return API.get(`/manager/patent/deadline`, { params: payload });
  },
  getPatentDeadlineAsAgent(company_idx, payload) {
    return API.get(`/agent/company/${company_idx}/patent/deadline`, {
      params: payload,
    });
  },
  getTradeMarkDeadline(payload) {
    return API.get(`/manager/trademark/deadline`, { params: payload });
  },

  getTradeMarkNoticeDeadline() {
    return API.get(`/member/deadline/trademark`);
  },

  updateTradeMarkDeadline(payload) {
    return API.post(`/member/deadline/trademark`, { deadline_list: payload });
  },
};
