import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import "./css/MPatentDetail.scss";
import {HorizontalBar} from "react-chartjs-2";
import CommonAPI from "../../../API/common";
import AnnualPayAPI from "../../../API/annualPay";
import PatentAPI from "../../../API/patent";
import OneInRow from "./OneInRow";
import TwoInRow from "./TwoInRow";

function MPatentDetail({history}) {
    const {patent_idx} = useParams();
    const [tabType, setTabType] = useState(1);
    const [responseData, setResponseData] = useState([]);
    const [patentInfo, setPatentInfo] = useState({
            members: [
                {
                    name: '',
                    share: 0
                }
            ]
    });

    const year = new Date().getFullYear();
    const backgroundColor = responseData.map(() => '#ffa600');
    const max = window.$Global.calcGraphMaxNumber(responseData.map(item => item.cost));
    const line_chart_width = Math.floor(window.$Global.getBrowserSize().width / 1.1);

    useEffect(async () => {
        let option = await CommonAPI.getOption(0).then(res => {
            let option = res.data[0]?.option || {};
            return option;
        });

        let payload = {
            ...option,
            start_year: year,
            end_year: year + 13
        };

        let result = await AnnualPayAPI.getPatentAnnualPay(patent_idx, payload).then(res => res.data);

        setResponseData(result);

        PatentAPI.getInfo(patent_idx).then(res => setPatentInfo(res.data));
    }, []);

    const m_data = {
        labels: responseData.map(item => item.year),
        datasets: [{
            data: responseData.map(item => item.cost),
            labels: [
                '등록',
                '출원',
                '포기'
            ],
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor,
            borderWidth: 0,
        }]
    };

    const m_annualFee_options = {
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        },
        responsive: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            xAxes: [{
                ticks: {
                    display: false,
                    beginAtZero: true,
                    min: 0,
                    max : max,
                    stepSize: max / 5,
                },
                gridLines: {
                    display : true,
                    borderDash: [4],
                    color: "#d8d8d8",
                    zeroLineColor: '#aaaaaa',
                    zeroLineWidth: 2,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: true
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor : '#959595',
                    fontSize: 10
                },

                gridLines: {
                    display : false,
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'right',
                color: '#959595',
                font: {
                    weight: 'bold',
                },
                formatter: function(value) {
                    return window.$Global.commaify(value);
                }
            }
        },
    };

    return (
        <div id="MPatentDetail" className={tabType == 1 ? 'bg_tab1' : 'bg_tab2'}>
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2 className="page_title">특허 출원 상세</h2>
            </div>
            <ul className="tabs">
                <li className={`${tabType == 1 ? 'active' : ''}`} onClick={() => setTabType(1)}>
                    <p>상세정보</p>
                </li>
                <li className={`${tabType == 2 ? 'active' : ''}`} onClick={() => setTabType(2)}>
                    <p>연차료 관리</p>
                </li>
            </ul>
            {
                tabType == 1 &&
                <div className="wrap_info">
                    <div className="list">
                        <div className="item">
                            <div className="head">
                                <p>서지사항</p>
                            </div>
                            <div className="content">
                                <OneInRow title="관리번호" value={patentInfo.manage_number} className="row_title"/>
                                <TwoInRow title1="유형" value1={window.$Global.convertTypeToText(patentInfo.type)} title2="대리인" value2={patentInfo.agent_company_name}/>
                                <TwoInRow title1="Family" value1={patentInfo.family_cnt + '건'} title2="발명자1" value2={patentInfo.members.length && patentInfo.members[0].name}/>
                                <TwoInRow title1="담당자" value1={patentInfo.manager_name} title2="지분율" value2={patentInfo.members.length && patentInfo.members[0].share + '%'}/>
                                <div className="br"/>
                                <OneInRow title="발명신고서 접수" value={window.$Global.convertDate(patentInfo.invention_created_at)} className="row_center"/>
                                <OneInRow title="선행기술 조사" value={patentInfo.survey_req ? '유' : '무'} className="row_center"/>
                                {
                                    patentInfo.product?.map((item, idx) => {
                                        return <OneInRow key={idx} title={idx == 0 && `제품명 ${idx + 1}`} value={item.name} className="row_center"/>
                                    })
                                }
                            </div>
                        </div>
                        <div className="item">
                            <div className="head">
                                <p>출원</p>
                            </div>
                            <div className="content">
                                <OneInRow title="출원번호" value={patentInfo.apply_number} className="row_title"/>
                                <TwoInRow title1="출원일" value1={window.$Global.convertDate(patentInfo.apply_at)} title2="우선일" value2={window.$Global.convertDate(patentInfo.priority_at)}/>
                                <OneInRow title="발명명칭" value={patentInfo.invention_name} className="row_long"/>
                                <OneInRow title="영문명칭" value={patentInfo.invention_name_en} className="row_long"/>
                                <div className="br"/>
                                <OneInRow title="국내 우선권 주장 기한" value={window.$Global.convertDate(patentInfo.priority_claim_deadline_domestic)} className="row_center"/>
                                <OneInRow title="해외 우선권 주장 기한" value={window.$Global.convertDate(patentInfo.priority_claim_deadline_foreign)} className="row_center"/>
                                <OneInRow title="PCT 출원 기한" value={window.$Global.convertDate(patentInfo.pct_apply_deadline)} className="row_center"/>
                                <div className="br"/>
                                <TwoInRow title1="우선심사청구" value1={patentInfo.priority_screen ? '유' : '무'} title2="심사청구" value2={patentInfo.screen_req ? '유' : '무'}/>
                                <OneInRow title="심사청구일" value={window.$Global.convertDate(patentInfo.screen_req_at)}/>
                                <OneInRow title="심사청구 기한" value={window.$Global.convertDate(patentInfo.screen_deadline)}/>
                            </div>
                        </div>
                        {
                            patentInfo.oa?.map((item, idx) => {
                                return <div key={idx} className="item">
                                            <div className="head">
                                                <p>중간사건</p>
                                                <p>{`OA ${idx + 1}`}</p>
                                            </div>
                                            <div className="content">
                                                <OneInRow title="OA발행일" value={window.$Global.convertDate(item.published_at)} className="row_title row_center"/>
                                                <OneInRow title="OA답변서 제출일" value={window.$Global.convertDate(item.opinion_deadline)} className="row_center"/>
                                                <OneInRow title="OA연장 횟수" value={item.extension_cnt + '회'} className="row_center"/>
                                                <OneInRow title="OA기한" value={window.$Global.convertDate(item.deadline)} className="row_center"/>
                                            </div>
                                        </div>
                            })
                        }

                        <div className="item">
                            <div className="head">
                                <p>등록</p>
                            </div>
                            <div className="content">
                                <OneInRow title="등록 결정일" value={window.$Global.convertDate(patentInfo.register_decision_at)} className="row_title row_center"/>
                                <OneInRow title="등록번호" value={patentInfo.register_number} className="row_center"/>
                                <OneInRow title="등록일" value={window.$Global.convertDate(patentInfo.register_at)} className="row_center"/>
                                <OneInRow title="분할 출원" value={patentInfo.divideApplication ? '유' : '무'} className="row_center"/>
                                <div className="br"/>
                                <OneInRow title="존속기간 만료일" value={window.$Global.convertDate(patentInfo.expiration_at)} className="row_center"/>
                                <OneInRow title="등록기한" value={window.$Global.convertDate(patentInfo.register_deadline)} className="row_center"/>
                                <OneInRow title="관납료 납부기한" value={window.$Global.convertDate(patentInfo.payment_deadline)} className="row_center"/>
                                <OneInRow title="분할 출원기한" value={window.$Global.convertDate(patentInfo.division_deadline)} className="row_center"/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="head">
                                <p>연차료 정보</p>
                            </div>
                            <div className="content">
                                <OneInRow title="납부년차" value={patentInfo.payment_year && patentInfo.payment_year + "년차"} className="row_center"/>
                                <OneInRow title="청구항 수 (독립항/청구항)" value={patentInfo.claimCount} className="row_center"/>
                                <OneInRow title="연차료 기한" value={window.$Global.convertDate(patentInfo.annual_payment_deadline)} className="row_center"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                tabType == 2 &&
                <div className="wrap_annualFee">
                    <HorizontalBar data={m_data} width={line_chart_width} height={480} options={m_annualFee_options}/>
                </div>
            }
        </div>
    );
}

export default MPatentDetail;
