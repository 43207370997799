import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MatchingBizEdit from "../components/matchingBiz/MatchingBizEdit";
import MatchingBizDetail from "../components/matchingBiz/MatchingBizDetail";
import MatchingBizMyList from "../components/matchingBiz/MatchingBizMyList";
import CustomSwitch from "./common/CustomSwitch";
import PatentList from "../components/patent/PatentListView";
import CacheRoute from "react-router-cache-route";

function MatchingBizRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/edit`} requireAuth={true} component={MatchingBizEdit}/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={true} component={MatchingBizDetail}/>
			<CacheRoute exact path={`${match.path}/my`}
						render={(props) => window.$Global.renderFunc(props, MatchingBizMyList)}
						when="always"
			/>
		</CustomSwitch>
	)
}

export default MatchingBizRoute;