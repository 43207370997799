import CommonAPI from "../../../API/common";

export default {
  convertCommunityCategory() {
    const communityList = [
      {
        name: "BIZ 업무 메뉴얼",
        subTitle:
          "비즈내비에서 모든 부서의 업무 매뉴얼을 알려드립니다. 숙련자가 아니여도 BIZ 업무매뉴얼을 보고 충분히 할 수 있어요. 비즈내비만으로도 충분합니다.",
        isOpen: false,
        child: [
          {
            name: "마케팅팀",
            content: (
              <p>
                유튜브 마케팅 매뉴얼부터
                <br />
                마케팅 트렌드까지 한번에
              </p>
            ),
            value: 10,
            section: "infoBoard1",
            bgColor: "#fef0eb",
          },
          {
            name: "영업팀",
            content: (
              <p>
                최근 각광받는 영업 비즈니스부터
                <br />
                B2B 영업 매뉴얼까지!
              </p>
            ),
            value: 11,
            section: "infoBoard1",
            bgColor: "#fff8e5",
          },
          {
            name: "인사팀",
            content: (
              <p>
                52시간 근무제 매뉴얼부터
                <br />
                퇴직금 계산 매뉴얼까지 모두 다!
              </p>
            ),
            value: 12,
            section: "infoBoard1",
            bgColor: "#ebf3fe",
          },
          {
            name: "경영관리팀",
            content: (
              <p>
                비품관리부터 최소가 비품 구매까지
                <br />한 번에 해결!
              </p>
            ),
            value: 13,
            section: "infoBoard1",
            bgColor: "#ebf9fe",
          },
          {
            name: "디자인팀",
            content: (
              <p>
                디자이너 없이도
                <br />
                멋진 디자인하기 어렵지 않아요!
              </p>
            ),
            value: 14,
            section: "infoBoard1",
            bgColor: "#f9f2ff",
          },
          {
            name: "개발팀",
            content: (
              <p>
                '어?', '헉!' 금지
                <br />
                초보개발자들을 위한 쉽고 편한 매뉴얼
              </p>
            ),
            value: 15,
            section: "infoBoard1",
            bgColor: "#ffffee",
          },
          {
            name: "정부사업",
            content: (
              <p>
                모르면 우리 회사만 손해!
                <br />
                정부사업 수주받는 핵심 매뉴얼
              </p>
            ),
            value: 16,
            section: "infoBoard1",
            bgColor: "#f3f4f6",
          },
        ],
      },
      {
        name: "BIZ 업무 TIP",
        isOpen: false,
        child: [
          {
            name: "마케팅팀",
            value: 20,
            section: "infoBoard2",
            bgColor: "#fef0eb",
          },
          {
            name: "영업팀",
            value: 21,
            section: "infoBoard2",
            bgColor: "#fff8e5",
          },
          {
            name: "인사팀",
            value: 22,
            section: "infoBoard2",
            bgColor: "#ebf3fe",
          },
          {
            name: "경영관리팀",
            value: 23,
            section: "infoBoard2",
            bgColor: "#ebf9fe",
          },
          {
            name: "디자인팀",
            value: 24,
            section: "infoBoard2",
            bgColor: "#f9f2ff",
          },
          {
            name: "개발팀",
            value: 25,
            section: "infoBoard2",
            bgColor: "#ffffee",
          },
          {
            name: "정부사업",
            value: 26,
            section: "infoBoard2",
            bgColor: "#f3f4f6",
          },
        ],
      },
      {
        name: "비즈내비 활용 TIP",
        isOpen: false,
        child: [
          {
            name: "마케팅팀",
            value: 30,
            section: "infoBoard3",
            bgColor: "#fef0eb",
          },
          {
            name: "영업팀",
            value: 31,
            section: "infoBoard3",
            bgColor: "#fff8e5",
          },
          {
            name: "인사팀",
            value: 32,
            section: "infoBoard3",
            bgColor: "#ebf3fe",
          },
          {
            name: "경영관리팀",
            value: 33,
            section: "infoBoard3",
            bgColor: "#ebf9fe",
          },
          {
            name: "디자인팀",
            value: 34,
            section: "infoBoard3",
            bgColor: "#f9f2ff",
          },
          {
            name: "개발팀",
            value: 35,
            section: "infoBoard3",
            bgColor: "#ffffee",
          },
          {
            name: "정부사업",
            value: 36,
            section: "infoBoard3",
            bgColor: "#f3f4f6",
          },
        ],
      },
      {
        name: "IP관리의 모든 것",
        isOpen: false,
        child: [
          { name: "마케팅팀", value: 40, section: "infoBoard4" },
          { name: "영업팀", value: 41, section: "infoBoard4" },
          { name: "인사팀", value: 42, section: "infoBoard4" },
          { name: "경영관리팀", value: 43, section: "infoBoard4" },
          { name: "디자인팀", value: 44, section: "infoBoard4" },
          { name: "개발팀", value: 45, section: "infoBoard4" },
          { name: "정부사업", value: 46, section: "infoBoard4" },
        ],
      },
    ];

    return CommonAPI.getCommunityCategory().then((res) => {
      let data = res.data;
      let keys = Object.keys(data);

      let arr = [];
      for (let idx in keys) {
        let str = "";
        if (keys[idx] == "news") {
          str = "서비스 소식";
        } else if (keys[idx] == "media") {
          str = "언론보도";
        } else if (keys[idx] == "now_love") {
          str = "NOW 사랑방";
        }

        arr.push({
          name: str,
          value: data[keys[idx]],
        });
      }
      arr = [...arr, ...communityList];
      return arr;
    });
  },

  convertCommunitySectionName(section) {
    let str = "";
    if (section === "infoBoard1") {
      str = "BIZ 업무매뉴얼";
    } else if (section === "infoBoard2") {
      str = "BIZ 업무 TIP";
    } else if (section === "infoBoard3") {
      str = "비즈내비 활용 TIP";
    } else if (section === "infoBoard4") {
      str = "IP관리의 모든 것";
    }
    return str;
  },

  convertCommunityCategoryNo(category, num) {
    let result = category.find((item) => item.value == num);
    return result?.name;
  },

  convertServiceCategory() {
    return CommonAPI.getCommunityCategoryVer2().then((res) => {
      let data = res.data;
      let keys = Object.keys(data);

      let arr = [];
      for (let idx in keys) {
        let str = "";
        if (keys[idx] == "competition") {
          str = "교육·행사·경진대회";
        } else if (keys[idx] == "briefing") {
          str = "사업설명회";
        } else if (keys[idx] == "government_support") {
          str = "정부지원 정책 알림";
        }

        arr.push({
          name: str,
          value: data[keys[idx]].value,
        });
      }
      return arr;
    });
  },
};
