// src/components/ReportButton.jsx

import React, { useState, useEffect } from "react";
import CommonAPI from "../../API/common";
import BorderLinearProgress from "./BorderLinearProgress";

const ReportButton = ({ item, setIsReportShow, setAnnualFeeData }) => {
  const [buttonState, setButtonState] = useState("INITIAL"); // 기본값은 'INITIAL'

  useEffect(() => {
    if (
      (item.pge_idx && item.org_nation === 0) ||
      (item.pge_idx_f && item.org_nation !== 0)
    ) {
      setButtonState("READY");
    } else if (item.apply_cnt > 0) {
      //alert(item.apply_cnt); // apply_cnt를 확인하기 위한 알림
      setButtonState("GENERATING");
    } else {
      setButtonState("INITIAL");
    }
  }, [item]);

  useEffect(() => {
    let interval;
    if (buttonState === "GENERATING") {
      interval = setInterval(() => {
        CommonAPI.getReportApplyCheck(item).then((response) => {
          let data = response.data;
          if (data.code === "00") {
            if (data.resultCode === 1) {
              setButtonState("READY");
              clearInterval(interval);
            } else if (data.resultCode === 9) {
              alert(data.msg);
              setButtonState("INITIAL");
              clearInterval(interval);
            }
          } else if (data.code === "99") {
            alert(data.msg);
            setButtonState("INITIAL");
          }
        });
      }, 10000); // 30초마다 상태 확인
    }
    return () => clearInterval(interval);
  }, [buttonState, item]);

  const handleReportApply = () => {
    const confirm = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    if (confirm) {
      setButtonState("GENERATING");

      CommonAPI.patentReportApply(item)
        .then((response) => {
          alert(
            "보고서 생성까지 3분정도 소요됩니다."
          );
          const generatedReportIdx = response.data.reportIdx;
          item.reportIdx = generatedReportIdx;
        })
        .catch((error) => {
          setButtonState("INITIAL");
        });
    }
  };

  if (buttonState === "READY") {
    return (
      <button
        className="report_btn"
        onClick={(event) => {
          event.stopPropagation();

          setIsReportShow(true);
          setAnnualFeeData({
            manage_number: item.manage_number,
            patent_idx: item.idx,
            nation: item.org_nation,
            register_number: item.register_number || item.regist_number,
            first_renewal_expiration_deadline:
              item.first_renewal_expiration_deadline,
            apply_number: item.apply_number,
            document_number: item.document_number,
            company_name: item.company_name,
            family_idx: item.family_idx,
            type: "patent",
            year: item.year,
          });
        }}
      >
        확인하기
      </button>
    );
  } else if (buttonState === "GENERATING") {
    return (
      <div className="generating">
        <BorderLinearProgress />
        <span
          style={{
            display: "block",
            color: "#534f4f",
            fontSize: "12px",
            paddingTop: "6px",
          }}
        >
          생성중
        </span>
      </div>
    );
  } else if (item.nation === "KR") {
    if (item.register_number !== null && item.register_number !== "") {
      return (
        <button
          className="report_btn"
          onClick={(event) => {
            event.stopPropagation();
            handleReportApply();
          }}
        >
          생성하기
        </button>
      );
    } else {
      return "-";
    }
  } else {
    if (
      item.parent_register_number !== null &&
      item.parent_register_number !== "" &&
      item.parent_register_number !== undefined
    ) {
      return (
        <button
          className="report_btn"
          onClick={(event) => {
            event.stopPropagation();
            handleReportApply();
          }}
        >
          생성하기
        </button>
      );
    } else {
      return "-";
    }
  }
};

export default ReportButton;
