import React, { useEffect, useRef, useState } from "react";
import "../css/MarketInfoEdit.scss";
import MarketInfoPopupCart from "../MarketInfoPopupCart";
import convertCategory from "../js/convertCategory";
import CommonAPI from "../../../API/common";
import qs from "query-string";
import _ from "lodash";
import MarketInfoPopupDetail from "../MarketInfoPopupDetail";
import Validator from "../../../util/validator";
import SunEditor from "suneditor-react";
import SearchCrawledData from "../../common/SearchCrawledData";

function MarketInfoEdit({ history, location }) {
  const maxLength = 1000;
  const titleRef = useRef();
  const { category_idx1, category_idx2, idx } = qs.parse(location.search);
  const [category, setCategory] = useState([]);
  const [categoryChild, setCategoryChild] = useState([]);
  const select_category = convertCategory(
    category,
    category_idx1,
    category_idx2
  );

  const [selectIndex, setSelectIndex] = useState(-1);
  const [document, setDocument] = useState({
    title: "", // 제목(string)
    content: [], // 내용(Object)
    category1_idx: category_idx1, // 대분류_idx(int)
    category2_idx: category_idx2, // 중분류_idx(int)
  });

  const [cartPopupShow, setCartPopupShow] = useState(false);
  const [detailPopupShow, setDetailPopupShow] = useState(false);
  const [detailPopupInfo, setDetailPopupInfo] = useState({});
  const [checkType, setCheckType] = useState("image"); // image: 이미지 첨부/ gpt: GPT요약 추가하기

  useEffect(() => {
    async function fetchData() {
      if (idx) {
        const res = await CommonAPI.getMarketDocument(idx);
        setDocument(res.data);
      }

      const categoryRes = await CommonAPI.getMarketCategory();
      const categories = categoryRes.data.category_list;

      if (!document.category1_idx || !document.category2_idx) {
        setDocument((prevDoc) => ({
          ...prevDoc,
          category1_idx: categories[0].idx,
          category2_idx: categories[0].child[0].idx,
        }));
      }
      setCategoryChild(categories[0].child);
      setCategory(categories);
    }

    fetchData();
  }, [idx]);

  const onClickDetailShowPopup = (item) => {
    setDetailPopupShow(true);
    setDetailPopupInfo(item);
  };

  const onClickPopupShow = (index) => {
    setSelectIndex(index);
    setCartPopupShow(true);
  };

  const onChangeDoc = (key, value) => {
    let copy = _.cloneDeep(document);
    copy[key] = value;
    setDocument(copy);
  };

  const addContent = (type) => {
    let copy = _.cloneDeep(document);
    if (type == "text") {
      copy.content.push({
        type: "text",
        value: "",
        isOpen: false,
      });
    } else {
      copy.content.push({
        type: "image",
      });
    }
    setDocument(copy);
  };

  const onChangeTextDocContent = (index, value) => {
    setDocument((prevDocument) => {
      let copy = _.cloneDeep(prevDocument);

      // 기존 값과 새 값이 동일한 경우 상태 업데이트를 하지 않음
      if (copy.content[index].value === value) return prevDocument;

      copy.content[index].value = value;
      return copy;
    });
  };

  const deleteContent = (index) => {
    let copy = _.cloneDeep(document);
    copy.content.splice(index, 1);
    setDocument(copy);
  };

  const deleteContentImage = (index) => {
    let copy = _.cloneDeep(document);
    copy.content[index].mi_idx = "";
    copy.content[index].my_idx = "";
    copy.content[index].image_key = "";
    setDocument(copy);
  };

  const onClickSelectItem = (item) => {
    if (selectIndex > -1) {
      setDocument((prevDocument) => {
        let copy = _.cloneDeep(prevDocument); // 이전 상태를 깊은 복사

        // 선택한 항목에 따라 mi_idx 또는 my_idx를 설정
        if (item.mi_idx) {
          copy.content[selectIndex].mi_idx = item.mi_idx;
        } else {
          copy.content[selectIndex].my_idx = item.idx;
        }

        // GPT 요약이 있을 경우 gpt_summary에 추가
        if (item.mi_info) {
          copy.content[selectIndex].value += item.mi_info.gpt_summary;
        }

        // 이미지 키 설정
        copy.content[selectIndex].image_key = item.image_key;

        return copy; // 새로운 상태 반환
      });

      // 팝업 닫기
      setCartPopupShow(false);
    }
  };

  const onClickSaveAndDownload = async () => {
    if (Validator.refValidator([titleRef])) {
      if (!document.content.length) {
        alert("내용을 입력해주세요");
        return;
      }

      let validatorTxt = document.content.filter(
        (item) => item.type == "text" && !Boolean(item.value.length)
      ).length;

      if (validatorTxt) {
        alert("글 내용을 입력해주세요");
        return;
      }

      let validatorImage = document.content.filter(
        (item) => item.type == "image" && !Boolean(item.mi_idx || item.my_idx)
      ).length;

      if (validatorImage) {
        alert("이미지를 선택해주세요");
        return;
      }

      if (idx) {
        await CommonAPI.updateMarketDocument(idx, document);
      } else {
        await CommonAPI.addMarketDocument(document);
      }
      alert("저장되었습니다");
      history.replace("my");
    }
  };

  return (
    <div id="MarketInfoEdit">
      <div className="header">
        <h2>시장 정보 작성하기</h2>
      </div>
      <section>
        {select_category.b_category || document.category1_name ? (
          <h5>
            {select_category.b_category || document.category1_name}
            {select_category.s_category || document.category2_name}
          </h5>
        ) : (
          <div style={{ display: "flex" }}>
            <select
              onChange={(e) => {
                const selectedIdx = e.target.value;
                const selectedItem = category.find(
                  (item) => item.idx == selectedIdx
                );
                setDocument({ ...document, category1_idx: selectedIdx });
                if (selectedItem) {
                  setCategoryChild(selectedItem.child);
                }
              }}
              style={{ width: "150px" }}
            >
              {category.map((item, index) => {
                return <option value={item.idx}>{item.name}</option>;
              })}
            </select>
            <h5 style={{ margin: "0 10px" }}></h5>
            <select
              onChange={(e) =>
                setDocument({ ...document, category2_idx: e.target.value })
              }
              style={{ width: "160px" }}
            >
              {categoryChild.map((item) => {
                return <option value={item.idx}>{item.name}</option>;
              })}
            </select>
          </div>
        )}
        <article className="step_wrapper">
          <div className="step_header">
            <p className="step_title">Step.1 제목 작성하기</p>
          </div>
          <div className="step1_input">
            <p className="step_subtitle">제&emsp;목</p>
            <input
              type="text"
              placeholder="제목을 입력해주세요"
              data-name="제목"
              ref={titleRef}
              autoFocus={true}
              value={document.title}
              onChange={(e) => onChangeDoc("title", e.target.value)}
            />
          </div>
        </article>
        <article className="step_wrapper">
          <div className="step_header">
            <p className="step_title">Step.2 내용 작성하기</p>
            <button className="step_content" onClick={() => addContent("text")}>
              글 박스 추가하기
              <i className="icon_info_with_blue_bg" />
            </button>
            <button className="step_image" onClick={() => addContent("image")}>
              이미지 박스 추가하기
              <i className="icon_download_with_white_bg" />
            </button>
          </div>
          {document.content.map((item, idx) => {
            if (item.type == "text") {
              return (
                <div className="step2">
                  <div className="step2_body">
                    <div className="step2_body_head">
                      <div className="desc_info">
                        <h2>설명 란</h2>
                        <button
                          className="btn_crawled"
                          onClick={() => {
                            item.isOpen = true;
                            setDocument({ ...document });
                          }}
                        >
                          크롤링 데이터 검색
                        </button>
                        <button
                          className="btn_crawled"
                          onClick={() => {
                            setCheckType("gpt");
                            onClickPopupShow(idx);
                          }}
                        >
                          요약 추가하기
                        </button>
                        <button
                          className="btn_delete"
                          onClick={() => deleteContent(idx)}
                        >
                          삭제하기
                          <i className="icon_badge_del_white" />
                        </button>
                      </div>
                    </div>
                    <div className="content_wrapper">
                      <SunEditor
                        placeholder="설명글을 입력해주세요"
                        setContents={item.value ? item.value : ""}
                        setOptions={{ width: "1551px" }}
                        onChange={(content) => {
                          onChangeTextDocContent(idx, content); // 변경된 값만 업데이트
                        }}
                      />
                    </div>
                    {item.isOpen && (
                      <SearchCrawledData
                        saveData={item}
                        setData={setDocument}
                        data={document}
                      />
                    )}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="step2">
                  <div className="step2_body">
                    <div className="step2_body_head">
                      <div className="desc_info">
                        <h2>이미지 첨부</h2>
                        <button
                          className="btn_delete"
                          onClick={() => deleteContent(idx)}
                        >
                          삭제하기
                          <i className="icon_badge_del_white" />
                        </button>
                      </div>
                    </div>
                    <div className="image_attach_list">
                      {item.image_key ? (
                        <div className="item image">
                          <img
                            src={window.$Global.getCDN(item.image_key)}
                            alt="이미지"
                            onContextMenu={window.$Global.blockRightClick}
                          />
                          <button
                            className="icon_badge_del ir_txt"
                            onClick={() => deleteContentImage(idx)}
                          >
                            삭제
                          </button>
                        </div>
                      ) : (
                        <div
                          className="item get_image"
                          onClick={() => {
                            setCheckType("image");
                            onClickPopupShow(idx);
                          }}
                        >
                          <i className="icon_download_attached" />
                          <p>장바구니에서 이미지 가져오기</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </article>
      </section>

      <button className="btn_save" onClick={onClickSaveAndDownload}>
        저장하기
      </button>

      <MarketInfoPopupCart
        show={cartPopupShow}
        onClickPopupShow={onClickDetailShowPopup}
        onSuccess={onClickSelectItem}
        onClose={() => setCartPopupShow(false)}
        checkType={checkType}
      />
      <MarketInfoPopupDetail
        show={detailPopupShow}
        item={detailPopupInfo}
        type="graph"
        onClose={() => setDetailPopupShow(false)}
      />
    </div>
  );
}

export default MarketInfoEdit;
