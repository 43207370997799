import React, {useContext} from "react";
import {Bar} from "react-chartjs-2";
import CommonContext from "../../../store/Common";

function BarBizTypeCnt({type_cnt, width, height, tooltipsText, pluginHide}) {
    const {isMobile} = useContext(CommonContext);
    type_cnt = type_cnt["30"];

    if (typeof type_cnt == "undefined") return null;

    const biz_type_cnt = () => {
        let keys = Object.keys(type_cnt);
        return {
            labels: keys,
            datasets: [
                {
                    data: keys.map(item => type_cnt[item]),
                    backgroundColor: isMobile ? 'rgba(255, 255, 255)' : 'rgb(255, 231, 0)',
                    borderColor: isMobile ? 'rgba(255, 255, 255)' : 'rgba(255, 231, 0, 0.8)',
                    borderWidth: 8,
                    lineTension: 0,
                    fill: true,
                }
            ],
        };
    };

    let type_cnt_keys = Object.keys(type_cnt);
    let type_cnt_max = window.$Global.calcGraphMaxNumber(type_cnt_keys.map(key => type_cnt[key])) || 100;

    const biz_type_options = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem) => window.$Global.commaify(tooltipItem.value) + (tooltipsText || "건")
            }
        },
        // maintainAspectRatio: false,
        // responsive: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            yAxes: [{
                ticks: {
                    display: true,
                    beginAtZero: true,
                    max: type_cnt_max,
                    stepSize: type_cnt_max / 4,
                    maxTicksLimit: 5,
                    fontColor: isMobile ? "#ffffff" : '#443018',
                    callback: value => window.$Global.commaify(Math.floor(value))
                },
                gridLines: {
                    display: true,
                    borderDash: [3],
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    zeroLineColor: isMobile ? 'rgb(255,255,255)' : '#e8e8e8',
                    color: isMobile ? "#ffffff" : '#e8e8e8',
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: isMobile ? "#ffffff" : '#443018',
                },
                gridLines: {
                    display: false,
                }
            }]
        },
        plugins: {
            datalabels: {
                display: pluginHide,
                anchor: 'end',
                align: 'top',
                color: isMobile ? '#ffffff' : "#443018",
                font: {
                    weight: 'bold'
                },
                formatter: (value) => window.$Global.commaify(value)
            }
        }
    };

    return (
        <Bar
            data={biz_type_cnt}
            width={width || 465}
            height={height || 250}
            options={biz_type_options}
        />
    )
}

export default BarBizTypeCnt;