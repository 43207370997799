import React, {useContext} from 'react';
import './css/HomeInventor.scss';
import {Link} from "react-router-dom";
import PieGraph from "./common/PieGraph";
import UnEventContext from "../../store/UnEvent";

function HomeInventor({data, data2, designData, options, patent_all_cnt, trademark_all_cnt, design_all_cnt, isEmptyPatentData, isEmptyTrademarkData, isEmptyDesignData}) {
    const unEvent = useContext(UnEventContext);

    return (
        <div className="pc">
            <div className="area_box shadow" style={{marginTop: 10}}>
                <div className="title">
                    <h2>실시간 보유 IP 현황</h2>
                </div>
                <div className="menu inventor_menu">
                    <div className="wrap_tab">
                        <PieGraph className="tab_patent" title={"특 허"} to="/patent/list" data={data} options={options} cnt={patent_all_cnt} isEmptyData={isEmptyPatentData}/>
                        <PieGraph title={["상 표", "디 자 인"]} to={["/tradeMark/list", "/design/list"]} data={[data2, designData]} options={options} cnt={[trademark_all_cnt, design_all_cnt]} isEmptyData={[isEmptyTrademarkData, isEmptyDesignData]} dual={true}/>
                        <div className="inner_tab">
                            <div className="tab_un_event tab_link">
                                <Link to="/unEvent">
                                    <h2>알림</h2>
                                    <p className="desc">단계별 진행사항 알림 제공</p>
                                    <p className="count">
                                        <span>{unEvent.unConfirmEventCnt}</span>건
                                    </p>
                                </Link>
                            </div>
                            <div className="tab_date_manage investor_tab_date_manage tab_link">
                                <Link to="/deadline/list">
                                    <i className="icon_hourglass_small"/>
                                    <p>IP기한관리</p>
                                    <button className="icon_next_black"/>
                                </Link>
                            </div>
                            <div className="tab_invention investor_tab_invention tab_link">
                                <Link to="/invention/list">
                                    <i className="icon_invention"/>
                                    <p>발명신고서 등록</p>
                                    <button className="icon_next_black"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mo">
            <div className="alert">
                <i className="icon_alert_circle"/>
                <p>PC로 이용해주시기 바랍니다.</p>
            </div>
        </div>
        </div>
    );
}

export default HomeInventor;
