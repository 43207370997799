import React, { useEffect, useState } from "react";
import "./css/IntroTaskList.scss";
import CommonAPI from "../../../API/common";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useHistory } from "react-router-dom";
import LoaderPlaceholder from "../../common/LoaderPlaceholder";
import TaskTable from "../../taskManage/organisms/TaskTable";
import Const from "../../taskManage/const";
import links from "../../../const/links";
import PDFDocument from "../../common/PDFDocument";

function IntroTaskList() {
  const defaultStr = "공고 참고";
  const history = useHistory();
  const date = new Date();
  const [filePDF, setFilePDF] = useState(null);
  const [list, setList] = useState([]);
  const [value, setValue] = useState(0);
  const [field, setField] = useState({});

  const [PDFList, setPDFList] = useState([]);

  useEffect(() => {
    CommonAPI.getTaskField(Const.showType.bizOrganized).then((res) =>
      setField(res.data)
    );
    CommonAPI.getTaskPDFList().then((res) =>
      setPDFList(res.data.items.sort((a, b) => a.order - b.order))
    );
    getList();
  }, []);

  const getPDFInfo = (idx) => {
    CommonAPI.getTaskPDFInfo(idx).then((res) => {
      setFilePDF(res.data);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setList([]);

    if (newValue !== 0) {
      getList(newValue - 1);
    } else {
      getList(newValue);
    }
  };

  const getList = (option) => {
    let payload = { count: 26, select_option: option || 0 };
    CommonAPI.getTaskNewHome(payload).then((res) => setList(res.data.items));
  };

  const redirectURL = (keyword, idx) => {
    return history.push(
      `${links.taskManage}?searchKeyword=${keyword}${idx ? `&idx=${idx}` : ""}`
    );
  };

  const convertDate = (ms) => {
    if (ms) {
      return isNaN(ms) ? ms : window.$Global.convertDate(ms);
    } else {
      return defaultStr;
    }
  };

  const convertDay = (day) => {
    let result = Number(day) || "";
    return result > 999 ? "999+" : result;
  };

  const onClickDetail = (idx, title) => {
    redirectURL(title, idx);
  };

  return (
    <div id="IntroTaskList">
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="핵심 통합 공고" data-key={"init"} />
        <Tab label="추천 사업" data-key={"recommended"} />
        <Tab label="마감 임박 사업" data-key={"d_day"} />
        <Tab label="조회수 상위 사업" data-key={"high_hit"} />
      </Tabs>

      <div className="task_box_list scrollbar_hidden">
        {value === 0 ? (
          PDFList.map((item, idx) => {
            return (
              // <div key={item.idx} className={`task_item korea_item type_${(idx % 3 + 1)}`} onClick={() => setFilePDF(`https://findbiz.co.kr/${item.file_key}`)}>
              <div
                key={item.idx}
                className={`task_item korea_item type_${(idx % 3) + 1}`}
                onClick={() => getPDFInfo(item.idx)}
              >
                <p>{date.getFullYear()}년</p>
                <h2>{item.title}</h2>
                <button>보러가기</button>
              </div>
            );
          })
        ) : Boolean(list?.length) ? (
          list?.slice(0, 4).map((item, idx) => {
            return (
              <div
                key={idx}
                className="task_item"
                onClick={() => redirectURL(item.title, item.idx)}
              >
                <div className="task_head">
                  <div className="task_state">{"접수중"}</div>
                  <h2 className="task_title">{item.title}</h2>
                </div>
                <p
                  className="task_desc"
                  dangerouslySetInnerHTML={{
                    __html: window.$Global.removeHTML(item.description),
                  }}
                />
                <p className="day pc">D-day {convertDay(item.d_day)}</p>
                <div className="task_info_list">
                  <div className="wrap_date">
                    <div className="task_info_item">
                      <h2>접수일</h2>
                      <p>{convertDate(item.reception_at)}</p>
                    </div>
                    <div className="task_info_item">
                      <h2>마감일</h2>
                      <p>{convertDate(item.deadline_at)}</p>
                    </div>
                  </div>
                  <div className="wrap_date col">
                    <div className="task_info_item">
                      <h2>지역</h2>
                      <p>
                        {window.$Global.matchJsonToStr(
                          field,
                          "region",
                          item.region
                        ) || defaultStr}
                      </p>
                    </div>
                    <div className="task_info_item">
                      <h2>분야</h2>
                      <p>
                        {window.$Global.matchJsonToStr(
                          field,
                          "field",
                          item.field
                        ) || defaultStr}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="task_add_info">
                  <div className="task_amount">
                    <i className="icon_money" />
                    <p>
                      <b>
                        {Boolean(item.amount)
                          ? (isNaN(item.amount)
                              ? item.amount
                              : window.$Global.commaify(item.amount) || 0) +
                            "원"
                          : "공고 확인"}
                      </b>
                    </p>
                  </div>
                  <p className="day mo">D-day {convertDay(item.d_day)}</p>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <LoaderPlaceholder />
            <LoaderPlaceholder />
            <LoaderPlaceholder />
            <LoaderPlaceholder />
          </>
        )}
      </div>

      <TaskTable
        showType={Const.showType.bizOrganized}
        searchType={{}}
        field={field}
        list={list.slice(4)}
        onClickDetail={onClickDetail}
      />

      {filePDF && <PDFDocument file={filePDF} setFile={setFilePDF} />}
    </div>
  );
}

export default IntroTaskList;
