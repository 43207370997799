import React, { useContext, useEffect, useRef, useState } from "react";
import "../css/MyPageFindbizAccountEdit.scss";
import { Link, useHistory } from "react-router-dom";
import MemberAPI from "../../../API/member";
import Validator from "../../../util/validator";
import message from "../../../const/message";
import config from "../../../const/config";
import companyType from "../../../types/companyType";
import CommonContext from "../../../store/Common";

let { IMP } = window;
function MyPageFindbizAccountEdit() {
  const { isMobile } = useContext(CommonContext);
  const history = useHistory();
  const [info, setInfo] = useState({});
  const [historyInfo, setHistoryInfo] = useState({});
  const [phoneChange, setPhoneChange] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [phoneRef, emailRef] = [useRef(null), useRef(null)];

  useEffect(() => {
    MemberAPI.getInfo().then((res) => {
      setInfo(res.data);
      setHistoryInfo(res.data);
    });
  }, []);

  const cancelPhone = () => {
    setPhoneChange(false);
    setInfo(historyInfo);
  };

  const onClickCertification = () => {
    if (info.phone?.length != 11) {
      alert(message.errorRequirePhone);
      return;
    }

    IMP.certification(
      {
        phone: info.phone,
      },
      (rsp) => {
        // callback
        if (rsp.success) {
          let payload = {
            imp_uid: rsp.imp_uid,
            phone: info.phone,
          };

          MemberAPI.phoneAuthentication(payload).then(() => {
            alert(message.successCertified);
            history.go(0);
          });
        } else {
          console.log("실패");
        }
      }
    );
  };

  const cancelEmail = () => {
    setEmailChange(false);
    setInfo(historyInfo);
  };

  const updateEmail = () => {
    if (Validator.refValidator([emailRef])) {
      if (!info.email.match(config.emailReg)) {
        alert(message.errorRegxEmail);
        return;
      }
      MemberAPI.updateInfo({ email: info.email }).then(() => {
        alert(message.successSave);
        setEmailChange(false);
        setHistoryInfo(info);
      });
    }
  };

  return (
    <div id="MyPageFindbizAccountEdit">
      <div className="head">
        <h2>사용자 정보 수정</h2>
        <p>{window.$Global.convertAccountType(info.company_type)} 가입자</p>
      </div>
      <div className="account_area">
        {info.company_type !== companyType.person && (
          <div className="wrap_info one">
            <h2>회사</h2>
            <div className="info">
              <p>{info.company_name}</p>
            </div>
          </div>
        )}
        <div className="wrap_info">
          <h2>아이디</h2>
          <div className="info">
            <p>{info.id}</p>
          </div>
        </div>
        <div className="wrap_info">
          <h2>이름</h2>
          <div className="info">
            <p>{info.name}</p>
          </div>
        </div>
        <div className="wrap_info">
          <h2>휴대폰</h2>
          <div className="info">
            {!Boolean(info.is_authentication) && phoneChange ? (
              <>
                <input
                  type="text"
                  maxLength={11}
                  ref={phoneRef}
                  data-name={"휴대폰"}
                  value={info.phone}
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      phone: window.$Global.isNumberInput(e.target.value),
                    })
                  }
                />
                <div className="btn_list">
                  <button
                    className={`btn_save ${
                      Boolean(info.is_authentication) ? "active" : ""
                    }`}
                    onClick={onClickCertification}
                  >
                    인증
                  </button>
                  <button onClick={cancelPhone}>취소</button>
                </div>
              </>
            ) : (
              <>
                <p>{info.phone}</p>
                {Boolean(info.is_authentication) ? (
                  <button className="btn_save success">인증 완료</button>
                ) : (
                  <button onClick={() => setPhoneChange(true)}>수정</button>
                )}
              </>
            )}
          </div>
          <p className="notify">
            휴대폰 번호 변경 시 고객센터로 문의 바랍니다. (
            <span>1551-8442</span>)
          </p>
        </div>
        <div className="wrap_info">
          <h2>이메일</h2>
          <div className="info">
            {emailChange ? (
              <>
                <input
                  type="text"
                  ref={emailRef}
                  data-name={"이메일"}
                  value={info.email}
                  onChange={(e) => setInfo({ ...info, email: e.target.value })}
                />
                <div className="btn_list">
                  <button className="btn_save" onClick={updateEmail}>
                    저장
                  </button>
                  <button onClick={cancelEmail}>취소</button>
                </div>
              </>
            ) : (
              <>
                <p>{info.email}</p>
                <button onClick={() => setEmailChange(true)}>수정</button>
              </>
            )}
          </div>
        </div>
      </div>
      {/*{*/}
      {/*	!isViewTypeIpnow*/}
      {/*	&&*/}
      {/*	<div className="unsubscription">*/}
      {/*		<p>비즈내비 멤버십을 더 이상 이용하지 않는다면</p>*/}
      {/*		<button>구독 해지하기</button>*/}
      {/*	</div>*/}
      {/*}*/}
      {!isMobile && (
        <div className="signout">
          <p>{window.$Global.getPlatformName()}를 더 이상 이용하지 않는다면</p>
          <Link to="/mypage/withdrawal" className="btn_del">
            회원탈퇴 바로가기
          </Link>
        </div>
      )}
    </div>
  );
}

export default MyPageFindbizAccountEdit;
