import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import CashflowIntro from "../components/groupware/cashflow/CashflowIntro";
import CashflowFind from "../components/groupware/cashflow/CashflowFind";

const CashflowRoute = ({ match }) => {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/intro`}
        requireAuth={true}
        component={CashflowIntro}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/find/:type`}
        requireAuth={true}
        component={CashflowFind}
      />
    </CustomSwitch>
  );
};

export default CashflowRoute;
