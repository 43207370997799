import React from 'react';
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function DatePickerRow({index, dataKey, title, titleClassName, rowClassName, condition, data, dataClassName, useOaFunc, onChangeDatePicker, maxDate}) {

    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            <div className="btns">
                <span className={dataClassName ? dataClassName : ''}>{data ? window.$Global.convertDate(data) : 'N/A'} </span>
                {
                    condition
                    &&
                    // useOaFunc => oa 함수 사용 여부
                    <DatePickerCustom data={data} onChangeDatePicker={date => useOaFunc ? onChangeDatePicker(index, dataKey, date) : onChangeDatePicker(dataKey, date)} maxDate={maxDate}/>
                }
            </div>
        </div>
    );
}

export default DatePickerRow;
