import PackageItems from "./PackageItems";
import "./css/Package.scss";

const Package = ({ menu }) => {
  return (
    <div className="package">
      {/*<p>*/}
      {/*  <i className="icon_3d_magnifier_black" />*/}
      {/*  <span>*/}
      {/*    서비스를 클릭해보세요. 자세한 소개와 정보를 확인할 수 있습니다.*/}
      {/*  </span>*/}
      {/*</p>*/}
      <PackageItems menu={menu} />
    </div>
  );
};

export default Package;
