import GroupwareSideBar from "./GroupwareSideBar";
import {
    groupWareWorksPath,
    groupWareExpensionWorksPath,
} from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom";
import "moment/locale/ko";
import { param, use } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState, useRef } from "react";
import util from "../../util/util";

const getAppPathExt = (pathObject) => {
    var src = config.gwUrl + "/";
    var tmp = "?";
    pathObject.map = (key, val) => {
        tmp += "&" + key + "=" + val;
    };
    return src + tmp;
};

const GroupwareWorks = () => {
    const baseSrc = config.gwUrl + "/";
    const [src, setSrc] = useState(baseSrc);
    const [strParam, setStrParam] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [adminMenu, setAdminMenu] = useState([]);
    const [gwMenu, setGwMenu] = useState([]);
    const iframeRef = useRef(null);
    const iframe = iframeRef.current;
    var isSection = !params.get("section") ? "doc" : params.get("section");
    var isCommand = !params.get("command") ? "writeForm" : params.get("command");
    var isDocType = !params.get("doc_type") ? "draft" : params.get("doc_type");
    const [isApp, setIsApp] = useState("");
    let sideBarSection = "";
    let adminMenuCd = "";
    if (
        isSection === "approval" ||
        isSection === "docView" ||
        isSection === "docList" ||
        isSection === "doc"
    ) {
        sideBarSection = "approval";
        //전자결재 관리자

    } else if (
        isSection === "vacation" &&
        isDocType === "100108"
    ) {
        sideBarSection = "attend";
    } else if (
        isSection === "expense" ||
        isSection === "extension"
    ) {
        sideBarSection = "expense";
    } else {
        sideBarSection = isSection;
    }

    const getAppPath = (section, command, doc_type, params) => {
        if (section == "reservation") {
            switch (command) {
                case "vehicleReserve":
                    setSrc(baseSrc + "vehicle.do");
                    break;
                case "insBusinessCardForm":
                case "lstBusinessCard":
                    setSrc(baseSrc + "rental.auth.do");
                    break;
                case "conferenceReserve":
                default:
                    setSrc(baseSrc + "conference.do");
                    break;
            }
        } else if (section == "extension") {
            switch (command) {
                case "lstCustomerContractor":
                    setSrc(baseSrc + "contractor.auth.do");
                    break;
                default:
                    setSrc(baseSrc + section + ".auth.do");
                    break;
            }
        } else if (section == "approval") {
            //src += section + ".person.do";
            setSrc(baseSrc + section + ".person.do");
        } else if (section == "vacationList") {
            //src += "doc.auth.do";
            setSrc(baseSrc + "doc.auth.do");
        } else if (section == "expense") {
            //src += "doc.auth.do";
            setSrc(baseSrc + "doc.auth.do");
        } else if (section == "docView") {
            let lstPart = params.get("lstPart");
            setStrParam("&idx=" + params.get("idx")
                + "&doc_knd=" + params.get("doc_knd")
                + "&doc_status=" + params.get("doc_status")
                + "&doc_status_name=" + params.get("doc_status_name")
                + "&list_mode=" + params.get("list_mode")
                + "&lstPart=" + params.get("lstPart"));
            if (lstPart == "doc") {
                setSrc(baseSrc + "doc.auth.do");
            } else if (lstPart == "expense") {
                setSrc(baseSrc + "doc.auth.do");
            } else {
                setSrc(baseSrc + "vacation.auth.do");
            }
        } else if (section == "command") {
            setStrParam("&idx=" + params.get("idx") + "&method=" + params.get("method"));
            setSrc(baseSrc + "command.information.do");
        } else if (section == "docList") {
            setSrc(baseSrc + "doc.auth.do");
        } else {
            setSrc(baseSrc + section + ".auth.do");
        }

        if (section == "vacationList") {
            return src + "?command=" + command + "&lstPart=" + doc_type;
        } else if (section == "board") {

            switch (command) {
                case "view":
                    setSrc(baseSrc + "?command=" + command + "&boardno=" + params.get("boardno") + "&pid=" + params.get("pid"));
                    break;
                default:
                    setSrc(baseSrc + "?command=" + command + "&boardno=" + doc_type);
                    break;
            }

            return src;

        } else if (section == "docView" || section == "command") {
            return src + "?command=" + command + strParam;
        } else {
            switch (command) {
                case "approvalList":
                    setSrc(prev => prev + "?command=" + command + "&list_type=" + params.get("list_type"));
                    break;

                // 전자결재 > 올린결재 > 상신문서 / 결재완료함 구분
                case "myList":
                    if (params.get("status")) {
                        setSrc(prev => prev + "?command=" + command + "&status=" + params.get("status"));
                    } else {
                        setSrc(prev => prev + "?command=" + command + "&doc_type=" + doc_type);
                    }
                    break;

                default:
                    setSrc(prev => prev + "?command=" + command + "&doc_type=" + doc_type);
                    break;
            }
            return src;
        }
    };

    useEffect(() => {
        setIsApp(getAppPath(isSection, isCommand, isDocType, params) + "&siteFlag=biznavi");
        resizeIframe();
    }, [location]);

    useEffect(() => {
        setIsApp(src + "&siteFlag=biznavi");
    }, [src]);

    useEffect(() => {
        getAdminMenu();
        resizeIframe();
    }, []);

    const resizeIframe = () => {
        // iframe 높이 조절
        const iframe = iframeRef.current;
        const handleIframeLoad = () => {
            iframe.height = 0;
            let newHeight = iframe.contentWindow.document.body.scrollHeight;
            iframe.height = newHeight;
        };
        iframe.addEventListener('load', handleIframeLoad);

        return () => {
            window.removeEventListener('load', handleIframeLoad);
        };
    };

    const getAdminMenu = () => {
        let param = {
            su_menu_cd: "",
            menuTitle: "관리자",
            isContentWrap: true,
            isOpen: false
        };

        let gwParam = {
            su_menu_cd: "",
            menuTitle: "",
            isContentWrap: true,
            isOpen: false
        };

        if (sideBarSection == "approval") {
            param.su_menu_cd = "100006";
            util.getGroupwareAdminMenu(setAdminMenu, param);
        } else if (sideBarSection == "expense") {
            param.su_menu_cd = "200247";
            util.getGroupwareAdminMenu(setAdminMenu, param);

            // 출/퇴근관리 관리자 메뉴 사라지는 현상 수정
        } else if (sideBarSection == "attend") {
            param.su_menu_cd = "100009";
            util.getGroupwareAdminMenu(setAdminMenu, param);

        } else if (sideBarSection == "board") {
            gwParam.su_menu_cd = "200057";
            gwParam.menuTitle = "게시판";
            gwParam.isOpen = true;
            util.getGroupwareMenu(setGwMenu, gwParam);

            param.su_menu_cd = "200345";
            param.menu_cd = "200290";
            param.menuTitle = "관리자";
            param.isOpen = false;
            util.getGroupwareAdminMenu(setAdminMenu, param);
        }
    }
    return (
        <div id="GroupwareWorks">
            {sideBarSection == "board" ? <GroupwareSideBar currentPath={gwMenu} adminMenu={adminMenu} sidebar={sideBarSection} /> :
                <GroupwareSideBar currentPath={groupWareWorksPath[sideBarSection]} adminMenu={adminMenu} sidebar={sideBarSection} />}
            <div className="commute_wrapper">
                <iframe
                    id="contentWrap"
                    src={isApp}
                    width="100%"
                    height="800px"
                    ref={iframeRef}
                />
            </div>
        </div>
    );
};

export default GroupwareWorks;
