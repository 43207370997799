import React from "react";
import Menu from "./common/Menu";
import "./css/ManageMyPoint.scss";
import DocTradeMyTransactionUsage from "../docTrade/DocTradeMyTransactionUsage";

function ManageMyPoint() {
    return (
        <div id="ManageMyPoint">
            <Menu number={6}/>
            <DocTradeMyTransactionUsage/>
        </div>
    )
}

export default ManageMyPoint;