import React, { useEffect, useState } from "react";
import "./css/ContentPhotoList.scss";
import Pagination from "./Pagination";
import ContentPhotoItem from "./ContentPhotoItem";

function ContentPhotoList({ title, styleHandler, path, category, getListAPI }) {
    const [resData, setResData] = useState({
        items: [],
        last: 0
    });
    const [curPage, setCurPage] = useState(1);
    const [keyword, setKeyword] = useState('');

    useEffect(() => getList(), [curPage])

    const getList = (page) => {
        if (page) {
            setCurPage(page);
        }

        let params = {
            page: page || curPage,
            service: 1,
            category: category,
            category2: -1,
            keyword: keyword,
            count: 12
        };
        getListAPI(params).then(res => setResData(res.data));
    };

    const keywordSearch = () => {
        if (curPage == 1) {
            getList();
        } else {
            setCurPage(1);
        }
    };

    return (
        <div id="ContentPhotoList" className={styleHandler} >
            <div className="pc">
                <div className="contentPhotoList_title-wrap">
                    <div className="contentPhotoList_text-wrap">
                        <div className="contentPhotoList_title">{title}</div>
                        <div className="contentPhotoList_menu-wrap">
                            {/*<ul className="contentPhotoList_menu">*/}
                            {/*    <li className="contentPhotoList_menu_list on">*/}
                            {/*        <button type="button">전&nbsp;&nbsp;&nbsp;&nbsp;체</button>*/}
                            {/*    </li>*/}
                            {/*    <li className="contentPhotoList_menu_list">*/}
                            {/*        <button type="button">카테고리1</button>*/}
                            {/*    </li>*/}
                            {/*    <li className="contentPhotoList_menu_list">*/}
                            {/*        <button type="button">카테고리2</button>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    {/*<div className="contentPhotoList_fav">*/}
                    {/*    <button type="button" className="contentPhotoList_fav_btn">즐겨찾기 보기</button>*/}
                    {/*</div>*/}
                </div>
                <div className="contentPhotoList_search">
                    <div className="contentPhotoList_search_input-wrap">
                        <select name="searchSel" id="searchSel" className="contentPhotoList_search_select">
                            <option value="s1">제목</option>
                        </select>
                        <div className="contentPhotoList_search_input">
                            <label htmlFor="serchInput" className="hidden">검색어 입력</label>
                            <input type="text" name="serchInput" id="serchInput" value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={(e) => e.key === 'Enter' && keywordSearch()}
                            />
                        </div>
                    </div>
                    <button type="submit" className="contentPhotoList_search_btn" onClick={keywordSearch}>검색하기</button>
                </div>
                <div className="contentPhotoList_table-wrap">
                    {/* <div className="contentPhotoList_table-top">
                        <select name="tableSel" id="tableSel" className="contentPhotoList_table-top_select">
                            <option value="t1">최신 등록순</option>
                            <option value="t2">최신 등록순</option>
                            <option value="t3">최신 등록순</option>
                        </select>
                    </div> */}
                    <div className="contentPhotoList_table">
                        {
                            resData.items.map((item, idx) => <ContentPhotoItem key={idx} path={path} item={item} />)
                        }
                    </div>
                    <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
                </div>
            </div>
            <div className="mo">
                <div className="contentPhotoList">
                    <h2 className="contentPhotoList_title">{title}</h2>
                    <div className="contentPhotoList_table">
                        {
                            resData.items.map((item, idx) => <ContentPhotoItem key={idx} path={path} item={item} />)
                        }
                    </div>
                </div>
                <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
            </div>
        </div>
    )
}

export default ContentPhotoList;