import GroupwareSideBar from "./GroupwareSideBar";
import "./css/GroupwarePims.scss";
import config from "../../const/config";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import IframeResizer from "iframe-resizer-react";

let mailParam = "";

const resizeIframe = (ifrIdName) => {
  var the_height =
    document.getElementById(ifrIdName).contentWindow.document.body.scrollHeight;
  document.getElementById(ifrIdName).height = the_height;
};
const loadMailPage = (ifrIdName) => {
  alert(mailParam);
  document
    .getElementById(ifrIdName)
    .contentWindow.moveMenu("/mail/mailLeftMenu.do" + mailParam, "mailIcn");
  resizeIframe("contentsWrap");
};

// top.leftframe.moveMenu('/addressbook/addressbookLeftMenu.do', 'addressInc');
// top.leftframe.moveMenu('/webfolder/webfolderLeftMenu.do', 'webfolderInc');
// top.leftframe.moveMenu('/webfolder/v2/webfolderLeftMenu.do', 'webfolderInc');
//<iframe name="leftframe" width="100%" height="100%" frameborder="0" src="/g2works/main/leftPage.do" scrolling="no" id="leftmenuWrap" style="overflow:auto; width:100%; min-height:900px;"></iframe>
export const GroupwareScheduleWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let id = !params.get("id")
    ? "?siteFlag=biznavi"
    : "?id=" + params.get("id") + "&siteFlag=biznavi";
  let param = id;
  const [iframeSrc, setIframeSrc] = useState("");
  const dummy = params.get("dummy");

  useEffect(() => {
    setIframeSrc(
      `/scheduler/showCalendar.do?siteFlag=biznavi&dummy=${dummy || ""}`
    );
  }, [dummy]);

  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{ padding: "0px" }}>
        <iframe
          id="leftmenuWrap"
          name="leftframe"
          src={`${config.gwUrl}/scheduler/schedulerLeftMenu.do${param}`}
          height="100%"
          onLoad={() => {
            //resizeIframe("leftmenuWrap");
          }}
        />
      </div>
      <div className="commute_wrapper">
        {/* <iframe
          id="contentsWrap"
          name="bodyframe"
          src=""
          width="100%"
          style={{ minHeight: "895px" }}
          onLoad={() => {
            resizeIframe("contentsWrap");
          }}
        /> */}
        <IframeResizer
          id="contentsWrap"
          name="bodyframe"
          src={iframeSrc}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyScroll"
        />
      </div>
    </div>
  );
};

export const _GroupwareMailWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let url = !params.get("mailUrl")
    ? "/g2works/main/leftPage.do"
    : params.get("mailUrl");
  mailParam = url + "&siteFlag=biznavi";
  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{ padding: "0px" }}>
        <iframe
          id="leftmenuWrap"
          name="leftframe"
          // src="/mail/mailLeftMenu.do"
          src={`${url}`}
          width="100%"
          height="100%"
          onLoad={() => {
            loadMailPage("leftmenuWrap");
            resizeIframe("leftmenuWrap");
          }}
        />
      </div>
      <div className="commute_wrapper">
        <iframe
          id="contentsWrap"
          name="bodyframe"
          src=""
          width="100%"
          height="100%"
          //style={{minHeight:"895px"}}
          onLoad={() => {
            //resizeIframe("contentsWrap");
          }}
        />
      </div>
    </div>
  );
};

export const GroupwareMailWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const iframeRef = useRef(null);

  let url = !params.get("mailUrl")
    ? "/mail/mailLeftMenu.do"
    : params.get("mailUrl");
  let to = !params.get("to") ? "" : params.get("to");
  let addressGroup = !params.get("addressGroup")
    ? ""
    : params.get("addressGroup");

  if (to !== "") {
    url = url + "?url=/mail/mailWrite.do" + "?to=" + to;
  }
  if (addressGroup !== "") {
    url = url + "?url=/mail/mailWrite.do" + "?addressGroup=" + addressGroup;
  }
  if (url.indexOf("?") > -1) {
    url = url + "&siteFlag=biznavi";
  } else {
    url = url + "?siteFlag=biznavi";
  }

  const dummy = params.get("dummy");

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.height = 0;
        let newHeight = iframe.contentWindow.document.body.scrollHeight;
        if (newHeight < 380) {
          newHeight = 380;
        }
        iframe.height = newHeight;
      }
    };

    const handleIframeSrcChange = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.addEventListener("load", handleIframeLoad);
      }
    };

    window.addEventListener("message", handleIframeSrcChange);

    if (iframeRef.current) {
      iframeRef.current.src = `/mail/mailList.do?mailBox=&siteFlag=biznavi&dummy=${
        dummy || ""
      }`;
    }

    return () => {
      window.removeEventListener("message", handleIframeSrcChange);
    };
  }, [dummy]);

  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{ padding: "0px" }}>
        <iframe
          id="leftmenuWrap"
          name="leftframe"
          src={`${url}`}
          width="100%"
          height="100%"
          // onLoad={() => {
          //   //resizeIframe("leftmenuWrap");
          // }}
        />
      </div>
      <div className="commute_wrapper">
        <iframe
          id="contentsWrap"
          name="bodyframe"
          ref={iframeRef}
          src=""
          width="100%"
          height="897px"
          //onLoad={() => { resizeIframe("contentsWrap"); }}
        />
      </div>
    </div>
  );
};

export const GroupwareCloudWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let id = !params.get("id")
    ? "?siteFlag=biznavi"
    : "?id=" + params.get("id") + "&siteFlag=biznavi";
  let param = id;
  return (
    <div className="cloudContainer">
      <div id="GroupwarePims">
        {/* style={{ height: "900px" }} */}
        {/* <div id="WebhardSideBar" style={{padding:"0px"}}> */}
        {/* <iframe
        id="leftmenuWrap"
        name="leftframe"
        src={`${config.gwUrl}/webfolder/webfolderLeftMenu.do${param}`}
        width="100%"
        height="100%"
      // onLoad={() => {
      //   resizeIframe("leftmenuWrap");
      // }}
      /> */}
        <IframeResizer
          id="leftmenuWrap"
          name="leftframe"
          src={`${config.gwUrl}/webfolder/webfolderLeftMenu.do${param}`}
          style={{ minWidth: "100%", minHeight: "calc(100vh - 207px)" }}
          heightCalculationMethod="bodyScroll"
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export const GroupwareAddressBook = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let id = !params.get("id")
    ? "?siteFlag=biznavi"
    : "?id=" + params.get("id") + "&siteFlag=biznavi";
  let param = id;
  const dummy = params.get("dummy");
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    setIframeSrc(
      `/addressbook/addressList.do?siteFlag=biznavi&dummy=${dummy || ""}`
    );
  }, [dummy]);

  return (
    <div id="GroupwarePims">
      <div id="GroupwareSideBar" style={{ padding: "0px" }}>
        <IframeResizer
          id="leftmenuWrap"
          name="leftframe"
          src={`${config.gwUrl}/addressbook/addressbookLeftMenu.do${param}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyScroll"
          className="bd_none"
        />
      </div>
      <div className="commute_wrapper address_wrapper">
        <IframeResizer
          id="contentsWrap"
          name="bodyframe"
          src={iframeSrc}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyScroll"
        />
      </div>
    </div>
  );
};
