import React from "react";
import "./css/PopupTaskFile.scss";
import CommonAPI from "../../API/common";

function PopupTaskFile({show, item, onClose}) {

    const onClickFileDownload = (f_idx) => {
        CommonAPI.taskFileDownload(item.idx, f_idx);
    }

    if (!show) return null;

    return (
        <div id="PopupTaskFile">
            <div className="head">
                <h2>첨부파일</h2>
                <button className="icon_exit" onClick={onClose}/>
            </div>
            <ul>
            {
                Boolean(item.file?.length)
                ? item.file.map((item, idx) => <li key={idx} onClick={() => onClickFileDownload(item.idx)}>{item.file_name + "." + item.file_type}</li>)
                : <li>해당 공고에는 첨부파일이 없습니다</li>
            }
            </ul>
        </div>
    );
}

export default PopupTaskFile;