import React from "react";
import InputCustom from "../inputCommon/InputCustom";
import InputId from "../inputCommon/InputId";
import InputEmail from "../inputCommon/InputEmail";
import InputPhone from "../inputCommon/InputPhone";

function SignupInputCompany({keywordList,
                             commonPwdTag,
                             editAble, checkId, checkEmail, checkPhone,
                             signInfo, setSignInfo,
                             onChangeFindCompany,
                             onClickCheckId, onClickCheckEmail, onClickCertification}) {

    const able = editAble;
    const props_common = {
        able, signInfo, setSignInfo
    };

    return (
        <>
            <div className="alert_notify" style={{marginTop: 40}}>
                <i className="icon_idea"/>
                <p>나의 기업 검색 후 선택할 경우 우리회사 IP(특허 · 상표 · 디자인)을 자동으로 IPNOW에 등록 할 수 있습니다.</p>
            </div>
            <div className="wrap_field">
                <div className="field">
                    <div className="f_head">
                        <i className="icon_red_dot"/>
                        법인명 (단체명)
                    </div>
                    <div className="f_content">
                        <input type="text"
                               value={signInfo.company}
                               placeholder="검색하세요"
                               onChange={(e) => onChangeFindCompany(e.target.value, "company")}
                               autoFocus
                        />
                        {keywordList}
                    </div>
                </div>
                <InputCustom title="영문 법인명"
                             objKey="company_name_en"
                             {...props_common}
                />
            </div>
            <div className="wrap_field">
                <InputCustom title="법인번호"
                             objKey="corporate_number"
                             required={true}
                             {...props_common}
                />
                <InputCustom title="사업자 등록번호"
                             objKey="licence_number"
                             required={true}
                             {...props_common}
                />
                <div className={`alert ${editAble ? '' : 'action_no'}`}>
                    <i className="icon_red_dot"/>
                    <p>법인 번호, 사업자 등록번호 중 1개는 필수 기입해 주세요.</p>
                </div>
            </div>
            <div className="box">
                <div className="wrap_field">
                    <InputId check={checkId}
                             onClick={onClickCheckId}
                             {...props_common}
                    />
                    <InputEmail check={checkEmail}
                                onClick={onClickCheckEmail}
                                required={true}
                                {...props_common}
                    />
                </div>
                <div className="wrap_field">
                    {commonPwdTag}
                </div>
                <div className="wrap_field" style={{margin: 0}}>
                    <InputPhone check={checkPhone}
                                onClick={onClickCertification}
                                required={true}
                                {...props_common}
                    />
                </div>
            </div>
        </>
    )
};

export default SignupInputCompany;