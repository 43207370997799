import React, { useEffect, useState } from "react";
import ContentTableList from "../common/ContentTableList";
import { useParams } from "react-router-dom";
import InfoAPI from "../../API/info";

import "./css/News.scss";

function PatentApplicationInfoContent() {
  const { type } = useParams();

  let pageTitle = type == "news" ? "뉴스자료" : "유튜브자료";
  const count = 100;
  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [content, setContent] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    let payload = {
      count: count,
      page: 1,
    };
    getAPI(payload);
  }, []);

  useEffect(() => {
    if (!window.$Global.isEmptyObject(data)) {
      drawTable();
    }
  }, [data]);

  useEffect(() => {}, []);

  const getAPI = async (payload, content_type) => {
    let result = [];
    if (type == "news") {
      result = await InfoAPI.getNews(payload).then((res) => res.data);
    } else {
      result = await InfoAPI.getYoutube(payload).then((res) => res.data);
    }

    let get_data = result;

    if (content_type == "next") {
      get_data.items = get_data.items.concat(data.items);
      setData(get_data);
    } else {
      setData(get_data);
    }
  };

  const drawTable = () => {
    setContent(
      data.items.map((item, index) => {
        return (
          <tr key={index} onClick={() => window.open(item.url, "_blank")}>
            <td>{index + 1}</td>
            <td>
              <div className="content">
                <img
                  src={window.$Global.getCDN(item.thumbnail_key)}
                  alt="이미지"
                />
                <div>
                  <h2>{item.title}</h2>
                  <p>{item.content}</p>
                </div>
              </div>
            </td>
            <td>{window.$Global.convertDate(item.created_at)}</td>
          </tr>
        );
      })
    );
    if (data.page == data.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const nextInvestorList = () => {
    let payload = {
      page: curPage + 1,
      count: count,
    };
    setCurPage(curPage + 1);
    getAPI(payload, "next");
  };

  return (
    <div className="News">
      <ContentTableList
        title={pageTitle}
        data={data.items || []}
        content={content}
        drawkList={nextInvestorList}
        hasMore={hasMore}
      />
    </div>
  );
}

export default PatentApplicationInfoContent;
