import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "../css/HoldingsStatic.scss";

const HoldingsStatic = () => {
  const [activeTab, setActiveTab] = useState("sales");
  const [dataQuarter, setDataQuarter] = useState("Q1");

  const salesChartData = {
    Q4: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "매출(만원)",
          data: [
            205784, 167850, 189800, 157500, 100750, 46340, 107360, 107340,
            10550, 20974,
          ],
          borderColor: "#0078f1",
          barThickness: 40,
          backgroundColor: "#0078f1",
        },
      ],
    },
    Q1: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "매출(만원)",
          data: [
            245784, 127890, 209800, 187500, 90750, 56340, 147360, 87340, 12550,
            23974,
          ], // Example data for Q1
          borderColor: "#0078f1",
          barThickness: 40,
          backgroundColor: "#0078f1",
        },
      ],
    },
  };

  // const salesChartData = {
  //   Q4: {
  //     labels: [
  //       "0만원",
  //       "1~5백만원",
  //       "5백~1천만원",
  //       "1천~5천만원",
  //       "1억원~2억원",
  //       "2억 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [3, 17, 23, 12, 8, 4],
  //         borderColor: "#0078f1",
  //         barThickness: 40,
  //         backgroundColor: "#0078f1",
  //       },
  //     ],
  //   },
  //   Q1: {
  //     labels: [
  //       "0만원",
  //       "1~5백만원",
  //       "5백~1천만원",
  //       "1천~5천만원",
  //       "1억원~2억원",
  //       "2억 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [5, 14, 21, 15, 9, 3], // Example data for Q1
  //         borderColor: "#0078f1",
  //         barThickness: 40,
  //         backgroundColor: "#0078f1",
  //       },
  //     ],
  //   },
  // };

  const historyChartData = {
    Q4: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "업력",
          data: [2, 1, 11, 2, 5, 4, 9, 8, 4, 7],
          borderColor: "#605BFF",
          barThickness: 40,
          backgroundColor: "#605BFF",
        },
      ],
    },
    Q1: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "업력",
          data: [3, 2, 12, 3, 6, 5, 10, 9, 5, 8], // Example data for Q1
          borderColor: "#605BFF",
          barThickness: 40,
          backgroundColor: "#605BFF",
        },
      ],
    },
  };

  // const historyChartData = {
  //   Q4: {
  //     labels: [
  //       "1년 미만",
  //       "1년~3년 미만",
  //       "3년~5년 미만",
  //       "5년~7년 미만",
  //       "7년~10년 미만",
  //       "10년~15년 미만",
  //       "15년~20년 미만",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [2, 3, 5, 3, 8, 4, 1],
  //         borderColor: "#605BFF",
  //         barThickness: 40,
  //         backgroundColor: "#605BFF",
  //       },
  //     ],
  //   },
  //   Q1: {
  //     labels: [
  //       "1년 미만",
  //       "1년~3년 미만",
  //       "3년~5년 미만",
  //       "5년~7년 미만",
  //       "7년~10년 미만",
  //       "10년~15년 미만",
  //       "15년~20년 미만",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [1, 4, 6, 2, 7, 3, 2], // Example data for Q1
  //         borderColor: "#605BFF",
  //         barThickness: 40,
  //         backgroundColor: "#605BFF",
  //       },
  //     ],
  //   },
  // };

  const empChartData = {
    Q4: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "종업원 수",
          data: [23, 14, 26, 17, 11, 10, 22, 17, 5, 8],
          borderColor: "#FFA600",
          barThickness: 40,
          backgroundColor: "#FFA600",
        },
      ],
    },
    Q1: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "종업원 수",
          data: [26, 12, 29, 18, 8, 12, 20, 19, 6, 10], // Example data for Q1
          borderColor: "#FFA600",
          barThickness: 40,
          backgroundColor: "#FFA600",
        },
      ],
    },
  };

  // const empChartData = {
  //   Q4: {
  //     labels: ["1~4인", "5~9인", "10~29인", "30~49인", "50~99인", "100~199인"],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [4, 2, 5, 6, 4, 0],
  //         borderColor: "#FFA600",
  //         barThickness: 40,
  //         backgroundColor: "#FFA600",
  //       },
  //     ],
  //   },
  //   Q1: {
  //     labels: ["1~4인", "5~9인", "10~29인", "30~49인", "50~99인", "100~199인"],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [3, 3, 4, 5, 6, 1], // Example data for Q1
  //         borderColor: "#FFA600",
  //         barThickness: 40,
  //         backgroundColor: "#FFA600",
  //       },
  //     ],
  //   },
  // };

  const investChartData = {
    Q4: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "투자금액(만원)",
          data: [
            250000, 240000, 150000, 110000, 10000, 22000, 80000, 16000, 17000,
            10000,
          ],
          borderColor: "#00DE73",
          barThickness: 40,
          backgroundColor: "#00DE73",
        },
      ],
    },
    Q1: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "투자금액(만원)",
          data: [
            300000, 200000, 270000, 125000, 15000, 20000, 90000, 20000, 10000,
            12000,
          ], // Example data for Q1
          borderColor: "#00DE73",
          barThickness: 40,
          backgroundColor: "#00DE73",
        },
      ],
    },
  };

  // const investChartData = {
  //   Q4: {
  //     labels: [
  //       "5,000만원 미만",
  //       "5,000만원~1억 미만",
  //       "1억~5억 미만",
  //       "5억~10억 미만",
  //       "10억~15억 미만",
  //       "15억 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [6, 1, 5, 2, 3, 2],
  //         borderColor: "#00DE73",
  //         barThickness: 40,
  //         backgroundColor: "#00DE73",
  //       },
  //     ],
  //   },
  //   Q1: {
  //     labels: [
  //       "5,000만원 미만",
  //       "5,000만원~1억 미만",
  //       "1억~5억 미만",
  //       "5억~10억 미만",
  //       "10억~15억 미만",
  //       "15억 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [5, 2, 4, 3, 4, 1], // Example data for Q1
  //         borderColor: "#00DE73",
  //         barThickness: 40,
  //         backgroundColor: "#00DE73",
  //       },
  //     ],
  //   },
  // };

  const ipChartData = {
    Q4: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "IP보유건수",
          data: [945, 521, 550, 745, 945, 785, 700, 448, 476, 429],
          borderColor: "#A09DFF",
          barThickness: 40,
          backgroundColor: "#A09DFF",
        },
      ],
    },
    Q1: {
      labels: [
        "아이피나우",
        "위너링크",
        "아이티엘",
        "비즈내비",
        "꿀픽",
        "김조교",
        "인크리셀",
        "에듀온링크",
        "나우앤드",
        "iAi",
      ],
      datasets: [
        {
          label: "IP보유건수",
          data: [950, 540, 559, 745, 978, 856, 712, 556, 512, 492], // Example data for Q1
          borderColor: "#A09DFF",
          barThickness: 40,
          backgroundColor: "#A09DFF",
        },
      ],
    },
  };

  // const ipChartData = {
  //   Q4: {
  //     labels: [
  //       "10건 미만",
  //       "10건~50건 미만",
  //       "50건~100건 미만",
  //       "100건~500건 미만",
  //       "500건~1000건 미만",
  //       "1000건 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [1, 3, 5, 4, 2, 0],
  //         borderColor: "#A09DFF",
  //         barThickness: 40,
  //         backgroundColor: "#A09DFF",
  //       },
  //     ],
  //   },
  //   Q1: {
  //     labels: [
  //       "10건 미만",
  //       "10건~50건 미만",
  //       "50건~100건 미만",
  //       "100건~500건 미만",
  //       "500건~1000건 미만",
  //       "1000건 이상",
  //     ],
  //     datasets: [
  //       {
  //         label: "기업수",
  //         data: [2, 4, 4, 3, 1, 1], // Example data for Q1
  //         borderColor: "#A09DFF",
  //         barThickness: 40,
  //         backgroundColor: "#A09DFF",
  //       },
  //     ],
  //   },
  // };

  const chartOption = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <div id="HoldingsStatic">
      <div className="part">
        <div>
          <strong className="part_tit">기업현황 통계</strong>
        </div>
        <div className="set">
          <div className="tabs">
            <button
              onClick={() => setActiveTab("sales")}
              className={activeTab === "sales" ? "active" : ""}
            >
              매출
            </button>
            <button
              onClick={() => setActiveTab("history")}
              className={activeTab === "history" ? "active" : ""}
            >
              업력
            </button>
            <button
              onClick={() => setActiveTab("employees")}
              className={activeTab === "employees" ? "active" : ""}
            >
              종업원 수
            </button>
            <button
              onClick={() => setActiveTab("investment")}
              className={activeTab === "investment" ? "active" : ""}
            >
              투자금액
            </button>
            <button
              onClick={() => setActiveTab("ip")}
              className={activeTab === "ip" ? "active" : ""}
            >
              IP보유건수
            </button>
          </div>
          <div className="selectors">
            <select onChange={(e) => setDataQuarter(e.target.value)}>
              <option value="Q1">2024년 1분기</option>
              <option value="Q4">2023년 4분기</option>
            </select>
          </div>
        </div>
        <div className="chart_box">
          {activeTab === "sales" && (
            <Bar
              data={salesChartData[dataQuarter]}
              options={chartOption}
              height={300}
            />
          )}
          {activeTab === "history" && (
            <Bar
              data={historyChartData[dataQuarter]}
              options={chartOption}
              height={300}
            />
          )}
          {activeTab === "employees" && (
            <Bar
              data={empChartData[dataQuarter]}
              options={chartOption}
              height={300}
            />
          )}
          {activeTab === "investment" && (
            <Bar
              data={investChartData[dataQuarter]}
              options={chartOption}
              height={300}
            />
          )}
          {activeTab === "ip" && (
            <Bar
              data={ipChartData[dataQuarter]}
              options={chartOption}
              height={300}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HoldingsStatic;
