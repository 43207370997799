import React from "react";
import "./css/MarketInfoCartItem.scss";
import CheckBox from "../common/CheckBox";
import { useHistory } from "react-router-dom";

function MarketInfoCartItem({
  index,
  item,
  hideCheckBox,
  checked,
  showPopup,
  onClickImageDelete,
  onClickCheck,
  checkType,
}) {
  const history = useHistory();
  const notUserUploadImage = !item.title; // 유저가 업로드한 이미지가 아닌 경우 참
  //console.log(item);
  return (
    <li className="MarketInfoCartItem">
      <div className="info">
        {!notUserUploadImage ? (
          <p
            className="show_detail"
            onClick={() => showPopup(item.idx, "graph")}
          >
            {/* <i className="icon_badge_info_gray" /> */}
            상세보기
          </p>
        ) : (
          <p onClick={() => alert("그래프 정보가 없는 글입니다.")}>
            <i className="icon_badge_info_gray" />
          </p>
        )}
        {checkType === "image" ? (
          item.image_key === "" ? (
            <span>이미지가 없습니다.</span>
          ) : (
            <CheckBox
              text="선택하기"
              checked={checked}
              onChangeChecked={(e) => onClickCheck(e, item, index)}
            />
          )
        ) : checkType === "gpt" ? (
          item.mi_info ? (
            <CheckBox
              text="선택하기"
              checked={checked}
              onChangeChecked={(e) => onClickCheck(e, item, index)}
            />
          ) : (
            <span>요약이 없습니다.</span>
          )
        ) : (
          <CheckBox
            text="선택하기"
            checked={checked}
            onChangeChecked={(e) => onClickCheck(e, item, index)}
          />
        )}

        {/* <CheckBox
          text="선택하기"
          checked={checked}
          onChangeChecked={(e) => onClickCheck(e, item, index)}
        /> */}
      </div>
      <img
        src={
          item.image_key
            ? window.$Global.getCDN(item.image_key)
            : require("../../assets/images/common/no_image.png").default
        }
        alt="이미지"
        onContextMenu={window.$Global.blockRightClick}
      />
      <h2>{item.title || item.image_name}</h2>
      {item.chart_data !== null && item.type === 1 && (
        <button
          className="chart_edit_btn"
          onClick={() =>
            history.push(`/marketInfo/chart?isPostType=edit&idx=${item.idx}`)
          }
        >
          차트 수정 하기
        </button>
      )}
    </li>
  );
}

export default MarketInfoCartItem;
