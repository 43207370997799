import React, {useEffect, useState} from "react";
import "../css/AdminManageResearch.scss";
import AdminAPI from "../../../API/admin";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import message from "../../../const/message";
import Pagination from "../../common/Pagination";

function AdminManageResearch() {
	const history = useHistory();
	const [category, setCategory] = useState([]);
	const [payload, setPayload] = useState({category_list: []});
	const [selectIdx, setSelectIdx] = useState(null);
	const [list, setList] = useState({});
	const [curPage, setCurPage] = useState(1);

	useEffect(() => {
		AdminAPI.getResearchCategory().then(res => setCategory(res.data));
	}, []);

	useEffect(() => {
		AdminAPI.getResearchList({count: 10, page: curPage}).then(res => setList(res.data))
	}, [curPage]);

	const onChangeBigSelect = (idx) => {
		setSelectIdx(idx);
		let copy = _.cloneDeep(payload);
		copy.category_list[0] = { category1_idx: category.category_list[idx].idx };
		setPayload(copy);
	}

	const onChangeSmallSelect = (idx) => {
		let copy = _.cloneDeep(payload);
		copy.category_list[0].category2_idx = idx;
		setPayload(copy);
	}

	const onClickAdd = () => {
		let formData = new FormData();
		for (let key in payload) {
			if (key === "category_list") {
				formData.append(key, JSON.stringify(payload[key]));
			} else {
				formData.append(key, payload[key]);
			}
		}

		AdminAPI.addResearch(formData).then(() => {
			alert("등록되었습니다");
			history.go(0);
		});
	}

	const onClickDelete = (idx) => {
		if (window.confirm(message.confirmDelete)) {
			AdminAPI.deleteResearch(idx).then(() => history.go(0))
		}
	}

	return (
		<div id="AdminManageResearch">
			<div className="articles">
				<div className="field">
					<h2>카테고리</h2>
					<select onChange={e => onChangeBigSelect(e.target.value)}>
						<option hidden>선택</option>
						{
							category.category_list?.map((item, idx) => <option key={item.idx} value={idx}>{item.name}</option>)
						}
					</select>
					<select onChange={e => onChangeSmallSelect(e.target.value)}>
						<option hidden>선택</option>
						{
							Boolean(category.category_list?.length)
							&&
							category.category_list[selectIdx]?.child.map(item => <option key={item.idx} value={item.idx}>{item.name}</option>)
						}
					</select>
				</div>
				<div className="field">
					<h2>제목</h2>
					<input type="text" onChange={e => setPayload({...payload, title: e.target.value})}/>
				</div>
				<div className="field">
					<h2>키워드</h2>
					<input type="text" onChange={e => setPayload({...payload, keyword: e.target.value})}/>
				</div>
				<div className="field">
					<h2>파일</h2>
					<input type="file" accept=".xlsx, xls" onChange={e => setPayload({...payload, file: e.target.files[0]})}/>
				</div>
			</div>

			<button className="btn_add" onClick={onClickAdd}>등록</button>

			<table>
				<colgroup>
					<col width="10%"/>
					<col width="45%"/>
					<col width="40%"/>
					<col width="5%"/>
				</colgroup>
				<thead>
					<tr>
						<th>번호</th>
						<th>제목</th>
						<th>키워드</th>
						<th>삭제</th>
					</tr>
				</thead>
				<tbody>
				{
					list.items?.map((item, idx) => {
						return (
							<tr key={item.idx}>
								<td>{idx + 1}</td>
								<td>{item.title}</td>
								<td>{item.keyword}</td>
								<td>
									<button className="icon_badge_del ir_txt" onClick={() => onClickDelete(item.idx)}>삭제 버튼</button>
								</td>
							</tr>
						)
					})
				}
				</tbody>
			</table>

			<Pagination curPage={curPage} lastNum={list.last} onClick={setCurPage} />
		</div>
	)
}

export default AdminManageResearch;
