import React from "react";
import CommonAPI from "../../API/common";
import "./css/BusinessDescriptionMain.scss";
import ContentPhotoList from "../common/ContentPhotoList";

function BusinessDescriptionMain() {
    return (
        <div id="BusinessDescriptionMain">
            <div className="pc">
                <div className="businessDescriptionMain">
                    <div className="businessDescriptionMain_box-wrap">
                        <div className="businessDescriptionMain_box">
                            <div className="businessDescriptionMain_box_title">사업설명회 동영상</div>
                            <p className="businessDescriptionMain_box_sub">정부, 지자체, 공공기관의 모든 사업 설명을 동영상으로 만날 수 있습니다.</p>
                        </div>
                        {/*<button type="button" className="businessDescriptionMain_box_btn">*/}
                        {/*	튜토리얼 바로가기*/}
                        {/*</button>*/}
                    </div>
                    <ContentPhotoList
                        title={"사업설명회 동영상 리스트"}
                        styleHandler={"col-1-1"}
                        path={"/businessDescription"}
                        category={4}
                        getListAPI={CommonAPI.getNoticeList}
                    />
                </div>
            </div>
            <div className="mo">
                <div className="businessDescriptionMain">
                    <div className="businessDescriptionMain_top">
						<h2 className="businessDescriptionMain_title">
                            사업설명회 동영상
						</h2>
						<p className="businessDescriptionMain_subtitle">
                            정부, 지자체, 공공기관의 모든 사업 설명을<br /> 동영상으로 만날 수 있습니다.
						</p>
					</div>
					<div className="businessDescriptionMain_content">
						<div className="businessDescriptionMain_content_list-wrap">
							<ContentPhotoList
								title={"사업설명회 동영상"}
								styleHandler={"m-col-1-2"}
								path={"/businessDescription"}
								category={4}
								getListAPI={CommonAPI.getNoticeList}
							/>
						</div>
					</div>
                </div>
            </div>
        </div>
    )
}

export default BusinessDescriptionMain;