import API from '../../util/api';

export default {
    getSortField() {
        return API.get('/manager/patent/cost/field', {params: {cache: true}});
    },
    getSortFieldAsAgent(company_idx) {
        return API.get(`/agent/company/${company_idx}/patent/cost/field`, {params: {cache: true}});
    },
    getPatentCostList(payload) {
        return API.get('/manager/patent/cost', {params: payload});
    },
    getPatentCostType(company_idx){
        return API.get(`/agent/company/${company_idx}/patent/cost/type`);
    },
    getAgentPatentCostList(company_idx, payload) {
        return API.get(`/agent/company/${company_idx}/patent/cost`, {params: payload});
    },
    getTradeMarkCostList(payload) {
        return API.get('/manager/trademark/cost', {params: payload});
    },
    getAgentTradeMarkCostList(company_idx, payload) {
        return API.get(`/agent/company/${company_idx}/trademark/cost`, {params: payload});
    },
    getDesignCostList(payload) {
        return API.get('/manager/design/cost', {params: payload});
    },
    getDesignCostListAsAgent(company_idx, payload) {
        return API.get(`/agent/company/${company_idx}/design/cost`, {params: payload});
    },

    getPatentBilling(patent_idx, cost_idx) {
        return API.get(`/manager/patent/${patent_idx}/cost/${cost_idx}`);
    },
    getAgentPatentBilling(company_idx, patent_idx, cost_idx) {
        return API.get(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}`);
    },
    getTradeMarkBilling(patent_idx, cost_idx) {
        return API.get(`/manager/trademark/${patent_idx}/cost/${cost_idx}`);
    },
    getDesignBilling(design_idx, cost_idx) {
        return API.get(`/manager/design/${design_idx}/cost/${cost_idx}`);
    },
    getAgentTradeMarkBilling(company_idx, patent_idx, cost_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${patent_idx}/cost/${cost_idx}`);
    },
    getDesignBillingAsAgent(company_idx, design_idx, cost_idx) {
        return API.get(`/agent/company/${company_idx}/design/${design_idx}/cost/${cost_idx}`);
    },
    CostConfirm(patent_idx, cost_idx, payload) {
        return API.patch(`/manager/patent/${patent_idx}/cost/${cost_idx}`, payload);
    },
    CostConfirmTradeMark(trademark_idx, cost_idx, payload) {
        return API.patch(`/manager/trademark/${trademark_idx}/cost/${cost_idx}`, payload);
    },
    CostConfirmDesign(design_idx, cost_idx, payload) {
        return API.patch(`/manager/design/${design_idx}/cost/${cost_idx}`, payload);
    },
    getPatentPaySuccess(patent_idx, cost_idx) {
        return API.post(`/manager/patent/${patent_idx}/cost/${cost_idx}/pay`);
    },
    getTradeMarkPaySuccess(patent_idx, cost_idx) {
        return API.post(`/manager/trademark/${patent_idx}/cost/${cost_idx}/pay`);
    },
    getDesignPaySuccess(patent_idx, cost_idx) {
        return API.post(`/manager/design/${patent_idx}/cost/${cost_idx}/pay`);
    },
    addPatentBillingAsAgent (company_idx, patent_idx, payload) {
        return API.post(`/agent/company/${company_idx}/patent/${patent_idx}/cost`, payload);
    },
    updatePatentBillingAsAgent (company_idx, patent_idx, cost_idx, payload) {
        return API.patch(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}`, payload);
    },
    addTradeMarkBillingAsAgent (company_idx, trademark_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost`, payload);
    },
    addDesignBillingAsAgent (company_idx, design_idx, payload) {
        return API.post(`/agent/company/${company_idx}/design/${design_idx}/cost`, payload);
    },
    updateTradeMarkBillingAsAgent (company_idx, trademark_idx, cost_idx, payload) {
        return API.patch(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost/${cost_idx}`, payload);
    },
    updateDesignBillingAsAgent (company_idx, design_idx, cost_idx, payload) {
        return API.patch(`/agent/company/${company_idx}/design/${design_idx}/cost/${cost_idx}`, payload);
    },
    // 파일 관련 api
    getPatentCostFileList(patent_idx, cost_idx, payload) {
        return API.get(`/manager/patent/${patent_idx}/cost/${cost_idx}/file`, {params: payload});
    },
    getAgentPatentCostFileList(company_idx, patent_idx, cost_idx, payload) {
        return API.get(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}/file`, {params: payload});
    },
    getAgentTradeMarkCostFileList(company_idx, patent_idx, cost_idx, payload) {
        return API.get(`/agent/company/${company_idx}/trademark/${patent_idx}/cost/${cost_idx}/file`, {params: payload});
    },
    getAgentDesignCostFileList(company_idx, design_idx, cost_idx, payload) {
        return API.get(`/agent/company/${company_idx}/design/${design_idx}/cost/${cost_idx}/file`, {params: payload});
    },
    downloadPatentCostFile(patent_idx, cost_idx, idx) {
        window.$Global.download(`/manager/patent/${patent_idx}/cost/${cost_idx}/file/${idx}/download`);
    },
    downloadTradeMarkCostFile(trademark_idx, cost_idx, idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/cost/${cost_idx}/file/${idx}/download`);
    },
    downloadAgentPatentCostFile(company_idx, patent_idx, cost_idx, file_idx) {
        window.$Global.download(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}/file/${file_idx}/download`);
    },
    downloadAgentTradeMarkCostFile(company_idx, trademark_idx, cost_idx, file_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost/${cost_idx}/file/${file_idx}/download`);
    },
    downloadDesignCostFileAsAgent(company_idx, design_idx, cost_idx, file_idx) {
        window.$Global.download(`/agent/company/${company_idx}/design/${design_idx}/cost/${cost_idx}/file/${file_idx}/download`);
    },
    uploadPatentCostFile(company_idx, patent_idx, cost_idx, payload) {
        return API.post(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}/file`, payload);
    },
    uploadTradeMarkCostFile(company_idx, trademark_idx, cost_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost/${cost_idx}/file`, payload);
    },
    uploadDesignCostFile(company_idx, design_idx, cost_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${design_idx}/cost/${cost_idx}/file`, payload);
    },
    getPatentCostBilling(company_idx, patent_idx, cost_idx) {
        return API.post(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}/billing`);
    },
    deletePatentAgentFile(company_idx, patent_idx, cost_idx, file_idx) {
        return API.delete(`/agent/company/${company_idx}/patent/${patent_idx}/cost/${cost_idx}/file/${file_idx}`);
    },
    deleteTradeMarkAgentFile(company_idx, trademark_idx, cost_idx, file_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost/${cost_idx}/file/${file_idx}`);
    },
    deleteDesignFile(company_idx, design_idx, cost_idx, file_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${design_idx}/cost/${cost_idx}/file/${file_idx}`);
    },
    getTradeMarkCostBilling(company_idx, trademark_idx, cost_idx) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/cost/${cost_idx}/billing`);
    },
    getDesignCostBilling(company_idx, design_idx, cost_idx) {
        return API.post(`/agent/company/${company_idx}/design/${design_idx}/cost/${cost_idx}/billing`);
    },
};

