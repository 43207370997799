import React from "react";
import "./css/GovSupportMain.scss";
import CommonAPI from "../../API/common";
import ContentPhotoList from "../common/ContentPhotoList";

function GovSupportMain() {
	return (
		<div id="govSupportMain">
			<div className="pc">
				<div className="govSupportMain">
					<div className="govSupportMain_box-wrap">
						<div className="govSupportMain_box">
							<div className="govSupportMain_box_title">정부 지원 정책 알림</div>
							<p className="govSupportMain_box_sub">정부,공공,지자체에서 지원하는 다양한 지원 정책을 활용하여 회사를 운영하시는데 <br /> 많은 도움을 받아 보세요.</p>
						</div>
						{/*<button type="button" className="govSupportMain_box_btn">*/}
						{/*	튜토리얼 바로가기*/}
						{/*</button>*/}
					</div>
					<ContentPhotoList
						title={"정부 지원 정책 알림"}
						styleHandler={"col-1-4"}
						path={"/govSupport"}
						category={5}
						getListAPI={CommonAPI.getNoticeList}
					/>
				</div>
			</div>
			<div className="mo">
				<div className="govSupportMain">
					<div className="govSupportMain_top">
						<h2 className="govSupportMain_title">
							정부 지원 정책 알림
						</h2>
						<p className="govSupportMain_subtitle">
							정부·공공·지자체에서 지원하는 다양한 지원 정책을 활용해<br />회사 운영에 많은 도움을 받아 보세요.
						</p>
					</div>
					<div className="govSupportMain_content">
						<div className="govSupportMain_content_list-wrap">
							<ContentPhotoList
								title={"정부 지원 정책 알림"}
								styleHandler={"m-col-1-1"}
								path={"/govSupport"}
								category={5}
								getListAPI={CommonAPI.getNoticeList}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GovSupportMain;