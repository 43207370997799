import "./css/ResearchTechMyKeyword.scss";
import CommonAPI from "../../../API/common";
import React, { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import { useHistory } from "react-router-dom";
import ResearchTechMySideBar from "./ResearchTechMySideBar";

const ResearchTechMyKeyword = () => {
  const [data, setData] = useState();
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getList();
  }, [curPage]);

  const getList = () => {
    let payload = {
      page: curPage,
      count: 10,
    };

    CommonAPI.getMyKeywordList(payload).then((res) => {
      setData(res.data);
      setLast(res.data.last);
    });
  };

  const deleteKeyword = (idx) => {
    CommonAPI.deleteMyKeyword(idx).then(() => {
      alert("삭제되었습니다.");
      window.location.reload();
    });
  };

  return (
    <div id="ResearchTechMyKeyword">
      <ResearchTechMySideBar />
      <div className="keyword_wrapper">
        <h2>
          키워드 검색 내역 확인하기
          <br />
          <span>선행기술 키워드로 조사한 내역 확인하기</span>
        </h2>
        <table>
          <colgroup>
            <col width={60} />
            <col width={170} />
            <col width={400} />
            <col width={40} />
            <col width={170} />
            <col width={100} />
            <col width={50} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>분류</th>
              <th>키워드 명</th>
              <th>재검색</th>
              <th>검색 일시</th>
              <th>건수</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {data?.items.map((item, idx) => {
              return (
                <tr>
                  <td>{(curPage - 1) * 10 + idx + 1}</td>
                  <td>키워드 검색</td>
                  <td>{item.original_keyword.toUpperCase()}</td>
                  <td>
                    <button
                      className="icon_arrow_right_s_with_bg"
                      onClick={() => {
                        history.push({
                          pathname: "/researchTech/category",
                          state: {
                            keyword: item.original_keyword,
                            isKeywordSearch: 2,
                          },
                        });
                      }}
                    />
                  </td>
                  <td>
                    {window.$Global.convertDateTimeSecond(item.created_at)}
                  </td>
                  <td>{item.result_cnt}</td>
                  <td>
                    <button
                      className="icon_del_trashcan"
                      onClick={() => deleteKeyword(item.idx)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      </div>
    </div>
  );
};

export default ResearchTechMyKeyword;
