import React, {useContext} from "react";
import {Link} from "react-router-dom";
import "./css/MobileMore.scss";
import links from "../../const/links";
import memberContext from "../../store/Member";

function MobileMore() {
	const {idx, name} = useContext(memberContext);

	const onClickChangeView = () => {
		window.$Global.toggleViewType();
	};
	return (
		<div id="MobileMore">
			<div className="account_area">
				<div className="info">
					<p>{idx ? `${name}님` : '로그인해주세요'}</p>
					{
						idx
						&&
						<Link to={links.mypage.mobile.edit}>
							<img src={require('../../assets/images/common/icon-more-setting.svg').default} alt="마이페이지 아이콘"/>
						</Link>
					}
				</div>
				<button className="btn_change" onClick={onClickChangeView}>
					<p>아이피나우 전환</p>
					<i className="icon_more_arrow_right_white"/>
				</button>
			</div>
			<div className="box">

			</div>

			<div className="btns">
				<Link to={links.intro.service}>고객센터</Link>
				<Link to={links.notify.list}>공지사항</Link>
			</div>
		</div>
	)
}

export default MobileMore;