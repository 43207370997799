import { Link } from "react-router-dom/cjs/react-router-dom";

const Financing = ({ runway = "" }) => {
  const companyName = localStorage.getItem("company_name");
  const links = [
    {
      title: "투자사 컨택하러 가기",
      text:
        runway === ""
          ? ""
          : `${companyName}는 ${runway}개월 내로 투자유치가 필요할 것으로 예측됩니다.`,
      link: "/cashflow/find/investor",
    },
    {
      title: "IP담보대출 준비하러 가기",
      text:
        runway === ""
          ? ""
          : `${companyName}는 ${runway}개월 내로 대출이 필요할 것으로 예측됩니다.`,
      link: "/mortgageLoan/list",
    },
    {
      title: "대출은행 알아보기",
      text:
        runway === ""
          ? ""
          : `${companyName}는 ${runway}개월 내로 대출이 필요할 것으로 예측됩니다.`,
      link: "/cashflow/find/bank",
    },
    {
      title: "상표 판매하기",
      text:
        runway === ""
          ? ""
          : `${companyName}는 ${runway}개월 내로 현금확보가 필요할 것으로 예측됩니다.`,
      link: "/trade/list",
    },
    {
      title: "자금조달 지원사업 알아보기",
      text: "정부의 융자지원사업을 확인하여 자금조달 계획을 수립해보세요.",
      link: "/taskManage/part/list?keyword=8",
    },
  ];

  return (
    <div className="financing">
      <strong>자금조달 정보</strong>
      <ul className="link_list">
        {links.map((item, idx) => (
          <li key={idx}>
            <Link to={item.link}>
              <div>
                <strong>{item.title}</strong>
                <p>{item.text}</p>
                <div className="go">
                  <strong>바로가기&rarr;</strong>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Financing;
