import React, {useEffect, useState} from "react";
import "./css/DocTradeMyTransactionUsage.scss";
import DocTradeMyTransactionUsageList from './DocTradeMyTransactionUsageList';
import TemplateAPI from "../../API/template";
import DocTradeSelectDate from "./common/DocTradeSelectDate";

function DocTradeMyTransactionUsage() {
    const [curPage, setCurPage] = useState(1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [resData, setResData] = useState({items: []});

    const getList = (params) => {
        let payload = {
            page: curPage,
            count: 100,
            start_at: startDate,
            end_at: endDate,
            ...params
        };
        TemplateAPI.getPointUsage(payload).then(res => setResData(res.data));
    };

    const onChangeDate = (sdate, edate) => {
        setStartDate(sdate);
        setEndDate(edate);
    }

    return (
        <div id="docTradeMyTransactionUsage" >
            <div className="usage">
                <div className="usage_content">
                    <div className="usage_content_title-wrap">
                        <h2 className="usage_content_title">포인트 거래 내역</h2>
                        <p className="usage_content_info">거래내역은 전자금융거래법에 의해 삭제가 불가하며, 최근 6개월 이내 거래내역만 확인 가능합니다.</p>
                    </div>
                    <div className="usage_content_table">
                        <DocTradeSelectDate getList={getList}
                                            onChangeDate={onChangeDate}
                                            setCurPage={setCurPage}
                        />
                        <table className="usage_content_table_content">
                            <caption className="hidden">포인트 사용 내역</caption>
                            <colgroup>
                                <col className="number" width="40px"/>
                                <col className="title" width="420px"/>
                                <col className="division" width="80px"/>
                                <col className="holding-points" width="140px"/>
                                <col className="date" width="120px"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">순 번</th>
                                    <th scope="col" className="style">수입/지출 내역</th>
                                    <th scope="col">구분</th>
                                    <th scope="col">거래 포인트</th>
                                    <th scope="col">날&nbsp;&nbsp;짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                <DocTradeMyTransactionUsageList data={resData}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocTradeMyTransactionUsage;