import React from "react";
import ApprovalList from "../components/approval/ApprovalList";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";

function ApprovalRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={ApprovalList}/>
		</CustomSwitch>
	)
}

export default ApprovalRoute;