import API from '../../util/api';

export default {
    getStatistic(payload) {
        return API.get('/manager/company/statistic', {params: payload});
    },
    getStatisticAgent(company_idx) {
        return API.get(`/agent/company/${company_idx}/statistic`);
    },
    getStatisticPaymentHistory() {
        return API.get(`/test/statistic/paymentHistory`);
    },
    getStatisticTemplateTradeHistory() {
        return API.get(`/test/statistic/templateTradeHistory`);
    },
    insertPaymentHistory(payload) {
        return API.post('/test/statistic/paymentHistory', payload);
    },
    deletePaymentHistory(payload) {
        return API.delete('/test/statistic/paymentHistory', {data: payload});
    }
};
