import React, {useEffect, useRef, useState} from "react";
import "./ChartTest.scss";
import {Line} from 'react-chartjs-2';

const data = {
    labels: 'a,b,c,d,e'.split(','),
    datasets: [
        {
            label: '출원건수',
            data: [1, 2, 3, 4],
            borderColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: '등록건수',
            data: [3, 4, 6, 7],
            borderColor: 'rgba(53, 162, 235, 0.5)',
        }
    ],
};

const colorList = [];

function ChartTest() {
    const [rowLabelInputList, setRowLabelInputList] = useState('')
    const [columnLabelInputList, setColumnLabelInputList] = useState('')
    const [inputList, setInputList] = useState('');
    const chartRef = useRef(null);
    const colorInputRef = useRef(null);
    const [showColorChangeInput, setShowColorChangeInput] = useState(false);
    const [inputColor, setInputColor] = useState('#111111');
    const [targetIndex, setTargetIndex] = useState(null);

    const [dataSet, setDataSet] = useState([
        [1, 2, 3, 4],
        [1, 2, 3, 4],
    ]);

    const [chartData, setChartData] = useState(data);

    const [rowLabelList, setRowLabelList] = useState([]);
    const [columnLabelList, setColumnLabelList] = useState([]);

    const onChangeValue = (e, i, j) => {
        dataSet[i][j] = e.target.value;

        setDataSet([...dataSet]);
    };

    const showColorChangeDiv = (index) => {
        setShowColorChangeInput(true);
        const color = colorList[index];

        const rgb = color.substring(color.indexOf('rgba(')+'rgba('.length, color.lastIndexOf(',')).split(',');

        const hex = rgb.reduce((hexColor, v) => {
            return hexColor += parseInt(v).toString(16).padEnd(2, '0');
        }, '#');

        setTargetIndex(index);
        setInputColor(hex);
    };

    const cancelColorChange = () => {
        setShowColorChangeInput(false);
    };

    const confirmColorChange = () => {
        const colorHex = inputColor.substring(1);

        colorList[targetIndex] = 'rgba(' + parseInt(colorHex.substring(0,2), 16) + ',' + parseInt(colorHex.substring(2,4), 16) + ',' + parseInt(colorHex.substring(4,6), 16) + ', 0.5)'

        renderChart();

        setShowColorChangeInput(false);
    };

    const renderChart = () => {
        const tempRowLabelInputList = [];
        const tempColumnLabelInputList = [];

        setInputList(dataSet.map((list, i) => {
            tempRowLabelInputList.push(
                <input key={`rowLabelInput_${i}`} type="text" defaultValue={rowLabelList[i] || `list ${i}`} onChange={(e) => {
                    rowLabelList[i] = e.target.value;
                    setRowLabelList([...rowLabelList]);
                }}/>
            )

            return <div className="input_wrap" key={`div_${i}`}>{
                list.map((v, j) => {
                    return <input key={`${i}, ${j}`}
                                  className="chart_input"
                                  type="number" defaultValue={v} onChange={(e) => onChangeValue(e, i, j)}/>
                })
            }
                <button onClick={() => showColorChangeDiv(i)}>색상 변경</button>
            </div>
        }));

        setRowLabelInputList(tempRowLabelInputList);

        for(let i=0 ; i<dataSet[0].length ; i++){
            if(columnLabelList[i] === undefined) columnLabelList[i] = `column ${i}`
            tempColumnLabelInputList.push(
                <input
                    key={`columnLabelInput_${i}`} type="text" defaultValue={columnLabelList[i] || `column ${i}`} onChange={(e) => {
                    columnLabelList[i] = e.target.value;
                    setColumnLabelList([...columnLabelList]);
                }}/>
            )
        }

        setColumnLabelInputList(tempColumnLabelInputList)

        const labels = columnLabelList;
        const datasets = [];

        let cnt = -1;
        for(const d of dataSet){
            cnt++;
            const obj = {};
            let color = colorList[cnt];

            if(!color){
                color = colorCreator();
                colorList[cnt] = color;
            }

            obj.label = rowLabelList[cnt] || `list ${cnt}`;
            obj.data = d;
            obj.borderColor = color;

            datasets.push(obj);
        }

        setChartData({labels, datasets});

        chartRef.current.chartInstance.chart.update();
    };

    useEffect(() => {
        renderChart();
    },  [ dataSet, rowLabelList, columnLabelList ]);

    const colorCreator = () => {
        return `rgba(${(Math.random()*255)+1}, ${(Math.random()*255)+1}, ${(Math.random()*255)+1}, 0.5)`
    };

    const controller = (target, action) => {
        if(target === '행'){
            if(action === '+'){
                dataSet.push('1'.repeat(dataSet[0].length).split(''));
            }else{
                if(dataSet.length <= 1){
                    alert('더이상 행을 삭제할 수 없습니다.');
                    return;
                }
                dataSet.pop();
            }
        }else{

            for(let i=0 ; i<dataSet.length ; i++) {
                if (action === '+') {
                    dataSet[i].push(1);
                }else{
                    if(dataSet[i].length <= 2){
                        alert('더이상 열을 삭제할 수 없습니다.');
                        return;
                    }
                    columnLabelList.pop();
                    dataSet[i].pop();
                }
            }
        }

        setDataSet([...dataSet]);
    };

    return (
        <div id="TestChart">
            <div className="chart_wrap">
                <Line
                    ref={chartRef}
                    height={250}
                    data={{...chartData}}
                />
            </div>

            <div className="button_wrap">
                <button onClick={() => controller('행', '+')}>행 추가</button>
                <button onClick={() => controller('행', '-')}>행 삭제</button>
                <button onClick={() => controller('열', '+')}>열 추가</button>
                <button onClick={() => controller('열', '-')}>열 삭제</button>
            </div>

            <div className="row_label_input_wrap">
                {
                    rowLabelInputList
                }
            </div>

            <div className="column_label_input_wrap">
                {
                    columnLabelInputList
                }
            </div>

            <div className="input_list_wrap">
                {
                    inputList
                }
            </div>

            {
                showColorChangeInput ?
                <div className="color_input">
                    <input ref={colorInputRef} type="color" value={inputColor} onChange={(e) => {
                        setInputColor(e.target.value);
                    }
                    }/>
                    <button onClick={confirmColorChange}>변경하기</button>
                    <button onClick={cancelColorChange}>취소</button>
                </div>
                    :
                    ''
            }
        </div>
    );
}

export default ChartTest;
