import GroupwareSideBar from "../../groupware/GroupwareSideBar";
import { groupwareMypagePath } from "../../groupware/groupwarePath";
import CompanyAPI from "../../../API/company";
import ".././css/CompetitorInterest.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Pagination from "../../common/Pagination";

const CompetitorInterest = () => {
  const memberIdx = localStorage.getItem("idx");
  const [interestList, setInterestList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [last, setLast] = useState(1);

  const getInterest = () => {
    CompanyAPI.getInterest({
      owner_idx: memberIdx,
      page: page,
      count: count,
      flag: 0,
    }).then((res) => {
      //console.log("getInterest:", res);
      if (res.data?.code === "00") {
        setInterestList(res.data?.data?.interestData || []);
        setLast(res.data?.data?.last || 1);
      }
    });
  };

  const deleteInterest = (interest_idx, biz_no) => {
    return new Promise((resolve, reject) => {
      //console.log("interest_idx:", interest_idx);
      CompanyAPI.deleteClt({
        owner_idx: memberIdx,
        idx: interest_idx,
        biz_no: biz_no,
        flag: 0,
      })
        .then((res) => {
          //console.log("deleteInterest:", res);
          if (res.data?.code === "00") {
            resolve(res);
          } else {
            reject(new Error("Failed to delete interest"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    getInterest();
  }, []);

  useEffect(() => {
    getInterest();
  }, [page]);

  const handleCheckboxChange = (index, biz_no) => {
    const selectedIndex = selectedItems.findIndex(
      (item) => item.index === index
    );
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, { index, biz_no }]);
    } else {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedSelectedItems);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedItems.length) {
      const confirmResult = window.confirm("관심기업을 삭제하시겠습니까?");
      if (confirmResult) {
        const deletePromises = selectedItems.map((item) => {
          //console.log("item:", item);
          return deleteInterest(item.index, item.biz_no);
        });

        Promise.all(deletePromises)
          .then(() => {
            setSelectedItems([]);
            getInterest();
          })
          .catch((error) => {
            console.error("Error deleting interests:", error);
          });
      }
    } else {
      alert("삭제할 관심기업을 선택해주세요.");
    }
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupwareMypagePath} />
      <div className="commute_wrapper">
        <div id="CompetitorInterest">
          <h3 className="cont_title">관심기업</h3>
          <p className="cont_sub_title">
            관심기업 지정한 기업 리스트를 확인할 수 있습니다.
          </p>
          <div className="btn_box">
            <button className="del" onClick={handleDeleteSelected}>
              관심기업 삭제
            </button>
          </div>
          <div className="bg_wh interest">
            <table>
              <colgroup>
                <col width={60} />
              </colgroup>
              <thead>
                <tr>
                  <th>선택</th>
                  <th>기업명</th>
                  <th>대표자</th>
                  <th>소재지</th>
                  {/* <th>기업형태</th> */}
                  <th>바로가기</th>
                </tr>
              </thead>
              <tbody>
                {interestList?.length ? (
                  interestList.map((list, index) => (
                    <tr key={list.idx}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedItems.some(
                            (item) => item.index === list.interest_idx
                          )}
                          onChange={() =>
                            handleCheckboxChange(list.interest_idx, list.biz_no)
                          }
                        />
                      </td>
                      <td>{list.company_name}</td>
                      <td>{list.company_ceo}</td>
                      <td>{list.company_addr}</td>
                      <td>
                        {list.applicant && list.biz_no ? (
                          <Link
                            to={`/competitor/details?applicant=${list.applicant}&bizno=${list.biz_no}`}
                            className="url"
                          >
                            조회
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {interestList?.length ? (
              <Pagination curPage={page} lastNum={last} onClick={setPage} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorInterest;
