import React, { useContext, useState } from "react";
import "./MobilePCServiceAlert.scss";
import CheckBox from "../common/CheckBox";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";
import CommonAPI from "../../API/common";
import login from "../login/Login";

function MobilePCServiceAlert() {
  const { isMobile } = useContext(CommonContext);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const [moHideSevenDayCheck, setMoHideSevenDayCheck] = useState(false);
  const [pcHideSevenDayCheck, setPcHideSevenDayCheck] = useState(false);
  const [moHide, setMoHide] = useState(false);
  const [pcHide, setPcHide] = useState(false);

  const onMoClickClose = () => {
    if (moHideSevenDayCheck) {
      window.$Global.setCookie("popup_mo", "moHide", 1);
    }
    setMoHide(true);
  };

  const onPcClickClose = () => {
    if (pcHideSevenDayCheck) {
      window.$Global.setCookie("popup_pc", "pcHide", 1);
    }
    setPcHide(true);
  };

  if (
    Boolean(window.$Global.getCookie("popup_mo")) ||
    moHide ||
    isViewTypeIpnow
  )
    return null;

  if (
    Boolean(window.$Global.getCookie("popup_pc")) ||
    pcHide ||
    isViewTypeIpnow
  )
    return null;

  const clickCount = () => {
    CommonAPI.addVisitCount({
      type: 1,
    }).then((res) => console.log(res));
  };

  return (
    !isMobile && (
      <div id="MobilePCServiceAlert">
        {/*{isMobile ? (*/}
        {/*  <div className="popup">*/}
        {/*    <div className="content">*/}
        {/*      <img*/}
        {/*        src={*/}
        {/*          require("../../assets/images/common/logo_findBiz.svg").default*/}
        {/*        }*/}
        {/*        className="logo"*/}
        {/*        alt="이미지"*/}
        {/*      />*/}
        {/*      <p className="title">정부사업 수주 필수템</p>*/}
        {/*      <h2 className="desc1">*/}
        {/*        <span>정리된 공고</span>부터 <b>제안서에 필요한</b>*/}
        {/*        <br />*/}
        {/*        <span>시장정보</span>와 <span>선행기술</span>까지*/}
        {/*        <br />*/}
        {/*      </h2>*/}
        {/*      <p className="desc2">*/}
        {/*        정부사업 수주의 A to Z,*/}
        {/*        <br />*/}
        {/*        비즈내비에서 모두 확인하세요.*/}
        {/*      </p>*/}
        {/*      <Link*/}
        {/*        to="/signup/type"*/}
        {/*        className="btn_join"*/}
        {/*        onClick={() => setMoHide(true)}*/}
        {/*      >*/}
        {/*        비즈내비 가입하기*/}
        {/*      </Link>*/}

        {/*      <p className="notify">*/}
        {/*        <b>비즈내비</b>는 <b>PC</b>에서 이용하시면 더욱 좋습니다.*/}
        {/*      </p>*/}
        {/*      <img*/}
        {/*        src={require("../../assets/images/common/bg1.svg").default}*/}
        {/*        className="bg bg1"*/}
        {/*        alt="이미지"*/}
        {/*      />*/}
        {/*      <img*/}
        {/*        src={require("../../assets/images/common/bg2.svg").default}*/}
        {/*        className="bg"*/}
        {/*        alt="이미지"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="btns">*/}
        {/*      <div className="btn">*/}
        {/*        <CheckBox*/}
        {/*          text="오늘은 더 이상 보지 않기"*/}
        {/*          checked={moHideSevenDayCheck}*/}
        {/*          onChangeChecked={() =>*/}
        {/*            setMoHideSevenDayCheck(!moHideSevenDayCheck)*/}
        {/*          }*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div className="btn_close" onClick={onMoClickClose}>*/}
        {/*        <i className="icon_exit" />*/}
        {/*        <p>닫기</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : (*/}
        <div className="pc_popup pc">
          <div className="content">
            <div className="notice">공 지</div>
            <div className="padding_content">
              <h2>
                안녕하세요, 비즈내비 입니다.
                <br />
                <span>
                  2차 시스템 확장 작업으로 인해 일부 서비스(특허검색)에 일시적인
                  장애가 발생 할 수 있습니다.
                </span>
              </h2>
              <div className="content_info">
                <div>
                  <p>중 단 일 시</p>
                  <p>2023.12.01(금) 18:00</p>
                </div>
                <div>
                  <p>복 구 일 시</p>
                  <p>2023.12.04(월) 09:00</p>
                </div>
                <div>
                  <p>대상 서비스</p>
                  <p>특허/기술 트렌드 조사</p>
                </div>
                <div className="detail_info">
                  <p>내 용 상 세</p>
                  <div>
                    <p>- 2차 시스템 확장 작업</p>
                    <p>&emsp;1. 네트웍 인프라 확장</p>
                    <p>&emsp;2. 스위칭 장비 고도화</p>
                  </div>
                </div>
              </div>
              <h5>
                더욱 편리한 서비스를 제공할 수 있도록 최선을 다하겠습니다.
                <br />
                감사합니다.
              </h5>
              <div className="btns">
                <div className="btn">
                  <CheckBox
                    text="오늘은 더 이상 보지 않기"
                    checked={pcHideSevenDayCheck}
                    onChangeChecked={() =>
                      setPcHideSevenDayCheck(!pcHideSevenDayCheck)
                    }
                  />
                </div>
                <div className="btn_close" onClick={onPcClickClose}>
                  <i className="icon_exit" />
                  <p>닫기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*)}*/}
      </div>
    )
  );
}

export default MobilePCServiceAlert;
