import React, {useEffect, useState} from "react";
import "./css/CyberMyPoint.scss";
import {Link} from "react-router-dom";
import CyberAPI from "../../API/cyber";

function CyberMyPoint() {
    const [resData, setResData] = useState({log: []});
    useEffect(() => {
        CyberAPI.getMyPoint({page:1, count: 1000}).then(res => setResData(res.data));
    }, []);

    return (
        <div id="CyberMyPoint">
            <h2 className="page_title">내 포인트</h2>
            <p className="desc">
                내가 등록한 기업이 가입하고<br/>
                스탠다드 요금제를 결제하면 리워드가 지급됩니다.
            </p>
            <p className="sum_point">{window.$Global.commaify(resData.amount || 0)}P</p>

            <ul className="tabs">
                <li>
                    <Link to="/cyber/account">
                        계좌번호 관리
                   </Link>
                </li>
                <li>
                    <Link to="/cyber/pointHistory">
                        인출내역
                    </Link>
                </li>
            </ul>

            <Link to="/cyber/pointChange" className="btn btn_point_change">포인트 전환하기</Link>

            <div className="wrap_list">
                <h2>포인트 적립 내역</h2>
                <ul>
                    {
                        resData.log.map((item, idx) => {
                            return (
                                <li key={idx}>
                                    <div>
                                        <h2>{item.content}</h2>
                                        <p>{window.$Global.convertDate(item.created_at)}</p>
                                    </div>
                                    <p className={`point ${item.type ? 'minus' : 'add'}`}>{(item.type ? '-' : '+') + window.$Global.commaify(item.amount)}</p>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default CyberMyPoint;