import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-chart-treemap";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.plugins.unregister(ChartDataLabels); // datalabels 충돌나서 해제

const Treemap = ({ chartData }) => {
  const chartRef = useRef(null);
  const treeData = chartData;

  const colors = [
    "#6A89E5",
    "#85C1FF",
    "#9AE3FF",
    "#5BB195",
    "#A3D7C1",
    "#AAD07C",
    "#F9D85B",
    "#FF9E3C",
    "#F17474",
    "#FEC1C1",
    "#FFB84F",
  ];

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colorFromValue = (value, border) => {
    const index = value;
    // colors 순서대로 배경색 설정
    if (index !== -1 && index < colors.length) {
      return colors[index];
      // colors 개수 벗어나면 랜덤으로
    } else {
      return generateRandomColor();
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (treeData.length) {
        new Chart(ctx, {
          type: "treemap",
          data: {
            datasets: [
              {
                label: "Sample",
                tree: treeData,
                key: "value",
                groups: ["title"],
                fontColor: "#fff",
                spacing: 0,
                backgroundColor: function (ctx) {
                  return colorFromValue(ctx.dataIndex);
                },
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "특구별 분포",
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                title: function (item, data) {
                  return data.datasets[item[0].datasetIndex].title;
                },
                label: function (item, data) {
                  var dataset = data.datasets[item.datasetIndex];
                  var dataItem = dataset.data[item.index];
                  return dataItem.g + " " + dataItem.v;
                },
              },
            },
          },
        });
      }
      return () => {
        ctx && ctx.chart && ctx.chart.destroy();
      };
    }
  }, []);

  return (
    <div>
      <canvas ref={chartRef} height={"400px"}></canvas>
    </div>
  );
};

export default Treemap;
