import "./css/CompetitorDetails.scss";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut, HorizontalBar, Line } from "react-chartjs-2";
import logo from "../../assets/images/common/logo_findBiz.svg";
import { useLocation, useParams } from "react-router-dom";
import CommonAPI from "../../API/common";
import InfoAPI from "../../API/info";
import CompanyAPI from "../../API/company";
import GradeCompany from "../grade/GradeCompany";
import qs from "query-string";
import axios from "axios";

const CompetitorIpnowDetails = ({ history }) => {
  const location = useLocation();
  const { applicant, bizno } = qs.parse(location.search);
  const [companyCountData, setCompanyCountData] = useState({});
  const [patentList, setPatentList] = useState([]);
  const [trademarkList, setTrademarkList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [companySkillList, setCompanySkillList] = useState([]);
  const [gradeData, setGradeData] = useState({});

  const [finance, setFinance] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [companyFinance, setCompanyFinance] = useState({});
  const [companyBidInfo, setCompanyBidInfo] = useState([]);
  const [companyCompList, setCompanyCompList] = useState([]);
  const [companyTechGrade, setCompanyTechGrade] = useState({});
  const [companyTechPatent, setCompanyTechPatent] = useState([]);
  const [companyFirstTag, setCompanyFirstTag] = useState("0");
  const [companyStatistics, setCompanyStatistics] = useState({});

  const handleNavigate = (item) => {
    history.replace({
      pathname: `/competitor/info/${item.info.company_bizno}`,
    });
  };

  useEffect(() => {
    getCompanyInfo();
    if (applicant != 0) {
      getPatentInfo();
    }
  }, []);

  const getCompanyInfo = async () => {
    await axios
      .get(`https://dev.biznavi.co.kr/api/v3/get_company_info`, {
        params: { company: bizno },
      })
      .then((res) => {
        setFinance(Object.values(res.data.finance || {}));
        setCompanyInfo(res.data.info);
        setCompanyFinance(res.data.finance);
        setCompanyBidInfo(res.data.bidinfo);
        setCompanyCompList(res.data.competitorlist);
        setCompanyTechGrade(res.data.tech_grade);
        setCompanyTechPatent(res.data.patent);
        setCompanyFirstTag(res.data.info.grouptags[0]?.company_group_idx || "");
        setCompanyStatistics(res.data.statistics);
      });
  };

  const getPatentInfo = () => {
    InfoAPI.getCompanyPatent({
      company_code: applicant,
      page: 1,
      count: 5,
    }).then((res) => {
      setPatentList(res.data.items);
    });

    InfoAPI.getTradeMark({ company_code: applicant, page: 1, count: 30 }).then(
      (res) => {
        res.data.items.map((item) => {
          let obj = {};
          let classArr = (item.class && item.class.split("|")) || [];
          let productArr = (item.product && item.product.split("|")) || [];
          for (let i = 0; i < classArr.length; i++) {
            obj[classArr[i]] = [
              ...Object.values(obj[classArr[i]] || {}),
              productArr[i],
            ];
          }
          item.classification = obj;
        });
        setTrademarkList(res.data.items);
      }
    );

    InfoAPI.getDesign({ company_code: applicant, page: 1, count: 30 }).then(
      (res) => {
        setDesignList(res.data.items);
      }
    );

    CommonAPI.getIpInfo({ company_code: applicant }).then((res) => {
      setCompanyCountData(res.data);
    });

    // PatentAPI.getGradePatent(idx).then((res) => {
    //   setCompanySkillList(res.data.patent.slice(0, 2));
    // });
  };

  const getCompanySmartGrade = () => {
    CompanyAPI.getCompanyGrade().then((res) => setGradeData(res.data));
  };

  const patentDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.patent_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const trademarkDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.trademark_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const patentDoughnutChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const annualFeeChartData = {
    labels: companyCountData?.annual_pay?.map((item) => item.year + "년"),
    datasets: [
      {
        label: "Data",
        data: companyCountData?.annual_pay?.map((item) => item.cost) || 0,
        borderColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
        backgroundColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
      },
    ],
  };

  const annualFeeChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: "end",
        align: "top",
        font: {
          size: 8,
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: window.$Global.calcGraphMaxNumber(
              companyCountData.annual_pay &&
                companyCountData.annual_pay.map((item) => item.cost)
            ),
            callback: function (value) {
              return window.$Global.commaify(value);
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };

  const yearlyChartData = {
    labels: Object.keys(companyCountData.annual_apply_cnt || {}).map(
      (item) => item.slice(2) + "년"
    ),
    datasets: [
      {
        label: "Data 1",
        type: "line",
        data: Object.values(companyCountData.annual_apply_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#bcec45",
        backgroundColor: "#0095ae",
        pointBackgroundColor: "#0095ae",
        lineTension: 0,
      },
      {
        label: "Data 2",
        type: "line",
        data: Object.values(companyCountData.annual_register_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#ffc500",
        backgroundColor: "#ffac12",
        pointBackgroundColor: "#ffac12",
        lineTension: 0,
      },
    ],
  };

  const yearlyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display:
          Math.max(
            ...Object.values(companyCountData.annual_apply_cnt || {})
              .map((item) => item.cnt)
              .concat(
                Object.values(companyCountData.annual_register_cnt || {}).map(
                  (item) => item.cnt
                )
              )
          ) === 0
            ? false
            : true,
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: window.$Global.calcGraphMaxNumber(
              Object.values(companyCountData.annual_apply_cnt || {})
                .map((item) => item.cnt)
                .concat(
                  Object.values(companyCountData.annual_register_cnt || {}).map(
                    (item) => item.cnt
                  )
                )
            ),
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  //재무정보 차트 데이터
  const profitMixedChartData = {
    labels: ["2019", "2020", "2021", "2022"],
    datasets: [
      {
        label: "자본",
        data: [
          (companyFinance || {})[2019]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTcptAmt?.val / 1000000,
        ],
        borderColor: "#0078f1",
        barThickness: 40,
        order: 2,
        backgroundColor: "#0078f1",
      },
      {
        label: "부채",
        data: [
          (companyFinance || {})[2019]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTdbtAmt?.val / 1000000,
        ],
        borderColor: "#4593f5",
        barThickness: 40,
        order: 3,
        backgroundColor: "#4593f5",
      },
      {
        label: "자산",
        data: [
          (companyFinance || {})[2019]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2019]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2020]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2021]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2022]?.enpTdbtAmt?.val / 1000000,
        ],
        type: "line",
        fill: false,
        order: 1,
        borderWidth: 6,
        borderColor: "rgba(33, 198, 227, 0.35)",
        backgroundColor: "#21c6e3",
        pointBackgroundColor: "#21c6e3",
      },
    ],
  };

  // 손익 계산서 차트 정보
  const profitAndLossMixedChartData = {
    labels: ["2019", "2020", "2021", "2022"],
    datasets: [
      {
        label: "매출액",
        data: [
          (companyFinance || {})[2019]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpSaleAmt?.val / 1000000,
        ],
        borderColor: "#0078f1",
        barThickness: 40,
        order: 2,
        backgroundColor: "#0078f1",
      },
      {
        label: "영업이익",
        data: [
          (companyFinance || {})[2019]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2020]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2021]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2022]?.enpBzopPft?.val / 1000000,
        ],
        borderColor: "#4593f5",
        barThickness: 40,
        order: 3,
        backgroundColor: "#4593f5",
      },
      {
        label: "당기순이익",
        data: [
          (companyFinance || {})[2019]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2020]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2021]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2022]?.enpCrtmNpf?.val / 1000000,
        ],
        type: "line",
        fill: false,
        order: 1,
        borderWidth: 6,
        borderColor: "rgba(33, 198, 227, 0.35)",
        backgroundColor: "#21c6e3",
        pointBackgroundColor: "#21c6e3",
      },
    ],
  };

  const profitMixedChartOption = {
    responsive: false,
    legend: {
      display: true,
      position: "right",
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  // const analysisChartData = {
  //   labels: ["특허건수", "매출", "영업이익", "입찰수", "낙찰수", "낙찰률"],
  //   datasets: [
  //     {
  //       label: "조회 기업",
  //       data: [...myData],
  //       type: "line",
  //       fill: false,
  //       backgroundColor: "rgba(0, 120, 241, 0.35)",
  //       borderColor: "rgba(0, 120, 241, 0.35)",
  //       pointBackgroundColor: "#0078f1",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //     {
  //       label: "평균",
  //       data: [...my1Data],
  //       type: "line",
  //       fill: false,
  //       borderColor: "rgba(0, 221, 119, 0.35)",
  //       pointBackgroundColor: "#00dd77",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //     {
  //       label: "상위 10%",
  //       data: [...my2Data],
  //       type: "line",
  //       fill: false,
  //       borderColor: "rgb(255, 99, 71)",
  //       pointBackgroundColor: "rgb(255, 99, 71)",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //   ],
  // };
  //
  // const analysisChartOption = {
  //   responsive: false,
  //   legend: {
  //     display: true,
  //     position: "bottom",
  //     labels: {
  //       boxWidth: 11,
  //       boxHeight: 11,
  //       usePointStyle: true,
  //     },
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       align: "top",
  //     },
  //   },
  //   scales: {
  //     xAxes: [
  //       {
  //         ticks: {
  //           display: true,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           display: true,
  //           beginAtZero: true,
  //         },
  //         gridLines: {
  //           display: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  const auctionCompanyChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.my?.bid || 0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.my?.win || 0,
        ],
        borderColor: "#0078f1",
        backgroundColor: "#0078f1",
      },
    ],
  };

  const auctionAvgChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["avg"]?.bid ||
            0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["avg"]?.win ||
            0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const auction10AvgChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["10avg"]
            ?.bid || 0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["10avg"]
            ?.win || 0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const salesChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.sales?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#00b894",
        backgroundColor: "#00b894",
      },
    ],
  };

  const profitChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.profit?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.profit?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.profit?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#0984e3",
        backgroundColor: "#0984e3",
      },
    ],
  };

  const techChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["10avg"]
              ?.tgrade
          ) || 0,
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["avg"]?.tgrade
          ) || 0,
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["my"]?.tgrade
          ) || 0,
        ],
        borderColor: "#00cec9",
        backgroundColor: "#00cec9",
      },
    ],
  };

  const patentChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.patent?.[companyFirstTag]?.data?.["10avg"]
            ?.ipcnt || 0,
          companyStatistics?.patent?.[companyFirstTag]?.data?.["avg"]?.ipcnt ||
            0,
          companyStatistics?.patent?.[companyFirstTag]?.data?.["my"]?.ipcnt ||
            0,
        ],
        borderColor: "#6c5ce7",
        backgroundColor: "#6c5ce7",
      },
    ],
  };

  const tradeMarkChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.sales?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const auctionBizChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#b2bec3",
        backgroundColor: "#b2bec3",
      },
    ],
  };

  const auctionCompanyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
          barThickness: 25,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  const auctionMoneyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(Math.floor(value / 1000000));
        },
        color: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
          barThickness: 25,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function (label, index, labels) {
              return Math.round(label / 1000000);
            },
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  const growthFirstChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 매출",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.enpSaleAmt?.val,
          (companyFinance || {})[2020]?.enpSaleAmt?.val,
          (companyFinance || {})[2021]?.enpSaleAmt?.val,
          (companyFinance || {})[2022]?.enpSaleAmt?.val,
        ],
        fill: false,
        borderColor: "rgba(0, 120, 241, 0.35)",
        backgroundColor: "#0078f1",
        lineTension: 0,
        borderWidth: 7,
      },
    ],
  };

  const growthSecondChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 영업이익",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.enpBzopPft?.val,
          (companyFinance || {})[2020]?.enpBzopPft?.val,
          (companyFinance || {})[2021]?.enpBzopPft?.val,
          (companyFinance || {})[2022]?.enpBzopPft?.val,
        ],
        fill: false,
        borderColor: "rgba(0, 221, 119, 0.35)",
        backgroundColor: "#00dd77",
        lineTension: 0,
        borderWidth: 7,
      },
    ],
  };

  const growthThirdChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 부채비율",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.rateDebt?.val,
          (companyFinance || {})[2020]?.rateDebt?.val,
          (companyFinance || {})[2021]?.rateDebt?.val,
          (companyFinance || {})[2022]?.rateDebt?.val,
        ],
        fill: false,
        borderColor: "rgba(255, 166, 0, 0.35)",
        backgroundColor: "#ffa600",
        lineTension: 0,
        borderWidth: 7,
      },
    ],
  };

  const growthFourthChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 영업이익율",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.rateOpm?.val,
          (companyFinance || {})[2020]?.rateOpm?.val,
          (companyFinance || {})[2021]?.rateOpm?.val,
          (companyFinance || {})[2022]?.rateOpm?.val,
        ],
        fill: false,
        borderColor: "rgba(172, 127, 255, 0.35)",
        backgroundColor: "#ac7fff",
        lineTension: 0,
        borderWidth: 7,
      },
    ],
  };

  const growthWonChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(Math.round(value / 1000000));
        },
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            callback: function (value, index, labels) {
              return Math.round(value / 1000000);
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  const growthPercentChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(value) + "%";
        },
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            steps: 10,
            max: 100,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  // const tabs = {
  //   0: useMoveScroll("기업정보"),
  //   1: useMoveScroll("재무·손익"),
  //   2: useMoveScroll("기업 기술력 평가"),
  //   3: useMoveScroll("핵심 기술정보"),
  //   4: useMoveScroll("특허·상표·디자인"),
  //   5: useMoveScroll("정부사업 경쟁률 분석정보"),
  //   6: useMoveScroll("경쟁현황 종합분석"),
  //   7: useMoveScroll("기업가치 성장분석"),
  //   8: useMoveScroll("보도자료"),
  //   length: 9,
  // };

  const emptyChartData = (obj) => {
    if (obj) {
      let arr = Object.values(obj).filter((el) => el !== null);

      return arr.length === 0;
    }
  };

  const emptyFieldData = () => {
    if (
      Object.keys(companyStatistics?.task_cnt || {}).includes("") &&
      Object.keys(companyStatistics?.patent || {}).includes("") &&
      Object.keys(companyStatistics?.sales || {}).includes("") &&
      Object.keys(companyStatistics?.profit || {}).includes("") &&
      Object.keys(companyStatistics?.task_rate || {}).includes("") &&
      companyBidInfo?.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div id="CompetitorInfo">
      <header>
        <div
          className="competitor_title"
          style={{
            width: "1280px",
            margin: "0 auto",
            padding: "35px 150px",
            alignItems: "center",
          }}
        >
          <div style={{ alignItems: "center" }}>
            <div className="oval" />
            <p style={{ marginLeft: "15px" }}>
              {companyCountData?.company_name || companyInfo.company_name}
            </p>
            {/*<i className="icon_share" />*/}
            {/*<i className="icon_fav_on_tack" />*/}
          </div>
          <div>
            <button onClick={() => history.goBack()}>뒤로 돌아가기</button>
          </div>
        </div>
        {/*<div className="info_tab">*/}
        {/*  {Array.from(tabs).map((tab, index) => {*/}
        {/*    return (*/}
        {/*      <p*/}
        {/*        onClick={tab.onMoveToElement}*/}
        {/*        className={index === 0 && "active"}*/}
        {/*      >*/}
        {/*        {tab.name}*/}
        {/*      </p>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
      </header>
      <section>
        <article>
          <h5 className="active">기업정보</h5>
          <div className="competitor_info_grid">
            <div>
              <p>대표자</p>
              <p>{companyInfo.company_ceo}</p>
            </div>
            <div>
              <p>설립일자</p>
              <p></p>
            </div>
            <div>
              <p>고용인원</p>
              <p></p>
            </div>
            <div>
              <p>매출액</p>
              {finance?.length > 0 && (
                <p>
                  {window.$Global.numberToKorean(
                    finance[finance.length - 1].enpSaleAmt?.val
                  )}
                </p>
              )}
            </div>
            <div>
              <p>업종</p>
              <p></p>
            </div>
            <div>
              <p>기업규모</p>
              <p></p>
            </div>
            <div>
              <p>홈페이지</p>
              {/*<a href={companyInfo.homepage} target="_blank">*/}
              {/*  {companyInfo.homepage}*/}
              {/*</a>*/}
            </div>
            <div>
              <p>주소</p>
              <p>{companyInfo.company_addr}</p>
            </div>
          </div>
        </article>
        {companyInfo?.grouptags?.length > 0 && (
          <article>
            <h5>사업목적</h5>
            <div className="analysis">
              {companyInfo.grouptags?.map((keyword) => {
                return <p>{keyword.company_group_name}</p>;
              })}
            </div>
          </article>
        )}
        {/*<article>*/}
        {/*  <h5>조직분석·고용현황</h5>*/}
        {/*  <div className="employee">*/}
        {/*    <div>*/}
        {/*      <Bar*/}
        {/*        data={employeeMixedChartData}*/}
        {/*        options={employeeMixedChartOption}*/}
        {/*        width={310}*/}
        {/*        height={180}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="employee_info">*/}
        {/*      <div>*/}
        {/*        <p>11월 전체 인원수</p>*/}
        {/*        <p>*/}
        {/*          <span>1,298</span>명*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <p>11월 퇴사자 수</p>*/}
        {/*        <p>*/}
        {/*          <span>17</span>명*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <p>11월 입사자 수</p>*/}
        {/*        <p>*/}
        {/*          <span>63</span>명*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <p>연간 퇴사율</p>*/}
        {/*        <p>*/}
        {/*          <span>17</span>%*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <Doughnut*/}
        {/*        data={employeeDoughnutChartData}*/}
        {/*        options={employeeDoughnutChartOption}*/}
        {/*        width={310}*/}
        {/*        height={180}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</article>*/}
        <article>
          <h5>재무·손익</h5>
          <div className="financial_wrapper">
            <div className="sub_title">
              <p>주요 재무 상태표</p>
              <p>단위 : 백만</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>구&emsp;분</th>
                  <th>2019</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>자본</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2019]?.enpTcptAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2020]?.enpTcptAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2021]?.enpTcptAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2022]?.enpTcptAmt?.val / 1000000
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>부채</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2019]?.enpTdbtAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2020]?.enpTdbtAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2021]?.enpTdbtAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2022]?.enpTdbtAmt?.val / 1000000
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>자산</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        ((companyFinance || {})[2019]?.enpTcptAmt?.val +
                          (companyFinance || {})[2019]?.enpTdbtAmt?.val) /
                          1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        ((companyFinance || {})[2020]?.enpTcptAmt?.val +
                          (companyFinance || {})[2020]?.enpTdbtAmt?.val) /
                          1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        ((companyFinance || {})[2021]?.enpTcptAmt?.val +
                          (companyFinance || {})[2021]?.enpTdbtAmt?.val) /
                          1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        ((companyFinance || {})[2022]?.enpTcptAmt?.val +
                          (companyFinance || {})[2022]?.enpTdbtAmt?.val) /
                          1000000
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="chart_wrapper">
              <Bar
                data={profitMixedChartData}
                options={profitMixedChartOption}
                width={882}
                height={200}
              />
              <p>주요 재무 상태표</p>
            </div>
          </div>
          <div className="financial_wrapper">
            <div className="sub_title">
              <p>주요 손익 계산서</p>
              <p>단위 : 백만</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>구&emsp;분</th>
                  <th>2019</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>매출액</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2019]?.enpSaleAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2020]?.enpSaleAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2021]?.enpSaleAmt?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2022]?.enpSaleAmt?.val / 1000000
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>영업이익</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2019]?.enpBzopPft?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2020]?.enpBzopPft?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2021]?.enpBzopPft?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2022]?.enpBzopPft?.val / 1000000
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <td>당기순이익</td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2019]?.enpCrtmNpf?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2020]?.enpCrtmNpf?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2021]?.enpCrtmNpf?.val / 1000000
                      )
                    )}
                  </td>
                  <td>
                    {window.$Global.commaify(
                      Math.floor(
                        (companyFinance || {})[2022]?.enpCrtmNpf?.val / 1000000
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="chart_wrapper">
              <Bar
                data={profitAndLossMixedChartData}
                options={profitMixedChartOption}
                width={882}
                height={200}
              />
              <p>주요 손익 계산서</p>
            </div>
          </div>
        </article>
        <article className="company_skill_wrapper">
          <h5>기업 기술력 평가</h5>
          <GradeCompany
            data={{
              quality: companyTechGrade?.quality?.str,
              quantity: companyTechGrade?.quantity?.str,
              total: companyTechGrade?.total?.str,
            }}
          />
        </article>
        {/*<article ref={tabs[3].element}>*/}
        {/*  <h5>핵심 기술정보</h5>*/}
        {/*  <div className="skill_info">*/}
        {/*    <table className="list">*/}
        {/*      <colgroup>*/}
        {/*        <col width="10%" />*/}
        {/*        <col width="10%" />*/}
        {/*        <col width="15%" />*/}
        {/*        <col width="15%" />*/}
        {/*        <col width="40%" />*/}
        {/*        <col width="10%" />*/}
        {/*      </colgroup>*/}
        {/*      <thead>*/}
        {/*        <tr>*/}
        {/*          <th>순번</th>*/}
        {/*          <th>국가</th>*/}
        {/*          <th>등록 번호</th>*/}
        {/*          <th>SMART3 등급</th>*/}
        {/*          <th>발명의 명칭</th>*/}
        {/*          <th>출원일자</th>*/}
        {/*        </tr>*/}
        {/*      </thead>*/}
        {/*      <tbody>*/}
        {/*        {companySkillList.map((item, idx) => {*/}
        {/*          return (*/}
        {/*            <tr key={idx}>*/}
        {/*              <td>{idx + 1}</td>*/}
        {/*              <td>{item.nation}</td>*/}
        {/*              <td>{item.register_number || "N/A"}</td>*/}
        {/*              <td>*/}
        {/*                <div*/}
        {/*                  className={`circle grade_${*/}
        {/*                    item.sm_grade || "default"*/}
        {/*                  }`}*/}
        {/*                >*/}
        {/*                  {item.sm_grade || "N"}*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*              <td>*/}
        {/*                {item.invention_title ||*/}
        {/*                  item.invention_title_en ||*/}
        {/*                  "N/A"}*/}
        {/*              </td>*/}
        {/*              <td>{window.$Global.convertDate(item.apply_at)}</td>*/}
        {/*            </tr>*/}
        {/*          );*/}
        {/*        })}*/}
        {/*      </tbody>*/}
        {/*    </table>*/}
        {/*    <div className="skill_info_header">*/}
        {/*      <p>요약</p>*/}
        {/*      <p>기술명</p>*/}
        {/*      <p>기술 요약</p>*/}
        {/*    </div>*/}
        {/*    <div className="skill_info_items custom_scroll">*/}
        {/*      {companyTechPatent.map((item) => {*/}
        {/*        if (!item.abstract) return;*/}
        {/*        return (*/}
        {/*          <div className="skill_info_item">*/}
        {/*            <div></div>*/}
        {/*            <div>{item.invention_title}</div>*/}
        {/*            <div>{window.$Global.removeHTML(item.abstract)}</div>*/}
        {/*          </div>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</article>*/}
        <article>
          <h5>특허·상표·디자인</h5>
          <div className="patent_chart_wrapper">
            <div className="chart patent_trade">
              <p className="chart_category_name">특&emsp;허</p>
              <p className="total_cnt">
                {companyCountData.patent_statistic?.all || 0}건
              </p>
              <div className="add_label">
                등록 {companyCountData.patent_statistic?.register_cnt || 0}건
              </div>
              <div className="apply_label">
                출원 {companyCountData.patent_statistic?.apply_cnt || 0}건
              </div>
              <div className="giveup_label">
                포기 {companyCountData.patent_statistic?.give_up_cnt || 0}건
              </div>
              <Doughnut
                data={patentDoughnutChartData}
                width={140}
                height={140}
                options={patentDoughnutChartOption}
              />
            </div>
            <div className="chart patent_trade">
              <p className="chart_category_name">상&emsp;표</p>
              <p className="total_cnt">
                {companyCountData.trademark_statistic?.all || 0}건
              </p>
              <div className="add_label">
                등록 {companyCountData.trademark_statistic?.register_cnt || 0}건
              </div>
              <div className="apply_label">
                출원 {companyCountData.trademark_statistic?.apply_cnt || 0}건
              </div>
              <div className="giveup_label">
                포기 {companyCountData.trademark_statistic?.give_up_cnt || 0}건
              </div>
              <Doughnut
                data={trademarkDoughnutChartData}
                width={140}
                height={140}
                options={patentDoughnutChartOption}
              />
            </div>
            <div className="chart annual_fee_and_year">
              <p>
                예상 연차료<span>(추천등급제공)</span>
              </p>
              <Bar
                data={annualFeeChartData}
                options={annualFeeChartOption}
                width={200}
                height={150}
              />
              {!companyCountData?.annual_pay && (
                <i className="no_chart_image" />
              )}
            </div>
            <div className="chart annual_fee_and_year">
              <p>연도별 데이터</p>
              <Bar
                data={yearlyChartData}
                options={yearlyChartOption}
                width={200}
                height={150}
              />
              {!companyCountData.annual_apply_cnt && (
                <i className="no_chart_image" />
              )}
            </div>
          </div>

          <div className="patent_list">
            <p>특허 리스트</p>
            {patentList.length <= 0 ? (
              <p className="empty_data" style={{ margin: "0 0 0 0" }}>
                데이터 없음
              </p>
            ) : (
              <table>
                <colgroup>
                  <col width={50} />
                  <col width={120} />
                  <col width={100} />
                  <col width={120} />
                  <col width={100} />
                  <col width={300} />
                  <col width={120} />
                  <col width={80} />
                </colgroup>
                <thead>
                  <tr>
                    <th>국가</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th style={{ textAlign: "left" }}>발명의 명칭</th>
                    <th>출원인</th>
                    <th>등급</th>
                  </tr>
                </thead>
                <tbody>
                  {patentList?.map((item) => {
                    return (
                      <tr>
                        <td>{item.nation}</td>
                        <td>{item.register_number}</td>
                        <td>
                          {window.$Global.convertDateDot(item.register_at)}
                        </td>
                        <td>{item.apply_no}</td>
                        <td>{window.$Global.convertDateDot(item.apply_at)}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.invention_title}
                        </td>
                        <td></td>
                        <td>
                          <div
                            className={`circle grade_${
                              item.sm_grade || "default"
                            }`}
                          >
                            {item.sm_grade || "N"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </article>
        <article>
          <h5>상표</h5>
          {trademarkList.length <= 0 ? (
            <p className="empty_data">데이터 없음</p>
          ) : (
            <div className="trademark_wrapper custom_scroll">
              {trademarkList?.map((item) => {
                return (
                  <div className="trademark_item">
                    <div className="trademark_name">
                      <h6>상표명</h6>
                      <p>{item.title}</p>
                    </div>
                    <div className="trademark_info">
                      <h6>지정상품 정보</h6>
                      {Object.keys(item.classification).map((key) => {
                        return (
                          <div className="product_list custom_scroll">
                            <p>제 {key}류</p>
                            <div>
                              {Object.values(item.classification).map(
                                (value) => {
                                  return value.map((valueItem) => {
                                    return <p>{valueItem}</p>;
                                  });
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </article>
        <article>
          <h5>디자인</h5>
          {designList.length <= 0 ? (
            <p className="empty_data">데이터 없음</p>
          ) : (
            <div className="design_wrapper custom_scroll">
              {designList.map((item) => {
                return (
                  <div className="design_item">
                    <div className="design_title">
                      <div>
                        <h6>디자인 명</h6>
                        <p className="design_name">{item.article_name}</p>
                      </div>
                      <div className="design_category">
                        <p className="design_status">{item.status}</p>
                      </div>
                    </div>
                    <div className="design_content">
                      <div>
                        <img
                          src={window.$Global.getCDN(item.img_key)}
                          alt="이미지"
                        />
                      </div>
                      <div>
                        1. 재질은 직물, 금속 또는 합성수지임.
                        <br /> 2. 사용자의 머리에 착용하여 뇌파를 측정 및
                        분석하고, 자극을 인가하여 스트레스를 케어하는 웨어러블
                        기기임.
                        <br /> 3. 본 디자인의 2개의 뇌파 자극을 위한 전극은
                        스트레스 케어 모드에 따라 회전이 되어 위치 조절이 가능한
                        구조를 가짐.
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </article>
        <article>
          <h5>정부사업 경쟁률 분석정보</h5>
          <div className="competition_rate">
            <p>동종업 내 정부사업 낙찰 현황</p>
            <div className="auction_chart_wrapper">
              <div>
                <HorizontalBar
                  data={auctionCompanyChartData}
                  options={auctionCompanyChartOption}
                />
                <p>{companyInfo.company_name} 낙찰현황</p>
                {emptyChartData(
                  companyStatistics?.task_cnt?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              <div>
                <HorizontalBar
                  data={auctionAvgChartData}
                  options={auctionCompanyChartOption}
                />
                <p>
                  {companyInfo?.grouptags?.[0]?.company_group_name || ""} 산업
                  전체 평균 낙찰 현황
                </p>
                {emptyChartData(
                  companyStatistics?.task_cnt?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              <div>
                <HorizontalBar
                  data={auction10AvgChartData}
                  options={auctionCompanyChartOption}
                />
                <p>
                  {companyInfo?.grouptags?.[0]?.company_group_name || ""} 산업
                  상위 10% 낙찰 현황
                </p>
                {emptyChartData(
                  companyStatistics?.task_cnt?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
            </div>
          </div>

          <div className="competition_rate">
            <p>동종 산업 내 정부사업 종합분석</p>
            <div className="auction_chart_wrapper"></div>
            <div className="grid_chart_wrapper">
              <div>
                <Bar data={salesChartData} options={auctionMoneyChartOption} />
                <p>매출 (단위: 백만)</p>
                {emptyChartData(
                  companyStatistics?.sales?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              <div>
                <Bar data={profitChartData} options={auctionMoneyChartOption} />
                <p>영업이익 (단위: 백만)</p>
                {emptyChartData(
                  companyStatistics?.profit?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              <div>
                <Bar data={techChartData} options={auctionCompanyChartOption} />
                <p>기술력</p>
                {emptyChartData(
                  companyStatistics?.patent?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              <div>
                <Bar
                  data={patentChartData}
                  options={auctionCompanyChartOption}
                />
                <p>특허건수</p>
                {emptyChartData(
                  companyStatistics?.patent?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
              {/*<div>*/}
              {/*  <HorizontalBar*/}
              {/*    data={auctionAvgChartData}*/}
              {/*    options={auctionCompanyChartOption}*/}
              {/*  />*/}
              {/*  <p>상표건수</p>*/}
              {/*</div>*/}
              <div>
                <Bar
                  data={auctionBizChartData}
                  options={auctionCompanyChartOption}
                />
                <p>낙찰률</p>
                {emptyChartData(
                  companyStatistics?.task_rate?.[companyFirstTag]?.data
                ) && <i className="no_chart_image" />}
              </div>
            </div>
          </div>
          {!companyBidInfo?.length === 0 && (
            <div className="auction_status">
              <p>동종업 내 정부사업 낙찰 현황</p>
              <div className="auction_status_text">
                <p>
                  지원과제 <span>{companyBidInfo?.length}</span>
                  <span>건</span>
                </p>
                <p>
                  낙찰과제{" "}
                  <span>
                    {
                      companyBidInfo.filter((item) => item.is_winner == 1)
                        .length
                    }
                  </span>
                  <span>건</span>
                </p>
                <p>
                  낙찰률
                  <span>
                    {(
                      (companyBidInfo.filter((item) => item.is_winner == 1)
                        .length /
                        companyBidInfo.length) *
                      100
                    ).toFixed(2)}
                  </span>
                  <span>%</span>
                </p>
              </div>
              <div className="auction_status_box">
                {companyBidInfo.length > 0 &&
                  companyBidInfo.slice(0, 4).map((item) => {
                    let randNumber = Math.floor(Math.random() * 90) + 10;
                    let text = item.is_winner == 1 ? "낙찰" : "미낙찰";
                    return (
                      <div>
                        <p>{text}</p>
                        <p>{item.bid_title}</p>
                        <p>{randNumber}:1</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {!companyBidInfo?.length === 0 && (
            <div className="auction_status_table custom_scroll">
              <table>
                <thead>
                  <tr>
                    <th>즐찾</th>
                    <th>요약</th>
                    <th>사업명</th>
                    <th>현황</th>
                    <th>공고일</th>
                    <th>접수일</th>
                    <th>경쟁률</th>
                  </tr>
                </thead>
                <tbody>
                  {(companyBidInfo || {})?.map((item) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "left", paddingLeft: "15px" }}>
                          {item?.bid_title}
                        </td>
                        <td>{item.is_winner == 1 ? "낙찰" : "미낙찰"}</td>
                        <td>
                          {window.$Global.convertDateDot(item.noti_date * 1000)}
                        </td>
                        <td>
                          {window.$Global.convertDateDot(
                            item.start_date * 1000
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </article>
        <article>
          <h5>경쟁현황 종합분석</h5>
          {companyCompList.length !== 0 ? (
            <div className="competitor_list">
              <p>주요 경쟁사</p>
              <div className="company_info_items custom_scroll">
                {(companyCompList || {})?.map((item) => {
                  return (
                    <div className="company_info_item">
                      <img src={logo} />
                      <div className="item_text">
                        <p>{item.info.company_name}</p>
                        <p>대표명: {item.info.company_ceo}</p>
                        <div className="item_text_category">
                          {item?.grouptags?.map((keyword) => {
                            return <p>{keyword.company_group_name}</p>;
                          })}
                        </div>
                      </div>
                      <button onClick={() => handleNavigate(item)}>
                        기업 분석정보
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <p className="empty_data">주요 경쟁사 데이터 없음</p>
          )}
        </article>
        <article>
          <h5>기업가치 성장분석</h5>
          <div className="growth_rate">
            <p>지표별 성장률</p>
            <div>
              <div>
                <Bar
                  data={growthFirstChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
                <p>
                  기업 매출 <span>(단위:백만)</span>
                </p>
              </div>
              <div>
                <Bar
                  data={growthSecondChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
                <p>
                  기업 영업이익 <span>(단위:백만)</span>
                </p>
              </div>
              <div>
                <Bar
                  data={growthThirdChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={250}
                />
                <p>기업 부채비율</p>
              </div>
              <div>
                <Bar
                  data={growthFourthChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={230}
                />
                <p>기업 영업이익율</p>
              </div>
            </div>
          </div>
          {/*  <div className="growth_compare_rate">*/}
          {/*    <p>산업 내 기업가치 성장률 비교분석</p>*/}
          {/*    <div>*/}
          {/*      <Bar*/}
          {/*        data={growthCompareChartData}*/}
          {/*        options={growthCompareChartOption}*/}
          {/*        width={766}*/}
          {/*        height={230}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <p>*/}
          {/*        (주)에듀헬스퓨쳐는 최근 4년간 매출에서 15%의 증가율을 보였고,*/}
          {/*        2022년 3% 소폭 감소하였다.*/}
          {/*      </p>*/}
          {/*      <p>*/}
          {/*        (주)에듀헬스퓨처는 최근 4년간 기술력에서 30%의 증가율을 보였다.*/}
          {/*      </p>*/}
          {/*      <p>*/}
          {/*        (주)에듀헬스퓨처는 최근 4년간 영업이익에서 43.5%의 증가율을*/}
          {/*        보였다.*/}
          {/*      </p>*/}
          {/*      <p>*/}
          {/*        (주)에듀헬스퓨처는 최근 4년간 인력 비중이 8%의 감소율을 보였다.*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
        </article>
        {/*<article ref={tabs[8].element}>*/}
        {/*  <h5>관련뉴스</h5>*/}
        {/*  <div className="competitor_news">*/}
        {/*    <div>*/}
        {/*      <p>*/}
        {/*        '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래*/}
        {/*        제시”*/}
        {/*      </p>*/}
        {/*      <p>*/}
        {/*        디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서*/}
        {/*        개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식*/}
        {/*        초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데*/}
        {/*        세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.*/}
        {/*        이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한*/}
        {/*        미래 헬스케어 혁신 솔루션을 설명할 예정이다.*/}
        {/*      </p>*/}
        {/*      <p>[매일경제 2023-01-16]</p>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <p>*/}
        {/*        '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래*/}
        {/*        제시”*/}
        {/*      </p>*/}
        {/*      <p>*/}
        {/*        디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서*/}
        {/*        개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식*/}
        {/*        초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데*/}
        {/*        세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.*/}
        {/*        이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한*/}
        {/*        미래 헬스케어 혁신 솔루션을 설명할 예정이다.*/}
        {/*      </p>*/}
        {/*      <p>[매일경제 2023-01-16]</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</article>*/}
      </section>
    </div>
  );
};

export default CompetitorIpnowDetails;
