/*
 * 사용자 수 체크하는 Counter
 * */

import "./css/Counter.scss";
import React, { useState } from "react";

const Counter = ({ count, setCount, numberArr }) => {
  const [index, setIndex] = useState(0);

  const handleChange = (type) => {
    if (type === "decrease" && index > 0) {
      setCount(numberArr[index - 1]);
      setIndex(index - 1);
    }
    if (type === "increase" && index < 9) {
      setCount(numberArr[index + 1]);
      setIndex(index + 1);
    }
  };

  return (
    <div id="Counter">
      <i className="icon_minus" onClick={() => handleChange("decrease")} />
      <span>{count}</span>
      <i className="icon_plus_gray" onClick={() => handleChange("increase")} />
    </div>
  );
};

export default Counter;
