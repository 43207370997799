import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./css/TradeMarkDetail.scss";
import InputRow from "./row/InputRow";
import SelectRow from "../patent/detail/common/SelectRow";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import FileRow from "./row/FileRow";
import InputAndFileRow from "./row/InputAndFileRow";
import DatePickerRow from "./row/DatePickerRow";
import DateAndFileRow from "./row/DateAndFileRow";
import ToggleButtonRow from "./row/ToggleButtonRow";
import ToggleButtonAndDatePickerRow from "./row/ToggleButtonAndDatePickerRow";
import ProductClassificationPopup from "./ProductClassificationPopup";

import TradeMarkAPI from "../../API/trademark";
import AccountAPI from "../../API/account";
import ProductAPI from "../../API/product";
import MemberContext from "../../store/Member";
import ProductSelectPopup from "./ProductSelectPopup";
import _ from "lodash";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import NormalRow from "../patent/detail/common/NormalRow";
import config from "../../const/config";
import { tradeMarkNationType } from "../../types/nationType";

function TradeMarkDetail() {
  const img_type = config.imgType;
  const { auth, manage_company_idx } = useContext(MemberContext);
  const { trademark_idx } = useParams();
  const history = useHistory();
  const isNewTradamark = trademark_idx == "new";
  const [managerSelectTag, setManagerSelectTag] = useState([]);
  const [agentListSelectTag, setAgentListSelectTag] = useState([]);
  const [fileImage, setFileImage] = useState("");
  const [productSelect, setProductSelect] = useState([]); // 관련 제품명
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
  const [historyTradeMark, setHistoryTradeMark] = useState({});
  const [tabData, setTabData] = useState(window.localStorage.getItem(""));
  const [tradeMark, setTradeMark] = useState({
    editing: false,
    name: "", // 상표 명칭
    manage_number: "", // 관리번호
    survey_req: 0, // 선행 상표조사 유무
    manager_idx: "", // 담당자 idx
    manager_name: "", // 담당자
    agent_company_idx: "", // 특허 사무소 idx
    agent_company_name: "", // 특허 사무소
    agent_manage_number: "", // 특허 사무소 관리번호
    agent_idx: "", // 대리인 담당자 idx
    agent_name: "", // 대리인 담당자
    relation_product_idx: "", // 관련 제품idx
    relation_product_name: "", // 관련 제품명
    apply_number: "", // 출원번호
    apply_at: "", // 출원일
    nation: 0, // 나라
    nation_string: "",
    objection_req: 0, // 이의신청 유무
    objection_at: "", // 이의신청일
    objection_opinion_upload_at: "", // 이의신청 의견서 접수
    objection_submission_at: "", // 이의신청 의견서 제출일
    product: "", // 관련 제품
    product_class: [], // 상품 분류
    regist_decision_at: "", // 등록 결정일
    regist_at: "", // 등록 납부일/등록일
    regist_number: "", // 등록번호
    regist_payment_deadline: "", // 등록료 납부 기한
    renewal_first_deadline: "", // 1차 갱신기간 시작일
    renewal_first_deadline_end: "", // 1차 갱신기간 만료일
    divide_pay: 0, // 분할 납부 여부
    second_payment_scheduled_at: "", // 2차 납부 예정일
    renewal_req: 0, //  갱신신청 유무
    renewal_order_at: "", // 갱신 지시일
    renewal_order: 0, // 갱신 지시 여부
    renewal_at: "", // 갱신일
    renewal_regist_at: "", // 갱신 등록 납부일/등록일
    renewal_cnt: 0, // 갱신 차수
    renewal_second_deadline: "", // 갱신 2차 기간 만료일
    renewal_divide_pay: 0, // 분할 납부 여부(갱신)
    renewal_second_scheduled_at: "", // 2차 납부 예정일(갱신)
    renewal_deadline_start: "",
    renewal_deadline_end: "",
    renewal_year_cnt: 0,
    state: 0, // 상표 상태
    product_list: [],
    file: null, // 미리보기 이미지
    image_key: "",
    priority_screen_req: 0, // 우선 심사 청구
    oa: [
      {
        idx: null,
        published_at: null, // OA 발행일
        opinion_upload_at: null, // OA 의견서 접수
        deadline: null,
        apply_decision_at: null,
        apply_at: null,
        objection_deadline: null,
        submission_at: null,
        extension_cnt: 0,
      },
    ],
    fileList: {
      survey: [],
      survey_file: [],
      apply: [],
      apply_file: [],
      oa_content: [],
      oa_content_file: [],
      oa_opinion: [],
      oa_opinion_file: [],
      oa_apply: [],
      oa_apply_file: [],
      objection_content: [],
      objection_content_file: [],
      objection_opinion: [],
      objection_opinion_file: [],
      regist: [],
      regist_file: [],
      expiration: [],
      expiration_file: [],
      renewal: [],
      renewal_file: [],
      image: [],
      image_file: [],
    },
    // 상표 신규 등록할 때 사용할 파일 저장 변수
  });
  const [stateShow, setStateShow] = useState(false);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const [popupProp, setPopupProp] = useState({ type: null, oa_idx: null });
  const [downPopupShow, setDownPopupShow] = useState(false); // 팝업 보여주기 여부
  const [upPopupShow, setUpPopupShow] = useState(false); // 팝업 보여주기 여부
  const [classificationPopupShow, setClassificationPopupShow] = useState(false);
  const [selectPopupShow, setSelectPopupShow] = useState(false);
  const [productClassNo, setProductClassNo] = useState(0);
  const [downloadable, setDownloadable] = useState(true); // 다운로드 버튼 노출 여부
  const [fileType, setFileType] = useState({
    survey: "",
    apply: "",
    oa_content: "",
    oa_opinion: "",
    oa_apply: "",
    objection_content: "",
    objection_opinion: "",
    regist: "",
    expiration: "",
    renewal: "",
    image: "",
  });

  let classificationList = [];

  useEffect(() => {
    if (!isNewTradamark) {
      initTradeMarkInfo();
    } else {
      setTradeMark({ ...tradeMark, editing: true });
      setDownloadable(false);
    }
    let file_type = {};
    if (isCompany) {
      AccountAPI.getAccountCompany().then((res) => {
        if (res.data.manager) {
          setManagerSelectTag(
            res.data.manager.map((item) => (
              <option key={item.idx} value={item.idx}>
                {item.name}
              </option>
            ))
          );
        }
      });
      AccountAPI.getAgentCompanyList().then((res) => {
        setAgentListSelectTag(
          res.data.map((item) => (
            <option key={item.idx} value={item.company_idx}>
              {item.name}
            </option>
          ))
        );
      });
      ProductAPI.getProductListCompany().then((res) => {
        setProductSelect(
          res.data.map((item) => (
            <option key={item.idx} value={item.idx}>
              {item.name}
            </option>
          ))
        );
      });
      TradeMarkAPI.getClass().then((res) => {
        classificationList = res.data;
      });
      file_type.survey = "survey";
      file_type.apply = "apply";
      file_type.oa_content = "oa_content";
      file_type.oa_opinion = "oa_opinion";
      file_type.oa_apply = "oa_apply";
      file_type.objection_content = "objection_content";
      file_type.objection_opinion = "objection_opinion";
      file_type.regist = "regist";
      file_type.expiration = "expiration";
      file_type.renewal = "renewal";
      file_type.image = "image";
    } else if (isAgent) {
      AccountAPI.getAccountAgent().then((res) => {
        if (res.data.manager) {
          setManagerSelectTag(
            res.data.manager.map((item) => (
              <option key={item.idx} value={item.idx}>
                {item.name}
              </option>
            ))
          );
        }
      });
      file_type.survey = "agent_survey";
      file_type.apply = "agent_apply";
      file_type.oa_content = "agent_oa_content";
      file_type.oa_opinion = "agent_oa_opinion";
      file_type.oa_apply = "agent_oa_apply";
      file_type.objection_content = "agent_objection_content";
      file_type.objection_opinion = "agent_objection_opinion";
      file_type.regist = "agent_regist";
      file_type.expiration = "agent_expiration";
      file_type.renewal = "agent_renewal";
      file_type.image = "agent_image";
    }
    setFileType(file_type);
  }, [auth]);

  const initTradeMarkInfo = () => {
    if (isCompany) {
      TradeMarkAPI.getTradeMarkInfo(trademark_idx).then(async (res) => {
        let data = res.data;
        if (!res.data.oa.length) {
          await TradeMarkAPI.createOa(trademark_idx).then((res) => {
            data.oa = [
              {
                idx: res.data.oa_idx,
                apply_at: null,
                apply_decision_at: null,
                deadline: null,
                extension_cnt: 0,
                objection_deadline: null,
                opinion_upload_at: null,
                published_at: null,
                submission_at: null,
              },
            ];
          });
        }
        data.editing = false;
        setTradeMark(data);
        setHistoryTradeMark(data);
      });
    } else if (isAgent) {
      TradeMarkAPI.getTradeMarkInfoAgent(
        manage_company_idx,
        trademark_idx
      ).then(async (res) => {
        let data = res.data;
        if (!res.data.oa.length) {
          await TradeMarkAPI.createOaAgent(
            trademark_idx,
            manage_company_idx
          ).then((res) => {
            data.oa = [
              {
                idx: res.data.oa_idx,
                apply_at: null,
                apply_decision_at: null,
                deadline: null,
                extension_cnt: 0,
                objection_deadline: null,
                opinion_upload_at: null,
                published_at: null,
                submission_at: null,
              },
            ];
          });
        }
        data.editing = false;
        setTradeMark(data);
        setHistoryTradeMark(data);
      });
    }
  };

  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    return new Promise((resolve) => {
      reader.onload = () => {
        setFileImage(reader.result);
        resolve();
      };
    });
  };

  const updateFileCntTradeMark = () => {
    if (isCompany) {
      TradeMarkAPI.getTradeMarkInfo(trademark_idx).then(async (res) => {
        let data = res.data;

        let trade = {
          ...tradeMark,
          apply_file_cnt: data.apply_file_cnt,
          objection_contetn_file_cnt: data.objection_contetn_file_cnt,
          objection_opinion_file_cnt: data.objection_opinion_file_cnt,
          register_file_cnt: data.register_file_cnt,
          renewal_cnt: data.renewal_cnt,
          renewal_expiration_file_cnt: data.renewal_expiration_file_cnt,
          renewal_file_cnt: data.renewal_file_cnt,
          survey_file_cnt: data.survey_file_cnt,
        };
        trade.oa = {
          ...tradeMark.oa,
          oa_apply_file_cnt: data.oa_apply_file_cnt,
          oa_content_file_cnt: data.oa_content_file_cnt,
          oa_opinion_file_cnt: data.oa_opinion_file_cnt,
        };
        setTradeMark(trade);
        setHistoryTradeMark(trade);
      });
    } else if (isAgent) {
      TradeMarkAPI.getTradeMarkInfoAgent(
        manage_company_idx,
        trademark_idx
      ).then(async (res) => {
        let data = res.data;
        setTradeMark(data);
      });
    }
  };

  const onClickEditngStart = () => {
    setTradeMark({ ...tradeMark, editing: true });
  };

  const onClickSave = () => {
    if (isCompany) {
      TradeMarkAPI.updateTradeMarkCompany(trademark_idx, tradeMark).then(
        async () => {
          await TradeMarkAPI.updateOa(
            trademark_idx,
            tradeMark.oa[0].idx,
            tradeMark.oa[0]
          ).then((res) => {});
          await initTradeMarkInfo();
        }
      );
    } else if (isAgent) {
      TradeMarkAPI.updateTradeMarkAgent(
        trademark_idx,
        manage_company_idx,
        tradeMark
      ).then(async () => {
        await initTradeMarkInfo();
      });
    }
  };

  const onClickEditngCancel = () => {
    setTradeMark({
      ...historyTradeMark,
      editing: false,
    });
  };

  const onClickDownPopShow = async (type, oa_idx) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });
    let result = [];
    switch (type) {
      case "survey":
        result = await TradeMarkAPI.getCompanySurveyFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "apply":
        result = await TradeMarkAPI.getCompanyApplyFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "oa_content":
        result = await TradeMarkAPI.getCompanyOaContentFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "oa_opinion":
        result = await TradeMarkAPI.getCompanyOaOpinionFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "oa_apply":
        result = await TradeMarkAPI.getCompanyOaApplyFiles(
          trademark_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "objection_content":
        result = await TradeMarkAPI.getCompanyObjectionContentFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "objection_opinion":
        result = await TradeMarkAPI.getCompanyObjectionOpinionFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "regist":
        result = await TradeMarkAPI.getCompanyRegistFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "expiration":
        result = await TradeMarkAPI.getCompanyRenewalExpirationFiles(
          trademark_idx
        ).then((res) => res.data);
        break;
      case "renewal":
        result = await TradeMarkAPI.getCompanyRenewalFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "image":
        if (tradeMark.image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.image_name,
              file_type: tradeMark.image_type,
              created_at: "",
            },
          ];
        }
        break;
      case "agent_survey":
        result = await TradeMarkAPI.getAgentSurveyFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_apply":
        result = await TradeMarkAPI.getAgenApplyFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_content":
        result = await TradeMarkAPI.getAgentOaContentFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_opinion":
        result = await TradeMarkAPI.getAgentOaOpinionFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_oa_apply":
        result = await TradeMarkAPI.getAgentOaApplyFiles(
          trademark_idx,
          manage_company_idx,
          oa_idx
        ).then((res) => res.data);
        break;
      case "agent_objection_content":
        result = await TradeMarkAPI.getAgentObjectionContentFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_objection_opinion":
        result = await TradeMarkAPI.getAgentObjectionOpinionFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_regist":
        result = await TradeMarkAPI.getAgentRegistFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_expiration":
        result = await TradeMarkAPI.getAgentRenewalExpirationFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_renewal":
        result = await TradeMarkAPI.getAgentRenewalFiles(
          trademark_idx,
          manage_company_idx
        ).then((res) => res.data);
        break;
      case "agent_image":
        if (tradeMark.image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.image_name,
              file_type: tradeMark.image_type,
              created_at: "",
            },
          ];
        }
        break;
    }
    setFileViewList(result);
    setDownPopupShow(true);
  };

  const onClickUpPopShow = (type, oa_idx) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });
    setUpPopupShow(true);

    let result = [];
    if (isNewTradamark) {
      switch (type) {
        case "survey":
          result = tradeMark.fileList.survey;
          break;
        case "apply":
          result = tradeMark.fileList.apply;
          break;
        case "oa_content":
          result = tradeMark.fileList.oa_content;
          break;
        case "oa_opinion":
          result = tradeMark.fileList.oa_opinion;
          break;
        case "oa_apply":
          result = tradeMark.fileList.oa_apply;
          break;
        case "objection_content":
          result = tradeMark.fileList.objection_content;
          break;
        case "objection_opinion":
          result = tradeMark.fileList.objection_opinion;
          break;
        case "regist":
          result = tradeMark.fileList.regist;
          break;
        case "expiration":
          result = tradeMark.fileList.expiration;
          break;
        case "renewal":
          result = tradeMark.fileList.renewal;
          break;
        case "image":
          result = tradeMark.fileList.image;
          break;
      }
      setUploadFileViewList(result);
    }
  };

  const onClickDownload = (idx) => {
    switch (popupProp.type) {
      case "survey":
        TradeMarkAPI.downloadCompanySurveyFile(trademark_idx, idx);
        break;
      case "apply":
        TradeMarkAPI.downloadCompanyApplyFile(trademark_idx, idx);
        break;
      case "oa_content":
        TradeMarkAPI.downloadCompanyOaContentFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "oa_opinion":
        TradeMarkAPI.downloadCompanyOaOpinionFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "oa_apply":
        TradeMarkAPI.downloadCompanyOaApplyFile(
          trademark_idx,
          idx,
          popupProp.oa_idx
        );
        break;
      case "objection_content":
        TradeMarkAPI.downloadCompanyObjectionContentFile(trademark_idx, idx);
        break;
      case "objection_opinion":
        TradeMarkAPI.downloadCompanyObjectionOpinionFile(trademark_idx, idx);
        break;
      case "regist":
        TradeMarkAPI.downloadCompanyRegistFile(trademark_idx, idx);
        break;
      case "expiration":
        TradeMarkAPI.downloadCompanyRenewalExpirationFile(trademark_idx, idx);
        break;
      case "renewal":
        TradeMarkAPI.downloadCompanyRenewalFile(trademark_idx, idx);
        break;
      case "image":
        TradeMarkAPI.downloadTradeMarkImage(trademark_idx);
        break;
      case "agent_survey":
        TradeMarkAPI.downloadAgentSurveyFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_apply":
        TradeMarkAPI.downloadAgentApplyFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_oa_content":
        TradeMarkAPI.downloadAgentOaContentFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_oa_opinion":
        TradeMarkAPI.downloadAgentOaOpinionFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_oa_apply":
        TradeMarkAPI.downloadAgentOaApplynFile(
          trademark_idx,
          idx,
          popupProp.oa_idx,
          manage_company_idx
        );
        break;
      case "agent_objection_content":
        TradeMarkAPI.downloadAgentObjectionContentFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_objection_opinion":
        TradeMarkAPI.downloadAgentyObjectionOpinionFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_regist":
        TradeMarkAPI.downloadAgentyRegistFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_expiration":
        TradeMarkAPI.downloadAgentRenewalExpirationFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_renewal":
        TradeMarkAPI.downloadAgentRenewalFile(
          trademark_idx,
          idx,
          manage_company_idx
        );
        break;
      case "agent_image":
        TradeMarkAPI.downloadTradeMarkImageAgent(
          trademark_idx,
          manage_company_idx
        );
        break;
    }
  };

  const onClickDeleteFileInDownload = async (idx) => {
    switch (popupProp.type) {
      case "survey":
        await TradeMarkAPI.deleteCompanySurveyFile(trademark_idx, idx);
        break;
      case "apply":
        await TradeMarkAPI.deleteCompanyApplyFile(trademark_idx, idx);
        break;
      case "oa_content":
        await TradeMarkAPI.deleteCompanyOaContentFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "oa_opinion":
        await TradeMarkAPI.deleteCompanyOaOpinionFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "oa_apply":
        await TradeMarkAPI.deleteCompanyOaApplyFile(
          trademark_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "objection_content":
        await TradeMarkAPI.deleteCompanyObjectionContentFile(
          trademark_idx,
          idx
        );
        break;
      case "objection_opinion":
        await TradeMarkAPI.deleteCompanyObjectionOpinionFile(
          trademark_idx,
          idx
        );
        break;
      case "regist":
        await TradeMarkAPI.deleteCompanyRegistFile(trademark_idx, idx);
        break;
      case "expiration":
        await TradeMarkAPI.deleteCompanyRenewalExpirationFile(
          trademark_idx,
          idx
        );
        break;
      case "renewal":
        await TradeMarkAPI.deleteCompanyRenewalFile(trademark_idx, idx);
        break;
      case "image":
        await TradeMarkAPI.updateTradeMarkCompany(trademark_idx, {
          delete_image: true,
        });
        setTradeMark({
          ...tradeMark,
          image_name: "",
          image_key: "",
          image_type: "",
        });
        break;
      case "agent_survey":
        await TradeMarkAPI.deleteAgentSurveyFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_apply":
        await TradeMarkAPI.deleteAgentApplyFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_oa_content":
        await TradeMarkAPI.deleteAgentOaContentFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_oa_opinion":
        await TradeMarkAPI.deleteAgentOaOpinionFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_oa_apply":
        await TradeMarkAPI.deleteAgentOaApplyFile(
          trademark_idx,
          manage_company_idx,
          popupProp.oa_idx,
          idx
        );
        break;
      case "agent_objection_content":
        await TradeMarkAPI.deleteAgentObjectionContentFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_objection_opinion":
        await TradeMarkAPI.deleteAgentObjectionOpinionFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_regist":
        await TradeMarkAPI.deleteAgentRegistFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_expiration":
        await TradeMarkAPI.deleteAgentRenewalExpirationFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_renewal":
        await TradeMarkAPI.deleteAgentRenewalFile(
          trademark_idx,
          manage_company_idx,
          idx
        );
        break;
      case "agent_image":
        await TradeMarkAPI.updateTradeMarkAgent(
          trademark_idx,
          manage_company_idx,
          { file: [] }
        );
        setTradeMark({
          ...tradeMark,
          image_name: "",
          image_key: "",
          image_type: "",
        });
        break;
    }
    alert("삭제되었습니다");
    setFileViewList(
      fileViewList.filter((file) => {
        return file.idx !== idx;
      })
    );
    updateFileCntTradeMark();
  };

  const onClickUpload = async () => {
    if (isNewTradamark) {
      let copy = _.cloneDeep(tradeMark);

      if (popupProp.type == "image") {
        if (uploadFileViewList.length > 1) {
          alert("상표 이미지는 하나만 등록하실 수 있습니다");
          return;
        }
        if (
          img_type.test(
            uploadFileViewList[0].file_type.toLowerCase().toString()
          )
        ) {
          encodeFileToBase64(uploadFileViewList[0].file);
          copy.fileList.image = uploadFileViewList;
        } else {
          alert("jpg, jpeg, gif, png만 업로드하실 수 있습니다");
          return;
        }
      }
      copy.fileList[popupProp.type] = uploadFileViewList;
      setTradeMark(copy);
    } else {
      let formData = new FormData();

      uploadFileViewList.forEach((item) => {
        formData.append("file", item.file);
      });

      switch (popupProp.type) {
        case "survey":
          await TradeMarkAPI.uploadCompanySurveyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "apply":
          await TradeMarkAPI.uploadCompanyApplyFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_content":
          await TradeMarkAPI.uploadCompanyOaContentFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_opinion":
          await TradeMarkAPI.uploadCompanyOaOpinionFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "oa_apply":
          await TradeMarkAPI.uploadCompanyOaApplyFile(
            trademark_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "objection_content":
          await TradeMarkAPI.uploadCompanyObjectionContentFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "objection_opinion":
          await TradeMarkAPI.uploadCompanyObjectionOpinionFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "regist":
          await TradeMarkAPI.uploadCompanyRegistFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "expiration":
          await TradeMarkAPI.uploadCompanyRenewalExpirationFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "renewal":
          await TradeMarkAPI.uploadCompanyRenewalFile(
            trademark_idx,
            formData
          ).then((res) => res.data);
          break;
        case "image":
          if (validatorTradeMarkImage(uploadFileViewList)) {
            console.log(uploadFileViewList[0]);
            encodeFileToBase64(uploadFileViewList[0].file);
            await TradeMarkAPI.updateTradeMarkCompany(
              trademark_idx,
              formData
            ).then((res) => res.data);
          }
          break;
        case "agent_survey":
          await TradeMarkAPI.uploadAgentSurveyFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_apply":
          await TradeMarkAPI.uploadAgentApplyFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_content":
          await TradeMarkAPI.uploadAgentOaContentFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_opinion":
          await TradeMarkAPI.uploadAgentOaOpinionFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_oa_apply":
          await TradeMarkAPI.uploadAgentOaApplyFile(
            trademark_idx,
            manage_company_idx,
            popupProp.oa_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_objection_content":
          await TradeMarkAPI.uploadAgentObjectionContentFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_objection_opinion":
          await TradeMarkAPI.uploadAgentObjectionOpinionFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_regist":
          await TradeMarkAPI.uploadAgentRegistFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_expiration":
          await TradeMarkAPI.uploadAgentRenewalExpirationFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_renewal":
          await TradeMarkAPI.uploadAgentRenewalFile(
            trademark_idx,
            manage_company_idx,
            formData
          ).then((res) => res.data);
          break;
        case "agent_image":
          if (validatorTradeMarkImage(uploadFileViewList)) {
            await TradeMarkAPI.updateTradeMarkAgent(
              trademark_idx,
              manage_company_idx,
              formData
            ).then((res) => res.data);
          }
          break;
      }
      alert("업로드되었습니다");
    }
    setUpPopupShow(false);
    setUploadFileViewList([]);
    if (!isNewTradamark) {
      updateFileCntTradeMark();
    }
  };

  const validatorTradeMarkImage = (list) => {
    if (list.length > 1) {
      alert("상표 이미지는 하나만 등록하실 수 있습니다");
      return false;
    }
    if (!img_type.test(list[0].file_type)) {
      alert("jpg, jpeg, gif, png 업로드하실 수 있습니다");
      return false;
    }
    return true;
  };

  const onChangeOA = (idx, key, value) => {
    let copy_oa = window.$Global.cloneObject(tradeMark.oa);
    let oa = Object.keys(copy_oa).map((key) => copy_oa[key]);
    oa[idx][key] = value;
    setTradeMark({ ...tradeMark, oa: oa });
  };

  const onClickSelectClass = (td_class) => {
    setProductClassNo(td_class);
    setSelectPopupShow(true);
  };

  const onClickConfirm = (arr) => {
    let product_class = arr.map((item) => {
      return { class: productClassNo, product: item.text };
    });
    setTradeMark({
      ...tradeMark,
      product_class: tradeMark.product_class.concat(product_class),
    });
    setClassificationPopupShow(false);
    setSelectPopupShow(false);
  };

  const onClickDeleteClass = (idx) => {
    var copyArr = tradeMark.product_class.slice(0);
    copyArr.splice(idx, 1);
    setTradeMark({ ...tradeMark, product_class: copyArr });
  };

  const onClickChangeState = (state) => {
    setTradeMark({ ...tradeMark, state: state });
    setStateShow(false);
  };

  const onClickAdd = () => {
    if (tradeMark.name.length == 0) {
      alert("상표 명칭을 입력해주세요");
      return;
    }

    let formData = new FormData();

    for (let key in tradeMark) {
      console.log(key);
      console.log(tradeMark[key]);
      if (key == "oa" || key == "product_class" || key == "product_list") {
        formData.append(key, JSON.stringify(tradeMark[key]));
        continue;
      }
      formData.append(key, tradeMark[key]);
    }

    tradeMark.fileList.image.forEach((item) => {
      formData.append("file", item.file);
    });

    tradeMark.fileList.survey.forEach((item) => {
      formData.append("survey_file", item.file);
    });

    tradeMark.fileList.apply.forEach((item) => {
      formData.append("apply_file", item.file);
    });

    tradeMark.fileList.regist.forEach((item) => {
      formData.append("regist_file", item.file);
    });

    tradeMark.fileList.objection_content.forEach((item) => {
      formData.append("objection_content_file", item.file);
    });

    tradeMark.fileList.objection_opinion.forEach((item) => {
      formData.append("objection_opinion_file", item.file);
    });

    tradeMark.fileList.expiration.forEach((item) => {
      formData.append("first_expiration_file", item.file);
    });

    tradeMark.fileList.renewal.forEach((item) => {
      formData.append("renewal_file", item.file);
    });

    tradeMark.fileList.oa_content.forEach((item) => {
      formData.append("oa_content_file0", item.file);
    });

    tradeMark.fileList.oa_opinion.forEach((item) => {
      formData.append("oa_opinion_file0", item.file);
    });

    tradeMark.fileList.oa_apply.forEach((item) => {
      formData.append("oa_apply_file0", item.file);
    });

    TradeMarkAPI.addTradeMark(formData).then((res) => {
      window.location.href = `/tradeMark/detail/${res.data.trademark_idx}`;
    });
  };

  console.log(tradeMark.product_class);
  let tag = tradeMark.product_class.map((item, index) => {
    console.log(11);
    if (index == 0) return null;
    return (
      <div className="rows" key={index}>
        <div className="row">
          <h2 className="title">상품 분류 / 지정상품</h2>
          <div className="btns">
            <p>{`제 ${item.class}류`}</p>
            {tradeMark.editing && (
              <>
                <button
                  className="icon_plus"
                  onClick={() => setClassificationPopupShow(true)}
                />
                <button
                  className="icon_badge_del"
                  onClick={() => onClickDeleteClass(index)}
                />
              </>
            )}
          </div>
        </div>
        <div className="row">
          <p>{item.product}</p>
        </div>
      </div>
    );
  });

  return (
    <div id="TradeMarkDetail">
      <div className="header">
        <h2 className="title">
          {isNewTradamark ? "상표 출원 등록 페이지" : "상표 출원 상세 페이지"}
        </h2>
        <div className="info">
          <div className="manage_no">출원번호 {tradeMark.apply_number}</div>
          <div className="apply_no">등록번호 {tradeMark.regist_number}</div>
          {isNewTradamark ? (
            <div>
              국가선택
              <select
                className="select_nation"
                onChange={(e) =>
                  setTradeMark({
                    ...tradeMark,
                    nation: e.target.value,
                    nation_string: tradeMarkNationType[e.target.value],
                  })
                }
              >
                {tradeMarkNationType.map((item, index) => {
                  return (
                    <option key={index} value={index}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div>국가: {tradeMark.nation_string}</div>
          )}
          <button className="state" onClick={() => setStateShow(!stateShow)}>
            {window.$Global.convertTradeMarkStateCodeToStr(tradeMark.state)}
            {tradeMark.editing && <i className="icon_more" />}
          </button>
          {stateShow && (
            <ul>
              <li>
                <button onClick={() => onClickChangeState(0)}>준비중</button>
                <button onClick={() => onClickChangeState(1)}>
                  선행상표조사
                </button>
                <button onClick={() => onClickChangeState(2)}>
                  선행기술 조사 완료
                </button>
                <button onClick={() => onClickChangeState(3)}>출원신청</button>
                <button onClick={() => onClickChangeState(4)}>출원완료</button>
                <button onClick={() => onClickChangeState(5)}>등록신청</button>
                <button onClick={() => onClickChangeState(6)}>등록결정</button>
                <button onClick={() => onClickChangeState(7)}>등록완료</button>
                <button onClick={() => onClickChangeState(8)}>oa</button>
                <button onClick={() => onClickChangeState(9)}>
                  oa의견 전달 받은 상태
                </button>
                <button onClick={() => onClickChangeState(10)}>
                  oa제출 요청상태
                </button>
                <button onClick={() => onClickChangeState(11)}>
                  이의신청 상태
                </button>
                <button onClick={() => onClickChangeState(12)}>
                  이의신청 의견서 접수 상태
                </button>
                <button onClick={() => onClickChangeState(13)}>
                  갱신 신청 상태
                </button>
                <button onClick={() => onClickChangeState(14)}>
                  갱신 요청 상태
                </button>
                <button onClick={() => onClickChangeState(99)}>종료</button>
              </li>
            </ul>
          )}
        </div>
        <div className="btns">
          {isNewTradamark ? (
            <button className="btn_save" onClick={onClickAdd}>
              등록
            </button>
          ) : tradeMark.editing ? (
            <>
              <button className="btn_save" onClick={onClickSave}>
                저장
              </button>
              <button className="btn_cancle" onClick={onClickEditngCancel}>
                취소
              </button>
            </>
          ) : (
            <button className="btn_edit" onClick={onClickEditngStart}>
              수정
            </button>
          )}
        </div>
      </div>
      <div className={`form ${downloadable ? "" : "not_download"}`}>
        <div className="field">
          <div className="rows">
            {isCompany ? (
              <InputRow
                title="관리번호"
                titleClass="color_blue"
                condition={tradeMark.editing}
                content={tradeMark.manage_number}
                onChange={(e) =>
                  setTradeMark({ ...tradeMark, manage_number: e.target.value })
                }
              />
            ) : (
              <InputRow
                title="관리번호"
                titleClass="color_blue"
                condition={tradeMark.editing}
                content={tradeMark.agent_manage_number}
                onChange={(e) =>
                  setTradeMark({
                    ...tradeMark,
                    agent_manage_number: e.target.value,
                  })
                }
              />
            )}
            <div className="row no_data" />
            <div className="row no_data">
              <h2 className="title color_blue" />
              <div className="btns">
                <button
                  className={
                    tradeMark.image_key
                      ? "icon_download_attached"
                      : "icon_download"
                  }
                  onClick={() => onClickDownPopShow(fileType.image)}
                />
                <button
                  className="icon_upload"
                  onClick={() => onClickUpPopShow(fileType.image)}
                />
              </div>
            </div>
          </div>
          <div className="rows">
            <SelectRow
              title="담당자"
              content={tradeMark.manager_name}
              onChange={(e) =>
                setTradeMark({
                  ...tradeMark,
                  manager_idx: e.target.value,
                  manager_name: e.target.options[e.target.selectedIndex].text,
                })
              }
              condition={isCompany && tradeMark.editing}
              contentIdx={tradeMark.manager_idx}
              selectTag={managerSelectTag}
            />
            {isCompany ? (
              <SelectRow
                title="대리인"
                content={tradeMark.agent_company_name}
                onChange={(e) =>
                  setTradeMark({
                    ...tradeMark,
                    agent_company_idx: e.target.value,
                  })
                }
                condition={tradeMark.editing}
                contentIdx={tradeMark.agent_company_idx}
                selectTag={agentListSelectTag}
              />
            ) : (
              <SelectRow
                title="대리인 담당자"
                content={tradeMark.agent_name}
                onChange={(e) =>
                  setTradeMark({ ...tradeMark, agent_idx: e.target.value })
                }
                condition={tradeMark.editing}
                contentIdx={tradeMark.agent_idx}
                selectTag={managerSelectTag}
              />
            )}
            <div className="thumbnail">
              {tradeMark.image_key ? (
                tradeMark.image_type == "svg" ? (
                  <span>미리보기를 지원하지 않는 파일형식</span>
                ) : (
                  <img
                    src={window.$Global.getCDN(tradeMark.image_key)}
                    alt="이미지"
                    onError={(error) => console.log(error)}
                  />
                )
              ) : fileImage ? (
                <img src={fileImage} alt="sample" />
              ) : (
                <span>미리보기</span>
              )}
            </div>
          </div>
          <div className="rows">
            <FileRow
              editing={tradeMark.editing}
              title="선행 상표조사 의뢰"
              haveFile={Boolean(tradeMark.survey_file_cnt)}
              onClickDownPopShow={() => onClickDownPopShow(fileType.survey)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.survey)}
            />
          </div>
          <div className="rows">
            <ToggleButtonRow
              title="선행 상표조사"
              condition={tradeMark.editing}
              selected={tradeMark.survey_req}
              toggleSelected={() =>
                setTradeMark({
                  ...tradeMark,
                  survey_req: Number(!tradeMark.survey_req),
                })
              }
            />
            <InputRow
              title="상표 명칭"
              condition={tradeMark.editing}
              content={tradeMark.name}
              onChange={(e) =>
                setTradeMark({ ...tradeMark, name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="field">
          <div className="rows">
            <InputAndFileRow
              editing={tradeMark.editing}
              title="출원번호"
              titleClassName="color_blue"
              content={tradeMark.apply_number}
              haveFile={Boolean(tradeMark.apply_file_cnt)}
              onChange={(e) =>
                setTradeMark({ ...tradeMark, apply_number: e.target.value })
              }
              onClickDownPopShow={() => onClickDownPopShow(fileType.apply)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.apply)}
            />
            <DatePickerRow
              title="출원일"
              condition={tradeMark.editing}
              data={tradeMark.apply_at}
              onChangeDatePicker={(e) =>
                setTradeMark({ ...tradeMark, apply_at: new Date(e).getTime() })
              }
            />
            <SelectRow
              title="관련 제품명"
              content={
                tradeMark.product_list[0]
                  ? tradeMark.product_list[0].name
                  : null
              }
              onChange={(e) =>
                setTradeMark({
                  ...tradeMark,
                  product_list: [{ idx: e.target.value, state: 0 }],
                })
              }
              condition={tradeMark.editing}
              contentIdx={
                tradeMark.product_list[0] ? tradeMark.product_list[0].idx : 0
              }
              selectTag={productSelect}
            />
          </div>
          <div className="rows">
            <div className="row">
              <h2 className="title">상품분류 / 지정상품</h2>
              <div className="btns">
                <p>
                  {tradeMark.product_class[0]
                    ? `제 ${tradeMark.product_class[0].class}류`
                    : "미선택"}
                </p>
                {tradeMark.editing && (
                  <>
                    <button
                      className="icon_plus"
                      onClick={() => setClassificationPopupShow(true)}
                    />
                    <button
                      className="icon_badge_del"
                      onClick={() => onClickDeleteClass(0)}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <p>
                {tradeMark.product_class[0]
                  ? tradeMark.product_class[0].product
                  : "지정상품이 선택되지 않았습니다."}
              </p>
            </div>
            <NormalRow
              title="우선심사청구"
              component={
                <ToggleButtonWithSpan
                  text=""
                  className={tradeMark.editing ? "" : "not_editing"}
                  condition={{ on: "유", off: "무" }}
                  selected={tradeMark.priority_screen_req}
                  toggleSelected={() =>
                    setTradeMark({
                      ...tradeMark,
                      priority_screen_req: Number(
                        !tradeMark.priority_screen_req
                      ),
                    })
                  }
                />
              }
            />
          </div>
          {tag}
        </div>
        <div className="field">
          <div className="rows">
            <DateAndFileRow
              title="OA발행일"
              titleClassName="color_blue"
              date={tradeMark.oa[0].published_at}
              haveFile={tradeMark.oa[0].oa_content_file_cnt}
              uploadBtnShow={true}
              onClickDownPopShow={() =>
                onClickDownPopShow(fileType.oa_content, tradeMark.oa[0].idx)
              }
              onClickUpPopShow={() =>
                onClickUpPopShow(fileType.oa_content, tradeMark.oa[0].idx)
              }
            />
            <DateAndFileRow
              title="OA의견서 접수"
              date={tradeMark.oa[0].opinion_upload_at}
              haveFile={tradeMark.oa[0].oa_opinion_file_cnt}
              uploadBtnShow={true}
              onClickDownPopShow={() =>
                onClickDownPopShow(fileType.oa_opinion, tradeMark.oa[0].idx)
              }
              onClickUpPopShow={() =>
                onClickUpPopShow(fileType.oa_opinion, tradeMark.oa[0].idx)
              }
            />
          </div>
          <div className="rows">
            <DatePickerRow
              title="OA 제출일"
              condition={tradeMark.editing}
              data={tradeMark.oa[0].submission_at}
              onChangeDatePicker={(e) =>
                onChangeOA(0, "submission_at", new Date(e).getTime())
              }
            />
            <div className="row">
              <h2 className="title">OA연장 횟수</h2>
              {tradeMark.editing ? (
                <select
                  defaultValue={tradeMark.oa[0].extension_cnt}
                  style={{ width: 60 }}
                  onChange={(e) =>
                    onChangeOA(0, "extension_cnt", Number(e.target.value))
                  }
                >
                  <option value={0}>0회</option>
                  <option value={1}>1회</option>
                  <option value={2}>2회</option>
                </select>
              ) : (
                <p>{tradeMark.oa[0].extension_cnt}회</p>
              )}
            </div>
            <DatePickerRow
              title="OA기한"
              condition={tradeMark.editing}
              data={tradeMark.oa[0].deadline}
              onChangeDatePicker={(e) =>
                onChangeOA(0, "deadline", new Date(e).getTime())
              }
            />
          </div>
          <div className="rows">
            <DateAndFileRow
              title="출원공고 결정일"
              date={tradeMark.oa[0].apply_decision_at}
              uploadBtnShow={true}
              haveFile={tradeMark.oa[0].oa_apply_file_cnt}
              onClickDownPopShow={() =>
                onClickDownPopShow(fileType.oa_apply, tradeMark.oa[0].idx)
              }
              onClickUpPopShow={() =>
                onClickUpPopShow(fileType.oa_apply, tradeMark.oa[0].idx)
              }
            />
            <DatePickerRow
              title="출원 공고일"
              condition={tradeMark.editing}
              data={tradeMark.oa[0].apply_at}
              onChangeDatePicker={(e) =>
                onChangeOA(0, "apply_at", new Date(e).getTime())
              }
            />
            <DatePickerRow
              title="이의신청 기간"
              condition={tradeMark.editing}
              data={tradeMark.oa[0].objection_deadline}
              onChangeDatePicker={(e) =>
                onChangeOA(0, "objection_deadline", new Date(e).getTime())
              }
            />
          </div>
        </div>
        <div className="field">
          <div className="rows">
            <ToggleButtonRow
              title="이의신청 유무"
              condition={tradeMark.editing}
              selected={tradeMark.objection_req}
              toggleSelected={() =>
                setTradeMark({
                  ...tradeMark,
                  objection_req: Number(!tradeMark.objection_req),
                })
              }
            />
          </div>
          {Boolean(tradeMark.objection_req) && (
            <div className="rows">
              <DateAndFileRow
                title="이의신청일"
                date={tradeMark.objection_at}
                uploadBtnShow={true}
                haveFile={tradeMark.objection_contetn_file_cnt}
                onClickDownPopShow={() =>
                  onClickDownPopShow(
                    fileType.objection_content,
                    tradeMark.oa[0].idx
                  )
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(
                    fileType.objection_content,
                    tradeMark.oa[0].idx
                  )
                }
              />
              <DateAndFileRow
                title={`이의신청 의견서 접수${
                  tradeMark.objection_opinion_upload_at ? "일" : ""
                }`}
                date={tradeMark.objection_opinion_upload_at}
                uploadBtnShow={true}
                haveFile={tradeMark.objection_opinion_file_cnt}
                onClickDownPopShow={() =>
                  onClickDownPopShow(
                    fileType.objection_opinion,
                    tradeMark.oa[0].idx
                  )
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(
                    fileType.objection_opinion,
                    tradeMark.oa[0].idx
                  )
                }
              />
              <DatePickerRow
                title="이의신청 의견서 제출일"
                condition={tradeMark.editing}
                data={tradeMark.objection_submission_at}
                onChangeDatePicker={(e) =>
                  setTradeMark({
                    ...tradeMark,
                    objection_submission_at: new Date(e).getTime(),
                  })
                }
              />
            </div>
          )}
        </div>
        <div className="field">
          <div className="rows">
            <DatePickerRow
              title="등록 결정일"
              titleClassName="color_blue"
              condition={tradeMark.editing}
              data={tradeMark.regist_decision_at}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  regist_decision_at: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="등록료 납부 기한"
              titleClassName="color_blue"
              condition={tradeMark.editing}
              data={tradeMark.regist_payment_deadline}
              onChangeDatePicker={(e) =>
                setTradeMark({
                  ...tradeMark,
                  regist_payment_deadline: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="등록 납부일/등록일"
              condition={tradeMark.editing}
              data={tradeMark.regist_at}
              onChangeDatePicker={(e) =>
                setTradeMark({ ...tradeMark, regist_at: new Date(e).getTime() })
              }
            />
          </div>
          <div className="rows">
            <InputAndFileRow
              title="등록번호"
              content={tradeMark.regist_number}
              editing={tradeMark.editing}
              haveFile={tradeMark.register_file_cnt}
              onChange={(e) =>
                setTradeMark({ ...tradeMark, regist_number: e.target.value })
              }
              onClickDownPopShow={() => onClickDownPopShow(fileType.regist)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.regist)}
            />
          </div>
        </div>
        <div className="field">
          <div className="rows">
            <div className="row" style={{ width: "590px" }}>
              <h2 className="title">
                {tradeMark.renewal_year_cnt}차 갱신 가능 기간
              </h2>
              {window.$Global.convertDate(tradeMark.renewal_deadline_start)} ~{" "}
              {window.$Global.convertDate(tradeMark.renewal_deadline_end)}
              <div>
                <button
                  className={
                    tradeMark.renewal_first_deadline_end
                      ? "icon_download_attached"
                      : "icon_download"
                  }
                  onClick={() => onClickDownPopShow(fileType.expiration)}
                />
                <button
                  className="icon_upload"
                  onClick={() => onClickUpPopShow(fileType.expiration)}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
            <ToggleButtonAndDatePickerRow
              title={`분할 납부 여부\n2차납부(예정)일`}
              condition={tradeMark.editing}
              selected={tradeMark.divide_pay}
              date={tradeMark.second_payment_scheduled_at}
              toggleSelected={() =>
                setTradeMark({
                  ...tradeMark,
                  divide_pay: Number(!tradeMark.divide_pay),
                })
              }
              onChangeDatePicker={(date) => {
                setTradeMark({
                  ...tradeMark,
                  second_payment_scheduled_at: new Date(date).getTime(),
                });
              }}
            />
          </div>
        </div>
        {/*<div className="field">
          <div className="rows">
            <ToggleButtonRow
              title="갱신신청 유무"
              condition={tradeMark.editing}
              selected={tradeMark.renewal_req}
              toggleSelected={() =>
                setTradeMark({
                  ...tradeMark,
                  renewal_req: Number(!tradeMark.renewal_req),
                })
              }
            />
          </div>
          {Boolean(tradeMark.renewal_req) && (
            <>
              <div className="rows">
                <ToggleButtonRow
                  title="갱신지시"
                  condition={tradeMark.editing}
                  selected={tradeMark.renewal_order}
                  toggleSelected={() =>
                    setTradeMark({
                      ...tradeMark,
                      renewal_order: Number(!tradeMark.renewal_order),
                    })
                  }
                />
                <DateAndFileRow
                  title="갱신일"
                  date={tradeMark.renewal_at}
                  uploadBtnShow={true}
                  haveFile={tradeMark.renewal_file_cnt}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(fileType.renewal)
                  }
                  onClickUpPopShow={() => onClickUpPopShow(fileType.renewal)}
                />
                <DatePickerRow
                  title="등록 납부일/등록일"
                  condition={tradeMark.editing}
                  data={tradeMark.renewal_regist_at}
                  onChangeDatePicker={(e) =>
                    setTradeMark({
                      ...tradeMark,
                      renewal_regist_at: new Date(e).getTime(),
                    })
                  }
                />
              </div>
              <div className="rows">
                <InputRow
                  title="갱신 차수"
                  type="number"
                  inputClassName="small_input"
                  condition={tradeMark.editing}
                  content={tradeMark.renewal_cnt}
                  onChange={(e) =>
                    setTradeMark({ ...tradeMark, renewal_cnt: e.target.value })
                  }
                />
                <DatePickerRow
                  title="2차 기간 만료일"
                  condition={tradeMark.editing}
                  data={tradeMark.renewal_second_deadline}
                  onChangeDatePicker={(e) =>
                    setTradeMark({
                      ...tradeMark,
                      renewal_second_deadline: new Date(e).getTime(),
                    })
                  }
                />
                <ToggleButtonAndDatePickerRow
                  title={`분할 납부 여부\n2차납부(예정)일`}
                  condition={tradeMark.editing}
                  selected={tradeMark.renewal_divide_pay}
                  date={tradeMark.renewal_second_scheduled_at}
                  toggleSelected={() =>
                    setTradeMark({
                      ...tradeMark,
                      renewal_divide_pay: Number(!tradeMark.renewal_divide_pay),
                    })
                  }
                  onChangeDatePicker={(date) => {
                    setTradeMark({
                      ...tradeMark,
                      renewal_second_scheduled_at: new Date(date).getTime(),
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>*/}
      </div>
      {classificationPopupShow && (
        <ProductClassificationPopup
          classificationList={classificationList}
          onClickSelect={onClickSelectClass}
          onClickClose={() => setClassificationPopupShow(false)}
        />
      )}
      {selectPopupShow && (
        <ProductSelectPopup
          productClassNo={productClassNo}
          onClickClose={() => setSelectPopupShow(false)}
          onClickConfirm={onClickConfirm}
        />
      )}
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
        setFileImage={setFileImage}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
        setFileImage={setFileImage}
      />
    </div>
  );
}

export default TradeMarkDetail;
