import React from 'react';
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function InnerDatePickerAndFileRow({title, titleClass, preventTitle, data, condition, setKey, haveFile, onChangeDatePickerJson, onClickDownPopShow, onClickUpPopShow, uploadBtnShow}) {
    let date_content = (data.date !== null);

    return (
        <div className="row">
            <h2 className={`title ${titleClass || ""}`}>{title}{(date_content && !preventTitle) ? '일' : ''}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            <div className="btns">
                {
                    date_content
                    ? <span>{window.$Global.convertDate(data.date)} </span>
                    : <>
                        <span className="date_txt">기한</span>
                        <span>{data.inner_date ? window.$Global.convertDate(data.inner_date) : 'N/A'} </span>
                      </>
                }
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                        condition && <DatePickerCustom data={data.date} onChangeDatePicker={date => onChangeDatePickerJson(setKey, date)} readOnly={date_content}/>
                    }
                    <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClickDownPopShow}/>
                    {
                        uploadBtnShow ? <button className="icon_upload" onClick={onClickUpPopShow}/> : ''
                    }
                </div>
            </div>
        </div>
    );
};

export default InnerDatePickerAndFileRow;
