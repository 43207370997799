import React from 'react';

let state =  React.createContext({
    mobilePopup: {
        mobileShow: false,
        mobileHeaderTitle: "",
        historyMobileHeaderTitle: "", // 2뎁스로 이뤄진 팝업일 경우 이전 타이틀 저장하는 값
    },
    setMobilePopup: () => {}
});

export default state;
