import "./css/CompetitorMain.scss";
import logo from "../../assets/images/common/logo_findBiz.svg";
import img from "../../assets/images/competitor/img-search.png";
import iconMagnifierBlue from "../../assets/images/competitor/icon-magnifier-blue.png";
import iconBinocular from "../../assets/images/competitor/icon-binocular.png";
import iconPaperList from "../../assets/images/competitor/icon-paper-list.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import InfoAPI from "../../API/info";

const CompetitorMain = () => {
  const [searchInput, setSearchInput] = useState("");
  const [mySearchCompany, setMySearchCompany] = useState([]); // 최근 조회한 기업
  const history = useHistory();

  useEffect(() => {
    test();
  }, []);
  const getCompanyInfo = () => {
    history.push({ pathname: "list", state: { keyword: searchInput } });
  };

  const onClickSearch = (input) => {
    history.push({ pathname: "list", state: { keyword: input } });
  };

  const test = () => {
    InfoAPI.getCompanyResearchHistory().then((res) => {
      setMySearchCompany(res.data.sort((a, b) => b.updated_at - a.updated_at));
    });
  };

  return (
    <div id="CompetitorMain">
      <div className="header_wrap">
        <h2>궁금한 기업이 있으신가요?</h2>
        <div className="header_grid">
          <div>
            <p>
              관심기업 정보<span>검색</span>
              <p className="new_red">NEW</p>
            </p>
            <p>기업명을 검색하여 재무·기술·정부사업 참여현황 등</p>
            <br />
            <p> 기업의 최신 현황과 분석정보를 확인해 보세요. </p>
            <div className="search_wrapper">
              <input
                type="text"
                placeholder="입력하세요"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") getCompanyInfo();
                }}
              />
              <i className="icon_search_gray" onClick={getCompanyInfo} />
            </div>
          </div>
          <div className="header_list_wrapper">
            <h5>최근 조회한 기업</h5>
            <div className="header_list_items">
              {mySearchCompany?.slice(0, 3).map((item) => {
                return (
                  <div
                    className="header_list_item"
                    onClick={() => {
                      history.push(
                        `/competitor/details?applicant=${item.company_idx}&bizno=${item.company_bizno}`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <img src={logo} />
                      <p>{item.company_name}</p>
                    </div>
                    <i className="icon_arrow_right_s" />
                  </div>
                );
              })}
            </div>
          </div>
          {/*<div className="header_list_wrapper">*/}
          {/*  <h5>One Click 서비스</h5>*/}
          {/*  <div className="header_list_items">*/}
          {/*    <div className="header_list_item">*/}
          {/*      <p className="one_click_text">*/}
          {/*        <span>우리회사</span> 경쟁사 탐색하기*/}
          {/*      </p>*/}
          {/*      <img src={iconMagnifierBlue} />*/}
          {/*    </div>*/}
          {/*    <div className="header_list_item">*/}
          {/*      <p className="one_click_text">*/}
          {/*        <span>우리회사</span> 상세 정보보기*/}
          {/*      </p>*/}
          {/*      <img src={iconBinocular} />*/}
          {/*    </div>*/}
          {/*    <div className="header_list_item">*/}
          {/*      <p className="one_click_text">*/}
          {/*        <span>우리회사vs관심기업</span> 비교 분석*/}
          {/*      </p>*/}
          {/*      <img src={iconPaperList} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <p>*/}
          {/*    기업*/}
          {/*    <br />*/}
          {/*    <span>비교분석 서비스</span>*/}
          {/*  </p>*/}
          {/*  <div>*/}
          {/*    <p>*/}
          {/*      여러 기업을*/}
          {/*      <br />*/}
          {/*      종합적인 지표로 비교분석 할 수 있습니다.*/}
          {/*    </p>*/}
          {/*    <i className="icon_3d_like" />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="competitor_list">
        <div>
          <h5>
            지금 가장 HOT한 <span>기업 분석정보</span> TOP 5
          </h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>현대두산인프라코어(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>굴착기</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("현대두산인프라코어(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>린나이코리아(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>가스보일러</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("린나이코리아(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>현대로템(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>전동기기</p>
                  <p>영구자석</p>
                  <p>방사선</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("현대로템(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>CJ제일제당(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>스마트팩토리</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("CJ제일제당(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>한국타이어앤테크놀로지(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>생필품제조</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("한국타이어앤테크놀로지(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
          </div>
        </div>
        <div>
          <h5>지금 가장 HOT한 기업 TOP 5</h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)포스코건설</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>건설토목</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("(주)포스코건설");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>코오롱글로벌(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>건설토목</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("코오롱글로벌(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>한국기초과학지원연구원</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>연구원</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("한국기초과학지원연구원");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>캐논코리아(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>복합기</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("캐논코리아(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>대한전선(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>통신공사</p>
                  <p>전기공사</p>
                  <p>건설</p>
                  <p>정보통신</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("대한전선(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="search_company_list">
        <div>
          <h5>
            지금 가장 HOT한 <span>기업</span> TOP 5
          </h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)포스코건설</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>건설토목</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("(주)포스코건설");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>코오롱글로벌(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>건설토목</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("코오롱글로벌(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>한국기초과학지원연구원</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>연구원</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("한국기초과학지원연구원");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>캐논코리아(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>복합기</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("캐논코리아(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>대한전선(주)</p>
                <p>-</p>
                <div className="item_text_category">
                  <p>통신공사</p>
                  <p>전기공사</p>
                  <p>건설</p>
                  <p>정보통신</p>
                </div>
              </div>
              <button
                onClick={() => {
                  onClickSearch("대한전선(주)");
                }}
              >
                기업 분석정보
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="competitor_list">
        {/*<div>*/}
        {/*  <h5>우리 회사를 조회한 기업</h5>*/}
        {/*  <div className="company_info_items">*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>에스케이브로드밴드(주)</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>통신</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("에스케이브로드밴드(주)");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>(주)동명기술공단종합건축사사무소</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>설계용역</p>*/}
        {/*          <p>건설토목 계획용역</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("(주)동명기술공단종합건축사사무소");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>원투씨엠(주)</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>플랫폼 구축</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("원투씨엠(주)");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>(주)바이오니아</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>진단기</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("(주)바이오니아");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>에스케이에코플랜트(주)</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>건설토목</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("에스케이에코플랜트(주)");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <h5>기업 조회를 이용한 기업</h5>*/}
        {/*  <div className="company_info_items">*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>레인보우스케이프(주)</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>조경 수경 분수</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("레인보우스케이프(주)");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>(주)글로우원</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>전기공사</p>*/}
        {/*          <p>전동기기 영구자석 방사선</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("(주)글로우원");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>(주)파리크라상</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>생필품제조</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("(주)파리크라상");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>한화파워시스템(주)</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>충전소</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("한화파워시스템(주)");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="company_info_item">*/}
        {/*      <img src={logo} />*/}
        {/*      <div className="item_text">*/}
        {/*        <p>(주)시너지이노베이션</p>*/}
        {/*        <p>-</p>*/}
        {/*        <div className="item_text_category">*/}
        {/*          <p>방역납품</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        onClick={() => {*/}
        {/*          onClickSearch("(주)시너지이노베이션");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        기업 분석정보*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default CompetitorMain;
