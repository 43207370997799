import Pagination from "../../common/Pagination";
import { useState } from "react";
import "./css/AdminInventorList.scss";

const AdminInventorList = ({ history }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const Tbody = () => {
    return isEditing ? (
      <tbody>
        <tr>
          <td>1</td>
          <td>
            <input type="text" />
          </td>
          <td>
            <input type="text" />
          </td>
          <td>
            <input type="text" />
          </td>
          <td>
            <input type="text" />
          </td>
          <td>
            <button className="btn_save">저장</button>
            <button className="btn_cancel" onClick={handleEditing}>
              취소
            </button>
          </td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td>1</td>
          <td>김고은</td>
          <td>01012345678</td>
          <td>a1231@gooditl.com</td>
          <td>
            (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동, 창덕빌딩)
          </td>
          <td>
            <button className="btn_edit" onClick={handleEditing}>
              수정하기
            </button>
          </td>
        </tr>
      </tbody>
    );
  };
  return (
    <div id="AdminInventorList">
      <div className="header">
        <h2>발명자 리스트 확인하기</h2>
        <button onClick={() => history.goBack()}>목록으로 돌아가기</button>
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="80px" />
            <col width="200px" />
            <col width="250px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>수정</th>
            </tr>
          </thead>
          <Tbody />
        </table>
      </div>
      <Pagination />
    </div>
  );
};

export default AdminInventorList;
