import React from "react";

let patent = React.createContext({
  idx: "", // 특허 idx
  dummy_state: "", // 키프리스 출원 여부
  editing: false, // 수정 활성화 여부
  editingClass: "not_editing", // 수정 여부
  survey_req: false, // 선행기술 조사
  company_idx: "", // 특허 회사 idx
  invention_idx: "", // 발명 idx
  invention_created_at: "", // 발명신고서 접수일
  state: 0, // 특허 상태
  nation: 0, // 국가 코드
  nation_string: "kr", // 국가
  manager_name: "", // 담당자
  manager_idx: "", // 담당자 idx
  agent_name: "", // 대리인 담당자
  agent_idx: "", // 대리인 담당자 idx
  agent_company_name: "", // 대리인
  agent_company_idx: "", // 대리인 idx
  local_agent_company_idx: "", // 현지대리인 idx
  local_agent_company_name: "", // 현재대리인
  manage_number: "", // 관리번호
  agent_manage_number: "", // 대리인 관리번호
  family_cnt: 0, // family 출원 수
  inventor: [], // 발명자
  product: [{ name: "", idx: "", state: 0 }], // 제품명
  claim: false, // 심사청구 여부
  priority_screen: false, // 우선심사 여부
  screen_req_at: "", // 심사 청구일
  screen_deadline: "", // 심사청구 기한
  draft_order_at: false, // 초안 작성 지시
  type: "", // 출원 유형
  apply_order_at: 0, // 출원 지시
  apply_number: "", // 출원번호
  apply_at: "", // 출원일
  priority_at: "", // 우선일
  inventionName: "", // 발명의 명칭
  invention_name: "", // 발명의 명칭
  invention_name_en: "", // 영문 명칭
  priority_claim_deadline_domestic: "", // 국내 우선권 주장 기한
  priority_claim_deadline_foreign: "", // 해외 우선권 주장 기한
  pct_apply_deadline: "", // pct 출원 기한
  register_at: "", // 등록일
  register_decision_at: "", // 등록 결정일
  register_deadline: "", // 등록기한
  register_number: "", // 등록번호
  expiration_at: "", // 존속 기간 만료일
  divideApplication: { value: false, date: null }, // 분할 출원
  division_deadline: "", // 분할 출원기한
  draftReceivingDate: { date: null, inner_date: null }, // 초안 접수일
  draftInventorsDate: { date: null, inner_date: null }, // 초안 발명자 검토일
  draftManagerDate: { date: null, inner_date: null }, // 초안 담당자 검토일
  draftModifiedUploadDate: "", // 초안 수정본 전달일
  draftFinalDate: { date: null, inner_date: null }, // 초안 최종본 접수일
  paymentDeadline: "", // 관납료 납부 기한
  payment_year: null, // 납부년차
  claimCount: { independence: "", dependent: "" }, // 독립항/종속항
  annual_payment_manage_company: "", // 연차관리 회사
  annual_payment_deadline: "", // 연차료 기한
  translation: false, // 번역문 ON, OFF 상태
  translateDraftReceivingDate: { date: null, inner_date: null }, // 번역문 초안 접수일
  translateDraftInventorsDate: { date: null, inner_date: null }, // 번역문 초안 발명자 검토일
  translateDraftManagerDate: { date: null, inner_date: null }, // 번역문 초안 담당자 검토일
  translateDraftFinalDate: { date: null, inner_date: null }, // 번역문 초안 최종본 접수일
  draft_translation_order_at: null, // 번역문 초안 작성 지시
  draft_translation_modified_upload_at: null, // 번역문 초안 수정본 전달일
  translation_deadline: "", // 번역문 기한
  ca_deadline: "", // CA 기한
  ListOa: [], // OA 변수 리스트
  fileList: {
    survey_file: [],
    draft: [],
    draft_researcher: [],
    draft_manager: [],
    draft_modified: [],
    draft_final: [],
    patent_apply: [],
    patent_register: [],
    oa: [],
  }, // 신규 특허 등록 파일들을 담을 변수
  merge: [], // 미국 우선권
  mergeTag: [],
  ep: [], // 유럽
  pace_order_at: "", // PACE 신청일
  dp: "",
  international_report_upload_at: null, // 국제조사보고서 업로드일
  domestic_level_deadline: null, // 국내 진입 기한
  inventorListTag: [], // 발명자 목록
  productListTag: [], // 제품 목록
  process: {}, // 절차관리
  joint_apply: [],
  setPatent: () => {},
});

export default patent;
