import React, { useEffect, useState } from "react";
import "./css/MarketInfoSideBar.scss";
import CommonAPI from "../../API/common";
import { useHistory } from "react-router-dom";

const MarketInfoSideBar = () => {
  const history = useHistory();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const onClickView = (idx1, idx2) => {
    history.push(`list?category_idx1=${idx1}&category_idx2=${idx2}`);
  };

  const getCategory = () => {
    CommonAPI.getMarketCategory().then((res) => {
      res.data.category_list.map((el) => {
        el.isOpen = false;
      });
      setCategory(res.data.category_list);
    });
  };
  return (
    <div id="MarketInfoSideBar">
      <ul className="parent_category_items">
        {category?.map((item, index) => {
          return (
            <li
              className="parent_category_item"
              key={item.idx}
              onClick={() => {
                item.isOpen = !item.isOpen;
                setCategory([...category]);
              }}
            >
              {item.name}
              {item.isOpen && (
                <ul className="child_category_items">
                  {item.child.map((child_item, idx) => {
                    return (
                      <li
                        className="child_category_item"
                        onClick={() => onClickView(item.idx, child_item.idx)}
                      >
                        {child_item.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MarketInfoSideBar;
