import React, { useEffect, useState } from "react";
import "./css/ResearchTechMain.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import message from "../../const/message";
import convertCategory from "../marketInfo/js/convertCategory";
import ResearchTechCategory from "./ResearchTechCategory";
import ResearchTechRelatedPopup from "./ResearchTechRelatedPopup";

function ResearchTechMain() {
  const history = useHistory();
  const location = useLocation();
  const { category_idx1, category_idx2 } = qs.parse(location.search);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [relatedTaskArr, setRelatedTaskArr] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const count = 10;

  useEffect(() => {
    CommonAPI.getMarketCategory().then((res) => setCategory(res.data));
    getList(curPage);
  }, [category_idx2]);

  const getList = (page) => {
    let payload = {
      page: page,
      count: count,
      category1_idx: category_idx1,
      category2_idx: category_idx2,
    };
    CommonAPI.getResearchList(payload).then((res) => {
      setData(res.data);
      setCurPage(page);
    });
  };

  const searchList = (page) => {
    if (keyword === "") {
      alert("키워드를 입력하세요");
      return;
    }
    let payload = {
      page: page,
      count: count,
      category1_idx: category_idx1,
      category2_idx: category_idx2,
      keyword: keyword,
    };
    CommonAPI.getResearchList(payload).then((res) => {
      setData(res.data);
      setCurPage(page);
      setSearchState(true);
    });
  };

  const onClickCancel = () => {
    setKeyword("");
    setSearchState(false);
    getList(1);
  };

  const changePage = (page) => {
    return searchState ? getList(page) : searchList(page);
  };

  const onClickNext = (selectTarget) => {
    history.push(
      `patent?idx=${selectTarget}&category_idx1=${category_idx1}&category_idx2=${category_idx2}`
    );
  };

  const getCategory = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    return result.b_category + " > " + result.s_category;
  };

  const searchRelated = (idx) => {
    CommonAPI.getResearchRelated(idx).then((res) => {
      setRelatedTaskArr(res.data);
      setShowPopup(true);
    });
  };

  return (
    <div id="ResearchTechMain">
      <ResearchTechCategory />
      <div>
        <div className="wrap_category">
          <div className="category">
            <p>{getCategory()}</p>
          </div>
          <Link to="category">
            <span>목록으로 돌아가기</span>
            <i className="icon_more_arrow_right" />
          </Link>
        </div>

        <div className="keyword_find pc">
          <h2>선행기술 조사명 검색</h2>
          <div className="search_wrapper">
            <input
              type="text"
              placeholder="입력하세요"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && searchList(1)}
            />
            {!searchState ? (
              <button
                className="icon_search_gray ir_txt"
                onClick={() => searchList(1)}
              >
                검색
              </button>
            ) : (
              <button
                className="icon_del_small_with_bg ir_txt"
                onClick={onClickCancel}
              >
                검색 취소
              </button>
            )}
          </div>
        </div>
        <table>
          <colgroup>
            <col width="5%" />
            <col width="70%" />
            <col width="20%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th>순번</th>
              <th>선행기술 조사명</th>
              <th>관련 정부사업 과제</th>
              <th>선 택</th>
            </tr>
          </thead>
          <tbody>
            {data.items?.map((item, idx) => {
              return (
                <tr key={item.idx}>
                  <td>{(curPage - 1) * 10 + idx + 1}</td>
                  <td>{item.title}</td>
                  <td>
                    <button
                      className="relate_research"
                      onClick={() => searchRelated(item.idx)}
                    >
                      연관 정부과제 보기
                      <i className="icon_arrow_right_s" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon_next_black"
                      onClick={() => onClickNext(item.idx)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Pagination
          curPage={curPage}
          lastNum={data.last}
          onClick={changePage}
        />
      </div>
      {showPopup && (
        <ResearchTechRelatedPopup
          data={relatedTaskArr}
          onChange={setShowPopup}
        />
      )}
    </div>
  );
}

export default ResearchTechMain;
