import React from 'react';
import './css/ToggleButtonWithSpan.scss';
import ToggleButton from "./ToggleButton";

function ToggleButtonWithSpan({className, text, condition, selected, toggleSelected}) {
    return (
        <div className="ToggleButtonWithSpan">
            {
                condition &&
                <span>{text} {selected ? condition.on : condition.off}</span>
            }
            <ToggleButton
                className={className}
                selected={Boolean(selected)}
                toggleSelected={toggleSelected}
            />
        </div>
    );
}

export default React.memo(ToggleButtonWithSpan);
