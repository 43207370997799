import React, { useState, useEffect } from "react";
import CompanyAPI from "../../../API/company";
import InfoAPI from "../../../API/info";
import "../css/MyCltGroupPop.scss";

const MyCltGroupPop = ({
  myClt,
  setIsOpenGroupPop,
  memberIdx,
  getCltGroup,
  getCtlAll,
  flagType,
}) => {
  const [groupNames, setGroupNames] = useState([]); // 거래처 그룹
  const [addedGroupName, setAddedGroupName] = useState("");
  const [updatedGroupNames, setUpdatedGroupNames] = useState({}); // 수정될 거래처 그룹명

  useEffect(() => {
    setGroupNames(myClt);
  }, [myClt]);

  const updateGroup = (idx, newValue) => {
    CompanyAPI.updateCltGroup({
      group_name: newValue,
      group_idx: idx,
      flag: flagType,
      owner_idx: memberIdx,
    }).then((res) => {
      //console.log("updateGroup:", res);
      if (res.data?.code === "00") {
        alert("수정되었습니다.");
      }
      getCltGroup();
      getCtlAll();
    });
  };

  // 거래처 그룹 삭제
  const delGroup = (groupIdx) => {
    const confirmResult = window.confirm("해당 그룹을 삭제하시겠습니까?");
    if (confirmResult) {
      CompanyAPI.deleteCltGroup({
        owner_idx: memberIdx,
        group_idx: groupIdx,
        flag: flagType,
      }).then((res) => {
        //console.log("deleteCltGroup:", res);
        if (res.data.code === "00") {
          getCltGroup();
          getCtlAll();
        }
      });
    }
  };

  // 거래처기업 그룹 추가
  const addCltGroup = (group_name) => {
    CompanyAPI.addCltGroup({
      owner_idx: memberIdx,
      group_name: group_name,
      flag: flagType,
    }).then((res) => {
      //console.log("addCltGroup:", res);
      if (res.data.code === "00") {
        getCltGroup();
        getCtlAll();
        setAddedGroupName("");
      } else {
        alert("이미 존재하는 폴더명입니다.");
      }
    });
  };

  const clickCltGroup = () => {
    if (addedGroupName.trim() === "") {
      alert("추가할 그룹명을 입력해주세요.");
    } else {
      addCltGroup(addedGroupName);
    }
  };

  const handleGroupNameChange = (idx, newName) => {
    setUpdatedGroupNames((prev) => ({
      ...prev,
      [idx]: newName,
    }));
  };

  return (
    <div id="MyCltGroupPop">
      <h4>{flagType === 1 ? "거래처" : "경쟁사"} 그룹 관리</h4>
      <div className="add_box">
        <input
          type="text"
          value={addedGroupName}
          className="add_input"
          placeholder="추가할 그룹명을 입력해주세요."
          onChange={(e) => setAddedGroupName(e.target.value)}
        />
        <div className="btns">
          <button className="add btn" onClick={() => clickCltGroup()}>
            추가하기
          </button>
        </div>
      </div>
      {groupNames?.length ? (
        <div className="group_list custom_scroll">
          <ul>
            {groupNames.map((item) => (
              <li key={item.idx}>
                <input
                  type="text"
                  value={updatedGroupNames[item.idx] || item.group_name}
                  onChange={(e) =>
                    handleGroupNameChange(item.idx, e.target.value)
                  }
                />
                <div className="btns">
                  <button
                    className="mod btn"
                    onClick={() =>
                      updateGroup(
                        item.idx,
                        updatedGroupNames[item.idx] || item.group_name
                      )
                    }
                  >
                    수정
                  </button>
                  <button
                    className="del btn"
                    onClick={() => delGroup(item.idx)}
                  >
                    삭제
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        "데이터가 없습니다."
      )}
      <button className="close" onClick={() => setIsOpenGroupPop(false)}>
        <i className="icon_exit_gray"></i>
      </button>
    </div>
  );
};

export default MyCltGroupPop;
