import React, {useState, useEffect, useContext} from 'react';
import './css/IncidentPopup.scss';
import PatentAPI from "../../API/patent";
import memberContext from "../../store/Member";
import InfiniteScroll from "react-infinite-scroll-component";
import {nationType} from "../../types/nationType";

function IncidentPopup({disableMerge, onClickAdd, onClickClose}) {
    const count = 50;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const {auth} = useContext(memberContext);
    const [mergePatent, setMergePatent] = useState([]);
    const [mergeAddPatentList, setMergeAddPatentList] = useState([]); // 추가한 특허 리스트
    const [mergePatentTag, setMergePatentTag] = useState([]);
    const [tbody, setTbody] = useState([]);
    const [select, setSelect] = useState([]);
    const [selectPatentIndex, setSelectPatentIndex] = useState(-1);
    const [selectPatent, setSelectPatent] = useState("선택 안함");
    const [optionShow, setOptionShow] = useState(false);
    const [mouse, setMouse] = useState(false);

    let nation_ref = React.useRef();
    let patent_ref = React.useRef();
    let type_ref = React.useRef();

    useEffect(() => {
        if (!disableMerge) {
            getList();
        }
    }, [auth]);

    useEffect(() => {
        let tag = mergeAddPatentList.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.apply_number}</td>
                    <td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
                    <td>{item.invention_name}</td>
                    <td>
                        <button className="icon_badge_del" onClick={() => onClickPatentDelete(index)}/>
                    </td>
                </tr>
            );
        });
        setTbody(tag);
    }, [mergeAddPatentList]);

    const getList = (type) => {
        if (window.$Global.checkAuth('특허회사', auth)) {
            PatentAPI.getMergePatentList({page: curPage, count: count}).then(res => {
                let items = [];
                if (type == "next") {
                    items = res.data.items.concat(mergePatent);
                } else {
                    items = res.data.items;
                }
                let result = (
                    items.map((item, index) => {
                        return <li key={index} data-idx={index} tabIndex={0}
                                    onBlur={onBlur}
                                    onClick={() => {
                                        setSelectPatentIndex(index);
                                        setSelectPatent(items[index].apply_number);
                                        setOptionShow(false);
                                    }}>{item.apply_number}</li>
                    })
                );
                if (result.length) {
                    setMergePatentTag(result);
                } else {
                    setSelectPatent("병합 가능 특허 없음");
                }
                setMergePatent(items);
                setCurPage(curPage +1);

                if (curPage == res.data.last) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            });
        }
    };

    const onClickPatentAdd = () => {
        let item = mergePatent[selectPatentIndex];
        if (!item) {
            alert("선택한 특허가 없습니다");
            return;
        }
        setMergeAddPatentList(arr => {
            return arr.includes(item) ? arr : [...arr, item];
        });
    };

    const onClickPatentDelete = (index) => {
        let arr = mergeAddPatentList.slice(0);
        arr.splice(index, 1);
        setMergeAddPatentList(arr);
    };

    const onClickSubmit = () => {
        if (nation_ref.current.value == 'default') {
            alert("국가를 선택해주세요");
            return;
        }
        let payload = {
            nation: nation_ref.current.value,
            type: type_ref.current ? type_ref.current.value : 0,
            patent_list: mergeAddPatentList,
            sort: patent_ref.current ? Number(patent_ref.current.value) : 0
        };
        onClickAdd(payload);
    };

    const onChangeNation = (e) => {
        let nation = e.target.options[e.target.selectedIndex].text.toString();
        let arr_select = [];
        if (nation === 'KR') {
            arr_select.push(
                <React.Fragment key={e.target.value}>
                    <label style={{clear: "none"}}>
                        <span>특허/실용</span>
                        <select ref={patent_ref}>
                            <option value={0}>특허</option>
                            <option value={1}>실용신안</option>
                        </select>
                    </label>
                    <label>
                        <span>출원 유형</span>
                        <select ref={type_ref}>
                            <option value={0}>원출원</option>
                            <option value={1}>분할</option>
                            <option value={2}>변경</option>
                            <option value={3}>국내우선권</option>
                        </select>
                    </label>
                </React.Fragment>
            );
        } else if (nation === 'PCT') {
            
        } else if (nation === 'EP' || nation === 'JP' || nation === 'TW') {
            arr_select.push(
                <React.Fragment key={e.target.value}>
                    <label style={{both: "clear"}}>
                        <span>출원 유형</span>
                        <select ref={type_ref} >
                            <option value={0}>원출원</option>
                            <option value={1}>분할</option>
                        </select>
                    </label>
                </React.Fragment>
            );
        } else if (nation === 'US') {
            arr_select.push(
                <React.Fragment key={e.target.value}>
                    <label>
                        <span>출원 유형</span>
                        <select ref={type_ref}>
                            <option value={0}>원출원</option>
                            <option value={4}>CA</option>
                            <option value={5}>DA</option>
                            <option value={6}>CIP</option>
                            <option value={7}>재출원</option>
                        </select>
                    </label>
                </React.Fragment>
            );
        } else { // 중국과 나머지 국가들
            arr_select.push(
                <React.Fragment key={e.target.value}>
                    <label>
                        <span>특허/실용</span>
                        <select ref={patent_ref}>
                            <option value={0}>특허</option>
                            <option value={1}>실용신안</option>
                        </select>
                    </label>
                    <label style={{both: "clear"}}>
                        <span>출원 유형</span>
                        <select ref={type_ref}>
                            <option value={0}>원출원</option>
                            <option value={1}>분할</option>
                        </select>
                    </label>
                </React.Fragment>
            );
        }
        setSelect(arr_select);
    };

    const onBlur = () => {
        if (!mouse) {
            setOptionShow(false);
        }
    };

    const labelTag =
        (<label onClick={e => e.preventDefault()}>
            <span>병합 출원 추가</span>
            <div className="custom_select no_focus_outline" tabIndex={0} onBlur={onBlur}>
                <div className="default" data-idx={-1} tabIndex={0} onClick={() => setOptionShow(!optionShow)} onBlur={onBlur}>{selectPatent}</div>
                    <ul id="scrollable" className="custom_scroll custom_hover" onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                        <InfiniteScroll
                            dataLength={mergePatentTag.length}
                            next={() => getList('next')}
                            hasMore={hasMore}
                            scrollableTarget="scrollable">
                            {optionShow && mergePatentTag}
                        </InfiniteScroll>
                    </ul>
            </div>
            <button className="icon_badge_more_blue" onClick={onClickPatentAdd} style={{position: "relative", top: 10, left: 5}}/>
        </label>);

    return (
        <div id="FamilyPopup">
            <div className="popup_header">
                <h2 className="title">새로운 사건 등록</h2>
                <div className="btns">
                    <button className="btn_add" onClick={onClickSubmit}>추가하기</button>
                    <button className="icon_exit" onClick={onClickClose}></button>
                </div>
            </div>

            <div className="popup_content">
                <label style={{float: "left", marginRight: 29}}>
                    <span>국가선택</span>
                    <select ref={nation_ref} onChange={onChangeNation}>
                        <option value="default">선택안함</option>
                        { nationType.map((item, idx) => <option key={idx} value={idx}>{item}</option>) }
                    </select>
                </label>
                {select}
                {
                    !disableMerge
                    &&
                    <>
                        {labelTag}
                        <div className="wrap_list">
                            <table className="list">
                                <thead>
                                    <tr>
                                        <th width="8%">순번</th>
                                        <th>특허번호</th>
                                        <th width="8%">국가</th>
                                        <th>발명의 명칭</th>
                                        <th width="5%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tbody}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default IncidentPopup;
