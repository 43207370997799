import React, {useContext, useEffect} from "react";
import './css/PopupUpDownload.scss';
import MemberContext from "../../store/Member";

function PopupUpDownload({show, showType, uploadFileViewList, downloadFileViewList, onChangeFile,
                          onClickClose, onClickUpload, onClickDelete, onClickDownloadDelete, onClickDownload}) {
    const fileRef = React.useRef();
    const {auth} = useContext(MemberContext);
    const isAdmin = window.$Global.checkAuth("특허회사_관리자", auth);
    const isUpload = showType == "upload";
    const isDownload = showType == "download";

    useEffect(() => {
        if (!fileRef.current) return;
        fileRef.current.value = null;
    });

    const onClickFile = () => {
        fileRef.current.click();
    };

    let files = uploadFileViewList.concat(downloadFileViewList).map((file, index) => {
        return (
            <tr key={file.idx}>
                <td>{index + 1}</td>
                <td>{`${file.file_name}.${file.file_type}`}</td>
                <td><button className="btn_delete" onClick={() => isNaN(file.idx) ? onClickDownloadDelete(file.idx) : onClickDelete(index)}>삭제하기</button></td>
            </tr>
        );
    });

    let download_files = downloadFileViewList.map((file, index) => {
        return (
            <tr key={`${file.idx}_down`}>
                <td>{index + 1}</td>
                <td>{`${file.file_name}.${file.file_type}`}</td>
                <td><button className="icon_download" onClick={() => onClickDownload(file.idx)}/></td>
            </tr>
        );
    });

    if (!show) return null;

    return (
        <div id="PopupUpDownload">
            <button className="icon_exit" onClick={onClickClose}/>
            {
                isUpload &&
                <div className="upload_area">
                    <div className="header">
                        <h2 className="title">직무발명서 양식 등록/삭제</h2>
                        <div className="btns">
                            <button className="btn_attach" onClick={onClickFile}>파일 선택</button>
                        </div>
                    </div>
                    <div className="wrap_table">
                        <table>
                            <thead>
                                <tr>
                                    <th>순번</th>
                                    <th width="65%">파일명</th>
                                    <th>삭제하기</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files}
                            </tbody>
                        </table>
                    </div>
                    <button className="btn_upload" onClick={onClickUpload}>업로드하기</button>
                    <input type="file" onChange={onChangeFile} multiple ref={fileRef} style={{display: 'none'}} />
                </div>
            }
            {
                isDownload
                &&
                <div className="download_area">
                    <div className="header">
                        <h2 className="title">우리회사 직무발명서 양식</h2>
                    </div>
                    <div className="wrap_table">
                        <table>
                            <thead>
                                <tr>
                                    <th>순번</th>
                                    <th width="60%">파일명</th>
                                    <th>다운로드</th>
                                </tr>
                            </thead>
                            <tbody>
                                {download_files}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}

export default React.memo(PopupUpDownload);
