import React, {useEffect, useState} from 'react';
import './css/FreeUsApplyList.scss';
import AdminAPI from "../../API/admin/index";
import InfiniteScroll from "react-infinite-scroll-component";

function FreeUsApplyList({history}) {

    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        let payload = {
            page: 1,
            count: count,
        };
        getList(payload);
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    const getList = (payload, type) => {
        AdminAPI.getFreeUsList(payload).then(res => {
            let get_data = res.data;
            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
            }
            setData(get_data);

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const nextList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            let state = item.state == 1 ? "심사중" : item.state == 2 ? "승인 완료" : item.state == 3 ? "반려" : "대기";
            return (
                <tr>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.idea_desc}</td>
                    <td>{item.licence_number}</td>
                    <td>{item.company_phone}</td>
                    <td className={item.state == 3 ? "color_red" : item.state == 2 ? "color_blue" : ""}>{state}</td>
                    <td><button className="icon_more_red" onClick={() => onClickPageGo(item.idx)}/></td>
                </tr>
            );
        }));
    };

    const onClickPageGo = (idx) => {
        history.push(`/freeApply/${idx}`);
    };

    return (
        <div id="FreeUsApplyList">
            <div className="header">
                <h2 className="title">미국 출원 요청 목록</h2>
            </div>
            <div className="notice">현재 총 {data.total_count || 0}건의 출원 요청이 있습니다.</div>
            <div id="scrollableDiv" className="wrap_list hover">
                <InfiniteScroll
                    dataLength={tbody.length ? data.items.length : 0}
                    next={nextList}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv">
                    <table className="list">
                        <thead>
                            <tr>
                                <th width="5%">순번</th>
                                <th>회사명</th>
                                <th>핵심 특허 내용</th>
                                <th>사업자 등록번호</th>
                                <th>회사 전화번호</th>
                                <th>승인 상태</th>
                                <th>상세보기</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default FreeUsApplyList;
