import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "./css/PopupPatentApprovalApply.scss";
import PatentAPI from "../../../API/patent";
import {ApprovalState} from "../../approval/interface";
import message from "../../../const/message";
import {getApprovalStateToStr} from "../../approval/js/getApprovalStateToStr";
import CheckBox from "../../common/CheckBox";
import _ from "lodash";
import memberContext from "../../../store/Member";

function PopupPatentApprovalApply({show, patentInfo, patent_idx, memberList, approvalList, onClickClose}) {
	const history = useHistory();
	const maxApprovaler = 8;
	const drawArray = new Array(maxApprovaler).fill(false);
	const {idx} = useContext(memberContext);
	const [data, setData] = useState({items: []});
	const [checkList, setCheckList] = useState([]);
	const [checkMemberList, setCheckMemberList] = useState([]);
	const convertMemberList = memberList.filter(item => item.idx != idx);
	const isNew = !Boolean(data?.items?.length);

	useEffect(() => {
		setCheckList([]);
		setCheckMemberList([]);
	}, [show]);

	useEffect(() => {
		setCheckList(new Array(memberList.length).fill(false));
	}, [memberList]);

	useEffect(() => {
			let data = approvalList;
			if (Boolean(data?.items?.length)) {
				data.approval_idx = data.items[0].idx;
				data.manager_name = data.items[0].manager_name;
				data.created_at = data.items[0].created_at;
				let answer_list = data.items.map(item => item.answer);
				let min = Math.min.apply(Math, answer_list);
				let max = Math.max.apply(Math, answer_list);
				let answer = 0;

				if (max === ApprovalState.rejectCode) {
					answer = ApprovalState.rejectCode
				} else if (min === ApprovalState.awaitCode) {
					answer = ApprovalState.awaitCode;
				} else if (min === ApprovalState.approvalCode) {
					answer = ApprovalState.approvalCode;
				}
				data.answer = answer;
			}
			setData(data);
	}, [approvalList]);

	const changeApproval = (index, approval_idx, answer) => {
		const confirmMsg = ApprovalState.approvalCode === answer ? message.confirmApproval : message.confirmApprovalReject;
		if (window.confirm(confirmMsg)) {
			PatentAPI.acceptPatentApproval(patent_idx, approval_idx, answer).then(() => {
				let copy = _.cloneDeep(data);
				copy.items[index].answer = answer;
				setData(copy);
			});
		}
	}

	const onChangeCheckList = (idx) => {
		let copyCheckMemberList = _.cloneDeep(checkMemberList);
		let copy = _.cloneDeep(checkList);
		let checked = !copy[idx];
		let checkError = false;
		copy[idx] = checked;

		if (checked && copyCheckMemberList.length === maxApprovaler) {
			alert(message.errorLengthOver);
			checkError = true;
			copy[idx] = false;
		}

		setCheckList(copy);

		if (checkError) return;

		let selectIdx = memberList[idx].idx;
		let findIndex = copyCheckMemberList.findIndex(item => item.idx === selectIdx);
		if (findIndex === -1) {
			copyCheckMemberList.push(memberList[idx]);
		} else {
			copyCheckMemberList.splice(findIndex, 1);
		}
		setCheckMemberList(copyCheckMemberList);
	}

	const onClickMemberRegister = () => {
		let member_idx_list = [];

		checkList.forEach((item, idx) => item && member_idx_list.push(memberList[idx].idx));

		if (!Boolean(member_idx_list.length)) {
			alert(message.errorChoiceApproval);
			return;
		}

		PatentAPI.requestPatentApproval(patent_idx, {member_idx_list: member_idx_list}).then(() => {
			alert(message.successApproval);
			history.go(0);
		})
	}

	const onClickDeleteApproval = () => {
		if (window.confirm(message.confirmDelete)) {
			PatentAPI.deletePatentApproval(patent_idx).then(() => history.go(0));
		}
	}

	const onClickApply = () => {
		patentInfo.setPatent({...patentInfo, apply_order_at: new Date().getTime()})
		onClickClose();
	};

	if (!show) return null;

	return (
		<div id="PopupPatentApprovalApply">
			<div className="popup">
				<div className="head">
					<h2 className="title">출원 결재 신청</h2>
					<div className="btns">
					{
						Boolean(!patentInfo.apply_order_at)
						&&
						(
							isNew
							? <button className="btn_instruction" onClick={onClickMemberRegister}>상신하기</button>
							:
							<>
							{
								(data.answer === ApprovalState.approvalCode) && <button className="btn_instruction" onClick={onClickApply}>출원 지시하기</button>
							}
								<button className="btn_delete" onClick={onClickDeleteApproval}>결재 삭제</button>
							</>
						)
					}
						<button className="icon_exit" onClick={onClickClose}/>
					</div>
				</div>
				{
					isNew
					?
					<>
						<table className="add_approval_tale">
							<caption>결재라인</caption>
							<tbody>
								<tr>
								{
									drawArray.map((item, idx) => <td key={idx}>{idx + 1}</td>)
								}
								</tr>
								<tr>
								{
									drawArray.map((item, idx) => <td key={idx}>{checkMemberList[idx]?.name}</td>)
								}
								</tr>
							</tbody>
						</table>
						<div className="wrap_table custom_scroll">
							<table>
								<thead>
								<tr>
									<th>결재자 선택</th>
									<th>결재자</th>
								</tr>
								</thead>
								<tbody>
								{
									convertMemberList.map((item, idx) => {
										return (
											<tr key={idx}>
												<td>
													<CheckBox checked={checkList[idx]} onChangeChecked={() => onChangeCheckList(idx)}/>
												</td>
												<td>{item.name}</td>
											</tr>
										)
									})
								}
								</tbody>
							</table>
						</div>
					</>
					:
					<>
						<table>
							<tbody>
							<tr>
								<th>신청자</th>
								<td>{data.manager_name}</td>
							</tr>
							<tr>
								<th>기안일자</th>
								<td>{window.$Global.convertDate(data.created_at)}</td>
							</tr>
							<tr>
								<th>결재상태</th>
								<td>{getApprovalStateToStr(data.answer)}</td>
							</tr>
							</tbody>
						</table>
						<div className="wrap_table custom_scroll">
							<table>
								<thead>
								<tr>
									<th>결재</th>
									<th>검토</th>
								</tr>
								</thead>
								<tbody>
								{
									data.items.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.name}</td>
												<td>
												{
													((item.answer === ApprovalState.awaitCode) && item.member_idx === idx)
													?
													<div className="btns">
														<button className="btn_success" onClick={() => changeApproval(index, item.idx, ApprovalState.approvalCode)}>결재하기</button>
														<button className="btn_fail" onClick={() => changeApproval(index, item.idx, ApprovalState.rejectCode)}>반려하기</button>
													</div>
													: getApprovalStateToStr(item.answer)
												}
												</td>
											</tr>
										)
									})
								}
								</tbody>
							</table>
						</div>
					</>
				}
			</div>
		</div>
	)
}

export default PopupPatentApprovalApply;