import React, { useEffect, useRef, useState } from "react";
import "../css/MyPageIpnowAccountEdit.scss";
import Menu from "../common/Menu";
import DataInput from "../common/DataInput";
import config from "../../../const/config";
import KeywordListWithPerson from "../../common/KeywordListWithPerson";
import { Link, useHistory, useLocation } from "react-router-dom";
import PopupIPAutoAdd from "../../signup/PopupIPAutoAdd";
import SignupAlertAddSuccess from "../../signup/SignupAlertAddSuccess";
import qs from "query-string";
import MemberAPI from "../../../API/member";
import Validator from "../../../util/validator";
import InfoAPI from "../../../API/info";

let { IMP } = window;
function MyPageIpnowAccountEdit() {
  const history = useHistory();
  const location = useLocation();
  const { testMode } = qs.parse(location.search);
  const [info, setInfo] = useState({});
  const [historyInfo, setHistoryInfo] = useState({});
  const [myInfoEdit, setMyInfoEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);

  const [newPwd, setNewPwd] = useState("");
  const [newPwdConfirm, setNewPwdConfirm] = useState("");

  const [checkRegPwd, setCheckRegPwd] = useState(false);
  const [checkSamePwd, setCheckSamePwd] = useState(false);

  const [timer, setTimer] = useState(0); // 디바운싱 타이머
  const [keywordList, setKeywordList] = useState([]);

  const [ipEdit, setIpEdit] = useState(false);
  const [searchInfoIP, setSearchInfoIp] = useState({
    unq_key: "",
    name: "",
    company_code: "",
  });
  const [popupShow, setPopupShow] = useState(false);
  const [popupSuccessShow, setPopupSuccessShow] = useState(false);

  const isSns = !Boolean(info.sns_type);
  const isTestMode = testMode == "test";

  let emailRef = useRef();
  let nameRef = useRef();
  let newPwdRef = useRef();
  let newPwdConfirmRef = useRef();

  useEffect(() => {
    MemberAPI.getInfo().then((res) => {
      res.data.auth = res.data.rank;
      setInfo(res.data);
      setHistoryInfo(res.data);
    });
  }, []);

  useEffect(() => {
    let checkReg = config.checkReg;

    if (newPwd.match(checkReg)) {
      setCheckRegPwd(false);
    } else {
      setCheckRegPwd(true);
    }
  }, [newPwd]);

  useEffect(() => {
    if (newPwd == newPwdConfirm) {
      setCheckSamePwd(false);
    } else {
      setCheckSamePwd(true);
    }
  }, [newPwd, newPwdConfirm]);

  const onClickEditingCancel = () => {
    setInfo(historyInfo);
    setMyInfoEdit(false);
  };

  const onClickEditingEnd = async () => {
    let arr = [];
    if (emailRef.current) arr.push(emailRef);
    if (nameRef.current) arr.push(nameRef);

    if (Validator.refValidator(arr)) {
      let email = emailRef.current?.value;
      let name = nameRef.current?.value;

      await updateInfo({ email: email, name: name }).then(() => {
        setInfo({ ...info, email: email, name: name });
      });
    }
  };

  const resetPassword = () => {
    newPwdRef.current.value = "";
    newPwdConfirmRef.current.value = "";
  };

  const onClickPasswordEditingCancel = () => {
    resetPassword();
    setPasswordEdit(false);
  };

  const onClickPasswordEditingEnd = async () => {
    if (Validator.refValidator([newPwdRef, newPwdConfirmRef])) {
      let pwd = newPwdRef.current.value;

      if (checkRegPwd) {
        alert(config.regxMsg);
        return;
      }
      if (checkSamePwd) {
        alert("비밀번호가 일치하지 않습니다");
        return;
      }
      await updateInfo({ password: pwd }).then(() => {
        resetPassword();
        setPasswordEdit(false);
      });
    }
  };

  const updateInfo = async (payload) => {
    await MemberAPI.updateInfo(payload).then(() => {
      window.$Global.saveToast();
      setMyInfoEdit(false);
    });
  };

  const onClickCertification = () => {
    if (!info.phone || info.phone.length != 11) {
      alert("휴대폰 번호 11자리를 입력해주세요");
      return;
    }

    IMP.certification(
      {
        // param
        phone: info.phone,
      },
      (rsp) => {
        // callback
        if (rsp.success) {
          let payload = {
            imp_uid: rsp.imp_uid,
            phone: info.phone,
          };

          MemberAPI.phoneAuthentication(payload).then(() => {
            alert("정상적으로 인증되었습니다");
            history.go(0);
          });
        } else {
          console.log("실패");
        }
      }
    );
  };

  const checkIPAdd = async (selectIP) => {
    if (!info.company_code) {
      await MemberAPI.connectApplicant(searchInfoIP.unq_key);
    }

    let object = {
      company_idx: info.company_idx,
      member_idx: info.idx,
      company_code: searchInfoIP.company_code,
    };
    await setTimeout(() => {
      let patent_length = selectIP.patent.length;
      let td_length = selectIP.tradeMark.length;
      let design_length = selectIP.design.length;

      if (patent_length) {
        let payload = {
          ...object,
        };
        payload.patent_list = selectIP.patent.map((item) => item.idx);
        InfoAPI.insertPatent(payload)
          .then()
          .catch((err) => err);
      }
      if (td_length) {
        let payload = {
          ...object,
        };
        payload.td_list = selectIP.tradeMark.map((item) => item.idx);
        InfoAPI.insertTrademark(payload)
          .then()
          .catch((err) => err);
      }
      if (design_length) {
        let payload = {
          ...object,
        };
        payload.design_list = selectIP.design.map((item) => item.idx);
        InfoAPI.insertDesign(payload)
          .then()
          .catch((err) => err);
      }

      if (patent_length || td_length || design_length) {
        alert(
          "선택하신 IP를 등록되는데에는 일정시간이 소요됩니다\n새로고침 후 정상적으로 이용 가능합니다"
        );
      }
      setPopupSuccessShow(true);
    }, 100);
  };

  const onClickSelectIPAdd = async (json) => {
    setPopupShow(false);
    await checkIPAdd(json);
  };

  const getPersonList = (value) => {
    if (value.length < 2) return;

    setSearchInfoIp({ ...searchInfoIP, name: value });
    if (timer) clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      await InfoAPI.getCompany(value, 0, 0).then((res) =>
        setKeywordList(res.data)
      );
    }, 800);
    setTimer(newTimer);
  };

  const onClickManualInput = () => {
    setKeywordList([]);
  };

  const onClickAutoInput = (item) => {
    setSearchInfoIp({
      unq_key: item.unq_key,
      name: item.name,
      company_code: item.company_code,
    });
    setKeywordList([]);
    setPopupShow(true);
  };

  const onClickIPSuccess = () => {
    setPopupSuccessShow(false);
    history.go(0);
  };

  return (
    <div id="MyPageIpnowAccountEdit">
      <Menu number={1} />
      <div className="form">
        <div className="area">
          <div className="header">
            <h2>사용자 정보</h2>
            {myInfoEdit ? (
              <div className="btns">
                <button className="btn_save" onClick={onClickEditingEnd}>
                  저장
                </button>
                <button className="btn_cancle" onClick={onClickEditingCancel}>
                  취소
                </button>
              </div>
            ) : (
              <button onClick={() => setMyInfoEdit(true)}>수정</button>
            )}
          </div>
          <div className="info">
            <DataInput
              edit={false}
              title="회사"
              text={info.company_name || "개인"}
              placeholder="회사명을 입력해주세요"
            />
            <DataInput
              edit={false}
              title="가입 유형"
              text={window.$Global.convertAccountType(info.company_type)}
            />
            <DataInput edit={false} title="아이디" text={info.id} />
            <DataInput
              edit={myInfoEdit}
              title="이메일"
              text={info.email}
              setRef={emailRef}
              placeholder="이메일을 입력해주세요"
            />
            <DataInput
              edit={!Boolean(Number(info.is_authentication)) && myInfoEdit}
              title="가입자 휴대폰"
              text={info.phone}
              placeholder="휴대폰 번호를 입력해주세요"
              onChange={(e) => setInfo({ ...info, phone: e.target.value })}
              btnShow={!Boolean(Number(info.is_authentication))}
              btnText="본인인증"
              onClick={onClickCertification}
            />
          </div>
        </div>
        {isSns && (
          <div className="area">
            <div className="header">
              <h2>비밀번호 변경</h2>
              {passwordEdit ? (
                <div className="btns">
                  <button
                    className="btn_save"
                    onClick={onClickPasswordEditingEnd}
                  >
                    저장
                  </button>
                  <button
                    className="btn_cancle"
                    onClick={onClickPasswordEditingCancel}
                  >
                    취소
                  </button>
                </div>
              ) : (
                <button onClick={() => setPasswordEdit(true)}>수정</button>
              )}
            </div>
            <div className="info">
              <DataInput
                edit={true}
                className={passwordEdit ? "" : "no_click"}
                title="새 비밀번호"
                inputType="password"
                setRef={newPwdRef}
                placeholder="변경할 비밀번호를 입력해주세요"
                onChange={(e) => setNewPwd(e.target.value)}
              />
              <DataInput
                edit={true}
                className={passwordEdit ? "" : "no_click"}
                title="새 비밀번호 확인"
                inputType="password"
                setRef={newPwdConfirmRef}
                placeholder="비밀번호를 다시 입력해주세요"
                onChange={(e) => setNewPwdConfirm(e.target.value)}
              />
            </div>
            {passwordEdit && (
              <div className="info">
                <p className={`data msg ${checkRegPwd ? "show" : ""}`}>
                  {config.regxMsg}
                </p>
                <p className={`data msg ${checkSamePwd ? "show" : ""}`}>
                  비밀번호가 일치하지 않습니다
                </p>
              </div>
            )}
          </div>
        )}
        {window.$Global.checkAuth("특허회사", info.auth) && (
          <div className="area">
            <div className="header">
              <h2>공개 IP(특허, 상표, 디자인) 등록하기</h2>
              {ipEdit ? (
                <div className="btns">
                  <button className="btn_save" onClick={checkIPAdd}>
                    등록
                  </button>
                  <button
                    className="btn_cancle"
                    onClick={() => setIpEdit(false)}
                  >
                    취소
                  </button>
                </div>
              ) : (
                <button
                  className="btn_ip_register"
                  onClick={() => setIpEdit(true)}
                >
                  등록하기
                </button>
              )}
            </div>
            {ipEdit && (
              <div className="info">
                <DataInput
                  edit={ipEdit}
                  title="이름"
                  text={searchInfoIP.name}
                  placeholder="검색하세요"
                  onChange={(e) => getPersonList(e.target.value)}
                />
                <DataInput
                  edit={ipEdit}
                  title="출원인 등록번호"
                  text={searchInfoIP.company_code}
                  placeholder="출원인 등록번호를 입력해주세요"
                  onChange={(e) =>
                    setSearchInfoIp({
                      ...searchInfoIP,
                      company_code: e.target.value,
                    })
                  }
                />
                <KeywordListWithPerson
                  arr={keywordList}
                  onClickManualInput={onClickManualInput}
                  onClickAutoInput={onClickAutoInput}
                />
              </div>
            )}
          </div>
        )}
        {(window.$Global.checkAuth("슈퍼계정", info.auth) ||
          window.$Global.checkAuth("투자자", info.auth)) && (
          <div className="area">
            <div className="header">
              <h2>회원 탈퇴하기</h2>
              <Link to="/mypage/withdrawal" className="btn_del">
                탈퇴하기
              </Link>
            </div>
          </div>
        )}
        {isTestMode && (
          <div className="area">
            <div className="header">
              <h2>비즈내비 멤버십을 더 이상 이용하지 않는다면</h2>
              <Link
                to="#"
                onClick={() => alert("개발 중인 기능입니다")}
                className="btn_del"
              >
                구독 해지 하기
              </Link>
            </div>
          </div>
        )}
      </div>
      {popupShow && (
        <PopupIPAutoAdd
          company_code={searchInfoIP.company_code}
          onClickSelectIPAdd={onClickSelectIPAdd}
        />
      )}
      {popupSuccessShow && <SignupAlertAddSuccess onClick={onClickIPSuccess} />}
    </div>
  );
}

export default MyPageIpnowAccountEdit;
