import React, { useContext, useEffect, useState } from "react";
import ConsultingAPI from "../../API/consulting";
import Pagination from "../common/Pagination";
import "./css/ConsultComMain.scss";
import { Link, useHistory } from "react-router-dom";
import memberContext from "../../store/Member";
import { search } from "es5-ext/reg-exp/#";
import _ from "lodash";
import ConsultComDetail from "./ConsultComDetail";
import ConsultSideBar from "./ConsultSideBar";

function ConsultComMain() {
  const { auth } = useContext(memberContext);
  const history = useHistory();
  const [curPage, setCurPage] = useState(1);
  const [resData, setResData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isListView, setIsListView] = useState(true);
  const isLogin = auth != null && auth != undefined;

  useEffect(() => {
    let params = {
      page: curPage,
      count: 10,
    };

    ConsultingAPI.getList(params).then((res) => {
      console.log(res.data);
      setResData(res.data.items);
    });
  }, [curPage]);

  const handleToggleFavorite = (item) => {
    ConsultingAPI.updateConsultFavoriteItem(item.idx).then((res) =>
      console.log(res)
    );

    let copy = _.cloneDeep(resData);

    copy.map((el) => {
      if (el.idx == item.idx) {
        el.is_favorite = !el.is_favorite;
      }
    });

    setResData(copy);
  };

  return (
    <div id="consultComMain">
      <div className="consultComTitle">
        <p>개인 컨설턴트 찾기</p>
        <p>
          비즈내비에서 정부 · 공공기관 · 지자체 사업, 과제 공고를 지원, 수행할
          때 필요한 개인컨설턴트를 찾아보세요.
        </p>
      </div>
      <div className="sitemap_wrapper">
        <div className="sitemap">
          <p>HOME</p>
          <i className="icon_arrow_right_s" />
          <p>컨설턴트 찾기</p>
          <i className="icon_arrow_right_s" />
          <p>리스트</p>
        </div>
        {isLogin && (
          <div className="consult_nav">
            <p>
              개인 컨설턴트 등록하기
              <i className="icon-write-outline" />
            </p>
            <p>
              즐겨찾기한 컨설턴트
              <i className="icon_fav_on" />
            </p>
          </div>
        )}
      </div>
      <div className="consultComMain">
        <ConsultSideBar />
        <div className="consult_content_wrapper">
          {isLogin && (
            <div className="consult_tab">
              <div
                className={isListView && "tab_focus_on"}
                onClick={() => setIsListView(true)}
              >
                컨설턴트 리스트
              </div>
              <div
                className={!isListView && "tab_focus_on"}
                onClick={() => setIsListView(false)}
              >
                내가 등록한 컨설턴트
              </div>
            </div>
          )}
          <div className="consult_search_box">
            <div className="consult_list_sort">
              <select>
                <option>최신 등록순</option>
              </select>
            </div>
            <div className="search_bar">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm.length > 0 ? (
                <i
                  className="icon_del_small_with_bg_gray"
                  onClick={() => setSearchTerm("")}
                />
              ) : (
                <i className="icon_search_gray" />
              )}
            </div>
          </div>
          <div className="consult_items">
            {resData.map((item) => {
              console.log(item);
              return (
                <div className="consult_item">
                  <div className="consult_img_box">
                    <img
                      src={window.$Global.getCDN(item.img_key)}
                      alt="consultItem"
                    />
                  </div>
                  <div className="consult_item_content">
                    <div className="content_data">
                      <div>{item.company_name}</div>
                      <div>{item.field}</div>
                      <div>
                        <p>주 소</p>
                        <span>{item.address}</span>
                      </div>
                      <div>
                        <p>대표번호</p>
                        <span>{item.phone}</span>
                      </div>
                      <div>
                        <p>이메일</p>
                        <span>{item.email}</span>
                      </div>
                    </div>
                    <div className="content_icon">
                      <i
                        className={
                          item.is_favorite ? "icon_fav_on" : "icon_fav_off"
                        }
                        onClick={() => handleToggleFavorite(item)}
                      />
                      <button
                        onClick={() =>
                          history.push(`/consultCom/detail/${item.idx}`)
                        }
                      >
                        소개페이지 바로가기
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultComMain;
