import React from 'react';

function FileRow({title, titleClassName, haveFile, onClickDownPopShow, onClickUpPopShow}) {
    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClickDownPopShow}/>
                <button className="icon_upload" onClick={onClickUpPopShow}/>
            </div>
        </div>
    );
}

export default FileRow;
