import React from "react";

function InputRow({title, titleClass, condition, content, onChange}) {

    return (
        <div className="row">
            <h2 className={`title ${titleClass || ''}`}>{title}</h2>
            {
                condition
                    ? <input type="text" value={content || ""} onChange={onChange}/>
                    : <p>{content ? content : 'N/A'}</p>
            }
        </div>
    );
}
export default InputRow;
