import React from "react";
import CustomSwitch from "./common/CustomSwitch";
import UserAuthRoute from "./common/UserAuthRoute";
import RnDPlanFirst from "../components/businessPlan/R&D/planOne/RnDPlanFirst";
import RnDPlanSecond from "../components/businessPlan/R&D/planOne/RnDPlanSecond";
import RnDPlanThird from "../components/businessPlan/R&D/planOne/RnDPlanThird";
import RnDPlanFourth from "../components/businessPlan/R&D/planOne/RnDPlanFourth";
import RnDPlanFifth from "../components/businessPlan/R&D/planOne/RnDPlanFifth";
import BusinessPlanMain from "../components/businessPlan/BusinessPlanMain";

const BusinessPlanRoute = ({ match }) => {
  return (
    <>
      <CustomSwitch>
        <UserAuthRoute
          exact
          path={`${match.path}/main`}
          requireAuth={true}
          component={BusinessPlanMain}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/first`}
          requireAuth={true}
          component={RnDPlanFirst}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/second`}
          requireAuth={true}
          component={RnDPlanSecond}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/third`}
          requireAuth={true}
          component={RnDPlanThird}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/fourth`}
          requireAuth={true}
          component={RnDPlanFourth}
        />
        <UserAuthRoute
          exact
          path={`${match.path}/rnd/fifth`}
          requireAuth={true}
          component={RnDPlanFifth}
        />
      </CustomSwitch>
    </>
  );
};

export default BusinessPlanRoute;
