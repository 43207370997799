import React, {useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import "./css/MyTrade.scss";
import _ from "lodash";
import TradeAPI from "../../API/trade";
import CommonContext from "../../store/Common";
import Validator from "../../util/validator";

function MyTrade({history}) {
    const {isMobile} = useContext(CommonContext);
    const [more, setMore] = useState([true, true]);
    const [tabType, setTabType] = useState(0);
    const [menuTab, setMenuTab] = useState(0);
    const [list, setList] = useState([]);

    const [keyword, setKeyword] = useState('');
    const [keywordList, setKeywordList] = useState([]);
    const keywordRef = useRef();

    const isSell = (tabType == 0);
    const isBuy = (tabType == 1);
    const isInterest = (tabType == 2);

    let process = list.filter(item => item.state < 1) || [];
    let success = list.filter(item => item.state == 1) || [];

    useEffect(() => {
        TradeAPI.getMyTradeHistory(tabType).then(res => {
            setList(res.data)
            setMenuTab(0);
        });

        if (tabType == 2) {
            getKeywordList();
        }
    }, [tabType]);

    useEffect(() => {
        drawLiTag();
    }, [menuTab]);

    const onClickTab = (depth) => {
        setTabType(depth);
        setMore([true, true]);
    }

    const onClickMore = (depth) => {
        let copy = _.cloneDeep(more);
        copy[depth] = !copy[depth];
        setMore(copy);
    }

    const drawLiTag = (arr) => {
        const not_msg = '거래 정보가 없습니다';
        arr = arr || [];
        let result = [];
        if (isMobile) {
            if (arr.length == 0) {
                result = <li className="alert_not_info">{not_msg}</li>;
            } else {
                result =
                    arr.map((item, idx) => {
                        return (
                            <li key={idx}>
                                <Link to={`/trade/view/${item.idx}`}>
                                    <img src={window.$Global.getCDN(item.thumbnail_key)} className="item_img" alt="이미지"/>
                                    <div className="inner">
                                        <p className="category">{item.product_list[0]}{item.product_list.length > 1 && ' 외 ' + (item.product_list.length - 1) + '건'}</p>
                                        <p className="item_title">{item.name}</p>
                                        <p className="item_price">{window.$Global.commaify(item.price)}원</p>
                                    </div>
                                    {
                                        isSell
                                        &&
                                        <div className="circle">{item.state == 1 ? '완료' : `${item.proposal_cnt}명`}</div>
                                    }
                                    {
                                        isBuy
                                        &&
                                        <div className="circle">
                                            {
                                                Boolean(item.state)
                                                ?   '완료'
                                                :   convertProposalState(item)
                                            }
                                        </div>
                                    }
                                </Link>
                            </li>
                        )
                    });
            }
        } else {
            if (isSell) {
                if (menuTab == 0) {
                    result =
                        process.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{process.length - idx}</td>
                                    <td>{item.name}</td>
                                    <td>{window.$Global.commaify(item.price)}</td>
                                    <td>{Boolean(item.proposal) ? '가능' : '불가'}</td>
                                    <td>
                                        <span className="color_blue">{item.proposal_cnt}건</span>
                                    </td>
                                    <td>
                                        <Link to={`/trade/view/${item.idx}`} className="icon_more_arrow_right_black"/>
                                    </td>
                                </tr>
                            );
                        });
                }

                if (menuTab == 1) {
                    result =
                        success.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{process.length - idx}</td>
                                    <td>{item.name}</td>
                                    <td>{window.$Global.commaify(item.price)}</td>
                                    <td>거래 완료</td>
                                    <td>
                                        <Link to={`/trade/view/${item.idx}`} className="icon_more_arrow_right_black"/>
                                    </td>
                                </tr>
                            );
                        });
                }
            } else if (isBuy) {
                if (menuTab == 0) {
                    result =
                        process.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{process.length - idx}</td>
                                    <td>{item.name}</td>
                                    <td>{window.$Global.commaify(item.price)}</td>
                                    <td>{convertProposalState(item)}</td>
                                    <td>
                                        <Link to={`/trade/view/${item.idx}`} className="icon_more_arrow_right_black"/>
                                    </td>
                                </tr>
                            );
                        });
                } else if (menuTab == 1) {
                    result =
                        success.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{process.length - idx}</td>
                                    <td>{item.name}</td>
                                    <td>{window.$Global.commaify(item.price)}</td>
                                    <td>완료</td>
                                    <td>
                                        <Link to={`/trade/view/${item.idx}`} className="icon_more_arrow_right_black"/>
                                    </td>
                                </tr>
                            );
                        });
                }
            }
        }

        if (result.length == 0) {
            result = [window.$Global.notTd(7, not_msg)];
        }

        return result;
    };

    const convertProposalState = (item) => {
        let str = '';
        switch (Number(item.proposal_state)) {
            case 0:
                str = '대기';
                break;
            case 1:
                str = '수락';
                break;
            case 2:
                str = '거절';
                break;
            case 3:
                str = '판매자 측 역제안';
                break;
            case 4:
                str = '구매제안'
                break;
        }
        return str;
    }


    const getKeywordList = () => {
        TradeAPI.getKeywordList().then(res => {
            setKeywordList(res.data);
        });
    }

    const onClickAdd = () => {
        if (Validator.refValidator([keywordRef])) {
            TradeAPI.addKeyword({keyword: keyword}).then(res => {
                setKeyword('');
                getKeywordList();
            });
        }
    };

    const onClickDelete = (idx) => {
        TradeAPI.deleteKeyword(idx).then(res => getKeywordList());
    }
    return (
        <div id="MyTrade">
            <div className="pc">
                <div className="menu">
                    <h2>나의 거래</h2>
                    <ul>
                        <li className={`${isSell ? 'active' : ''}`} onClick={() => onClickTab(0)}>나의 판매 목록</li>
                        <li className={`${isBuy ? 'active' : ''}`} onClick={() => onClickTab(1)}>나의 구매 목록</li>
                        <li className={`${isInterest ? 'active' : ''}`} onClick={() => onClickTab(2)}>관심 알림 설정</li>
                    </ul>
                </div>
                <div className="wrap">
                    {
                        isInterest
                        ?
                            <>
                                <h2>관심 알림 설정</h2>
                                <div className="wrap_keyword_input">
                                    <input type="text"
                                           placeholder="키워드를 입력해 주세요"
                                           className="keyword_input"
                                           value={keyword}
                                           ref={keywordRef}
                                           data-name="키워드"
                                           onChange={e => setKeyword(e.target.value)}
                                           onKeyUp={(e) => {
                                               if (e.key === 'Enter') onClickAdd()
                                           }}
                                    />
                                    <button className="btn_add" onClick={onClickAdd}>추가</button>
                                </div>
                                <div className="table-box-wrap">
                                    <div className="wrap_list" id="scrollableDiv">
                                        <table className="list keyword_list">
                                            <thead>
                                                <tr>
                                                    <th>등록된 키워드</th>
                                                    <th>삭제하기</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    keywordList.map((item, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{item.keyword}</td>
                                                                <td>
                                                                    <button className="icon_badge_del" onClick={() => onClickDelete(item.idx)}/>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        :
                        <>
                            <h2>나의 거래 확인하기</h2>
                            <ul>
                                <li className={`${menuTab == 0 ? 'active' : ''}`}  onClick={() => setMenuTab(0)}>거래중</li>
                                <li className={`${menuTab == 1 ? 'active' : ''}`}  onClick={() => setMenuTab(1)}>거래완료</li>
                            </ul>
                            <div className="table-box-wrap">
                                <div className="wrap_list" id="scrollableDiv">
                                    <table className="list">
                                        <thead>
                                        <tr>
                                            <th>순번</th>
                                            <th>상표명칭</th>
                                            <th>등록 가격</th>
                                            {
                                                isSell
                                                &&
                                                (
                                                    Boolean(menuTab)
                                                        ?   <th>진행 상태</th>
                                                        :   <>
                                                            <th>역제안여부</th>
                                                            <th>거래요청</th>
                                                        </>
                                                )
                                            }
                                            {
                                                isBuy
                                                &&
                                                <th>진행 상태</th>
                                            }
                                            <th>자세히보기</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            drawLiTag()
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="mo">
                <div className="header">
                    <button className="icon_more_arrow_left_black" onClick={() => history.replace('/trade/list')}/>
                    <h2 className="page_title">나의 거래</h2>
                </div>
                <ul className="tab">
                    <li className={`${isSell ? 'active' : ''}`} onClick={() => onClickTab(0)}>나의 판매 목록</li>
                    <li className={`${isBuy ? 'active' : ''}`} onClick={() => onClickTab(1)}>나의 구매 목록</li>
                </ul>
                {
                    isSell
                    ?
                        <>
                            <div className={`box ${more[0] ? 'active' : ''}`}>
                                <div className="b_head">
                                    <div className="inner">
                                        <h2>판매 거래 중</h2>
                                        <p>{process.length}건</p>
                                    </div>
                                    <button className={`${more[0] ? 'icon_more_arrow_up' : 'icon_more_arrow_down_black'}`} onClick={() => onClickMore(0)}/>
                                </div>
                                <ul className="b_body">
                                    {drawLiTag(process)}
                                </ul>
                            </div>
                            <div className={`box ${more[1] ? 'active' : ''}`}>
                                <div className="b_head">
                                    <div className="inner">
                                        <h2>판매 완료</h2>
                                        <p>{success.length}건</p>
                                    </div>
                                    <button className={`${more[1] ? 'icon_more_arrow_up' : 'icon_more_arrow_down_black'}`} onClick={() => onClickMore(1)}/>
                                </div>
                                <ul className="b_body">
                                    {drawLiTag(success)}
                                </ul>
                            </div>
                        </>
                    :
                        <>
                            <div className={`box ${more[0] ? 'active' : ''}`}>
                                <div className="b_head">
                                    <div className="inner">
                                        <h2>구매 거래 중</h2>
                                        <p>{process.length}건</p>
                                    </div>
                                    <button className={`${more[0] ? 'icon_more_arrow_up' : 'icon_more_arrow_down_black'}`} onClick={() => onClickMore(0)}/>
                                </div>
                                <ul className="b_body">
                                    {drawLiTag(process)}
                                </ul>
                            </div>
                            <div className={`box ${more[1] ? 'active' : ''}`}>
                                <div className="b_head">
                                    <div className="inner">
                                        <h2>구매 완료</h2>
                                        <p>{success.length}건</p>
                                    </div>
                                    <button className={`${more[1] ? 'icon_more_arrow_up' : 'icon_more_arrow_down_black'}`} onClick={() => onClickMore(1)}/>
                                </div>
                                <ul className="b_body">
                                    {drawLiTag(success)}
                                </ul>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default MyTrade;