import React, {useContext} from "react";
import DateAndFileRow from "./common/DateAndFileRow";
import NormalRow from "./common/NormalRow";
import DatePickerRow from "./common/DatePickerRow";
import DatePickerOaAndFileRow from "./common/DatePickerOaAndFileRow";
import PatentContext from "../../../store/Patent";
import typeName from "./constrant/typeName";
import _ from "lodash";

function FieldOaAgent({index, item, onClickOaRemove, onClickDownPopShow, onClickUpPopShow, onChangeDatePrckerOA}) {
    const patentInfo = useContext(PatentContext);
    let copy = _.cloneDeep(patentInfo);

    return (
        <div className="field">
            <div className="header">
                <h2 className="title">OA {index + 1}</h2>
                <button className="btn_remove" onClick={() => onClickOaRemove(index)}>삭제하기</button>
            </div>
            <div className="rows">
                <DatePickerOaAndFileRow title='OA발행일' titleClassName='color_blue' data={item.published_at}
                                        index={index} dataKey='published_at' dateBtnHide={!patentInfo.editing}
                                        uploadBtnShow={true}
                                        haveFile={Boolean(item.oa_content_file_cnt)}
                                        onChangeDatePickerJson={onChangeDatePrckerOA}
                                        onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_content, {}, index)}
                                        onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_content, {}, index)}
                />
                <DateAndFileRow title={`대응안 접수`}
                                titleClassName="color_blue"
                                date={item.opinion_upload_at}
                                uploadBtnShow={true}
                                haveFile={Boolean(item.oa_opinion_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_opinion, {modified: 0}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_opinion, {modified: 0}, index)}
                />
            </div>
            <div className="rows">
                <DateAndFileRow title={`대응안\n수정본 접수`}
                                date={item.opinion_modified_upload_at}
                                uploadBtnShow={false}
                                haveFile={Boolean(item.oa_modified_opinion_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_opinion, {modified: 1}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_opinion, {modified: 1}, index)}
                />
                <DateAndFileRow title={`대응안\n최종본 전달`}
                                date={item.opinion_final_upload_at}
                                uploadBtnShow={true}
                                haveFile={Boolean(item.oa_final_opinion_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_opinion, {modified: 2}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_opinion, {modified: 2}, index)}
                />
                <NormalRow title='OA초안작성 지시일' content={item.draft_order_at} txtType="date"/>
            </div>
            <div className="rows">
                <DateAndFileRow title={`OA초안 전달`}
                                date={item.draft_upload_at}
                                uploadBtnShow={true}
                                haveFile={Boolean(item.oa_draft_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_draft, {modified: 0}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_draft, {modified: 0}, index)}
                />
                <DateAndFileRow title={`OA수정본 접수`}
                                date={item.draft_modified_upload_at}
                                uploadBtnShow={false}
                                haveFile={Boolean(item.oa_modified_draft_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_draft, {modified: 1}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_draft, {modified: 1}, index)}
                />
                <DateAndFileRow title={`OA최종본 전달`}
                                date={item.final_upload_at}
                                uploadBtnShow={true}
                                haveFile={Boolean(item.oa_final_draft_file_cnt)}
                                onClickDownPopShow={() => onClickDownPopShow(typeName.agent_oa_draft, {modified: 2}, index)}
                                onClickUpPopShow={() => onClickUpPopShow(typeName.agent_oa_draft, {modified: 2}, index)}
                />
            </div>
            <div className="rows">
                <NormalRow title='OA제출 지시 접수' content={item.submission_order_at} txtType="date"/>
                <div className="row">
                    <h2 className="title">OA연장 횟수<i className="icon_info" data-content={window.$Global.getInfoMessage("OA연장 횟수")}/></h2>
                    {
                        patentInfo.editing
                            ?
                            <select defaultValue={item.extension_cnt} style={{width: 100}} onChange={(e) => {
                                copy.ListOa[index].extension_cnt = Number(e.target.value);
                                patentInfo.setPatent(copy);
                            }}>
                                <option value={0}>0회</option>
                                <option value={1}>1회</option>
                                <option value={2}>2회</option>
                            </select>
                            : <p>{item.extension_cnt}회</p>
                    }
                </div>
                <DatePickerRow title='OA기한'
                               titleClassName='color_blue'
                               condition={patentInfo.editing}
                               data={item.deadline}
                               dataClassName="color_red"
                               index={index}
                               dataKey='deadline' useOaFunc={true}
                               onChangeDatePicker={onChangeDatePrckerOA}
                />
            </div>
        </div>
    );
}

export default React.memo(FieldOaAgent);
