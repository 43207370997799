import React, {useEffect, useState} from "react";
import Menu from "./common/Menu";
import "./css/ManageApproval.scss";
import ToggleButton from "../common/ToggleButton";
import CompanyAPI from "../../API/company";

function ManageApproval() {
	const [checkList, setCheckList] = useState({apply: false});

	useEffect(() => {
		CompanyAPI.getCompanyConfig().then(res => {
			setCheckList({apply: Boolean(res.data.apply_approval)});
		})
	}, []);

	const toggleApply = () => {
		let toggle = !checkList.apply;
		let payload = {
			content: {
				apply_approval: Number(toggle)
			}
		};
		CompanyAPI.updateCompanyConfig(payload).then(() => {
			setCheckList({...checkList, apply: toggle});
		});
	}

	return (
		<div id="ManageApproval">
			<Menu number={7}/>
			<div className="wrap_content">
				<div className="header">
					<h2>환경설정</h2>
				</div>
				<ul className="system_list">
					<li>
						<h2>출원 결재 시스템</h2>
						<ToggleButton selected={checkList.apply} toggleSelected={toggleApply}/>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default ManageApproval;