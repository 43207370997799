import React, { useContext, useEffect, useState } from "react";
import "./css/IntroService.scss";
import { useHistory } from "react-router-dom";
import links from "../../const/links";
import CommonContext from "../../store/Common";

function IntroServiceRenew() {
  const history = useHistory();
  const { isMobile } = useContext(CommonContext);

  const redirectURL = (keyword) => {
    return history.push(`${links.intro.page}/detail?keyword=${keyword}`);
  };

  const tab_list = [
    { s_name: "중기부", name: "중소벤처기업부" },
    { s_name: "산업부", name: "산업통상자원부" },
    { s_name: "과기부", name: "과학기술정보통신부" },
    { s_name: "문체부", name: "문화체육관광부" },
    { s_name: "해수부", name: "해양수산부" },
    { s_name: "국토부", name: "국토교통부" },
    { s_name: "복지부", name: "보건복지부" },
    { s_name: "농림부", name: "농림축산식품부" },
    // {s_name: '산림청', name: '산림청'},
    { s_name: "환경부", name: "환경부" },
    // {s_name: '기상청', name: '기상청'},
    // {s_name: '원안위', name: '원자력안전위원회'},
  ];

  return (
    <div id="IntroService">
      <h2 className="work_tit">부처별 정부사업</h2>
      <div className="table">
        <div className="list">
          {tab_list.map((item, idx) => {
            return (
              <div
                key={idx}
                className="item"
                onClick={() => redirectURL(item.name)}
              >
                <img
                  src={
                    require("../../assets/images/intro/logo-korea.svg").default
                  }
                  alt="이미지"
                />
                <div className="txt_area">
                  <h2>{item.s_name}</h2>
                  <p>최신 소식 모아보기</p>
                </div>
                <button className="item_arrow" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default IntroServiceRenew;
