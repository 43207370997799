import API from '../../util/api';

export default {
    getRequestInvestmentList(payload) {
        return API.get('/investor/company/investment', {params: payload});
    },
    getRequestInvestmentView(investment_idx) {
        return API.get(`/investor/company/investment/${investment_idx}`);
    },
    getIntroduceFileList(investment_idx) {
        return API.get(`/investor/company/investment/${investment_idx}/file/introduce`);
    },
    downloadIntroduceFile(investment_idx, introduce_idx) {
        window.$Global.download(`/investor/company/investment/${investment_idx}/file/introduce/${introduce_idx}/download`);
    },
    getCoreFileList(investment_idx) {
        return API.get(`/investor/company/investment/${investment_idx}/file/core`);
    },
    downloadCoreFile(investment_idx, core_idx) {
        window.$Global.download(`/investor/company/investment/${investment_idx}/file/core/${core_idx}/download`);
    },
    getBusinessFileList(investment_idx) {
        return API.get(`/investor/company/investment/${investment_idx}/file/business`);
    },
    downloadBusinessFile(investment_idx, business_idx) {
        window.$Global.download(`/investor/company/investment/${investment_idx}/file/business/${business_idx}/download`);
    },
    getCompanyGrade(company_code) {
        return API.get(`/investor/company/grade`, {params: {company_code: company_code}});
    },
    downloadRegisterFile(patent_idx) {
        window.$Global.download(`/investor/company/grade/${patent_idx}/download`);
    },
};
