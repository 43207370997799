import "./css/CompetitorLoading.scss";

const CompetitorLoading = () => {
  return (
    <div id="CompetitorLoading">
      <p className="loading">
        <span></span>
        <span></span>
        <span></span>
      </p>
    </div>
  );
};

export default CompetitorLoading;
