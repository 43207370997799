import React from "react";

function LawfirmList({data, onClickChoice}) {
    return (
        <ul className="lawfirmMain_content_list_wrap">
            {
                data.items.map((item, idx) => {
                    return (
                        <li className="lawfirmMain_content_list" key={item.idx}>
                            <div className="lawfirmMain_content_list_img">
                                <img src={window.$Global.getCDN(item.img_key)} alt={item.title} />
                            </div>
                            <div className="lawfirmMain_content_list_text-wrap">
                                <div className="lawfirmMain_content_list_text-area">
                                    <h3 className="lawfirmMain_content_list_text-area_title">
                                        {item.title}
                                    </h3>
                                    <dl className="table_list">
                                        <dt className="table_list_title">국&nbsp;&nbsp;&nbsp;&nbsp;가</dt>
                                        <dd className="table_list_info">{item.nation}</dd>
                                        <dt className="table_list_title">주&nbsp;&nbsp;&nbsp;&nbsp;소</dt>
                                        <dd className="table_list_info">{item.address}</dd>
                                        <dt className="table_list_title">대표번호</dt>
                                        <dd className="table_list_info">{item.phone}</dd>
                                        <dt className="table_list_title">이 메 일</dt>
                                        <dd className="table_list_info">{item.email}</dd>
                                    </dl>
                                </div>
                                {
                                    Boolean(item.introduce.length)
                                    &&
                                    <div className="lawfirmMain_content_list_file-area">
                                        <span className="lawfirmMain_content_list_file-area_title">회사 소개자료</span>
                                        <button type="button" className="lawfirmMain_content_list_file-area_btn" onClick={() => onClickChoice(idx)}>회사 소개자료</button>
                                    </div>
                                }
                                <button type="button" className="btnMore" onClick={() => window.open(item.url, "_blank")}>더 알아보기</button>
                            </div>
                        </li>
                    );
                })
            }
        </ul>
    )
}

export default LawfirmList;