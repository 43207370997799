import { useParams } from "react-router-dom/cjs/react-router-dom";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupCashflow } from "../groupwarePath";

import "./scss/CashflowFind.scss";
import { useState } from "react";
import Pagination from "../../common/Pagination";

const CashflowFind = () => {
  const { type } = useParams();
  const [count, setCount] = useState(5);
  const [curPage, setCurpage] = useState(1);

  const investorData = [
    {
      logo: "../../../assets/images/cashflow/conpany_logo.png",
      name: "케이그라운드벤처스1",
      tag: "벤처캐피탈",
      description:
        "기술사업화와 스케일업을 위한 파트너, 케이그라운드 벤처스입니다.",
      contact: "02-3443-4579",
      email: "info@kground.com",
    },
    {
      logo: "../../../assets/images/cashflow/conpany_logo.png",
      name: "케이그라운드벤처스2",
      tag: "벤처캐피탈",
      description:
        "기술사업화와 스케일업을 위한 파트너, 케이그라운드 벤처스입니다.",
      contact: "02-3443-4579",
      email: "info@kground.com",
    },
    {
      logo: "../../../assets/images/cashflow/conpany_logo.png",
      name: "케이그라운드벤처스3",
      tag: "벤처캐피탈",
      description:
        "기술사업화와 스케일업을 위한 파트너, 케이그라운드 벤처스입니다.",
      contact: "02-3443-4579",
      email: "info@kground.com",
    },
    {
      logo: "../../../assets/images/cashflow/conpany_logo.png",
      name: "케이그라운드벤처스4",
      tag: "벤처캐피탈",
      description:
        "기술사업화와 스케일업을 위한 파트너, 케이그라운드 벤처스입니다.",
      contact: "02-3443-4579",
      email: "info@kground.com",
    },
    {
      logo: "../../../assets/images/cashflow/conpany_logo.png",
      name: "케이그라운드벤처스5",
      tag: "벤처캐피탈",
      description:
        "기술사업화와 스케일업을 위한 파트너, 케이그라운드 벤처스입니다.",
      contact: "02-3443-4579",
      email: "info@kground.com",
    },
  ];

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="CashflowFind">
          <div className="top">
            <div className="txt">
              <strong className="title">
                {type === "bank" ? "대출 은행" : "투자사"}
              </strong>
              <p>
                {type === "bank"
                  ? "중소벤처기업 대상 대출상품을 운영하는 은행정보를 확인하고, 대출을 준비해 보세요."
                  : "비즈내비 연계 투자사에 컨택하여 투자자금 조달 준비를 시작해 보세요."}
              </p>
            </div>
            <img
              src={
                require(type === "bank"
                  ? "../../../assets/images/cashflow/bank_find.png"
                  : "../../../assets/images/cashflow/investor_find.png").default
              }
              alt="배경이미지"
            />
          </div>
          <div className="sch">
            <div className="box">
              <input type="text" placeholder="검색어를 입력해주세요" />
              <button>
                <i className="icon_search_blue"></i>
              </button>
            </div>
          </div>
          <div className="list">
            {investorData.length > 0 ? (
              <>
                <ul>
                  {investorData.map((data, idx) => (
                    <li key={idx}>
                      <div className="logo_box">
                        <img
                          src={
                            require("../../../assets/images/cashflow/conpany_logo.png")
                              .default
                          }
                          alt="logo"
                        />
                        <div className="info">
                          <div className="name_tag">
                            <strong className="name">{data.name}</strong>
                            <span className="tag">{data.tag}</span>
                          </div>
                          <p className="desc">{data.description}</p>
                          <div className="contact detail">
                            <strong>Contact</strong>
                            <p>{data.contact}</p>
                          </div>
                          <div className="email detail">
                            <strong>E-mail</strong>
                            <p>{data.email}</p>
                          </div>
                        </div>
                      </div>
                      <button className="more">더 알아보기</button>
                    </li>
                  ))}
                </ul>
                <Pagination curPage={curPage} lastNum={3} />
              </>
            ) : (
              <p className="no_data">데이터가 없습니다.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashflowFind;
