import API from '../../util/api';

export default {
    getPatentApplicationInfo(type, keyword) {
        return API.get('/manager/patent/product', {params: {type: type, keyword: keyword}});
    },
    updateProductState(patent_idx, product_idx, state) {
        return API.patch(`/manager/patent/${patent_idx}/product`, {product_idx: product_idx, state : state});
    },
    updateProductInfo(product_idx, payload) {
        return API.patch(`/manager/product/${product_idx}`, payload);
    },
    getProductPatentFileList(product_idx) {
        return API.get(`/manager/product/${product_idx}/file`);
    },
    uploadProductPatentFile(product_idx, files) {
        return API.post(`/manager/product/${product_idx}/file`, files);
    },
    downloadProductPatentFile(product_idx, file_idx) {
        window.$Global.download(`/manager/product/${product_idx}/file/${file_idx}/download`);
    },
    deleteProductPatentFile(product_idx, file_idx) {
        return API.patch(`/manager/product/${product_idx}/file/${file_idx}`);
    }
};
