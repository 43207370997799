import React from 'react';
import './css/ToggleButton.scss';

function ToggleButton({className, selected, toggleSelected}) {
    return (
        <button className={`ToggleButton ${selected ? "on" : ""} ${className}`} onClick={toggleSelected}>
            <div className={`dialog-button ${selected ? "" : "off"}`}></div>
        </button>
    );
}

export default React.memo(ToggleButton);
