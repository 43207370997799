import React, { useContext, useEffect, useRef, useState } from "react";
import "../css/DocTradePopup.scss";

function DocTradePopup({terms, onClose}) {
	return (
		<div id="DocTradePopup">
			<button className="btnPopupClose" onClick={onClose}>팝업 창 닫기</button>
			<div className="popup-content">
				<p className="title">{terms.title}</p>
				<p className="text custom_scroll" dangerouslySetInnerHTML={ {__html: terms.desc} }/>
			</div>
		</div>
	);
};

export default DocTradePopup;