import { groupWareCorporationCarPath } from "./groupwarePath";
import GroupwareSideBar from "./GroupwareSideBar";
import "./css/GroupwareCorporationCar.scss";
import React from "react";

const GroupwareCorporationCar = () => {
  return (
    <div id="GroupwareCorporationCar">
      <GroupwareSideBar currentPath={groupWareCorporationCarPath} />
      <div className="car_wrapper">123</div>
    </div>
  );
};

export default GroupwareCorporationCar;
