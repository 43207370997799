import React from "react";
import {Link} from "react-router-dom";

function ConsortiumListItem({item, isMobile}) {
    const link = `detail/${item.idx}`;
    return (
        <>
            {
                !isMobile
                ?
                    <div className="consortiumMain_table_list">
                        <div className="consortiumMain_table_list_img">
                            <Link to={link}>
                                <img src={window.$Global.getCDN(item.profile_image_key)} alt={item.company_name} />
                            </Link>
                        </div>
                        <div className="consortiumMain_table_list_info">
                            <div className="list_info">
                                <Link to={link} className="list_title">
                                    {item.company_name}
                                </Link>
                                <p className="list_text">
                                    {item.desc}
                                </p>
                            </div>
                            <dl className="list_table">
                                <dt className="list_table_title">
                                    기업유형
                                </dt>
                                <dd className="list_table_info">
                                    {item.company_type}
                                </dd>
                                <dt className="list_table_title">
                                    대표자
                                </dt>
                                <dd className="list_table_info">
                                    {item.representative}
                                </dd>
                                <dt className="list_table_title">
                                    사업 분야
                                </dt>
                                <dd className="list_table_info">
                                    {item.business_field}
                                </dd>
                                <dt className="list_table_title">
                                    산업분야
                                </dt>
                                <dd className="list_table_info">
                                    {item.industry_field}
                                </dd>
                                <dt className="list_table_title">
                                    담당전화
                                </dt>
                                <dd className="list_table_info">
                                    {item.phone}
                                </dd>
                                <dt className="list_table_title">
                                    소재지
                                </dt>
                                <dd className="list_table_info">
                                    {item.location}
                                </dd>
                            </dl>
                        </div>
                        {/*<div className="consortiumMain_table_list_fav">*/}
                        {/*    <button type="button" className="btn_fav on">*/}
                        {/*        즐겨찾기 선택*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                :
                    <div className="consortiumMain_content_table_list">
                        <Link to={link} className="consortiumMain_content_table_link">
                            <div className="list-title-wrap">
                                <h3 className="list-name">{item.company_name}</h3>
                                <p className="list-field">{item.company_type}</p>
                            </div>
                            <p className="list-text">
                                {item.desc}
                            </p>
                            <dl className="list_table">
                                <dt className="list_table_title">
                                    사업/산업 분야
                                </dt>
                                <dd className="list_table_info">
                                    {item.business_field}/{item.industry_field}
                                </dd>
                                <dt className="list_table_title">
                                    담당전화
                                </dt>
                                <dd className="list_table_info">
                                    {item.phone}
                                </dd>
                            </dl>
                        </Link>
                    </div>
            }
        </>
    )
}

export default ConsortiumListItem;