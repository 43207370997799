import React, {useEffect, useState} from "react";
import "./css/CyberCompanyAdd.scss";
import CheckBox from "../common/CheckBox";
import CyberAPI from "../../API/cyber";
import _ from "lodash";
import {Link} from "react-router-dom";

function CyberCompanyAdd({history}) {
    const [keyword, setKeyword] = useState("");
    const [resData, setResData] = useState([]);
    const [checkList, setCheckList] = useState([]);

    const searchCompany = () => {
        CyberAPI.searchCompany(keyword).then(res => {
            let data = res.data;
            setResData(data);
            setCheckList(new Array(data.length).fill(false));
        });
    };

    const addCompany = () => {
        let idx = checkList.findIndex(item => item);
        if (idx == -1) {
            alert("기업을 선택해주세요");
            return;
        }
        CyberAPI.registCompany(resData[idx].idx).then(() => {
            alert("추가되었습니다");
            history.go(0);
        });
    };

    const checked = (idx) => {
        let copy = _.cloneDeep(checkList);
        let new_arr = new Array(copy.length).fill(false);
        new_arr[idx] = !copy[idx];
        setCheckList(new_arr);
    };

    return (
        <div id="CyberCompanyAdd">
            <h2 className="page_title">추천할 기업 등록하기</h2>
            <p className="desc">
                추천할 기업을 선택 후 등록하여<br/>
                우선권을 소지해 보세요.
            </p>

            <div className="wrap_search">
                <input type="text"
                       placeholder="기업명을 입력하세요"
                       value={keyword}
                       onChange={e => setKeyword(e.target.value)}
                />
                <button className="icon_search_white" onClick={searchCompany}/>
            </div>
            <div className="notify">
                <h2>기업등록</h2>
                <p>
                    <i className="icon_alert_circle"/>
                    <span>검색이 되지 않을 경우 수동등록이 필요합니다.</span>
                </p>
            </div>
            <div className="enroll">
                <Link to="/cyber/manual" className="btn_manual_add">기업 수동 등록</Link>
            </div>

            <div className="wrap_add">
                <ul>
                    {
                        resData.map((item, idx) => {
                            return (
                                <li key={idx}>
                                    <CheckBox checked={checkList[idx]} onChangeChecked={() => checked(idx)}/>
                                    <div>
                                        <h2>{item.name}</h2>
                                        <p>대표:{item.representative || "정보없음"}</p>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className="wrap_btn">
                <div className="alert">
                    <i className="icon_check_green"/>
                    <p>
                        우선권으로 선택 할 때 한 기업 당 3회까지 가능합니다.<br/>
                        3회 소진 후 6개월 후 부터 다시 부여가 됩니다.
                    </p>
                </div>
                <button className="btn" onClick={addCompany}>기업 등록하기</button>
            </div>
        </div>
    )
}

export default CyberCompanyAdd;