import "./css/ResearchTechAddPatent.scss";
import { useState } from "react";
import CommonAPI from "../../API/common";

const ResearchTechAddPatent = ({ onChangeShow, list, setList, setCurPage }) => {
  const [inputData, setInputData] = useState("");

  const addPatent = () => {
    CommonAPI.getPatentDataUseApplyNumber({ applyNumber: inputData }).then(
      (res) => {
        setList([...list, res.data]);
        setCurPage(1);
        onChangeShow(false);
      }
    );
  };
  return (
    <div id="ResearchTechAddPatent">
      <div className="add_patent_title">
        <p>특허 추가하기</p>
        <i className="icon_exit_gray" onClick={() => onChangeShow(false)} />
      </div>
      <p className="sub_title">
        특허 출원번호를 입력 할 시 특허가 리스트에 추가됩니다.
      </p>
      <div className="input_wrap">
        <input
          type="text"
          value={inputData}
          onChange={(e) => setInputData(e.target.value)}
        />
        <i className="icon_m_search" />
      </div>
      <div className="btn_wrapper">
        <button
          onClick={() => {
            addPatent();
          }}
        >
          확 인
        </button>
      </div>
    </div>
  );
};

export default ResearchTechAddPatent;
