import API from "../../util/api";

export default {
    getInfo(design_idx) {
        return API.get(`/manager/design/${design_idx}`);
    },
    getInfoAsAgent(manage_company_idx, design_idx) {
        return API.get(`/agent/company/${manage_company_idx}/design/${design_idx}`);
    },
    getDesignList(payload) {
        return API.get('/manager/design', {params: payload});
    },
    getDesignListAsAgent(company_idx, payload) {
        return API.get(`/agent/company/${company_idx}/design`, {params: payload});
    },
    updatePatent(design_idx, payload) {
        return API.patch(`/manager/design/${design_idx}/modify`, payload);
    },
    updateOA(design_idx, oa_idx, payload) {
        return API.patch(`/manager/design/${design_idx}/oa/${oa_idx}/modify`, payload);
    },
    updateDeadlineEP(design_idx, ep_idx, payload) {
        return API.post(`/manager/design/${design_idx}/ep/${ep_idx}/deadline`, payload);
    },
    getRelationFile(design_idx) {
        return API.get(`/manager/design/${design_idx}/relation_file`);
    },
    getRelationFileAsAgent(company_idx, design_idx) {
        return API.get(`/agent/company/${company_idx}/design/${design_idx}/relation_file`);
    },
    downloadFile(design_idx, file_idx) {
        window.$Global.download(`/manager/design/${design_idx}/file/${file_idx}/download`);
    },
    downloadDesginFileAsAgent(design_idx, file_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/design/${design_idx}/file/${file_idx}/download`);
    },
    downloadOaFile(design_idx, file_idx, oa_idx) {
        window.$Global.download(`/manager/design/${design_idx}/oa/${oa_idx}/file/${file_idx}/download`);
    },
    downloadOaFileAsAgent(design_idx, file_idx, oa_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/design/${design_idx}/oa/${oa_idx}/file/${file_idx}/download`);
    }
};
