import React, {useContext} from "react";
import DatePickerRow from "../../row/DatePickerRow";
import InputRow from "../../row/InputRow";
import NormalRow from "../../row/NormalRow";
import BillingContext from "../../../../store/Billing";
import _ from "lodash";

function BankField({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    const onChangeScheduled = (e) => {
        let copy = _.cloneDeep(billObj);
        copy.scheduled_claim_at = new Date(e).getTime();
        copy.billing_scheduled_at = new Date(e).getTime();
        billing.func.onChangeInput(copy, null, billing);
    };

    const onChangeReal = (e) => {
        let copy = _.cloneDeep(billObj);
        copy.real_claim_at = new Date(e).getTime();
        copy.billing_at = new Date(e).getTime();
        billing.func.onChangeInput(copy, null, billing);
    };

    const onChangeAmount = (e) => {
        let copy = _.cloneDeep(billObj);
        copy.claim_amount = e.target.value;
        copy.billing_amount = e.target.value;
        billing.func.onChangeInput(copy, null, billing);
    };

    return (
        <div className="field">
            <div className="rows">
                <DatePickerRow title="청구 예정 일자" content={billObj.scheduled_claim_at || billObj.billing_scheduled_at} condition={edit} onChangeDatePicker={onChangeScheduled}/>
                <DatePickerRow title="실제 청구 일자" content={billObj.real_claim_at || billObj.billing_at} condition={false} onChangeDatePicker={onChangeReal}/>
                <InputRow title="청구 금액" content={billObj.claim_amount || billObj.billing_amount} type="number" condition={edit} onChange={onChangeAmount}/>
            </div>
            <div className="rows">
                <InputRow title="은행명" content={billObj.bank_name} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "bank_name", billing)}/>
                <InputRow title="예금주" content={billObj.account_owner} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "account_owner", billing)}/>
                <InputRow title="계좌번호" content={billObj.account_number} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "account_number", billing)}/>
            </div>
            <div className="rows">
                <div className="row no_data"/>
                <NormalRow title="입금 완료일" content={billObj.deposit_at} txtType="date"/>
                <NormalRow title="입금 금액" titleClassName="color_blue" content={billObj.deposit_amount} contentClassName="color_red" txtType="number"/>
            </div>
        </div>
    );
}

export default BankField;
