import React from 'react';

function NormalRow({rowClassName, title, titleClassName, txtType, content, contentClassName, component}) {
    return (
        <div className={`row ${rowClassName || ''}`}>
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            {
                (content || content === null)
                    ? <p className={contentClassName ? contentClassName : ''}>{!content ? 'N/A' : (txtType === 'date') ? window.$Global.convertDate(content)  : content}</p>
                    : component

            }
        </div>
    );
};

export default NormalRow;
