import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.scss";
import Loading from "./components/loading/Loading";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import MenuTab from "./components/menu/MenuTab";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import HomeTaskManage from "./components/home/HomeTaskManage";
import CompanyChoice from "./components/choice/CompanyChoice";
import UnEventView from "./components/unEvent/UnEventView";
import SignupInvite from "./components/signup/SignupInvite";
import UserChat from "./components/chatbot/UserChat";
import UserChatAdmin from "./components/chatbot/UserChatAdmin";
import ItlCompany from "./components/itl/ItlCompany";

import MailRefuse from "./components/mailRefuse/MailRefuse";

import MemberContext from "./store/Member";
import UnEventContext from "./store/UnEvent";
import CommonContext from "./store/Common";
import MobilePopupContext from "./store/MobilePopup";

import API from "./util/api";
import UnEventAPI from "./API/unevent";
import AdminAPI from "./API/admin";
import MemberAPI from "./API/member";

import { CacheRoute } from "react-router-cache-route";
import { ToastContainer } from "react-toastify";

import RouteIf from "./routes/common/RouteIf";
import UserAuthRoute from "./routes/common/UserAuthRoute";
import ScrollToTop from "./util/ScrollToTop";

import GradeCompany from "./components/grade/GradeCompany";
import AdminTaskPosting from "./components/admin/AdminTaskPosting";
import AdminTaskContentList from "./components/admin/AdminTaskContentList";
import MobilePCServiceAlert from "./components/popup/MobilePCServiceAlert";
import People from "./components/people/People";
import CyberMenuBar from "./components/cyber/CyberMenuBar";
import CyberHeader from "./components/cyber/CyberHeader";
import BusinessDescriptionMain from "./components/businessDescription/BusinessDescriptionMain";
import LawfirmMain from "./components/lawfirm/LawfirmMain";
import FindbizGraph from "./components/graph/FindbizGraph";
import Calculator from "./components/calc/Calculator";
import AlbaMailing from "./components/admin/albal/AlbaMailing";
import AdminMarketSurvey from "./components/admin/manage/AdminMarketSurvey";
import AdminMarketList from "./components/admin/manage/AdminMarketList";
import SideBanner from "./components/common/SideBanner";
import ApprovalRoute from "./routes/ApprovalRoute";
import AdminRoute from "./routes/AdminRoute";
import SignupRoute from "./routes/SignupRoute";
import PatentRoute from "./routes/PatentRoute";
import ManageRoute from "./routes/ManageRoute";
import IntroRoute from "./routes/IntroRoute";
import MarketInfoRoute from "./routes/MarketInfoRoute";
import DesignRoute from "./routes/DesignRoute";
import AnnualRoute from "./routes/AnnualRoute";
import AnnualCountRoute from "./routes/AnnualCountRoute";
import TradeMarkRoute from "./routes/TradeMarkRoute";
import InventionRoute from "./routes/InventionRoute";
import DeadlineRoute from "./routes/DeadlineRoute";
import CostRoute from "./routes/CostRoute";
import MortgageLoanRoute from "./routes/MortgageLoanRoute";
import PatentIntroRoute from "./routes/PatentIntroRoute";
import MyPageRoute from "./routes/MyPageRoute";
import DocTradeRoute from "./routes/DocTradeRoute";
import ConsortiumRoute from "./routes/ConsortiumRoute";
import ExpositionRoute from "./routes/ExpositionRoute";
import GovSupportRoute from "./routes/GovSupportRoute";
import MatchingBizRoute from "./routes/MatchingBizRoute";
import ConsultComRoute from "./routes/ConsultComRoute";
import CyberRoute from "./routes/CyberRoute";
import TradeRoute from "./routes/TradeRoute";
import TaskManageRoute from "./routes/TaskManageRoute";
import FreeApplyRoute from "./routes/FreeApplyRoute";
import EducationRoute from "./routes/EducationRoute";
import TutorialRoute from "./routes/TutorialRoute";
import TermsRoute from "./routes/TermsRoute";
import FindRoute from "./routes/FindRoute";
import NotifyRoute from "./routes/NotifyRoute";
import CommunityRoute from "./routes/CommunityRoute";
import CustomSwitch from "./routes/common/CustomSwitch";
import CashflowRoute from "./routes/CashflowRoute";
import config from "./const/config";
import MobileBottomGNB from "./components/common/MobileBottomGNB";
import MobileRoute from "./routes/MobileRoute";
import links from "./const/links";
import ResearchTechRoute from "./routes/ResearchTechRoute";
import qs from "query-string";
import ChannelTalk from "./components/chatbot/ChannelTalk";
import ChannelTalkPage from "./components/chatbot/ChannelTalkPage";
import RnDPlanFirst from "./components/businessPlan/R&D/planOne/RnDPlanFirst";
import PlanInfo from "./components/businessPlan/PlanInfo";
import BusinessPlanRoute from "./routes/BusinessPlanRoute";
import PaymentHistory from "./components/statistic/PaymentHistory";
import TaskDownload from "./components/dataVoucher/TaskDownload";
import AllDownload from "./components/dataVoucher/AllDownload";
import ChartTest from "./components/chart/ChartTest";
import PatentDownload from "./components/dataVoucher/PatentDownload";
import SideBar from "./components/businessPlan/SideBar";
import IncriselPopupLog from "./components/popup/IncriselPopupLog";
import DummyLogin from "./components/login/DummyLogin";
import AnnualFeeLogin from "./components/login/AnnualFeeLogin";
import RenewalIntro from "./components/intro/findbiz/RenewalIntro";
import RenewalMain from "./components/intro/findbiz/RenewalMain";
import TestTaskFina from "./components/test/task/fina";
import TestResearchDownload from "./components/test/research/download";
import HeaderIFrame from "./components/header/HeaderIFrame";
import RenewalLogin from "./components/login/RenewalLogin";
import RenewalLogout from "./components/login/RenewalLogout";
import GroupwareCommute from "./components/groupware/GroupwareCommute";
import GroupwareWorks from "./components/groupware/GroupwareWorks";
import GroupwareWorksNew from "./components/groupware/GroupwareWorksNew";
import {
  GroupwareScheduleWorks,
  GroupwareMailWorks,
  GroupwareCloudWorks,
  GroupwareAddressBook,
} from "./components/groupware/GroupwarePims";
import GroupwareVacation from "./components/groupware/GroupwareVacation";
import GroupwareVacationWorks from "./components/groupware/GroupwareVacationWorks";
import GroupwareVacationWorksNew from "./components/groupware/GroupwareVacationWorksNew";
import GroupwareAdminCommute from "./components/groupware/GroupwareAdminCommute";
import GroupwareApproval from "./components/groupware/GroupwareApproval";
import GroupwareApprovalMng from "./components/groupware/GroupwareApprovalMng";
import GroupwareVacationStats from "./components/groupware/GroupwareVacationStats";
import GroupwareAttend from "./components/groupware/GroupwareAttend";
import GroupwareCorporationCar from "./components/groupware/GroupwareCorporationCar";
import GroupwareEmp from "./components/groupware/GroupwareEmp";
import GroupwareInfoBoard from "./components/groupware/infoBoard/GroupwareInfoBoard";
import CompanyInfo from "./components/companyInfo/companyInfo";
import CompetitorRoute from "./routes/CompetitorRoute";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CompetitorIpnowRoute from "./routes/CompetitorIpnowRoute";
import { GroupwareAdminMain } from "./components/groupware/GroupwareAdmin";
import GroupWareRoute from "./routes/GroupWareRoute";
import GroupwareBoardDetail from "./components/groupware/infoBoard/GroupwareBoardDetail";
import GroupwareInfoBoardMain from "./components/groupware/infoBoard/GroupwareInfoBoardMain";
import IPManageHome from "./components/intro/findbiz/IPManageHome";
import GroupwareMypage from "./components/groupware/GroupwareMypage";
import HomeCompany from "./components/home/HomeCompany";
import InventorRoute from "./routes/InventorRoute";
import GroupwareVacationDashBoard from "./components/groupware/dashboard/GroupwareVacationDashBoard";
import GroupwareAdminMenus from "./components/groupware/GroupwareAdminMenus";
import GroupwareBoardList from "./components/groupware/GroupwareBoard";
import GroupwareOfficeRoom from "./components/groupware/GroupwareOfficeRoom";
import GroupwareCommuteDashBoard from "./components/groupware/dashboard/GroupwareCommuteDashBoard";
import GroupwareTodo from "./components/groupware/GroupwareTodo";
import {
  GroupwareReportIns,
  GroupwareReportSend,
  GroupwareReportReceive,
  GroupwareReportTemp,
  GroupwareWeekReportIns, GroupwareTweekReport2Ins,
} from "./components/groupware/GroupwareReport";
import Price from "./components/price/Price";
import GroupwareDocDashBoard from "./components/groupware/dashboard/GroupwareDocDashBoard";
import {
  GroupwareCashflow,
  GroupwareCashflowBalanceIns,
  GroupwareCashflowFinance,
  GroupwareCashflowInvest,
  GroupwareCashflowOpExpense,
  GroupwareCashflowOpRevenue,
  GroupwareCashflowReport,
} from "./components/groupware/GroupwareCashflow";
import BalanceIns from "./components/groupware/cashflow/BalanceIns";
import BizAct from "./components/groupware/cashflow/BizAct";
import CashflowList from "./components/groupware/cashflow/CashflowList";
import CashflowReport from "./components/groupware/cashflow/CashflowReport";
import CashflowHistory from "./components/groupware/cashflow/CashflowHistory";
import RenewalHeader from "./components/header/RenewalHeader";
import HoldingsRoute from "./routes/HoldingsRoute";
import CashflowLandingImage from "./components/home/CashflowLandingImage";
import IpLoanLandingImage from "./components/home/IpLoanLandingImage";
import SupportLandingImage from "./components/home/SupportLandingImage";
// import PmsRoute from "./routes/PmsRoute";

function App() {
  const token = window.$Global.getToken();
  const auth = token != null;
  const { gwmode } = qs.parse(window.location.search);

  if (window.localStorage.getItem("gwmode") !== "off") {
    window.localStorage.setItem("gwmode", gwmode);
  }
  const str =
    gwmode !== undefined ? gwmode : window.localStorage.getItem("gwmode");

  const [unEvent, setUnEvent] = useState({});
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(
    useContext(MobilePopupContext)
  );
  const { isMobile } = useContext(CommonContext);
  const [common, setCommon] = useState(useContext(CommonContext));
  const errorMsg = "Error가 발생하였습니다.\n잠시 후 이용해주세요";
  const isIE = /*@cc_on!@*/ !!document.documentMode;
  const isCyberPath = window.location.href.indexOf("/cyber") > -1;
  const queryData = qs.parse(window.location.search);
  const isTestPath = window.location.href.indexOf("/test") > -1;
  const isRenewalHeader = window.location.href.indexOf("/renewal/header") > -1;
  const isResearchTechInfoIpnowPath =
    window.location.href.indexOf("/researchTech/ipnow") > -1;
  const isCompetitorIpnowPath =
    window.location.href.indexOf("/competitor/ipnow") > -1;
  const isMainPath =
    window.location.href.indexOf("/intro/main") > -1 ||
    window.location.href.indexOf("/") > -1;
  const isMobileTerms = window.location.href.indexOf("/terms/mo") > -1;
  const isLanding = window.location.href.indexOf("/land/") > -1;
  if (queryData.token !== undefined) {
    MemberAPI.loginToken({ token: queryData.token }).then((res) => {
      const data = res.data;

      if (data.token) {
        localStorage.setItem(config.keyToken, data.token);

        window.location.href = "/";
      }
    });
  }

  useEffect(() => {
    if (isIE) {
      alert(
        "본 서비스는 익스플로러 환경을 지원하지 않습니다\n원활한 사용을 위해 크롬, 엣지로 이용해주세요"
      );
    }

    let timer;
    let count = 0;

    API.interceptors.request.use(
      (config) => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/mortgageLoan/list"
        ) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        count += 1;

        return config;
      },
      (error) => Promise.reject(error)
    );

    API.interceptors.response.use(
      (response) => {
        count -= 1;

        if (count == 0) {
          setLoading(false);
        }

        return response;
      },
      (error) => {
        count -= 1;
        setLoading(false);

        if (error.response) {
          if (!timer) {
            timer = setTimeout(() => {
              timer = null;
              // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
              alert(error.response.data.message || errorMsg);

              let code = error.response.data.status;
              if (code == 401 || code == 603 || code == 604) {
                window.$Global.saveHistory();
                localStorage.removeItem("idx");
                localStorage.removeItem(config.keyToken);
                window.location.href = "/login";
              }

              if (code == 607 || code == 608) {
                window.location.href = "/mypage/info";
              }

              if (error.response.data.code == "not_exist_company") {
                localStorage.removeItem("manage_company_idx");
                window.location.href = "/choice";
              }
              if (code == 600) {
                window.location.href =
                  gwmode === "off" ? "/taskManage/list" : "/";
              }

              if (code == 601) {
                window.location.href = "/manage/price";
              }
            }, 0);
          }
        } else if (error.request) {
          // 요청이 이루어 졌으나 응답을 받지 못했습니다.
          console.log(error.request);
          return Promise.reject(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          alert("Error " + error.message);
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("idx")) {
      let initData = {};

      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);

        if (key == "manage_company_idx") {
          initData[key] = localStorage.getItem(key) || null;
        } else if (key == "admin") {
          initData[key] = Boolean(localStorage.getItem(key) == "true" ? 1 : 0);
        } else {
          initData[key] = localStorage.getItem(key);
        }
      }
      setMember(initData);
    } else if (token) {
      MemberAPI.getInfo().then(async (res) => {
        let data = res.data;
        console.log(data);
        data.auth_name = window.$Global.convertAuthCodeToStr(res.data.rank);
        const isAdmin = await AdminAPI.checkAuth().then((res) => res.data);
        const initData = {
          ...data,
          auth: data.rank,
          manage_company_idx:
            localStorage.getItem("manage_company_idx") || null,
          admin: isAdmin,
        };

        localStorage.setItem("idx", data.idx);
        localStorage.setItem("id", data.id);
        localStorage.setItem("name", data.name);
        localStorage.setItem("email", data.email);
        localStorage.setItem("auth", data.rank);
        localStorage.setItem("company_name", data.company_name);
        localStorage.setItem("company_idx", data.company_idx);
        localStorage.setItem("auth_name", data.auth_name);
        localStorage.setItem("admin", isAdmin);
        localStorage.setItem("ipnow_permission", data.ipnow_permission);
        localStorage.setItem("company_type", data.company_type);
        localStorage.setItem("company_code", data.company_code);

        let keys = Object.keys(initData);

        keys.forEach((item) => {
          if (item == "admin") {
            localStorage.setItem("admin", isAdmin);
          } else if (initData[item] != null) {
            localStorage.setItem(item, initData[item]);
          }
        });
        setMember(initData);
      });
    }
  }, []);

  useEffect(() => {
    if (member.idx) {
      UnEventAPI.getNotification(member.manage_company_idx).then((res) => {
        let obj = {};
        obj.unConfirmEventCnt = res.data.filter(
          (item) => item.state == 0
        ).length;
        obj.setUnEvent = (no) => {
          obj.unConfirmEventCnt = no;
          setUnEvent(obj);
        };
        setUnEvent(obj);
      });
    }
  }, [member]);

  useEffect(() => {
    handleResize(window.$Global.getBrowserSize().width < 850);

    window.addEventListener("resize", () =>
      handleResize(window.$Global.getBrowserSize().width < 850)
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = (result) => {
    setCommon({ isMobile: result });
  };

  const path = {
    intro: "/intro",
    terms: "/terms",
    find: "/find",
    qna: "/qna",
    notify: "/notify",
    nowLove: "/nowLove",
    signup: "/signup",
    admin: "/admin",
    annual: "/annual",
    annualCount: "/annualCount",
    tradeMark: "/tradeMark",
    invention: "/invention",
    inventor: "/inventor",
    deadline: "/deadline",
    cost: "/cost",
    mortgageLoan: "/mortgageLoan",
    patent: "/patent",
    patentIntro: "/patentIntro",
    myPage: "/myPage",
    grade: "/grade",
    design: "/design",
    tutorial: "/tutorial",
    education: "/education",
    freeApply: "/freeApply",
    trade: "/trade",
    taskManage: "/taskManage",
    taskManageTo: "/taskManageTo",
    manage: "/manage",
    people: "/people",
    community: "/community",
    cyber: "/cyber",
    docTrade: "/docTrade",
    userChat: "/userChat",
    consortium: "/consortium",
    matchingBiz: "/matchingBiz",
    govSupport: "/govSupport",
    exposition: "/exposition",
    businessDescription: "/businessDescription",
    taskDetail: "/taskDetail",
    lawFirm: "/lawfirm",
    graph: "/graph",
    consultCom: "/consultCom",
    calc: "/calc",
    marketInfo: "/marketInfo",
    researchTech: "/researchTech",
    approval: "/approval",
    mobile: "/mobile",
    mailRefuse: "/mailRefuse",
    business: "/business",
    groupware: "/groupware",
    competitor: "/competitor",
    competitorIpnow: "/competitorIpnow",
    // pms: "/pms",
    holdings: "/holdings",
    cashflow: "/cashflow",
  };

  if (window.location.href.indexOf("/login") == -1) {
    localStorage.removeItem("historyURL");
  }

  if (auth && window.$Global.isEmptyObject(member)) return null;

  // 서비스 점검이 마무리되는 시간에 자동으로 팝업을 내리기 위한 함수
  const isPopupTime = () => {
    const now = new Date();
    const end = new Date("December 4, 2023 09:00:00");

    return now <= end;
  };

  return (
    <MobilePopupContext.Provider value={{ mobilePopup, setMobilePopup }}>
      <CommonContext.Provider value={common}>
        <UnEventContext.Provider value={unEvent}>
          <MemberContext.Provider value={member}>
            <Loading show={loading} />
            <ToastContainer />
            <BrowserRouter forceRefresh={false}>
              <ScrollToTop />
              <div
                id="App"
                className="App"
                style={{ pointerEvents: loading ? "none" : "" }}
              >
                <CustomSwitch>
                  <Route
                    path="/lti_admin/company"
                    component={ItlCompany}
                    exact
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/posting/task`}
                    auth={true}
                    component={AdminTaskPosting}
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMailing`}
                    auth={true}
                    component={AlbaMailing}
                  />

                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMarket/list`}
                    auth={true}
                    component={AdminMarketList}
                  />
                  <UserAuthRoute
                    exact
                    path={`${path.admin}/AlbaMarket/posting`}
                    auth={true}
                    component={AdminMarketSurvey}
                  />

                  <CacheRoute
                    exact
                    path={`${path.admin}/posting/task/list`}
                    component={AdminTaskContentList}
                    when="always"
                  />

                  <Route
                    exact
                    path="/statistic/paymentHistory"
                    component={PaymentHistory}
                  />

                  <Route exact path="/chart/test" component={ChartTest} />

                  <Route
                    exact
                    path="/test/task/fina"
                    component={TestTaskFina}
                  />
                  <Route
                    exact
                    path="/test/research/download"
                    component={TestResearchDownload}
                  />

                  <Route
                    exact
                    path="/dataVoucher/task"
                    component={TaskDownload}
                  />
                  <Route
                    exact
                    path="/dataVoucher/download"
                    component={AllDownload}
                  />
                  <Route
                    exact
                    path="/dataVoucher/patent"
                    component={PatentDownload}
                  />
                  <Route
                    exact
                    path="/visitCount/incrisel"
                    component={IncriselPopupLog}
                  />
                  <Route
                    exact
                    path="/renewal/logout"
                    component={RenewalLogout}
                  />
                  <React.Fragment>
                    {!isCyberPath && (
                      <>
                        {!isTestPath &&
                          !isRenewalHeader &&
                          !isCompetitorIpnowPath &&
                          !isResearchTechInfoIpnowPath &&
                          !isMobileTerms &&
                          !isLanding && (
                            // <Route path="*" component={Header} state={gwmode}/>
                            <Route
                              path="*"
                              render={(props) => (
                                <RenewalHeader {...props} state={str} />
                              )}
                            />
                          )}
                        {!auth &&
                          !isMobile &&
                          !isCompetitorIpnowPath &&
                          !isResearchTechInfoIpnowPath &&
                          !isMobileTerms &&
                          !isLanding && <Route path="*" component={MenuTab} />}
                        {/*<Route*/}
                        {/*  exact*/}
                        {/*  path={["/intro/main", "/"]}*/}
                        {/*  component={SideBanner}*/}
                        {/*/>*/}
                        <Route exact path="/business/*" component={PlanInfo} />
                      </>
                    )}
                    <Route path={`${path.cyber}`} component={CyberHeader} />

                    <div id="container" className="container">
                      {/* <Route
                        exact
                        path={`${path.intro}/*`}
                        component={DemoView}
                      /> */}
                      <CustomSwitch>
                        <RouteIf
                          exact
                          path="/"
                          condition={auth}
                          data={str}
                          redirectPath="/intro/main"
                          component={Home}
                        />
                        <RouteIf
                          exact
                          path="/renewal/header"
                          condition={auth}
                          redirectPath="/intro/main"
                          component={HeaderIFrame}
                        />
                        <RouteIf
                          exact
                          path="/renewal/headerFrame"
                          condition={auth}
                          redirectPath="/intro/main"
                          component={Header}
                        />
                        <RouteIf
                          exact
                          path="/"
                          condition={auth}
                          redirectPath="/intro/main"
                          component={HomeTaskManage}
                        />
                        <RouteIf
                          exact
                          path="/login"
                          condition={!auth}
                          redirectPath="/"
                          component={RenewalLogin}
                        />
                        <RouteIf
                          exact
                          path="/dummylogin"
                          condition={!auth}
                          redirectPath="/"
                          component={DummyLogin}
                        />
                        <RouteIf
                          exact
                          path="/annual/login"
                          condition={!auth}
                          redirectPath="/"
                          component={AnnualFeeLogin}
                        />
                        <UserAuthRoute
                          exact
                          path="/choice"
                          auth={auth}
                          component={CompanyChoice}
                        />
                        <UserAuthRoute
                          exact
                          path="/unEvent"
                          auth={auth}
                          component={UnEventView}
                        />
                        <UserAuthRoute
                          exact
                          path="/renewal/main"
                          auth={auth}
                          component={RenewalMain}
                        />
                        <UserAuthRoute
                          exact
                          path="/renewal/intro"
                          auth={auth}
                          component={RenewalIntro}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/attend"
                          auth={auth}
                          component={GroupwareCommute}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/attend/dashboard"
                          auth={auth}
                          component={GroupwareCommuteDashBoard}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/works"
                          auth={auth}
                          component={GroupwareWorks}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/schedule"
                          auth={auth}
                          component={GroupwareScheduleWorks}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/mail"
                          auth={auth}
                          component={GroupwareMailWorks}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cloud"
                          auth={auth}
                          component={GroupwareCloudWorks}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/addressbook"
                          auth={auth}
                          component={GroupwareAddressBook}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/vacation"
                          auth={auth}
                          component={GroupwareVacation}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/vacation/dashboard"
                          auth={auth}
                          component={GroupwareVacationDashBoard}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/vacationWorks"
                          auth={auth}
                          component={GroupwareVacationWorksNew}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/office"
                          auth={auth}
                          component={GroupwareOfficeRoom}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/todo"
                          auth={auth}
                          component={GroupwareTodo}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/landing"
                          auth={auth}
                          component={GroupwareCashflow}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/balance_ins"
                          auth={auth}
                          component={GroupwareCashflowBalanceIns}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/op_revenue"
                          auth={auth}
                          component={GroupwareCashflowOpRevenue}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/op_expense"
                          auth={auth}
                          component={GroupwareCashflowOpExpense}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/finance"
                          auth={auth}
                          component={GroupwareCashflowFinance}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/invest"
                          auth={auth}
                          component={GroupwareCashflowInvest}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/report"
                          auth={auth}
                          component={GroupwareCashflowReport}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/balanceIns"
                          auth={auth}
                          component={BalanceIns}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/bizAct"
                          auth={auth}
                          component={BizAct}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/list"
                          auth={auth}
                          component={CashflowList}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/view"
                          auth={auth}
                          component={CashflowReport}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/cashflow/history"
                          auth={auth}
                          component={CashflowHistory}
                        />
                        <Route path={path.cashflow} component={CashflowRoute} />
                        <UserAuthRoute
                          exact
                          path="/groupware/report/daily/ins"
                          auth={auth}
                          component={GroupwareReportIns}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/report/daily/send"
                          auth={auth}
                          component={GroupwareReportSend}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/report/daily/receive"
                          auth={auth}
                          component={GroupwareReportReceive}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/report/daily/temp"
                          auth={auth}
                          component={GroupwareReportTemp}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/report/week/ins"
                          auth={auth}
                          component={GroupwareWeekReportIns}
                        />
                        <UserAuthRoute
                            exact
                            path="/groupware/report/tWeek2/ins"
                            auth={auth}
                            component={GroupwareTweekReport2Ins}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/admin/commute"
                          auth={auth}
                          component={GroupwareAdminCommute}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/approval"
                          auth={auth}
                          component={GroupwareApproval}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/approvalMng"
                          auth={auth}
                          component={GroupwareApprovalMng}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/vacationStats"
                          auth={auth}
                          component={GroupwareVacationStats}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/attend"
                          auth={auth}
                          component={GroupwareWorksNew}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/car"
                          auth={auth}
                          component={GroupwareCorporationCar}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/emp"
                          auth={auth}
                          component={GroupwareEmp}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/infoBoard/list"
                          auth={auth}
                          component={GroupwareInfoBoard}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/infoBoard/main"
                          auth={auth}
                          component={GroupwareInfoBoardMain}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/admin"
                          auth={auth}
                          component={GroupwareAdminMain}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/mypage"
                          auth={auth}
                          component={GroupwareMypage}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/adminmenu"
                          auth={auth}
                          component={GroupwareAdminMenus}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/boardlist"
                          auth={auth}
                          component={GroupwareBoardList}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/infoBoard/detail/:idx"
                          auth={auth}
                          component={GroupwareBoardDetail}
                        />
                        <UserAuthRoute
                          exact
                          path="/groupware/doc/dashboard"
                          auth={auth}
                          component={GroupwareDocDashBoard}
                        />
                        <RouteIf
                          exact
                          path="/ipHome"
                          condition={auth}
                          component={Home}
                        />
                        <RouteIf
                          exact
                          path="/ipHome/grade"
                          condition={auth}
                          component={Home}
                        />
                        <RouteIf
                          exact
                          path="/ipHome/new"
                          condition={auth}
                          component={Home}
                        />
                        <Route exact path="/invite" component={SignupInvite} />
                        <Route exact path="/people" component={People} />
                        <Route exact path={path.calc} component={Calculator} />
                        <Route
                          exact
                          path={`${path.grade}/company`}
                          component={GradeCompany}
                        />
                        <Route
                          exact
                          path="/land/cashflow"
                          component={CashflowLandingImage}
                        />
                        <Route
                          exact
                          path="/land/iploan"
                          component={IpLoanLandingImage}
                        />
                        <Route
                          exact
                          path="/land/support"
                          component={SupportLandingImage}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.graph}/biznavi`}
                          requireAuth={false}
                          component={FindbizGraph}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.userChat}/admin`}
                          requireAuth={true}
                          component={UserChatAdmin}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.businessDescription}/list`}
                          requireAuth={false}
                          component={BusinessDescriptionMain}
                        />
                        <UserAuthRoute
                          exact
                          path={`${path.lawFirm}/list`}
                          requireAuth={false}
                          component={LawfirmMain}
                        />
                        <Route path={path.cyber} component={CyberRoute} />
                        <Route path={path.trade} component={TradeRoute} />
                        <Route
                          path={path.taskManage}
                          component={TaskManageRoute}
                        />
                        <Route
                          path={path.freeApply}
                          component={FreeApplyRoute}
                        />
                        <Route
                          path={path.education}
                          component={EducationRoute}
                        />
                        <Route path={path.tutorial} component={TutorialRoute} />
                        <Route path={path.manage} component={ManageRoute} />
                        <Route path={path.intro} component={IntroRoute} />
                        <Route path={path.terms} component={TermsRoute} />
                        <Route path={path.find} component={FindRoute} />
                        <Route path={path.notify} component={NotifyRoute} />
                        <Route
                          path={path.community}
                          component={CommunityRoute}
                        />
                        <Route path={path.signup} component={SignupRoute} />
                        <Route path={path.admin} component={AdminRoute} />
                        <Route path={path.patent} component={PatentRoute} />
                        <Route path={path.design} component={DesignRoute} />
                        <Route path={path.annual} component={AnnualRoute} />
                        <Route
                          path={path.groupware}
                          component={GroupWareRoute}
                        />
                        <Route
                          path={path.annualCount}
                          component={AnnualCountRoute}
                        />
                        <Route
                          path={path.tradeMark}
                          component={TradeMarkRoute}
                        />
                        <Route
                          path={path.invention}
                          component={InventionRoute}
                        />
                        <Route path={path.inventor} component={InventorRoute} />
                        <Route path={path.deadline} component={DeadlineRoute} />
                        <Route path={path.cost} component={CostRoute} />
                        <Route path={path.approval} component={ApprovalRoute} />
                        <Route
                          path={path.mortgageLoan}
                          component={MortgageLoanRoute}
                        />
                        <Route
                          path={path.patentIntro}
                          component={PatentIntroRoute}
                        />
                        <Route path={path.myPage} component={MyPageRoute} />
                        <Route path={path.docTrade} component={DocTradeRoute} />
                        <Route
                          path={path.consortium}
                          component={ConsortiumRoute}
                        />
                        <Route
                          path={path.exposition}
                          component={ExpositionRoute}
                        />
                        <Route
                          path={path.govSupport}
                          component={GovSupportRoute}
                        />
                        <Route
                          path={path.matchingBiz}
                          component={MatchingBizRoute}
                        />
                        <Route
                          path={path.consultCom}
                          component={ConsultComRoute}
                        />
                        <Route
                          path={path.competitor}
                          component={CompetitorRoute}
                        />
                        <Route
                          path={path.competitorIpnow}
                          component={CompetitorIpnowRoute}
                        />
                        <Route
                          path={path.marketInfo}
                          component={MarketInfoRoute}
                        />
                        <Route
                          path={path.researchTech}
                          component={ResearchTechRoute}
                        />
                        <Route
                          path={links.mobile.path}
                          component={MobileRoute}
                        />
                        <Route
                          path={path.business}
                          component={BusinessPlanRoute}
                        />
                        {/* <Route path={path.pms} component={PmsRoute} /> */}
                        <Route path={path.holdings} component={HoldingsRoute} />
                        <RouteIf
                          exact
                          path={path.mailRefuse}
                          condition={!auth}
                          redirectPath="/"
                          component={MailRefuse}
                        />
                      </CustomSwitch>
                    </div>
                    {!isCyberPath && (
                      <>
                        {/*<Route path="*" component={MobileBottomGNB} />*/}
                        {!isTestPath &&
                          !isRenewalHeader &&
                          !isCompetitorIpnowPath &&
                          !isResearchTechInfoIpnowPath &&
                          !isMobileTerms &&
                          !isLanding && <Route path="*" component={Footer} />}
                        {/*<Route path="*" component={ChannelTalk} />*/}
                        {isMainPath && isPopupTime() && (
                          <Route path="*" component={MobilePCServiceAlert} />
                        )}
                      </>
                    )}
                    <Route path={`${path.cyber}`} component={CyberMenuBar} />
                  </React.Fragment>
                </CustomSwitch>
              </div>
            </BrowserRouter>
          </MemberContext.Provider>
        </UnEventContext.Provider>
      </CommonContext.Provider>
    </MobilePopupContext.Provider>
  );
}

export default App;
