import { useEffect, useState } from "react";
import { getText } from "./data";
import "./css/InfoChoice.scss";

const InfoCost = ({ infoPackage, cost, packageCost }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    infoPackage.map((item) => {
      if (item.isChecked) {
        setData(item);
      }
    });
  }, [infoPackage]);

  return data.isChecked ? (
    <div className="subs_section_items">
      <header>
        <p className="name">{data.title}</p>
        <p>{window.$Global.commaify(packageCost)}원</p>
      </header>
      <div>
        <p>{`└ ${cost.dataPeriod}개월`}</p>
        {getText(data)}
      </div>
    </div>
  ) : null;
};

export default InfoCost;
