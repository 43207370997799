import "./css/SaveButton.scss";
import saveImg from "../../assets/images/businessPlan/button-save.svg";

const SaveButton = () => {
  return (
    <div id="SaveButton" onClick={() => alert("준비중인 기능입니다.")}>
      <img src={saveImg} />
    </div>
  );
};

export default SaveButton;
