import IframeResizer from "iframe-resizer-react";
import GroupwareSideBar from "./GroupwareSideBar";
import { holdings } from "./groupwarePath";
import { useEffect, useState } from "react";
import HoldingsHome from "./holdings/HoldingsHome";
import HoldingsCurrent from "./holdings/HoldingsCurrent";
import HoldingsInvestComp from "./holdings/HoldingsInvestComp";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import qs from "query-string";

const HoldingsStatistic = () => {
  return (
    <div id="GroupwareWorks">
      <div className="holdings_left">
        <GroupwareSideBar currentPath={holdings} />
      </div>
      <div className="commute_wrapper">
        {/* <IframeResizer
          id="contentWrap"
          src={`/command.information.do?command=holdingComp`}
          style={{ minWidth: "100%", minHeight: "100vh", height: "1200px" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        /> */}
        <HoldingsHome />
      </div>
    </div>
  );
};

const HoldingsInfo = () => {
  return (
    <div id="GroupwareWorks">
      <div className="holdings_left">
        <GroupwareSideBar currentPath={holdings} />
      </div>
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/command.information.do?command=holdingInfo`}
          style={{ minWidth: "100%", minHeight: "100vh", height: "1200px" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

const HoldingsProject = () => {
  const location = useLocation();
  const queryString = location.search;
  const [currentMsg, setCurrentMsg] = useState("");
  const compId = qs.parse(queryString).id;
  const [url, setUrl] = useState("/command.information.do?command=holdingList");

  window.addEventListener(
    "message",
    function (event) {
      if (event.data.msg === "messengerLayoutChange") {
        // 메신저 뷰페이지
        setCurrentMsg(event.data.msg);
      } else if (event.data.msg === "messengerLayoutOrigin") {
        // 메신저 뷰페이지 아닌 페이지
        setCurrentMsg(event.data.msg);
      }
    },
    false
  );

  useEffect(() => {
    if (currentMsg === "messengerLayoutChange") {
      document.querySelector("#GroupwareWorks .commute_wrapper").style.padding =
        "0";
    } else if (currentMsg === "messengerLayoutOrigin") {
      document.querySelector("#GroupwareWorks .commute_wrapper").style.padding =
        "30px";
    }
  }, [currentMsg]);

  useEffect(() => {
    if (compId === "ipnow") {
      setUrl(
        "/command.information.do?command=showCommandOrderParent&method=&idx=2&siteFlag=biznavi&mode=address&subsidiary_idx=1"
      );
    } else if (compId === "winnerlink") {
      setUrl(
        "/command.information.do?command=showCommandOrderParent&method=&idx=2&siteFlag=biznavi&mode=address&subsidiary_idx=2"
      );
    }
  }, []);

  return (
    <div id="GroupwareWorks">
      <div className="holdings_left">
        <GroupwareSideBar currentPath={holdings} />
      </div>
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={url}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

const HoldingsCurrentPage = () => {
  return (
    <div id="GroupwareWorks">
      <div className="holdings_left">
        <GroupwareSideBar currentPath={holdings} />
      </div>
      <div className="commute_wrapper">
        <HoldingsCurrent />
      </div>
    </div>
  );
};

const HoldingsInvestPage = () => {
  return (
    <div id="GroupwareWorks">
      <div className="holdings_left">
        <GroupwareSideBar currentPath={holdings} />
      </div>
      <div className="commute_wrapper">
        <HoldingsInvestComp />
      </div>
    </div>
  );
};

export default {
  HoldingsStatistic,
  HoldingsProject,
  HoldingsInfo,
  HoldingsCurrentPage,
  HoldingsInvestPage,
};
