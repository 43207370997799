import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { HorizontalBar, Bar, Line, Doughnut } from "react-chartjs-2";
import PieGraph from "./common/PieGraph";
import UnEventContext from "../../store/UnEvent";
import MemberContext from "../../store/Member";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DeadlineAPI from "../../API/deadline";
import CompanyAPI from "../../API/company";
import CommonAPI from "../../API/common";
import AccountAPI from "../../API/account";
import config from "../../const/config";
import SearchInterested from "./SearchInterested";
import CommonContext from "../../store/Common";
import links from "../../const/links";
import ExpectationAnnual from "../annualFee/ExpectationAnnual";

function HomeCompany({
  data,
  data2,
  data3,
  data4,
  designData,
  options,
  options2,
  options3,
  patent_all_cnt,
  trademark_all_cnt,
  design_all_cnt,
  isEmptyPatentData,
  isEmptyTrademarkData,
  isEmptyDesignData,
  history,
  statistic,
}) {
  const unEvent = useContext(UnEventContext);
  const { idx, company_name, ipnow_permission, simple, name } =
    useContext(MemberContext);
  const [deadlineList, setDeadlineList] = useState([]);
  const [companyGrade, setCompanyGrade] = useState("N");
  const [findXTotalCount, setFindXTotalCount] = useState(0);
  const [ipnowExpirationAt, setIpnowExpirationAt] = useState(0);
  const date = new Date();
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const name_arr = [
    "우선권 기한",
    "해외출원 기한",
    "PCT출원 기한",
    "심사청구 기한",
    "OA 기한",
    "등록료 납부기한",
    "분할출원 기한",
    "연차료 기한",
  ];
  const cur_year = new Date().getFullYear();
  const line_chart_width = Math.floor(
    window.$Global.getBrowserSize().width / 1.3
  );

  const patent_statistic = statistic.patent_statistic;
  const trademark_statistic = statistic.trademark_statistic;
  const design_statistic = statistic.design_statistic;
  const cost = statistic.cost_last_month;
  const { isMobile } = useContext(CommonContext);

  window.localStorage.setItem("deadlineTab", true);
  useEffect(() => {
    if (isMobile) {
      CommonAPI.getTaskTotalCount().then((res) =>
        setFindXTotalCount(res.data.total_count)
      );

      if (ipnow_permission > 0 && !simple) {
        DeadlineAPI.getPatentDeadline({
          count: 4,
          page: 1,
          sort_type: 0,
        }).then((res) => setDeadlineList(res.data.items));
      }
    }
    CompanyAPI.getCompanyGrade().then((res) => setCompanyGrade(res.data.total));
    AccountAPI.getAccountInfo().then((res) =>
      setIpnowExpirationAt(res.data.ipnow_expiration_at)
    );
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20px",
  };

  const m_patent_data = () => {
    data.datasets[0].data = [
      data.datasets[0].data[0] || 0,
      data.datasets[0].data[1] || 0,
      data.datasets[0].data[2] || 0,
    ];
    return { ...data };
  };

  const m_trademark_data = () => {
    data2.datasets[0].data = [
      data2.datasets[0].data[0] || 0,
      data2.datasets[0].data[1] || 0,
      data2.datasets[0].data[2] || 0,
    ];
    return { ...data };
  };

  const m_design_data = {
    datasets: [
      {
        data: [1, 1, 1],
        backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        borderWidth: 0,
      },
    ],
  };

  const m_options = {
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: true,
    cutoutPercentage: 60,
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const m_annualFee_data = (canvas) => {
    const ctx = canvas.getContext("2d");
    let gradientOne = ctx.createLinearGradient(0, 0, 170, 0);

    gradientOne.addColorStop(0, "rgba(255,255,255, 0.2)");
    gradientOne.addColorStop(1, "rgb(255,255,255)");

    let annual_fee_year_arr = window.$Global.calcYear(cur_year, 3);
    return {
      labels: annual_fee_year_arr.convert_arr,
      datasets: [
        {
          data: statistic.annual_payment.slice(4).map((item) => {
            let result = annual_fee_year_arr.year_arr.find(
              (year) => year == item.year
            );

            if (result) {
              return item.cost;
            }
          }),
          backgroundColor: [gradientOne, gradientOne, gradientOne, gradientOne],
          borderWidth: 0,
        },
      ],
    };
  };

  const annualFee_max =
    window.$Global.calcGraphMaxNumber(
      statistic.annual_payment &&
        statistic.annual_payment.map((item) => item.cost)
    ) * 1.2;

  const m_annualFee_options = {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
    responsive: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      xAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: annualFee_max,
            stepSize: annualFee_max / 4,
            maxTicksLimit: 5,
          },
          gridLines: {
            display: true,
            borderDash: [3],
            color: "#ffffff",
            zeroLineColor: "rgba(255,255,255, 0.6)",
            zeroLineWidth: 2,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#ffffff",
          },

          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "right",
        color: "#ffffff",
        font: {
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
  };

  const patent_count_max =
    window.$Global.calcGraphMaxNumber(
      statistic.annual_apply &&
        statistic.annual_apply
          .map((item) => item.cnt)
          .concat(statistic.annual_register.map((item) => item.cnt))
    ) * 1.2;

  const m_annual_count_data = () => {
    if (window.$Global.isEmptyObject(data4)) return;

    return {
      labels: data4.labels,
      datasets: [
        {
          ...data4.datasets[0],
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderColor: "rgba(255, 255, 255, 0.7)",
          borderWidth: 5,
        },
        {
          ...data4.datasets[1],
          backgroundColor: "rgb(255, 255, 255)",
          borderColor: "rgb(255, 255, 255)",
          borderWidth: 5,
        },
      ],
    };
  };

  const m_annual_count_options = {
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: patent_count_max || 10,
            stepSize: patent_count_max / 4,
            maxTicksLimit: 5,
          },
          gridLines: {
            display: true,
            borderDash: [3],
            color: "#ffffff",
            zeroLineColor: "rgba(255,255,255, 0.6)",
            zeroLineWidth: 2,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "#ffffff",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        color: "#ffffff",
        font: {
          weight: "bold",
        },
      },
    },
  };

  // 이전달을 구하는 함수
  const lastMonth = (today, diff_month) => {
    let d = new Date(today);
    d.setMonth(d.getMonth() - diff_month);
    return d.getFullYear() + "년 " + (d.getMonth() + 1);
  };

  let tag = deadlineList.map((item, idx) => {
    let deadline_arr = [
      item.priority_claim_deadline_domestic,
      item.priority_claim_deadline_foreign,
      item.pct_apply_deadline,
      item.screen_deadline,
      item.oa_deadline,
      item.payment_deadline,
      item.division_deadline,
      item.annual_payment_deadline,
    ];
    let cur_max = Math.max.apply(null, deadline_arr);
    let name = "";
    let today = new Date().getTime();

    for (let i = 0; i < deadline_arr.length; i++) {
      if (today > deadline_arr[i]) {
        continue;
      }

      if (cur_max >= deadline_arr[i]) {
        cur_max = deadline_arr[i];
        name = name_arr[i];
      }
    }

    if (name.length == 0) return false;

    let d_day = window.$Global.getDday(cur_max);
    let className = d_day == 0 ? "today" : "";
    let str_day = d_day == 0 ? "Today" : `D-` + d_day;
    let days = week[new Date(cur_max).getDay()];

    return (
      <li key={idx} className={className}>
        <div className="date_info">
          <h2 className="title">{item.invention_name}</h2>
          <p className="number">{item.manage_number}</p>
          <div className="inner">
            <p className="title">{name}</p>
            <p className="value">
              {window.$Global.convertDate(cur_max)}({days})
            </p>
          </div>
        </div>
        <div className="date">{str_day}</div>
      </li>
    );
  });

  if (!tag[0]) {
    tag = (
      <li className="not_date">
        <div className="date_info">
          <h2 className="title">
            {ipnow_permission
              ? "스탠다드 요금제만 지원하는 기능입니다"
              : "등록된 기한이 없습니다."}
          </h2>
        </div>
      </li>
    );
  }

  return (
    <>
      <div className="pc" style={{ maxWidth: "960px", margin: "0 auto" }}>
        <div className="intro_header">
          <h2>필요한 모든 기능을 보유한 가장 쉬운 IP관리시스템</h2>
        </div>
        <div className="area_box shadow" style={{ marginTop: 10 }}>
          <div className="title">
            <h2>실시간 IP 현황</h2>
            <h2 style={{ fontFamily: "Pretendard_extraBold" }}>
              IP Monetization Platform
            </h2>
          </div>
          <div className="menu">
            <div className="wrap_tab">
              <PieGraph
                title={"특 허"}
                to="/patent/list"
                data={data}
                options={options}
                cnt={patent_all_cnt}
                isEmptyData={isEmptyPatentData}
              />
              <PieGraph
                title={["상 표", "디 자 인"]}
                to={["/tradeMark/list", "/design/list"]}
                data={[data2, designData]}
                options={options}
                cnt={[trademark_all_cnt, design_all_cnt]}
                isEmptyData={[isEmptyTrademarkData, isEmptyDesignData]}
                dual={true}
              />
              <div className="inner_tab">
                <div className="tab_my_info tab_link">
                  <h2>{company_name || name}</h2>
                  {Boolean(ipnowExpirationAt) && (
                    <p>
                      <b>만료일</b>{" "}
                      {window.$Global.convertDate(ipnowExpirationAt)}
                    </p>
                  )}
                  <div className="btns">
                    <Link to="/manage/price" className="btn_mypage">
                      내 요금제 확인/결제
                    </Link>
                  </div>
                </div>
                <div className="tab_un_event tab_link">
                  <Link to="/unEvent">
                    <h2>알림</h2>
                    <p className="desc">단계별 진행사항 알림 제공</p>
                    <p className="count">
                      <span>{unEvent.unConfirmEventCnt}</span>건
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="wrap_tab">
              <div className="tab_payment tab_info">
                <div className="header">
                  <h2>예상 연차료</h2>
                  <Link to="/annual/graph" className="btn_link">
                    자세히보기
                  </Link>
                </div>
                <p>(추천등급제공)</p>
                {data3.datasets[0].data.findIndex((item) => item > 0) > -1 ? (
                  <div className="chart">
                    <Bar
                      data={data3}
                      width={160}
                      height={180}
                      options={options2}
                    />
                  </div>
                ) : (
                  <p className="no_data no_data_black">
                    현재 등록되어진 그래프가 없습니다.
                  </p>
                )}
              </div>
              <div className="inner_tab">
                <div className="tab_count tab_info">
                  <div className="header" style={{ marginBottom: 15 }}>
                    <h2>연도별 데이터</h2>
                    <Link to="/annualCount/graph" className="btn_link">
                      자세히보기
                    </Link>
                  </div>
                  {data4.datasets[0].data.findIndex((item) => item > 0) > -1 ||
                  data4.datasets[1].data.findIndex((item) => item > 0) > -1 ? (
                    <div className="chart">
                      <Line
                        data={data4}
                        width={180}
                        height={190}
                        options={options3}
                      />
                    </div>
                  ) : (
                    <p
                      className="no_data no_data_black"
                      style={{ paddingTop: 65 }}
                    >
                      현재 등록되어진 그래프가 없습니다.
                    </p>
                  )}
                </div>
              </div>
              <div className="divide_tab">
                <div className="tab_date_manage">
                  <Link to="/deadline/list">
                    <i className="icon_hourglass_small" />
                    <p>IP기한관리</p>
                    <button className="icon_next_black" />
                  </Link>
                </div>
                <div className="tab_invention">
                  <Link to="/invention/list">
                    <i className="icon_invention" />
                    <p>직무발명신고서</p>
                    <button className="icon_next_black" />
                  </Link>
                </div>
                <div className="tab_invention">
                  <Link to="/cost/list?nation=1">
                    <i className="icon_combined_shape" />
                    <p>비용 관리</p>
                    <button className="icon_next_black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrap_area_box" style={{ marginTop: 18 }}>
          <div
            className="area_box"
            style={{
              width: 742,
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ul>
              <li>
                <img
                  src={require("../../assets/images/home/bg_ip.png").default}
                  alt="이미지"
                  /*onClick={() => history.push("/application/list")}*/
                  onClick={() => history.push("/mortgageLoan/list")}
                />
              </li>
              <li>
                <img
                  src={
                    require("../../assets/images/home/bg_us_apply.png").default
                  }
                  alt="이미지"
                  onClick={() => history.push("/freeApply/list")}
                />
              </li>
              <li>
                <img
                  src={
                    require("../../assets/images/home/bg_open_market.png")
                      .default
                  }
                  alt="이미지"
                  onClick={() => history.push("/trade/list")}
                />
              </li>
              <li>
                <img
                  src={
                    require("../../assets/images/home/bg_lawFirm.png").default
                  }
                  alt="이미지"
                  onClick={() => history.push("/lawfirm/list")}
                />
              </li>
            </ul>
          </div>
          <div
            className="area_box"
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 207,
            }}
          >
            {/* <h2>교육자료</h2> */}
            <ul style={{ paddingLeft: "0" }}>
              <li>
                <img
                  src={
                    require("../../assets/images/home/bg_education.png").default
                  }
                  alt="이미지"
                  onClick={() => history.push("/education/main")}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="wrap_area_box" style={{ marginTop: 18 }}>
          <div
            className="area_box"
            style={{
              marginTop: 18,
              padding: "15px 43px 12px 17px",
              height: "270px",
            }}
          >
            <div className="wrap_title">
              {/* <h2>정보분석 서비스</h2> */}
              <p>※ 경쟁기업 기술역량 · 등록특허 등급은 개별구매서비스입니다.</p>
            </div>
            <div className="inner">
              <ul>
                {/* <li>
                                    <img src={require('../../assets/images/home/bg_grade.png').default} alt="이미지" onClick={() => history.push('/grade/company')}/>
                                </li> */}
                <li>
                  <img
                    src={
                      require("../../assets/images/home/bg_grade.png").default
                    }
                    alt="우리회사 NOW 기술역량 등급"
                    onClick={() => history.push("/grade/company")}
                  />
                </li>
                {/* <li>
                                    <img src={require('../../assets/images/home/bg_findx.png').default} alt="이미지" onClick={() => history.push('/taskManage/list')}/>
                                </li> */}
              </ul>
              <SearchInterested />
            </div>
          </div>
        </div>
      </div>
      {/*{window.localStorage.getItem("company_idx") === "C5I1ZDEPUMV4WVI1" ? (*/}
      {/*  <div className="mo">*/}
      {/*    <ExpectationAnnual />*/}
      {/*  </div>*/}
      {/*) : (*/}
      <div className="mo">
        <div className="findx_area">
          <img
            src={
              require("../../assets/images/mobile/bg_banner_04_small.svg")
                .default
            }
            alt="이미지"
          />
          <p>
            현재 총 {window.$Global.commaify(findXTotalCount)}건의 사업 공고
            제공
          </p>
          <Link to={links.taskManage} className="btn btn_findx">
            BizNavi 바로가기
          </Link>
        </div>
        <div className="chart_area">
          <h2 className="area_title">실시간 보유 IP 현황</h2>
          <Slider {...settings}>
            <div className="wrap_chart">
              <div className="chart">
                <div className="header">
                  <h2>특허 건수</h2>
                  <Link to="/patent/mlist">
                    자세히보기
                    <i className="icon_more_arrow_right_white" />
                  </Link>
                </div>
                <p className="total_cnt">
                  <span>{patent_all_cnt}</span>건
                </p>
                <div className="add_label">
                  등록 {patent_statistic.registered}건
                </div>
                <div className="apply_label">
                  출원 {patent_statistic.applied}건
                </div>
                <div className="giveup_label">
                  포기 {patent_statistic.terminated}건
                </div>
                <Doughnut
                  data={m_patent_data}
                  width={170}
                  height={170}
                  options={m_options}
                />
              </div>
            </div>
            <div className="wrap_chart">
              <div className="chart">
                <div className="header">
                  <h2>상표 건수</h2>
                  <Link to="/tradeMark/mlist">
                    자세히보기
                    <i className="icon_more_arrow_right_white" />
                  </Link>
                </div>
                <p className="total_cnt">
                  <span>{trademark_all_cnt}</span>건
                </p>
                <div className="add_label">
                  등록 {trademark_statistic.registered}건
                </div>
                <div className="apply_label">
                  출원 {trademark_statistic.applied}건
                </div>
                <div className="giveup_label">
                  포기 {trademark_statistic.terminated}건
                </div>
                <Doughnut
                  data={m_trademark_data}
                  width={170}
                  height={170}
                  options={m_options}
                />
              </div>
            </div>
            <div className="wrap_chart">
              <div className="chart">
                <div className="header">
                  <h2>디자인 건수</h2>
                  <Link to="/design/mlist">
                    자세히보기
                    <i className="icon_more_arrow_right_white" />
                  </Link>
                </div>
                <p className="total_cnt">
                  <span>{design_all_cnt}</span>건
                </p>
                <div className="add_label">
                  등록 {design_statistic?.registered}건
                </div>
                <div className="apply_label">
                  출원 {design_statistic?.applied}건
                </div>
                <div className="giveup_label">
                  포기 {design_statistic?.terminated}건
                </div>
                <Doughnut
                  data={m_design_data}
                  width={170}
                  height={170}
                  options={m_options}
                />
              </div>
            </div>
            <div className="wrap_chart">
              <div className="chart">
                <div className="header" style={{ marginBottom: 17 }}>
                  <h2>예상 연차료</h2>
                  <Link to="/annual/mgraph">
                    자세히보기
                    <i className="icon_more_arrow_right_white" />
                  </Link>
                </div>
                <HorizontalBar
                  data={m_annualFee_data}
                  width={line_chart_width}
                  height={170}
                  options={m_annualFee_options}
                />
              </div>
            </div>
            <div className="wrap_chart">
              <div className="chart">
                <div className="header" style={{ marginBottom: 17 }}>
                  <h2>연도별 갯수</h2>
                  <Link to="/annualCount/mview">
                    자세히보기
                    <i className="icon_more_arrow_right_white" />
                  </Link>
                </div>
                <Line
                  data={m_annual_count_data}
                  width={line_chart_width}
                  height={190}
                  options={m_annual_count_options}
                />
              </div>
            </div>
          </Slider>
        </div>
        <div className="grade_area">
          <Link to="/grade/company" className="my_grade">
            <div className="circle">{companyGrade || "N"}</div>
            <div className="inner">
              <h2>
                <span>우리회사</span>
                <br />
                NOW 기술 역량 등급
              </h2>
            </div>
            <button className="icon_more_arrow_right_black" />
          </Link>
        </div>

        <div className="more_view_area">
          <h2 className="area_title">IP 수익화 서비스</h2>
          <div className="list remove_scrollbar">
            <img
              src={
                require("../../assets/images/mobile/bg_main_trade.svg").default
              }
              alt="이미지"
              onClick={() => history.push("/trade/list")}
            />
            <img
              src={require("../../assets/images/mobile/bg_main_ip.svg").default}
              alt="이미지"
              onClick={() => history.push("/intro/loans")}
            />
            <img
              src={
                require("../../assets/images/mobile/bg_main_invest.svg").default
              }
              alt="이미지"
              onClick={() => history.push("/intro/investment")}
              style={{ height: 180 }}
            />
            {/*<img src={require('../../assets/images/home/bg_us_apply.png').default} alt="이미지" onClick={() => history.push('/freeApply/list')}/>*/}
          </div>
        </div>

        <div className="date_manage_area">
          <h2 className="area_title">기한 관리하기</h2>
          <ul>{tag}</ul>
        </div>

        <div className="my_price">
          <h2 className="area_title">지난달 사용 비용 확인하기</h2>

          <div className="wrap">
            <div className="header">
              <h2>{lastMonth(date, 1)}월 총 발생 비용</h2>
              <p>{window.$Global.commaify(cost.total_cost)}</p>
            </div>
            <div className="content">
              <ul className="detail_price">
                {cost.patent_cost > 0 && (
                  <li>
                    <p>특허 유지비</p>
                    <p>
                      <span>{window.$Global.commaify(cost.patent_cost)}</span>원
                    </p>
                  </li>
                )}
                {cost.trademark_cost > 0 && (
                  <li>
                    <p>상표 유지비</p>
                    <p>
                      <span>
                        {window.$Global.commaify(cost.trademark_cost)}
                      </span>
                      원
                    </p>
                  </li>
                )}
              </ul>
              <div className="total">
                <p>총 결제금액</p>
                <p>
                  <span>{window.$Global.commaify(cost.total_cost)}</span>원
                </p>
              </div>
              {cost.compare_cost > 0 && (
                <div className="compare">
                  <p>이전 달 대비 사용금액</p>
                  <p>
                    <span>{window.$Global.commaify(cost.compare_cost)}</span>원
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="ip_edu">
          <h2 className="area_title">IP 교육자료</h2>
          <button
            className="btn_more"
            onClick={() => alert("PC 버전에서 이용가능합니다")}
          >
            더보기
            <i className="icon_more_arrow_right_small_black" />
          </button>
          <div className="list remove_scrollbar">
            <div className="item">
              <img
                src={require("../../assets/images/mobile/bg_ip_01.svg").default}
                alt="이미지"
                onClick={() => alert("준비 중인 페이지입니다")}
              />
              <h2>아이피나우</h2>
              <p>
                IP에 대한 모든 교육을 가장 쉽게 접할 수 있는 방법을 친절하게 ...
              </p>
            </div>
            <div className="item">
              <img
                src={require("../../assets/images/mobile/bg_ip_02.svg").default}
                alt="이미지"
                onClick={() => alert("준비 중인 페이지입니다")}
              />
              <h2>아이피나우</h2>
              <p>
                IP에 대한 모든 교육을 가장 쉽게 접할 수 있는 방법을 친절하게 ...
              </p>
            </div>
          </div>
        </div>
      </div>
      {idx === config.testerIdx && (
        <div className="demo_alert">
          <p>현재 보시는 화면은 데모 페이지입니다.</p>
          <p>
            데모 페이지에서는 가상으로 등록된 데이터로 서비스를{" "}
            <br className="mo" />
            체험해보실 수 있습니다
          </p>
          <p onClick={() => window.$Global.logout()}>데모 페이지 나가기</p>
        </div>
      )}
    </>
  );
}

export default HomeCompany;
