import React, {useContext, useEffect, useState} from "react";
import BillingContext from "../../../../store/Billing";
import {patentCostTypeValue, patentCostTypeValueInfo} from "../../../../types/patentCostType";

function ClaimField({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    const [largeClass, setLargeClass] = useState([]);
    const [middleClass, setMiddleClass] = useState([]);


    useEffect(async () => {
        const { middleClass } = billing;

        setLargeClass(billing.largeClass);
        setMiddleClass(middleClass);

        let key;

        for(let k in patentCostTypeValue){
            if(patentCostTypeValue[k].indexOf(billObj.type.toString()) !== -1){
                key = k;
                break;
            }
        }

        let base_type = middleClass[key][0].split('/')[1];

        if (!billObj.idx) {
            billing.func.onChangeInput(base_type, "type", billing);
        }
    }, []);

    const getType = (type) => {
        for(let k in patentCostTypeValue){
            if(patentCostTypeValue[k].indexOf(type.toString()) !== -1){
                return k;
            }
        }
    };

    const onChangeLarge = (e) => {
        let key = e.target.value;
        let type = middleClass[key][0].split('/')[1];

        initInput(type);
    };

    const onChangeMiddle = (e) => {
        let type = e.target.value.split('/')[1];

        initInput(type);
    };

    const initInput = (type) => {
        let bill = {
            ...billObj,
            agent_commission: 0,
            basic_fee: 0,
            claim_amount: 0,
            commission_dc: 0,
            dependent_claim_cnt: 0,
            dependent_ec: 0,
            deposit_amount: 0,
            drawing_cnt: 0,
            drawing_ec: 0,
            ed_cnt: 0,
            etc_fee: 0,
            for_basic_fee: 0,
            for_drawing_fee: 0,
            for_etc_fee: 0,
            for_gc: 0,
            for_remittance_fee: 0,
            for_sum_commission: 0,
            for_sum_total: 0,
            for_surtax: 0,
            for_translate_fee: 0,
            gc: 0,
            independent_claim_cnt: 0,
            independent_ec: 0,
            judgement_at: 0,
            oa_cnt: 0,
            referee_number: "",
            referee_type: "",
            screen_claim_cnt: 0,
            statement_page_cnt: 0,
            statement_page_ec: 0,
            submission_cnt: 0,
            sum_commission: 0,
            sum_total_domestic: 0,
            surtax: 0,
            time_charge_ec: 0,
            total_claim_cnt: 0,
            total_claim_ec: 0,
            type: type,
            work_hour: 0,
            work_hour_rate: 0,
        };
        billing.func.onChangeInput(bill, null, billing);
    };

    let large_option = largeClass.map((item, index) => <option key={index} value={item}>{item}</option>);
    let middle_option = middleClass[getType(billObj.type)]?.map((item, index) => <option key={item.split('/')[1]} value={item}>{item.split('/')[0]}</option>);

    let selected_large = largeClass.find(item => item === getType(billObj.type));
    let selected_middle = patentCostTypeValueInfo[billObj.type.toString()];

    return (
        <>
            <div className="field">
                <div className="rows">
                    <div className="row">
                        <h2 className="title">청구내용(대분류)</h2>
                        {
                            edit
                            ?
                                <select value={selected_large} onChange={onChangeLarge} dir="rtl">
                                    {large_option}
                                </select>
                            :   <p>{selected_large}</p>
                        }
                    </div>
                    <div className="row">
                        <h2 className="title">청구내용(중분류)</h2>
                        {
                            edit
                            ?
                                <select value={selected_middle} onChange={onChangeMiddle} dir="rtl">
                                    {middle_option}
                                </select>
                            :   <p>{selected_middle}</p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimField;
