import React, {useState, useEffect} from "react";
import "./css/PopupComment.scss";
function PopupComment({show, text, onChange, onClickEditRequest, onClickClose}) {
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setEdit(false);
    }, [show]);

    const onClickEdit = () => {
        setEdit(true);
    };

    if (!show) return null;

    return (
        <div id="PopupComment">
            <div className="header">
                <h2>수정요청 코멘트 작성</h2>
                <div className="btns">
                    {
                        edit || <button className="btn_edit" onClick={onClickEdit}>작성</button>
                    }
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
            </div>
            <div className="comment">
                <h2>수정이 필요하다고 생각한 내용을 작성해주세요</h2>
                {
                    edit
                    ? <textarea value={text} onChange={onChange} autoFocus/>
                    : <p className="custom_scroll">{text}</p>
                }
            </div>
            {
                edit && <button className="btn_edit_request" onClick={() => onClickEditRequest(text)}>작성 완료</button>
            }
        </div>
    )
}

export default PopupComment;
