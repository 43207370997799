import React, { useContext } from "react";
import "./css/Pagination.scss";
import CommonContext from "../../store/Common";
import { useHistory } from "react-router-dom";

function Pagination({ curPage, lastNum, onClick, scrollType }) {
  const { isMobile } = useContext(CommonContext);
  const history = useHistory();
  let tag = [],
    viewCount;

  if (isMobile) {
    viewCount = 5;
  } else {
    viewCount = 10;
  }

  let page = Math.ceil(curPage / viewCount);
  let lastPage = Math.ceil(lastNum / viewCount);

  let sNum = page > 1 ? (page - 1) * viewCount + 1 : 1;
  let lNum = lastPage > page ? page * viewCount : lastNum;

  const moveToTop = () => {
    if (!scrollType) {
    } else document.documentElement.scrollTop = 0;
  };

  for (let i = sNum; i <= lNum; i++) {
    tag.push(
      <li
        key={i}
        className={`item ${i == curPage ? "on" : ""}`}
        onClick={() => onClick(i)}
      >
        <button className="link" onClick={moveToTop}>
          {i}
        </button>
      </li>
    );
  }

  return (
    <div id="pagination">
      <ul className="pagination">
        <li
          className="start item"
          style={{ pointerEvents: !isMobile && curPage !== 1 ? "" : "none" }}
          onClick={() => onClick(1)}
        >
          <button className="link" />
        </li>
        <ul className="item-wrap">
          <li
            className="prev item"
            style={{ pointerEvents: 1 <= curPage - 1 ? "" : "none" }}
            onClick={() => {
              onClick(curPage - 1);
            }}
          >
            <button className="link" />
          </li>
          {tag}
          <li
            className="next item"
            style={{
              pointerEvents: 1 <= (lastNum >= curPage + 1) ? "" : "none",
            }}
            onClick={() => onClick(curPage + 1)}
          >
            <button className="link" />
          </li>
        </ul>
        <li
          className="end item"
          style={{
            pointerEvents:
              1 <= (!isMobile && curPage !== lastNum) ? "" : "none",
          }}
          onClick={() => onClick(lastNum)}
        >
          <button className="link" />
        </li>
      </ul>
    </div>
  );
}

export default Pagination;
