import React from "react";

function InputRow({rowClassName, type, title, titleClassName, condition, content, contetClassName, inputClassName, onChange}) {

    let value = content;
    if (type == "number") {
        value = window.$Global.commaify(content);
    }
    return (
        <div className={`row ${rowClassName || ''}`}>
            <h2 className={`title ${titleClassName || ''}`}>{title}</h2>
            {
                condition
                    ? <input type={type || "text"} className={inputClassName || ''} value={content || ""} onChange={onChange}/>
                    : <p className={contetClassName || ''}>{content ? value : 'N/A'}</p>
            }
        </div>
    );
}
export default InputRow;
