import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContentDetail from "../common/ContentDetail";
import CommonAPI from "../../API/common/index";
import commonFunc from "../admin/commonFunc/common";
import qs from "query-string";

function CommunityDetail({history, location}) {
    const {idx} = useParams();
    const {category} = qs.parse(location.search);
    const [categoryList, setCategoryList] = useState([]);
    const [postData, setPostData] = useState({});

    useEffect(() => {
        CommonAPI.getNoticeInfo(idx).then(res => setPostData(res.data));
        commonFunc.convertCommunityCategory().then(res => setCategoryList(res));
    }, []);

    const fileDownload = (file_idx) => {
        CommonAPI.downloadNotice(idx, file_idx);
    };

    return (
        <div id="CommunityDetail">
            <ContentDetail history={history}
                           path="/community"
                           pathParam={`category=${category}`}
                           page_title={commonFunc.convertCommunityCategoryNo(categoryList, category)}
                           title={postData.title}
                           update_at={window.$Global.convertDate(postData.created_at)}
                           content={postData.content}
                           files={postData.files}
                           onClickDownload={fileDownload}
            />
        </div>
    )
}
export default CommunityDetail;
