import React from 'react';
import "./css/MobilePopupAttach.scss";
import WithPreventScroll from "../../HOC/WithPreventScroll";

function MobilePopupAttach({show, item, onDownload}) {

	const SettingByHOC = () => {
		return (<></>)
	};

	const ComponentWithDimmed = WithPreventScroll(SettingByHOC, show);

	if (!show) return null;

	return (
		<div id="MobilePopupAttach" className="mo">
			<ComponentWithDimmed/>
			<div className="popupAttach">
				<h2 className="popupAttach_title">
					다운로드
				</h2>
				<table className="popupAttach_table">
					<colgroup>
						<col width="10%" />
						<col width="70%" />
						<col width="20%" />
					</colgroup>
					<thead className="popupAttach_table_head">
						<tr>
							<th>순번</th>
							<th>파일명</th>
							<th>다운로드</th>
						</tr>
					</thead>
					<tbody className="popupAttach_table_body">
						{
							item?.map((file, idx) => {
								return (
									<tr key={file.idx}>
										<td>{idx + 1}</td>
										<td>{file.file_name}.{file.file_type}</td>
										<td>
											<button type="button" className="btn_down" onClick={() => onDownload(file.idx)}>
												첨부파일 다운받기
											</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default MobilePopupAttach;