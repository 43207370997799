import React, {useEffect, useState} from "react";
import "./css/AdminService.scss";
import ContentTableList from "../common/ContentTableList";
import AdminAPI from "../../API/admin";
import commonFunc from "./commonFunc/common";
import _ from "lodash";
import {Link} from "react-router-dom";
import qs from "query-string";

function AdminService({history, location}) {
    const category = qs.parse(location.search).category || 3;
    const [categoryList, setCategoryList] = useState([]);

    const [resData, setResData] = useState({items: []});
    const [tbody, setTbody] = useState([]);

    const [keyword, setKeyword] = useState("");
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const count = 10;
    const path = "/admin/service";

    let payload = {
        count: count,
        page: 1,
        service: 1,
        category: category,
        keyword: keyword
    };

    useEffect(() => {
        commonFunc.convertServiceCategory().then(res => setCategoryList(res));
    }, []);

    useEffect(() => {
        getServiceList(payload);
        setCurPage(1);
    }, [category]);

    useEffect(() => {
        drawTable();
    }, [resData]);

    const getServiceList = (payload, content_type) => {
        AdminAPI.getCommunityList(payload).then(res => {
            let result = res.data;

            if (content_type == "next") {
                result.items = resData.items.concat(result.items);
            }
            setResData(result);
        });
    };

    const drawTable = () => {
        setTbody(resData.items.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{resData.total_count - index}</td>
                    <td>
                        <Link to={`${path}/posting?idx=${item.idx}&type=view&category=${category}`}>
                            <div className="content">
                                <img src={window.$Global.getCDN(item.thumbnail_key)} alt="이미지"/>
                                <div>
                                    <h2>{item.title}</h2>
                                    <p>{window.$Global.decode(window.$Global.removeHTML(item.content || ""))}</p>
                                </div>
                            </div>
                        </Link>
                    </td>
                    <td>{window.$Global.convertDate(item.created_at)}</td>
                    <td>
                        <button className="icon_badge_del" onClick={() => onClickPostDelete(index, item.idx)}/>
                    </td>
                </tr>
            );
        }));
        if (resData.page == resData.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    const searchList = () => {
        setCurPage(1);
        payload.page = 1;
        getServiceList(payload);
    };

    const nextList = () => {
        let nextPage = curPage + 1;
        payload.page = nextPage;
        setCurPage(nextPage);
        getServiceList(payload, "next");
    };

    const onClickSelectCategory = (idx) => {
        history.push(`?category=${idx + 3}`);
    };

    const onClickPostDelete = (depth, idx) => {
        AdminAPI.deleteNotify(idx).then(() => {
            let copy = _.cloneDeep(resData);
            copy.items.splice(depth, 1);
            setResData(copy);
            alert("삭제되었습니다");
        });
    }

    return (
        <div id="AdminService">
            <div className="category_tab">
                <h2>카테고리</h2>
                <ul>
                    {
                        categoryList.map((item, idx) => {
                            return (
                                <li key={idx} className={`${item.value == category ? 'active' : ''}`}
                                    onClick={() => onClickSelectCategory(idx)}>
                                    {item.name}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="btns" style={{display: "flex", columnGap: 10}}>
                <input type="text" style={{width: 300, border: "1px solid #d8d8d8"}} value={keyword} onKeyUp={e => e.key === 'Enter' && searchList(payload)} onChange={e => setKeyword(e.target.value)} placeholder="검색어를 입력하고 엔터를 눌러주세요"/>
                <Link to={`${path}/posting?category=${category}`} className="btn_post">글 작성하기</Link>
            </div>
            <ContentTableList addTheadList={["삭제"]} title={categoryList[category - 3]?.name} data={tbody} content={tbody} drawList={nextList} hasMore={hasMore}/>
        </div>
    );
};

export default AdminService;