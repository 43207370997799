import React, { useEffect, useState } from "react";
import "./css/TaskMyAddList.scss";
import _ from "lodash";
import CommonAPI from "../../API/common";
import links from "../../const/links";
import message from "../../const/message";
import qs from "query-string";
import { useLocation  } from "react-router-dom";
import GwAPI from "../../API/groupware";

function TaskMyAddList({ history }) {
  const location = useLocation();
  //const navigate = useNavigate();
  const count = 50;
  const [field, setField] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [resData, setResData] = useState({
    items: [],
    total_count: 0,
  });
  const { idx, searchKeyword, category } = qs.parse(location.search);
  const [keyword, setKeyword] = useState(searchKeyword || "");
  const [showList, setShowList] = useState([false]);
  let defaultPayload = {
    page: 1,
    count: count,
    interest: 1,
  };

  useEffect(() => {
    CommonAPI.getTaskField().then((res) => setField(res.data));
    getList(defaultPayload);
  }, []);

  useEffect(() => {
    setShowList(new Array(resData.items.length).fill(false));
  }, [resData]);

  const getList = (payload, type) => {
    CommonAPI.getTaskList(payload).then((res) => {
      let data = res.data;

      if (type == "next") {
        data.items = resData.items.concat(data.items);
      }
      setResData(data);
      console.log(res.data);
      if (data.page == data.last) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };

  const onClickDeleteInterest = (idx) => {
    CommonAPI.deleteInterest(idx).then(() => {
      let copy = _.cloneDeep(resData);
      copy.items.splice(
        copy.items.findIndex((item) => item.idx == idx),
        1
      );
      copy.total_count -= 1;
      setResData(copy);
    });
  };

  const onClickAddCalendar = async (item) => {
    let calendar = await getCalendarList();
    let calendarList = calendar?.data?.resultList;
    let calendarId = "";
    if (calendarList.length < 1) {
      // 내 캘린더가 없음
      return;
    } else {
      // 나중에 디자인되면 추후에 처리.. 일단 내 캘린더에 추가 함...
      calendarId = calendarList[0].id;
      //console.log(calendarId);
    }

    let isStart = isNaN(item.reception_at);
    let isEnd = isNaN(item.deadline_at);
    let start_timestamp = "";
    let end_timestamp = "";
    console.log(item.reception_at + " ====> " + isNaN(item.deadline_at));
    if (!isStart && !isEnd) {
      // 시작일, 마감일 모두 있을꼉우
      start_timestamp = window.$Global.convertDateTime(item.reception_at);
      end_timestamp = window.$Global.convertDateTime(item.deadline_at);
    } else if (!isStart && isEnd) {
      // 시작일은 있고 마감일이 없을 경우 (둘다 시작일 등록)
      start_timestamp = window.$Global.convertDateTime(item.reception_at);
      end_timestamp = window.$Global.convertDateTime(item.reception_at);

      // 현재 캘린더에서 시간이 같으면 다음날로 넘어가서 시작일만 있을경우 end_timestamp의 시간을 23:59으로 설정
      let endDate = new Date(end_timestamp);
      endDate.setHours(23, 59, 0, 0); // 시, 분, 초, 밀리초를 각각 설정
      end_timestamp = window.$Global.convertDateTime(endDate);
    }

    console.log(start_timestamp + " ==== " + end_timestamp);
    const addParam = new URLSearchParams();
    addParam.append("calendarId", calendarId);
    addParam.append("start_timestamp", start_timestamp);
    addParam.append("end_timestamp", end_timestamp);
    addParam.append("title", item.title);

    GwAPI.addCalendar(addParam)
      .then((response) => {
        let result = response.data.result;

        if (result == "fail") {
          alert("등록 실패하였습니다.");
        } else if (result == "success") {
          //alert("등록 성공");
          // 이동 여부를 묻는 confirm 창
          const userConfirmed = window.confirm("등록 했습니다. \n일정관리 페이지로 이동하시겠습니까?");

          if (userConfirmed) {
            // "예"를 선택한 경우 일정관리 페이지로 이동
            window.parent.location.href ='/groupware/schedule';
          }
        }
        //console.log('API Response:', response);
      })
      .catch((error) => {
        alert("등록 처리중 오류가 발생했습니다.");
        // 에러 처리
        //console.error('Error:', error);
      });
  };

  const getCalendarList = () => {
    return GwAPI.getCalendarList({ searchCalendar: "myCal" })
      .then((res) => {
        ////console.log("getOneReportInfo:", res);
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching report data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };
  const onClickShowToggle = (idx) => {
    let copy = _.cloneDeep(showList);
    copy[idx] = !copy[idx];
    setShowList(copy);
  };

  const onClickFileDownload = (idx, f_idx) => {
    CommonAPI.taskFileDownload(idx, f_idx);
  };

  const hashTagPushHistory = (keyword) => {
    return history.push(`${links.taskManage}?searchKeyword=${keyword}`);
  };

  const nextList = () => {
    let payload = {
      ...defaultPayload,
      page: curPage + 1,
    };
    setCurPage(curPage + 1);
    getList(payload, "next");
  };

  let items = resData.items.map((item, idx) => {
    const regex = /^[0-9]+$/;
    const checkDday = regex.test(item.d_day);
    let str = "";

    if (checkDday) {
      str += "D-";
      if (item.d_day === "0") {
        str += "DAY";
      } else {
        str += item.d_day;
      }
    } else {
      str += item.d_day;
    }
    return (
      <div className={`item ${showList[idx] ? "animation" : ""}`}>
        <div className="title_area">
          <div className="post_title">
            <p className="dday">{str}</p>
            <h2 className="title">{item.title}</h2>
          </div>
        </div>
        <div className="detail_area">
          <div className="wrap_data">
            <div className="datas">
              <div className="data">
                <h2>공고 기관</h2>
                <p>{item.department_name}</p>
              </div>
              <div className="data">
                <h2>현황</h2>
                <p>
                  {window.$Global.matchJsonToStr(field, "state", item.state)}
                </p>
              </div>
              <div className="data">
                <h2>접수일</h2>
                <p>
                  {isNaN(item.reception_at)
                    ? item.reception_at
                    : window.$Global.convertDateTime(item.reception_at)}
                </p>
              </div>
            </div>
            <div className="datas">
              <div className="data">
                <h2>분야</h2>
                <p>
                  {window.$Global.matchJsonToStr(field, "field", item.field)}
                </p>
              </div>
              <div className="data">
                <h2>마감일</h2>
                <p>
                  {isNaN(item.deadline_at)
                    ? item.deadline_at
                    : window.$Global.convertDateTime(item.deadline_at)}
                </p>
                <p className="dday">{str}</p>
              </div>
            </div>
            <div className="datas">
              <div className="data">
                <h2>참가가능지역</h2>
                <p>
                  {window.$Global.matchJsonToStr(field, "region", item.region)}
                </p>
              </div>
              <div className="data">
                <h2>금액</h2>
                <p className="amount_color_blue">
                  {isNaN(item.amount)
                    ? item.amount
                    : window.$Global.numberWithCommas(item.amount)}
                </p>
              </div>
            </div>
          </div>
          <div className="contact_wrap_data">
            <div className="contact_datas">
              <div className="contact_data">
                <h2>문 의 처</h2>
                <p>{item.inquries}</p>
              </div>
            </div>
          </div>
          <div className="file_wrap_data">
            <div className="file_data">
              <h2>첨부파일</h2>
              <div className="inner">
                {Boolean(item.file.length) ? (
                  item.file.map((f_item, idx) => {
                    return (
                      <p
                        key={idx}
                        onClick={() =>
                          onClickFileDownload(item.idx, f_item.idx)
                        }
                      >
                        <i className="icon_download_attached" />
                        {f_item.file_name + "." + f_item.file_type}
                      </p>
                    );
                  })
                ) : (
                  <p>없음</p>
                )}
              </div>
            </div>
            <div className="link_btn">
              {item.uri && (
                <button
                  className="btn_go"
                  onClick={() => window.open(item.uri, "_blank")}
                >
                  <i className="icon_link" />
                  <span>공고문 바로가기</span>
                </button>
              )}
              <button onClick={() => onClickDeleteInterest(item.idx)}>
                <i className="icon_fav_on_tack" />
                <span>즐겨찾기 삭제하기</span>
              </button>
              {(!isNaN(item.reception_at) || !isNaN(item.deadline_at)) && (
                <>
                  <button onClick={() => onClickAddCalendar(item)}>
                    <i className="fi-rs-calendar-check" />
                    <span>캘린더 등록</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {/*{*/}
        {/*    (item.type != 1 || (item.type == 1 && item.ready == 1)) // 조달청이 아닌 거나 조달청인데 정리된 것이면 참*/}
        {/*    &&*/}
        {/*    <>*/}
        {/*        <div className="content_area">*/}
        {/*            <h2>공고 내용</h2>*/}
        {/*            <p className="content" dangerouslySetInnerHTML={ {__html: item.content_image} }/>*/}
        {/*        </div>*/}
        {/*        <button className="btn_toggle" onClick={() => onClickShowToggle(idx)}>*/}
        {/*            <span>공고 내용 {showList[idx] ? '닫기' : '펼치기'}</span>*/}
        {/*            <i className={`${showList[idx] ? 'icon_more_arrow_up_white' : 'icon_more_white'}`}/>*/}
        {/*        </button>*/}
        {/*    </>*/}
        {/*}*/}
      </div>
    );
  });

  return (
    <div id="TaskMyAddList">
      <div className="header_inner_search">
        <div className="header_keyword">
          <p onClick={() => hashTagPushHistory("메타버스")}>#메타버스</p>
          <p onClick={() => hashTagPushHistory("융자")}>#융자</p>
          <p onClick={() => hashTagPushHistory("인공지능")}>#인공지능</p>
          <p onClick={() => hashTagPushHistory("홍보")}>#홍보</p>
        </div>
        <div className="search_area">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="1500여 개 공공기관 사업 한 번에 찾기?"
            onKeyUp={(e) => {
              if (e.key === "Enter")
                history.push(`${links.taskManage}?searchKeyword=${keyword}`);
            }}
          />
          {keyword.length > 0 ? (
            <button
              className="icon_del_small_with_bg"
              onClick={() => setKeyword("")}
            />
          ) : (
            <button
              className="icon_search_blue"
              onClick={() =>
                history.push(`${links.taskManage}?searchKeyword=${keyword}`)
              }
            />
          )}
        </div>
      </div>
      <div className="header">
        <button
          onClick={() => history.push(links.taskManage)}
          className="btn_go"
        >
          <i className="icon_list" />
          <p>리스트로 돌아가기</p>
        </button>
        <button to="/taskManage/setting" className="btn_mail">
          <i className="icon_mail_blue" />
          <p>관심 공고 메일받기</p>
        </button>
      </div>
      <div className="inner">
        <h2>즐겨찾기 한 공고</h2>
      </div>
      <div className="list">{items}</div>
      {hasMore && (
        <button className="btn_more" onClick={nextList}>
          더보기
        </button>
      )}
    </div>
  );
}

export default TaskMyAddList;
