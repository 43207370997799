import "../css/HoldingsCommon.scss";
import HoldingsInvest from "./HoldingsInvest";
import HoldingsInvestDistribution from "./HoldingsInvestDistribution";
import HoldingsLevel from "./HoldingsLevel";
import HoldingsSituation from "./HoldingsSituation";

const HoldingsInvestComp = () => {
  return (
    <div id="HoldingsInvestComp" className="holdings_common">
      <HoldingsInvest />
      <div className="distribution">
        <HoldingsLevel />
        <HoldingsInvestDistribution />
      </div>
      <HoldingsSituation />
    </div>
  );
};

export default HoldingsInvestComp;
