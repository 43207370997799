import React, { useState } from "react";
import "./css/MarketInfoMy.scss";
import MarketInfoEditList from "./edit/MarketInfoEditList";
import qs from "query-string";
import MarketInfoRequestList from "./request/MarketInfoRequestList";

function MarketInfoMy({ location }) {
  const { category } = qs.parse(location.search);
  const [type, setType] = useState(category || 0);
  const [edit, request] = [0, 1];
  const isEdit = type == edit;
  const isRequest = type == request;

  return (
    <div id="MarketInfoMy" className="marketInfoMy">
      <div className="marketInfoMy_menu">
        <h2>
          <i className="icon_category_paper" />
          나의 시장 정보
        </h2>
        <ul>
          <li className={isEdit ? "active" : ""} onClick={() => setType(edit)}>
            시장 정보 작성 리스트
          </li>
          <li
            className={isRequest ? "active" : ""}
            onClick={() => setType(request)}
          >
            시장 정보 작성요청 리스트
          </li>
        </ul>
      </div>
      {isEdit && <MarketInfoEditList />}
      {isRequest && <MarketInfoRequestList />}
    </div>
  );
}

export default MarketInfoMy;
