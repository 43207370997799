import React, {useState} from "react";
import "./PatentDownload.scss";
import dataVoucherAPI from "../../API/dataVoucher/dataVoucher";

let downloadStart = false;

function PatentDownload() {
    const [files, setFiles] = useState(undefined);

    async function wordDownload (type, auto) {
        if((/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }

        if(downloadStart){
            alert('이미 다운로드가 진행중입니다.');
            return;
        }

        if(!files || files.length === 0){
            alert('파일을 선택해주세요');
            return;
        }

        downloadStart = true;

        const formData = new FormData();

        formData.append('file', files[0]);

        if(type === 0) {
            if(auto === true){
                formData.append('type', 1);
            }
            await dataVoucherAPI.asyncDownload('test/dataVoucher/selectPatentWord', formData);
        }else if(type === 1) {
            await dataVoucherAPI.asyncDownload('test/dataVoucher/chartResearch', formData);
        }

        downloadStart = false;
    }

    async function excelDownload (type, auto) {
        if((/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }

        if(downloadStart){
            alert('이미 다운로드가 진행중입니다.');
            return;
        }

        if(!files || files.length === 0){
            alert('파일을 선택해주세요');
            return;
        }

        downloadStart = true;

        const formData = new FormData();

        formData.append('file', files[0]);


        await dataVoucherAPI.asyncDownload('test/dataVoucher/excel/patent', formData);

        downloadStart = false;
    }

    return (
        <div id="PatentDownload">
            <div>
                <input
                    id="file"
                    type={"file"}
                    onChange={(e) => setFiles(e.target.files)}
                />
            </div>
            
            <div>
                <button onClick={() => wordDownload(0)}>특허 요약 정보 수동 다운</button>
            </div>

            <div>
                <button onClick={() => wordDownload(0, true)}>특허 요약 정보 DB내 데이터 기반으로 다운</button>
            </div>

            <div>
                <button onClick={() => wordDownload(1)}>선행기술다운</button>
            </div>

            <div>
                <button onClick={() => excelDownload()}>특허 리스트 다운</button>
            </div>

        </div>
    );
}

export default PatentDownload;
