import { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import logo from "../../assets/images/common/logo_findBiz.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import _ from "lodash";
import { Bar, Line, Pie } from "react-chartjs-2";
import moment from "moment";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import Family from "./Family";
import AnnualMarketInfo from "./AnnualMarketInfo";
import MarketInfoPopupDetail from "../marketInfo/MarketInfoPopupDetail";
import ResearchTechFamilyView from "../researchTech/ResearchTechFamilyView";

import "../patent/family/css/FamilyView.scss";
import "./css/AnnualFeeReport.scss";
import "../researchTech/css/ResearchTechPatentInfo.scss";

const AnnualFeeReport = ({
  setIsReportShow,
  patent_idx,
  family_idx,
  annualFeeData,
  setIsCompFrameShow,
  report_idx,
}) => {
  const location = useLocation();
  let history = useHistory();
  const companyIdx = localStorage.getItem("company_idx");
  const [analysis, setAnalysis] = useState({}); // 그래프 데이터
  const copyAnalysis = _.cloneDeep(analysis);
  const [companyInfo, setCompanyInfo] = useState({}); // 관련 기업 정보 리스트
  const [keyWordSearch, setKeyWordSearch] = useState({}); // 관련 주요 특허 리스트
  const [patentInfo, setPatentInfo] = useState({}); // 특허 정보
  const [commonPatentInfo, setCommonPatentInfo] = useState({});
  const [strPatentSummery, setStrPatentSummery] = useState(""); // 검토 요약 내용
  const [alliedProducts, setAlliedProducts] = useState([]); // 관련 제품 정보 리스트
  const [marketInfo, setMarketInfo] = useState([]); // 관련 시장 정보
  const [industryType, setIndustryType] = useState([]); // 업종
  const [relatedField, setRelatedField] = useState([]); // 관련 분야
  const [alliedCompany, setAlliedCompany] = useState([]); // 관련 국내 기업
  const [alliedForCompany, setAlliedForCompany] = useState([]); // 관련 해외 기업
  const [tooltipState, setTooltipState] = useState(true); // 도움말 설정
  const [popupType, setPopupType] = useState("graph"); // graph, content
  const [popupInfo, setPopupInfo] = useState({});
  const [popupShow, setPopupShow] = useState(false);
  const [mi, setMi] = useState({});
  const [nationList, setNationList] = useState([]);
  const [uniqueNationList, setUniqueNationList] = useState([]);
  const [strCpcInfo, setStrCpcInfo] = useState(""); // CPC 정보
  const tooltipMsgs = [
    {
      name: "domestic_market",
      msg: "특허가 활용가능한 시장과 시장의 크기를 확인",
    },
    {
      name: "oversea_market",
      msg: "해외에서 특허가 활용 가능한 시장과 시장의 크기를 확인",
    },
    {
      name: "product",
      msg: "본 특허로 만들수 있는 제품군들이 어떤 것이 있는지 확인",
    },
    {
      name: "domestic_comp",
      msg: "수익화 고려 대상 혹은 시장경쟁 국내기업",
    },
    {
      name: "oversea_comp",
      msg: "수익화 고려 대상 혹은 시장경쟁 해외기업",
    },
    {
      name: "ipc",
      msg: "본 특허와 연관성이 있는 특허전체의 특허분야 분류",
    },
    {
      name: "grade_info",
      msg: "관련특허들의 특허등급 분포정보",
    },
    {
      name: "related_patent",
      msg: "개별특허의 등급포함",
    },
    {
      name: "trl",
      msg: "본 특허 기술은 제품화하기 위해 어느 정도의 레벨에 있는 기술인지 확인해보세요 전체 10단계 중에 10에 가까울수록 제품에 근접함.",
    },
    {
      name: "ai",
      msg: "산업분류 기준의 업종은 실제 실무상의 업종 분류 기준과 다름",
    },
  ];

  useEffect(() => {
    getReportInfo();
  }, []);

  useEffect(() => {
    if (patentInfo && Object.keys(patentInfo).length > 0) {
      const productArr = splitWords(patentInfo.allied_products);
      const marketInfoArr = splitWords(patentInfo.market_info);
      const industryTypeArr = splitWords(patentInfo.industry_type);
      const relatsedFieldArr = splitWords(patentInfo.related_field);
      const alliedCompanyArr = splitWords(patentInfo.allied_company);
      const alliedForCompanyArr = splitWords(patentInfo.allied_for_company);

      setAlliedProducts(productArr);
      setMarketInfo(marketInfoArr);
      setIndustryType(industryTypeArr);
      setRelatedField(relatsedFieldArr);
      setAlliedCompany(alliedCompanyArr);
      setAlliedForCompany(alliedForCompanyArr);
    }
  }, [patentInfo]);

  useEffect(() => {
    // 국가 리스트 중복제거
    let filteredNationList = nationList.filter(
      (nation, index, self) =>
        index === self.findIndex((t) => t.nation === nation.nation)
    );
    setUniqueNationList(filteredNationList);
  }, [nationList]);

  // ===== start 그래프 옵션 =====
  const vertical_options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            callback: (value) => window.$Global.commaify(value),
          },
        },
      ],
    },
  };

  const pie_options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const line_options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  // ===== end 그래프 옵션 =====

  // 특허권자별 특허 건수
  const applicant_count =
    (copyAnalysis?.applicant_count?.items &&
      copyAnalysis.applicant_count.items
        .filter((item) => item.apply_cnt + item.register_cnt > 0)
        .splice(0, 6)) ||
    [];
  const vertical_applicant_labels = applicant_count.map((item) => item.name);

  const vertical_applicant_data = {
    labels: vertical_applicant_labels,
    datasets: [
      {
        label: "출원건수",
        data: applicant_count.map((item) => item.apply_cnt),
        backgroundColor: "rgba(69, 147, 245, 1)",
      },
      {
        label: "등록건수",
        data: applicant_count.map((item) => item.register_cnt),
        backgroundColor: "rgba(129, 202, 233, 1)",
      },
    ],
  };

  // 연도별 특허 건 수
  const number_of_year_count =
    (copyAnalysis?.number_of_year?.items &&
      copyAnalysis.number_of_year.items.splice(
        copyAnalysis.number_of_year.items.length - 6
      )) ||
    [];
  const vertical_number_of_year_labels = number_of_year_count.map(
    (item) => item.year
  );

  const vertical_number_of_year_data = {
    labels: vertical_number_of_year_labels,
    datasets: [
      {
        label: "출원건수",
        data: number_of_year_count.map(
          (item) => item.apply_count + item.f_apply_count
        ),
        backgroundColor: "rgba(69, 147, 245, 1)",
      },
      {
        label: "등록건수",
        data: number_of_year_count.map(
          (item) => item.register_count + item.f_register_count
        ),
        backgroundColor: "rgba(129, 202, 233, 1)",
      },
    ],
  };

  // IPC 분포 현황
  const ipc_number =
    (copyAnalysis?.ipc_number?.items &&
      copyAnalysis.ipc_number.items
        .sort((a, b) => a.cnt - b.cnt)
        .splice(copyAnalysis.ipc_number.items.length - 6)) ||
    [];
  const vertical_ipc_number_labels = ipc_number.map((item) => item.ipc_code);

  const pie_data = {
    labels: vertical_ipc_number_labels,
    datasets: [
      {
        data: ipc_number.map((item) => item.cnt),
        backgroundColor: [
          "rgb(130 106 249)",
          "rgb(255 231 0)",
          "rgb(45 153 255)",
          "rgb(80 93 111)",
          "rgb(44 217 197)",
          "rgb(255 159 64)",
        ],
        // borderColor: [
        //   "rgba(255, 99, 132, 1)",
        //   "rgba(54, 162, 235, 1)",
        //   "rgba(255, 206, 86, 1)",
        //   "rgba(75, 192, 192, 1)",
        //   "rgba(153, 102, 255, 1)",
        //   "rgba(255, 159, 64, 1)",
        // ],
        // borderWidth: 1,
      },
    ],
  };

  // 연도별 국내&해외기업 특허 건수
  const line_data = {
    labels: vertical_number_of_year_labels,
    datasets: [
      {
        label: "국내",
        data: number_of_year_count.map(
          (item) => item.apply_count + item.register_count
        ),
        borderColor: "rgb(255, 166, 0)",
        backgroundColor: "rgba(255, 166, 0, 0.5)",
        lineTension: 0,
        fill: false,
      },
      {
        label: "해외",
        data: number_of_year_count.map(
          (item) => item.f_apply_count + item.f_register_count
        ),
        borderColor: "rgb(129, 202, 233)",
        backgroundColor: "rgba(129, 202, 233, 0.5)",
        lineTension: 0,
        fill: false,
      },
    ],
  };

  // 등록건에 대한 특허 등급 정보
  copyAnalysis?.patent_grade_info?.items.reverse();

  const vertical_patent_list_data = {
    labels: copyAnalysis?.patent_grade_info?.items?.map(
      (item) => item.sm_grade
    ),
    datasets: [
      {
        label: "등록건수",
        data: copyAnalysis?.patent_grade_info?.items?.map((item) => item.cnt),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const getReportInfo = async () => {
    if (patent_idx !== null && patent_idx !== undefined && patent_idx !== "") {
      const res = await CommonAPI.getReportInfo({
        patent_idx: annualFeeData.nation === 0 ? patent_idx : family_idx,
        nation: annualFeeData.nation,
        family_idx: patent_idx,
      });

      setAnalysis(res.data?.analysis);
      setCompanyInfo(res.data?.companyInfo);
      setKeyWordSearch(res.data?.keyWordSearch);
      setPatentInfo(res.data?.patentInfo);
      setStrPatentSummery(res.data?.strPatentSummery);
      setAnalysis(res.data?.analysis?.data);
      setKeyWordSearch(res.data?.keyWordSearch.items);
      setMi(res.data.mi);
      setStrCpcInfo(res.data.strCpcInfo);

      /*getPatentInfo(
          res.data?.patentInfo.apply_number,
          res.data?.patentInfo.nation
      );*/
    } else if (
      report_idx !== null &&
      report_idx !== undefined &&
      report_idx !== ""
    ) {
      const res = await CommonAPI.getReportDummyPatentInfo({
        report_idx: report_idx,
        nation: annualFeeData.nation,
        apply_number: annualFeeData.apply_number,
        idx: annualFeeData.idx,
      });

      if (res.data?.resultCode === "00") {
        console.log("res::", res);
        setAnalysis(res.data?.analysis);
        setCompanyInfo(res.data?.companyInfo);
        setKeyWordSearch(res.data?.keyWordSearch);
        setPatentInfo(res.data?.patentInfo);
        setStrPatentSummery(res.data?.strPatentSummery);
        setAnalysis(res.data?.analysis?.data);
        setKeyWordSearch(res.data?.keyWordSearch.items);
        setMi(res.data.mi);
        setStrCpcInfo(res.data.strCpcInfo);

        /*getPatentInfo(
          res.data?.patentInfo.apply_number,
          res.data?.patentInfo.nation
        );*/
      }
    }
  };

  const getPatentInfo = (apply_number, nation) => {
    CommonAPI.getPatentInfo(apply_number.replace(/-/g, ""), {
      nation: window.$Global.convertNationCodeToStr(nation),
    }).then((res) => {
      console.log("resssss:", res);
      setCommonPatentInfo(res.data);
    });
  };

  const onClickSearch = (input) => {
    history.push({ pathname: "/competitor/list", state: { keyword: input } });
  };

  const splitWords = (input) => {
    // "|"를 구분자로 사용하여 입력 문자열을 분할
    if (input) {
      return input.split("|");
    }
    return [];
  };

  const signalToColor = (signal) => {
    let color = "";

    switch (signal) {
      case 0:
        color = "Green";
        break;
      case 1:
        color = "Orange";
        break;
      case 2:
        color = "Red";
        break;
      default:
        color = "Gray";
        break;
    }

    return color;
  };

  const addComma = (price) => {
    let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnString;
  };

  const onClickToolTipState = () => {
    setTooltipState(!tooltipState);
    window.localStorage.setItem(
      "patent_tooltip",
      String(Number(!tooltipState))
    );
  };

  const findMsgByName = (name) => {
    return tooltipMsgs.find((el) => el.name === name);
  };

  const ToolTip = ({ name }) => {
    return (
      tooltipState && (
        <i
          className="icon_badge_info_black"
          data-content={findMsgByName(name).msg}
        ></i>
      )
    );
  };

  const onClickShowPopup = (item, type) => {
    setPopupShow(true);
    setPopupInfo(item);
    setPopupType(type);
  };

  const downloadReport = () => {
    const { nation } = annualFeeData;
    let payload = {
      // patent_idx: nation === 0 ? patent_idx : family_idx,
      // nation,
      // family_idx: patent_idx,
    };
    if (patent_idx !== null && patent_idx !== undefined && patent_idx !== "") {
      payload = {
        patent_idx: annualFeeData.nation === 0 ? patent_idx : family_idx,
        nation: annualFeeData.nation,
        family_idx: patent_idx,
      };
    } else if (
      report_idx !== null &&
      report_idx !== undefined &&
      report_idx !== ""
    ) {
      payload = {
        report_idx: report_idx,
        nation: annualFeeData.nation,
        apply_number: annualFeeData.apply_number,
        idx: annualFeeData.idx,
      };
    }

    CommonAPI.downloadPatentReport(payload);
  };

  return (
    <div id="AnnualFeeReport">
      <div className="close">
        <i class="icon_exit_gray" onClick={() => setIsReportShow(false)}></i>
      </div>
      {/* <div className="desc">
        <p>특허 수익화 최고 전문가들이 만든 우리 회사 AI특허 분석 보고서</p>
        <p>
          우리 특허와 관련된 모든 정보를 한눈에 파악해야 활용, 수익, 비용 절감을
          할 수 있다.
        </p>
        <p>우리 회사 보유 특허 활용 전략 보고서</p>
      </div> */}
      {/* <div className="index">
        <ul>
          <li>서지 사항</li>
          <li>특허요약</li>
          <li>특허 등급 (9등급)</li>
          <li>특허의 핵심키워드</li>
          <li>연차료 납부 정보</li>
          <li>연차료 납부 추천 등급</li>
          <li>관련 국내 시장</li>
          <li>관련 해외시장</li>
          <li>관련 국내 기업</li>
          <li>관련 해외 기업</li>
          <li>기술 분야</li>
          <li>관련 특허 요약 정리</li>
        </ul>
      </div> */}
      <div className="tit_box">
        <strong>특허번호 {patentInfo.apply_number} 검토 보고서</strong>
        <div className="tip">
          {companyIdx === "MOZQYC25XKG8KEE0" ? ( // 아이티엘에서만 다운로드 버튼 노출
            <button className="download_btn" onClick={() => downloadReport()}>
              보고서 다운로드
            </button>
          ) : (
            ""
          )}

          <i className="icon_badge_question" />
          <ToggleButtonWithSpan
            text="도움말 설정"
            condition={{ on: "ON", off: "OFF" }}
            selected={tooltipState}
            toggleSelected={onClickToolTipState}
          />
        </div>
      </div>
      <div className="info">
        <div>
          <p>
            <i className="icon_calendar_blue"></i>
            <strong>보고서 작성일:</strong>
            {moment().format("YYYY년 MM월 DD일")}
          </p>
        </div>
        {/* <div>
          <p>
            <i className="icon_people"></i>
            <strong>보고서 작성자:</strong>XXX팀 YYY
          </p>
        </div> */}
      </div>
      <div className="round_box bg_green">
        <i className="icon_search_green tit_icon"></i>
        <strong className="box_tit">서지 사항</strong>
        <ul className="dot_list">
          <li>
            <strong>특허출원번호:</strong> {patentInfo.apply_number}
          </li>
          <li>
            <strong>출원일:</strong> {patentInfo.apply_date}
          </li>
          <li>
            <strong>특허등록번호:</strong> {patentInfo.register_number}
          </li>
          <li>
            <strong>등록일:</strong> {patentInfo.register_date}
          </li>
          <li>
            <strong>발명의 명칭:</strong> {patentInfo.title}
          </li>
          <li>
            <strong>발명자:</strong> {patentInfo.researcher}
          </li>
          <li>
            <strong>국가:</strong>{" "}
            {window.$Global.convertNationCodeToKoreanStr(patentInfo.nation)}
          </li>
          <li>
            <strong>{patentInfo.payment_year}년차 연차료 금액 :</strong>{" "}
            {patentInfo.cost &&
              addComma(patentInfo.cost) + (patentInfo.money_symbol || "원")}
          </li>
          <li>
            <strong>연차료납부 기한 :</strong> {patentInfo.payment_deadline}
          </li>
          <li>
            <strong>공동출원인 정보 :</strong> {patentInfo.applicant_name}
          </li>
          <li>
            <strong>CPC 정보 :</strong> {strCpcInfo}
          </li>
        </ul>
      </div>
      <div className="round_box bg_purple">
        <i className="icon_summary tit_icon"></i>
        <strong className="box_tit">본 특허관련 종합 요약정보</strong>
        {/* <p>{strPatentSummery}</p> */}
        <ul className="dot_list summary">
          <li>
            본 특허는 {patentInfo.apply_date}에 출원되었고&nbsp;
            {patentInfo.register_date}에 등록된 특허임.
          </li>
          <li>
            {moment().format("YYYY")}년 올해는&nbsp;
            <strong>{patentInfo.payment_year}년차</strong>&nbsp;연차료 지급해임.
          </li>
          <li>
            본 특허의 핵심 키워드는&nbsp;
            {splitWords(patentInfo.keyword)
              .filter((key) => key.trim() !== "")
              .map(
                (key, index, array) =>
                  key + (index < array.length - 1 ? ", " : "")
              )}
            임
          </li>
          {patentInfo.sm_grade && (
            <li>
              특허의 청구항의 복잡도, family의 수, 출원 경과사항, 소송이력,
              기술분야등의 정보를 종합한 결과 본 특허의 SMART5 등급은&nbsp;
              <span className={`grade grade_${patentInfo.sm_grade}`}>
                {patentInfo.sm_grade}
              </span>
              임
            </li>
          )}
          {uniqueNationList.length ? (
            <li>
              현재 본 특허의 family는&nbsp;
              {uniqueNationList.map((nation, idx) => (
                <span key={idx}>
                  {window.$Global.convertNationCodeToStr(nation.nation)}
                  {idx < uniqueNationList.length - 1 ? ", " : ""}
                </span>
              ))}
              &nbsp;국가에 특허가 존재함
            </li>
          ) : (
            ""
          )}

          <li>
            본 특허에 대한 IPNOW 스마트 연차료 추천 등급은&nbsp;
            <span
              className={`smart smart_${signalToColor(
                patentInfo.signal
              ).toLowerCase()}`}
            >
              {signalToColor(patentInfo.signal)}
            </span>
            등급임
          </li>
        </ul>
      </div>
      <div className="round_box bg_blue">
        <strong className="box_tit">본 특허의 대표 청구항</strong>
        <p>{patentInfo.clam_info}</p>
      </div>
      <div className="round_box bg_pink">
        <strong className="box_tit">
          등록건에 대한 특허등급 정보
          <ToolTip name={"grade_info"} />
        </strong>
        <p>한국발명진흥회 Smart5 특허분석시스템에서 제공하는 특허 등급</p>
        <ul className="smart_list">
          <li>
            <strong>특허평가등급</strong>
            <span className={`grade grade_${patentInfo.sm_grade}`}>
              {patentInfo.sm_grade}
            </span>
          </li>
          <li>
            <strong>권리성 등급</strong>
            <span className={`grade grade_${patentInfo.sm_r_grade}`}>
              {patentInfo.sm_r_grade}
            </span>
          </li>
          <li>
            <strong>기술성 등급</strong>
            <span className={`grade grade_${patentInfo.sm_t_grade}`}>
              {patentInfo.sm_t_grade}
            </span>
          </li>
          <li>
            <strong>활용성 등급</strong>
            <span className={`grade grade_${patentInfo.sm_u_grade}`}>
              {patentInfo.sm_u_grade}
            </span>
          </li>
        </ul>
      </div>
      <div className="round_box bg_blue">
        <strong className="box_tit">본 특허의 핵심 키워드</strong>
        <p>
          {splitWords(patentInfo.keyword)
            .filter((key) => key.trim() !== "")
            .map(
              (key, index, array) =>
                key + (index < array.length - 1 ? ", " : "")
            )}
        </p>
      </div>
      <div className="round_box border_box">
        <strong className="box_tit">연차료 납부 추천 등급</strong>
        <div className="rcm_grade">
          <strong
            className={`smart smart_${signalToColor(
              patentInfo.signal
            ).toLowerCase()}`}
          >
            &nbsp;
          </strong>
          <p>
            연차료납부는 비용이 발생합니다. 비용 절감을 위해 검토를 해 보세요.
            <br />
            연차료 납부 추천은 단순한 참고용입니다.
            <br />귀 기관의 다양한 상황을 고려하여 납부여부를 결정하시기
            바랍니다.
          </p>
        </div>
      </div>
      <div id="Family" className="round_box bg_blue annual_report">
        <strong className="box_tit_sm">Family</strong>
        {/* family_idx */}
        <div id="ResearchTechPatentInfo">
          {patentInfo.familyList ? (
            <ResearchTechFamilyView
              familyData={patentInfo.familyList}
              state={location.state}
            />
          ) : (
            ""
          )}
        </div>
        {/*<div id="ResearchTechPatentInfo">
          {Object.keys(commonPatentInfo).length > 0 ? (
            <ResearchTechFamilyView
              familyData={commonPatentInfo.familyList}
              state={location.state}
            />
          ) : (
            ""
          )}
        </div>*/}
      </div>
      {/* <div className="sub_tit">
        <strong>추가 검토 내용</strong>
      </div> */}
      <div className="round_box bg_gray">
        <strong className="box_tit_sm">본 특허기술 핵심 요약 정보</strong>
        <p>{patentInfo.summary}</p>
      </div>
      <div className="round_box bg_mint">
        <strong className="box_tit_sm">
          본 특허기술이 이용되는 국내 시장 정보
          <ToolTip name="domestic_market" />
        </strong>
        <p>{patentInfo.market_info}</p>
        {/*<ul className="pd_list">
          {marketInfo.length ? marketInfo.map((item) => <li>{item}</li>) : ""}
      </ul>*/}
      </div>
      <div className="round_box bg_blue">
        <strong className="box_tit_sm">
          본 특허기술이 이용되는 해외 시장 정보
          <ToolTip name={"oversea_market"} />
        </strong>
        <p>{patentInfo.for_market_info}</p>
      </div>
      {mi?.items?.length ? (
        <div className="round_box border_box">
          <div className="market_lilst">
            {mi?.items?.length ? (
              mi.items.map((item) => (
                <AnnualMarketInfo
                  key={item.idx}
                  item={item}
                  showPopup={onClickShowPopup}
                />
              ))
            ) : (
              <p>조회된 데이터가 없습니다</p>
            )}
            <div className="view">
              <MarketInfoPopupDetail
                show={popupShow}
                type={popupType}
                item={popupInfo}
                onClose={() => setPopupShow(false)}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div className="round_box bg_gray">
        <strong className="box_tit_sm">관련 기업 정보 리스트</strong>
        <ul className="comp_list">
          {companyInfo?.normal?.length
            ? companyInfo?.normal.map((item, idx) => (
                <li key={idx}>
                  <img src={logo} />
                  <div className="item_text">
                    <p>{item.info.company_name}</p>
                    <p>-</p>
                    <div className="item_text_category">
                      {item.info.grouptags.length
                        ? item.info.grouptags.map((tag, idx) => (
                            <p key={idx}>{tag.company_group_name}</p>
                          ))
                        : ""}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      onClickSearch("현대두산인프라코어(주)");
                    }}
                  >
                    기업 분석정보
                  </button>
                </li>
              ))
            : "데이터가 없습니다."}
        </ul>
      </div> */}
      <div className="round_box bg_gray">
        <strong className="box_tit_sm">
          본 특허와 관련 깊은 국내 대표기업
          <ToolTip name={"domestic_comp"} />
        </strong>
        {/* 국내 */}
        <ul className="pd_list">
          {alliedCompany.length
            ? alliedCompany
                .filter((item) => item !== "")
                .map((item, index) => <li key={index}>{item}</li>)
            : ""}
        </ul>
        <div className="more_btn">
          <button onClick={() => setIsCompFrameShow((prev) => !prev)}>
            연관기업 더보기
          </button>
        </div>
      </div>
      <div className="round_box bg_mint">
        <strong className="box_tit_sm">
          본 특허와 관련 깊은 해외 대표기업
          <ToolTip name={"oversea_comp"} />
        </strong>
        {/* 해외 */}
        <ul className="pd_list">
          {alliedForCompany.length > 0
            ? alliedForCompany
                .filter((item) => item !== "")
                .map((item, index) => <li key={index}>{item}</li>)
            : ""}
        </ul>
      </div>
      <div className="round_box bg_pink">
        <strong className="box_tit_sm">
          본 특허기술을 활용한 제품군
          <ToolTip name={"product"} />
        </strong>
        <ul className="pd_list">
          {alliedProducts.length > 0
            ? alliedProducts
                .filter((item) => item !== "")
                .map((item, index) => <li key={index}>{item}</li>)
            : ""}
        </ul>
      </div>
      <div className="round_box bg_green">
        <strong className="box_tit_sm">
          본 특허기술의 기술 성숙도
          <ToolTip name={"trl"} />
        </strong>
        <p>{patentInfo.trl_info}</p>
      </div>
      <div className="round_box bg_purple">
        <strong className="box_tit_sm">
          본 특허기술에 해당되는 산업분류 기준 업종
        </strong>
        <p>{patentInfo.tech_class}</p>
      </div>
      <div className="round_box bg_gray">
        <strong className="box_tit_sm">
          AI가 추천하는 업종 기준
          <ToolTip name={"ai"} />
        </strong>
        <ul className="pd_list">
          {industryType.length > 0
            ? industryType
                .filter((item) => item !== "")
                .map((item, index) => <li key={index}>{item}</li>)
            : ""}
        </ul>
      </div>
      <div className="round_box bg_mint">
        <strong className="box_tit_sm">본 특허기술과 관련된 연관 분야</strong>
        <ul className="pd_list">
          {relatedField.length > 0
            ? relatedField
                .filter((item) => item !== "")
                .map((item, index) => <li key={index}>{item}</li>)
            : ""}
        </ul>
      </div>
      {!_.isEmpty(keyWordSearch) && (
        <div className="round_box border_box">
          <strong className="box_tit_sm">
            본 특허와 관련된 주요특허 정보
            <ToolTip name={"related_patent"} />
          </strong>
          <table>
            <colgroup>
              <col width={"5%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"*"} />
              <col width={"*"} />
              <col width={"5%"} />
            </colgroup>
            <thead>
              <tr>
                <th>국가</th>
                <th>등록번호</th>
                <th>등록일</th>
                <th>출원번호</th>
                <th>출원일</th>
                <th className="left">발명의 명칭</th>
                <th>출원인</th>
                <th>등급</th>
              </tr>
            </thead>
            <tbody>
              {keyWordSearch.length ? (
                keyWordSearch.map((search, idx) => (
                  <tr key={idx}>
                    <td>{search.nation}</td>
                    <td>{search.register_number}</td>
                    <td>
                      {search.register_at.trim()
                        ? moment(search.register_at).format("YYYY.MM.DD")
                        : ""}
                    </td>
                    <td>{search.apply_number}</td>
                    <td>
                      {search.apply_at.trim()
                        ? moment(search.apply_at).format("YYYY.MM.DD")
                        : ""}
                    </td>
                    <td className="left">{search.invention_name}</td>
                    <td>{search.applicant}</td>
                    <td>{search.sm_grade}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {!_.isEmpty(analysis) && (
        <div className="round_box bg_blue">
          <strong className="box_tit_sm">
            본 특허와 연관성이 깊은 특허에 대한 분석 정보
          </strong>
          <div className="graph_box">
            <div className="graph">
              <strong>특허권자별 특허 건수</strong>
              <div className="chart_box">
                <Bar
                  height={250}
                  data={vertical_applicant_data}
                  options={vertical_options}
                />
              </div>
            </div>
            <div className="graph">
              <strong>연도별 특허 건 수</strong>
              <div className="chart_box">
                <Bar
                  height={250}
                  data={vertical_number_of_year_data}
                  options={vertical_options}
                />
              </div>
            </div>
            <div className="graph">
              <strong>
                IPC 분포 현황
                <ToolTip name={"ipc"} />
              </strong>
              <div className="chart_box">
                <Pie height={250} data={pie_data} options={pie_options} />
              </div>
            </div>
            <div className="graph">
              <strong>연도별 국내&해외기업 특허 건수</strong>
              <div className="chart_box">
                <Line height={250} data={line_data} options={line_options} />
              </div>
            </div>
            <div className="graph">
              <strong>등록건에 대한 특허 등급 정보</strong>
              <div className="chart_box">
                <Bar
                  height={250}
                  data={vertical_patent_list_data}
                  options={vertical_options}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnualFeeReport;
