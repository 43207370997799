import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import TutorialMain from "../components/tutorial/TutorialMain";
import TutorialListView from "../components/tutorial/TutorialListView";
import TutorialDetail from "../components/tutorial/TutorialDetail";
import CustomSwitch from "./common/CustomSwitch";

function TutorialRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/main`} requireAuth={false} component={TutorialMain}/>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={false} component={TutorialListView}/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={false} component={TutorialDetail}/>

		</CustomSwitch>
	)
}

export default TutorialRoute;