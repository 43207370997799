import React, { useEffect, useState } from "react";
import "./css/PopupTaskDetail.scss";
import CommonAPI from "../../API/common";
import * as Scroll from "react-scroll";

let Link = Scroll.Link;
let Element = Scroll.Element;

function PopupTaskDetail({ show, item, field, onClickInterestAdd, onClose }) {
  const [tab, setTab] = useState(0);

  const onClickView = () => {
    let imageWin = window.open("", "_blank");
    imageWin.document.write(
      `<html><body style='margin:0; text-align: center;'><style type="text/css">img {max-width: 1100px;}</style>`
    );
    imageWin.document.write(item.content_image);
    imageWin.document.write("</body><html>");
  };

  const onClickFileDownload = (idx, f_idx) => {
    CommonAPI.taskFileDownload(idx, f_idx);
  };

  if (window.$Global.isEmptyObject(item) || !show) return null;

  return (
    <div id="PopupTaskDetail" className="custom_scroll">
      <div className="header_menu">
        <i className="icon_del_big_gray" onClick={onClose} />
      </div>
      <div className="detail_area">
        <div className="post_title">
          <p className="detail_dday">
            {Math.ceil(item.d_day) ? `D-${Math.ceil(item.d_day)}` : ""}
          </p>
          <h2 className="title">{item.title}</h2>
        </div>
        <div className="data_wrap">
          <div className="data_wrap_left">
            <div className="item_date">
              <div>
                <p>접수일</p>
                <p>{window.$Global.convertDate(item.reception_at)}</p>
              </div>
              <div>
                <p>마감일시</p>
                <p>{window.$Global.convertDate(item.deadline_at)}</p>
              </div>
              <div>
                <p>금액</p>
                <p>
                  {item.amount.includes("공고참조") ? (
                    item.amount
                  ) : (
                    <>
                      <span>{window.$Global.commaify(item.amount)}</span>
                      <span>원</span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="item_inquries">
              <p>문의처: {item.inquries}</p>
            </div>
          </div>
          <div className="data_wrap_right">
            <div>
              <p>공고기관</p>
              <p>{item.department_name}</p>
            </div>
            <div>
              <p>분 야</p>
              <p>{window.$Global.matchJsonToStr(field, "field", item.field)}</p>
            </div>
            <div>
              <p>참가 가능 지역</p>
              <p>
                {window.$Global.matchJsonToStr(field, "region", item.region)}
              </p>
            </div>
            <div>
              <p>문의처</p>
              <p>{item.inquries}</p>
            </div>
            <div>
              <p>모집마감일</p>
              <p>
                {Math.ceil(item.d_day)}일 ~
                {window.$Global.convertDateTimeSlash(item.deadline_at)}
              </p>
            </div>
            <div className="data_btns">
              {item.uri && (
                <button
                  className="btn_go"
                  onClick={() => window.open(item.uri, "_blank")}
                >
                  <i className="icon_link" />
                  공고문 바로가기
                </button>
              )}
              <button
                className="btn_add"
                onClick={() => onClickInterestAdd(item.idx)}
              >
                <i className="icon_fav_on_tack" />
                관심공고 추가하기
              </button>
            </div>
          </div>
        </div>
        <div className="data_files">
          <div className="data_file_item">
            <h2>첨부파일</h2>
            <div className="inner">
              {item.file.map((f_item, idx) => {
                return (
                  <p
                    key={idx}
                    onClick={() => onClickFileDownload(item.idx, f_item.idx)}
                  >
                    <i className="icon_download_attached_blue" />
                    {f_item.file_name + "." + f_item.file_type}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {item.content_core && (
        <>
          <div className="content_tab">
            {item.content_core.map((item2, idx2) => {
              return <Link smooth={true}>{item2.title}</Link>;
            })}
          </div>
          <div className="content_area">
            {item.content_image && (
              <button onClick={onClickView}>전체 공고문 보기</button>
            )}
            {item.content_core.map((item3, idx3) => {
              return (
                <Element key={idx3} className="inner">
                  <div className="title">
                    <i className="icon_detail" />
                    {item3.title}
                  </div>
                  <p
                    className="content sun-editor-editable"
                    dangerouslySetInnerHTML={{ __html: item3.content }}
                  />
                </Element>
              );
            })}
          </div>
        </>
      )}
      <button className="btn_close" onClick={onClose}>
        <i className="icon_del_big_gray" />
        닫&emsp;기
      </button>
    </div>
  );
}

export default PopupTaskDetail;
