import React from "react";

function FileRow({title, onClick1, onClick2, haveFile}) {
    return (
        <div className="row between">
            <h2 className="title">{title}</h2>
            <div className="btns">
                <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClick1}/>
                <button className="icon_upload" onClick={onClick2}/>
            </div>
        </div>
    );
}

export default FileRow;
