import GroupwareSideBar from "./GroupwareSideBar";
import {
  groupWareEmpPath,
  groupWareExpensionWorksPath,
  groupwareMypagePath,
} from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom";
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState } from "react";
import IframeResizer from "iframe-resizer-react";

const getAppPath = (section, command, params) => {
  var src = config.gwUrl + "/";
  if (section == "empAuth") {
    src += "emp.auth.do";
  } else if (section == "conAuth") {
    src += "contractor.auth.do";
  } else if (section == "impInfo") {
    src += "important.information.do";
  } else if (section == "approvalPer") {
    src += "approval.person.do";
  }
  src += "?command=" + command;
  return src;
};

const resizeIframe = (ifrIdName) => {
  //var the_height = document.getElementById(ifrIdName).body.scrollHeight;
  //document.getElementById(ifrIdName).height = the_height;
  //let iframe = document.querySelector("#contentWrap");
  //alert(iframe.contentDocument.body.scrollHeight);
  //iframe.style.height = iframe.contentDocument.body.scrollHeight;
};

export const GroupwareMypage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  var isSection = !params.get("section") ? "doc" : params.get("section");
  var isCommand = !params.get("command") ? "writeForm" : params.get("command");
  var isApp = getAppPath(isSection, isCommand, params) + "&siteFlag=biznavi";
  //var _isApp     = getAppPathExt({"section":isSection, "command":isCommand, "doc_type":isDocType, "lstPart", });

  //alert(sideBarSection);
  // useEffect(() => {
  //   window.addEventListener(
  //     "message",
  //     (e) => {
  //       //alert(e.data.iframeHeight);
  //       let height = e.data.iframeHeight;
  //       if(height < 800){
  //         height = 800;
  //       }
  //       document.getElementById('contentWrap').height = height + 20;
  //     },
  //     false
  //   );
  // }, []);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupwareMypagePath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={isApp}
          style={{ minWidth: "100%" }}
          heightCalculationMethod="bodyScroll"
        />
      </div>
    </div>
  );
};

export default GroupwareMypage;
