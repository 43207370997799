import React, {useEffect} from 'react';

const WithPreventScroll = (WrappedComponent, moMenuShow, FuncEvent) => {
    const body = document.querySelector('body');

    useEffect(() => {
        if (moMenuShow) {
            body.style.overflow = 'hidden';
        }
        return () => {
            body.style.removeProperty('overflow');
        };
    }, [moMenuShow]);

    return (props) => (
        <WrappedComponent {...props} moMenuShow={moMenuShow} FuncEvent={FuncEvent}/>
    );
};

export default WithPreventScroll;
