import React, {useState, useEffect, useContext} from "react";
import "./css/FreeUsApply.scss";
import {useParams} from "react-router-dom";
import IntroRow from "../patentIntro/row/IntroRow";
import PatentAPI from "../../API/patent";
import AdminAPI from "../../API/admin";
import _ from "lodash";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";

import PopupPatentInfo from "../patentIntro/PopupPatentInfo";
import TableRow from "../patentApplicationInfo/row/TableRow";
import memberContext from "../../store/Member";

function FreeUsApply({history}) {
    const {idx} = useParams();
    const addFileRef = React.useRef();
    const {admin} = useContext(memberContext);
    const [request, setRequest] = useState({
        additional_file: [],
        comment: "",
        idea_desc: "",
        idea_file:[],
        idx: "",
        product: [],
        state: -1
    });
    const [historyRequest, setHitoryRequest] = useState({});
    const [editing, setEditing] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [downPopupShow, setDownPopupShow] = useState(false);
    const [upPopupShow, setUpPopupShow] = useState(false);
    const [fileViewList, setFileViewList] = useState([]);
    const [uploadFileViewList, setUploadFileViewList] = useState([]);
    const [popupPatentInfoShow, setPopupPatentInfoShow] = useState(false);
    const columnShow = {
        checkbox: false,
        no: true,
        name: true,
        patent_no: true,
        nation: true,
        apply_number: true,
        register_number: true,
        state: true,
        invention_name: true,
        product_state: true,
        relative_file: false,
        amount: true
    };
    const isNew = idx == "new";
    const isAdmin = admin;

    useEffect(() => {
        if (!isNew) {
            init();
        } else {
            setEditing(true);
        }
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(request)) {
            drawPatentInfoTable();
        }
    }, [request, editing]);

    const init = async () => {
        let result = {};
        if (!isAdmin) {
            result = await PatentAPI.getFreeUsInfo(idx).then(res => res.data);
        } else {
            result = await AdminAPI.getFreeUsInfo(idx).then(res => res.data);
        }
        setRequest(result);
        setHitoryRequest(result);
    };

    const onClickDownPopShow = () => {
        setDownPopupShow(true);
        setFileViewList(request.idea_file);
    };

    const onClickUpPopShow = () => {
        setUpPopupShow(true);
        if (isNew) {
            setUploadFileViewList(request.idea_file);
        }
    };

    const onClickAdd = () => {
        let patent_list = [];

        let formData = new FormData();

        for (let i = 0; i < request.product.length; i++) {
            let item = request.product[i];
            item.patent.forEach(item => patent_list.push(item.idx));
        }

        for (let i in request.idea_file) {
            formData.append('idea_file', request.idea_file[i].file);
        }

        for (let i in request.additional_file) {
            formData.append('additional_file', request.additional_file[i]);
        }
        if (request.idea_desc.length == 0) {
            alert("특허 기술을 작성해주세요");
            return;
        }
        if (patent_list.length == 0) {
            alert("특허를 등록해주세요");
            return;
        }

        formData.append('idea_desc', request.idea_desc);
        formData.append('patent_list', JSON.stringify(patent_list));

        PatentAPI.addFreeUs(formData).then(() => {
           alert("등록되었습니다");
           history.push('/freeApply/list');
        });
    };

    const onClickSave = () => {
        let payload = request;
        payload.patent_list = [];

        for (let i = 0; i < payload.product.length; i++) {
            let item = payload.product[i];
            item.patent.forEach(item => payload.patent_list.push(item.patent_idx));
        }

        PatentAPI.updateFreeUsInfo(idx, payload).then(res => {
            setEditing(false);
            window.$Global.saveToast();
        });
    };

    const onClickCancle = () => {
        setEditing(false);
        setRequest(historyRequest);
    };

    const onClickSubmit = () => {
        PatentAPI.updateFreeUsInfo(idx, {submit: 1}).then(res => {
            window.$Global.toast('신청되었습니다');
            history.push('list');
        });
    };

    const onClickDownload = (file_idx) => {
        if (isAdmin) {
            AdminAPI.downloadFreeUsFile(idx, file_idx);
        } else {
            PatentAPI.downloadFreeUsFile(idx, file_idx);
        }
    };

    const onClickDeleteFileInDownload = async (file_idx, type) => {
        if (!isNew) {
            await PatentAPI.deleteFreeUsFile(idx, file_idx).then(() => {
                alert('삭제되었습니다');
            });
        }
        if (!type) {
            setFileViewList(fileViewList.filter(file => {
                return file.idx !== file_idx;
            }));
        } else if (type === 'add') {
            let copy = _.cloneDeep(request);
            copy.additional_file = copy.additional_file.filter((file, index) => {
                if (!isNew) {
                    return file.idx !== file_idx;
                } else {
                    return index !== file_idx;
                }
            });
            setRequest(copy);
        }
    };

    const onClickUpload = async () => {
        let formData = new FormData();

        if (isNew) {
            setRequest({...request, idea_file: uploadFileViewList});
        } else {
            uploadFileViewList.forEach(item => {
                formData.append("idea_file", item.file);
            });

            await PatentAPI.updateFreeUsInfo(idx, formData).then(() => {
                alert('업로드되었습니다');
                setUploadFileViewList([]);
            });
        }
        setUpPopupShow(false);
    };

    const onClickRegisterPatentInfo = (arr) => {
        let copy = _.cloneDeep(request);
        let concat_arr = copy.product.concat(arr);
        copy.product = window.$Global.deduplicationToJSON(concat_arr);
        setRequest(copy);
        setPopupPatentInfoShow(false);
    };

    const onClickPatentInfoDelete = (idx) => {
        let copy = _.cloneDeep(request);
        copy.product.splice(idx, 1);
        setRequest(copy);
    };

    const onChangeAddFile = (e) => {
        var formData = new FormData();

        if (isNew) {
            let copy = _.cloneDeep(request);
            copy.additional_file = copy.additional_file.concat(Array.from(e.target.files));
            setRequest(copy);
        } else {
            e.target.files.forEach(item => {
                formData.append("additional_file", item);
            });
            PatentAPI.updateFreeUsInfo(idx, formData).then(res => {
                alert("파일 추가되었습니다");
                init();
            });
        }
    };

    const onClickAdminConfirm = (type) => {
        let payload = {
            state: type,
            comment: request.comment
        };
        AdminAPI.updateFreeUs(idx, payload).then(res => {
            alert("처리되었습니다.");
            history.go(-1);
        })
    };

    const drawPatentInfoTable = () => {
        let tags = [];

        for (let i = 0; i < request.product.length; i++) {
            let data = request.product[i];
            let first_patent = data.patent.length ? data.patent[0] : {};
            let sales = data.sales;
            let rowSpan =  data.patent.length;

            tags.push(
                <TableRow key={i}
                          editing={false}
                          rowSpan={rowSpan|| 1}
                          emptyInfo={window.$Global.isEmptyObject(first_patent)}
                          number={i+1}
                          nation_string={window.$Global.convertNationCodeToStr(first_patent.nation)}
                          apply_number={first_patent.apply_number}
                          patent_idx={first_patent.idx}
                          product_idx={data.idx}
                          name={data.product_name || data.name}
                          state={first_patent.state}
                          invention_name={first_patent.invention_name || first_patent.title}
                          product_state={first_patent.product_state}
                          cur_year={new Date().getFullYear()}
                          sales={sales}
                          register_number={first_patent.register_number}
                          afterShow={true} columnShow={columnShow}
                          index={i}
                          deleteEditing={editing}
                          onClickDelete={onClickPatentInfoDelete}/>
            );

            for (let j = 1; j < rowSpan; j++) {
                let item = data.patent[j];

                tags.push(
                    <TableRow key={i + "-" + j}
                              editing={false}
                              rowSpan={rowSpan || 1}
                              number={j+1}
                              nation_string={window.$Global.convertNationCodeToStr(first_patent.nation)}
                              apply_number={item.apply_number}
                              patent_idx={item.idx} product_idx={data.idx}
                              state={item.state}
                              invention_name={first_patent.invention_name || first_patent.title}
                              product_state={item.product_state}
                              sales={sales}
                              register_number={item.register_number}
                              afterShow={false}
                              columnShow={columnShow}
                              index={i}
                              deleteEditing={editing}
                              onClickDelete={onClickPatentInfoDelete}/>
                );
            }
        }

        if (tags.length == 0) {
            tags = [window.$Global.notTd(12, '추가한 특허가 없습니다')];
        }
        setTbody(tags);
    };

    let stateTag = [];

    if (request.state == 0) {
        stateTag = <p className="state">신청 대기 중입니다</p>;
    } else if (request.state == 1) {
        stateTag = <p className="state">심사 중입니다</p>;
    } else if (request.state == 3) {
        stateTag = <p className="state red">반려되었습니다</p>;
    } else if (request.state == 2) {
        stateTag = <p className="state green">승인이 완료되었습니다</p>;
    }

    let addFileTag = window.$Global.notTd(3, '등록된 파일이 없습니다');

    if (request.additional_file.length) {
        addFileTag = request.additional_file.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.file_name ? (item.file_name + '.' + item.file_type) : item.name}</td>
                    {
                        !isNew &&
                        <td>
                            <button className="icon_download" onClick={() => onClickDownload(item.idx)}/>
                        </td>
                    }
                    {
                        editing &&
                        <td>
                            <button className="icon_badge_del" onClick={() => onClickDeleteFileInDownload(item.idx || idx, 'add')}/>
                        </td>
                    }
                </tr>
            )
        });
    }

    if (!isNew && !request.idx) return null;

    return (
        <div id="FreeUsApply">
            <div className='header'>
                <h2 className="title">미국 특허 무료 출원 신청서 {isNew ? '작성' : ''}</h2>
                {stateTag}
            </div>
            <div className="field">
                <IntroRow title="핵심 특허기술"
                          smallTitle="특허 기술 설명란"
                          fileTitle="첨부파일"
                          introDesc={request.idea_desc}
                          condition={editing}
                          onChange={e => setRequest({...request, idea_desc: e.target.value})}
                          autoFocus={true}
                          upHide={!editing}
                          downHide={isNew}
                          onClickDownPopShow={() => onClickDownPopShow("core")}
                          onClickUpPopShow={() => onClickUpPopShow("core")}
                />
                <div className="table_list">
                    <div className="header">
                        <h2 className="title">출원 신청할 특허 리스트</h2>
                        {
                            editing && <button className="btn_add" onClick={() => setPopupPatentInfoShow(true)}>추가하기</button>
                        }
                    </div>
                    <div className="table-box-wrap not_align">
                        <div className="wrap_list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>순번</th>
                                        <th>제품/<br/>서비스</th>
                                        <th>순번</th>
                                        <th>국가</th>
                                        <th>출원번호</th>
                                        <th>Smart3<br/>등급</th>
                                        <th>NOW<br/>사업성</th>
                                        <th>등록번호</th>
                                        <th>현재상태</th>
                                        <th>발명의<br/>명칭</th>
                                        <th>적용유무</th>
                                        <th colSpan="5">매출액 (전년 - 추후 3년)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tbody}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="field">
                <div className="table_list add_file">
                    <div className="header">
                        <h2 className="title">추가자료</h2>
                        {
                            editing &&
                            <button className="btn_add" onClick={() => addFileRef.current.click()}>추가하기</button>
                        }
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>순번</th>
                                <th>첨부파일명</th>
                                {
                                    !isNew && <th>다운로드</th>
                                }
                                {
                                    editing && <th>삭제</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {addFileTag}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="btns_ctl">
                {
                    isNew
                        ?   <button className="btn_save" onClick={onClickAdd}>작성완료</button>
                        :   request.state == 0 && <button className="btn_submit" onClick={onClickSubmit}>신청하기</button>
                }
            </div>

            {
                (!isAdmin && (request.state == 2 || request.state == 3)) &&
                <div className={`wrap_comment ${request.state == 2 ? 'approval' : 'reject'}`}>
                    <h2 className="title">{request.state == 2 ? '승인' : '반려'} 사유</h2>
                    <div className="comment">{request.comment}</div>
                </div>
            }
            {
                isAdmin &&
                <>
                    <div className="wrap_comment">
                        <h2 className="title">승인 및 반려 사유</h2>
                        <textarea className="comment" defaultValue={request.comment} onChange={e => setRequest({...request, comment: e.target.value})}/>
                    </div>

                    <div className="wrap_btns">
                        <button className="btn_approval" onClick={() => onClickAdminConfirm(2)}>승인하기</button>
                        <button className="btn_unable" onClick={() => onClickAdminConfirm(3)}>반려</button>
                    </div>
                </>
            }
            <input type="file" ref={addFileRef} multiple hidden onChange={onChangeAddFile}/>
            <DetailPopupDownload show={downPopupShow}
                                 setShow={setDownPopupShow}
                                 fileViewList={fileViewList}
                                 setFileList={setFileViewList}
                                 deleteHide={!editing}
                                 onClickDownload={onClickDownload}
                                 onClickDelete={onClickDeleteFileInDownload}/>
            <DetailPopupUpload show={upPopupShow}
                               setShow={setUpPopupShow}
                               uploadFileViewList={uploadFileViewList}
                               setFileList={setUploadFileViewList}
                               onClickUpload={onClickUpload}/>

            <PopupPatentInfo idx="US" show={popupPatentInfoShow} onClickRegister={onClickRegisterPatentInfo} onClickClose={() => setPopupPatentInfoShow(false)}/>
        </div>
    )
}

export default FreeUsApply;
