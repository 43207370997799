import React from "react";
import ApplyField from "./common/ApplyField";
import ClaimField from "./common/ClaimField";
import DefaultKRField from "./common/DefaultKRField";
import BankField from "./common/BankField";
import CostField from "./common/CostField";

function CostDetailPatentKR({edit}) {
    return (
        <div className="form">
            <DefaultKRField edit={edit}/>
            <ApplyField/>
            <ClaimField edit={edit}/>
            <CostField edit={edit}/>
            <BankField edit={edit}/>
        </div>
    )
}

export default CostDetailPatentKR;
