import React from 'react';
import './css/RelativeFileTable.scss';
import RelativeFileRow from "./RelativeFileRow";

function RelativeFileTable({props}) {
    let rows;
    rows = props.rows.map((row, index) =>
        <RelativeFileRow key={index} row={row}/>
    );

    return (
        <div className="RelativeFileList">
            <table className="list">
                <caption>{props.title}</caption>
                <thead>
                <tr>
                    <th>항목</th>
                    <th>다운</th>
                    <th>업로드일</th>
                </tr>
                </thead>
                <tbody>
                    {
                        rows.length
                        ? rows
                        : window.$Global.notTd(3, '관련 파일이 없습니다')
                    }
                </tbody>
            </table>
        </div>
    );
}

export default RelativeFileTable;
