import React from "react";
import CheckBox from "../../common/CheckBox";
import _ from "lodash";

function TableRow({
  editing,
  rowSpan,
  emptyInfo,
  afterShow,
  columnShow,
  patent_idx,
  product_idx,
  cur_year,
  sales,
  number,
  name,
  nation_string,
  apply_number,
  register_number,
  state,
  invention_name,
  product_state,
  sm_grade,
  now_grade,
  onClickInput,
  onChange,
  onClickDownPopShow,
  onClickUpPopShow,
  checkboxList,
  setCheckboxList,
  setCheckboxListNotClone,
  index,
  deleteEditing,
  onClickDelete,
}) {
  let colsSpan =
    Number(columnShow.patent_no) +
    Number(columnShow.nation) +
    Number(columnShow.apply_number) +
    Number(columnShow.register_number) +
    Number(columnShow.state) +
    Number(columnShow.invention_name) +
    Number(columnShow.product_state);

  const getYearToAmount = (arr, year) => {
    for (let idx in arr) {
      if (arr[idx].year == year) {
        return arr[idx].amount;
      }
    }
    return "";
  };

  return (
    <tr>
      {afterShow && (
        <>
          {columnShow.checkbox && (
            <td className="td1 rowspan" rowSpan={rowSpan}>
              <CheckBox
                checked={checkboxList[index]}
                onChangeChecked={() => {
                  if (setCheckboxListNotClone) {
                    setCheckboxList((prev) =>
                      prev.map((item, idx) => (index === idx ? !item : item))
                    );
                  } else {
                    let copy = _.cloneDeep(checkboxList);
                    copy[index] = !copy[index];
                    setCheckboxList(copy);
                  }
                }}
              />
            </td>
          )}
          {columnShow.no && (
            <td className="rowspan td2" rowSpan={rowSpan}>
              {onClickDelete ? (
                deleteEditing ? (
                  <button
                    className="btn_remove icon_badge_del"
                    onClick={() => onClickDelete(index)}
                  />
                ) : (
                  number
                )
              ) : (
                number
              )}
            </td>
          )}
          {columnShow.name && (
            <td className="rowspan border_right td3" rowSpan={rowSpan}>
              {editing ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) =>
                    onClickInput("name", product_idx, e.target.value)
                  }
                />
              ) : (
                name
              )}
            </td>
          )}
        </>
      )}
      {Boolean(emptyInfo && colsSpan) && (
        <td colSpan={colsSpan} className="empty_info td4">
          특허가 등록되지 않은 상태입니다.
        </td>
      )}
      {!emptyInfo && columnShow.patent_no && <td className="td4">{number}</td>}
      {!emptyInfo && columnShow.nation && (
        <td className="no_border td5">{nation_string}</td>
      )}
      {!emptyInfo && columnShow.apply_number && (
        <td className="no_border td6">{apply_number || "N/A"}</td>
      )}
      {!Boolean(emptyInfo && colsSpan) && (
        <td className="value td7">
          <div className={`circle grade_${sm_grade || "default"}`}>
            {sm_grade || "N"}
          </div>
        </td>
      )}
      {!Boolean(emptyInfo && colsSpan) && (
        <td className="value td8">
          <div className={`circle grade_${now_grade || "default"}`}>
            {now_grade || "N"}
          </div>
        </td>
      )}
      {!emptyInfo && columnShow.register_number && (
        <td className="no_border td9">{register_number || "N/A"}</td>
      )}
      {!emptyInfo && columnShow.state && (
        <td className="no_border td10">
          {window.$Global.convertStateToText(state)}
        </td>
      )}
      {!emptyInfo && columnShow.invention_name && (
        <td className="no_border td11">{invention_name || "N/A"}</td>
      )}
      {!emptyInfo && columnShow.product_state && (
        <td className="no_border td12">
          {editing ? (
            <select
              value={product_state}
              onChange={(e) =>
                onChange(product_idx, patent_idx, e.target.value)
              }
            >
              <option value={0}>미적용</option>
              <option value={1}>제품 개발중</option>
              <option value={2}>제품 적용중</option>
            </select>
          ) : (
            window.$Global.convertProductStateCodeToState(product_state)
          )}
        </td>
      )}
      {afterShow && (
        <>
          {columnShow.relative_file && (
            <td className="rowspan td13" rowSpan={rowSpan}>
              {!emptyInfo && (
                <>
                  <button
                    className="icon_download"
                    onClick={() => onClickDownPopShow(product_idx)}
                  />
                  <button
                    className="icon_upload"
                    onClick={() => onClickUpPopShow(product_idx)}
                  />
                </>
              )}
            </td>
          )}

          {!emptyInfo && columnShow.amount ? (
            <>
              <td className="rowspan td14" rowSpan={rowSpan}>
                <div className="color_blue">
                  {editing ? (
                    <input
                      type="text"
                      value={getYearToAmount(sales, cur_year - 1) || ""}
                      onChange={(e) =>
                        onClickInput(
                          "amount",
                          product_idx,
                          e.target.value,
                          cur_year - 1
                        )
                      }
                      placeholder="1억 5천만원"
                    />
                  ) : (
                    getYearToAmount(sales, cur_year - 1) || "N/A"
                  )}
                </div>
                <div>
                  {cur_year - 1}
                  <br />
                  작년
                </div>
              </td>
              <td className="rowspan td15" rowSpan={rowSpan}>
                <div className="color_blue">
                  {editing ? (
                    <input
                      type="text"
                      value={getYearToAmount(sales, cur_year) || ""}
                      onChange={(e) =>
                        onClickInput(
                          "amount",
                          product_idx,
                          e.target.value,
                          cur_year
                        )
                      }
                      placeholder="1억 5천만원"
                    />
                  ) : (
                    getYearToAmount(sales, cur_year) || "N/A"
                  )}
                </div>
                <div>
                  올해
                  <br />
                  (예상)
                </div>
              </td>
              <td className="rowspan td16" rowSpan={rowSpan}>
                <div className="color_blue">
                  {editing ? (
                    <input
                      type="text"
                      value={getYearToAmount(sales, cur_year + 1) || ""}
                      onChange={(e) =>
                        onClickInput(
                          "amount",
                          product_idx,
                          e.target.value,
                          cur_year + 1
                        )
                      }
                      placeholder="1억 5천만원"
                    />
                  ) : (
                    getYearToAmount(sales, cur_year + 1) || "N/A"
                  )}
                </div>
                <div>
                  {cur_year + 1}
                  <br />
                  +1년 후
                </div>
              </td>
              <td className="rowspan td17" rowSpan={rowSpan}>
                <div className="color_blue">
                  {editing ? (
                    <input
                      type="text"
                      value={getYearToAmount(sales, cur_year + 2) || ""}
                      onChange={(e) =>
                        onClickInput(
                          "amount",
                          product_idx,
                          e.target.value,
                          cur_year + 2
                        )
                      }
                      placeholder="1억 5천만원"
                    />
                  ) : (
                    getYearToAmount(sales, cur_year + 2) || "N/A"
                  )}
                </div>
                <div>
                  {cur_year + 2}
                  <br />
                  +2년 후
                </div>
              </td>
              <td className="rowspan td18" rowSpan={rowSpan}>
                <div className="color_blue">
                  {editing ? (
                    <input
                      type="text"
                      value={getYearToAmount(sales, cur_year + 3) || ""}
                      onChange={(e) =>
                        onClickInput(
                          "amount",
                          product_idx,
                          e.target.value,
                          cur_year + 3
                        )
                      }
                      placeholder="1억 5천만원"
                    />
                  ) : (
                    getYearToAmount(sales, cur_year + 3) || "N/A"
                  )}
                </div>
                <div>
                  {cur_year + 3}
                  <br />
                  +3년 후
                </div>
              </td>
            </>
          ) : (
            columnShow.amount && (
              <>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </>
            )
          )}
        </>
      )}
    </tr>
  );
}

export default TableRow;
