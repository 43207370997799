import { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import "./css/AdminAnnualManage.scss";
import { Link, useHistory } from "react-router-dom";

const AdminAnnualManage = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    /*AdminAPI.getAdminCompanyAnnual().then((res) => {
      setData(res.data);
    });*/
    AdminAPI.getAdminMngCompanyAnnual().then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <div id="AdminAnnualManage">
      <div className="annual_wrapper">
        <h2>연차료 납부 관리</h2>
        <div className="annual_user_list">
          <div className="list_header">
            <p>고객 리스트</p>
            <button onClick={() => history.push("/admin/annual/giveup")}>
              포기권 모아보기
            </button>
          </div>
          <div className="annual_list_grid">
            {data?.map((el) => {
              return (
                <Link
                  to={{
                    pathname: `/admin/annual/graph`,
                    state: { company_idx: el.company_idx },
                  }}
                >
                  <div className="annual_list_grid_item">
                    <p>
                      {el.name}
                      <span>바로가기</span>
                    </p>
                    <i className="icon_double_arrow_right" />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="annual_search_wrapper">
        <div className="search_box">
          <p>대학·기관명 검색하기</p>
          <div className="search_input">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <i
              className="icon_del_small_with_bg_gray"
              onClick={() => setSearch("")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAnnualManage;
