import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareWorksPath } from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import Moment from "react-moment";
import "moment/locale/ko";
import { Bar, Doughnut } from "react-chartjs-2";
import config from "../../const/config";

const GroupwareApprovalMng = () => {
  console.log(groupWareWorksPath);
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareWorksPath['approval']} />
      <div className="commute_wrapper">
      <iframe
          src={`${
            config.gwUrl
          }/approval.person.do?command=lstApproval`}
          width="100%"
          height="600px"
        />
      </div>
    </div>
  );
};

export default GroupwareApprovalMng;
