import { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import "./css/ResearchTechCategory.scss";
import { useHistory } from "react-router-dom";

const ResearchTechCategory = () => {
  const [category, setCategory] = useState([]);
  const history = useHistory();

  useEffect(() => {
    CommonAPI.getResearchCategory().then((res) => {
      let copy = res.data.category_list;
      copy.map((el) => {
        el.isOpen = false;
      });
      setCategory(copy);
    });
  }, []);

  return (
    <div id="ResearchTechCategory">
      <ul className="parent_category">
        {category.map((item) => {
          return (
            <li
              key={item.idx}
              className="parent_category_item"
              onClick={() => {
                item.isOpen = !item.isOpen;
                setCategory([...category]);
              }}
            >
              {item.name}
              {item.isOpen && (
                <ul className="child_category">
                  {item.child.map((child_item) => {
                    return (
                      <li
                        key={child_item.idx}
                        className="child_category_item"
                        onClick={() => {
                          history.push(
                            `list?category_idx1=${item.idx}&category_idx2=${child_item.idx}`
                          );
                        }}
                      >
                        {child_item.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ResearchTechCategory;
