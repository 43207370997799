import React, {useState} from "react";
import "../css/AdminCalcYearPayAdd.scss";
import InfoCompanyAPI from "../../../API/infoCompany";
import AdminAPI from "../../../API/admin";
import _ from "lodash";
import {Link} from "react-router-dom";

function AdminCalcYearPayAdd() {
    const initData = {
        name: "",
        company_code: "",
        type: 0
    };
    const [payload, setPayload] = useState(initData);
    const [selectCompanyList, setSelectCompanyList] = useState([]);
    const [mouse, setMouse] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [keywordList, setKeywordList] = useState([]);
    const show = mouse && Boolean(keywordList.length);

    const onClickSearch = () => {
        InfoCompanyAPI.getInfoCompany(keyword).then(res => {
            setMouse(true);
            setKeywordList(res.data);
        });
    };

    const choiceKeyword = (name, code) => {
        setMouse(false);
        setPayload({...payload, name: name, company_code: code});

        let copy = _.cloneDeep(selectCompanyList);
        copy.push({name: name, company_code: code});
        setSelectCompanyList(copy);
    };

    const onClickClose = () => {
        setMouse(false);
        setKeyword("");
        setKeywordList([]);
        setPayload({name: "", company_code: ""});
    };

    const add = () => {
        AdminAPI.postAnnualPay(payload).then(() => {
            alert("등록되었습니다");
            setKeyword("");
            setPayload(initData);
        });
    };

    return (
        <div id="AdminCalcYearPayAdd">
            <h2 className="title">특허 연차료</h2>
            <div className="articles">
                <div className="field">
                    <span>검색</span>
                    <input type="text"
                           value={keyword}
                           placeholder="회사명을 입력하여 검색하실 수 있습니다"
                           onChange={e => setKeyword(e.target.value)}
                           onKeyUp={e => e.key === 'Enter' && onClickSearch()}
                           onFocus={() => setMouse(true)}
                    />
                    {
                        show && <button className="icon_badge_del ir_txt" onClick={onClickClose}>닫기</button>
                    }
                    {
                        show
                        &&
                        <ul className="keyword_list">
                            {
                                keywordList.map((item, idx) => {
                                    return (
                                        <li key={idx} onClick={() => choiceKeyword(item.name, item.company_code)}>
                                            <p>
                                                <b>회사명:</b>{item.name}</p>
                                            <p>
                                                <b>주소:</b>{item.location}
                                            </p>
                                            <p>
                                                <b>법인번호:</b>{item.corporate_number}
                                            </p>
                                            <p>
                                                <b>사업자 등록번호:</b>{item.license_number}
                                            </p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                    <button className="btn_search" onClick={onClickSearch}>검색</button>
                </div>
                <div className="field">
                    <span>회사명</span>
                    <p>{payload.name}</p>
                </div>
                <div className="field">
                    <span>회사코드</span>
                    <p>{payload.company_code}</p>
                </div>
                <div className="field">
                    <span>기업 유형</span>
                    <div className="wrap_radio">
                        <label htmlFor="universe">대학</label>
                        <input type="radio" id="universe" name="type" defaultChecked={true} value={0} onChange={e => setPayload({...payload, type: Number(e.target.value)})}/>
                        <label htmlFor="patent">출연 연구소</label>
                        <input type="radio" id="patent" name="type" value={1} onChange={e => setPayload({...payload, type: Number(e.target.value)})}/>
                        <label htmlFor="company">기업</label>
                        <input type="radio" id="company" name="type" value={2} onChange={e => setPayload({...payload, type: Number(e.target.value)})}/>
                    </div>
                </div>
            </div>

            <div className="wrap_btn">
                <button className="btn_register" onClick={add}>등록</button>
                <Link to={"/admin/yearPay"} className="btn_register">등록 목록 보러가기</Link>
            </div>
        </div>
    )
}

export default AdminCalcYearPayAdd;