import React, {useEffect, useState} from "react";
import InputCustom from "../inputCommon/InputCustom";
import InputId from "../inputCommon/InputId";
import InputEmail from "../inputCommon/InputEmail";
import InputPhone from "../inputCommon/InputPhone";

function SignupInputInvestor({keywordList,
                              commonPwdTag,
                              isInvestor, editAble, checkId, checkEmail, checkPhone,
                              signInfo, setSignInfo,
                              onChangeFindCompany,
                              onClickCheckId, onClickCheckEmail, onClickCertification}) {
    const able = editAble;
    const props_common = {
        able, signInfo, setSignInfo
    };

    const fileRef = React.useRef();
    const [personal, setPersonal] = useState(true); // 투자자가 개인일 경우 true

    let type_arr = ['개인형 엑셀러레이터 (IA)', '법인형 액셀러레이터 (CA)', '벤처캐피탈 (VC)', '사모펀드 (PEF)', '은행', '개인투자조합', '기술지주회사', '기업'];
    let type_option =
        type_arr.map((item, idx) => {
            return <option key={idx} value={idx}>{item}</option>
        });

    useEffect(() => {
        if (signInfo.type_detail == "0") {
            setPersonal(true);
        } else {
            setPersonal(false);
        }
    }, [signInfo.type_detail]);


    const onClickFile = () => {
        fileRef.current.click();
    };

    return (
        <>
            <div className="field" style={{marginTop: 40}}>
                <div className="f_head">투자자 유형</div>
                <div className="f_content">
                    <select value={signInfo.type_detail} onChange={(e) => setSignInfo({...signInfo, type_detail: e.target.value})}>
                        {type_option}
                    </select>
                </div>
            </div>
            <div className={`wrap_field ${personal ? 'action_no' : ''}`}>
                <div className="field">
                    <div className="f_head">
                        {
                            signInfo.type_detail != 0
                            &&
                            <i className="icon_red_dot"/>
                        }
                        기관 명칭
                    </div>
                    <div className="f_content">
                        <input type="text"
                               value={signInfo.company}
                               placeholder="검색하세요"
                               onChange={(e) => onChangeFindCompany(e.target.value, "company")}
                        />
                        {keywordList}
                    </div>
                </div>
            </div>
            <div className={`field ${personal ? 'action_no' : ''}`}>
                <div className="f_head">
                    {
                        signInfo.type_detail != 0
                        &&
                        <i className="icon_red_dot"/>
                    }
                    사업자 등록번호
                </div>
                <div className="f_content">
                    <input type="text" value={signInfo.licence_number} onChange={(e) => setSignInfo({...signInfo, licence_number: e.target.value})}/>
                </div>
            </div>
            <div className="box">
                <div className="wrap_field">
                    <InputId able={(isInvestor || editAble)}
                             check={checkId}
                             onClick={onClickCheckId}
                             signInfo={signInfo}
                             setSignInfo={setSignInfo}
                    />
                </div>
                <div className="wrap_field">
                    {commonPwdTag}
                </div>
            </div>
            <div className="wrap_field" style={{marginTop: 40}}>
                <div className="field">
                    <div className="f_head">
                        <i className="icon_red_dot"/>
                        담당자 성명
                    </div>
                    <div className="f_content">
                        <input type="text"
                               placeholder="담당자 성명을 입력해주세요"
                               value={signInfo.manager_name}
                               onChange={(e) => setSignInfo({...signInfo, manager_name: e.target.value})}
                        />
                    </div>
                </div>
                <InputEmail able={true}
                            check={checkEmail}
                            required={true}
                            onClick={onClickCheckEmail}
                            signInfo={signInfo}
                            setSignInfo={setSignInfo}
                />
            </div>
            <div className="wrap_field">
                <InputPhone able={editAble}
                            check={checkPhone}
                            required={true}
                            onClick={onClickCertification}
                            signInfo={signInfo}
                            setSignInfo={setSignInfo}
                />
            </div>
            <div className="wrap_field">
                <InputCustom title="회사 홈페이지"
                             objKey="company_homepage"
                             {...props_common}
                />
                <div className="field">
                    <div className="f_head">회사 소개자료</div>
                    <div className="f_content">
                        <p style={{width: 200}}>{signInfo.company_file ? signInfo.company_file.name : '첨부파일 없음'}</p>
                        <button className="icon_upload" onClick={onClickFile}/>
                        <input type="file"
                               ref={fileRef}
                               hidden
                               onChange={(e) => setSignInfo({...signInfo, company_file: e.target.files[0]})}
                        />
                    </div>
                </div>
            </div>
            <div className="wrap_field" style={{marginBottom: 64}}>
                <div className="field field_txt">
                    <div className="f_head">회사 소개글</div>
                    <textarea type="text"
                              placeholder="회사 소개글을 작성해주세요"
                              value={signInfo.desc}
                              onChange={(e) => setSignInfo({...signInfo, desc: e.target.value})}
                    />
                </div>
            </div>
        </>
    )
};

export default SignupInputInvestor;