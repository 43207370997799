import React from 'react';
import DatePicker from "react-datepicker";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function TranslateInnerDatePickerAndFileRow({title, preventTitle, index, data, condition, ep_idx, setKey, haveFile, onChangeDatePickerJson, onClickDownPopShow, onClickUpPopShow, dateBtnHide, uploadBtnShow}) {

    const setWeekendColor = (date) => {
        return (date.getDay() === 0 || date.getDay() === 6) ? 'color_red' : ''
    };

    let date_content = (data.date !== null);

    return (
        <div className="row">
            <h2 className="title">{title}{(date_content && !preventTitle) ? '일' : ''}<i className="icon_info" data-content={window.$Global.getInfoMessage(title)}/></h2>
            <div className="btns">
                {
                    date_content
                    ? <span>{window.$Global.convertDate(data.date)} </span>
                    : <>
                        <span className="date_txt">기한</span>
                        <span>{data.inner_date ? window.$Global.convertDate(data.inner_date) : 'N/A'} </span>
                      </>
                }
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                        condition &&
                            <DatePicker
                                onChange={date => onChangeDatePickerJson(ep_idx, index, setKey, date)}
                                readOnly={date_content}
                                customInput={<button className="icon_calendar"/>}
                                dayClassName={date => setWeekendColor(date)}/>
                    }
                    <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClickDownPopShow}/>
                    {
                        uploadBtnShow ? <button className="icon_upload" onClick={onClickUpPopShow}/> : ''
                    }
                </div>
            </div>
        </div>
    );
};

export default TranslateInnerDatePickerAndFileRow;
