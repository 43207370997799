import React, {useState, useEffect} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
const axios = require('axios');

function ItlCompany() {

    const count = 100;
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    const [view, setView] = useState('company');
    const [responseData, setResponseData] = useState({});
    const [tbody, setTbody] = useState([]);

    const API = axios.create({
        baseURL: 'https://www.ipnow.co.kr/api/v1',
        timeout: 600000,
        headers: {
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    });

    useEffect(() => {
        getList();
    }, [view]);

    const getList = async (type) => {
        let result = {};
        if (view == "company") {
            result = await API.get('/info/patent/company_all', {params: {count: count, page: page}}).then(res => res.data);
        } else if (view == "cnt") {
            result = await API.get('/info/patent/company_counter', {params: {count: count, page: page}}).then(res => res.data);
        }


        if (type == "next") {
            result.items = responseData.items.concat(result.items);
        } else {
            setResponseData({});
        }
        commonFunc(result, "next");
        setResponseData(result);

        if (view == "company") {
            setTbody(result.items.map((item, idx) => {
                return (
                    <tr key={idx} className="company"
                        onClick={() => onClickCompanySelect(item.company_code, item.name)}>
                        <td>{idx + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.name_en}</td>
                        <td>{item.license_number}</td>
                        <td>{item.corporate_number}</td>
                        <td>{item.all_effective_cnt}</td>
                        <td>{item.effective_cnt}</td>
                        <td>{item.patent_cnt}</td>
                        <td>{item.for_cnt ? item.for_cnt : 0}</td>
                        <td>{item.open_cnt ? item.open_cnt : 0}</td>
                        <td>{item.reg_cnt ? item.reg_cnt : 0}</td>
                        <td>{item.cancel_cnt ? item.cancel_cnt : 0}</td>
                        <td>{item.reject_cnt ? item.reject_cnt : 0}</td>
                        <td>{item.give_up_cnt ? item.give_up_cnt : 0}</td>
                        <td>{item.td_cnt ? item.td_cnt : 0}</td>
                        <td>{item.td_open_cnt ? item.td_open_cnt : 0}</td>
                        <td>{item.td_reg_cnt ? item.td_reg_cnt : 0}</td>
                        <td>{item.td_cancel_cnt ? item.td_cancel_cnt : 0}</td>
                        <td>{item.td_reject_cnt ? item.td_reject_cnt : 0}</td>
                        <td>{item.td_give_up_cnt ? item.td_give_up_cnt : 0}</td>
                        <td>{item.td_effective_cnt ? item.td_effective_cnt : 0}</td>

                        <td>{item.design_cnt ? item.design_cnt : 0}</td>
                        <td>{item.design_open_cnt ? item.design_open_cnt : 0}</td>
                        <td>{item.design_reg_cnt ? item.design_reg_cnt : 0}</td>
                    </tr>
                );
            }));
        } else if (view == "cnt") {
            setTbody(result.items.map((item, idx) => {
                if (!item) return;
                return (
                    <tr key={idx} className="company">
                        <td>{item.all_effective_cnt}</td>
                        <td>{item.cnt}</td>
                    </tr>
                );
            }));
        }
    };

    const onClickCompanySelect = (code) => {
        setView('patent');
        API.get('/info/patent', {params: {company_code: code}}).then(res => {
            setTbody(res.data.map((item, idx) => {
                return (
                    <tr key={idx} className="company">
                        <td>{item.invention_title || item.invention_title_en}</td>
                        <td>{item.status}</td>
                        <td>{item.nation}</td>
                        <td>{window.$Global.convertDate(item.open_at)}</td>
                        <td>{item.open_number}</td>
                        <td>{window.$Global.convertDate(item.register_at)}</td>
                        <td>{item.apply_number}</td>
                        <td>{window.$Global.convertDate(item.apply_at)}</td>
                        <td>{window.$Global.convertDate(item.register_at)}</td>
                        <td>{item.register_number}</td>
                        <td>{item.claim}</td>
                    </tr>
                );
            }));
        });
    };

    const onClickCompany = (type) => {
        if (type != "next") {
            setPage(1);
        }
        setView('company');
    };

    const onClickCntSelect = (type) => {
        if (type != "next") {
            setPage(1);
        }
        setView('cnt');
    };

    const commonFunc = (data, type) => {
        if (type == "next") {
            setPage(page + 1);
        }
        if (data.page == data.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    return (
        <div id="ItlCompaany">
            <button style={{width: 300, height: 50}} onClick={onClickCompany}>회사 목록 보기</button>
            <button style={{width: 300, height: 50, marginLeft: 15}} onClick={onClickCntSelect}>유효특허별 회사 갯수정보</button>
            <div style={{margin: "15px 0"}}>
                <p>현재 페이지: {responseData.page}</p>
                <p>
                    <span>이동할 페이지 입력</span>
                    <input type="number" value={page} onChange={e => setPage(e.target.value)} style={{marginLeft: 10, width: 50}}/>
                    <button style={{marginLeft: 5, width: 50, height: 25}} onClick={onClickCompany}>이동</button>
                </p>
                <p>마지막 페이지: {responseData.last}</p>
            </div>
            <div className="table-box-wrap">
                <div className="wrap_list" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={tbody.length}
                        next={() => getList("next")}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table style={{minWidth: 1280}}>
                            <thead>
                                <tr>
                                    {
                                        view == "patent" &&
                                            <>
                                                <th>발명의 명칭</th>
                                                <th>등록상태</th>
                                                <th>국가</th>
                                                <th>공개일</th>
                                                <th>공개번호</th>
                                                <th>등록일자</th>
                                                <th>출원번호</th>
                                                <th>출원일자</th>
                                                <th>등록일자</th>
                                                <th>등록번호</th>
                                                <th>청구항</th>
                                            </>
                                    }
                                    {
                                        view == "company" &&
                                            <>
                                                <th>순번</th>
                                                <th>회사명</th>
                                                <th>영문법인명</th>
                                                <th>사업자등록번호</th>
                                                <th>법인등록번호</th>
                                                <th>유효 특허,상표, 디자인 갯수</th>
                                                <th>유효 특허 갯수</th>
                                                <th>특허 보유 갯수</th>
                                                <th>해외 특허 갯수</th>
                                                <th>공개 갯수</th>
                                                <th>등록 갯수</th>
                                                <th>취하 갯수</th>
                                                <th>거절 갯수</th>
                                                <th>포기 갯수</th>
                                                <th>전체 상표 개수</th>
                                                <th>상표 공개개수</th>
                                                <th>상표 등록개수</th>
                                                <th>상표 취소 개수</th>
                                                <th>상표 거절 개수</th>
                                                <th>상표 포기 개수</th>
                                                <th>유효 상표 개수</th>
                                                <th>디자인 개수</th>
                                                <th>디자인 공개 개수</th>
                                                <th>디자인 등록 개수</th>
                                            </>
                                    }
                                    {
                                        view == "cnt" &&
                                            <>
                                                <th>유효 특허, 상표, 디자인 개수</th>
                                                <th>회사 수</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>

        </div>
    );
}

export default ItlCompany;
