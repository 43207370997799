import React, {useContext} from "react";
import _ from "lodash"
import DateAndFileRow from "./common/DateAndFileRow";
import OaInnerDatePickerAndFileRow from "./common/OaInnerDatePickerAndFileRow";
import ButtonRow from "./common/ButtonRow";
import DatePickerRow from "./common/DatePickerRow";
import DatePickerOaAndFileRow from "./common/DatePickerOaAndFileRow";
import PatentContext from "../../../store/Patent";
import typeName from "./constrant/typeName";
import ProcessManage from "./common/ProcessManage";
import ProcessStep from "./common/ProcessStep";

function FieldOa({index, onClickOaRemove, onClickDownPopShow, onClickUpPopShow, onClickOaInstruction, onChangeOaInnerDate, onChangeDatePrckerOA}) {
    const patentInfo = useContext(PatentContext);
    let copy = _.cloneDeep(patentInfo);
    let item = patentInfo.ListOa[index];

    return (
        <div className="field company">
            <ProcessStep text="중간 사건" state={patentInfo.state}/>
            <div className="header">
                <h2 className="title">OA {index + 1}</h2>
                <button className="btn_remove" onClick={() => onClickOaRemove(index)}>삭제하기</button>
            </div>
            <div className="rows">
                <DatePickerOaAndFileRow title='OA발행일' titleClassName='color_blue' data={item.published_at}
                                        index={index} dataKey='published_at' dateBtnHide={!patentInfo.editing}
                                        uploadBtnShow={true}
                                        haveFile={Boolean(item.oa_content_file_cnt)}
                                        onChangeDatePickerJson={onChangeDatePrckerOA}
                                        onClickDownPopShow={() => onClickDownPopShow(typeName.oa_content, {}, index)}
                                        onClickUpPopShow={() => onClickUpPopShow(typeName.oa_content, {}, index)}/>
                <DatePickerOaAndFileRow title='OA답변서 제출일' titleClassName='color_blue' data={item.opinion_upload_at}
                                        index={index} dataKey='opinion_upload_at' dateBtnHide={!patentInfo.editing}
                                        uploadBtnShow={true}
                                        haveFile={Boolean(item.oa_opinion_file_cnt)}
                                        onChangeDatePickerJson={onChangeDatePrckerOA}
                                        onClickDownPopShow={() => onClickDownPopShow(typeName.oa_opinion, {modified: 0}, index)}
                                        onClickUpPopShow={() => onClickUpPopShow(typeName.oa_opinion, {modified: 0}, index)}
                />
                <div className="row">
                    <h2 className="title">OA연장 횟수<i className="icon_info" data-content={window.$Global.getInfoMessage("OA연장 횟수")}/></h2>
                    {
                        patentInfo.editing
                            ?
                            <select defaultValue={item.extension_cnt} style={{width: 100}} onChange={(e) => {
                                copy.ListOa[index].extension_cnt = Number(e.target.value);
                                patentInfo.setPatent(copy);
                            }}>
                                <option value={0}>0회</option>
                                <option value={1}>1회</option>
                                <option value={2}>2회</option>
                            </select>
                            : <p>{item.extension_cnt}회</p>
                    }
                </div>
            </div>
            <div className="rows">
                <div className="row no_data"/>
                <div className="row no_data"/>
                <DatePickerRow title='OA기한'
                               titleClassName='color_blue'
                               condition={patentInfo.editing}
                               data={item.deadline}
                               dataClassName="color_red"
                               index={index}
                               dataKey='deadline'
                               useOaFunc={true}
                               onChangeDatePicker={onChangeDatePrckerOA}
                />
            </div>
            <div className="process_manage">
                <ProcessManage index={index} title="중간 사건 상세 절차 관리" selected={item.oa_detail}/>
                {
                    Boolean(item.oa_detail) &&
                    <>
                        <div className="rows">
                            <OaInnerDatePickerAndFileRow title={"대응안\n발명자 검토"} data={item.opinion_researcher}
                                                         index={index}
                                                         dataKey='opinion_researcher'
                                                         haveFile={Boolean(item.oa_opinion_reply_researcher_file_cnt)}
                                                         onChangeOaInnerDate={onChangeOaInnerDate}
                                                         dateBtnHide={!patentInfo.editing}
                                                         uploadBtnShow={true}
                                                         onClickDownPopShow={() => onClickDownPopShow(typeName.oa_reply, {type: 2}, index)}
                                                         onClickUpPopShow={() => onClickUpPopShow(typeName.oa_reply, {type: 2}, index)}
                            />
                            <OaInnerDatePickerAndFileRow title={'대응안\n담당자 검토'}
                                                         data={item.opinion_manager}
                                                         index={index}
                                                         dataKey='opinion_manager'
                                                         haveFile={Boolean(item.oa_opinion_reply_manager_file_cnt)}
                                                         onChangeOaInnerDate={onChangeOaInnerDate}
                                                         dateBtnHide={!patentInfo.editing}
                                                         uploadBtnShow={true}
                                                         onClickDownPopShow={() => onClickDownPopShow(typeName.oa_reply, {type: 1}, index)}
                                                         onClickUpPopShow={() => onClickUpPopShow(typeName.oa_reply, {type: 1}, index)}
                            />
                            <DateAndFileRow title={`대응안\n수정본 전달`}
                                            date={item.opinion_modified_upload_at}
                                            haveFile={Boolean(item.oa_modified_opinion_file_cnt)}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.oa_opinion, {modified: 1}, index)}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.oa_opinion, {modified: 1}, index)}
                                            uploadBtnShow={true}
                            />
                        </div>
                        <div className="rows">
                            <DateAndFileRow title={`대응안\n최종본 접수`}
                                            date={item.opinion_final_upload_at}
                                            haveFile={Boolean(item.oa_final_opinion_file_cnt)}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.oa_opinion, {modified: 2}, index)}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.oa_opinion, {modified: 2}, index)}
                                            uploadBtnShow={true}
                            />
                            <ButtonRow title='OA초안작성 지시' condition={patentInfo.editing}
                                       data={item.draft_order_at} index={index} dataKey='draft_order_at'
                                       onClick={onClickOaInstruction}
                            />
                            <DateAndFileRow title={`OA초안 접수`}
                                            date={item.draft_upload_at}
                                            haveFile={Boolean(item.oa_draft_file_cnt)}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.oa_draft, {modified: 0}, index)}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.oa_draft, {modified: 0}, index)}
                                            uploadBtnShow={true}
                            />
                        </div>
                        <div className="rows">
                            <DateAndFileRow title={`OA수정본 전달`}
                                            date={item.draft_modified_upload_at}
                                            haveFile={Boolean(item.oa_modified_draft_file_cnt)}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.oa_draft, {modified: 1}, index)}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.oa_draft, {modified: 1}, index)}
                                            uploadBtnShow={true}
                            />
                            <DateAndFileRow title={`OA최종본 접수`}
                                            date={item.final_upload_at}
                                            haveFile={Boolean(item.oa_final_draft_file_cnt)}
                                            onClickDownPopShow={() => onClickDownPopShow(typeName.oa_draft, {modified: 2}, index)}
                                            onClickUpPopShow={() => onClickUpPopShow(typeName.oa_draft, {modified: 2}, index)}
                                            uploadBtnShow={true}
                            />
                            <ButtonRow title='OA제출 지시'
                                       condition={patentInfo.editing}
                                       data={item.submission_order_at}
                                       index={index} dataKey='submission_order_at'
                                       onClick={onClickOaInstruction}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default React.memo(FieldOa);
