import React, {useEffect, useState} from "react";
import "./css/TradeItemView.scss";
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import TradeAPI from "../../API/trade";
import CommonAPI from "../../API/common";
import PopupTrade from "./PopupTrade";
import qs from "query-string";

function TradeItemView({history, location}) {
    const {type} = qs.parse(location.search);
    const {idx} = useParams();
    const [proposalIdx, setProposalIdx] = useState(null);
    const [trader, setTrader] = useState('');
    const [tradePrice, setTradePrice] = useState(0);
    const [info, setInfo] = useState({});
    const [moMenuType, setMoMenuType] = useState(0);
    const [popupShow, setPopupShow] = useState(false);

    useEffect(() => {
        getInfo();
    }, [idx]);

    const getInfo = () => {
        CommonAPI.getInfo(idx).then(res => {
            let data = res.data;
            res.data.comment = data.comment || '판매자가 입력한 Comment가 없습니다.';
            res.data.apply_number = data.apply_number || 'N/A';
            res.data.class_arr = window.$Global.deduplicationToJSON(data.class, "class").sort((a, b) => a.class - b.class);
            setInfo(data);
            setTradePrice(data.price);
        });
    };

    const onClickSuccess = async (param) => {
        setPopupShow(false);
        // 구매 요청
        if (moMenuType == 0) {
            await TradeAPI.proposal(idx, {buy: 1}).then(res => {
                setMoMenuType(1);
                setPopupShow(true);
            });
        // 구매자 제안하기
        } else if (moMenuType == 2) {
            setPopupShow(false);
            if (param.length == 0) {
                alert("가격을 입력해주세요");
                return;
            }
            await TradeAPI.proposal(idx, {price: param}).then(res => {
                setMoMenuType(1);
                setPopupShow(true);
            });
        // 판매 결정
        } else if (moMenuType == 4 || moMenuType == 7) {
            await TradeAPI.acceptProposal(idx, proposalIdx,{state: 1}).then(res => {
                setMoMenuType(5);
                setPopupShow(true);
            });
        // 판매자 역제안
        } else if (moMenuType == 8) {
            await TradeAPI.acceptProposal(idx, proposalIdx,{price: param, state: 3}).then(res => {
                setMoMenuType(9);
                setPopupShow(true);
            });
        } else if (moMenuType == 9) {

        } else if (moMenuType == 10) {
            onClickDeleteTrade();
            return;
        } else if (moMenuType == 11) {
            await TradeAPI.reProposal(idx, proposalIdx,{state: 1}).then(res => {
                setMoMenuType(5);
                setPopupShow(true);
            });
        }
        getInfo();
    };

    const onClickReject = async () => {
        if (moMenuType == 7) {
            await TradeAPI.acceptProposal(idx, proposalIdx,{state: 2}).then(res => {
                setMoMenuType(6);
                setPopupShow(true);
            });
        // 역 제안
        } else if (moMenuType == 11) {
            await TradeAPI.reProposal(idx, proposalIdx,{state: 2}).then(res => {
                setMoMenuType(12);
                setPopupShow(true);
            });
        }
        getInfo();
    };

    const onClickDeleteTrade = () => {
        TradeAPI.deleteTrade(idx).then(res => {
            history.push('/trade/list');
        });
    }

    const onClickDeleteProposal = (proposal_idx) => {
        TradeAPI.deleteProposal(idx, proposal_idx || info.proposal_list[0].idx).then(res => {
            getInfo();
        });
    };

    const onClickProposalPopupShow = () => {
        onClickPopupShow(2);
    }

    const onClickTradePopupShow = () => {
        onClickPopupShow(0);
    }

    const onClickSell = (idx, name) => {
        setProposalIdx(idx);
        setTrader(name);
        onClickPopupShow(4);
    };

    const onClickProposal = (idx, name, price) => {
        setProposalIdx(idx);
        setTrader(name);
        setTradePrice(price);
        onClickPopupShow(7);
    }

    const onClickReProposal = (idx, price) => {
        setProposalIdx(idx);
        setTradePrice(price);
        onClickPopupShow(8);
    }

    const onClickTradeDelete = () => {
        onClickPopupShow(10);
    }
    const onClickReProposalConfirm = (idx, price) => {
        setProposalIdx(idx);
        setTradePrice(price);
        onClickPopupShow(11);
    }


    const onClickPopupShow = (type) => {
        setPopupShow(true)
        setMoMenuType(type);
    };

    if (window.$Global.isEmptyObject(info)) return null;

    const isFinish = (info.state == 1);
    const isOwner = (info.owner);
    const proposalStr = Boolean(info.proposal) ? '가격 역제안하기' : '가격 제안 불가';
    const proposalClassName = Boolean(info.proposal) ? '': 'disable';
    const hasProposal = (info.proposal_list.length == 0);
    const waitProposal = (info.proposal_list[0]?.state == 0);
    const reProposal = (info.proposal_list[0]?.state == 3);
    const buyProposal = (info.proposal_list[0]?.state == 4);
    const imageUrl = window.$Global.getCDN(info.thumbnail_key);

    return (
        <div id="TradeItemView">
            <div className="pc">
                <div className="head">
                    <h2>상표 상세 정보</h2>
                    {
                        isOwner
                        ?
                            !isFinish &&
                            <div className="btns">
                                <Link to={`/trade/post?edit=${info.idx}`}>수정</Link>
                                <button onClick={onClickTradeDelete}>삭제</button>
                            </div>
                        :   <p>{window.$Global.convertDate(info.created_at)} 등록</p>
                    }
                </div>
                <div className="wrap">
                    <div className="image">
                        <h2>상표 이미지</h2>
                        <img src={imageUrl} alt="이미지"/>
                    </div>
                    <div className="info">
                        <div className="row">
                            <h2>상표 명칭</h2>
                            <p>{info.name}</p>
                        </div>
                        <div className="wrap">
                            <div className="row">
                                <h2>출원번호</h2>
                                <p>{info.apply_number}</p>
                            </div>
                            <div className="row">
                                <h2>거래 제안 금액</h2>
                                <p className="price">{window.$Global.numberToKorean(info.price)}원</p>
                            </div>
                        </div>
                        <div className="wrap half">
                            <div className="row">
                                <h2>가격 역제안</h2>
                                {
                                    (!isOwner && waitProposal)
                                    &&
                                    <>
                                        <button className="alert_trade_disable disable">제안 승낙 확인 대기 중</button>
                                        <button className="alert_trade_cancel" onClick={() => onClickDeleteProposal()}>취소</button>
                                    </>
                                }
                                {
                                    (!isOwner && reProposal)
                                    &&
                                    <button className="alert"
                                            onClick={() => onClickReProposalConfirm(info.proposal_list[0].idx, info.proposal_list[0].price)}>
                                        판매자측 역제안 확인하기
                                    </button>
                                }
                                {
                                    (!isOwner && hasProposal)
                                    &&
                                    <button className={`alert ${proposalClassName}`} onClick={onClickProposalPopupShow}>{proposalStr}</button>
                                }
                                {
                                    isOwner && <button className={`alert ${proposalClassName}`}>{proposalStr}</button>
                                }
                            </div>
                            <div className="row">
                                {
                                    (
                                        !isOwner
                                        && !isFinish
                                        && (hasProposal || info.proposal_list.filter(item => item.state == 2).length > 0)
                                        && info.proposal_list.filter(item => item.state == 4).length == 0
                                    )
                                    &&
                                    <button className="btn_process" onClick={onClickTradePopupShow}>거래 진행하기</button>
                                }
                                {
                                    (!isOwner && buyProposal)
                                    &&
                                    <>
                                        <button className="alert_trade_disable disable">거래 확인 중</button>
                                        <button className="alert_trade_cancel" onClick={() => onClickDeleteProposal()}>취소</button>
                                    </>
                                }
                                {
                                    isFinish && <button className="alert_trade_finish">완료된 거래입니다.</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment_area">
                    <h2>판매자 Comment</h2>
                    <p>{info.comment}</p>
                </div>
                <div className="classification_area">
                    <h2>상표분류</h2>
                    <p>
                        {
                            info.class_arr.map((item, c_idx) => item.class + '류').join(', ')
                        }
                    </p>
                </div>
                <div className="product_area">
                    <h2>지정상품정보</h2>
                    <div className="list">
                        {
                            info.class_arr.map((item, c_idx) => {
                                return (
                                    <div className="item">
                                        <p className="title">
                                            제{item.class}류
                                        </p>
                                        <p className="value">
                                            {
                                                info.class.map(item2 => {
                                                    if (item.class == item2.class) {
                                                        return <span>{item2.product}</span>;
                                                    }
                                                    return '';
                                                })
                                            }
                                        </p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="mo">
                <div className="header">
                    <button className="icon_more_arrow_left_black" onClick={() => type ? history.goBack() : history.push('/trade/list')}/>
                    <h2 className="page_title">상표 거래</h2>
                </div>
                <div className="view">
                    <img src={imageUrl} className="item_img" alt="이미지"/>
                    <div className="info">
                        <ul className="tags">
                            {
                                info.class_arr.map((item, c_idx) => {
                                    return (
                                        <li key={c_idx}>
                                            <Link to={`/trade/class/${idx}`}>
                                                <p>{item.class}류</p>
                                                <i className="icon_more_arrow_right_white"/>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="item_head">
                        <div className="wrap_item_info">
                            <p className="item_title">{info.company_name}</p>
                            <p className="item_price">{window.$Global.commaify(info.price)}원</p>
                        </div>

                        <div className="wrap">
                            <p className="posting_at">{window.$Global.convertDate(info.created_at)} 등록</p>
                            {
                                isOwner
                                ?
                                    <div className="seller_ctl">
                                        <Link to={`/trade/post?edit=${info.idx}`}>수정</Link>
                                        <button onClick={onClickTradeDelete}>삭제</button>
                                    </div>
                                :   (
                                        Boolean(info.proposal)
                                        ?   Boolean(hasProposal)
                                            ?    <button className="btn_proposal" onClick={onClickProposalPopupShow}>가격 역제안하기</button>
                                            :   (info.proposal_list[0]?.state == 2)
                                                ?   <button className="btn_disable_proposal">역제안을 거절하였습니다</button>
                                                :   null
                                        :    <button className="btn_disable_proposal">가격 역제안 불가</button>
                                    )
                            }
                        </div>
                    </div>
                    <div className="trade_item_apply">
                        <h2>출원번호</h2>
                        <p>
                            {info.apply_number}
                        </p>
                    </div>
                    <div className="trade_item_comment">
                        <h2>판매자 Comment</h2>
                        <p>
                            {info.comment}
                        </p>
                    </div>
                    {
                        (
                            !isOwner
                            && !isFinish
                            && (hasProposal || info.proposal_list.filter(item => item.state == 2).length > 0)
                            && info.proposal_list.filter(item => item.state == 4).length == 0
                        )
                        &&
                        <button className="alert_trade_start" onClick={onClickTradePopupShow}>거래 진행하기</button>
                    }

                    {
                        (!isOwner && waitProposal)
                        &&
                        <div className="alert_wait">
                            <button className="alert_trade_disable">제안 승낙 확인 대기 중</button>
                            <div className="alert_trade_cancel">
                                <div className="inner">
                                    <i className="icon_alert_circle"/>
                                    <p>판매자가 제안을 거절하는 경우<br/>해당 상품에는 더이상 제안 할 수 없습니다.</p>
                                </div>
                                <button onClick={() => onClickDeleteProposal()}>제안 취소</button>
                            </div>
                        </div>
                    }

                    {
                        (!isOwner && reProposal)
                        &&
                        <button className="alert_trade_confirm"
                                onClick={() => onClickReProposalConfirm(info.proposal_list[0].idx, info.proposal_list[0].price)}>
                            판매자측 역제안 확인하기
                        </button>
                    }

                    {
                        (!isOwner && buyProposal)
                        &&
                        <div className="alert_wait buy_wait">
                            <button className="alert_trade_disable">거래 확인 중</button>
                            <div className="alert_trade_cancel">
                                <button onClick={() => onClickDeleteProposal()}>취소하기</button>
                            </div>
                        </div>
                    }
                    {
                        isFinish && <button className="alert_trade_disable alert_trade_finish">완료된 거래입니다.</button>
                    }
                </div>
            </div>

            {
                isOwner
                &&
                <div className="recv_list">
                    <div className="r_head">
                        <div className="first">
                            <h2>받은 요청 리스트</h2>
                            <p>{info.proposal_list.length}건</p>
                        </div>
                    </div>
                    {
                        Boolean(info.proposal_list.length) &&
                        <div className="table-box-wrap">
                            <div id="scrollableDiv" className="wrap_list">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>회사명</th>
                                        <th>구분</th>
                                        <th>가격</th>
                                        <th>제안답변</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        info.proposal_list.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.company_name}</td>
                                                    <td>{item.state == 4 ? '구매요청' : '제안'}</td>
                                                    <td>{window.$Global.commaify(item.price || info.price)}</td>
                                                    <td>
                                                        {
                                                            item.state == 4
                                                            ?   <button className="btn_sell_decision" onClick={() => onClickSell(item.idx, item.company_name)}>판매결정</button>
                                                            :
                                                                item.state == 1
                                                                ?   <p>판매완료</p>
                                                                :   (item.state == 3)
                                                                    ?   <ul>
                                                                            <li className="not_styled">대기중</li>
                                                                            <li className="btn_cancel" onClick={() => onClickDeleteProposal(item.idx)}>취 소</li>
                                                                        </ul>
                                                                    :   (item.state == 2)
                                                                        ?   <p>거절</p>
                                                                        :   <ul>
                                                                                <li className="btn_common" onClick={() => onClickReProposal(item.idx, item.price)}>역제안</li>
                                                                                <li className="btn_common" onClick={() => onClickProposal(item.idx, item.company_name, item.price)}>답변</li>
                                                                            </ul>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            }

            <div className="pc">
                <button className="btn_list" onClick={() => history.push('/trade/list')}>목록</button>
            </div>
            {
                (!isOwner && Boolean(info.relations.length)) &&
                <div className="relation">
                    <h2>이 상표와 연관 있는 상표</h2>
                    <div className="wrap">
                        {
                            info.relations.map((item, idx) => {
                                return (
                                    <Link to={`/trade/view/${item.idx}`} className="relative_item" key={idx}>
                                        <img src={window.$Global.getCDN(item.thumbnail_key)} className="item_img" alt="이미지"/>
                                        <p className="category">{item.product_list}</p>
                                        <p className="name">{item.name}</p>
                                        <p className="price">{window.$Global.commaify(item.price) + '원'}</p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            }
            {
                popupShow
                &&
                <PopupTrade trader={trader}
                            value={tradePrice}
                            showType={moMenuType}
                            onClickSuccess={onClickSuccess}
                            onClickReject={onClickReject}
                            onClickClose={() => setPopupShow(false)}
                />
            }
        </div>
    )
}

export default TradeItemView;