import API from "../../../util/api";

export default {
    getTaskListAPI(params) {
        return API.get('/test/task/hasBidderList', {params});
    },
    getTaskBidderAPI(taskIdx, params) {
        return API.get(
            `/common/task/${taskIdx}/bidder`,
            {params}
        );
    },
    getTaskBidderFinaListAPI(corporateNumber) {
        return API.get(
            `/common/company/finaInfo`,
            {params: {corporateNumber}}
        )
    },
    getTaskBidderRivalListApi(params){
        return API.get(
            `/common/company/rivalList`,
            {params}
        )
    },
    getTaskBidderInfo(params){
        return API.get(
            `/common/company/bidInfo`,
            {params}
        )
    }
};
