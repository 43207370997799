import React, {useEffect, useState} from "react";
import "../css/AdminRecommendTask.scss";
import CommonAPI from "../../../API/common";
import AdminAPI from "../../../API/admin";
import Pagination from "../../common/Pagination";
import _ from "lodash";

function AdminRecommendTask() {
	const [isSearch, setIsSearch] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [searchKeyword, setSearchKeyword] = useState('');
	const [curPage, setCurPage] = useState(1);
	const [list, setList] = useState({items: []});

	useEffect(() => {
		getList(searchKeyword);
	}, [curPage]);

	const getList = (p_keyword, page) => {
		if (curPage !== 1 && page) {
			setCurPage(page);
			return;
		}
		let payload = { page: curPage, keyword: p_keyword, select_option: 0 };
		CommonAPI.getTaskNewHome(payload).then(res => setList(res.data));
	};

	const toggleTaskRecommend = (idx) => {
		AdminAPI.toggleTaskRecommend(idx).then(res => {
			let copy = _.cloneDeep(list);
			copy.items.find(item => item.idx === idx).recommanded = res.data.recommanded;
			setList(copy);
		});
	}

	const onClickSearch = () => {
		setSearchKeyword(keyword);
		getList(keyword, 1);
		setIsSearch(true);
	}

	const onClickCancel = () => {
		setKeyword('');
		setSearchKeyword('');
		setIsSearch(false);
		getList('', 1);
	}

	return (
		<div id="AdminRecommendTask">
			<div className="head">
				<h2 className="title">추천 사업</h2>
				<input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} onKeyUp={(e) => e.key === 'Enter' && onClickSearch()}/>
				{
					isSearch && <button className="icon_exit" onClick={onClickCancel}/>
				}
			</div>

			<table>
				<colgroup>
					<col width="10%"/>
					<col width="70%"/>
					<col width="10%"/>
					<col width="10%"/>
				</colgroup>
				<thead>
					<tr>
						<th>순번</th>
						<th>사업명</th>
						<th>접수일</th>
						<th>추천</th>
					</tr>
				</thead>
				<tbody>
				{
					list.items.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{list.total_count - ((curPage - 1) * 20 + idx)}</td>
								<td>{item.title}</td>
								<td>{window.$Global.convertDate(item.reception_at)}</td>
								<td>
									<button className="btn"
											onClick={() => toggleTaskRecommend(item.idx)}
											style={{backgroundColor: Boolean(item.recommanded) ? 'var(--color-Scorpion)' : 'var(--color-Web-Orange)'}}>
										{
											Boolean(item.recommanded) ? '해제' : '추천'
										}
									</button>
								</td>
							</tr>
						)
					})
				}
				</tbody>
			</table>
			<Pagination curPage={curPage} lastNum={list.last} onClick={setCurPage} />
		</div>
	)
}

export default AdminRecommendTask;