import React, { useContext } from "react";
import IntroChartContainer from "../intro/chart/IntroChartContainer";
import "./css/FindbizGraph.scss";
import TaskManageChartSlider from "../taskManage/TaskManageChartSlider";
import CommonContext from "../../store/Common";

function FindbizGraph() {
  const { isMobile } = useContext(CommonContext);

  return (
    <div id="FindbizGraph">
      <div className="findbizGraph_box-wrap pc">
        <div className="findbizGraph_box">
          <div className="findbizGraph_box_title">비즈내비 사업분석</div>
          <p className="findbizGraph_box_sub">
            비즈내비의 올라오는 사업 등 다양한 정보를 분석해 그래프로
            보여줍니다.
          </p>
        </div>
      </div>

      {isMobile ? <TaskManageChartSlider /> : <IntroChartContainer />}
    </div>
  );
}

export default FindbizGraph;
