import React from "react";
import "chartjs-plugin-datalabels";
import TaskMain from "../taskManage/TaskMain";
import IntroRedirectList from "../intro/organisms/IntroRedirectList";
import RenewalMainAfterLogin from "../intro/findbiz/RenewalMainAfterLogin";
import RenewalServiceMain from "../intro/findbiz/RenewalServiceMain";

function HomeTaskManage({ data }) {
  return (
    <div id="HomeTaskManage">
      <div className="pc">
        <RenewalServiceMain data={data} />
      </div>
    </div>
  );
}

export default HomeTaskManage;
