import React, { useState, useEffect, useContext } from "react";
import "./css/UserManage.scss";
import TableList from "./TableList";
import API from "../../util/api";
import AccountAPI from "../../API/account";
import MemberAPI from "../../API/member";
import memberContext from "../../store/Member";
import Menu from "./common/Menu";
import qs from "query-string";

function UserManage({ history, location }) {
  const memberInfo = useContext(memberContext);
  const [type_arr, setType_arr] = useState([]);
  const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
  const isAgent = window.$Global.checkAuth("대리인", memberInfo.auth);
  const [menuTab, setMenuTab] = useState(1);
  const { tab } = qs.parse(location.search);

  const isInventor = memberInfo.is_inventor == 1; // 발명자 보여줘야하는 경우

  // type은 테이블 고유 타입, thead 표 헤더, tbody 표 내용, style th 스타일
  // inputCommon [props_wait, setProps_wait] = useState(
  //     {
  //         type: 'wait',
  //         title : '가입 승인 대기목록',
  //         rank: -1,
  //         link: true,
  //         code: '',
  //         thead:  {
  //             idx: '순번',
  //             auth: '계정 권한',
  //             name: '이름',
  //             id: 'ID',
  //             email: '이메일',
  //             position: '직급'
  //         },
  //         tbody: [],
  //         style: [{width: '10%'}, {width: '15%'}, {width: '15%'}, {width: '20%'}, {width: '30%'}, {width: '10%'}]
  //     }
  // );

  const [props_manager, setProps_manager] = useState({
    type: "manage",
    title: "관리자",
    // rank: 1,
    rank: 0,
    thead: {
      idx: "순번",
      name: "이름",
      id: "ID",
      password: "PW",
      email: "이메일",
      // position: '직급'
    },
    tbody: [],
    style: [
      { width: "10%" },
      { width: "15%" },
      { width: "15%" },
      { width: "15%" },
      { width: "15%" },
      { width: "25%" },
    ],
  });

  const [props_inventor, setProps_inventor] = useState({
    type: "inventor",
    title: "발명자",
    // rank: 2,
    rank: 1,
    thead: {
      idx: "순번",
      name: "이름",
      id: "ID",
      password: "PW",
      email: "이메일",
      // position: '직급'
    },
    tbody: [],
    style: [
      { width: "10%" },
      { width: "15%" },
      { width: "15%" },
      { width: "15%" },
      { width: "15%" },
      { width: "25%" },
    ],
  });

  const [props_agent, setProps_agent] = useState({
    type: "agent",
    title: "특허사무소",
    rank: 3,
    addDisable: true,
    company_idx: null,
    thead: {
      idx: "순번",
      name: "특허사무소",
      email: "대표 이메일",
    },
    tbody: [],
    style: [{ width: "10%" }, { width: "30%" }, { width: "30%" }],
  });

  const [props_agent_manager, setProps_agent_manager] = useState({
    type: "agent_manage",
    title: "관리자",
    rank: 11,
    thead: {
      idx: "순번",
      name: "이름",
      id: "ID",
      password: "PW",
      email: "이메일",
      // position: '직급'
    },
    tbody: [],
    style: [
      { width: "10%" },
      { width: "15%" },
      { width: "15%" },
      { width: "15%" },
      { width: "10%" },
      { width: "25%" },
    ],
  });

  // inputCommon [props_agent_person, setProps_agent_person] = useState(
  //     {
  //         type: 'agent_person',
  //         title : '담당자',
  //         rank: 12,
  //         thead:  {
  //             idx: '순번',
  //             // name: '이름',
  //             id: 'ID',
  //             password: 'PW',
  //             email: '이메일',
  //             // position: '직급'
  //         },
  //         tbody: [],
  //         style: [{width: '10%'}, {width: '15%'}, {width: '15%'}, {width: '15%'}, {width: '10%'}, {width: '25%'}]
  //     }
  // );

  const [props_company, setProps_company] = useState({
    type: "company",
    title: "거래기업",
    rank: 9,
    addDisable: true,
    thead: {
      idx: "순번",
      name: "기업명",
      email: "대표 이메일",
    },
    tbody: [],
    style: [{ width: "10%" }, { width: "30%" }, { width: "50%" }],
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setMenuTab(tab || 1);
  }, [tab]);

  const init = async () => {
    if (isCompany) {
      await AccountAPI.getAccountCompany().then(async (res) => {
        let data = res.data;
        // rank >> 0 : 대표, 1 : 관리자, 2 : 연구원, 3: 대리인
        let ceo_arr = window.$Global.parseJsonArray(data.master);
        let manager_arr = window.$Global.parseJsonArray(data.manager);
        let inventor_arr = window.$Global.parseJsonArray(data.general);
        let agent_arr = data.agent.filter(async (item) => {
          props_agent.company_idx = item.company_idx;
          item.type = "account";
          item.password = "****";
          delete item.company_name;
          delete item.created_at;
          delete item.rank;
          return item;
        });
        // let result = await MemberAPI.getInviteList().then(res => {
        //     return res.data.items.filter((item) => {
        //         item.type = 'access';
        //         return item;
        //     });
        // });
        //
        // let code = await AccountAPI.getAccountInfo().then(res => res.data.code);

        // props_wait.code = code;
        // props_wait.tbody = result;
        // setProps_wait(props_wait);

        props_manager.tbody = manager_arr;
        setProps_manager({ ...props_manager });

        props_inventor.tbody = inventor_arr;
        setProps_inventor({ ...props_inventor });

        props_agent.tbody = agent_arr;
        setProps_agent({ ...props_agent });

        let arr = [];

        arr.push({
          type: "manage",
          props: props_manager,
          set_props: setProps_manager,
        });

        if (isInventor) {
          arr.push({
            type: "inventor",
            props: props_inventor,
            set_props: setProps_inventor,
          });
        }

        arr.push({
          type: "agent",
          props: props_agent,
          set_props: setProps_agent,
        });
        setType_arr(arr);
      });
    } else if (isAgent) {
      await AccountAPI.getAccountAgent().then(async (res) => {
        let data = res.data;
        // rank >> 0 : 대표, 1 : 관리자, 2 : 연구원
        let ceo_arr = window.$Global.parseJsonArray(data.master);
        let manager_arr = window.$Global.parseJsonArray(data.manager);
        let person_arr = window.$Global.parseJsonArray(data.in_charge);
        let company_arr = [];

        await AccountAPI.getCompanyToAgentList().then((res) => {
          company_arr = res.data.filter((item) => {
            item.type = "account";
            return item;
          });
        });

        // let result = await MemberAPI.getInviteList().then(res => {
        //     return res.data.items.filter((item) => {
        //         item.type = 'access';
        //         return item;
        //     });
        // });
        //
        // let code = await AccountAPI.getAccountAgent().then(res => res.data.code);

        // props_wait.code = code;
        // props_wait.tbody = result;
        // setProps_wait(props_wait);

        if (manager_arr) {
          props_agent_manager.tbody = manager_arr;
          setProps_agent_manager({ ...props_agent_manager });
        }

        // if (person_arr) {
        //     props_agent_person.tbody = person_arr;
        //     setProps_agent_person({...props_agent_person});
        // }

        if (company_arr) {
          props_company.tbody = company_arr;
          setProps_company({ ...props_company });
        }

        setType_arr([
          // {
          //     type: 'wait',
          //     props: props_wait,
          //     set_props: setProps_wait
          // },
          {
            type: "agent_manage",
            props: props_agent_manager,
            set_props: setProps_agent_manager,
          },
          // {
          //     type: 'agent_person',
          //     props: props_agent_person,
          //     set_props: setProps_agent_person
          // },
          {
            type: "company",
            props: props_company,
            set_props: setProps_company,
          },
        ]);
      });
    }
  };

  const requestInvite = (type, item, rank, position) => {
    let payload = {
      idx: item.idx,
      inventor: Number(rank),
      position: position,
      type: type,
    };

    if (position == "") {
      alert("직급을 입력해주세요");
      return;
    }

    if (window.confirm("요청을 처리하시겠습니까?")) {
      MemberAPI.requestInviteList(payload).then(() => {
        alert("처리되었습니다");
        history.go(0);
      });
    }
  };

  const accountAddAPI = (account, rank) => {
    let payload = {
      ...account,
      rank,
    };
    if (rank < 3) {
      payload = {
        ...payload,
        inventor: Boolean(rank),
      };
      return API.post("/manager/member", payload);
    } else if (rank > 9) {
      return API.post("/agent/member", payload);
    }
  };

  const companyCodeAddAPI = async (code) => {
    console.log(code);
    if (window.$Global.checkAuth("특허회사", memberInfo.auth)) {
      await API.post("/manager/company/connect", { code: code });
    } else if (window.$Global.checkAuth("대리인", memberInfo.auth)) {
      await API.post("/agent/company/connect", { code: code });
    }
    init();
  };

  const accountDeleteAPI = (idx, company_idx, rank) => {
    if (rank < 3) {
      return API.delete(`/manager/member/${idx}`);
    } else if (rank === 3) {
      return API.delete(`/manager/company/${company_idx}`);
    } else if (rank === 9) {
      return API.delete(`/agent/company/${idx}`);
    } else if (rank > 9 && 19 > rank) {
      return API.delete(`/agent/member/${idx}`);
    }
  };

  const accountUpdateAPI = (account, rank) => {
    let payload = {
      ...account,
      rank: rank,
    };
    if (memberInfo.auth < 10) {
      return API.patch(`/manager/member/${account.idx}`, payload);
    } else if (memberInfo.auth >= 10) {
      return API.patch(`/agent/member/${account.idx}`, payload);
    }
  };

  return (
    <div id="UserManage" className={`${menuTab == 1 ? "one" : "two"}`}>
      <Menu number={menuTab} />
      <div className="wrap_user_manage">
        {(!tab || tab == 1) && (
          <div className="area_box">
            <div className="area_box_text">
              <h2 className="area_box_text_title">관리자 계정 추가하기</h2>
              <p className="area_box_text_info">
                IPNOW를 함께 이용할 직원 아이디를 최대 3개 까지 생성해 IP관리 및
                다양한 기능을 쉽고 빠르게 이용하세요.
                <br />
                3개를 초과하여 계정 추가 등록이 필요할 경우 고객센터로 문의
                부탁드립니다.
              </p>
            </div>
          </div>
        )}
        <TableList
          type_arr={type_arr}
          accountAddAPI={accountAddAPI}
          accountDeleteAPI={accountDeleteAPI}
          accountUpdateAPI={accountUpdateAPI}
          companyCodeAddAPI={companyCodeAddAPI}
          requestInvite={requestInvite}
        />
      </div>
    </div>
  );
}

export default UserManage;
