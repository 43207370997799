import React, {useContext} from "react";
import {Link} from "react-router-dom";
import CommonContext from "../../store/Common";

function TradeItem({item, query}) {
    const {isMobile} = useContext(CommonContext);
    const able = Boolean(Number(item.proposal));
    const class_str = item.product_list.length > 0 ? (item.product_list[0] + ' 외 ' + (item.product_list.length - 1) + '건') : ' ';
    const link = `/trade/view/${item.idx}${query ? `?${query}` : ''}`;
    const img_src = window.$Global.getCDN(item.thumbnail_key);

    return (
        isMobile
        ?
            <Link to={link} className="item">
                <img src={img_src} className="bg_image" alt="이미지"/>
                <p className="category">{class_str}</p>
                <p className="name">{item.name}</p>
                <p className="price">{window.$Global.commaify(item.price) + '원'}</p>
            </Link>
        :
            <Link to={link} className="item">
                <img src={img_src} className="bg_image" alt="이미지"/>
                <p className="p_class">{class_str}</p>
                <p className="p_name">{item.name}</p>
                <div className="inner">
                    <p className={`proposal ${!able ? 'disable' : ''}`}>{able ? '가격제안 가능' : '가격 역제안 불가'}</p>
                    <p className="price">{window.$Global.numberToKorean(item.price)}원</p>
                </div>
            </Link>
    )
}

export default TradeItem;