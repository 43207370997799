const covertCategory = (category, category_idx1, category_idx2) => {
	let b_category = category.category_list?.find(item => item.idx == category_idx1)?.name;
	let s_category;

	category.category_list?.forEach(item => {
		let result = item.child.find(child => child.idx == category_idx2)?.name;
		if (result) s_category = result;
	});
	return {
		b_category: b_category || '',
		s_category: s_category || ''
	}
};

export default covertCategory;