import { cacheAdapterEnhancer } from "axios-extensions";
import config from "../const/config";
const axios = require("axios");

const instanceGroupware = axios.create({
  baseURL: config.gwUrl + "/bizoffi-api",
  timeout: 6 * 1000 * 10 * 10 * 2, // 20분
  headers: {
     "g-token": localStorage.getItem(config.gwKeyToken),
  },
  
});

export default instanceGroupware;
