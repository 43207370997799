import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransactionSaleList.scss";
import DocTradeMyTransactionSaleRow from './DocTradeMyTransactionSaleRow';
import TemplateAPI from "../../API/template";

function DocTradeMyTransactionSaleList() {
    const [curPage, setCurPage] = useState(1);
    const [resData, setResData] = useState({items: []});

    useEffect(() => {
        getList();
    }, []);

    const getList = (page) => {
        let payload = {
            page: page || curPage,
            count: 100,
            search_type: 3,
        };
        TemplateAPI.getTemplateList(payload).then(res => setResData(res.data));
    };

    return (
        <div id="docTradeMyTransactionSaleList">
            <div className="mytransaction_section-sale">
                <div className="mytransaction_section">
                    <h2 className="mytransaction_section_title">나의 판매 확인하기</h2>
                    <table className="mytransaction_section_table">
                        <colgroup>
                            <col width="40px" />
                            <col width="393px" />
                            <col width="80px" />
                            <col width="80px" />
                            <col width="80px" />
                            <col width="130px" />
                            <col width="77px" />
                        </colgroup>
                        <thead className="mytransaction_section_table_thead">
                            <tr>
                                <th className="num-title">순 번</th>
                                <th className="transaction-title">제목</th>
                                <th className="date-title">날&nbsp;&nbsp;짜</th>
                                <th className="class-title">자료 구분</th>
                                <th className="number-title">구매횟수</th>
                                <th className="price-title">판매가격<em className="style">&#40;VAT별도&#41;</em></th>
                                <th className="detail-title">자세히 보기</th>
                            </tr>
                        </thead>
                        <tbody className="section_table_tbody">
                            <DocTradeMyTransactionSaleRow data={resData}/>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DocTradeMyTransactionSaleList;