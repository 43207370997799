import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './css/HomeAgent.scss';
import 'chartjs-plugin-datalabels';
import API from '../../util/api';
import memberContext from "../../store/Member";
import PieGraph from "./common/PieGraph";
import UnEventContext from "../../store/UnEvent";

function HomeAgent({data, data2, designData, options, patent_all_cnt, trademark_all_cnt, design_all_cnt, isEmptyPatentData, isEmptyTrademarkData, isEmptyDesignData}) {
    const member = useContext(memberContext);
    const unEvent = useContext(UnEventContext);
    const [companyInfo, setCompanyInfo] = useState({});

    useEffect(() => {
        API.get(`/agent/company/${member.manage_company_idx}`).then(res => setCompanyInfo(res.data));
    }, []);

    return (
        <>
            <div className="pc" style={{marginTop: 15}}>
                <div className="area_box">
                    <div className="title">
                        <h2>실시간 IP 현황</h2>
                        <h2>기업정보</h2>
                    </div>
                    <div className="wrap_tab">
                        <PieGraph className="tab" title={"특 허"} to="/patent/list" data={data} options={options} cnt={patent_all_cnt} isEmptyData={isEmptyPatentData}/>
                        <PieGraph title={["상 표", "디 자 인"]} to={["/tradeMark/list", "/design/list"]} data={[data2, designData]} options={options} cnt={[trademark_all_cnt, design_all_cnt]} isEmptyData={[isEmptyTrademarkData, isEmptyDesignData]} dual={true}/>
                        <div className="tab_info agent_manage_company">
                            <h2 className="title">{companyInfo.name}</h2>
                            <div className="wrap_row">
                                <div className="row">
                                    <h2 className="title">대표자</h2>
                                    <p className="content">{companyInfo.representative}</p>
                                </div>
                                <div className="row">
                                    <h2 className="title">담당자</h2>
                                    <p className="content">{companyInfo.manager}</p>
                                </div>
                                <div className="row">
                                    <h2 className="title">
                                        담당자<br/>이메일
                                    </h2>
                                    <p className="content">{companyInfo.manager_email}</p>
                                </div>
                            </div>
                            <a href="/choice" className="btn" onClick={() => localStorage.removeItem('manage_company_idx')}>다른 기업으로 돌아가기</a>
                        </div>
                    </div>
                    <div className="wrap_tab">
                        <div className="tab_date_manage">
                            <Link to="/deadline/list">
                                <i className="icon_hourglass_small"/>
                                <p>IP기한관리</p>
                                <button className="icon_next_black"/>
                            </Link>
                        </div>
                        <div className="tab_invention">
                            <Link to="/cost/list?nation=1">
                                <i className="icon_combined_shape"/>
                                <p>비용 관리</p>
                                <button className="icon_next_black"/>
                            </Link>
                        </div>
                        <div className="tab_un_event agent tab_link">
                            <Link to="/unEvent">
                                <h2>알림</h2>
                                <p>
                                    <span>{unEvent.unConfirmEventCnt}</span>건
                                </p>
                                <button className="icon_next"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mo">
                <div className="alert">
                    <i className="icon_alert_circle"/>
                    <p>PC로 이용해주시기 바랍니다.</p>
                </div>
            </div>
        </>
    )
}

export default HomeAgent;
