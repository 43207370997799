import React, {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import "./css/InventionDetail.scss";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import API from "../../util/api";
import InventionAPI from "../../API/invention";
import AccountAPI from "../../API/account";
import PatentAPI from "../../API/patent";
import InputRow from "./row/InputRow";
import FileRow from "./row/FileRow";
import DynamicSelectRow from "./row/DynamicSelectRow";
import PopupPatentSelect from "./PopupPatentSelect";
import DynamicInputRow from "./row/DynamicInputRow";
import MemberContext from "../../store/Member";
import _ from "lodash";
import {nationType} from "../../types/nationType";

function InventionDetail({history}) {
    let inventorTag, applicantorsTag, inventionPublishTag;
    const {invention_idx} = useParams();
    const {company_idx, company} = useContext(MemberContext);
    const inventionType = (invention_idx !== 'new'); // true 수정, false 등록
    const INVENTOR = '발명자';
    const APPLICANTORS = '공동출원인';
    const INVENTIONPUBLISH = '발명의 공개';
    const INVENTION_UPLOAD_FILE_VIEW_LIST = 'inventionUploadIFileViewList';
    const DESCRIPTION_UPLOAD_FILE_VIEW_LIST = 'descriptionUploadIFileViewList';
    const RELATIVE_DATA = 'relativeData';

    const isNew = invention_idx == "new";
    const [nation, setNation] = useState(0);
    const [type, setType] = useState(0);
    const [inventorSelect, setInventorSelect] = useState([]);
    const [inventionStateMenu, setInventionStateMenu] = useState(false);
    const [inventionState, setInventionState] = useState(0);
    const [downPopupShow, setDownPopupShow] = useState(false);
    const [upPopupShow, setUpPopupShow] = useState(false);
    const [popupPatentSelectShow, setPopupPatentSelectShow] = useState(false);
    const [editing, setEditing] = useState(false);
    const [fileViewList, setFileViewList] = useState([]); // 다운로드 팝업에 보여줄 저장된 파일 리스트
    const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
    const [inventionUploadIFileViewList, setInventionUploadFileViewList] = useState([]); // 직무 발명 신고서 업로드 파일 리스트
    const [descriptionUploadIFileViewList, setDescriptionInventionUploadFileViewList] = useState([]); // 발명 설명서 업로드 파일 리스트
    const [inventionName, setInventionName] = useState(null);
    const [inventors, setInventors] = useState([]); // 발명자 목록
    const [applicantors, setApplicantors] = useState([]); // 공동 출원인
    const [applicantorsHistory, setApplicantorsHistory] = useState([]);

    const [inventionPublish, setInventionPublish] = useState([]); // 발명의 공개
    const [inventionPublishHistory, setInventionPublishHistory] = useState([]);
    const [firstApplyPatent, setFirstApplyPatent] = useState(null); // 선출원 특허 선택

    const [responseData, setResponseData] = useState({});
    const [historyData, setHistoryData] = useState({});
    const [autoCompleteInventionPublish, setAutoCompleteInventionPublish] = useState([]);
    const [paymentManagerSelectTag, setPaymentManagerSelectTag] = useState([]);
    const [paymentManageCompany, setPaymentManageCompany] = useState({}); // 연차료 관리 회사
    const [patentManageCompany, setPatentManageCompany] = useState({}); // 특허 관리 회사
    const [fileType, setFileType] = useState('');
    const [timer, setTimer] = useState(0); // 디바운싱 타이머

    useEffect(() => {
        // setInventorSelect(res.data.researcher || []);
        AccountAPI.getAccountCompany().then(res => {
            let data = res.data;
            setInventorSelect(data.manager.concat(data.general) || []);
        });
        drawInventionData();

        if(!inventionType) {
            setEditing(true);
        }
    }, []);

    useEffect(() => {
        setPaymentManagerSelectTag([{auto_keyword: false, idx: company_idx, name: company}].concat(applicantors.map(item => item)));
    }, [applicantors]);

    useEffect(() => {
        initData(responseData);
    }, [responseData]);

    const initData = (data) => {
        if (window.$Global.isEmptyObject(responseData)) return;
        if (data.members.length) {
            setInventors(data.members);
        }

        for (let i = 0; i < data.open_list.length; i++) {
            InventionAPI.getInventionOpenFileList(invention_idx, data.open_list[i].idx).then(res => {
                data.open_list[i].fileList = res.data;
            });
        }
        setInventionName(data.title);
        setApplicantors(data.joint_apply);
        setApplicantorsHistory(data.joint_apply);
        setInventionPublish(data.open_list);
        setInventionPublishHistory(data.open_list);
        setInventionState(data.state);
        setFirstApplyPatent({idx: data.patent_idx, apply_number: data.apply_number});
        setNation(data.nation);
        setType(data.type);
        setPaymentManageCompany({idx: data.payment_manage_company_idx, name: data.payment_manage_company});
        setPatentManageCompany({idx: data.manage_company_idx, name: data.manage_company});
    };

    const resetData = () => {
        setInventionName('');
        setInventors([{idx: '', name: '', share: '100'}]);
        setApplicantors([]);
        setInventionPublish([]);
    };

    const getJointCompany = (keyword) => {
        if (keyword.length) {
            InventionAPI.getJointCompany(keyword).then(res => setAutoCompleteInventionPublish(res.data));
        } else {
            setAutoCompleteInventionPublish([])
        }
    };

    const drawInventionData = () => {
        if (invention_idx === 'new') return;
        InventionAPI.getInventionInfo(invention_idx).then(res => {
            setResponseData(res.data);
            setHistoryData(_.cloneDeep(res.data));
        });
    };

    const saveToast = () => {
        if (inventionType) {
            window.$Global.saveToast();
        } else {
            window.$Global.registerToast();
        }
    };

    const onClickChangeInventionState = (state) => {
        setInventionState(state);
        setInventionStateMenu(false);
    };

    const onClickSave = async () => {
        if (!inventionName) {
            alert('발명의 명칭을 입력해주세요');
            return;
        }

        let open_list = inventionPublish.filter(item => item.idx != "");
        setInventionPublish(open_list);

        // 수정
        if (inventionType) {
            let payload = {
                title: inventionName,
                state: inventionState,
                members: inventors,
                joint_apply: applicantors,
                open_list: open_list,
                nation: nation,
                type: type,
                patent_idx: firstApplyPatent ? firstApplyPatent.idx : '',
                payment_manage_company: paymentManageCompany.name || company,
                payment_manage_company_idx: paymentManageCompany.idx || applicantors.length === 0 ? company_idx : '',
                manage_company: patentManageCompany.name || company,
                manage_company_idx: patentManageCompany.idx || applicantors.length === 0 ? company_idx : ''
            };

            await API.patch(`/manager/invention/${invention_idx}`, payload).then(() => {
                saveToast();
                setEditing(false);
            });
        // 등록
        } else {
            let formData = new FormData();
            formData.append("title", inventionName);
            formData.append("state", inventionState);
            if (inventors.filter(item => item.name).length) {
                formData.append("members", JSON.stringify(inventors));
            }
            formData.append("joint_apply", JSON.stringify(applicantors));
            formData.append("open_list", JSON.stringify(inventionPublish));
            formData.append("nation", nation);
            formData.append("type", type);
            formData.append("patent_idx", firstApplyPatent ? firstApplyPatent.idx : '');
            formData.append("payment_manage_company", paymentManageCompany.name || company);
            formData.append("payment_manage_company_idx", paymentManageCompany.idx || applicantors.length === 0 ? company_idx : '');
            formData.append("manage_company", patentManageCompany.name || company);
            formData.append("manage_company_idx", patentManageCompany.idx || applicantors.length === 0 ? company_idx : '');

            for (let i = 0; i < inventionUploadIFileViewList.length; i++) {
                formData.append("file", inventionUploadIFileViewList[i].file);
            }

            for (let i = 0; i < descriptionUploadIFileViewList.length; i++) {
                formData.append("description", descriptionUploadIFileViewList[i].file)
            }

            for (let i = 0; i < inventionPublish.length; i++) {
                for (let j = 0; j < inventionPublish[i].fileList.length; j++) {
                    formData.append("open" + i, inventionPublish[i].fileList[j].file);
                }
            }

            await API.post(`/manager/invention`, formData).then(res => {
                alert('등록되었습니다');
                history.replace(`/invention/${res.data.invention_idx}`);
            });
        }
    };

    const onClickCancel = () => {
        if (invention_idx === 'new') {
            resetData();
        }

        setResponseData(historyData);
        setApplicantors(applicantorsHistory);
        setInventionPublish(inventionPublishHistory);
        setEditing(false);
        setInventionStateMenu(false);
    };

    const onClickInventionStateMenu = () => {
        setInventionStateMenu(!inventionStateMenu);
    };

    const onClickDownload = (idx) => {
        if (fileType.type === INVENTION_UPLOAD_FILE_VIEW_LIST || fileType.type === DESCRIPTION_UPLOAD_FILE_VIEW_LIST) {
            InventionAPI.downloadInventionFile(invention_idx, idx);
        } else {
            InventionAPI.downloadInventionOpenFile(invention_idx, fileType.open_idx, idx);
        }
    };

    const onClickDeleteFileInDownload = async (idx) => {
        if (fileType.type === INVENTION_UPLOAD_FILE_VIEW_LIST || fileType.type === DESCRIPTION_UPLOAD_FILE_VIEW_LIST) {
            await InventionAPI.deleteInventionFile(invention_idx, idx);
        } else {
            await InventionAPI.deleteInventionOpenFile(invention_idx, fileType.open_idx, idx);
        }
        alert('삭제되었습니다');
        drawInventionData();
        let find_index = fileViewList.findIndex(item => item.idx === idx);
        fileViewList.splice(find_index, 1);
        setFileViewList(fileViewList.concat([]));
    };

    const onClickDeleteFileInUpload = (idx) => {
        let copyArr = uploadFileViewList.slice(0);
        copyArr.splice(idx, 1);
        setUploadFileViewList(copyArr);

        switch (fileType.type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setInventionUploadFileViewList(copyArr);
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setDescriptionInventionUploadFileViewList(copyArr);
                break;
            case RELATIVE_DATA:
                inventionPublish[fileType.idx].fileList = copyArr;
                setInventionPublish(inventionPublish);
                break;
        }
    };

    const onClickInsertInvention = () => {
        switch (fileType.type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setInventionUploadFileViewList(uploadFileViewList);
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setDescriptionInventionUploadFileViewList(uploadFileViewList);
                break;
            case RELATIVE_DATA:
                inventionPublish[fileType.idx].fileList = uploadFileViewList;
                setInventionPublish(inventionPublish);
                break;
        }
        setUpPopupShow(false);
    };

    const onClickUpdateInvention = async () => {
        let formData = new FormData();
        for (let i = 0; i < uploadFileViewList.length; i++) {
            formData.append('file', uploadFileViewList[i].file);
        }
        switch (fileType.type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                formData.append("description", "0");
                await InventionAPI.uploadInventionFile(invention_idx, formData);
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                formData.append("description", "1");
                await InventionAPI.uploadInventionFile(invention_idx, formData);
                break;
            case RELATIVE_DATA:
                await InventionAPI.uploadInventionOpenFile(invention_idx, fileType.open_idx, formData);
                break;
        }
        alert("업로드되었습니다");
        setUploadFileViewList([]);
        setUpPopupShow(false);
        drawInventionData();
    };

    const onClickCategoryAdd = (type) => {
        switch (type) {
            case INVENTOR:
                setInventors(inventors.concat({idx: '', name: '', share: 0}));
                break;
            case APPLICANTORS:
                setApplicantors(applicantors.concat([{name:'', idx: "", auto_keyword: false}]));
                break;
            case INVENTIONPUBLISH:
                setInventionPublish(inventionPublish.concat({idx: '', open_at: 0, reason: '', created_at: new Date().getTime(), fileList: []}));
                break;
        }
    };

    const onClickCategoryDelete = (type, idx) => {
        switch (type) {
            case INVENTOR:
                setInventors(inventors.filter((value, index) => index !== idx));
                break;
            case APPLICANTORS:
                setApplicantors(applicantors.filter((value, index) => index !== idx));
                break;
            case INVENTIONPUBLISH:
                setInventionPublish(inventionPublish.filter((value, index) => index !== idx));
                break;
        }
    };

    const onChangeCategoryUpdate = async (type, idx, key, value) => {
        switch (type) {
            case INVENTOR:
                inventors[idx][key] = value;
                setInventors(inventors.concat([]));
                break;
            case APPLICANTORS:
                if (key === "idx") {
                    applicantors[idx].name = value.name;
                    applicantors[idx].idx = value.name;
                } else {
                    applicantors[idx][key] = value;
                }
                setApplicantors(applicantors.concat([]));
                if (timer) {
                    clearTimeout(timer);
                }
                const newTimer = setTimeout(async () => {
                    await getJointCompany(value);
                }, 250);
                setTimer(newTimer);
                break;
            case INVENTIONPUBLISH:
                inventionPublish[idx][key] = value;
                setInventionPublish(inventionPublish.concat([]));
                break;
        }
    };

    const onChangeType = (e) => {
        if (e.target.value === "1") {
            setPopupPatentSelectShow(true);
        }
        setType(e.target.value);
    };

    const onChangeInventorUpdate = (idx, value) => {
        inventors[idx].idx = value.target.value;
        inventors[idx].name = value.target.options[value.target.selectedIndex].text;
        setInventors(inventors.concat([]));
    };

    const onClickUploadPopShow = (type, idx, open_idx) => {
        setFileType({type: type, idx: idx, open_idx: open_idx});

        switch (type) {
            case INVENTION_UPLOAD_FILE_VIEW_LIST:
                setUploadFileViewList(inventionUploadIFileViewList);
                break;
            case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                setUploadFileViewList(descriptionUploadIFileViewList);
                break;
            case RELATIVE_DATA:
                if (invention_idx === 'new') {
                    setUploadFileViewList(inventionPublish[idx].fileList);
                } else {
                    setUploadFileViewList([]);
                }
                break;
            default:
                break;
        }
        setUpPopupShow(true);
        setDownPopupShow(false);
    };

    const onClickDownloadPopShow = (type, idx, open_idx) => {
        setFileType({type: type, idx: idx, open_idx: open_idx});

        if (inventionType) {
            switch (type) {
                case INVENTION_UPLOAD_FILE_VIEW_LIST:
                    InventionAPI.getInventionFileList(invention_idx, 0).then(res => setFileViewList(res.data));
                    break;
                case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
                    InventionAPI.getInventionFileList(invention_idx, 1).then(res => setFileViewList(res.data));
                    break;
                case RELATIVE_DATA:
                    InventionAPI.getInventionOpenFileList(invention_idx, open_idx).then(res => setFileViewList(res.data));
                    break;
                default:
                    break;
            }
        }
        setUpPopupShow(false);
        setDownPopupShow(true);
    };

    const onClickPatentListAdd = async () => {
        let payload = {};

        if (type == 0) {
             payload = {
                invention_idx: invention_idx,
                nation: nation,
                type: type,
                patent_list: [],
                invention_name: inventionName,
            };
            // 원출원
            await PatentAPI.register(payload);

        } else if (type == 1) {
            payload = {
                nation: nation,
                type: type,
            };
            // 우선권
            await PatentAPI.familyRegister(firstApplyPatent.idx, payload);
        }
        alert("특허리스트에 추가되었습니다");
        history.push('/patent/list');
    };

    const autoKeywordShow = (idx, bool) => {
        applicantors.forEach(item => item.auto_keyword = false);
        applicantors[idx].auto_keyword = bool;
        setApplicantors(applicantors.concat([]));
    };

    const onClickFirstApplySuccess = (selected_item) => {
        setFirstApplyPatent(selected_item);
        setPopupPatentSelectShow(false);
    };

    inventorTag = inventors.map((item, index) => {
        return (
            <div key={index} className={`rows ${editing ? 'right' : ''}`}>
                <DynamicSelectRow title={`${INVENTOR} ${index + 1}`}
                                  titleClass="between"
                                  content={item.name}
                                  defaultValue={item.idx}
                                  editing={editing}
                                  onClickCategoryDelete={() => onClickCategoryDelete(INVENTOR, index)}
                                  options={inventorSelect} onChange={(e) => onChangeInventorUpdate(index, e)}/>
                <InputRow title="지분율" titleClass="between" content={`${item.share}%`} defaultValue={item.share} inputType="number" editing={editing} onChange={(e) => onChangeCategoryUpdate(INVENTOR, index, 'share', e.target.value)}/>
            </div>
        );
    });

    applicantorsTag = applicantors.map((item, index) => {
       return (
           <div key={index} className={`rows ${editing && index !== 0 ? 'center' : ''}`}>
               <div className="row between" style={editing && index === 0 ? {marginLeft: "15px"} : null}>
                   <h2 className="title">
                       {APPLICANTORS + " " + (index + 1)}
                       {editing && <button className="icon_badge_del" onClick={() => onClickCategoryDelete(APPLICANTORS, index)}/>}
                   </h2>
                   {
                       editing
                           ?
                           <div>
                               <input type="text" value={item.name} onChange={(e) => onChangeCategoryUpdate(APPLICANTORS, index, 'name', e.target.value)} onFocus={() => autoKeywordShow(index, true)} onBlur={() => autoKeywordShow(index, false)}/>
                               {

                                   applicantors[index].auto_keyword
                                   ?
                                       <ul>
                                           {
                                               autoCompleteInventionPublish.map((item) => {
                                                   return (
                                                       <li onMouseDown={() => onChangeCategoryUpdate(APPLICANTORS, index, 'idx', {idx: item.idx, name: item.name})}>
                                                           <button>{item.name}</button>
                                                       </li>
                                                   );
                                               })
                                           }
                                       </ul>
                                   : null
                               }
                           </div>
                           : <p>{item.name}</p>
                   }
               </div>
           </div>
       );
    });

    inventionPublishTag = inventionPublish.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <DynamicInputRow title="사유" titleClass="between" content={item.reason} editing={editing}
                                 onChange={(e) => onChangeCategoryUpdate(INVENTIONPUBLISH, index, 'reason', e.target.value)}
                                 onClickCategoryDelete={() => onClickCategoryDelete(INVENTIONPUBLISH, index)} />
                <FileRow title="관련자료" titleClass="between" onClick1={() => ((invention_idx === 'new') || item.idx) ? onClickDownloadPopShow(RELATIVE_DATA, index, item.idx) : alert('저장 후 업로드 가능합니다')} onClick2={() => ((invention_idx === 'new') || item.idx) ? onClickUploadPopShow(RELATIVE_DATA, index, item.idx) : alert('저장 후 업로드 가능합니다')}/>
            </React.Fragment>
        );
    });

    return (
        <div id="InventionDetail" className={`${isNew ? 'isNew' : ''}`}>
            <div className="header">
                <h2 className="title">발명신고서 {(invention_idx === 'new') ? '등록' : '상세'}페이지</h2>
                <div className="info">
                    <button className="state" onClick={editing ? onClickInventionStateMenu : undefined}>
                        {window.$Global.convertInventionStateToStr(inventionState)}
                        { (inventionType && editing) && <i className="icon_more"/> }
                    </button>
                    {
                        (inventionType && inventionStateMenu)
                        &&
                        <ul>
                            <li>
                                <button onClick={() => onClickChangeInventionState(0)}>발명신고서 접수</button>
                                <button onClick={() => onClickChangeInventionState(1)}>사건 등록</button>
                                <button onClick={() => onClickChangeInventionState(2)}>종료</button>
                                <button onClick={() => onClickChangeInventionState(3)}>폐기</button>
                            </li>
                        </ul>
                    }
                </div>
                <div className="btns">
                    {
                        invention_idx === 'new'
                        ? <button className="btn_save" onClick={onClickSave}>등록</button>
                        : editing
                        ?
                        <>
                            <button className="btn_save" onClick={onClickSave}>저장</button>
                            <button className="btn_cancle" onClick={onClickCancel}>취소</button>
                        </>
                        : <button className="btn_edit" onClick={() => setEditing(true)}>수정</button>
                    }
                    {
                        (!editing && inventionType) && <button className="btn_add" onClick={onClickPatentListAdd}>출원 승인하기</button>
                    }
                </div>
            </div>
            <div className="form">
                <div className="field">
                    <div className="rows">
                        <InputRow title="발명의 명칭" titleClass="colspan2 between" content={inventionName} defaultValue={inventionName} inputClass="long" editing={editing} onChange={(e) => setInventionName(e.target.value)}/>
                        <FileRow title="직무 발명 신고서"
                                 onClick1={() => onClickDownloadPopShow(INVENTION_UPLOAD_FILE_VIEW_LIST)}
                                 onClick2={() => onClickUploadPopShow(INVENTION_UPLOAD_FILE_VIEW_LIST)}
                                 haveFile={responseData.invention_file_cnt}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="rows">
                        <div className="row no_data"/>
                        <div className="row no_data"/>
                        <FileRow title="발명 설명서"
                                 onClick1={() => onClickDownloadPopShow(DESCRIPTION_UPLOAD_FILE_VIEW_LIST)}
                                 onClick2={() => onClickUploadPopShow(DESCRIPTION_UPLOAD_FILE_VIEW_LIST)}
                                 haveFile={responseData.description_file_cnt}
                        />
                    </div>
                    <div className="rows">
                        <div className="row between">
                            <h2 className="title">국가</h2>
                            {
                                editing
                                    ?
                                    <select onChange={(e) => setNation(e.target.value)} defaultValue={nation || 0}>
                                        { nationType.map((item, idx) => <option key={idx} value={idx}>{item}</option>) }
                                    </select>
                                    : <p>{window.$Global.convertNationCodeToStr(nation)}</p>
                            }
                        </div>
                        <div className="row between">
                            <h2 className="title">유형</h2>
                            {
                                editing
                                    ?
                                    <select value={type} onChange={onChangeType}>
                                        <option value={0}>원출원</option>
                                        <option value={1}>우선권 주장</option>
                                    </select>
                                    : <p>{type === 0 ? "원출원" : type === 1 ? "우선권 주장" : 'N/A'}</p>
                            }
                        </div>
                        {
                            type == "1" &&
                            <div className="row between">
                                <h2 className="title">선출원 출원번호</h2>
                                <p>{firstApplyPatent ? firstApplyPatent.apply_number || 'N/A' : ''}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="field" style={{marginTop: 60}}>
                    <div className="rows" style={{float: "left", overflow: "hidden"}}>
                        {
                            editing &&
                            <div className="row between">
                                <h2 className="title color_blue">발명자 추가</h2>
                                <button className="icon_blue_add" onClick={() => onClickCategoryAdd(INVENTOR)}/>
                            </div>
                        }
                    </div>
                    {inventorTag}
                </div>
                <div className="field" style={{marginTop: 150}}>
                    {
                        (editing || applicantorsTag.length !== 0 || inventionPublishTag.length !== 0) && <h2 style={{fontSize: 22, marginBottom: 20}}>부가입력</h2>
                    }
                    <div className="rows" style={{float: "left", overflow: "hidden"}}>
                        {
                            editing &&
                            <div className="row between">
                                <h2 className="title">공동출원인</h2>
                                <button className="icon_blue_add" onClick={() => onClickCategoryAdd(APPLICANTORS)}/>
                            </div>
                        }
                    </div>
                    {applicantorsTag}
                </div>
                {
                    applicantorsTag.length !== 0 &&
                    <div className="field">
                        <div className="rows">
                            <DynamicSelectRow title="연차료 관리 회사" titleClass="between" editing={editing}
                                              deleteBtnHide={true} defaultValue={paymentManageCompany.idx}
                                              content={paymentManageCompany.name}
                                              options={paymentManagerSelectTag}
                                              onChange={(e) => setPaymentManageCompany({
                                                  idx: e.target.value,
                                                  name: e.target.options[e.target.selectedIndex].text
                                              })}/>
                            <DynamicSelectRow title="특허 관리 회사" titleClass="between" editing={editing}
                                              deleteBtnHide={true} defaultValue={patentManageCompany.idx}
                                              content={patentManageCompany.name}
                                              options={paymentManagerSelectTag}
                                              onChange={(e) => setPatentManageCompany({
                                                  idx: e.target.value,
                                                  name: e.target.options[e.target.selectedIndex].text
                                              })}/>
                        </div>
                    </div>
                }
                <div className="field" style={{overflow: "hidden", clear: "both"}}>
                    <div className="rows" style={{float: "left", overflow: "hidden"}}>
                        {
                            editing &&
                            <div className="row between">
                                <h2 className="title">출원 전 발명 공개</h2>
                                <button className="icon_blue_add" onClick={() => onClickCategoryAdd(INVENTIONPUBLISH)}/>
                            </div>
                        }
                    </div>
                    <div className={`rows ${editing ? 'right' : ''}`}>
                        {inventionPublishTag}
                    </div>
                </div>
            </div>
            <DetailPopupDownload show={downPopupShow} setShow={setDownPopupShow} fileViewList={fileViewList} setFileList={setFileViewList} onClickDownload={onClickDownload} onClickDelete={onClickDeleteFileInDownload}/>
            <DetailPopupUpload show={upPopupShow} setShow={setUpPopupShow} uploadFileViewList={uploadFileViewList} setFileList={setUploadFileViewList} onClickUpload={(invention_idx === 'new') ? onClickInsertInvention : onClickUpdateInvention} onClickDelete={onClickDeleteFileInUpload}/>
            {popupPatentSelectShow && <PopupPatentSelect onClickSuccess={onClickFirstApplySuccess} onClickClose={() => { if (type === "1" && firstApplyPatent === null) { setType(0); } setPopupPatentSelectShow(false)}}/>}
        </div>
    );
}

export default InventionDetail;
