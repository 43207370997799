import "./css/InventorList.scss";
import { useHistory } from "react-router-dom";
import Pagination from "../common/Pagination";

const InventorList = () => {
  const history = useHistory();

  return (
    <div id="InventorList">
      <div className="header">
        <h2>"{localStorage.getItem("company_name")}"의 전체 발명자 리스트</h2>
        <button onClick={() => history.goBack()}>목록으로 돌아가기</button>
      </div>
      <div className="inventor_search_wrap">
        <input type="text" placeholder="찾고싶은 발명자의 이름을 입력하세요." />
        <i className="icon_search_gray" />
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="80px" />
            <col width="200px" />
            <col width="250px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>특허건수</th>
              <th>확인</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={() => history.push("/inventor/patent/list")}>
              <td>1</td>
              <td>김고은</td>
              <td>01012345678</td>
              <td>a1231@gooditl.com</td>
              <td>
                (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동,
                창덕빌딩)
              </td>
              <td>12건</td>
              <td>
                <input type="checkbox" />
              </td>
            </tr>
            <tr onClick={() => history.push("/inventor/patent/list")}>
              <td>1</td>
              <td>김고은</td>
              <td>01012345678</td>
              <td>a1231@gooditl.com</td>
              <td>
                (우 06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동,
                창덕빌딩)
              </td>
              <td>12건</td>
              <td>
                <input type="checkbox" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
};

export default InventorList;
