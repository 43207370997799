import React, {useEffect, useState} from "react";
import "./css/CyberCompanyAdded.scss";
import CyberAPI from "../../API/cyber";

function CyberCompanyAdded() {
    const count = 1000;

    // type
    // 0: 등록한 기업
    // 1: 가입한 기업
    // 2: 우선권 소진 기업
    const [type, setType] = useState(0);
    const [addedData, setAddedData] = useState({items: []});
    const [waitData, setWaitData] = useState({items: []});

    const isAdded = type == 0;
    const isWait = type == 1;

    useEffect(() => {
        let payload = {
            page: 1,
            count: count,
            type: 1
        };
        CyberAPI.getCompanyRegister(payload).then(res => setAddedData(res.data));

        payload = {
            page: 1,
            count: count,
            type: 0
        };
        CyberAPI.getCompanyRegister(payload).then(res => setWaitData(res.data));
    }, []);


    return (
        <div id="CyberCompanyAdded">
            <h2 className="page_title">등록 된 기업</h2>
            <p className="count">{type ? waitData.total_count : addedData.total_count}개</p>

            <ul className="tabs">
                <li className={isAdded ? 'active' : ''} onClick={() => setType(0)}>회원가입 한 기업</li>
                <li className={isWait ? 'active' : ''} onClick={() => setType(1)}>회원 대기중인 기업</li>
            </ul>
            {
                isAdded
                &&
                <div className="wrap_list">
                    <ul>
                        {
                            addedData.items.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <div className="info">
                                            {
                                                item.ipnow_permission == 1 && <p className="member">유료전환</p>
                                            }
                                            <p>{item.name}</p>
                                        </div>
                                        <p>가입일 {window.$Global.convertDate(item.sign_up_at)}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            {
                isWait
                &&
                <div className="wrap_table">
                    <table>
                        <thead>
                            <tr>
                                <th>기업이름</th>
                                <th>유효기간</th>
                                <th>재 선택권</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            waitData.items.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{item.name}</td>
                                        <td>{Math.floor(item.expiration_dday)}일</td>
                                        <td>{item.remaining_cnt}회</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
};

export default CyberCompanyAdded;