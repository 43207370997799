import React, {useContext} from "react";
import "./NotFoundPage.scss";
import memberContext from "../../store/Member";

function NotFoundPage() {
    const {auth} = useContext(memberContext);

    const redirect = () => {
        window.$Global.redirectHome(auth);
    };

    return (
        <div id="NotFoundPage">
            <img src={require('../../assets/images/common/icon_warning.svg').default} alt="이미지"/>
            <h2>페이지를 찾을 수 없습니다.</h2>
            <p>
                <span>페이지의 URL이 변경되었거나 일시적으로 사용할 수 없습니다.</span>
                주소가 정확하다면 브라우저의 새로고침 버튼을 눌러 확인하거나, 다시 잠시후에  접속을 시도해 주십시오.
            </p>
            <button onClick={redirect}>메인으로</button>
        </div>
    )
}

export default NotFoundPage;
