import React from "react";
import InputId from "../inputCommon/InputId";
import InputEmail from "../inputCommon/InputEmail";
import InputPhone from "../inputCommon/InputPhone";

function SignupInputAgent({keywordList,
                           commonPwdTag,
                           isInvestor, editAble, checkId, checkEmail, checkPhone,
                           signInfo, setSignInfo,
                           onChangeFindCompany,
                           onClickCheckId, onClickCheckEmail, onClickCertification}) {
    return (
        <>
            <div className="field" style={{marginTop: 82}}>
                <div className="f_head">
                    <i className="icon_red_dot"/>
                    특허사무소 명칭
                </div>
                <div className="f_content max">
                    <input type="text"
                           value={signInfo.company}
                           placeholder="검색하세요"
                           onChange={(e) => onChangeFindCompany(e.target.value, "company")}
                           autoFocus
                    />
                    {keywordList}
                </div>
            </div>
            <div className="wrap_field">
                <div className={`field ${(isInvestor || editAble) ? '' : 'action_no'}`}>
                    <div className="f_head">
                        <i className="icon_red_dot"/>
                        사업자 등록 번호
                    </div>
                    <div className="f_content">
                        <input type="text" value={signInfo.licence_number} onChange={(e) => setSignInfo({...signInfo, licence_number: e.target.value})}/>
                    </div>
                </div>
                <div className={`field ${(isInvestor || editAble) ? '' : 'action_no'}`}>
                    <div className="f_head">
                        <i className="icon_red_dot"/>
                        소재지
                    </div>
                    <div className="f_content">
                        <input type="text" value={signInfo.location} onChange={(e) => setSignInfo({...signInfo, location: e.target.value})}/>
                    </div>
                </div>
            </div>
            <div className="box">
                <div className="wrap_field">
                    <InputId able={(isInvestor || editAble)}
                             check={checkId}
                             onClick={onClickCheckId}
                             signInfo={signInfo}
                             setSignInfo={setSignInfo}
                    />
                    <InputEmail able={(isInvestor || editAble)}
                                check={checkEmail}
                                onClick={onClickCheckEmail}
                                required={true}
                                signInfo={signInfo}
                                setSignInfo={setSignInfo}
                    />
                </div>
                <div className="wrap_field">
                    {commonPwdTag}
                </div>
                <div className="wrap_field" style={{margin: 0}}>
                    <InputPhone able={editAble}
                                check={checkPhone}
                                required={true}
                                onClick={onClickCertification}
                                signInfo={signInfo}
                                setSignInfo={setSignInfo}
                    />
                </div>
            </div>
        </>
    )
};

export default SignupInputAgent;