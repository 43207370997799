import React from "react";
import { Route } from "react-router-dom";
import FindId from "../components/find/FindId";
import FindPw from "../components/find/FindPw";
import CustomSwitch from "./common/CustomSwitch";
import RenewalFindId from "../components/find/RenewalFindId";

function FindRoute({ match }) {
  return (
    <CustomSwitch>
      <Route exact path={`${match.path}/id`} component={RenewalFindId} />
      <Route exact path={`${match.path}/pw`} component={FindPw} />
    </CustomSwitch>
  );
}

export default FindRoute;
