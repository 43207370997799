import "./css/PackageItems.scss";
import { useEffect, useState } from "react";
import { matchIcons } from "./data";

const PackageItems = ({ menu }) => {
  const iconArr = matchIcons;

  return (
    <div className="package_items">
      {menu?.map((item) => {
        item = Number(item);
        return (
          <div className="package_item">
            <i className={`icon_3d_${iconArr[item - 1].icon}`} />
            <span>{iconArr[item - 1].text}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PackageItems;
