import "../../inventor/css/InventorList.scss";
import { useHistory } from "react-router-dom";
import Pagination from "../../common/Pagination";

const AdminGiveUpAnnual = () => {
  const history = useHistory();

  return (
    <div id="InventorList">
      <div className="header">
        <h2>"{localStorage.getItem("company_name")}"의 포기권 모아보기</h2>
        <button onClick={() => history.goBack()}>목록으로 돌아가기</button>
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="80px" />
            <col width="120px" />
            <col width="120px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>구분</th>
              <th>특허번호</th>
              <th>등록일</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>확인</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={() => history.push("/inventor/patent/list")}>
              <td>1</td>
              <td>부산교육대학교 [본교]</td>
              <td>1020140193146</td>
              <td>2020-11-12</td>
              <td>홍범수</td>
              <td>02-123-4567</td>
              <td>qlwks@gmail.com</td>
              <td>서울특별시 노원구 공릉로 232 (공릉동, 서울과학기술대학교)</td>
              <td>
                <input type="checkbox" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
};

export default AdminGiveUpAnnual;
