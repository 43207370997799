import { useEffect, useState } from "react";
import InfoAPI from "../../../API/info";
import GroupwareSideBar from "../../groupware/GroupwareSideBar";
import { groupwareMypagePath } from "../../groupware/groupwarePath";
import { Link } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import ".././css/CompetitorRecent.scss";

const CompetitorRecent = () => {
  const [mySearchCompany, setMySearchCompany] = useState([]); // 최근 조회한 기업

  const getCompanyResearchHistory = () => {
    InfoAPI.getCompanyResearchHistory().then((res) => {
      //console.log("최근조회한기업:", res);
      if (res.data?.length) {
        setMySearchCompany(
          res.data.sort((a, b) => b.updated_at - a.updated_at) || []
        );
      }
    });
  };

  useEffect(() => {
    getCompanyResearchHistory();
  }, []);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupwareMypagePath} />
      <div className="commute_wrapper">
        <div id="CompetitorRecent">
          <h3 className="cont_title">최근 조회한 기업</h3>
          <p className="cont_sub_title">
            최근 조회한 기업을 다시 확인할 수 있습니다.
          </p>
          <div className="bg_white recent">
            <div className="tbl_box custom_scroll">
              <table>
                <colgroup>
                  <col width={60}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>연번</th>
                    <th>사용일자</th>
                    {/* <th>계정</th> */}
                    <th>기업명</th>
                    {/* <th>만료일</th> */}
                    <th>바로가기</th>
                  </tr>
                </thead>
                <tbody>
                  {mySearchCompany.length ? (
                    mySearchCompany.map((company, idx) => (
                      <tr key={company.company_idx}>
                        <td>{idx + 1}</td>
                        <td>
                          {moment.unix(company.updated_at).format("YYYY-MM-DD")}
                        </td>
                        <td>{company.company_name}</td>
                        <td>
                          <Link
                            to={`/competitor/details?applicant=${company.company_idx}&bizno=${company.company_bizno}`}
                            className="url"
                          >
                            조회
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>데이터가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorRecent;
