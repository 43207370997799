import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(location => {
            if (location.state != "TabChange") {
                console.log(location);
                //window.scrollTo(0, 0);
                // history.replace({
                //     pathname: location.pathname,
                //     search:location.search,
                // });
            }
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
}

export default withRouter(ScrollToTop);
