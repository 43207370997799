import React from "react";

function MyPageTemplate({title, Component}) {
	return (
		<div id="DashBoardTemplate" style={{width: 1030}}>
			<div className="dashBoard_title">{title}</div>
			<Component/>
		</div>
	)
}

export default MyPageTemplate;