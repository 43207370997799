export const employeeMixedChartData = {
  labels: ["2022.04", "2022.05", "2022.06", "2022.07"],
  datasets: [
    {
      label: "입사자 수",
      data: [50, 70, 60, 80],
      order: 3,
      borderColor: "#0078f1",
      backgroundColor: "#0078f1",
    },
    {
      label: "퇴사자 수",
      data: [11, 4, 10, 9],
      order: 2,
      borderColor: "#00ba78",
      backgroundColor: "#00ba78",
    },
    {
      label: "총 사원수",
      data: [101, 70, 105, 60],
      type: "line",
      fill: false,
      order: 1,
      pointRadius: 0,
      borderColor: "#d88fff",
      backgroundColor: "#d88fff",
    },
  ],
};

export const employeeMixedChartOption = {
  responsive: false,
  legend: {
    display: true,
    position: "bottom",
    labels: {
      boxWidth: 11,
      boxHeight: 11,
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export const employeeDoughnutChartData = {
  labels: ["20-30대", "40대 이상"],
  datasets: [
    {
      label: "Test",
      data: [3, 1],
      borderColor: ["#0078f1", "#00dd77"],
      backgroundColor: ["#0078f1", "#00dd77"],
    },
  ],
};

export const employeeDoughnutChartOption = {
  responsive: false,
  legend: {
    display: true,
    position: "right",
    labels: {
      boxWidth: 11,
      boxHeight: 11,
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

export const profitMixedChartData = {
  labels: ["2016", "2017", "2018", "2019", "2020"],
  datasets: [
    {
      label: "자본",
      data: [1691, 1640, 1561, 1902, 1524],
      borderColor: "#0078f1",
      barThickness: 40,
      order: 2,
      backgroundColor: "#0078f1",
    },
    {
      label: "부채",
      data: [614, 693, 423, 832, 896],
      borderColor: "#4593f5",
      barThickness: 40,
      order: 3,
      backgroundColor: "#4593f5",
    },
    {
      label: "자산",
      data: [2414, 2259, 2254, 2414, 2393],
      type: "line",
      fill: false,
      order: 1,
      borderWidth: 6,
      borderColor: "rgba(33, 198, 227, 0.35)",
      backgroundColor: "#21c6e3",
      pointBackgroundColor: "#21c6e3",
    },
  ],
};

export const profitMixedChartOption = {
  responsive: false,
  legend: {
    display: true,
    position: "right",
    labels: {
      boxWidth: 11,
      boxHeight: 11,
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: true,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
  },
};

export const auctionCompanyChartData = {
  labels: ["상위10%최대", "지원 수", "낙찰 수"],
  datasets: [
    {
      label: "Data",
      data: [30, 5, 1],
      borderColor: "#0078f1",
      backgroundColor: "#0078f1",
    },
  ],
};

export const auctionCompanyChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      color: "#ffffff",
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: true,
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
  },
};

export const analysisChartData = {
  labels: ["업력", "인력", "매출", "영업이익", "기술력", "낙찰률"],
  datasets: [
    {
      label: "조회 기업",
      data: [10, 12, 6, 5, 11, 5],
      type: "line",
      fill: false,
      backgroundColor: "rgba(0, 120, 241, 0.35)",
      borderColor: "rgba(0, 120, 241, 0.35)",
      pointBackgroundColor: "#0078f1",
      borderWidth: 6,
      lineTension: 0,
      pointRadius: 8,
    },
    {
      label: "상위 10%",
      data: [12, 15, 8, 6, 10, 8],
      type: "line",
      fill: false,
      borderColor: "rgba(0, 221, 119, 0.35)",
      pointBackgroundColor: "#00dd77",
      borderWidth: 6,
      lineTension: 0,
      pointRadius: 8,
    },
  ],
};

export const analysisChartOption = {
  responsive: false,
  legend: {
    display: true,
    position: "bottom",
    labels: {
      boxWidth: 11,
      boxHeight: 11,
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: true,
      formatter: function (value, ctx) {
        return value + "점";
      },
      align: "top",
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

export const growthFirstChartData = {
  labels: ["2019년", "2020년", "2021년", "2022년", "2023년"],
  datasets: [
    {
      label: "조회 기업",
      type: "line",
      data: [-23, -10, 1, -4, 15],
      fill: false,
      borderColor: "rgba(0, 120, 241, 0.35)",
      backgroundColor: "#0078f1",
      lineTension: 0,
    },
  ],
};

export const growthSecondChartData = {
  labels: ["2019년", "2020년", "2021년", "2022년", "2023년"],
  datasets: [
    {
      label: "조회 기업",
      type: "line",
      data: [-23, -10, 1, -4, 15],
      fill: false,
      borderColor: "rgba(0, 221, 119, 0.35)",
      backgroundColor: "#00dd77",
      lineTension: 0,
    },
  ],
};

export const growthThirdChartData = {
  labels: ["2019년", "2020년", "2021년", "2022년", "2023년"],
  datasets: [
    {
      label: "조회 기업",
      type: "line",
      data: [-23, -10, 1, -4, 15],
      fill: false,
      borderColor: "rgba(255, 166, 0, 0.35)",
      backgroundColor: "#ffa600",
      lineTension: 0,
    },
  ],
};

export const growthFourthChartData = {
  labels: ["2019년", "2020년", "2021년", "2022년", "2023년"],
  datasets: [
    {
      label: "조회 기업",
      type: "line",
      data: [-23, -10, 1, -4, 15],
      fill: false,
      borderColor: "rgba(172, 127, 255, 0.35)",
      backgroundColor: "#ac7fff",
      lineTension: 0,
    },
  ],
};

export const growthChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      formatter: function (value, ctx) {
        return value + "%";
      },
      align: "top",
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
          suggestedMin: -30,
          suggestedMax: 20,
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

export const growthCompareChartData = {
  labels: ["2019", "2020", "2021", "2022", "2023"],
  datasets: [
    {
      label: "조회 기업",
      type: "line",
      data: [10, 3, 7, 15, 20],
      borderColor: "rgba(0, 221, 119, 0.35)",
      backgroundColor: "#f1ffe2",
      pointBackgroundColor: "#00dd77",
      lineTension: 0,
      pointRadius: 5,
    },
    {
      label: "상위 10%",
      type: "line",
      data: [14, 10, 18, 20, 23],
      borderColor: "rgba(0, 120, 241, 0.35)",
      backgroundColor: "#eaf8ff",
      pointBackgroundColor: "#0078f1",
      lineTension: 0,
      pointRadius: 5,
    },
  ],
};

export const growthCompareChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      formatter: function (value, ctx) {
        return value + "%";
      },
      align: "top",
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

export const patentDoughnutChartData = {
  labels: ["등록", "출원", "포기"],
  datasets: [
    {
      label: "Data",
      data: [1, 2, 3],
      borderColor: [
        "#ffffff",
        "rgba(255,255,255,0.3)",
        "rgba(255,255,255,0.15)",
      ],
      backgroundColor: [
        "#ffffff",
        "rgba(255,255,255,0.3)",
        "rgba(255,255,255,0.15)",
      ],
    },
  ],
};

export const patentDoughnutChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export const annualFeeChartData = {
  labels: ["21년", "22년", "23년", "24년"],
  datasets: [
    {
      label: "Data",
      data: [157860, 157860, 2562860, 1287480],
      borderColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
      backgroundColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
    },
  ],
};

export const annualFeeChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
      font: {
        weight: "bold",
      },
      formatter: function (value) {
        return window.$Global.commaify(value);
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true,
          max: 5000000,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return window.$Global.commaify(tooltipItem.value) + "원";
      },
    },
  },
};

export const yearlyChartData = {
  labels: ["17년", "18년", "19년", "20년", "21년"],
  datasets: [
    {
      label: "Data 1",
      type: "line",
      data: [13, 5, 2, 4, 15],
      fill: false,
      borderColor: "#bcec45",
      backgroundColor: "#0095ae",
      pointBackgroundColor: "#0095ae",
      lineTension: 0,
    },
    {
      label: "Data 2",
      type: "line",
      data: [37, 5, 1, 21, 31],
      fill: false,
      borderColor: "#ffc500",
      backgroundColor: "#ffac12",
      pointBackgroundColor: "#ffac12",
      lineTension: 0,
    },
  ],
};

export const yearlyChartOption = {
  responsive: false,
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      align: "top",
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
          max: 50,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
