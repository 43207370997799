import React from "react";
import {CacheRoute} from "react-router-cache-route";
import SignupTerms from "../components/signup/SignupTerms";
import SignupType from "../components/signup/SignupType";
import {Route} from "react-router-dom";
import SignupInput from "../components/signup/SignupInput";
import SignupInputContainer from "../components/signup/findbiz/SignupInputContainer";
import SignupSuccess from "../components/signup/SignupSuccess";
import CustomSwitch from "./common/CustomSwitch";

function SignupRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/terms`} component={SignupTerms} when="forward"/>
			<CacheRoute exact path={`${match.path}/type`} component={SignupType} when="always"/>
			<Route exact path={`${match.path}/input`} component={SignupInput}/>
			<Route exact path={`${match.path}/findbiz/input`} component={SignupInputContainer}/>
			<Route exact path={`${match.path}/success`} component={SignupSuccess}/>
		</CustomSwitch>
	)
}

export default SignupRoute;