import React from "react";
import "./css/TradeList.scss";
import TradeItem from "./TradeItem";

function TradeList({query, title, activeDepth, list, hasMore, onClickNextList}) {
    let tag = [];

    if (list.length == 0) {
        tag =
            <div className="not_data">
                {
                    title
                    ?
                        activeDepth == 0
                        ? '판매 등록된 상표가 없습니다.'
                        : `관심 알림에 등록하신 키워드를 바탕으로 상표를 추천해드립니다.`
                    : '검색 결과가 없습니다.'
                }
           </div>;
    } else {
        tag =
            list.map((item, idx) => {
                return <TradeItem key={idx} item={item} query={query}/>;
            });
    }

    return (
        <div id="TradeList">
            <div className="wrap_list">
                {
                    title &&
                    <ul>
                        <li className={`wrap_title active`}>{title}</li>
                    </ul>
                }
                <div className="list">
                    {tag}
                </div>
            </div>
            {
                hasMore && <button className="btn_more" onClick={onClickNextList}>상표 더보기</button>
            }
        </div>
    )
}

export default TradeList;