import "./css/CompetitorList.scss";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/common/logo_findBiz.svg";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import CompetitorLoading from "./CompetitorLoading";
import session1 from "../../assets/images/common/companysearch.png";
const CompetitorList = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState(
    history.location.state.keyword
  );
  const [companyList, setCompanyList] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(
    history.location.state.keyword
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = async () => {
    await axios
      .get(`/api/v3/company`, {
        params: {
          search: searchInput,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setShowSearchInput(searchInput);
        setCompanyList(res.data);
      });
  };

  const handleNavigate = (item) => {
    if (item.company_bizno) {
      history.push({
        pathname: `/competitor/info/${item.company_bizno}`,
        state: {
          company_data: item,
        },
      });
    } else {
      alert("분석 정보를 확인할 수 없는 회사입니다.");
      return;
    }
  };

  return (
    <div id="CompetitorList">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>기업정보 검색</h2>
        </div>
        <div className="content">
          <div className="title">
            <p>
              <span>기업명을 검색하여 재무·기술·정부사업 참여현황 등</span>
              <br />
              <span>기업의 최신 현황과 </span>
              <br />
              <span>분석정보를 확인해 보세요. </span>
            </p>
          </div>
          <img src={session1} alt="session1" />
        </div>
      </div>
      <div id="CompetitorLists">
        <div className="search_wrapper">
          <p>
            기업검색결과 <i className="icon_info_with_blue_bg" />
          </p>
          <div className="search_input">
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") getCompanyList();
              }}
            />
            <i className="icon_search_gray" onClick={getCompanyList} />
          </div>
          <button>
            검색내역 보기
            <i className="icon_arrow_right_s" />
          </button>
        </div>
        <div className="company_info_wrapper">
          {isLoading ? (
            <CompetitorLoading />
          ) : companyList?.total === 0 ? (
            <p className="loading">경쟁사 정보 없음</p>
          ) : (
            <div>
              <h5>
                <span>{showSearchInput}</span> 검색 결과
              </h5>
              <div className="company_info_items custom_scroll">
                {companyList?.result?.map((item) => {
                  return (
                    <div className="company_info_item">
                      <img src={logo} />
                      <div className="item_text">
                        <p>{item.company_name}</p>
                        <p>대표명: {item.company_ceo}</p>
                        <div className="item_text_category">
                          {item.grouptags.map((keyword) => {
                            return <p>{keyword.company_group_name}</p>;
                          })}
                        </div>
                      </div>
                      <button onClick={() => handleNavigate(item)}>
                        기업 분석정보
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompetitorList;
