import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./css/MatchingBizEdit.scss";
import DatePickerCustom from "../datepicker/DatePickerCustom";
import CommonAPI from "../../API/common";
import qs from "query-string";
import memberContext from "../../store/Member";
import Validator from "../../util/validator";
import CommonAttachPopup from "../common/CommonAttachPopup";
import _ from "lodash";

function MatchingBizEdit({ history, location }) {
  const today = new Date().getTime();
  const { postType, idx } = qs.parse(location.search);
  const member = useContext(memberContext);
  const fileRef = createRef();
  const isNew = postType === "new";
  const isEdit = postType === "edit";
  const [showPopup, setShowPopup] = useState(false);
  const [field, setField] = useState({});
  const [payload, setPayload] = useState({
    title: "", // 요청 업무명 (string)
    description: "", // 업무 간이 소개(string)
    phone: "", // 전화번호(string)
    email: "", // 이메일(string)
    content: "", // 간단 설명
    region: 1, // 지역(int)
    field: 1, // 분야(int)
    support_target: 1, // 지원대상(int)
    biz_start_at: today, // 업무시작일(ms)
    biz_finish_at: today, // 업무마감일(ms)
    amount: "", // 금액(long)
    announcement_at: today, // 공고일(ms)
    reception_at: today, // 접수일(ms)
    deadline_at: today, // 마감일(ms)
  });
  const [fileList, setFileList] = useState([]);
  const phoneRef = useRef();
  const emailRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();
  const contentRef = useRef();
  const amountRef = useRef();

  const backURL = isEdit ? `detail/${idx}` : "/?category=2";

  useEffect(() => {
    CommonAPI.getTaskField(2).then((res) => setField(res.data));
    if (isEdit) {
      CommonAPI.getTaskInfo(idx).then((res) => {
        setPayload(res.data);
        setFileList(res.data.file);
      });
    }
  }, []);

  const submit = () => {
    if (
      Validator.refValidator([
        phoneRef,
        emailRef,
        titleRef,
        descriptionRef,
        contentRef,
        amountRef,
      ])
    ) {
      if (isNew) {
        CommonAPI.addMatchingBiz(payload).then(async (res) => {
          let formData = new FormData();
          let task_idx = res.data.task_idx;

          fileList.forEach((item) => formData.append("file", item));

          await CommonAPI.uploadMatchingBiz(task_idx, formData);
          history.push(`detail/${task_idx}`);
        });
      } else if (isEdit) {
        CommonAPI.editMatchingBiz(idx, payload).then(async (res) => {
          let formData = new FormData();
          fileList.forEach((item) => formData.append("file", item));
          await CommonAPI.uploadMatchingBiz(idx, formData);

          history.push(`detail/${idx}`);
        });
      }
    }
  };

  const fileDelete = async (f_idx, index) => {
    if (f_idx) {
      await CommonAPI.deleteMatchingBizFile(idx, f_idx);
    }
    let copy = _.cloneDeep(fileList);
    copy.splice(index, 1);
    setFileList(copy);
  };

  const convertJsonToHTML = (json, name, type) => {
    if (!json) return;
    let keys = Object.keys(json);

    let result = keys.map((item, index) => {
      let id = name + index;
      return (
        <React.Fragment key={index}>
          <input
            type={type ? type : "radio"}
            name={name}
            id={id}
            checked={json[item] === payload[name]}
            value={Number(json[item])}
            onChange={(e) =>
              setPayload({ ...payload, [name]: Number(e.target.value) })
            }
          />
          <label htmlFor={id}>{item}</label>
        </React.Fragment>
      );
    });
    return result;
  };

  return (
    <div id="MatchingBizEdit">
      <div className="MatchingBizEdit">
        <div className="MatchingBizEdit_title-wrap">
          <h2 className="MatchingBizEdit_title">매칭비즈 올리기</h2>
          <div className="MatchingBizEdit_btn-wrap">
            {isEdit && (
              <button className="btn_save" onClick={submit}>
                저&nbsp;&nbsp;장
              </button>
            )}
            <button
              type="button"
              className="btn_cancle"
              onClick={() => history.push(backURL)}
            >
              뒤&nbsp;&nbsp;로
            </button>
          </div>
        </div>
        <div className="MatchingBizEdit_content">
          <div className="comInfo">
            <div className="comInfo_title-wrap">
              <h3 className="comInfo_title">기본 정보 자동 입력</h3>
              {/*<button type="button" className="comInfo_btn">*/}
              {/*	수정하기*/}
              {/*</button>*/}
            </div>
            <div className="comInfo_content">
              <ul className="comInfo_content_list-wrap">
                <li className="comInfo_content_list comTitle">
                  <p className="comInfo_content_title">회사명</p>
                  <p className="comInfo_content_info">{member.company_name}</p>
                </li>
                <li className="comInfo_content_list">
                  <p className="comInfo_content_title">문의처</p>
                  <p className="comInfo_content_info">
                    <label
                      htmlFor="comTel"
                      className="comInfo_content_info_label"
                    >
                      전화번호
                    </label>
                    <input
                      type="tel"
                      name="comTel"
                      id="comTel"
                      className="comInfo_content_info_input"
                      value={payload.phone}
                      ref={phoneRef}
                      data-name={"전화번호"}
                      onChange={(e) =>
                        setPayload({ ...payload, phone: e.target.value })
                      }
                    />
                  </p>
                  <p className="comInfo_content_title">이메일</p>
                  <p className="comInfo_content_info">
                    <label
                      htmlFor="comEmail"
                      className="comInfo_content_info_label hidden"
                    >
                      이메일
                    </label>
                    <input
                      type="email"
                      name="comEmail"
                      id="comEmail"
                      className="comInfo_content_info_input"
                      value={payload.email}
                      ref={emailRef}
                      data-name={"이메일"}
                      onChange={(e) =>
                        setPayload({ ...payload, email: e.target.value })
                      }
                    />
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="inputInfo">
            <div className="inputInfo_title-wrap">
              <h3 className="inputInfo_title">정보 입력</h3>
              <div type="button" className="inputInfo_button">
                <p className="icon_i" />
                <p className="style">정보 입력의 모든 항목을 작성해 주세요</p>
              </div>
            </div>
            <div className="inputInfo_content">
              <ul className="inputInfo_content_list-wrap">
                <li className="inputInfo_content_list">
                  <label htmlFor="requested" className="list-title">
                    요청 업무명
                  </label>
                  <input
                    type="text"
                    name="requested"
                    id="requested"
                    className="list-info"
                    placeholder="요청할 업무명을 입력해주세요"
                    value={payload.title}
                    ref={titleRef}
                    data-name={"요청 업무명"}
                    onChange={(e) =>
                      setPayload({ ...payload, title: e.target.value })
                    }
                  />
                </li>
                <li className="inputInfo_content_list">
                  <label htmlFor="report" className="list-title">
                    업무 간이소개
                  </label>
                  <input
                    type="text"
                    name="report"
                    id="report"
                    className="list-info"
                    placeholder="업무를 간단하게 소개해주세요"
                    value={payload.description}
                    ref={descriptionRef}
                    data-name={"업무 간이소개"}
                    onChange={(e) =>
                      setPayload({ ...payload, description: e.target.value })
                    }
                  />
                </li>
              </ul>
              <ul className="inputInfo_content_list-wrap">
                <div className="badge-style">
                  <p>중복체크 가능</p>
                  <p className="icon_red_dot" />
                </div>
                <li className="inputInfo_content_list radio-style">
                  <div className="list-title">
                    지&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;역
                  </div>
                  <div className="list-info">
                    <div className="list-info_content custom_radio">
                      {convertJsonToHTML(field.region, "region")}
                    </div>
                  </div>
                </li>
                <li className="inputInfo_content_list radio-style">
                  <div className="list-title">
                    분&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;야
                  </div>
                  <div className="list-info">
                    <div className="list-info_content custom_radio">
                      {convertJsonToHTML(field.field, "field")}
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="inputInfo_content_list-wrap date">
                <li className="inputInfo_content_list-fixed">
                  <p className="firmDate_title">공고일</p>
                  <p className="firmDate_text">자동입력</p>
                </li>
                <ul className="inputInfo_content_list">
                  <li className="list-wrap">
                    <p className="list-title">업무 시작일</p>
                    <div className="list-date-wrap">
                      <p className="list-date">
                        {window.$Global.convertDate(payload.biz_start_at)}
                      </p>
                      <DatePickerCustom
                        data={payload.biz_start_at}
                        onChangeDatePicker={(date) =>
                          setPayload({
                            ...payload,
                            biz_start_at: new Date(date).getTime(),
                          })
                        }
                        minDate={payload.announcement_at}
                      />
                    </div>
                  </li>
                  <li className="list-wrap">
                    <p className="list-title">업무 마감일</p>
                    <div className="list-date-wrap">
                      <p className="list-date">
                        {window.$Global.convertDate(payload.biz_finish_at)}
                      </p>
                      <DatePickerCustom
                        data={payload.biz_finish_at}
                        onChangeDatePicker={(date) =>
                          setPayload({
                            ...payload,
                            biz_finish_at: new Date(date).getTime(),
                          })
                        }
                        minDate={payload.announcement_at}
                      />
                    </div>
                  </li>
                  <li className="list-wrap">
                    <p className="list-title">접수일</p>
                    <div className="list-date-wrap">
                      <p className="list-date">
                        {window.$Global.convertDate(payload.reception_at)}
                      </p>
                      <DatePickerCustom
                        data={payload.reception_at}
                        onChangeDatePicker={(date) =>
                          setPayload({
                            ...payload,
                            reception_at: new Date(date).getTime(),
                          })
                        }
                        minDate={payload.announcement_at}
                      />
                    </div>
                  </li>
                  <li className="list-wrap">
                    <p className="list-title">마감일</p>
                    <div className="list-date-wrap">
                      <p className="list-date">
                        {window.$Global.convertDate(payload.deadline_at)}
                      </p>
                      <DatePickerCustom
                        data={payload.deadline_at}
                        onChangeDatePicker={(date) =>
                          setPayload({
                            ...payload,
                            deadline_at: new Date(date).getTime(),
                          })
                        }
                        minDate={payload.announcement_at}
                      />
                    </div>
                  </li>
                </ul>
              </ul>
              <ul className="inputInfo_content_list-wrap explain">
                <li className="inputInfo_content_list-title">
                  <div className="list_title">
                    <div className="list_title_text">간단 설명</div>
                    <p className="list_title_sub">
                      간단한 설명글을 입력해주세요. &#40;1,000자 내외&#41;
                    </p>
                  </div>
                  <div className="list_btn">
                    <div className="list_btn_text">첨부파일</div>
                    <div className="list_btn_file">
                      <label
                        htmlFor="fileInput"
                        className="list_btn_file_label icon_upload"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPopup(true);
                        }}
                      >
                        파일찾기
                      </label>
                      <input
                        type="file"
                        name="fileInput"
                        id="fileInput"
                        className="hidden"
                        ref={fileRef}
                        multiple
                        onChange={(e) => setFileList([...e.target.files])}
                      />
                    </div>
                  </div>
                </li>
                <li className="inputInfo_content_list-content">
                  <textarea
                    name="inputTextarea"
                    id="inputTextarea"
                    className="textArea"
                    value={payload.content}
                    ref={contentRef}
                    data-name={"간단 설명"}
                    onChange={(e) =>
                      setPayload({
                        ...payload,
                        content: window.$Global.convertLine(e.target.value),
                      })
                    }
                  />
                </li>
              </ul>
              <ul className="inputInfo_content_list-wrap price">
                <li className="inputInfo_content_list">
                  <label htmlFor="priceInfo" className="price_label">
                    금&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;액
                  </label>
                  <div className="price_input-wrap">
                    <input
                      type="text"
                      name="priceInfo"
                      id="priceInfo"
                      className="price_input"
                      value={window.$Global.commaify(payload.amount)}
                      ref={amountRef}
                      data-name={"금액"}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          amount: window.$Global.numberRegx(e.target.value),
                        })
                      }
                    />
                    <sapn>원</sapn>
                  </div>
                </li>
              </ul>
            </div>
            {isNew && (
              <div className="inputInfo_btn">
                <button type="submit" onClick={submit}>
                  올리기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CommonAttachPopup
        show={showPopup}
        fileRef={fileRef}
        onClickDelete={fileDelete}
        onClickClose={() => setShowPopup(false)}
        list={fileList}
        hideDownloadBtn={true}
        showDeleteBtn={true}
      />
    </div>
  );
}

export default MatchingBizEdit;
