import React, { useContext, useEffect } from "react";
import "../css/RenewalSingupType.scss";
import { Link } from "react-router-dom";
import joinImg from "../../../assets/images/signup/join-img.png";
import axios from "axios";
import CommonContext from "../../../store/Common";

const RenewalSignupType = () => {
  const { isMobile } = useContext(CommonContext);
  return (
    <div id="RenewalSignupTypeFindBiz" className={isMobile ? "mo" : ""}>
      <div className="wrap_content">
        <h2>가입하기</h2>
        <div className="box_inner">
          <div className="join01">
            <Link to="findbiz/input?type=company" className="box">
              <div className="text">
                <p className="round">
                  <span>1개월 무료 사용 !</span>
                </p>
                <h5>
                  회원 가입하기<span>NEW</span>
                </h5>
                <p className="sub">신규 회원으로 시작합니다.</p>
              </div>
              <div className="img">
                <img src={joinImg} alt="기업회원 가입하기" />
              </div>
            </Link>
          </div>
          {/*<div className="join02">*/}
          {/*  <a href="#">*/}
          {/*    <div className="text">*/}
          {/*      <h5>기업 구성원 가입하기</h5>*/}
          {/*      <p>이미 가입한 기업의 구성원으로 가입합니다.</p>*/}
          {/*    </div>*/}
          {/*    <i className="icon_arrow_right_s" />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="counsel">
            <p>자세한 안내가 필요하신가요?</p>
            <Link to="/intro/service">상담요청</Link>
          </div>
          <p className="join">
            이미 가입한 회원이라면?&emsp;
            <Link to="/login">
              로그인 하기
              <i className="icon_arrow_right_s" />
            </Link>
          </p>
        </div>
        {/*<div className="left_content">*/}
        {/*  <div className="wrap_type">*/}
        {/*    <h2 className="type_head">희망하시는 가입유형을 선택해주세요.</h2>*/}
        {/*    <div className="wrap_box">*/}
        {/*      <Link to="findbiz/input?type=person" className="box">*/}
        {/*        <i className="icon_user_outline" />*/}
        {/*        <h2>예비 창업자</h2>*/}
        {/*        <p>간편 가입</p>*/}
        {/*        <p>&nbsp;</p>*/}
        {/*      </Link>*/}
        {/*      <Link to="findbiz/input?type=company" className="box">*/}
        {/*        <i className="icon_business_outline" />*/}
        {/*        <h2>기업 가입자</h2>*/}
        {/*        <p>간편 가입</p>*/}
        {/*        <p className="desc_text">*/}
        {/*          <span>BASIC&nbsp;</span>멤버십&nbsp;<span>무료&nbsp;</span>*/}
        {/*          제공*/}
        {/*        </p>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <Link to="/login" className="btn_login">*/}
        {/*    이미 회원이라면 로그인*/}
        {/*  </Link>*/}
        {/*  <div className="bottom_button_wrap">*/}
        {/*    <Link to="/find/pw">비밀번호 찾기</Link>*/}
        {/*    <span className="line" />*/}
        {/*    <Link to="/find/id">아이디 찾기</Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default RenewalSignupType;
