import React, {useEffect, useState} from "react";
import CheckBox from "../common/CheckBox";
import "./css/MobileAddPopup.scss";
import API from "../../util/api";

function MobileAddPopup({isPatent, isTradeMark, isDesign, company_code, onClickClose, onClickSelectAdd, nextStep}) {
    const [allChecked, setAllChecked] = useState(false);
    const [list, setList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    useEffect(() => {
        getList();
        setAllChecked(false);
    }, [isPatent, isTradeMark, isDesign]);

    const getList = () => {
        let params = {
            company_code: company_code,
        };

        if (isPatent) {
            API.get('/info/patent', {params: params}).then(res => {
                setList(res.data);
                let arr = [];
                res.data.map(() => arr.push(false));
                setCheckedList(arr);
            });
        } else if (isTradeMark) {
            API.get('/info/trademark', {params: params}).then(res => {
                setList(res.data);
                let arr = [];
                res.data.map(() => arr.push(false));
                setCheckedList(arr);
            });
        } else if (isDesign) {
            API.get('/info/design', {params: params}).then(res => {
                setList(res.data);
                let arr = [];
                res.data.map(() => arr.push(false));
                setCheckedList(arr);
            });
        }
    };

    const toggleCheckbox = (idx) => {
        let copy_json = window.$Global.cloneObject(checkedList);
        let copy_arr = Object.keys(copy_json).map(key => copy_json[key]);
        copy_arr[idx] = !copy_arr[idx];
        setCheckedList(copy_arr);
    };

    const onClickAdd = () => {
        let select_arr = checkedList.reduce((result, item, index) => {
            if (item) {
                result.push(list[index]);
            }
            return result
        }, []);
        onClickSelectAdd(select_arr, company_code);
        nextStep();
    };

    const onClickAllChecked = () => {
        setAllChecked(!allChecked);
        setCheckedList(checkedList.map(() => !allChecked));
    };

    let tbody = [];

    if (list.length == 0) {
        tbody = <li>
                    <p className="empty">등록된 {isPatent ? '특허' : isTradeMark ? '상표' : '디자인'}가 없습니다.</p>
                </li>
    } else {
        tbody = list.map((item, idx) => {
            return (
                <li>
                    <CheckBox checked={checkedList[idx] || false} onChangeChecked={() => toggleCheckbox(idx)}/>
                    <div className="txt">
                        <h2>{item.invention_title || item.invention_title_en || item.title || item.article_name}</h2>
                        <p>{item.apply_number}</p>
                    </div>
                    <p className="state">{item.status || item.state}</p>
                </li>
            );
        });
    }

    return (
        <div id="MobileAddPopup">
            <button className="icon_exit" onClick={onClickClose}/>
            <h2 className="page_title">
                기존 {isPatent ? '특허' : isTradeMark ? '상표' : '디자인'}<br/>
                자동 등록하기
            </h2>

            <div className="list">
                <div className="list_header">
                    <CheckBox checked={allChecked} onChangeChecked={onClickAllChecked}/>
                    <p>전체선택</p>
                </div>
                <ul className="list_content">
                    {tbody}
                </ul>
            </div>
            <button className="btn" onClick={onClickAdd}>{list.items?.length ? '등록' : '다음'}</button>
        </div>
    )
}

export default MobileAddPopup;
