import React from 'react';

function CompanyItem({title, src, onClick}) {
    return (
        <div className="item" onClick={onClick}>
            <p>
                {title}
            </p>
            <i className="icon_more_black"/>
        </div>
    );
}

export default CompanyItem;
