import React, { useRef, useState } from "react";
import axios from "axios";
import Admin from "../../API/admin";

function AdminTaskUpload() {
  const [file, setFile] = useState(null);
  const fileInput = useRef();

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await Admin.uploadTask(formData);
    fileInput.current.value = "";
    alert("업로드 되었습니다.");
  };
  return (
    <div id="AdminTaskService" style={{ minHeight: "100vh" }}>
      <h2 className="page_title">공고 업로드</h2>
      <div className="articles">
        <div className="field">
          <span>파일</span>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            ref={fileInput}
          />
        </div>
      </div>
      <button className="btn_upload" onClick={uploadFile}>
        업로드하기
      </button>
    </div>
  );
}

export default AdminTaskUpload;
