import "./css/EstimateInput.scss";

const EstimateInput = ({ data, title, name, essential, onChange }) => {
  return (
    <div className="estimate_input">
      <p>
        {title}
        {essential && <span className="require">*</span>}
      </p>
      <input type="text" name={name} value={data[name]} onChange={onChange} />
    </div>
  );
};

export default EstimateInput;
