import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./css/ResearchTechKeywordSearch.scss";
import { useHistory, Link } from "react-router-dom";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import ResearchTechAddPatent from "./ResearchTechAddPatent";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  applyNumberArray,
  highlightText,
  keywordIdx,
  researchTechHistory,
} from "../../atom";
import { Slider } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AnnualFeeReport from "../annualFee/AnnualFeeReport";
import ReportDummyPatentButton from './ReportDummyPatentButton';
import ReportBulkPatentButton from "./ReportBulkPatentButton";

const CustomSlider = withStyles({
  track: {
    backgroundImage: "linear-gradient(to left, #0d7, #0078f1)",
  },
})(Slider);

const ResearchTechKeywordSearch = ({ keyword }) => {
  const didMount = useRef(false);
  const history = useHistory();
  const [searchInput, setSearchInput] = useState(keyword || "");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [list, setList] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [addPatentData, setAddPatentData] = useState([]);
  const [deletePatentCheck, setDeletePatentCheck] = useState([]);
  const [deletePatentData, setDeletePatentData] = useState([]);
  const [applyNumArr, setApplyNumArr] = useRecoilState(applyNumberArray);
  const [historyData, setHistoryData] = useRecoilState(researchTechHistory);
  const [keywordNumber, setKeywordNumber] = useRecoilState(keywordIdx);
  const [count, setCount] = useState(10);
  const [rangeValue, setRangeValue] = useState(60);
  const setHighlightKeyword = useSetRecoilState(highlightText);
  const [companyIdx, setCompanyIdx] = useState(null);
  const [isReportShow, setIsReportShow] = useState(false);
  const [annualFeeData, setAnnualFeeData] = useState({});
  const regex = /\b(?:AND|OR)\b/gi; // highlight keyword를 AND OR로 분리하기 위한 정규식
  const [keywordCheck, setKeywordCheck] = useState({
    title: false,
    purpose: false,
    content: false,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    if (keyword !== undefined) historyBackKeywordSearch();
    const storedCompanyIdx = window.localStorage.getItem("company_idx");
    setCompanyIdx(storedCompanyIdx);
  }, []);
  useEffect(() => {
    if (didMount.current) {
      onClickKeywordSearch();
    } else {
      didMount.current = true;
    }
  }, [curPage, deletePatentData, count, rangeValue]);

  const onChangeSearchKeyword = (e) => {
    e.persist();
    setSearchInput(e.target.value);
  };

  const onClickSearchCancel = () => {
    setSearchInput("");
  };

  const historyBackKeywordSearch = () => {
    setSearchKeyword(keyword);
    let payload = {
      keyword: keyword,
      count: count,
      page: curPage,
      matchRate: rangeValue,
      enTransFlag: keywordCheck.title,
    };
    setHighlightKeyword(searchInput.split(regex));
    CommonAPI.getPatentDataKeyword(payload).then((res) => {
      res.data.items.map((el) => {
        if (deletePatentData.includes(el.apply_number)) {
          el.isDeleted = true;
        } else {
          el.isDeleted = false;
        }

        if (deletePatentCheck.includes(el.apply_number)) {
          el.isChecked = true;
        } else {
          el.isChecked = false;
        }
      });
      setList(res.data);
      setTotalCount(res.data.total_count);
    });
  };
  const onClickKeywordSearch = () => {
    setSearchKeyword(searchInput);
    let payload = {
      keyword: searchInput,
      count: count,
      page: curPage,
      matchRate: rangeValue,
      enTransFlag: keywordCheck.title,
    };
    setHighlightKeyword(searchInput.split(regex));

    CommonAPI.getPatentDataKeyword(payload).then((res) => {
      res.data.items.map((el) => {
        if (deletePatentData.includes(el.apply_number)) {
          el.isDeleted = true;
        } else {
          el.isDeleted = false;
        }

        if (deletePatentCheck.includes(el.apply_number)) {
          el.isChecked = true;
        } else {
          el.isChecked = false;
        }
      });
      setList(res.data);
      setTotalCount(res.data.total_count);
    });
  };

  const splitDate = (date) => {
    return (
      date.substring(0, 4) +
      "-" +
      date.substring(4, 6) +
      "-" +
      date.substring(6, 8)
    );
  };
  const handleClick = (e) => {
    //if(e.target.checked == false) {
    if (
      e.target.checked == true &&
      window.confirm(
        "해당 기능을 사용하면 번역 시간이 추가되어 검색결과가 추출에 장시간 걸릴 수 도 있습니다.\r\n계속 하시겠습니까?"
      )
    ) {
      setKeywordCheck({ ...keywordCheck, [e.target.name]: e.target.checked });
    } else if (e.target.checked !== true) {
      setKeywordCheck({ ...keywordCheck, [e.target.name]: e.target.checked });
    }

    //}
  };
  const handleAddText = (text) => {
    setSearchInput((prev) => prev + text);
    inputRef.current.focus();
  };
  const handleChange = (e, item) => {
    item.isChecked = !item.isChecked;
    if (item.isChecked) {
      setDeletePatentCheck([...deletePatentCheck, item.apply_number]);
    } else {
      setDeletePatentCheck(
        deletePatentCheck.filter((el) => el !== item.apply_number)
      );
    }
    setList({ ...list });
    console.log(deletePatentCheck);
  };

  const deletePatent = () => {
    setDeletePatentData([...deletePatentCheck]);
    setCurPage(1);
    alert("삭제되었습니다.");
  };

  const sendData = () => {
    let arr = [];
    let payload = {
      keyword: searchInput,
      count: totalCount,
      page: 1,
    };
    CommonAPI.getPatentDataKeyword(payload)
      .then((res) => {
        setKeywordNumber(res.data.keyword_idx);
        res.data.items.map((el) => {
          arr.push(el.apply_number);
        });
        addPatentData.map((el) => {
          arr.push(el.apply_number);
        });
        arr = arr.filter((el) => {
          return !deletePatentData.includes(el);
        });
      })
      .then(() => {
        setApplyNumArr(arr);
        setHistoryData({
          keyword: searchKeyword,
          isKeywordSearch: 2,
        });
        history.push(`edit?keyword=${searchKeyword}`);
      });
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
    setCurPage(1);
  };

  const downloadExcel = () => {
    let arr = [];
    let payload = {
      keyword: searchInput,
      count: totalCount,
      page: 1,
    };
    CommonAPI.getPatentDataKeyword(payload)
      .then((res) => {
        res.data.items.map((el) => {
          arr.push(el.apply_number);
        });
        addPatentData.map((el) => {
          arr.push(el.apply_number);
        });
        arr = arr.filter((el) => {
          return !deletePatentData.includes(el);
        });
      })
      .then(() => {
        console.log(arr);
        window.$Global.newDownload("/common/patent/downloadKeywordExcel", {
          patentApplyNumberList: arr,
        });
      });
  };
  const renderReportButton = (item) => {
    let value = item.nation;
    if (item.pge_idx || item.pge_idx2 || item.pge_idx_f) {
      return (
          <button
              className="report_btn"
              onClick={(event) => {
                event.stopPropagation();

                let report_idx = "";
                let family_idx = item.family_idx;

                if (item.pge_idx) {
                  report_idx = item.pge_idx;
                } else if (item.pge_idx2) {
                  report_idx = item.pge_idx2;
                } else if (item.pge_idx_f) {
                  report_idx = item.pge_idx_f;
                }

                setIsReportShow(true);
                setAnnualFeeData({
                  report_idx : report_idx,
                  nation : item.nation,
                  apply_number: item.apply_number,
                  register_number: item.register_number || item.regist_number,
                  idx : item.idx,
                });
              }}
          >
            확인하기
          </button>
      );
    } else if (item.nation === "KR") {
      if (item.register_number !== null && item.register_number.trim() !== "" && item.register_number !== undefined) {
        if(item.apply_cnt > 0) {
          return "생성중";
        } else {
          return (
              <button
                  className="report_btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    reportApply(item);
                  }}
              >
                생성하기
              </button>
          );
        }

      } else {
        return "-";
      }
    } else {
      if (
          item.parent_register_number !== null &&
          item.parent_register_number !== "" &&
          item.parent_register_number !== undefined
      ) {
        if(item.apply_cnt > 0 ){
          return "생성중";
        } else {
          return (
              <button
                  className="report_btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    reportForApply(item);
                  }}
              >
                생성하기
              </button>
          );
        }
        //return "-" + item.parent_register_number;
      }  else {
        return "-";
      }
    }
  };

  const reportApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.idx;
    param.org_nation = item.org_nation;

    // 벌크 테이블의 데이터는 출원번호, 등록번호에 - 없는 번호로 되어있어 이렇게 추가함
    let apply_number = item.apply_number;
    let register_number = item.register_number;
    // 구분번호: 첫번째 자리
    let sectionNumber = apply_number.slice(0, 2);
    // 연도: 두번째부터 다섯번째 자리
    let year = apply_number.slice(2, 6);
    // 일련번호: 나머지 자리
    let serialNumber = apply_number.slice(6);

    item.apply_number = `${sectionNumber}-${year}-${serialNumber}`;

    if(register_number !== null && register_number !== undefined && register_number.trim() !== '') {
      sectionNumber = register_number.slice(0, 2);
      // 연도: 두번째부터 다섯번째 자리
      year = register_number.slice(2, 6);
      // 일련번호: 나머지 자리
      serialNumber = register_number.slice(6);
      item.register_number = `${sectionNumber}-${year}-${serialNumber}`;
    }
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
        onClickKeywordSearch();
      });
    }
  };

  const reportForApply = (item) => {
    const check = window.confirm("특허활용 보고서를 생성하시겠습니까?");
    let param = {};
    param.patent_idx = item.parent_register_number;
    // MOZQYC25XKG8KEE0 우리회사
    //alert(companyIdx);
    if (check) {
      CommonAPI.dummyPatentReportApply(item).then(() => {
        alert("보고서 생성까지 5분정도 소요됩니다.\n5분후 새로고침 해 주세요.");
      });
    }
  };

  return (
    <div id="ResearchTechKeywordSearch">
      <div className="search_wrapper_border">
        <div className="search_wrapper">
          <p className="search_title">
            키워드검색
            <i
              className="icon_info_with_blue_bg"
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(false)}
            />
          </p>
          {showInfo && (
            <div className="search_info">
              키워드가 많을수록 정확하고 빠른검색 지원
              <div>
                ex)
                <p>
                  <span>로봇 AND 인공지능</span>
                </p>
              </div>
            </div>
          )}
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력하세요"
              value={searchInput}
              onChange={onChangeSearchKeyword}
              onKeyUp={(e) => e.key === "Enter" && onClickKeywordSearch()}
              ref={inputRef}
            />
            <button
              className="icon_search_gray ir_txt"
              style={{ marginRight: "10px" }}
              onClick={onClickKeywordSearch}
            >
              검색
            </button>
          </div>
        </div>
        <div className="keyword_search_sub">
          <div className="keyword_add_btn">
            <button className="btn_add" onClick={() => handleAddText(" AND ")}>
              AND
              <i className="icon_gray_add" />
            </button>
            <button className="btn_add" onClick={() => handleAddText(" OR ")}>
              OR
              <i className="icon_gray_add" />
            </button>
            {/*<label>*/}
            {/*  <input*/}
            {/*    type="checkbox"*/}
            {/*    checked={keywordCheck.title}*/}
            {/*    name="title"*/}
            {/*    onChange={handleClick}*/}
            {/*  />*/}
            {/*  <span style={{ color: "#4593f5", fontSize: "14px" }}>*/}
            {/*    {" "}*/}
            {/*    키워드 영문 변환 기능 사용*/}
            {/*  </span>*/}
            {/*</label>*/}
          </div>

          <button
            className="btn_history"
            onClick={() => history.push("/researchTech/my/keyword")}
          >
            검색내역 보기
            <i className="icon_arrow_right_s" />
          </button>
        </div>
      </div>
      {list.items?.length > 0 && (
        <>
          <div className="keyword_search_wrapper">
            <div className="keyword_header">
              <h2>
                검색 결과{" "}
                <span>{window.$Global.commaify(list.total_count)}</span>건
              </h2>
              <div className="util_btn">
                <div className="range_wrapper">
                  <p>검색결과 유사도범위(%)</p>
                  <CustomSlider
                    aria-label="Temperature"
                    defaultValue={rangeValue}
                    onChange={(e) => setRangeValue(e.target.value)}
                    valueLabelDisplay="auto"
                    step={20}
                    min={0}
                    max={100}
                    sx={{
                      width: 130,
                      height: 11,
                      color: "#d8d8d8",
                    }}
                  />
                </div>
                <select name="count" onChange={(e) => handleCountChange(e)}>
                  <option value={10}>10건 씩 보기</option>
                  <option value={20}>20건 씩 보기</option>
                  <option value={50}>50건 씩 보기</option>
                </select>
                <button onClick={() => sendData()}>보고서 작성하기</button>
                {/*<button onClick={downloadExcel}>*/}
                {/*  <i className="icon_download_attached" /> Excel 다운받기*/}
                {/*</button>*/}
              </div>
            </div>
            {/*<div className="keyword_btn">*/}
            {/*  <button onClick={() => setShowPopup(true)}>특허 추가하기</button>*/}
            {/*  <button onClick={() => deletePatent()}>특허 삭제하기</button>*/}
            {/*</div>*/}
            <table>
              <colgroup>
                <col width={50} />
                <col width={50} />
                <col width={150} />
                <col width={100} />
                <col width={150} />
                <col width={100} />
                <col width={400} />
                <col width={130} />
                <col width={50} />
                <col width={60} />
                <col width={70} />
              </colgroup>
              <thead>
                <tr>
                  <th>번 호</th>
                  <th>국 가</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th style={{ textAlign: "left" }}>발명의 명칭</th>
                  <th>출원인</th>
                  <th>등급</th>
                  <th></th>
                  <th>{"특허보고서"}</th>
                </tr>
              </thead>
              <tbody>
                {list?.items?.map((item, idx) => {
                  const apply_at = splitDate(item.apply_at);
                  const register_at = splitDate(item.register_at);
                  return (
                    <tr
                      onClick={() => {
                        history.push({
                          pathname: `patent/${
                            item.nation === "KR"
                              ? item.apply_number.replace(/-/g, "")
                              : item.document_number
                          }/detail?nation=${item.nation}`,
                          state: {
                            keyword: searchKeyword,
                            isKeywordSearch: 2,
                          },
                        });
                        window.location.reload();
                      }}
                      style={{
                        opacity: item.isDeleted && "0.4",
                        cursor: "pointer",
                      }}
                    >
                      {/*<td>*/}
                      {/*  <input*/}
                      {/*    type="checkbox"*/}
                      {/*    onChange={(e) => handleChange(e, item)}*/}
                      {/*    checked={item.isChecked}*/}
                      {/*    disabled={item.isDeleted}*/}
                      {/*  />*/}
                      {/*</td>*/}
                      <td>{(curPage - 1) * 10 + (idx + 1)}</td>
                      <td>{item.nation}</td>
                      <td>{item.register_number}</td>
                      <td>{register_at}</td>
                      <td>{item.apply_number}</td>
                      <td>{apply_at}</td>
                      <td style={{ textAlign: "left" }}>
                        {item.invention_name}
                      </td>
                      <td>{item.applicant}</td>
                      <td
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className={`circle grade_${
                            item.now_grade || "default"
                          }`}
                        >
                          {item.now_grade || "N"}
                        </div>
                      </td>
                      <td>
                        <i className="icon_arrow_right_s" />
                      </td>
                      <td>
                        {" "}
                        {(<ReportBulkPatentButton item={item} setIsReportShow={setIsReportShow} setAnnualFeeData={setAnnualFeeData} />)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            curPage={curPage}
            lastNum={list.last}
            onClick={setCurPage}
          />
          {addPatentData.length > 0 && (
            <div className="keyword_search_wrapper">
              <h2 style={{ margin: "10px" }}>
                추가한 특허 건 수 <span>{addPatentData.length}</span> 건
              </h2>
              <table>
                <colgroup>
                  <col width={50} />
                  <col width={50} />
                  <col width={425} />
                  <col width={120} />
                  <col width={150} />
                  <col width={60} />
                  <col width={75} />
                  <col width={70} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번 호</th>
                    <th>국 가</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th style={{ textAlign: "left" }}>발명의 명칭</th>
                    <th>출원인</th>
                    <th style={{ textAlign: "left" }}>등급</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {addPatentData.map((item, idx) => {
                    const apply_at = splitDate(item.apply_at);
                    return (
                      <tr>
                        <td>{(curPage - 1) * 10 + (idx + 1)}</td>
                        <td>{item.nation}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.invention_name}
                        </td>
                        <td>{item.applicant}</td>
                        <td>{item.apply_number}</td>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className={`circle grade_${
                              item.now_grade || "default"
                            }`}
                          >
                            {item.now_grade || "N"}
                          </div>
                        </td>
                        <td>{apply_at}</td>
                        <td>
                          <i className="icon_download_attached" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className="research_util_btn">
            <button onClick={() => downloadExcel()}>
              <i className="icon_download_blue" />
              Excel로 다운받기
            </button>
          </div>
        </>
      )}
      {showPopup && (
        <ResearchTechAddPatent
          onChangeShow={setShowPopup}
          list={addPatentData}
          setList={setAddPatentData}
          setCurPage={setCurPage}
        />
      )}
      {isReportShow && (
          <AnnualFeeReport
              report_idx={annualFeeData.report_idx}
              annualFeeData={annualFeeData}
              setIsReportShow={setIsReportShow}
              family_idx={annualFeeData.family_idx}
          />
      )}
    </div>
  );
};

export default ResearchTechKeywordSearch;
