import React, { useContext, useEffect, useState } from "react";
import "./css/IntroServiceDetail.scss";
import TaskTable from "../taskManage/organisms/TaskTable";
import Const from "../taskManage/const";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";
import CommonAPI from "../../API/common";
import config from "../../const/config";
import qs from "query-string";
import Pagination from "../common/Pagination";
import PopupTaskDetail from "../taskManage/PopupTaskDetail";
import PopupMobileTaskDetail from "../taskManage/PopupMobileTaskDetail";
import MobilePopupAttach from "../common/MobilePopupAttach";
import memberContext from "../../store/Member";
import LoginPop from "../popup/LoginPop";

function IntroServiceDetail() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { isMobile } = useContext(CommonContext);
  const MobilePopup = useContext(MobilePopupContext);
  const { keyword } = qs.parse(location.search);
  const [idx, setIdx] = useState(null);
  const [field, setField] = useState({}); // 조건 검색 필드 담는 변수
  const [list, setList] = useState({ items: [] });
  const [detailInfo, setDetailInfo] = useState({}); // 상세보기 팝업 데이터를 담는 변수
  const [popupShow, setPopupShow] = useState(false);
  const [moPopShow, setMoPopShow] = useState(false); // 모바일 상세보기 팝업 노출 여부
  const [moPopDownloadShow, setMoPopDownloadShow] = useState(false);
  const popTextDetail = "사업 상세페이지";
  const popTextDownload = "사업 다운로드";
  const popHash = "#pop";
  const [curPage, setCurPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [stateType, setStateType] = useState(Const.stateType.all); // 4 접수중, 2 접수예정
  const [isLoginPopVisible, setIsLoginPopVisible] = useState(false);
  const memberInfo = useContext(memberContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;

  useEffect(() => {
    CommonAPI.getTaskField(Const.showType.bizAll).then((res) =>
      setField(res.data)
    );
  }, []);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (location.hash !== popHash) {
      location.hash = "";
      setMoPopShow(false);
      MobilePopup.setMobilePopup({
        ...MobilePopup.mobilePopup,
        mobileShow: false,
        historyMobileHeaderTitle: "",
      });
    }
  }, [location]);

  useEffect(() => {
    // 헤더 close 이벤트 발생 시
    closeHeader();
  }, [MobilePopup.mobilePopup]);

  const closeHeader = () => {
    if (!MobilePopup.mobilePopup.mobileShow) {
      if (moPopShow && moPopDownloadShow) {
        // 둘 다 true 일 경우 상세 페이지 팝업에서 사업 다운로드가 열린 것이므로 사업 다운로드 팝업 숨김 처리
        setMoPopDownloadShow(false);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
          historyMobileHeaderTitle: "",
        });
      } else {
        setMoPopShow(false);
        setMoPopDownloadShow(false);
      }
    }
  };

  const getDepartment = () => {
    let department_name = [keyword];

    if (keyword === "중소벤처기업부") {
      department_name = department_name.concat([
        "중소기업기술정보진흥원",
        "에너지기술평가원",
        "중소벤처기업진흥공단",
      ]);
    } else if (keyword === "과학기술정보통신부") {
      department_name = department_name.concat([
        "정보통신기술진흥센터",
        "중소기업기술정보진흥원",
      ]);
    } else if (keyword === "산업통상자원부") {
      department_name = department_name.concat(["한국산업기술평가관리원"]);
    } else if (keyword === "문화체육관광부") {
      department_name = department_name.concat(["한국콘텐츠진흥원"]);
    } else if (keyword === "해양수산부") {
      department_name = department_name.concat(["해양수산과학기술진흥원"]);
    } else if (keyword === "국토교통부") {
      department_name = department_name.concat(["국토교통과학기술진흥원"]);
    } else if (keyword === "보건복지부") {
      department_name = department_name.concat(["한국보건산업진흥원"]);
    } else if (keyword === "농림축산식품부") {
      department_name = department_name.concat([
        "농업기술실용화재단",
        "농림식품기술기획평가원",
      ]);
    } else if (keyword === "환경부") {
      department_name = department_name.concat(["환경산업기술원"]);
    }
    return department_name.join(",");
  };

  const getList = (page, p_searchKeyword) => {
    let payload = {
      page: page || 1,
      keyword: p_searchKeyword || null,
      count: 50,
      department_name: getDepartment(),
      sort: 0,
      select_start_at: new Date(2024, 0, 1).getTime() / 1000,
      state: 6,
    };

    if (isSearch) {
      payload.keyword = searchKeyword;
    }

    CommonAPI.getTaskList(payload).then((res) => {
      console.log(res.data);
      setList(res.data);
    });
  };

  const initList = () => {
    setCurPage(1);
    setIsSearch(false);
    setSearchKeyword("");
    let payload = { page: 1, count: 50, department_name: getDepartment() };
    CommonAPI.getTaskList(payload).then((res) => setList(res.data));
  };

  const changePage = (page) => {
    getList(page);
    setCurPage(page);
  };

  const searchAPI = () => {
    setCurPage(1);
    setIsSearch(true);
    getList(1, searchKeyword);
  };

  const getDetail = async (idx) => {
    if(isLogin) {
      setIdx(idx);
      let result = await CommonAPI.getTaskInfo(idx, {
        headers: { "x-token": config.findbizFreeToken },
      }).then((res) => res.data);
      setDetailInfo(result);

      if (isMobile) {
        if (location.hash !== popHash) {
          history.push(popHash);
        }
        setMoPopShow(true);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
        });
      } else {
        setPopupShow(true);
      }
    } else {
      setIsLoginPopVisible(true);
    }
    
  };

  const showMoPopupFile = () => {
    setMoPopDownloadShow(true);

    let payload = {
      ...MobilePopup.mobilePopup,
      mobileShow: true,
      mobileHeaderTitle: popTextDownload,
    };

    if (moPopShow) {
      payload.historyMobileHeaderTitle = popTextDownload;
    }

    MobilePopup.setMobilePopup(payload);
  };

  const onClickFileDownload = (f_idx) => {
    CommonAPI.taskFileDownload(idx, f_idx);
  };

  const showLoginPop = () => {
    setIsLoginPopVisible(true);
  };

  const closeLoginPop = () => {
    setIsLoginPopVisible(false);
  };

  return (
    <div id="IntroServiceDetail">
      <div className="top_banner">
        <div>
          <h2>
            {keyword}
            <span>사업 총정리</span>
          </h2>
          <p>
            {keyword === "중소벤처기업부" ? (
              <>
                기술개발 R&D 사업부터 소상공인 정책 융자계획 까지,
                <br />
                중소벤처기업부 정부지원사업의 최신 정보를 모두 확인하세요.
              </>
            ) : keyword === "산업통상자원부" ? (
              <>
                산업기술혁신 R&D부터 바우처 사업까지,
                <br />
                산업통상자원부 정부지원사업의 최신 소식을 모두 확인하세요.
              </>
            ) : keyword === "과학기술정보통신부" ? (
              <>
                정보통신방송 기술개발 사업부터 ICT원천연구 개발 사업까지,
                <br />
                과학기술정보통신부 정부지원사업의 최신 정보를 모두 확인하세요.
              </>
            ) : (
              <>{keyword} 정부지원사업의 최신 정보를 모두 확인하세요.</>
            )}
          </p>
        </div>
        <img
          src={
            require("../../assets/images/intro/gov_work.png").default
          }
          className="banner_top"
          alt="탑 배너"
        />
      </div>
      <div className="index">
        <Link to="/">HOME</Link> &gt; 
        <Link to="/taskManage/page2">2024 지원사업 총정리</Link> &gt;
        <Link to={`/intro/page/detail?keyword=${keyword}`}>{keyword} 사업 총정리</Link>
      </div>

      <div className="head">
        <h2>2024 공고 모아보기</h2>
        <div className="search_area">
          <input
            type="text"
            placeholder="검색어를 입력해주세요"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && searchAPI()}
          />
          {isSearch && (
            <button className="icon_del_small_with_bg" onClick={initList} />
          )}
        </div>
      </div>

<div className="task_tbl_box">
      <TaskTable
        showType={Const.showType.bizOrganized}
        searchType={{}}
        stateType={stateType}
        field={field}
        list={list.items}
        onClickDetail={getDetail}
      />
</div>

      <Pagination curPage={curPage} lastNum={list.last} onClick={changePage} />

      <PopupTaskDetail
        show={popupShow}
        item={detailInfo}
        field={field}
        onClose={() => setPopupShow(false)}
      />
      <PopupMobileTaskDetail
        show={moPopShow}
        field={field}
        item={detailInfo}
        showMoPopupFile={showMoPopupFile}
      />
      <MobilePopupAttach
        show={moPopDownloadShow}
        item={detailInfo.file}
        onDownload={onClickFileDownload}
      />
      <LoginPop isVisible={isLoginPopVisible} handleClose={closeLoginPop} />
    </div>
  );
}

export default IntroServiceDetail;
