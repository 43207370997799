import React, {useContext} from 'react';
import PatentContext from "../../../../store/Patent";
import _ from "lodash";

function InventorRow({index, editing, inventor, inventorSelectTag, onClickEvent}) {
    const patentInfo = useContext(PatentContext);
    let copy = _.cloneDeep(patentInfo);

    return (
        <div className="row">
            <h2 className="title">
                발명자 {index + 1}
                {
                    editing &&
                    (
                        index == 0
                        ? <button className="icon_plus" onClick={onClickEvent}/>
                        : <button className="icon_badge_del" onClick={() => onClickEvent(index)}/>
                    )
                }
                {
                    (index == 0) && <i className="icon_info" data-content={window.$Global.getInfoMessage("발명자1")}/>
                }
            </h2>
            {
                editing
                ?
                <select defaultValue={inventor.idx || 0} onChange={(e) => {
                    copy.inventor[index].idx = e.target.value;
                    copy.inventor[index].name = e.target.options[e.target.selectedIndex].text;
                    patentInfo.setPatent(copy)
                }}>
                    <option value={0} disabled hidden>선택</option>
                    {inventorSelectTag}
                </select>
                : <p className={`${Boolean(inventor.name) ? '' : 'color_blue'}`}>{inventor.name || '수정을 눌러 입력해 주세요.'}</p>
            }
        </div>

    );
}
export default InventorRow;
