import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareWorksPath } from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import Moment from "react-moment";
import "moment/locale/ko";
import { Bar, Doughnut } from "react-chartjs-2";
import config from "../../const/config";

const GroupwareVactionStats = () => {
  const params = new URLSearchParams(window.location.search);
  let command = params.get("doc_type");
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareWorksPath} />
      <div className="commute_wrapper">
      <iframe
          src={`${
            config.gwUrl
          }/vacation.auth.do?command=${command}`}
          width="100%"
          height="600px"
        />
      </div>
    </div>
  );
};

export default GroupwareVactionStats;
