import React, {useEffect, useState} from "react";
import "./css/ApprovalList.scss";
import PatentAPI from "../../API/patent";
import {getApprovalStateToStr} from "./js/getApprovalStateToStr";
import Pagination from "../common/Pagination";
import {useHistory} from "react-router-dom";

function ApprovalList() {
	const history = useHistory();
	const [curPage, setCurPage] = useState(1);
	const [data, setData] = useState({items: []});

	useEffect(() => getList(), [curPage])

	const getList = (page) => {
		if (page) {
			setCurPage(page);
		}

		let params = {
			page: page || curPage,
			count: 30
		};
		PatentAPI.getPatentApproval(params).then(res => setData(res.data));
	};

	return (
		<div id="ApprovalList">
			<div className="head">
				<h2>결재 리스트</h2>
			</div>
			<div className="wrap_list">
				<table>
					<thead>
						<tr>
							<th>특허번호</th>
							<th>담당자명</th>
							<th>기안일</th>
							<th>결재상태</th>
						</tr>
					</thead>
					<tbody>
					{
						Boolean(data.items.length)
						?
						data.items.map(item => {
							return (
								<tr key={item.idx} onClick={() => history.push(`/patent/detail/${item.patent_idx}`)}>
									<td>{item.apply_number}</td>
									<td>{item.name}</td>
									<td>{window.$Global.convertDate(item.created_at)}</td>
									<td>{getApprovalStateToStr(item.answer)}</td>
								</tr>
							)
						})
						: window.$Global.notTd(4, "결재 정보가 없습니다")
					}
					</tbody>
				</table>
			</div>
			<Pagination curPage={curPage} lastNum={data.last} onClick={setCurPage} />
		</div>
	)
}

export default ApprovalList;