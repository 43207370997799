const Steps = () => {
  const steps = [
    {
      level: "STEP 1.",
      title: "연계은행정보 확인하기",
      description:
        "BIZNAVI 연계 은행 정보 파악하기\n은행별 IP담보대출 상품 확인하기",
    },
    {
      level: "STEP 2.",
      title: "보유특허 가치 점검하기",
      description:
        "우리회사 특허등급 무료 확인하기\n특허활용보고서로 내 특허 파악하기",
    },
    {
      level: "STEP 3.",
      title: "대출 상담 준비하기",
      description:
        "은행제출용 우리회사 특허자료 다운받기\n제품별 시장동향 파악하기",
    },
    {
      level:
        "STEP 4. 이후 대출 준비 심사/실행은 비즈내비 연계은행과 함께 진행해 보세요.",
      title: "",
      description: "",
    },
  ];

  return (
    <div id="Steps">
      <div className="top">
        <div className="biznavi">
          <img
            className="desc_auto"
            src={require("../../assets/images/common/biznavi_ko.png").default}
            alt="비즈내비"
          />
          <strong>로 IP담보대출 시작하기</strong>
        </div>
        <p>
          IP담보대출은 특허를 보유한 중소벤처기업이 유용하게 활용할 수 있는 자금
          조달 방법입니다.
        </p>
      </div>
      <div className="help">
        <strong className="section_tit">
          비즈내비에서는 이런 도움을 받을 수 있어요.
        </strong>
        <ul className="steps">
          {steps.map((step, idx) => (
            <li>
              <strong>
                {step.level}
                <br />
                {step.title}
              </strong>
              {step.description === "" ? "" : <p>{step.description}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Steps;
