import "../competitor/css/CompetitorMain.scss";
import logo from "../../assets/images/common/logo_findBiz.svg";
import img from "../../assets/images/competitor/img-search.png";
import iconMagnifierBlue from "../../assets/images/competitor/icon-magnifier-blue.png";
import iconBinocular from "../../assets/images/competitor/icon-binocular.png";
import iconPaperList from "../../assets/images/competitor/icon-paper-list.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const CompetitorIpnowMain = () => {
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();

  const getCompanyInfo = () => {
    history.push({ pathname: "list", state: { keyword: searchInput } });
  };

  return (
    <div id="CompetitorMain">
      <div className="header_wrap">
        <h2>우리기업의 경쟁사가 궁금하신가요?</h2>
        <div className="header_grid">
          <div>
            <p>
              관심기업 정보<span>보기</span>
              <p className="new_red">NEW</p>
            </p>
            <p>기업 경쟁사 찾기부터 기업의 경쟁정보까지 확인할 수 있습니다.</p>
            <div className="search_wrapper">
              <input
                type="text"
                placeholder="입력하세요"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") getCompanyInfo();
                }}
              />
              <i className="icon_search_gray" onClick={getCompanyInfo} />
            </div>
          </div>
          <div className="header_list_wrapper">
            <h5>최근 조회한 기업</h5>
            <div className="header_list_items">
              <div className="header_list_item">
                <div>
                  <img src={logo} />
                  <p>(주)유메딕스</p>
                </div>
                <i className="icon_arrow_right_s" />
              </div>
              <div className="header_list_item">
                <div>
                  <img src={logo} />
                  <p>(주)유메딕스</p>
                </div>
                <i className="icon_arrow_right_s" />
              </div>
              <div className="header_list_item">
                <div>
                  <img src={logo} />
                  <p>(주)유메딕스</p>
                </div>
                <i className="icon_arrow_right_s" />
              </div>
            </div>
          </div>
          <div className="header_list_wrapper">
            <h5>One Click 서비스</h5>
            <div className="header_list_items">
              <div className="header_list_item">
                <p className="one_click_text">
                  <span>우리회사</span> 경쟁사 탐색하기
                </p>
                <img src={iconMagnifierBlue} />
              </div>
              <div className="header_list_item">
                <p className="one_click_text">
                  <span>우리회사</span> 상세 정보보기
                </p>
                <img src={iconBinocular} />
              </div>
              <div className="header_list_item">
                <p className="one_click_text">
                  <span>우리회사vs관심기업</span> 비교 분석
                </p>
                <img src={iconPaperList} />
              </div>
            </div>
          </div>
          <div>
            <p>
              기업
              <br />
              <span>비교분석 서비스</span>
            </p>
            <div>
              <p>
                여러 기업의 경쟁력을
                <br />
                종합적인 지표로 비교분석 할 수 있습니다.
              </p>
              <i className="icon_3d_like" />
            </div>
          </div>
        </div>
      </div>
      <div className="competitor_list">
        <div>
          <h5>
            지금 가장 HOT한 <span>경쟁기업</span> TOP 5
          </h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
        <div>
          <h5>지금 가장 HOT한 기업 TOP 5</h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
      </div>
      <div className="search_company_list">
        <div>
          <h5>
            지금 가장 HOT한 <span>경쟁기업</span> TOP 5
          </h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
      </div>
      <div className="competitor_list">
        <div>
          <h5>우리회사를 조회한 경쟁기업</h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
        <div>
          <h5>우리 기업을 조회한 기업</h5>
          <div className="company_info_items">
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
            <div className="company_info_item">
              <img src={logo} />
              <div className="item_text">
                <p>(주)유메딕스</p>
                <p>웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업</p>
                <div className="item_text_category">
                  <p>데이터</p>
                  <p>B2B</p>
                  <p>플랫폼</p>
                </div>
              </div>
              <button>기업 분석정보</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorIpnowMain;
