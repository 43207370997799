import "./AnnualFeeLogin.scss";

const AnnualFeeLogin = () => {
  return (
    <div id="AnnualFeeLogin">
      <div className="wrapper">
        <h2>IPNOW연차료관리</h2>
        <div className="login_form">
          <div>
            <p>
              아이디
              <i className="icon_red_dot" />
            </p>
            <input type="text" placeholder="입력하세요." />
          </div>
          <div>
            <p>
              비밀번호
              <i className="icon_red_dot" />
            </p>
            <input type="password" placeholder="입력하세요." />
          </div>
        </div>
        <button>로그인</button>
      </div>
    </div>
  );
};

export default AnnualFeeLogin;
