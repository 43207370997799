import API from '../../util/api';

export default {
    getCompanyInvestmentInfo() {
        return API.get('/manager/company/investment');
    },
    updateInvestment(payload) {
        return API.post('/manager/company/investment', payload);
    },
    getInvestorList(payload) {
        return API.get('/manager/company/investor', {params: payload});
    },
    getCoreFileList() {
        return API.get('/manager/company/core');
    },
    uploadCoreFile(file) {
        return API.post('/manager/company/core', file);
    },
    downloadCoreFile(core_idx) {
        window.$Global.download(`/manager/company/core/${core_idx}/download`);
    },
    deleteCoreFile(core_idx) {
        return API.delete(`/manager/company/core/${core_idx}`);
    },
    getBusinessFileList() {
        return API.get('/manager/company/business');
    },
    uploadBusinessFile(file) {
        return API.post('/manager/company/business', file);
    },
    downloadBusinessFile(business_idx) {
        window.$Global.download(`/manager/company/business/${business_idx}/download`);
    },
    deleteBusinessFile(business_idx) {
        return API.delete(`/manager/company/business/${business_idx}`);
    },
    getIntroduceFileList() {
        return API.get('/manager/company/introduce');
    },
    uploaIntroduceFile(file) {
        return API.post('/manager/company/introduce', file);
    },
    downloadIntroduceFile(introduce_idx) {
        window.$Global.download(`/manager/company/introduce/${introduce_idx}/download`);
    },
    deleteIntroduceFile(introduce_idx) {
        return API.delete(`/manager/company/introduce/${introduce_idx}`);
    },
    getInvestorFileList(investor_idx) {
        return API.get(`/manager/company/investor/${investor_idx}/introduce`);
    },
    downloadInvestorFile(investor_idx, introduce_idx) {
        window.$Global.download(`/manager/company/investor/${investor_idx}/introduce/${introduce_idx}/download`);
    },
};
