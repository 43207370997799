import "./css/CompetitorStrategyPop.scss";

const CompetitorStrategyPop = ({
  data,
  search,
  close,
  handleCheckboxChange,
}) => {
  return (
    <div id="CompetitorStrategyPop">
      <div className="btn">
        <strong>12대 국가전략기술</strong>
        <button onClick={() => close(false)}>
          <i className="icon_delete"></i>
        </button>
      </div>

      {data.map((item) => (
        <div key={item.cd}>
          <input
            type="checkbox"
            name="strategy"
            value={item.name}
            checked={item.selected}
            onChange={() => handleCheckboxChange(item.cd)}
          />
          <span>{item.name}</span>
        </div>
      ))}
      {/* <button onClick={search} className="set">
        조건설정
      </button> */}
    </div>
  );
};

export default CompetitorStrategyPop;
