import React from "react";
import Table from "./Table";
import "./css/UserManage.scss";

function TableList({
  type_arr,
  accountAddAPI,
  accountDeleteAPI,
  accountUpdateAPI,
  companyCodeAddAPI,
  requestInvite,
}) {
  const onClickAdd = (type) => {
    let data = {};
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        for (var key in type_arr[i].props.thead) {
          if (key === "idx") {
            data[key] = 0;
            continue;
          }
          data[key] = "";
        }
        type_arr[i].set_props({
          ...type_arr[i].props,
          ...type_arr[i].props.tbody.push({ type: "new", ...data }),
        });
      }
    }
  };

  const onClickAddComplete = async (type, account) => {
    let data = {};
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        for (var key in type_arr[i].props.thead) {
          // 임시
          if (key === "idx") {
            data[key] = type_arr[i].props.tbody.length + 1;
            continue;
          }
          data[key] = account[key];
        }
        await accountAddAPI(data, type_arr[i].props.rank).then((res) => {
          deleteWithArrItemType(type_arr[i].props.tbody);
          data.idx = res.data.member_idx;
          type_arr[i].set_props({
            ...type_arr[i].props,
            ...type_arr[i].props.tbody.push({ type: "account", ...data }),
          });
        });
      }
    }
    return Promise.resolve(true);
  };

  const onClickEdit = (type, idx) => {
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        let account = findArrItem(type_arr[i].props.tbody, "idx", idx);
        trArrElementChange(
          type_arr[i].props.tbody,
          "idx",
          idx,
          account,
          "edit"
        );
        type_arr[i].set_props({ ...type_arr[i].props });
      }
    }
  };

  const onClickEditCancel = (type, idx) => {
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        let account = findArrItem(type_arr[i].props.tbody, "idx", idx);
        trArrElementChange(
          type_arr[i].props.tbody,
          "idx",
          idx,
          account,
          "account"
        );
        type_arr[i].set_props({ ...type_arr[i].props });
      }
    }
  };

  const onClickEditComplete = async (type, account) => {
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        await accountUpdateAPI(account, type_arr[i].props.rank).then(() => {
          trArrElementChange(
            type_arr[i].props.tbody,
            "idx",
            account.idx,
            account,
            "account"
          );
          type_arr[i].set_props({ ...type_arr[i].props });
        });
      }
    }
  };

  const onClickDelete = async (type, company_idx, idx) => {
    for (var i = 0; i < type_arr.length; i++) {
      if (type === type_arr[i].type) {
        // 기존 계정 삭제
        if (company_idx !== 0 || idx !== 0) {
          await accountDeleteAPI(idx, company_idx, type_arr[i].props.rank).then(
            () => {
              deleteWithArrItemIdx(
                type_arr[i].props.tbody,
                company_idx,
                idx,
                type
              );
            }
          );
          // 입력창 삭제
        } else {
          deleteWithArrItemType(type_arr[i].props.tbody);
        }
        type_arr[i].set_props({ ...type_arr[i].props });
      }
    }
  };

  const deleteWithArrItemIdx = (arr, company_idx, idx, type) => {
    let item_idx;
    if (type === "agent")
      item_idx = findArrItemIdx(arr, "company_idx", company_idx);
    else if (type === "manage") {
      item_idx = findArrItemIdx(arr, "idx", idx);
    }
    arr.splice(item_idx, 1);
  };

  const deleteWithArrItemType = (arr) => {
    let item_idx = findArrItemIdx(arr, "type", "new");
    arr.splice(item_idx, 1);
  };

  const findArrItem = (arr, arr_condition, condition) => {
    return arr.find((item) => {
      return item[arr_condition] === condition;
    });
  };

  const findArrItemIdx = (arr, arr_condition, condition) => {
    let findItem = arr.find((item) => {
      return item[arr_condition] === condition;
    });
    return arr.indexOf(findItem);
  };

  const trArrElementChange = (arr, arr_condition, condition, account, type) => {
    let changeIdx = findArrItemIdx(arr, arr_condition, condition);

    for (var key in account) {
      if (key === "type") {
        arr[changeIdx]["type"] = type;
        continue;
      }
      arr[changeIdx][key] = account[key];
    }
  };

  let table_tag = type_arr.map((item, idx) => (
    <Table
      key={idx}
      props={item.props}
      onClickAdd={onClickAdd}
      onClickAddComplete={onClickAddComplete}
      onClickEdit={onClickEdit}
      onClickEditComplete={onClickEditComplete}
      onClickEditCancle={onClickEditCancel}
      onClickDelete={onClickDelete}
      companyCodeAddAPI={companyCodeAddAPI}
      requestInvite={requestInvite}
    />
  ));

  return <div id="TableList">{table_tag}</div>;
}

export default TableList;
