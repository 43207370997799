import React from "react";

function WithdrawalInfo() {
    return (
        <div className="wrap_info">
            <h2 className="notify">
                출금 안내사항
                <i className="icon_alert_circle"/>
            </h2>
            <ul>
                <li>수익금은 회윈님의 은행계좌로 이체합니다.</li>
                <li>
                    최소 출금신청 가능한 금액은 5,000원으로 이체수수료 500원을 포함한 잔액이 5,500원 이상인 경우 신청 가능합니다.<br/>
                    예시) 수익금 잔액 : 6,000원 → 출금 가능금액 : 5,000원, 이체수수료 500원 ㅣ 출금 후 잔액 500원
                </li>
                <li><span>백원단위로</span> 신청이 가능합니다.</li>
                <li>
                    <span>신청금액이 25만원을 초과</span>하는 경우 소득세와 주민세(4.4%)가 포함된 금액이 수익금 계정에서 출금처리 됩니다.<br/>
                    출금신청 시 최초 1회에 한해 실명확인을 하고 있으며, 이후에는 개인정보를 수정할 수 없습니다.<br/>
                    단, 개명으로 인해 이름을 변경하고자 하실 경우 관리자에게 문의하세요.
                </li>
                <li>반드시 <span>본인명의 계좌</span>로 신청이 가능하며, 계좌번호를 잘못 입력하여 출금된 경우 사이버마케터에서는 책임지지 않습니다.</li>
                <li>
                    <span>신청한 다음 영업일</span> 회원님의 은행계좌에 입금해드립니다.<br/>
                    (은행 영업일 기준)
                </li>
            </ul>
        </div>
    )
}

export default WithdrawalInfo;