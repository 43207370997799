import React, { useEffect, useRef, useState } from "react";
import "./css/AdminServicePosting.scss";
import SunEditor from "suneditor-react";
import AdminAPI from "../../API/admin";
import commonFunc from "./commonFunc/common";
import qs from "query-string";
import _ from "lodash";

let { kakao } = window;
function AdminServicePosting({ history, location }) {
  const { idx, type, category } = qs.parse(location.search);
  const isView = type == "view";
  const isEdit = type == "edit";
  const editorRef = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
  const [postInput, setPostInput] = useState({
    file: [],
    fileView: [],
    preview: null,
    filePreview: [],
    title: "",
    category: 3,
    keyword: "",
    deadline_at: new Date().toISOString().slice(0, -8),
    url: "",
    location: "",
    cost: "",
    time_detail: "",
    start_at: "", // 시작일
    link_url: "", // 링크 url
    host: "", // 주최
    introduce: "", // 소개글
    lat: "", // 위도
    lon: "", // 경도
  });
  const [content, setContent] = useState("");

  const isTypeContest = postInput.category == 3; // 경진대회
  const isTypeBiz = postInput.category == 4; // 사업설명회
  const isTypeGov = postInput.category == 5; // 정부 지원
  const path = "/admin/service";
  const pathList = `${path}/list?category=${category}`;
  const commonParam = `${location.pathname}?idx=${idx}&category=${category}`;
  const drawMap = Boolean(postInput.lat && postInput.lon);
  const costStr = !postInput.cost
    ? "무료"
    : isNaN(postInput.cost)
    ? postInput.cost
    : window.$Global.commaify(postInput.cost);

  useEffect(() => {
    if (idx) {
      AdminAPI.getCommunityInfo(idx).then((res) => {
        let data = res.data;
        let copy = _.cloneDeep(data);
        copy.file = [];
        copy.fileView = data.files;
        copy.filePreview = data.preview;
        setPostInput(copy);
        setContent(data.content);

        if (isTypeContest && data.lat && data.lon) {
          let container = document.getElementById("map");
          let options = {
            center: new kakao.maps.LatLng(data.lat, data.lon),
            level: 3,
          };
          let map = new kakao.maps.Map(container, options);
          // 마커를 생성합니다
          let marker = new kakao.maps.Marker({
            // 마커가 표시될 위치입니다
            position: options.center,
          });

          // 마커가 지도 위에 표시되도록 설정합니다
          marker.setMap(map);
        }
      });
    }
    commonFunc.convertServiceCategory().then((res) => setCategoryList(res));
  }, [type]);

  const onClickEditGo = () => {
    history.push(`${commonParam}&type=edit`);
  };

  const addLocation = async (formData) => {
    if (isTypeContest) {
      let result = await window.$Global
        .addressSearch(postInput.address)
        .then((res) => res)
        .catch((err) => err);
      if (result === "NO DATA") return false;

      formData.append("lat", result.lat);
      formData.append("lon", result.lon);

      return true;
    }
  };

  const onClickEdit = async () => {
    await onClickSuccess(true);
  };

  const onClickPost = async () => {
    await onClickSuccess(false);
  };

  const onClickSuccess = async (edit) => {
    let formData = await parseFormData();

    if (isTypeContest) {
      let result = await addLocation(formData);

      if (!result) return;
    }

    if (isTypeBiz && postInput.url.length == 0) {
      alert("유튜브 링크를 입력해주세요");
      return;
    }

    if (edit) {
      await AdminAPI.updateNotify(idx, formData);
      if (postInput.file.length) await AdminAPI.uploadNotifyFile(idx, formData);
      alert("수정되었습니다");
      history.push(`${commonParam}&type=view`);
    } else {
      await AdminAPI.addNotify(formData).then(() => {
        alert("등록되었습니다");
        history.push(pathList);
      });
    }
  };

  const parseContent = async () => {
    let parse_result = content || "";

    let src = parse_result.match(
      new RegExp('src=\\"data:image\\/([a-zA-Z]*);base64,([^\\"]*)\\"', "g")
    );
    if (src != null) {
      for (let i = 0; i < src.length; i++) {
        let data_uri = src[i].replace('src="', "");
        if (data_uri.substr(0, 5) == "https") {
          continue;
        }
        let dataUri = data_uri.replace('"', "");
        let imgUrl = window.$Global.getCDN(
          await saveFile(window.$Global.dataURItoBlob(dataUri))
        );
        parse_result = parse_result.replace(dataUri, imgUrl);
      }
    }

    return parse_result;
  };

  const parseFormData = async () => {
    let formData = new FormData();

    postInput.file.forEach((item) => {
      formData.append("file", item);
    });

    let keys = Object.keys(postInput);

    keys.forEach((item) => {
      if (
        item !== "content" &&
        item !== "deadline_at" &&
        item !== "start_at" &&
        item !== "lat" &&
        item !== "lon"
      ) {
        formData.append(item, postInput[item]);
      }
    });
    formData.append("content", await parseContent());
    formData.append("deadline_at", new Date(postInput.deadline_at).getTime());
    formData.append("start_at", new Date(postInput.start_at).getTime());

    return formData;
  };

  const saveFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return await AdminAPI.uploadImage(formData).then(
      (res) => res.data[0].file_key
    );
  };

  const onClickDeleteFile = (depth, file_idx) => {
    AdminAPI.deleteNotifyFile(idx, file_idx).then(() => {
      let copy = _.cloneDeep(postInput);
      copy.fileView.splice(depth, 1);
      setPostInput(copy);
    });
  };

  return (
    <div id="AdminServicePosting">
      <h2 className="page_title">서비스 게시글 등록</h2>
      <div className="articles">
        <div className="field">
          <span>제목</span>
          {isView ? (
            <p>{postInput.title}</p>
          ) : (
            <input
              type="text"
              value={postInput.title}
              onChange={(e) =>
                setPostInput({ ...postInput, title: e.target.value })
              }
              placeholder="제목"
            />
          )}
        </div>
        <div className="field">
          <span>카테고리</span>
          {isView ? (
            <p>{categoryList[postInput.category - 3]?.name}</p>
          ) : (
            <select
              value={postInput.category || 3}
              onChange={(e) =>
                setPostInput({ ...postInput, category: Number(e.target.value) })
              }
            >
              {categoryList.map((item, idx) => {
                return (
                  <option key={idx} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <div className="field">
          <span>키워드</span>
          {isView ? (
            <p>{postInput.keyword}</p>
          ) : (
            <input
              type="text"
              value={postInput.keyword}
              onChange={(e) =>
                setPostInput({ ...postInput, keyword: e.target.value })
              }
            />
          )}
        </div>

        {isTypeGov && (
          <>
            <div className="field">
              <span>첨부파일</span>
              {isView ? (
                <div>
                  {postInput.fileView.length ? (
                    postInput.fileView.map((item, idx) => {
                      return (
                        <p key={idx}>
                          {item.file_name + "." + item.file_type}
                          <button
                            className="icon_badge_del"
                            onClick={() => onClickDeleteFile(idx, item.idx)}
                          />
                        </p>
                      );
                    })
                  ) : (
                    <p>첨부파일 없음</p>
                  )}
                </div>
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setPostInput({ ...postInput, file: e.target.files })
                  }
                  multiple
                />
              )}
            </div>
            <div className="field">
              <span>
                PDF <br />
                프리뷰어
                <br />
                업로드
              </span>
              {isView ? (
                <div>
                  {postInput.filePreview.length ? (
                    <p>PDF 업로드됨</p>
                  ) : (
                    <p>PDF 없음</p>
                  )}
                </div>
              ) : (
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) =>
                    setPostInput({ ...postInput, preview: e.target.files[0] })
                  }
                />
              )}
            </div>
          </>
        )}

        {isTypeContest && (
          <>
            <div className="field">
              <span>장소</span>
              {isView ? (
                <p>{postInput.location}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.location}
                  onChange={(e) =>
                    setPostInput({ ...postInput, location: e.target.value })
                  }
                />
              )}
            </div>

            <div className="field">
              <span>주소</span>
              {isView ? (
                <p>{postInput.address}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.address}
                  onChange={(e) =>
                    setPostInput({ ...postInput, address: e.target.value })
                  }
                  placeholder="카카오 맵을 보여주고 싶은 경우 해당 주소를 입력해주세요"
                />
              )}
            </div>
            <div className="field">
              <span>{isTypeContest ? "신청일" : "시작일"}</span>
              {isView ? (
                <p>{window.$Global.convertDate(postInput.start_at)}</p>
              ) : (
                <input
                  type="date"
                  value={
                    isNaN(postInput.start_at)
                      ? postInput.start_at
                      : window.$Global.convertDate(postInput.start_at)
                  }
                  onChange={(e) =>
                    setPostInput({ ...postInput, start_at: e.target.value })
                  }
                />
              )}
            </div>
            <div className="field">
              <span>일시</span>
              {isView ? (
                <p>{postInput.time_detail}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.time_detail}
                  onChange={(e) =>
                    setPostInput({ ...postInput, time_detail: e.target.value })
                  }
                />
              )}
            </div>
            <div className="field">
              <span>비용</span>
              {isView ? (
                <p>{costStr}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.cost}
                  onChange={(e) =>
                    setPostInput({ ...postInput, cost: e.target.value })
                  }
                />
              )}
            </div>
            <div className="field">
              <span>주최</span>
              {isView ? (
                <p>{postInput.host}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.host}
                  onChange={(e) =>
                    setPostInput({ ...postInput, host: e.target.value })
                  }
                />
              )}
            </div>
            <div className="field">
              <span>소개글</span>
              {isView ? (
                <p>{postInput.introduce}</p>
              ) : (
                <input
                  type="text"
                  value={postInput.introduce}
                  onChange={(e) =>
                    setPostInput({ ...postInput, introduce: e.target.value })
                  }
                />
              )}
            </div>
            {drawMap && (
              <div
                className="field"
                style={{ display: isView ? "block" : "none" }}
              >
                <div id="map" style={{ width: "100%", height: 500 }} />
              </div>
            )}
          </>
        )}
        {!isTypeBiz && (
          <div className="field">
            <span>마감일</span>
            {isView ? (
              <p>{window.$Global.convertDateTime(postInput.deadline_at)}</p>
            ) : (
              <input
                type="datetime-local"
                value={window.$Global.covertLocalDateTime(
                  postInput.deadline_at
                )}
                onChange={(e) =>
                  setPostInput({ ...postInput, deadline_at: e.target.value })
                }
              />
            )}
          </div>
        )}
        {isTypeBiz && (
          <div className="field">
            <span>유튜브</span>
            {isView ? (
              <p>{postInput.url}</p>
            ) : (
              <input
                type="text"
                value={postInput.url}
                onChange={(e) =>
                  setPostInput({ ...postInput, url: e.target.value })
                }
              />
            )}
          </div>
        )}

        <div className="content">
          {isView ? (
            <p
              className="sun-editor-editable"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            <SunEditor
              lang="ko"
              ref={editorRef}
              placeholder="본문에 등록한 첫번째 이미지가 썸네일 이미지로 사용됩니다"
              setContents={content}
              onChange={(e) => setContent(e)}
              setOptions={{
                height: 400,
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat",
                  ],
                  [
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "table",
                  ],
                  [
                    "link",
                    "image",
                    "video",
                    "fullScreen",
                    "codeView",
                    "preview",
                    "print",
                  ],
                  // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
              }}
            />
          )}
        </div>
      </div>

      <div className="btns">
        {isView ? (
          <button className="btn_post" onClick={onClickEditGo}>
            수정
          </button>
        ) : isEdit ? (
          <button className="btn_post" onClick={onClickEdit}>
            수정 완료
          </button>
        ) : (
          <button className="btn_post" onClick={onClickPost}>
            등록
          </button>
        )}
        <button className="btn_list" onClick={() => history.push(pathList)}>
          목록
        </button>
      </div>
    </div>
  );
}

export default AdminServicePosting;
