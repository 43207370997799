import React, {useEffect, useState} from "react"
import "../css/AdminStatistic.scss";
import AdminAPI from "../../../API/admin";

function AdminStatistic() {
    let params = {
        page: 1,
        count: 1000
    }
    const [resData, setResData] = useState({});

    useEffect(() => {
        AdminAPI.getDailyStatistic(params).then(res => {
            console.log(res.data);
            setResData(res.data);
        });
    }, []);

    if (window.$Global.isEmptyObject(resData)) return null;

    const keys = Object.keys(resData);

    return (
        <div id="AdminStatistic">
            <h2 className="title">트래킹</h2>

            <table>
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>방문자 수</th>
                        <th>누적 방문 횟수</th>
                        <th>방문 횟수 (회사 데이터 포함)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        keys.reverse().map(item => {
                            return (
                                <tr key={item}>
                                    <td>{item}</td>
                                    <td>{resData[item].visit_member_cnt}</td>
                                    <td>{resData[item].visit_total_cnt}</td>
                                    <td>{resData[item].visit_total_cnt_all}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default AdminStatistic;