import React from "react";

function OneInRow({title, value, className}) {
    return (
        <div className={`row ${className || ''}`}>
            <h2 className="title">{title}</h2>
            <p className="value">{value || 'N/A'}</p>
        </div>
    );
}

export default OneInRow;
