import React from "react";
import {Route} from "react-router-dom";
import NotifyListView from "../components/notify/NotifyListView";
import NotifyDetail from "../components/notify/NotifyDetail";
import CustomSwitch from "./common/CustomSwitch";

function NotifyRoute({match}) {
	return (
		<CustomSwitch>
			<Route exact path={`${match.path}/list`} component={NotifyListView}/>
			<Route exact path={`${match.path}/detail/:idx`} component={NotifyDetail}/>
		</CustomSwitch>
	)
}

export default NotifyRoute;