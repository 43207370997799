import React, { useContext, useState, useEffect, useRef } from "react";
import UserImg from "../../assets/images/chat/icon-user-example.png";
import AdminImg from "../../assets/images/common/m_logo.svg";

function UserChatAdmin() {
  const toggleFnc = (t) => {
    if (t.target.classList.contains("on")) {
      t.target.classList.remove("on");
    } else {
      t.target.classList.add("on");
    }
  };

  return (
    <div id="ChatAdmin">
      <div className="pc">
        <div className="chatAdmin">
          <div className="chatAdmin_title">
            <div className="chatAdmin_title_name">
              <h2 className="chatAdmin_title_head">고객문의 - 채팅서비스</h2>
              <button className="chatAdmin_title_button" type="button">
                설&nbsp;&nbsp;정
              </button>
            </div>
            <p className="chatAdmin_title_text">
              응대 상담 총 8건 / 미확인 메세지 4건
            </p>
            <div className="chatAdmin_title_search-wrap">
              <div className="chatAdmin_title_search">
                <select className="chatAdmin_title_search_select">
                  <option value="" selected>
                    검색항목
                  </option>
                  <option value="s1">검색항목1</option>
                  <option value="s2">검색항목2</option>
                  <option value="s3">검색항목3</option>
                </select>
                <label htmlFor="searchVal" className="hidden">
                  검색할 내용을 입력하세요
                </label>
                <input
                  type="search"
                  name="searchVal"
                  id="searchVal"
                  className="chatAdmin_title_search_input"
                  placeholder="검색값"
                />
              </div>
              <button type="button" className="chatAdmin_title_search_button">
                검색
              </button>
            </div>
          </div>
          <div className="chatAdmin_chat">
            <div className="chatAdmin_chat_info box-style">
              <div className="chatAdmin_chat_info_top">
                <div className="top-utill">
                  <button className="top-utill_standard" type="button">
                    최신순
                  </button>
                  <button className="top-utill_notread">안읽은메세지</button>
                </div>
                <button
                  className="top-button icon_more_dot"
                  type="button"
                ></button>
              </div>
              <ul className="chatAdmin_chat_info_content custom_scroll">
                <li className="oncall depth1">
                  <button
                    type="button"
                    className="depth1_title on"
                    onClick={toggleFnc}
                  >
                    담당자 대기중
                  </button>
                  <ul className="depth2-wrap">
                    <li className="depth2 numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            비회원 USER 12
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">999+</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <li className="depth2"> */}
                    <li className="depth2 admin-style numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">2</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="respond depth1 on">
                  <button
                    type="button"
                    className="depth1_title on"
                    onClick={toggleFnc}
                  >
                    응대 중
                  </button>
                  <ul className="depth2-wrap">
                    {/* <li className="depth2"> */}
                    <li className="depth2 numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            비회원 USER 12
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">999+</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <li className="depth2"> */}
                    <li className="depth2 admin-style numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">2</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="complete depth1">
                  <button
                    type="button"
                    className="depth1_title"
                    onClick={toggleFnc}
                  >
                    대화 완료 10건
                  </button>
                  <ul className="depth2-wrap">
                    {/* <li className="depth2"> */}
                    <li className="depth2 numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            비회원 USER 12
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">999+</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <li className="depth2"> */}
                    <li className="depth2 admin-style numtext-style">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num">
                            <span className="num-style">2</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                    <li className="depth2">
                      <div className="depth2_img">
                        <img
                          className="depth2_img-content"
                          src={UserImg}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="depth2_chat">
                        <div className="depth2_chat_title-wrap">
                          <div className="depth2_chat_title">
                            김관리자 /관리자/kmuu012
                          </div>
                          <div className="depth2_chat_time">10:31AM</div>
                        </div>
                        <div className="depth2_chat_text-wrap">
                          <div className="depth2_chat_text">
                            국무총리·국무위원 또는 정부위원은 국회나 그 위원회에
                            출석하여 국정처리상황을 보고하거나 의견을 진술하고
                          </div>
                          <div className="depth2_chat_num"></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="chatAdmin_chat_talk box-style">
              <div className="chatAdmin_chat_talk_top">
                <h3 className="chatAdmin_chat_talk_top_title">지난 상담</h3>
                <span className="chatAdmin_chat_talk_top_date">
                  2020-09-02 PM 10:00
                </span>
              </div>
              <div className="chatAdmin_chat_talk_content custom_scroll">
                <div className="chat">
                  <div className="chat_log my_chat">
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log">
                    <div className="sender_info">
                      <p>김관리자 님</p>
                    </div>
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log my_chat">
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log">
                    <div className="sender_info">
                      <p>김관리자 님</p>
                    </div>
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log my_chat">
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log">
                    <div className="sender_info">
                      <p>김관리자 님</p>
                    </div>
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log my_chat">
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_log">
                    <div className="sender_info">
                      <p>김관리자 님</p>
                    </div>
                    <div className="message">
                      히스토리와 이슈를 한번에 파악하여 협업할 수 있는
                      티켓기능을 활용해 보세요. 중요도 설정과 검색기능까지
                      제공하여 내부데이터를 공유하는 것이 쉬워지고 팀 내부의
                      빠른 의사결정과 원활한 소통이 가능해집니다
                    </div>
                    <div className="time">3:56PM</div>
                  </div>
                  <div className="chat_end">
                    <div className="chat_end_title">상담이 종료되었습니다.</div>
                    <div className="chat_end_date">2020-09-02 PM 10:00</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chatAdmin_chat_client">
              <div className="chatAdmin_chat_client_info box-style">
                <ul className="chatAdmin_chat_client_info_list-wrap">
                  <li className="chatAdmin_chat_client_info_title-wrap">
                    <h3 className="title">고객정보</h3>
                    <button className="icon_more_dot" type="button"></button>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">닉네임</div>
                    <div className="info">김관리자 님</div>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">권&nbsp;&nbsp;한</div>
                    <div className="info">관리자</div>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">회 사 명</div>
                    <div className="info">아이티엘</div>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">직통번호</div>
                    <div className="info">02-1234-5678</div>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">이메일</div>
                    <div className="info">kimmanager@test.com</div>
                  </li>
                </ul>
                <ul className="chatAdmin_chat_client_info_list-wrap">
                  <li className="chatAdmin_chat_client_info_title-wrap">
                    <h3 className="title">상담정보</h3>
                    <button className="icon_more_dot" type="button"></button>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">담당자</div>
                    <div className="info">관리자 1</div>
                  </li>
                  <li className="chatAdmin_chat_client_info_list">
                    <div className="title">카테고리</div>
                    <div className="info">세금계산서</div>
                  </li>
                </ul>
              </div>
              <div className="chatAdmin_chat_client_pay box-style">
                <div
                  className="chatAdmin_chat_client_pay_top on"
                  onClick={toggleFnc}
                >
                  <h3 className="title">대화 완료 3건</h3>
                  <button type="button" className="icon_more_black"></button>
                </div>
                <div className="chatAdmin_chat_client_pay_content custom_scroll">
                  <ul className="chatAdmin_chat_client_pay_content_list-wrap">
                    <li className="chatAdmin_chat_client_pay_content_list">
                      <div className="title">
                        2.결제 및 계산서 문의&#62;4.세금계산서
                      </div>
                      <div className="info">
                        <span className="info_charge">담당자 : admin0821</span>
                        <span className="info_date">2020-09-02 PM 10:00</span>
                      </div>
                    </li>
                    <li className="chatAdmin_chat_client_pay_content_list">
                      <div className="title">3.서비스문의&#62;1.상담톡</div>
                      <div className="info">
                        <span className="info_charge">담당자 : admin0821</span>
                        <span className="info_date">2020-09-02 PM 10:00</span>
                      </div>
                    </li>
                    <li className="chatAdmin_chat_client_pay_content_list admin-style">
                      <div className="title">1.가입문의&#62;3.상담콜</div>
                      <div className="info">
                        <span className="info_charge">담당자 : admin0821</span>
                        <span className="info_date">2020-09-02 PM 10:00</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserChatAdmin;
