import React from 'react';

function InputAndFileRow({title, titleClassName, type, condition, content, onChange, onClickDownPopShow, onClickUpPopShow}) {

    let value = "";
    if (content) {
        if (type == "number") {
            value = window.$Global.commaify(content);
        }
    } else {
        value = "N/A";
    }

    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                {
                    condition
                    ? <input type={type ? type : "text"} value={content || ""} onChange={onChange}/>
                    : <p>{value}</p>
                }
                <button className="icon_download" onClick={onClickDownPopShow}/>
                <button className="icon_upload" onClick={onClickUpPopShow}/>
            </div>
        </div>
    );
}

export default InputAndFileRow;
