import React from "react";
import InputId from "../inputCommon/InputId";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import InputEmail from "../inputCommon/InputEmail";
import InputPhone from "../inputCommon/InputPhone";
import InputCustom from "../inputCommon/InputCustom";

function SignupInputPersonal({
  keywordList,
  commonPwdTag,
  optionPersonal,
  setOptionPersonal,
  checkId,
  checkEmail,
  checkPhone,
  signInfo,
  setSignInfo,
  onChangeFindCompany,
  onClickCheckId,
  onClickCheckEmail,
  onClickCertification,
}) {
  const able = optionPersonal;
  const props_common = {
    able,
    signInfo,
    setSignInfo,
  };
  return (
    <>
      <div className="box">
        <div className="wrap_field">
          <InputId
            able={true}
            check={checkId}
            onClick={onClickCheckId}
            signInfo={signInfo}
            setSignInfo={setSignInfo}
          />
        </div>
        <div className="wrap_field">{commonPwdTag}</div>
        <div className="wrap_field" style={{ marginTop: 20 }}>
          <InputEmail
            able={true}
            check={checkEmail}
            onClick={onClickCheckEmail}
            required={true}
            signInfo={signInfo}
            setSignInfo={setSignInfo}
          />
          <InputPhone
            able={true}
            check={checkPhone}
            onClick={onClickCertification}
            required={true}
            signInfo={signInfo}
            setSignInfo={setSignInfo}
          />
        </div>
      </div>
      <div className="wrap_option">
        <div className="head">
          <h2>
            <i className="icon_electric_bulb" />
            IP 관리 서비스를 함께 이용하려면, 추가 정보를 입력해 주세요.
          </h2>
          <ToggleButtonWithSpan
            text="이용하기"
            condition={{ on: "ON", off: "OFF" }}
            selected={optionPersonal}
            toggleSelected={() => setOptionPersonal(!optionPersonal)}
          />
        </div>
        <p className="desc">
          아래의 정보 미입력 후 가입했을 경우 BizNavi만 우선 이용이 가능하고 IP
          관리 서비스는 추후 마이페이지에서 추가 정보 입력 후 이용이 가능합니다.
        </p>
        <div className="wrap_field" style={{ marginTop: 20 }}>
          <div className={`field ${able ? "" : "action_no"}`}>
            <div className="f_head">
              <i className="icon_red_dot" />
              이름
            </div>
            <div className="f_content">
              <input
                type="text"
                value={signInfo.name}
                placeholder="검색하세요"
                onChange={(e) => onChangeFindCompany(e.target.value, "name")}
              />
              {keywordList}
            </div>
          </div>
          <InputCustom
            title="출원인 등록번호"
            objKey="personal_number"
            {...props_common}
          />
        </div>
      </div>
    </>
  );
}

export default SignupInputPersonal;
