import { Link } from "react-router-dom/cjs/react-router-dom";
import "./css/GovProjectBySubject.scss";

const GovProjectBySubject = () => {
  return (
    <div id="GovProjectBySubject">
      <h2 className="work_tit">주제별 정부사업</h2>
      <div className="subject_list">
        <ul>
          <li>
            <Link to='/taskManage/part/list?keyword=2'>기술개발<br/>지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=256'>창업 지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=8'>자금 지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=1024'>인프라 지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=64'>판로-수출<br/>지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=512'>인력 양성사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=2048'>바우처<br/>지원사업</Link>
          </li>
          <li>
            <Link to='/taskManage/part/list?keyword=128'>기타 지원사업</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default GovProjectBySubject;