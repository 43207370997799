export default {
  successSave: "저장되었습니다",
  successSignup: "회원가입이 완료되었습니다",
  successCertified: "정상적으로 인증되었습니다",
  errorRegxId: "아이디 형식이 올바르지 않습니다",
  errorRegxEmail: "이메일 형식이 올바르지 않습니다",
  errorRequirePhone: "휴대폰 번호 11자리를 입력해주세요",
  errorNotEqualsPassword: "비밀번호가 일치하지 않습니다",
  whatSearchBiz: "어떤 사업 찾고 계세요?",
  errorChoice: "선택한 항목이 없습니다",
  errorChoiceApproval: "결재자를 추가해주세요",
  errorLengthOver: "최대 선택 가능 갯수를 초과하였습니다",
  errorEmailRegx: "이메일 형식이 올바르지 않습니다",
  confirmApproval: "결재하시겠습니까?",
  confirmApprovalReject: "반려하시겠습니까?",
  successApproval: "상신되었습니다",
  confirmDelete: "삭제하시겠습니까?",
  requireLogin: "로그인이 필요한 기능입니다",
  lengthOver: "최대 입력 길이를 초과하였습니다",
};
