import React from "react";
import "./css/DetailPopupDownload.scss";

function DetailPopupDownload({
  show,
  setShow,
  fileViewList,
  setFileList,
  onClickDownload,
  onClickDelete,
  onClickClose,
  deleteHide,
  setFileImage,
}) {
  let files = fileViewList.map((file, index) => {
    return (
      <tr key={`${file.idx}_down`}>
        <td>{index + 1}</td>
        <td>{`${file.file_name}.${file.file_type}`}</td>
        <td>{window.$Global.convertDate(file.created_at)}</td>
        <td>
          <button
            className="icon_download"
            onClick={() => onClickDownload(file.idx)}
          />
        </td>
        {deleteHide ? null : (
          <td>
            <button
              className="btn_delete"
              onClick={() => {
                onClickDelete(file.idx);
                setFileImage("");
              }}
            >
              삭제하기
            </button>
          </td>
        )}
      </tr>
    );
  });

  const onClickHide = () => {
    setShow(false);
    setFileList([]);
  };

  return (
    <div id="DetailDownloadPopup" style={show ? {} : { display: "none" }}>
      <h2 className="title">다운로드</h2>
      <button className="icon_exit" onClick={onClickClose || onClickHide} />
      <div className="wrap_table">
        <table>
          <thead>
            <tr>
              <th width="8%">순번</th>
              <th>파일명</th>
              <th width="15%">업로드 날짜</th>
              <th width="10%">다운로드</th>
              {deleteHide ? null : <th width="10%">삭제하기</th>}
            </tr>
          </thead>
          <tbody>{files}</tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailPopupDownload;
