import "./css/AnnualFeeReviewState.scss";

const AnnualFeeReviewState = ({ type, checkYear }) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div
      id="AnnualFeeReviewState"
      className={`state_${type === 0 ? "incomplete" : "complete"}${
        year === checkYear ? " spanHighlight" : ""
      }`}
    >
      <span>{type === 0 ? "검토하기" : "검토완료"}</span>
    </div>
  );
};

export default AnnualFeeReviewState;
