import React, {useEffect, useState} from "react";
import "./css/MatchingBizMyList.scss";
import CommonAPI from "../../API/common"
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";

function MatchingBizMyList({history}) {
    const [resData, setResData] = useState({items: []});
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        let payload = {
            show_type: 3,
            page: curPage,
            count: 50
        };

        CommonAPI.getTaskList(payload).then(res => {
            setCurPage(curPage + 1);
            let data = res.data;
            if (data.page == data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }

            if (data.page > 1) {
                let copy = _.cloneDeep(resData);
                data.items = copy.items.concat(data.items);
            }
            setResData(data);
        });
    };

    const nextList = () => {
        getList();
    };

    const goDetailPage = (idx) => {
        history.push(`/matchingBiz/detail/${idx}`);
    }

    return (
        <div id="MatchingBizMyList">
            <div className="header">
                <h2>등록한 매칭 비즈 목록</h2>
            </div>
            <div className="table-box-wrap">
                <div className="wrap_list" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={resData.items.length}
                        next={nextList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table className="custom_tr_hover">
                            <thead>
                                <tr>
                                    <th>순번</th>
                                    <th>사업명</th>
                                    <th>금액</th>
                                    <th>등록일</th>
                                    <th>조회수</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Boolean(resData.items.length)
                                    ?
                                    resData.items.map((item, idx) => {
                                        return (
                                            <tr key={item.idx} onClick={() => goDetailPage(item.idx)}>
                                                <td>{resData.total_count - idx}</td>
                                                <td>{item.title}</td>
                                                <td>{window.$Global.commaify(item.amount)}</td>
                                                <td>{window.$Global.convertDate(item.announcement_at)}</td>
                                                <td>{item.hit}</td>
                                            </tr>
                                        )
                                    })
                                    : window.$Global.notTd(5, "등록한 매칭 비즈가 없습니다")
                                }
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

export default MatchingBizMyList;