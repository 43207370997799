import "./css/PlanInfo.scss";

const PlanInfo = () => {
  return (
    <div id="PlanInfo">
      <div className="text_wrapper">
        <div className="big_text">
          <p>사업계획서 작성하기</p>
        </div>
        <div className="small_text">
          <p>
            비즈내비 사업계획서 작성하기의 장점은 기존정보를 불러와 중복입력의
            불편함 없이 간편하게 작성 할 수 있습니다.
            <br />
            비즈내비에서 사업계획서 작성을 해보세요, 실 제안서와 항목이 같고
            다운 후 카피하여 실 제안서에 반영 해 보세요.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlanInfo;
