import CompetitorStatisticList from "./CompetitorStatisticList";
import CompetitorStatisticSubject from "./CompetitorStatisticSubject";
import "./css/CompetitorStatistic.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CompetitorStatistic = () => {
  const location = useLocation();
  const rankList = [
    {
      title: "매출액 랭킹",
      company_list: [
        "한화에어로스페이스(주)",
        "애경케미칼(주)",
        "(주)다원시스",
        "(재)한국전기연구원",
        "성림첨단산업(주)",
        "(주)우신세이프티시스템",
        "(주)인텍플러스",
        "(주)디엔에프",
      ],
    },
    {
      title: "IP 보유건수 랭킹",
      company_list: [
        "한화에어로스페이스(주)",
        "한국전자기술연구원",
        "(재)한국전기연구원",
        "한국해양과학기술원",
        "한국동서발전(주)",
        "비나텍(주)",
        "(주)바이오니아",
        "(주)다원시스",
        "(주)인텍플러스",
      ],
    },
    {
      title: "기술평가 랭킹",
      company_list: [
        "(주)정석케미칼",
        "(주)에스오에스랩",
        "(주)리뉴시스템",
        "(주)바이오니아",
        "(주)인텍플러스",
        "(주)바이오제닉스",
        "(주)뉴로스",
        "(주)레고켐바이오사이언스",
      ],
    },
    {
      title: "정부사업참여건수 랭킹",
      company_list: [],
    },
  ];
  const targetList = ["특구사업"];
  const totalNum = location.state.totalNum;
  const statisticList = [
    { cd: 2, name: "기술 기준" },
    { cd: 1, name: "기업 기준" },
    { cd: 3, name: "재무 기준" },
    { cd: 4, name: "사업화 기준" },
  ];

  const rankItem = ({ rankInfo }) => {
    return (
      <li className="border_light_gray">
        <p className="tit">{rankInfo.title}</p>
        <ul className="comp_name_list">
          {rankInfo.company_list.length ? (
            rankInfo.company_list.map((item, idx) => (
              <li>
                <strong>{idx + 1}</strong>
                {item}
              </li>
            ))
          ) : (
            <li>서비스 개발중입니다.</li>
          )}
        </ul>
        {/* <div className="btn_box">
          <button className="more bright_blue">
            더보기<i className="icon_arrow_right_blue"></i>
          </button>
        </div> */}
      </li>
    );
  };

  return (
    <div id="CompetitorStatistic" className="CompetitorStatistic">
      <div className="center_content">
        <CompetitorStatisticSubject tagList={targetList} num={totalNum} />
        <div className="view pb20 mb20">
          <p className="sub_tit">기업 분석/통계보기</p>
          <CompetitorStatisticList
            statisticList={statisticList}
            num={totalNum}
          />
        </div>
        <div className="rank">
          <p className="sub_tit">랭킹보드</p>
          <ul className="rank_list">
            {rankList?.length
              ? rankList.map((rank) => rankItem({ rankInfo: rank }))
              : "데이터가 없습니다."}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompetitorStatistic;
