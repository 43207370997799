import React from "react";
import TaskMain from "../components/taskManage/TaskMain";
import UserAuthRoute from "./common/UserAuthRoute";
import TaskMyAddList from "../components/taskManage/TaskMyAddList";
import TaskNotifySetting from "../components/taskManage/TaskNotifySetting";
import MobilePopupAttach from "../components/common/MobilePopupAttach";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import { Route } from "react-router-dom";
import IntroService from "../components/intro/IntroService";
import IntroGovProject from "../components/intro/IntroGovProject";
import PartList from "../components/taskManage/PartList";

function TaskManageRoute({ match }) {
  return (
    <CustomSwitch>
      <Route exact path={`${match.path}/page`} component={IntroService} />
      <Route exact path={`${match.path}/page2`} component={IntroGovProject} />
      <Route exact path={`${match.path}/part/list`} component={PartList} />
      <CacheRoute
        exact
        path={`${match.path}/list`}
        component={TaskMain}
        when="always"
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my`}
        requireAuth={true}
        component={TaskMyAddList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/setting`}
        requireAuth={true}
        component={TaskNotifySetting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/pop`}
        requireAuth={true}
        component={MobilePopupAttach}
      />
    </CustomSwitch>
  );
}

export default TaskManageRoute;
