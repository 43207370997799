import React, { useContext, useEffect, useState } from "react";
import "../css/IntroFindBiz.scss";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import IntroTaskList from "../organisms/IntroTaskList";
import CommonContext from "../../../store/Common";
import CommonAPI from "../../../API/common";
import CountUp from "react-countup";
import IntroRedirectList from "../organisms/IntroRedirectList";
import message from "../../../const/message";
import IntroTaskCategoryTemp from "../organisms/IntroTaskCategoryTemp";
import IntroTaskCategory from "../organisms/IntroTaskCategory";
import { getKeywordToTaskURL } from "../js/getKeywordToTaskURL";
import links from "../../../const/links";
import TaskSearchIp from "../../taskManage/TaskSearchIp";
import quotationMark from "../../../assets/images/intro/icon_double_quotation_marks.svg";

function IntroFindBiz() {
  const history = useHistory();
  const { isMobile } = useContext(CommonContext);
  const [homeData, setHomeData] = useState({});
  const [keyword, setKeyword] = useState("");

  const SliderMobile = () => {
    return (
      <div className="review_list">
        <Slider {...review_settings}>
          <div className="review_item">
            <div className="tab_name">
              <span>빨라진 정부과제 검색</span>
            </div>
            <div className="review_content">
              <img src={quotationMark} alt="큰따옴표" />
              <p>
                <span>1500여 개 공공기관 사이트가</span>
                <br />
                <span>한 곳에</span> 모여 있어서 아주 편리합니다.
                <br />
                정부과제 업무시간을
                <br />
                80% 이상 단축할 수 있었습니다.
                <br />
              </p>
            </div>
            <div className="reviewer_info">
              <div className="inner">
                <p className="name">이○○님</p>
                <p className="grade">IT분야 중소기업 CEO</p>
              </div>
            </div>
          </div>
          <div className="review_item">
            <div className="tab_name">
              <span>쉬워진 제안서 업무</span>
            </div>
            <div className="review_content">
              <img src={quotationMark} alt="큰따옴표" />
              <p>
                어렵기만 했던
                <br />
                <span>시장조사와 선행기술조사</span>를<br />
                비즈내비에서 <span>쉽고 저렴하게</span>,<br />
                한 번에 해결했습니다.
                <br /> 중소기업을 위한 최고의 서비스
              </p>
            </div>
            <div className="reviewer_info">
              <div className="inner">
                <p className="name">박○○님</p>
                <p className="grade">제조분야 중소기업 팀장</p>
              </div>
            </div>
          </div>
          <div className="review_item">
            <div className="tab_name">
              <span>정부사업 놓치지 않는 방법</span>
            </div>
            <div className="review_content">
              <img src={quotationMark} alt="큰따옴표" />
              <p>
                <span>관심 키워드 공고</span>의 <span>실시간 알림</span>을
                <br />
                받아볼 수 있어서 빠르게 사업 참여를
                <br /> 준비할 수 있었다.
              </p>
            </div>
            <div className="reviewer_info">
              <div className="inner">
                <p className="name">한○○님</p>
                <p className="grade">콘텐츠 분야 스타트업 실장</p>
              </div>
            </div>
          </div>
          <div className="review_item">
            <div className="tab_name">
              <span>정부사업 놓치지 않는 방법</span>
            </div>
            <div className="review_content">
              <img src={quotationMark} alt="큰따옴표" />
              <p>
                <span>제안서 샘플마켓</span> 덕분에
                <br />
                <span>제안서 노하우와 인사이트</span>를 얻어
                <br /> 사업 수주에 성공할 수 있었다.
              </p>
            </div>
            <div className="reviewer_info">
              <div className="inner">
                <p className="name">원○○님</p>
                <p className="grade">IT분야 스타트업 CEO</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  };
  useEffect(() => {
    CommonAPI.getTaskHome().then((res) => setHomeData(res.data));
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const banner_setting = {
    dots: true,
    touchMove: false,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const banner_setting_mo = {
    dots: true,
    touchMove: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const review_settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2010,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  const searchAPI = (p_keyword) => {
    history.push(getKeywordToTaskURL(p_keyword || keyword));
  };

  return (
    <div id="IntroFindBiz">
      <div className="wrap_banner pc">
        <Slider {...banner_setting}>
          <Link to="/signup/type">
            <img
              src={
                require(isMobile
                  ? "../../../assets/images/intro/banner-00-mo.svg"
                  : "../../../assets/images/intro/banner-00.png").default
              }
              alt="배너 이미지"
            />
          </Link>
        </Slider>
      </div>

      <div className="mobile_view mo">
        <h2 className="mobile_view_title">
          <span>정부사업</span> 수주의 모든 것<br />
          <span className="bold">비즈내비</span>
        </h2>
        <ul className="hash_list">
          <li onClick={() => searchAPI("인공지능")}>#인공지능</li>
          <li onClick={() => searchAPI("메타버스")}>#메타버스</li>
          <li onClick={() => searchAPI("홍보")}>#홍보</li>
          <li onClick={() => searchAPI("융자")}>#융자</li>
        </ul>
        <div className="search_area_mo">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="어떤 사업이 궁금하세요?"
            onKeyUp={(e) => {
              if (e.key === "Enter") searchAPI();
            }}
          />
          <button className="icon_search_blue" onClick={() => searchAPI()} />
        </div>

        <Link to={links.taskManage} className="btn_go_task">
          <p>공공과제 찾기</p>
          <i className="icon_double_arrow_right_blue" />
        </Link>
      </div>
      {/*<div className={"mobile_banner mo"}>*/}
      {/*	<a href="https://forms.gle/Jy5Yeh9eJNCQoors8">*/}
      {/*		<img src={require(isMobile ? '../../../assets/images/intro/Mobile banner 360.svg' :  '../../../assets/images/intro/Mobile banner 360.svg').default} alt="배너 이미지"/>*/}
      {/*	</a>*/}
      {/*</div>*/}
      <div className="count_list">
        <Slider {...settings}>
          <div className="count_item">
            <h2>
              <CountUp
                start={0}
                end={homeData?.biz_total_count || 0}
                duration={2.75}
                separator=","
              />
              건
            </h2>
            <p>지난 6개월 사업 개수</p>
          </div>
          <div className="count_item">
            <h2>
              <CountUp
                start={0}
                end={homeData?.biz_week_amount || 0}
                duration={2.75}
                separator=","
                formattingFn={(value) => {
                  const convertValue = window.$Global.numberToKorean(value);
                  return (
                    convertValue.substr(0, convertValue.indexOf("억") + 1) || 0
                  );
                }}
              />
            </h2>
            <p>지난 일주일 사업 총 금액</p>
          </div>
          <div className="count_item">
            <h2>
              <CountUp
                start={0}
                end={homeData?.biz_today_count || 0}
                duration={2.75}
                separator=","
              />
              건
            </h2>
            <p>오늘 등록된 사업 개수</p>
          </div>
        </Slider>
      </div>

      {!isMobile && <TaskSearchIp />}

      <div className="wrap_search">
        <div className="search_area_pc pc">
          <div className="wrap">
            <ul className="hash_list">
              <li onClick={() => searchAPI("인공지능")}>#인공지능</li>
              <li onClick={() => searchAPI("메타버스")}>#메타버스</li>
              <li onClick={() => searchAPI("홍보")}>#홍보</li>
              <li onClick={() => searchAPI("융자")}>#융자</li>
            </ul>

            <Link to={links.taskManageInterest} className="btn_notify">
              <img
                className="icon_chat"
                src={
                  require("../../../assets/images/common/icon-mail.svg").default
                }
                alt="아이콘"
              />
              <p>관심 공고 알림</p>
              <i className="icon_next_black" />
            </Link>
          </div>
          <div className="search_inner">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder={message.whatSearchBiz}
              onKeyUp={(e) => {
                if (e.key === "Enter") searchAPI();
              }}
            />
            <button className="icon_search_gray" onClick={() => searchAPI()} />
          </div>
        </div>

        <Link to="/intro/page" className="banner">
          <img
            className="link_img"
            src={
              require(isMobile
                ? "../../../assets/images/intro/intro_bg_banner_mo.png"
                : "../../../assets/images/intro/intro_bg_banner.svg").default
            }
            alt="2022년 지원 사업 이미지"
          />
        </Link>
      </div>

      <IntroRedirectList />

      <div className="task_upload_info">
        <h2 className="title">
          <span>이 사업</span> 찾고계세요?
        </h2>
        <IntroTaskCategoryTemp />
        <IntroTaskCategory />
        <IntroTaskList />
      </div>

      <Link to={links.taskManage} className="btn_go_findbiz">
        <span>더 많은 공고 보기</span>
        <i className="icon_more_arrow_right_white" />
      </Link>

      {/*<div className="task_process">
				<h2 className="process_title">비즈내비의 <br className="mo"/><span>빠르고 편리하고<br className="mo"/>효율적인 프로세스</span>를<br className="mo"/>경험해 보세요.</h2>
				<ul className="tab_list scrollbar_hidden">
					<li className="tab_item active">
						<div className="circle">1</div>
						<p>사업, 정부사업 상세검색</p>
					</li>
					<li className="tab_item">
						<div className="circle">2</div>
						<p>시장정보 분석 서비스</p>
					</li>
					<li className="tab_item">
						<div className="circle">3</div>
						<p>샘플마켓 제안서다운</p>
					</li>
					<li className="tab_item">
						<div className="circle">4</div>
						<p>기업 컨소시엄 매칭하기</p>
					</li>
					<li className="tab_item">
						<div className="circle">5</div>
						<p>영상기술</p>
					</li>
				</ul>

				<div className="box">
					<p className="step">Sep.1 <b><span>사업, 정부사업 상세 검색하기</span></b></p>
					<p className="desc">정부 · 공공기관 · 지자체 사업, <br className="mo"/><span>한곳에서 한꺼번에 확인</span>하고<br/>원하는 사업가 뜨면 메일로 바로 알려줍니다.</p>

					<div className="more_desc">
						<p>Bizinfo, Smtech, NTIS, 조달청 포함 1500여 개 공공기관의 사업, 사업와 민간사업까지 모두 정리 해 드립니다.</p>
						<p>Bizinfo, Smtech, NTIS, 조달청 포함 1500여 개 공공기관의 사업, 사업와 민간사업까지 모두 정리 해 드립니다.</p>
					</div>
				</div>
			</div>*/}

      <div className="task_review">
        {isMobile ? (
          <h2 className="title">
            비즈내비 <br />
            <span>이렇게 빠르고 쉽고 전문적입니다.</span>
          </h2>
        ) : (
          <h2 className="title">
            비즈내비 <span>이렇게 빠르고 쉽고 전문적입니다.</span>
          </h2>
        )}
        {isMobile ? (
          <SliderMobile />
        ) : (
          <div className="review_list">
            <div className="review_item">
              <div className="tab_name">
                <span>빨라진 정부과제 검색</span>
              </div>
              <div className="review_content">
                <img src={quotationMark} alt="큰따옴표" />
                <p>
                  <span>1500여 개 공공기관 사이트가</span>
                  <br />
                  <span>한 곳에</span> 모여 있어서 아주 편리합니다.
                  <br />
                  정부과제 업무시간을
                  <br />
                  80% 이상 단축할 수 있었습니다.
                  <br />
                </p>
              </div>
              <div className="reviewer_info">
                <div className="inner">
                  <p className="name">이○○님</p>
                  <p className="grade">IT분야 중소기업 CEO</p>
                </div>
              </div>
            </div>
            <div className="review_item">
              <div className="tab_name">
                <span>쉬워진 제안서 업무</span>
              </div>
              <div className="review_content">
                <img src={quotationMark} alt="큰따옴표" />
                <p>
                  어렵기만 했던
                  <br />
                  <span>시장조사와 선행기술조사</span>를<br />
                  비즈내비에서 <span>쉽고 저렴하게</span>,<br />
                  한 번에 해결했습니다.
                  <br /> 중소기업을 위한 최고의 서비스
                </p>
              </div>
              <div className="reviewer_info">
                <div className="inner">
                  <p className="name">박○○님</p>
                  <p className="grade">제조분야 중소기업 팀장</p>
                </div>
              </div>
            </div>
            <div className="review_item">
              <div className="tab_name">
                <span>정부사업 놓치지 않는 방법</span>
              </div>
              <div className="review_content">
                <img src={quotationMark} alt="큰따옴표" />
                <p>
                  <span>관심 키워드 공고</span>의 <span>실시간 알림</span>을
                  <br />
                  받아볼 수 있어서 빠르게 사업 참여를
                  <br /> 준비할 수 있었다.
                </p>
              </div>
              <div className="reviewer_info">
                <div className="inner">
                  <p className="name">한○○님</p>
                  <p className="grade">콘텐츠 분야 스타트업 실장</p>
                </div>
              </div>
            </div>
            <div className="review_item">
              <div className="tab_name">
                <span>정부사업 수주 노하우</span>
              </div>
              <div className="review_content">
                <img src={quotationMark} alt="큰따옴표" />
                <p>
                  <span>제안서 샘플마켓</span> 덕분에
                  <br />
                  <span>제안서 노하우와 인사이트</span>를 얻어
                  <br /> 사업 수주에 성공할 수 있었다.
                </p>
              </div>
              <div className="reviewer_info">
                <div className="inner">
                  <p className="name">원○○님</p>
                  <p className="grade">IT분야 스타트업 CEO</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="task_mou">
        <h2 className="title">
          비즈내비의 <br className="mo" />
          <span>MOU 협약사</span>
        </h2>

        <ul className="mou_list">
          <li className="mou_item">
            <img
              className="dLight"
              src={
                require("../../../assets/images/mou/dlight-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="kea"
              src={require("../../../assets/images/mou/kea-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="innopolis"
              src={
                require("../../../assets/images/mou/innopolis-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="busan"
              src={require("../../../assets/images/mou/busan-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="kva"
              src={require("../../../assets/images/mou/kva-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="younsei"
              src={
                require("../../../assets/images/mou/younsei-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="honest"
              src={
                require("../../../assets/images/mou/honest-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="muirwoods"
              src={
                require("../../../assets/images/mou/muirwoods-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="intellectual"
              src={
                require("../../../assets/images/mou/intellectual-logo.png")
                  .default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="innobiz"
              src={
                require("../../../assets/images/mou/innobiz-logo.png").default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="middle"
              src={
                require("../../../assets/images/mou/large-middle-logo.png")
                  .default
              }
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="koiia"
              src={require("../../../assets/images/mou/koiia-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="kst"
              src={require("../../../assets/images/mou/kst-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
          <li className="mou_item">
            <img
              className="til"
              src={require("../../../assets/images/mou/til-logo.png").default}
              alt="mou 회사 로고 이미지"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default IntroFindBiz;
