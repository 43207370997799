import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareCashFlowPath, groupCashflow } from "./groupwarePath";
import IframeResizer from "iframe-resizer-react";

import "./css/GroupwareWorks.scss";

export const GroupwareCashflow = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=cashflowLanding`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowBalanceIns = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=balance_ins`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowOpRevenue = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=op_revenue`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowOpExpense = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=op_expense`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowFinance = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=finance`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowInvest = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=invest`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareCashflowReport = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareCashFlowPath} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/cashflow.do?command=report`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};
