import moment from "moment";
import "moment/locale/ko";
import "../css/HoldingsHomeTop.scss";
import config from "../../../const/config";
import axios from "axios";
import { useEffect, useState } from "react";

const HoldingsHomeTop = () => {
  const [userInfo, setUserInfo] = useState({});
  const getCurrentWeekday = () => {
    // 현재 날짜와 시간을 한국어로 설정
    moment.locale("ko");
    // 현재 요일을 반환
    return moment().format("dddd");
  };

  const getUserInfo = () => {
    let url = config.gwUrl;
    axios
      .get(url + "/bizoffi-api/member/userInfo.json", {
        headers: {
          "g-token": localStorage.getItem(config.gwKeyToken),
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.result == "fail") {
          window.$Global.logout("self");
        } else {
          setUserInfo(res.data.userInfo);
        }
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div id="HoldingsHomeTop">
      <div className="part">
        <div className="utils">
          {/* <div className="search">
            <input type="text" placeholder="검색어를 입력해주세요." />
            <button>
              <i className="icon_search_blue"></i>
            </button>
          </div>
          <div className="create">
            <button>
              <i className="icon_white_add"></i>신규기업
            </button>
          </div> */}
          <div className="user">
            반갑습니다.
            <strong>
              {localStorage.getItem("company_name")}&nbsp;
              {userInfo.user_nm}님!
            </strong>
          </div>
        </div>
        <p>
          {moment().format("YYYY년 MM월 DD일")}&nbsp;({getCurrentWeekday()})
        </p>
      </div>
    </div>
  );
};

export default HoldingsHomeTop;
