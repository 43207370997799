import React, { useEffect, useState, useRef } from "react";
import "./css/DocTradeSample.scss";
import DocTradeSampleListTop from "./DocTradeSampleListTop";
import DocTradeSampleList from './DocTradeSampleList';
import Pagination from '../common/Pagination';
import { Link } from 'react-router-dom';
import TemplateAPI from "../../API/template";

function DocTradeSample() {
    const [categoryList, setCategoryList] = useState([]);
    const [selectCategory, setSelectCategory] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [curPage, setCurPage] = useState(1);
    const [count, setCount] = useState(10);
    const [resData, setResData] = useState({ items: [] });
    const [chargedType, setChargedType] = useState(-1); // 전체 -1, 유로 0, 무료 1
    const selectBox = useRef();
    const buttonBox = useRef();

    useEffect(() => {
        TemplateAPI.getCategory().then(res => setCategoryList(res.data));
    }, []);

    useEffect(() => {
        getList();
    }, [count, curPage, selectCategory]);

    useEffect(() => {
        if (curPage == 1) {
            getList(1);
        } else {
            setCurPage(1);
        }
    }, [chargedType]);

    const getList = (page) => {
        let payload = {
            count: count,
            page: page || curPage,
            free: chargedType,
            category_idx1: selectCategory,
            keyword: keyword,
        };
        TemplateAPI.getTemplateList(payload).then(res => setResData(res.data));
    };

    const onClickSearch = () => {
        getList();
    };

    const selectBoxHandler = () => {
        if (!selectBox.current.classList.contains('on')) {
            selectBox.current.classList.add('on');
        }else{
            selectBox.current.classList.remove('on');
        }
    }

    const optionClick = (e) =>{
        if (!e.target.classList.contains('on')) {
            const optionElement = e.target.parentElement.children;
            optionElement.forEach(t => {t.classList.remove('on')});
            e.target.classList.add('on');
            buttonBox.current.innerText = e.target.innerText
            selectBox.current.classList.remove('on');
        }
    }

    return (
        <div id="docTradeSample">
            <div className="pc">
                <div className="sample">
                    <div className="sample_tutorial-box">
                        <div className="sample_tutorial-box_icon">
                            <span className="icon_document"></span>
                        </div>
                        <div className="sample_tutorial-box_content">
                            <div className="sample_tutorial-box_text">
                                <h3 className="sample_tutorial-box_title">제안서 샘플마켓</h3>
                                <p className="sample_tutorial-box_info">
                                    제안서, PPT의 실제 사례 샘플을 필요하신 분들에게 트레이드할 수 있습니다.
                                    <br />
                                    사업 제안을 하실 때 우수사례 샘플을 참고로 제안서 작성 및 사업 수주가 가능합니다.
                                </p>
                            </div>
                        </div>
                        {/*<a href="#" className="sample_tutorial-box_link">튜토리얼 바로가기</a>*/}
                    </div>
                    <div className="sample_list">
                        <div className="sample_list_title-wrap">
                            <h3 className="sample_list_title">제안서 샘플마켓</h3>
                            <ul className="sample_list_menu">
                                <li className={`sample_list_menu-list ${selectCategory == null ? 'active' : ''}`} onClick={() => setSelectCategory(null)}>
                                    <button className="sample_list_menu-link">전&nbsp;&nbsp;체</button>
                                </li>
                                {
                                    categoryList.map(item => {
                                        return (
                                            <li key={item.idx}
                                                className={`sample_list_menu-list ${item.idx == selectCategory ? 'active' : ''}`}
                                                onClick={() => setSelectCategory(item.idx)}>
                                                <button className="sample_list_menu-link">{item.name}</button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <ul className="sample_list_btn-section">
                                <li className="sample_list_btn-list enroll">
                                    <Link to="add" className="sample_list_btn-link">제안서 판매하기</Link>
                                </li>
                                <li className="sample_list_btn-list manage">
                                    <Link to="my" className="sample_list_btn-link">관리</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="sample_list_search-form formSearch">
                            <div className="sample_list_search-form_border-area">
                                <label htmlFor="inputSearch" className="hidden">검색어 입력창</label>
                                <input type="text"
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                    onKeyUp={(e) => { if (e.key === 'Enter') onClickSearch() }}
                                    id="inputSearch"
                                    className="sample_list_search-form_input-search"
                                    placeholder="검색어를 입력해 주세요"
                                />
                            </div>
                            <button type="submit" className="sample_list_search-form_btn-submit" onClick={onClickSearch}>검색</button>
                        </div>
                        <DocTradeSampleListTop chargedType={chargedType} onClick={setChargedType} onChange={e => setCount(e.target.value)} />
                        <div className="sample_list-contents">
                            <ul className="sample_list-contents_list-wrap">
                                {
                                    resData.items.map((item, idx) => {
                                        return <DocTradeSampleList key={idx} item={item} />
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
                </div>
            </div>
            <div className="mo">
                <div className="sample">
                    <div className="sample_top">
                        <h2 className="sample_title">제안서 샘플마켓</h2>
                        <p className="sample_subtitle">
                            제안서&amp;PPT 실제 사례 샘플 트레이드 해보세요.<br />
                            우수한 사례 참고로 사업제안서 작성 및 사업 수주가 가능합니다.
                        </p>
                    </div>
                    <div className="sample_search-form formSearch">
                        <h3 className="sample_search-form_title">샘플 검색 하기</h3>
                        <div className="sample_search-form_border-area">
                            <label htmlFor="inputSearch" className="hidden">검색어 입력창</label>
                            <input type="text"
                                value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={(e) => { if (e.key === 'Enter') onClickSearch() }}
                                id="inputSearch"
                                className="sample_search-form_input-search"
                                placeholder="검색어를 입력해 주세요"
                            />
                            <button type="submit" className="sample_search-form_btn-submit" onClick={onClickSearch}>검색</button>
                        </div>
                    </div>
                    <div className="sample_list-contents">
                        <div className="sample_list-contents_title-wrap">
                            <h3 className="sample_list-contents_title">제안서 샘플 마켓 리스트</h3>
                            <ul className="sample_list-contents_btn-wrap">
                                <li className="sample_list-contents_btn-list">
                                    <button 
                                        type="button" 
                                        className="sample_list-contents_btn"
                                        onClick={selectBoxHandler}
                                        ref = {buttonBox}
                                    >
                                        가격
                                    </button>
                                </li>
                                <li className="sample_list-contents_btn_select" ref={selectBox}>
                                    <button type="button" className="sample_list-contents_btn_option_button on" onClick={optionClick}>전체</button>
                                    <button type="button" className="sample_list-contents_btn_option_button 2" onClick={optionClick}>유료</button>
                                    <button type="button" className="sample_list-contents_btn_option_button 3" onClick={optionClick}>무료</button>
                                </li>
                            </ul>
                        </div>
                        <ul className="sample_list-contents_list-wrap">
                            {
                                resData.items.map((item, idx) => {
                                    return <DocTradeSampleList key={idx} item={item} />
                                })
                            }
                        </ul>
                        <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocTradeSample;