import { useEffect, useState } from "react";
import "./css/InfoChoice.scss";
import { dataPeriod, getText, period } from "./data";
import _ from "lodash";

const InfoChoice = ({ infoPackage, setTabChange, cost, setCost }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    infoPackage.map((item) => {
      if (item.isChecked) {
        setData(item);
      }
    });
  }, [infoPackage]);

  return data.isChecked ? (
    <div id="InfoChoice">
      {!_.isEmpty(data) ? (
        <div className="section_items">
          <div>
            <div>
              <p className="name">{data.name}</p>
            </div>
            <select
              onChange={(e) =>
                setCost({ ...cost, dataPeriod: Number(e.target.value) })
              }
            >
              {dataPeriod.map((item) => {
                return <option value={item.value}>{item.name}</option>;
              })}
            </select>
          </div>
          <div className="option_text">{getText(data)}</div>
        </div>
      ) : (
        <div className="free_text">
          <i className="icon_plus_blue" onClick={() => setTabChange(true)} />
          <p>
            비즈내비의 독보적인
            <br />
            비즈니스 데이터 패키지를
            <br />
            살펴보세요!
          </p>
        </div>
      )}
    </div>
  ) : null;
};

export default InfoChoice;
