import React, {useEffect, useState} from "react";
import "./css/CyberNotify.scss";
import CyberAPI from "../../API/cyber";

function CyberNotify() {
    const [tbody, setTbody] = useState([]);

    useEffect(() => {

        CyberAPI.getNotify().then(res => {

            setTbody(
                res.data.items.map((item, idx) => {
                   return (
                       <li key={idx}>
                           <div className="info">
                               <p className="circle"/>
                               <p>{item.etc}</p>
                           </div>
                           <p>{window.$Global.convertDate(item.created_at)}</p>
                       </li>
                   )
                })
            );
        });
    }, []);

    return (
        <div id="CyberNotify">
            <div className="head">
                <h2>알람</h2>
                <p>+5</p>
            </div>

            <ul className="wrap_list">
                <li>
                    <div className="info">
                        <p className="circle"/>
                        <p>무슨 기업</p>
                    </div>
                    <p>5분 전</p>
                </li>
                {tbody}
            </ul>
        </div>
    )
}

export default CyberNotify;