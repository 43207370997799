import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonAPI from "../../API/common/index";
import "./css/EducationDetail.scss";
import SlickSlider from "../common/SlickSlider";
import ImageViewer from "../common/ImageViewer";

function EducationDetail({ history }) {
    const { idx } = useParams();
    const [postData, setPostData] = useState({
        preview: []
    });
    const [curIndex, setCurIndex] = useState(0);
    const [viewerShow, setViewerShow] = useState(false);

    useEffect(() => {
        CommonAPI.getEduInfo(idx).then(res => setPostData(res.data));
    }, []);

    const onClickPrevViewer = () => {
        setCurIndex(curIndex - 1);
    };

    const onClickNextViewer = () => {
        setCurIndex(curIndex + 1);
    };

    const showViewer = (idx) => {
        setCurIndex(idx);
        setViewerShow(true);
    };

    return (
        <div id="EducationDetail">
            <h2 className="page_title">{postData.title}</h2>
            <p className="post_info">
                IPNOW 관리자
                <span>게시일 : {window.$Global.convertDate(postData.created_at)}</span>
            </p>
            <SlickSlider list={postData.preview} onClick={showViewer}/>

            <div className="intro_area">
                <h2>소개글</h2>
                <p className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: postData.content }} />
            </div>

            <button className="btn_list" onClick={() => history.push("/education/main")}>목록</button>

            {
                viewerShow
                &&
                <ImageViewer curIndex={curIndex}
                             postData={postData}
                             onClickClose={() => setViewerShow(false)}
                             onClickPrevViewer={onClickPrevViewer}
                             onClickNextViewer={onClickNextViewer}
                />
            }
        </div>
    )
};

export default EducationDetail;
