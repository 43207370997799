import React, {useEffect, useRef, useState} from "react";
import "../css/MyPageFindbizAccountPw.scss";
import config from "../../../const/config";
import _ from "lodash";
import message from "../../../const/message";
import Validator from "../../../util/validator";
import MemberAPI from "../../../API/member";
import {useHistory} from "react-router-dom";

function MyPageFindbizAccountPw() {
	const history = useHistory();
	const [signInfo, setSignInfo] = useState({});
	const [checkList, setCheckList] = useState({
		checkPassword: null,
		checkRegPassword: null,
	});
	const [checkAll, setCheckAll] = useState(false);
	const [newPwdRef, newPwdConfirmRef] = [useRef(null), useRef(null)];

	useEffect(() => {
		if (!signInfo.password) return;

		let copy = _.cloneDeep(checkList);

		if (signInfo.password === signInfo.password_confirm) {
			copy.checkPassword = true;
		} else {
			copy.checkPassword = false;
		}

		if (!signInfo.password.match(config.checkReg)) {
			copy.checkRegPassword = false;
		} else {
			copy.checkRegPassword = true;
		}
		setCheckList(copy);
	}, [signInfo.password, signInfo.password_confirm]);

	useEffect(() => {
		if (checkList.checkPassword && checkList.checkRegPassword) {
			setCheckAll(true);
		} else {
			setCheckAll(false);
		}
	}, [checkList]);

	const changePw = () => {
		if (Validator.refValidator([newPwdRef, newPwdConfirmRef])) {
			MemberAPI.updateInfo({password: signInfo.password}).then(() => {
				alert(message.successSave);
				history.go(0);
			});
		}
	}

	return (
		<div id="MyPageFindbizAccountPw">
			<div className="head">
				<h2>비밀번호 변경</h2>
			</div>
			<div className="account_area">
				<div className="wrap_info">
					<label htmlFor="user_pw">새 비밀번호</label>
					<div className="info">
						<input type="password" id="user_pw" data-name={"비밀번호"} ref={newPwdRef} onChange={e => setSignInfo({...signInfo, password: e.target.value})}/>
					</div>
					{
						(checkList.checkRegPassword != null) && !checkList.checkRegPassword && <p className="notify">{config.regxMsg}</p>
					}
				</div>
				<div className="wrap_info">
					<label htmlFor="user_pw_confirm">새 비밀번호 확인</label>
					<div className="info">
						<input type="password" id="user_pw_confirm"  data-name={"비밀번호 확인"} ref={newPwdConfirmRef} onChange={e => setSignInfo({...signInfo, password_confirm: e.target.value})}/>
					</div>
					{
						(checkList.checkPassword != null) && !checkList.checkPassword && <p className="notify">{message.errorNotEqualsPassword}</p>
					}
				</div>
			</div>

			<button className={`btn_save ${checkAll ? '' : 'btn_disabled'}`} onClick={changePw}>비밀번호 변경하기</button>
		</div>
	)
}

export default MyPageFindbizAccountPw;