import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import './css/SignupSuccess.scss';
import qs from "query-string";
import BannerIpnow from "../common/BannerIpnow";

function SignupSuccess({location, history}) {
    const {type, code, refresh} = qs.parse(location.search);
    const isAgent = type == 'agent';
    const isInvestor = type == "investor";
    const isPersonal = type == "personal";
    const codeInput = React.useRef();

    useEffect(() => {
        if (refresh == "1") {
            history.push(`/signup/success?type=${type}&code=${code}`);
        }
    }, []);

    return (
        <div id="SignupSuccess">
            <div className="pc">
                <BannerIpnow/>
            </div>
            <div className={`wrap ${isInvestor ? 'investor' : ''}`}>
                <h2 className="noti">
                    <span>가입이 <br className={`mo ${isInvestor ? 'show' : 'hide'}`}/> 완료되었습니다.</span>
                </h2>
                {
                    (!isAgent && !isInvestor) &&
                    <p className="mo alert">
                        IPNOW 서비스는 PC에서 모두 사용하실 수 있습니다.
                    </p>
                }
                {
                    isAgent &&
                    <p className="mo alert">특허사무소는 PC를 통해 서비스 이용이 가능합니다.</p>
                }
                <div className="mo">
                    <Link className="btn" to="/login">로그인 하러가기</Link>
                </div>

                {
                    (isPersonal && code) && <h2 className="personal_notify mo">IP 서비스 이용자가 사용하는 코드</h2>
                }

                {
                    (!isInvestor && code) &&
                    <div className="company_code">
                        <div className="box">
                            <h2>회사코드</h2>
                            <input ref={codeInput} value={code} readOnly/>
                            <button className="btn" onClick={() => window.$Global.copy(codeInput)}>복사하기</button>

                            <p className="company_code_info">
                                <i className="icon_alert_circle"/>
                                {
                                    !isAgent
                                    ?   <span>특허사무소와 온라인 연결할 때 사용되는 회사 고유코드임<br/>(관리자페이지에서 활용)</span>
                                    :   <span>기업과 온라인 연결할 때 사용되는 회사 고유코드임<br/>(관리자페이지에서 활용)</span>
                                }
                            </p>
                        </div>
                    </div>
                }
                <Link className="btn_confirm pc" to="/login">로그인 하러가기</Link>
            </div>
        </div>
    );
}

export default SignupSuccess;
