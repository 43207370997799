import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Doughnut} from "react-chartjs-2";
import _ from "lodash";

function PieGraph({title, to, cnt, data, options, isEmptyData, dual}) {
    const historyData = _.cloneDeep(data);
    const [mode, setMode] = useState(0);

    let mode_cnt = 2;
    let tag = [];
    for (let i = 0; i < mode_cnt; i++) {
        tag.push(<li key={i} className={`circle ${mode == i ? 'active' : ''}`} onClick={() => onClickMode(i)}/>);
    }

    const onClick = () => {
        if (to == "/") {
            alert("준비 중인 페이지입니다");
        }
    };

    const onClickMode = (type) => {
        setMode(type);
    };

    if (dual) {
        title = title[mode];
        to = to[mode];
        cnt = cnt[mode];
        data = historyData[mode];
        isEmptyData = isEmptyData[mode];
    }


    return (
        <div className="tab_info wrap_chart">
            <div className="header">
                <h2>{title}</h2>
                <Link to={to} onClick={onClick}>
                    자세히보기
                    <i className="icon_more_arrow_right_white"/>
                </Link>
            </div>
            {
                isEmptyData
                    ?   <p className="no_data">현재 등록되어진 {title.toString().replace(/ /g,"")}이(가) 없습니다.</p>
                    :   <div className="chart">
                            <p className="total_cnt">
                                총<span>{cnt}</span>건
                            </p>
                            <div className="add_label">등록 {data.datasets[0].data[0]}건</div>
                            <div className="apply_label">출원 {data.datasets[0].data[1]}건</div>
                            <div className="giveup_label">포기 {data.datasets[0].data[2]}건</div>
                            <Doughnut data={data} width={195} height={160} options={options}/>
                        </div>
            }
            {
                dual &&
                <ul className="nav">
                    {tag}
                </ul>
            }
        </div>
    );
}

export default PieGraph;
