import "./css/ResearchTechIntro.scss";
import paperImg from "../../assets/images/researchTech/img_paper.svg";
import keyImg from "../../assets/images/researchTech/img_key.svg";
import { useHistory } from "react-router-dom";

const ResearchTechIntro = () => {
  const history = useHistory();
  return (
    <div id="ResearchTechIntro">
      <h2>조사 검색 방식을 선택하세요</h2>
      <section>
      <div
          className="search_type"
          onClick={() => {
            history.push({
              pathname: "/researchTech/category",
              state: {
                isKeywordSearch: 1,
              },
            });
          }}
        >
          <div>
            <img src={keyImg} alt="keyImg" />
          </div>
          <p>
            <h3>출원인 검색하기</h3>
            <h5>직접 새로운 선행기술을 검색해 보세요.</h5>
          </p>
        </div>  
      <div
          className="search_type"
          onClick={() => {
            history.push({
              pathname: "/researchTech/category",
              state: {
                isKeywordSearch: 2,
              },
            });
          }}
        >
          <div>
            <img src={keyImg} alt="keyImg" />
          </div>
          <p>
            <h3>키워드 검색하기</h3>
            <h5>직접 새로운 선행기술을 검색해 보세요.</h5>
          </p>
        </div>
        <div
          className="search_type"
          onClick={() => {
            history.push({
              pathname: "/researchTech/category",
              state: {
                isKeywordSearch: 3,
              },
            });
          }}
        >
          <div>
            <img src={paperImg} alt="paperImg" />
          </div>
          <p>
            <h3>기존 선행기술 검색하기</h3>
            <h5>
              기존에 파인드비즈에 있는
              <br />
              선행기술 레퍼런스를 통해 작성 해보세요.
            </h5>
          </p>
        </div>
        
      </section>
    </div>
  );
};

export default ResearchTechIntro;
