import React, {useContext, useEffect, useState} from "react";
import "./css/MatchingBizDetail.scss";
import CommonAPI from "../../API/common"
import {Link, useParams} from 'react-router-dom';
import memberContext from "../../store/Member";
import links from "../../const/links";

function MatchingBizDetail({history}) {
	let { idx } = useParams();
	const [info, setInfo] = useState({});
	const [field, setField] = useState({});
	const member = useContext(memberContext);

	const editURL = `../edit?postType=edit&idx=${idx}`;
	const isMyPosting = member.company_idx === info.company_idx;

	useEffect(() => {
		CommonAPI.getTaskInfo(idx).then(res => setInfo(res.data));
		CommonAPI.getTaskField().then(res => setField(res.data));
	}, []);

	const download = () => {
		info.file.forEach(item => {
			CommonAPI.taskFileDownload(idx, item.idx);
		})
	};

	const matchJsonToStr = (key, condition) => {
		return window.$Global.matchJsonToStr(field, key, condition);
	};

	const deletePost = () => {
		if (window.confirm("삭제하시겠습니까?")) {
			CommonAPI.deleteTask(idx).then(() => history.replace(`/matchingBiz/my`));
		}
	};

    return (
        <div id="matchingBizDetail">
			<div className="pc">
				<div className="matchingBizDetail">
					<div className="matchingBizDetail_title-wrap">
						<h2 className="matchingBizDetail_title">{info.title}</h2>
						{
							isMyPosting
							&&
							<div className="matchingBizDetail_btn-wrap">
								<Link to={editURL} className="btn_mod">수&nbsp;&nbsp;정</Link>
								<button type="button" className="btn_del" onClick={deletePost}>삭&nbsp;&nbsp;제</button>
							</div>
						}
					</div>
					<div className="matchingBizDetail_subTitle">
						{info.title_detail}
					</div>
					<div className="matchingBizDetail_table">
						<ul className="matchingBizDetail_table_row borderBottom-gray">
							<li className="col-1-3">
								<div className="title">
									회사명
								</div>
								<div className="info font-strong">
									{info.company_name}
								</div>
							</li>
							<li className="col-2-3">
								<div className="title">
									문의처
								</div>
								<div className="info">
									{info.phone} / {info.email}
								</div>
							</li>
						</ul>
						<ul className="matchingBizDetail_table_row">
							<li className="col-1-3">
								<div className="title">
									참가가능지역
								</div>
								<div className="info">
									{matchJsonToStr("region", info.region)}
								</div>
							</li>
							<li className="col-1-3">
								<div className="title">
									분	야
								</div>
								<div className="info">
									{matchJsonToStr("field", info.field)}
								</div>
							</li>
						</ul>
						<ul className="matchingBizDetail_table_row">
							<li className="col-1-3">
								<div className="title color-blue">
									접수일
								</div>
								<div className="info">
									{window.$Global.convertDate(info.reception_at)}
								</div>
							</li>
							<li className="col-1-3">
								<div className="title color-blue">
									마감일
								</div>
								<div className="info">
									{window.$Global.convertDate(info.deadline_at)}
								</div>
							</li>
							<li className="col-1-3">
								<div className="title color-blue">
									공 고 일
								</div>
								<div className="info font-strong">
									{window.$Global.convertDate(info.announcement_at)}
									{/*<span className="dDay">*/}
									{/*	D-15*/}
									{/*</span>*/}
								</div>
							</li>
						</ul>
						<ul className="matchingBizDetail_table_row">
							<li className="col-1-3">
								<div className="title color-blue">
									업무 시작일
								</div>
								<div className="info">
									{window.$Global.convertDate(info.biz_start_at)}
								</div>
							</li>
							<li className="col-1-3">
								<div className="title color-blue">
									업무 마감일
								</div>
								<div className="info">
									{window.$Global.convertDate(info.biz_finish_at)}
								</div>
							</li>
							<li className="col-1-3">
								<div className="title color-blue">
									금	액
								</div>
								<div className="info color-blue font-big">
									{window.$Global.commaify(info.amount)}원
								</div>
							</li>
						</ul>
					</div>
					<div className="matchingBizDetail_file">
						<div className="matchingBizDetail_file_text-wrap">
							<div className="matchingBizDetail_file_title">
								첨부파일
							</div>
							{
								Boolean(info?.file?.length)
								?
								<div className="matchingBizDetail_file_text">
									<span className="icon_download_attached"/>
									<p className="matchingBizDetail_file_text_title" onClick={download}>
										{info.file[0].file_name + "." + info.file[0].file_type} {info.file.length > 1 && `외 ${info.file.length}건`}
									</p>
								</div>
								:
								<div className="matchingBizDetail_file_text">
									<p className="matchingBizDetail_file_text_title">첨부 없음</p>
								</div>
							}
						</div>
					</div>
					<div className="matchingBizDetail_box">
						<h3 className="matchingBizDetail_box_title">
							간단 설명
						</h3>
						<div className="matchingBizDetail_box_text">
							{info.content}
						</div>
					</div>
					<div className="matchingBizDetail_btn-wrap">
						<button className="matchingBizDetail_btn" onClick={() => history.push(`${links.taskManage}?category=2`)}>
							목&nbsp;&nbsp;록
						</button>
					</div>
				</div>
			</div>
			<div className="mo">
				<div className="matchingBizDetail">
					<div className="matchingBizDetail_box">
						<dl>
							<div className="matchingBizDetail_box_list style-title">
								<dt className="matchingBizDetail_box_title">요청 업무</dt>
								<dd className="matchingBizDetail_box_data">{info.title}</dd>
								<dt className="matchingBizDetail_box_title">간이소개</dt>
								<dd className="matchingBizDetail_box_data style-font">{info.title_detail}</dd>
							</div>
							<div className="matchingBizDetail_box_list">
								<dt className="matchingBizDetail_box_title">회&nbsp;&nbsp;사&nbsp;&nbsp;명</dt>
								<dd className="matchingBizDetail_box_data">{info.company_name}</dd>
								<dt className="matchingBizDetail_box_title">문&nbsp;&nbsp;의&nbsp;&nbsp;처</dt>
								<dd className="matchingBizDetail_box_data">{info.phone}<br />{info.email}</dd>
							</div>
							<div className="matchingBizDetail_box_list">
								<dt className="matchingBizDetail_box_title">지&nbsp;&nbsp;역&nbsp;&nbsp;명</dt>
								<dd className="matchingBizDetail_box_data">{matchJsonToStr("region", info.region)}</dd>
								<dt className="matchingBizDetail_box_title">분&nbsp;&nbsp;&nbsp;야</dt>
								<dd className="matchingBizDetail_box_data">{matchJsonToStr("field", info.field)}</dd>
							</div>
							<div className="matchingBizDetail_box_list">
								<dt className="matchingBizDetail_box_title">접&nbsp;&nbsp;수&nbsp;&nbsp;일</dt>
								<dd className="matchingBizDetail_box_data">{window.$Global.convertDate(info.reception_at)}</dd>
								<dt className="matchingBizDetail_box_title">마감 일시</dt>
								<dd className="matchingBizDetail_box_data">{window.$Global.convertDate(info.deadline_at)}</dd>
								<dt className="matchingBizDetail_box_title">공&nbsp;&nbsp;고&nbsp;&nbsp;일</dt>
								<dd className="matchingBizDetail_box_data">{window.$Global.convertDate(info.announcement_at)}</dd>
								<dt className="matchingBizDetail_box_title">업무시작일~마감일</dt>
								<dd className="matchingBizDetail_box_data">{window.$Global.convertDate(info.biz_start_at)}~{window.$Global.convertDate(info.biz_finish_at)}</dd>
								<dt className="matchingBizDetail_box_title">금&nbsp;&nbsp;액</dt>
								<dd className="matchingBizDetail_box_data">{window.$Global.commaify(info.amount)}원</dd>
							</div>
							{
								Boolean(info?.file?.length)
								&&
								<dt className="matchingBizDetail_box_title style-file">
									<div className="title">첨부파일</div>
									<button type="button" className="btn" onClick={download}>첨부파일 내려받기</button>
								</dt>
							}
							<dd className="matchingBizDetail_box_data style-font">
								{/* {info.file[0].file_name + "." + info.file[0].file_type} {info.file.length > 1 && `외 ${info.file.length}건`} */}
							</dd>
						</dl>
					</div>
					<div className="matchingBizDetail_box style-des">
						<dl>
							<dt className="matchingBizDetail_box_title">간단설명</dt>
							<dd className="matchingBizDetail_box_data">
								{info.content}
							</dd>
						</dl>
					</div>
					{/*<button type="button" className="matchingBizDetail_btn">매칭비즈 저장하기</button>*/}
				</div>
			</div>
        </div>
    )
}

export default MatchingBizDetail;