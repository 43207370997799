import React, {useContext} from "react";
import { Link } from 'react-router-dom';
import "../css/Menu.scss";
import memberContext from "../../../store/Member";
import ManageApproval from "../ManageApproval";

function Menu({number}) {
    const {auth} = useContext(memberContext);
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isAgent = window.$Global.checkAuth("대리인", auth);
    const isInvestor = window.$Global.checkAuth("투자자", auth);

    return (
        <div id="ManageMenu">
            <h2>관리 메뉴</h2>
            <ul>
            {
                !isInvestor &&
            <>
                <li className={number == 1 ? "active" : ""}>
                    <Link to="/manage/user?tab=1">
                        관리자 계정 추가하기
                    </Link>
                </li>
                <li className={number == 5 ? "active" : ""}>
                    <Link to="/manage/user?tab=5">
                        {
                            isCompany ? '특허사무소 등록하기' : '기업 등록하기'
                        }
                    </Link>
                </li>
            </>
            }
                <li className={number == 3 ? "active" : ""}>
                    <Link to="/manage/product">
                    {
                        isCompany ? '제품 추가/변경'
                        : isAgent ? '주요 분야/추가 변경' : isInvestor && '관심 분야/추가 변경'
                    }
                    </Link>
                </li>
                <li className={number == 2 ? "active" : ""}>
                    <Link to="/manage/price">이용 요금제</Link>
                </li>
                {
                    (isCompany || isAgent) &&
                    <li className={number == 4 ? "active" : ""}>
                        <Link to="/manage/code">회사 코드</Link>
                    </li>
                }
                <li className={number == 6 ? "active" : ""}>
                    <Link to="/manage/point">포인트 조회하기</Link>
                </li>
                <li className={number == 7 ? "active" : ""}>
                    <Link to="/manage/approval">환경설정</Link>
                </li>
            </ul>
        </div>
    )
}

export default Menu;
