import API from '../../util/api';

export default {
    getInventionFileList(invention_idx, description) {
        return API.get(`/manager/invention/${invention_idx}/file`, {params: {description: description}})
    },
    getInventionOpenFileList(invention_idx, open_idx) {
        return API.get(`/manager/invention/${invention_idx}/open/${open_idx}/file`);
    },
    getJointCompany(keyword) {
        return API.get(`/manager/company/joint`, {params: {keyword: keyword}});
    },
    getInventionInfo(invention_idx) {
        return API.get(`/manager/invention/${invention_idx}`);
    },
    downloadInventionFile(invention_idx, file_idx) {
        window.$Global.download(`/manager/invention/${invention_idx}/file/${file_idx}/download`);
    },
    downloadInventionOpenFile(invention_idx, open_idx, file_idx) {
        window.$Global.download(`/manager/invention/${invention_idx}/open/${open_idx}/file/${file_idx}/download`);
    },
    deleteInventionFile(invention_idx, file_idx) {
        return API.delete(`/manager/invention/${invention_idx}/file/${file_idx}`);
    },
    deleteInventionOpenFile(invention_idx, open_idx, file_idx) {
        return API.delete(`/manager/invention/${invention_idx}/open/${open_idx}/file/${file_idx}`);
    },
    uploadInventionFile(invention_idx, formData) {
        return API.post(`/manager/invention/${invention_idx}/file`, formData);
    },
    uploadInventionOpenFile(invention_idx, open_idx, formData) {
        return API.post(`/manager/invention/${invention_idx}/open/${open_idx}/file`, formData);
    }
};
