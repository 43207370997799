import { useRef } from "react";
//hook
function useMoveScroll(name) {
  const element = useRef(null);
  const onMoveToElement = () => {
    element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return { element, onMoveToElement, name };
}

export default useMoveScroll;
