import React, { useContext, useEffect, useState } from "react";
import "../css/AdminMarketSurvey.scss";
import AdminAPI from "../../../API/admin";
import _ from "lodash";
import { Link } from "react-router-dom";
import qs from "query-string";
import memberContext from "../../../store/Member";
import { unstable_ClassNameGenerator } from "@mui/material";

function AdminMarketSurvey({ history, location }) {
  const isPartTimer = !Boolean(useContext(memberContext).idx);
  const historyCategory = qs.parse(location.search).category;
  const { idx, type } = qs.parse(location.search);
  const isView = type == "view";
  const isEdit = type == "edit";
  const commonParam = `${location.pathname}?idx=${idx}&category=${historyCategory}`;
  const listPath = isPartTimer
    ? "/admin/AlbaMarket/list"
    : `/admin/manage/list?category=${historyCategory}`;
  const number = 0;
  const [form, setForm] = useState({
    image: null,
    content_image: null,
    category_list: [
      {
        category1_idx: -1, // 대분류idx(int)
        category2_idx: -1, // 중분류
        category3_idx: -1, // 소분류idx(int)
      },
    ],
    category1_idx: -1, // 대분류idx(int)
    category2_idx: -1, // 중분류
    category3_idx: -1, // 소분류idx(int)
    title: "", // 제목(string)
    content: "", // 내용 텍스트(string)
    sources: "", // 출처(string)
    made_at: 0, // 날짜
    keyword_list: "",
    chart_type: 0, // 차트 분류
    nondisclosure: 0,
  });
  const [category, setCategory] = useState([]);
  const [contentTypeText, setContentTypeText] = useState(false);
  const [chartType, setChartType] = useState({});
  const category_list = category.category_list || [];
  const category_list_find = category_list.find(
    (item) => item.idx == form?.category1_idx
  );
  const category_child_list = category_list_find?.child || [];
  const category_child_find = category_list_find?.child.find(
    (item) => item.idx == form?.category2_idx
  );

  const small_category_list = category?.small_category_list;
  const small_category_list_find = small_category_list?.find(
    (item) => item.idx == form?.category3_idx
  );
  useEffect(() => {
    if (idx) {
      AdminAPI.getMarketInfo(idx).then((res) => {
        let data = res.data;
        data.keyword_list = data.keyword_list.toString();

        if (data.content) {
          setContentTypeText(true);
        }
        data.category1_idx = data.category_list[0]?.category1_idx;
        data.category2_idx = data.category_list[0]?.category2_idx;
        data.category3_idx = data.category_list[0]?.category3_idx;
        setForm(data);
        console.log(data);
      });
    }
    getList();
    getChart();
  }, []);

  const getList = () => {
    AdminAPI.getMarketCategory().then((res) => {
      setCategory(res.data);
    });
  };

  const getChart = () => {
    AdminAPI.getMarketChartInfo().then((res) => {
      setChartType(res.data);
      Object.entries(chartType);
    });
  };
  const updateGo = () => {
    history.push(`${commonParam}&type=edit`);
  };

  const addPost = async () => {
    let copy = _.cloneDeep(form);

    if (copy.category1_idx == -1) {
      alert("분류를 선택해주세요");
      return;
    } else if (copy.category3_idx == -1) {
      alert("분야를 선택해주세요");
      return;
    }
    let keys = Object.keys(copy);
    let formData = new FormData();
    keys.forEach((key) => {
      if (
        key === "category_list" ||
        key === "category1_idx" ||
        key === "category2_idx" ||
        key === "category3_idx"
      ) {
        return;
      }
      if (copy[key] !== null) {
        formData.append(key, copy[key]);
      }
    });

    let list = [
      {
        category1_idx: copy.category1_idx,
        category2_idx: copy.category2_idx,
        category3_idx: copy.category3_idx,
      },
    ];
    formData.append("category_list", JSON.stringify(list));

    if (isEdit) {
      await AdminAPI.updateMarketInfo(idx, formData).then(() =>
        alert("수정되었습니다")
      );
    } else {
      await AdminAPI.addMarketInfo(formData).then(() =>
        alert("등록되었습니다")
      );
    }
    history.push(listPath);
  };

  const onChangeContentType = (e) => {
    if (e.target.id == "content_text") {
      setContentTypeText(true);
      onChangeForm("content_image", null);
    } else {
      setContentTypeText(false);
      onChangeForm("content", "");
    }
  };

  const onChangeChartType = (e) => {
    onChangeForm("chart_type", e.target.value);
  };

  const onChangeForm = (key, value) => {
    if (key == "image") {
      let name = value.name;
      setForm({
        ...form,
        [key]: value,
        title: name.substr(0, name.lastIndexOf(".")),
      });
    } else {
      setForm({ ...form, [key]: value });
    }
  };

  const onChangeCheckBox = (e) => {
    onChangeForm("nondisclosure", e.currentTarget.checked ? 1 : 0);
  };

  return (
    <div id="AdminMarketSurvey">
      <h2 className="page_title">시장 정보</h2>
      <div className="articles">
        <div className="field">
          <span>분야</span>
          {isView ? (
            <p>
              {category_list_find?.name}&nbsp;&gt;{category_child_find?.name}
            </p>
          ) : (
            <>
              <select
                value={form.category1_idx}
                onChange={(e) => onChangeForm("category1_idx", e.target.value)}
              >
                <option selected hidden>
                  선택
                </option>
                {category_list.map((item) => (
                  <option value={item.idx}>{item.name}</option>
                ))}
              </select>
              <p>&gt;</p>
              <select
                value={form.category2_idx}
                onChange={(e) => onChangeForm("category2_idx", e.target.value)}
              >
                <option selected hidden>
                  선택
                </option>
                {category_child_list.map((item) => (
                  <option value={item.idx}>{item.name}</option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="field">
          <span>분류</span>
          {isView ? (
            <p>{small_category_list_find?.name}</p>
          ) : (
            <select
              value={form.category3_idx}
              onChange={(e) => onChangeForm("category3_idx", e.target.value)}
            >
              <option selected hidden>
                선택
              </option>
              {small_category_list?.map((item) => (
                <option value={item.idx}>{item.name}</option>
              ))}
            </select>
          )}
        </div>
        <div className="field">
          <span>제목</span>
          {isView ? (
            <p>{form.title}</p>
          ) : (
            <input
              type="text"
              value={form.title}
              onChange={(e) => onChangeForm("title", e.target.value)}
            />
          )}
        </div>
        <div className="field">
          <span>날짜</span>
          {isView ? (
            <p>{window.$Global.convertDate(form.made_at)}</p>
          ) : (
            <input
              type="date"
              value={window.$Global.convertDate(form.made_at)}
              onChange={(e) =>
                onChangeForm("made_at", new Date(e.target.value).getTime())
              }
            />
          )}
        </div>
        <div className="field">
          <span>해시태그</span>
          {isView ? (
            <p>{form.keyword_list}</p>
          ) : (
            <input
              type="text"
              value={form.keyword_list}
              onChange={(e) => onChangeForm("keyword_list", e.target.value)}
              placeholder="쉼표로 구분해주세요 ex) 인공지능,전자기계"
            />
          )}
        </div>
        <div className="field">
          <span>사진</span>
          {isView ? (
            <img
              src={window.$Global.getCDN(form.image_key)}
              alt={"시장 정보 이미지"}
            />
          ) : (
            <input
              type="file"
              onChange={(e) => onChangeForm("image", e.target.files[0])}
            />
          )}
        </div>
        <div className="field">
          <span>글 타입</span>
          {isView ? (
            contentTypeText ? (
              "텍스트"
            ) : (
              "이미지"
            )
          ) : (
            <>
              <input
                type="radio"
                name="text_type"
                id="content_image"
                onChange={onChangeContentType}
                defaultChecked={!contentTypeText}
              />
              <label htmlFor="content_image">이미지</label>
              <input
                type="radio"
                name="text_type"
                id="content_text"
                onChange={onChangeContentType}
                defaultChecked={contentTypeText}
              />
              <label htmlFor="content_text">텍스트</label>
            </>
          )}
        </div>
        <div className="field">
          <span>글</span>
          {isView ? (
            contentTypeText ? (
              <p>{form.content}</p>
            ) : (
              <img
                src={window.$Global.getCDN(
                  form?.content_image_list?.length
                    ? form?.content_image_list[0]?.content_key
                    : ""
                )}
                alt={"시장 정보 글 이미지"}
              />
            )
          ) : contentTypeText ? (
            <textarea
              value={form.content}
              onChange={(e) => onChangeForm("content", e.target.value)}
            />
          ) : (
            <input
              type="file"
              onChange={(e) => onChangeForm("content_image", e.target.files[0])}
            />
          )}
        </div>
        <div className="field">
          <span>출처</span>
          {isView ? (
            <p>{form.sources}</p>
          ) : (
            <input
              type="text"
              value={form.sources}
              onChange={(e) => onChangeForm("sources", e.target.value)}
            />
          )}
        </div>
        <div className="field">
          <span>차트 분류</span>
          {isView ? (
            <>
              {Object.entries(chartType).map((el, idx) => {
                if (idx === form.chart_type) {
                  return <p>{el[0]}</p>;
                }
              })}
            </>
          ) : (
            <>
              {Object.entries(chartType).map((el, idx) => {
                return (
                  <>
                    <input
                      type="radio"
                      name="chart"
                      id={`chart_${idx}`}
                      onChange={onChangeChartType}
                      value={idx}
                      defaultChecked={idx === form.chart_type}
                    />
                    <label htmlFor={`chart_${idx}`}>{el[0]}</label>
                  </>
                );
              })}
            </>
          )}
        </div>
        <div className="field">
          <span className="unreleased">미공개 데이터바우처 해외자료</span>
          {isView ? (
            <>
              <input type="checkbox" checked={form.nondisclosure} />
            </>
          ) : (
            <>
              <input
                type="checkbox"
                value={form.nondisclosure || 0}
                checked={form.nondisclosure === 1}
                onChange={onChangeCheckBox}
              />
            </>
          )}
        </div>
      </div>
      <div className="wrap_btns">
        <button className="btn_register" onClick={isView ? updateGo : addPost}>
          {isView ? "수정" : isEdit ? "수정" : "등록"}
        </button>
        {(isView || isEdit) && (
          <Link to={listPath} className="btn_list">
            목록
          </Link>
        )}
      </div>
    </div>
  );
}

export default AdminMarketSurvey;
