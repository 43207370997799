import React, {useEffect, useState} from "react";
import "./css/CyberPointHistory.scss";
import CommonAPI from "../../API/common";
import DatePickerCustom from "../datepicker/DatePickerCustom";
import _ from "lodash";

function CyberPointHistory() {
    const today = new Date();
    const today_ms = today.getTime();
    const [startDate, setStartDate] = useState(today_ms);
    const [endDate, setEndDate] = useState(today_ms);
    const [selectMonth, setSelectMonth] = useState(1);
    const [resData, setResData] = useState({items: []});

    useEffect(() => {
        let copy = _.cloneDeep(today);
        copy.setMonth(copy.getMonth() - selectMonth);
        setStartDate(copy.getTime());

    }, [selectMonth]);

    const onClickSelect = () => {
        let payload = {
            page: 1,
            count: 1000,
            start_at: startDate,
            end_at: endDate

        };

        CommonAPI.getWithDrawAsCompany(payload).then(res => {
            console.log(res.data);
        });
    };

    return (
        <div id="CyberPointHistory">
            <h2 className="page_title">인출내역</h2>
            <p className="desc">
                <span className="blue">[대기중]</span> 출금 신청이 된 상태입니다.<br/>
                <span className="green">[입금완료]</span> 회원님의 통장으로 요청금액이 입금되었습니다.<br/>
                <span className="red">[계좌오류]</span> 계좌번호가 잘못되어 입금이 불가능합니다.<br/>
            </p>

            <div className="wrap_date">
                <h2>조회기간</h2>
                <div className="ctl_date">
                    <ul>
                        <li onClick={() => setSelectMonth(1)}>1개월</li>
                        <li onClick={() => setSelectMonth(3)}>3개월</li>
                        <li onClick={() => setSelectMonth(6)}>6개월</li>
                    </ul>

                    <div className="flex">
                        <div className="date">
                            <p>{window.$Global.convertDate(startDate)}</p>
                            <DatePickerCustom data={startDate}
                                              onChangeDatePicker={e => setStartDate(new Date(e).getTime())}
                            />
                        </div>
                        <p className="and">~</p>
                        <div className="date">
                            <p>{window.$Global.convertDate(endDate)}</p>
                            <DatePickerCustom data={endDate}
                                              onChangeDatePicker={e => setEndDate(new Date(e).getTime())}
                                              maxDate={today}
                            />
                        </div>
                        <button className="btn_select" onClick={onClickSelect}>조회</button>
                    </div>
                </div>
            </div>

            <div className="table-box-wrap">
                <div className="wrap_list">
                    <table>
                        <thead>
                            <tr>
                                <th>출금신청일</th>
                                <th>신청금</th>
                                <th>처리상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resData.items.map((item, idx) => {
                                    let state = "";
                                    switch (item.state) {
                                        case 0:
                                            state = "대기";
                                            break;
                                        case 1:
                                            state = "입금완료";
                                            break;
                                        case 2:
                                            state = "계좌오류";
                                            break;
                                        case 3:
                                            state = "이름 불일치";
                                            break;
                                    }
                                    return (
                                        <tr key={idx}>
                                            <td>{window.$Global.convertDate(item.created_at)}</td>
                                            <td>{item.amount}</td>
                                            <td>{state}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CyberPointHistory;