import React, {useContext, useEffect, useState} from "react";
import "./css/PopupTradeMarkAdd.scss";
import CheckBox from "../common/CheckBox";
import TradeMarkAPI from "../../API/trademark";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import CommonContext from "../../store/Common";

function PopupTradeMarkAdd({onClickSelect, onClose}) {
    const {isMobile} = useContext(CommonContext);
    const [list, setList] = useState({items: []});
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [checkList, setCheckList] = useState([]);

    useEffect(() => {
        getList();
    }, []);

    const Empty = () => {
        return <div/>
    };

    const ComponentWithDimmed = WithPreventScroll(Empty, isMobile);

    const getList = (next) => {
        let payload = {
            page: curPage,
            count: 100,
            options: 3
        };
        TradeMarkAPI.getTradeMarkListCompany(payload).then(res => {
            let copy_items = _.cloneDeep(res.data.items);
            let items = res.data.items;
            let original_items = _.cloneDeep(res.data.items);

            items = items.filter((item1, idx1) => {
                if (item1.name == null) return item1;
                return items.findIndex(item2 => {
                    return item1.name === item2.name;
                }) === idx1;
            });

            items = items.map(item => {
                item.class_arr = [];

                original_items.filter(item2 => item.name == item2.name).map(item3 => {
                    if (item3.class.length > 0) {
                        item.class_arr.push(item3.class[0].class);

                        if (item.idx != item3.idx) {
                            item.class = item.class.concat(item3.class);
                        }
                    }
                });

                item.class_arr.sort((a, b) => a - b);

                if (item.image_key == null) {
                   copy_items.find(item2 => {
                       if (item.name == item2.name) {
                           if (Boolean(item2.image_key)) {
                               item.image_key = item2.image_key;
                           }
                       }
                   })
               }
               return item;
            });

            if (next) {
                res.data.items = list.items.concat(items);
                let arr = new Array(items.length);
                setCheckList(checkList.concat(arr));
            } else {
                res.data.items = items;
                setCheckList(new Array(items.length));
            }

            setList(res.data);
            setCurPage(curPage + 1);

            if (res.data.last > res.data.page) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        });
    };

    const nextList = () => {
        getList(true);
    };

    const onClickChecked = (idx) => {
        let copy = _.cloneDeep(checkList);
        let checked = !copy[idx];
        copy = copy.fill(false);
        copy[idx] = checked;
        setCheckList(copy);
    };

    const onClickSuccess = () => {
        let selected_idx = checkList.findIndex(item => item == true);

        let selected_item = list.items[selected_idx];
        list.items[selected_idx].class_arr = selected_item.class_arr.filter((item, index) => selected_item.class_arr.indexOf(item) === index);

        onClickSelect(selected_item);
        onClose();
    };

    let tbody =
        list.items.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>
                        <CheckBox checked={checkList[idx] || false} onChangeChecked={() => onClickChecked(idx)}/>
                    </td>
                    <td>{item.name || '상표명이 없는 건 이미지로만 되어있는 도형상표입니다'}</td>
                    <td>{item.apply_number || 'N/A'}</td>
                </tr>
            )
        })

    return (
        <div id="PopupTradeMarkAdd">
            <ComponentWithDimmed/>
            <div className="header">
                <button className="icon_exit" onClick={onClose}/>
                <h2 className="page_title">상표 선택하기</h2>
            </div>
            <div className="trade_item_select">
                <div className="head">
                    <h2>나의 상표 목록</h2>
                    <div className="btns">
                        <button className="btn_select" onClick={onClickSuccess}>선택완료</button>
                        <button className="icon_exit pc" onClick={onClose}/>
                    </div>
                </div>
                <div className="table-box-wrap">
                    <div className="wrap_list" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={list.items.length}
                            next={nextList}
                            hasMore={hasMore}
                            scrollableTarget="scrollableDiv">
                            <table className="list">
                                <thead>
                                    <tr className="no_focus_outline">
                                        <th>선택</th>
                                        <th>상표 명칭</th>
                                        <th>출원 번호</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tbody}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupTradeMarkAdd;