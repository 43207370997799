import React from "react";
import SignupInputHead from "./SignupInputHead";

function SignupPersonKakao({
  signInfo,
  setSignInfo,
  checkList,
  onClickCheckEmail,
}) {
  return (
    <>
      <SignupInputHead title="SNS 간편 회원가입" />
      <div className="form_kakao">
        <div className="wrap_input kakao_top">
          <label htmlFor="user_phone" className="require">
            가입자 휴대폰
          </label>
          <div className="input_area">
            <input
              type="text"
              id="user_phone"
              maxLength="11"
              placeholder="가입자 휴대폰"
              value={signInfo.phone}
              onChange={(e) =>
                setSignInfo({
                  ...signInfo,
                  phone: window.$Global.isNumberInput(e.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="wrap_input kakao_bottom">
          <label htmlFor="user_email" className="require">
            이메일
          </label>
          <div className="input_area">
            <input
              type="text"
              id="user_email"
              className="has_btn"
              placeholder="이메일"
              value={signInfo.email}
              onChange={(e) =>
                setSignInfo({ ...signInfo, email: e.target.value })
              }
            />
            <button
              className={`btn_check ${checkList.checkEmail ? "btn_no" : ""}`}
              onClick={onClickCheckEmail}
            >
              중복확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupPersonKakao;
