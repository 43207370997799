import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/TaskMain.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import PopupTaskDetail from "./PopupTaskDetail";
import CommonAPI from "../../API/common";
import _ from "lodash";
import qs from "query-string";
import memberContext from "../../store/Member";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";
import config from "../../const/config";
import PopupTaskFile from "./PopupTaskFile";
import MobilePopupAttach from "../common/MobilePopupAttach";
import PopupMobileTaskDetail from "./PopupMobileTaskDetail";
import Const from "./const";
import { Link, useHistory, useLocation } from "react-router-dom";
import message from "../../const/message";
import TaskTable from "./organisms/TaskTable";
import IntroTaskCategoryTemp from "../intro/organisms/IntroTaskCategoryTemp";
import IntroRedirectList from "../intro/organisms/IntroRedirectList";
import IntroTaskCategory from "../intro/organisms/IntroTaskCategory";
import InfoCompanyAPI from "../../API/infoCompany";
import TaskSearchIp from "./TaskSearchIp";
import links from "../../const/links";
import { fixed } from "lodash/fp/_falseOptions";
import session1 from "../../assets/images/common/tasksearch.png";
function TaskMain() {
  const history = useHistory();
  const location = useLocation();
  // 무료 유저 토큰
  const token = config.findbizFreeToken;
  const count = 100;
  const { idx, searchKeyword, category } = qs.parse(location.search);
  const popTextDetail = "사업 상세페이지";
  const popTextDownload = "사업 다운로드";
  const tableRef = useRef();

  //window.localStorage.setItem("gwmode", gwmode);
  //console.log(location);
  //console.log(gwmode);
  const { isMobile } = useContext(CommonContext);
  const MobilePopup = useContext(MobilePopupContext);
  const memberInfo = useContext(memberContext);

  const defaultSort = 6; // 6 = 등록순
  const notLogin =
    window.$Global.isEmptyObject(memberInfo) ||
    memberInfo.idx == config.testerIdx;

  const [showType, setShowType] = useState(category || Const.showType.bizAll); // 0 전체보기, 1 정리된거 보기, 2 매칭 비즈
  const [stateType, setStateType] = useState(category || Const.stateType.all); // 4 접수중, 2 접수예정
  const [taskTotalCount, setTaskTotalCount] = useState(0);
  const [searchTotalCount, setSearchTotalCount] = useState(0);
  const [searchState, setSearchState] = useState(false); // 현재 상태가 검색한 상태인지 판별
  const [disableList, setDisableList] = useState({}); // 검색에 필요한 param 중 삭제할 값을 담는 변수

  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [resData, setResData] = useState({ items: [] });

  const [field, setField] = useState({}); // 조건 검색 필드 담는 변수

  const [keyword, setKeyword] = useState(searchKeyword || "");
  const [searchType, setSearchType] = useState({ sort: defaultSort }); // 검색에 필요한 param를 담는 변수
  const [excludeType, setExcludeType] = useState(0);
  const [popShow, setPopShow] = useState(false); // 상세보기 팝업 노출 여부
  const [moPopShow, setMoPopShow] = useState(false); // 모바일 상세보기 팝업 노출 여부
  const [filePopShow, setFilePopShow] = useState(false);
  const [detailInfo, setDetailInfo] = useState({}); // 상세보기 팝업 데이터를 담는 변수

  // 모바일
  const [moPopDownloadShow, setMoPopDownloadShow] = useState(false);
  const [animate, setAnimate] = useState(false); // 모바일 애니메이션용 변수

  // const bizAll = showType == Const.showType.bizAll;
  // const bizOrganized = showType == Const.showType.bizOrganized;
  // const bizMatching = showType == Const.showType.bizMatching;

  const bizAll = stateType == Const.stateType.all;
  const bizReceiving = stateType == Const.stateType.receiving;
  const bizScheduled = stateType == Const.stateType.scheduled;

  let defaultPayload = {
    page: 1,
    count: count,
    keyword: keyword,
    show_type: showType,
    state: stateType,
    exclude_termination: 0,
  };

  let timer;

  let freeViewCount = window.localStorage.getItem("taskFreeViewCount");
  let viewAble = freeViewCount == "null" || freeViewCount < 4;

  const freeNotify = resData.items.length > 0 && notLogin && !viewAble;
  const popHash = "#pop";

  useEffect(() => {
    if (location.hash !== popHash) {
      location.hash = "";
      setMoPopShow(false);
      MobilePopup.setMobilePopup({
        ...MobilePopup.mobilePopup,
        mobileShow: false,
        historyMobileHeaderTitle: "",
      });
    }
  }, [location]);

  useEffect(() => {
    // 헤더 close 이벤트 발생 시
    closeHeader();
  }, [MobilePopup.mobilePopup]);

  const hashTagPushHistory = (keyword) => {
    return history.push(`${links.taskManage}?searchKeyword=${keyword}`);
  };

  const closeHeader = () => {
    if (!MobilePopup.mobilePopup.mobileShow) {
      if (moPopShow && moPopDownloadShow) {
        // 둘 다 true 일 경우 상세 페이지 팝업에서 사업 다운로드가 열린 것이므로 사업 다운로드 팝업 숨김 처리
        setMoPopDownloadShow(false);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
          historyMobileHeaderTitle: "",
        });
      } else {
        setMoPopShow(false);
        setMoPopDownloadShow(false);
      }
    }
  };

  useEffect(() => {
    CommonAPI.getTaskTotalCount().then((res) => setTaskTotalCount(res.data));
  }, []);

  useEffect(() => {
    if (idx) {
      setTimeout(() => onClickDetail(idx), 1);
    }
  }, [idx]);

  useEffect(() => {
    setShowType(category || 0);
  }, [isMobile]);

  useEffect(() => {
    CommonAPI.getTaskField(showType).then((res) => setField(res.data));
  }, [showType]);

  useEffect(() => {
    setShowType(category || 0);
  }, [category]);

  useEffect(() => {
    setKeyword(searchKeyword);
    searchAPI({ keyword: searchKeyword });
  }, [searchKeyword]);

  useEffect(() => {
    // 리다이렉트로 넘어왔을 경우 keyword 캐시 리셋
    if (location.state === "delKeyword") setKeyword("");
  }, [location]);

  useEffect(() => {
    // 헤더 close 이벤트 발생 시
    if (!MobilePopup.mobilePopup.mobileShow) {
      if (moPopShow && moPopDownloadShow) {
        // 둘 다 true 일 경우 상세 페이지 팝업에서 사업 다운로드가 열린 것이므로 사업 다운로드 팝업 숨김 처리
        setMoPopDownloadShow(false);
        MobilePopup.setMobilePopup({
          ...MobilePopup.mobilePopup,
          mobileShow: true,
          mobileHeaderTitle: popTextDetail,
          historyMobileHeaderTitle: "",
        });
      } else {
        setMoPopShow(false);
        setMoPopDownloadShow(false);
      }
    }
  }, [MobilePopup.mobilePopup]);
  const searchAPI = (payloadAdd) => {
    // 매 호출마다 현재 페이지를 1로 초기화
    setCurPage(1);

    let payload = {
      ...defaultPayload,
      ...getSearchKeyword(),
      ...payloadAdd,
    };

    // 키워드 검색에 값이 있거나 기본 검색 외에 파라미터가 있으면 검색 취소 버튼 노출
    if (
      payload.keyword &&
      (payload.keyword?.length != 0 || Object.keys(payload).length > 5)
    ) {
      setSearchState(true);
    }
    getList(payload);
  };

  const getList = (payload, type) => {
    CommonAPI.getTaskList(payload).then((res) => {
      let data = res.data;
      setSearchTotalCount(res.data.total_count);
      if (type == "next") {
        data.items = resData.items.concat(data.items);
      } else {
        if (tableRef.current) {
          tableRef.current.scrollTo(0, 0);
        }
      }
      setResData(data);

      if (data.page == data.last) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };

  const nextList = () => {
    let searchKeyword = getSearchKeyword();

    let payload = {
      ...defaultPayload,
      ...searchKeyword,
      page: curPage + 1,
    };
    setCurPage(curPage + 1);
    getList(payload, "next");
  };

  const getSearchKeyword = () => {
    let searchKeyword = _.cloneDeep(searchType);

    if (disableList.annual) {
      delete searchKeyword.annual_min;
      delete searchKeyword.annual_max;
    }

    if (disableList.selectDate) {
      delete searchKeyword.select_start_at;
      delete searchKeyword.select_end_at;
    }

    if (disableList.sales) {
      delete searchKeyword.sales_min;
      delete searchKeyword.sales_max;
    }

    if (disableList.export) {
      delete searchKeyword.export_min;
      delete searchKeyword.export_max;
    }

    let keys = Object.keys(searchKeyword);

    keys.forEach((item, idx) => {
      if (item == "sort" || item == "annual_min" || item == "annual_max")
        return false;
      if (searchKeyword[item] <= 1) {
        delete searchKeyword[item];
      }
    });

    if (searchKeyword.select_start_at) {
      searchKeyword.reception_min = searchKeyword.select_start_at;
      searchKeyword.deadline_min = searchKeyword.select_start_at;
    }

    if (searchKeyword.select_end_at) {
      searchKeyword.reception_max = searchKeyword.select_end_at;
      searchKeyword.deadline_max = searchKeyword.select_end_at;
    }

    return searchKeyword;
  };

  const onClickConditionCancel = () => {
    setKeyword("");
    setCurPage(1);
    setSearchState(false);

    history.push({ search: `?searchKeyword=`, state: "TabChange" });

    getList({
      ...defaultPayload,
      sort: searchType.sort,
      keyword: "",
    });
  };

  const onClickInterestAdd = async (idx) => {
    await CommonAPI.addInterest(idx).then(() => {
      alert("관심공고에 추가되었습니다");
    });

    let copy = _.cloneDeep(resData);

    copy.items.map((item) => {
      if (item.idx == idx) {
        item.is_interest = !item.is_interest;
      }
    });
    setResData(copy);
  };

  const onClickInterest = async (idx, state) => {
    if (state) {
      await CommonAPI.deleteInterest(idx);
    } else {
      await CommonAPI.addInterest(idx);
    }

    let copy = _.cloneDeep(resData);

    copy.items.map((item) => {
      if (item.idx == idx) {
        item.is_interest = !item.is_interest;
      }
    });

    setResData(copy);
  };

  const onClickDetail = (idx) => {
    if (notLogin) {
      if (!viewAble) {
        setAnimate(true);
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          setAnimate(false);
        }, 400);
        return;
      } else {
        freeViewCount = isNaN(freeViewCount) ? 1 : Number(freeViewCount) + 1;
        window.localStorage.setItem("taskFreeViewCount", freeViewCount);
      }
    }
    getDetail(idx);
  };

  const getDetail = async (idx, typeFileShow) => {
    let result;
    if (notLogin) {
      result = await CommonAPI.getTaskInfo(idx, {
        headers: { "x-token": token },
      }).then((res) => res.data);
    } else {
      result = await CommonAPI.getTaskInfo(idx).then((res) => res.data);
    }
    setDetailInfo(result);

    if (typeFileShow) return;

    if (isMobile) {
      if (location.hash !== popHash) {
        history.push(location.search + popHash);
      }
      setMoPopShow(true);
      MobilePopup.setMobilePopup({
        ...MobilePopup.mobilePopup,
        mobileShow: true,
        mobileHeaderTitle: popTextDetail,
      });
    } else {
      setPopShow(true);
    }
  };

  const onChangeSearchType = (name, value) => {
    let copy = _.cloneDeep(searchType);
    copy[name] = value;
    setSearchType(copy);
    setCurPage(1);

    let searchParams = getSearchKeyword();

    getList({
      ...defaultPayload,
      ...searchParams,
      sort: value,
    });
  };

  const onClickFileDownload = (f_idx) => {
    CommonAPI.taskFileDownload(idx, f_idx);
  };

  const showMoPopupFile = () => {
    setMoPopDownloadShow(true);

    let payload = {
      ...MobilePopup.mobilePopup,
      mobileShow: true,
      mobileHeaderTitle: popTextDownload,
    };

    if (moPopShow) {
      payload.historyMobileHeaderTitle = popTextDownload;
    }

    MobilePopup.setMobilePopup(payload);
  };

  const changeShowType = async (type) => {
    await searchAPI({ show_type: type });
    setShowType(type);
    const urlParams = new URLSearchParams(window.location.search);
    const queryKey = "searchKeyword";
    const query = urlParams.get(queryKey);
    const addQuery = query ? `&${queryKey}=${query}` : "";
    history.push({
      search: `?category=${type}${addQuery}`,
      state: "TabChange",
    });
  };

  const changeStateType = async (state) => {
    await searchAPI({ state });
    setStateType(state);
    const urlParams = new URLSearchParams(window.location.search);
    const queryKey = "searchKeyword";
    const query = urlParams.get(queryKey);
    const addQuery = query ? `&${queryKey}=${query}` : "";
    history.push({ search: `?state=${state}${addQuery}`, state: "TabChange" });
  };

  const changeExcludeType = (state) => {
    searchAPI({ exclude_termination: state ? 1 : 0 });
  };
  const onClickFilePopShow = (item) => {
    setFilePopShow(true);
    getDetail(item.idx, true);
  };

  return (
    <div id="TaskMain">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>정부과제 검색</h2>
        </div>
        <div className="content">
          <div className="title">
            <p>
              <span>
                전국 1200여 개의 부처 · 공공기관 · 지자체에서 발주하는{" "}
              </span>
              <br />
              <span>조달 · R&D · 지원사업 정보를 Biznavi만의 </span>
              <br />
              <span>쉬운 공고 요약본으로 빠르게 확인해 보세요. </span>
            </p>
          </div>
          <div className="subtitle">
            <p>Tip</p>
            <p>
              관심 키워드를 설정해두시면,
              <br /> 신규 공고 업데이트 시 메일로 빠르게 알려드립니다.
            </p>
          </div>
          <img src={session1} alt="session1" />
        </div>
      </div>
      <div className="header">
        <div className="header_inner_search">
          <div className="header_keyword">
            <p onClick={() => hashTagPushHistory("메타버스")}>#메타버스</p>
            <p onClick={() => hashTagPushHistory("자율주행")}>#자율주행</p>
            <p onClick={() => hashTagPushHistory("드론")}>#드론</p>
            <p onClick={() => hashTagPushHistory("AI")}>#AI</p>
          </div>
          <div className="search_area">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="1500여 개 공공기관 사업 한 번에 찾기?"
              onKeyUp={(e) => {
                let urlData = `${links.taskManage}?searchKeyword=${keyword}`;
                if (e.key === "Enter") {
                  urlData = urlData.replace(/&/g, "%26").replace(/\+/g, "%2B");
                  history.push(urlData);
                }
              }}
            />
            {searchState ? (
              <button
                className="icon_del_small_with_bg"
                onClick={onClickConditionCancel}
              />
            ) : (
              <button
                className="icon_search_blue"
                onClick={() => {
                  let urlData = `${links.taskManage}?searchKeyword=${keyword}`;
                  urlData = urlData.replace(/&/g, "%26").replace(/\+/g, "%2B");
                  history.push(urlData);
                }}
              />
            )}
          </div>
        </div>
        {/*) : (*/}
        {/*  <div className="header_inner">*/}
        {/*    <h2 className="header_title">*/}
        {/*      <span>정부사업</span> 수주 받는*/}
        {/*      <br className="mo" /> <span>NO.1</span> 플랫폼*/}
        {/*    </h2>*/}
        {/*    <p className="header_desc pc">*/}
        {/*      1500여 개 공공기관의 정부 사업을 한 번에 찾고,*/}
        {/*      <br />*/}
        {/*      관심 공고 알림을 등록하여 원하는 사업을 가장 빠르게 받아보세요.*/}
        {/*    </p>*/}
        {/*    <div className="header_menu pc">*/}
        {/*      <div className="header_keyword">*/}
        {/*        <p onClick={() => hashTagPushHistory("메타버스")}>#메타버스</p>*/}
        {/*        <p onClick={() => hashTagPushHistory("인공지능")}>#인공지능</p>*/}
        {/*      </div>*/}
        {/*      <Link to="/taskManage/setting" className="btn_mail">*/}
        {/*        <i className="icon_notification_blue_full" />*/}
        {/*        <p>관심 공고 메일받기</p>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*    <div className="search_area">*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        value={keyword}*/}
        {/*        onChange={(e) => setKeyword(e.target.value)}*/}
        {/*        placeholder={message.whatSearchBiz}*/}
        {/*        onKeyUp={(e) => {*/}
        {/*          if (e.key === "Enter")*/}
        {/*            history.push(*/}
        {/*              `${links.taskManage}?searchKeyword=${keyword}`*/}
        {/*            );*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      {searchState ? (*/}
        {/*        <button*/}
        {/*          className="icon_del_small_with_bg"*/}
        {/*          onClick={onClickConditionCancel}*/}
        {/*        />*/}
        {/*      ) : (*/}
        {/*        <button*/}
        {/*          className="icon_search_gray"*/}
        {/*          onClick={() =>*/}
        {/*            history.push(`${links.taskManage}?searchKeyword=${keyword}`)*/}
        {/*          }*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      {location.pathname === "/" && (
        <>
          <IntroRedirectList />
          {!isMobile && <TaskSearchIp />}
        </>
      )}
      <div className="wrap">
        <div className="head">
          {!isMobile && (
            <div className="wrap_btns pc">
              <div className="btns">
                <Link to={"/taskManage/my"} className="btn_view">
                  <i className="icon_fav_on_tack" />
                  즐겨찾기한 공고
                </Link>
                <Link to="/taskManage/setting" className="btn_mail">
                  <i className="icon_mail_blue" />
                  <p>관심 공고 메일받기</p>
                </Link>
              </div>
            </div>
          )}
          <div className="inner remove_scrollbar">
            <div className="tabs">
              <button
                className={bizAll ? "active" : ""}
                onClick={() => changeStateType(Const.stateType.all)}
              >
                전체
                <br />
                <span>
                  {window.$Global.commaify(taskTotalCount.total_count || 0)}건
                </span>
              </button>
              <button
                className={bizReceiving ? "active" : ""}
                onClick={() => changeStateType(Const.stateType.receiving)}
              >
                접수중
                <br />
                <span>
                  {window.$Global.commaify(taskTotalCount.receiving || 0)}건
                </span>
              </button>
              <button
                className={bizScheduled ? "active" : ""}
                onClick={() => changeStateType(Const.stateType.scheduled)}
              >
                접수예정
                <br />
                <span>
                  {window.$Global.commaify(taskTotalCount.scheduled || 0)}건
                </span>
              </button>
              {/*<button className={bizMatching ? 'active' : ''} onClick={() => changeShowType(Const.showType.bizMatching)}>*/}
              {/*    민간 기업 사업*/}
              {/*    <br className="mo"/>*/}
              {/*    <span>{window.$Global.commaify(taskTotalCount.matching_biz_cnt || 0)}건</span>*/}
              {/*</button>*/}
            </div>
            <div className="count pc">
              <p>
                {keyword && `"${keyword}"`} 검색 결과
                <span>{window.$Global.commaify(searchTotalCount)}</span>건
              </p>
            </div>
          </div>
          <div className="wrap_btns pc">
            <div
              className="exclude_btn"
              onClick={() => {
                changeExcludeType(!excludeType);
                setExcludeType(!excludeType);
              }}
            >
              <i
                className={
                  excludeType ? "icon_checkbox_blue" : "icon_checkbox_gray"
                }
              />
              종료된 사업 제외
            </div>
          </div>
        </div>
        <div className="table-box-wrap">
          <div className={`wrap_list`} id="scrollableDiv" ref={tableRef}>
            <div>
              <div
                className={`not_login_msg ${freeNotify ? "show" : ""} ${
                  animate ? "animation" : ""
                }`}
                onClick={() => history.push("/signup/type")}
              >
                <i className="icon_badge_info_gray" />
                <p>
                  <span>가입 후 더많은 서비스</span>를 이용할 수 있습니다.
                </p>
                <div>무료 기업회원 가입하고 상세보기</div>
              </div>
            </div>
            <InfiniteScroll
              dataLength={resData.items.length}
              next={nextList}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              <TaskTable
                showType={showType}
                stateType={stateType}
                searchType={searchType}
                field={field}
                list={resData.items}
                onClickDetail={onClickDetail}
                onClickInterest={onClickInterest}
                onClickShowTaskFile={onClickFilePopShow}
                onChangeSearchType={onChangeSearchType}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <div className="mo">
        <IntroRedirectList />
      </div>

      <PopupTaskDetail
        show={popShow}
        item={detailInfo}
        field={field}
        onClickInterestAdd={onClickInterestAdd}
        onClose={() => setPopShow(false)}
      />
      <PopupMobileTaskDetail
        show={moPopShow}
        field={field}
        item={detailInfo}
        showMoPopupFile={showMoPopupFile}
      />

      <PopupTaskFile
        show={filePopShow}
        item={detailInfo}
        onClose={() => setFilePopShow(false)}
      />
      <MobilePopupAttach
        show={moPopDownloadShow}
        item={detailInfo.file}
        onDownload={onClickFileDownload}
      />
    </div>
  );
}

export default TaskMain;
