import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "./PatentMemo.scss";
import PatentAPI from "../../../API/patent";

function PatentMemo() {
    const {patent_idx} = useParams();
    const [memo, setMemo] = useState({
        memo: null,
        updated_at: null
    });
    const [edit, setEdit] = useState(false);
    const [historyMemo, setHistoryMemo] = useState();
    const [patentInfo, setPatentInfo] = useState({
        manage_number: '',
        nation: ''
    });

    useEffect(() => {
        PatentAPI.getInfo(patent_idx).then(res => setPatentInfo(res.data));
        PatentAPI.getMemo(patent_idx).then(res => {
            let data = res.data;
            if (!data.updated_at) {
                setEdit(true);
            }
            setMemo(data);
            setHistoryMemo(data);
        });
    }, []);

    const onClickCancel = () => {
        setEdit(false);
        setMemo(historyMemo);
    };

    const onClickSaveMemo = () => {
        if (!memo.memo?.length) {
            alert("내용을 입력해주세요");
            return;
        }
        let payload = {
            ...memo,
            memo: memo.memo.replace(/\n/g, '<br/>')
        };
        PatentAPI.updateMemo(patent_idx, payload).then(() => {
            window.$Global.saveToast();
            setEdit(false);
            setMemo(payload);
        });
    };

    return (
        <div id="PatentMemo">
            <div className="header">
                <h2 className="title">특허 메모</h2>
                <div className="patent_info">
                    관리번호 {patentInfo.manage_number != "null" ? patentInfo.manage_number : 'N/A'}
                    <i className={`icon_flag_${window.$Global.convertNationCodeToStr(patentInfo.nation)} flag`}/>
                </div>
                <div className="btns">
                    {
                        edit
                        ?   <>
                                <button className="btn_save" onClick={onClickSaveMemo}>저장</button>
                                {
                                    Boolean(memo?.updated_at) && <button onClick={onClickCancel}>취소</button>
                                }
                            </>
                        :   <button onClick={() => setEdit(true)}>수정</button>
                    }

                </div>
            </div>

            <div className="memo">
                <p>메모 내용</p>
                {
                    edit
                    ?   <textarea placeholder="해당 특허의 중요내용에 대해 기록해 놓으시고, 추후 활용하세요."
                                  value={memo.memo?.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')}
                                  onChange={e => setMemo({...memo, memo: e.target.value})} autoFocus/>
                    :   <div className="content" dangerouslySetInnerHTML={ {__html: memo.memo} }/>
                }
            </div>
        </div>
    );
}

export default PatentMemo;
