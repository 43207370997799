import React, {useContext, useEffect, useRef, useState} from "react";
import "./css/TradePost.scss";
import CheckBox from "../common/CheckBox";
import PopupTradeMarkAdd from "./PopupTradeMarkAdd";
import _ from "lodash";
import TradeAPI from "../../API/trade";
import CommonAPI from "../../API/common";
import Validator from "../../util/validator";
import config from "../../const/config";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import qs from "query-string";
import CommonContext from "../../store/Common";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";

function TradePost({history, location}) {
    const {isMobile} = useContext(CommonContext);
    const {edit} = qs.parse(location.search);
    const img_type = config.imgType;
    const [selectItem, setSelectItem] = useState({});
    const [preview, setPreview] = useState('');
    const [tradeInfo, setTradeInfo] = useState({
        price: "",
        proposal: 0
    });
    const [menuShow, setMenuShow] = useState(false);
    const fileRef = useRef();
    const priceRef = useRef();
    const hasSelectInfo = !window.$Global.isEmptyObject(selectItem);
    const isEdit = Boolean(edit);

    useEffect(() => {
        if (isMobile) {
            window.history.pushState(null, document.title, window.location.href);
        }
        window.addEventListener('popstate', closeBack);

        return () => {
            window.removeEventListener('popstate', closeBack);
        }
    }, []);

    useEffect(() => {
        if (edit) {
            CommonAPI.getInfo(edit).then(res => {
                let data = res.data;
                res.data.class_arr = window.$Global.deduplicationToJSON(data.class, "class").sort((a, b) => a.class - b.class);

                setSelectItem({
                    image_key: data.thumbnail_key,
                    class_arr: data.class_arr.map(item => item.class),
                    class: data.class,
                    name: data.td_list[0].name,
                    apply_number: data.td_list[0].apply_number,
                });

                let payload = {
                    name: data.name,
                    apply_number: data.apply_number || 'N/A',
                    comment: data.comment,
                    price: data.price,
                    td_idx: data.td_list[0].idx,
                    thumbnail_key: data.thumbnail_key,
                    proposal: data.proposal,
                    file: [],
                    class: data.class,
                    class_arr: data.class_arr
                };
                setTradeInfo(payload);
            });
        }
    }, []);

    const Empty = () => {
        return <div/>
    };

    const ComponentWithDimmed = WithPreventScroll(Empty, isMobile);

    const closeBack = () => {
        setMenuShow(false);
        window.history.pushState(null, document.title, window.location.href);
    }

    const numberRegx = (str) => {
        let copy = _.cloneDeep(tradeInfo);
        copy.price = window.$Global.numberRegx(str);
        setTradeInfo(copy);
    }

    const onChange = (type, value) => {
        let copy = _.cloneDeep(tradeInfo);
        copy[type] = value;
        setTradeInfo(copy);
    }

    const onClickTradeAdd = () => {
        if (!hasSelectInfo) {
            alert("판매할 상표를 선택해주세요");
            return
        }

        if (!Boolean(tradeInfo.thumbnail_key) && !Boolean(preview)) {
            alert("상표 이미지를 업로드해주세요");
            return;
        }

        if (!Boolean(selectItem.class)) {
            alert("상표 분류가 추가되지 않은 상표입니다");
            return;
        }

        if (Validator.refValidator([priceRef])) {
            let formData = new FormData();

            for (let key in tradeInfo) {
                formData.append(key, tradeInfo[key]);
            }

            if (isEdit) {
                TradeAPI.updateTrade(edit, formData).then(res => {
                    alert("수정되었습니다");
                    history.replace(`/trade/view/${edit}`);
                });
            } else {
                TradeAPI.addTrade(formData).then(res => {
                    alert("등록되었습니다");
                    history.replace('/trade/list');
                });
            }
        }
    };

    const onClickSelect = (item) => {
        let copy = _.cloneDeep(tradeInfo);
        copy.td_idx = item.idx;
        copy.thumbnail_key = item.image_key;

        setTradeInfo(copy);
        setSelectItem(item);
    };

    const onChangeImage = (value) => {
        let file = value[0];
        let filename = file.name.split('.');
        if (img_type.test(filename[filename.length - 1].toLowerCase().toString())) {
            onChange("file", file);
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                setPreview(e.target.result);
            }
        } else {
            alert("jpg, jpeg, gif, png만 업로드하실 수 있습니다");
            return;
        }
    }

    if (edit && window.$Global.isEmptyObject(tradeInfo)) return null;

    return (
        <div id="TradePost">
            <div className="pc">
                <div className="header">
                    <p>상표 판매 {isEdit ? '수정' : '등록'}하기</p>
                </div>
                <div className="trade_item_add">
                    <div className="head">
                        <h2>판매할 상표*</h2>
                        <button onClick={() => setMenuShow(true)}>상표 추가하기</button>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>상표 명칭</th>
                            <th>출원번호</th>
                            <th>삭제</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            hasSelectInfo
                                ?
                                <tr>
                                    <td>{selectItem.name || 'N/A'}</td>
                                    <td>{selectItem.apply_number || 'N/A'}</td>
                                    <td>
                                        <button className="icon_badge_del" onClick={() => setSelectItem({})}/>
                                    </td>
                                </tr>
                                : window.$Global.notTd(3, '판매할 상표를 추가해주세요')
                        }
                        </tbody>
                    </table>
                </div>

                <div className="wrap">
                    <div className="image">
                        <div className="inner">
                            <h2>상표 확인하기*</h2>
                            {
                                hasSelectInfo
                                &&
                                <button className="icon_upload" onClick={() => fileRef.current.click()}/>
                            }
                        </div>
                        <div className="btn_camera">
                        {
                            (selectItem.image_key || preview)
                            ?
                            <>
                                <img src={Boolean(preview) ? preview : window.$Global.getCDN(selectItem.image_key)}
                                     onClick={() => window.$Global.imagePopup(window.$Global.getCDN(selectItem.image_key))}
                                />
                                {
                                    Boolean(preview) &&
                                    <button className="icon_badge_del" onClick={() => setPreview(null)}/>
                                }
                            </>
                            :   <p>이미지를 업로드해주세요</p>
                        }
                        </div>
                    </div>
                    <div className="info">
                        <div className="row">
                            <h2>상표 명칭</h2>
                            <p>{tradeInfo.name}</p>
                        </div>
                        <div className="row">
                            <h2>출원번호</h2>
                            <p>{tradeInfo.apply_number}</p>
                        </div>
                        <div className="wrap">
                            <div className="row space-between">
                                <h2>구매자에게 <br/>가격 역제안 받기</h2>
                                <ToggleButtonWithSpan condition={{on: '허용', off: '거부'}}
                                                      selected={Boolean(tradeInfo.proposal)}
                                                      toggleSelected={e => onChange('proposal', Number(!Boolean(tradeInfo.proposal)))}
                                />
                            </div>
                            <div className="row">
                                <h2>판매 가격 입력</h2>
                                <input type="text"
                                       placeholder="판매 가격을 입력해주세요"
                                       ref={priceRef}
                                       data-name="판매 가격"
                                       value={window.$Global.commaify(tradeInfo.price)}
                                       onChange={e => numberRegx(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment_area">
                    <h2>판매자 Comment</h2>
                    <textarea value={tradeInfo.comment} onChange={e => onChange("comment", e.target.value)}/>
                </div>
                <div className="classification_area">
                    <h2>상표분류*</h2>
                    <p>
                        {
                            selectItem.class_arr?.map((item) => item + '류').join(', ')
                        }
                    </p>
                </div>
                <div className="product_area">
                    <h2>지정상품정보*</h2>
                    <div className="list">
                        {
                            selectItem.class_arr?.map((item, c_idx) => {
                                return (
                                    <div className="item">
                                        <p className="title">
                                            제{item}류
                                        </p>
                                        <p className="value">
                                            {
                                                selectItem.class?.map(item2 => {
                                                    if (item == item2.class) {
                                                        return <span>{item2.product}</span>;
                                                    }
                                                    return '';
                                                })
                                            }
                                        </p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="mo">
                <ComponentWithDimmed/>
                <div className="header">
                    <button className="icon_more_arrow_left_black" onClick={() => isEdit ? history.replace(`/trade/view/${edit}`) : history.push('/trade/list')}/>
                    <h2 className="page_title">상표 판매 등록</h2>
                </div>
                <div className="trade_item_add">
                    <div className="head">
                        <h2>판매할 상표*</h2>
                        <button onClick={() => setMenuShow(true)}>상표 추가하기</button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>상표 명칭</th>
                                <th>출원번호</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                hasSelectInfo
                                ?
                                    <tr>
                                        <td>{selectItem.name}</td>
                                        <td>{selectItem.apply_number || 'N/A'}</td>
                                        <td>
                                            <button className="icon_badge_del_white" onClick={() => setSelectItem({})}/>
                                        </td>
                                    </tr>
                                : window.$Global.notTd(3, '판매할 상표를 추가해주세요')
                            }
                        </tbody>
                    </table>
                </div>
                <div className="trade_item_image">
                    <div className="inner">
                        <h2>상표 확인하기*</h2>
                        <p>상표 사진을 확인 해 주세요</p>
                    </div>
                    <div className="images">
                        {
                            (selectItem.image_key || preview)
                            &&
                            <>
                                <div className="btn_camera">
                                    <img src={Boolean(preview) ? preview : window.$Global.getCDN(selectItem.image_key)}
                                         onClick={() => window.$Global.imagePopup(window.$Global.getCDN(selectItem.image_key))}
                                    />
                                    {
                                        Boolean(preview) &&
                                        <button className="icon_badge_del" onClick={() => setPreview(null)}/>
                                    }
                                </div>
                            </>
                        }
                        {
                            hasSelectInfo
                            &&
                            <button className="btn_camera">
                                <i className="icon_camera" onClick={() => fileRef.current.click()}/>
                                <input type="file" ref={fileRef} onChange={e => onChangeImage(e.target.files)} hidden/>
                            </button>
                        }
                    </div>
                </div>
                <div className="trade_item_class">
                    <h2>상표분류*</h2>
                    <p>
                        {
                            selectItem.class_arr?.map((item, idx) => {
                                return <span key={idx}>{item}류</span>
                            })
                        }
                    </p>
                </div>

                <div className="trade_item_comment">
                    <h2>Comment</h2>
                    <textarea placeholder="판매할 상품의 적고 싶은 내용이 있다면 작성해주세요"
                              value={tradeInfo.comment}
                              onChange={e => onChange('comment', e.target.value)}
                    />
                </div>

                <div className="trade_item_price">
                    <h2>판매 가격 입력*</h2>
                    <div className="wrap_price">
                        <input type="text"
                               placeholder="판매 가격을 입력해주세요"
                               ref={priceRef}
                               data-name="판매 가격"
                               value={window.$Global.commaify(tradeInfo.price)}
                               onChange={e => numberRegx(e.target.value)}
                       />
                        <CheckBox text="구매자에게 가격 역제안 받기" checked={Boolean(tradeInfo.proposal)} onChangeChecked={(e) => onChange('proposal', Number(e.target.checked))}/>
                    </div>
                </div>
            </div>
            {
                menuShow
                &&
                <PopupTradeMarkAdd onClickSelect={onClickSelect}
                                   onClose={() => setMenuShow(false)}
                />
            }

            <button className="btn_sell_add" onClick={onClickTradeAdd}>{isEdit ? '수정 완료' : '판매 등록'}</button>
        </div>
    )
}

export default TradePost;