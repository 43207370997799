let real_url = "https://" + window.location.hostname;
let test_real_url = "https://www.biznavi.co.kr";
let test_url = "https://www.ipnow-dev.co.kr";
let base =
  process.env.NODE_ENV === "production"
    ? real_url
    : window.location.href.indexOf(test_url) > -1
    ? test_url
    : test_real_url;

// 기업정보
let v4_test_url = "https://dev.biznavi.co.kr/api/v4";
let v4_base =
  process.env.NODE_ENV === "production"
    ? real_url
    : window.location.href.indexOf(test_url) > -1
    ? test_url
    : v4_test_url;

// 그룹웨어 도메인 추가
let gw_test_url = "http://127.0.0.1";
let gw_real_url = "http://localhost";
let gw_base =
  process.env.NODE_ENV === "production"
    ? gw_real_url
    : window.location.href.indexOf(test_url) > -1
    ? test_url
    : gw_test_url;

if (window.location.hostname === "localhost") {
  base = test_real_url;
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "127.0.0.1") {
  base = "http://127.0.0.1:8081";
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "221.151.187.148") {
  base = test_url;
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "54.180.76.88") {
  base = "http://54.180.76.88";
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "221.151.187.104") {
  base = "http://221.151.187.104:9100";
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "192.168.0.239") {
  base = test_real_url;
  gw_base = gw_test_url;
  v4_base = v4_test_url;
} else if (window.location.hostname === "192.168.0.124") {
  base = "http://192.168.0.124:8081";
  v4_base = v4_test_url;
} else {
  base = gw_base = ""; //"http://" + window.location.hostname;
}

export default {
  url: base,
  gwUrl: gw_base,
  v4Url: v4_base,
  testerIdx: "7IQDL4OVK90XKYDB",
  emailReg:
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
  idReg: /^[^\uAC00-\uD7A3!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~`-]+$/,
  imgType: /^jpg$|^jpeg$|^gif$|^png$|^JPG$|^JPEG$|^PNG$|^GIF$/,
  imgTypeCheck: /(.*?)\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/,
  pdfTypeCheck: /(.*?)\.(pdf|PDF)$/,
  checkReg: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+-]).{8,16}$/,
  regxMsg: "8~16자 영문, 숫자, 특수문자를 하나 이상 포함해주세요.",
  introSessionKey: "introViewIp",
  keyToken: "token",
  gwKeyToken: "gwtoken",
  findbizFreeToken:
    //"6c9a5dcde84a16bc1424c519a3e3d11d:591bd3faf303de843ca6c7ad4bd6f0830883df869c88171c739b526fc726d96968ec466815fb0862cdef9e32ccc3153a693d09c7ea2f1dad1d3140b530093bfcf8b0e3048c73ebf61f0af4f7ec6fc3a2b4d9cb42b4437a7ae70fd53bc119bdf1c1c4ebb315317617618b209349f3a82f997f80d42953eb8159bfedc385d92c72a0422e7cea7ac3b23a82f7c5eb35e5176c44dab9b6e9ba71f5d924be2956bcc56c266f3168ea441f8105cb44e3ee9027baab5022e40ba385aecfbf2744ab65bfb5477af4dc4c82f38fb1b787e446dea6bbeb5fb445db5b49907cb28addd2453adc48183486a17b9ac5bcae3100e773a15b3b7443cb13ac618caca5e1c1df5ca9d9a50126519d60811c3b01864467cdcd9eb8a8577afed4d26abe92686fd76091",
    "7d19d75cd5a940a956f6b787ef66b479:15a46d68b7313e541e4c80984e12070e209af7df617badfd6d6aa78560a68cb28ce32cc8b6fd479789850c5420bc0372532b6b4706b12a30f8ffe7c399961c2480c129045cad6d51110459ff622192da4ef7085ffddec730e5d35a420809952bf00d399427f85ba85a2defa0d652009c6022311d8a64e4d6708298f39d215f4d65ad73d95438118e68b26e2bcc3d9411d84a4bbbd05dfbd21d12672e79221c0e78de93f51bf5d7c86e05aae823a2503029c5aa7b6c0eb1b7408f17fcb893067207f40b4fa968f60d3688fcabf4a8b3d47310e4ab1ada96cd55717646b54ad7b8dee1b7a57909299568a789be893a8382952cfcebcd8a6b3c084ce8b3e5a42270049524c645035492860d0d10b8019a7092a923d5e71ef9fb5ae0e702c47e67f6f1d3a070aef54d99b97cdf504dd68ff757f11361f839e6742c3b8968b56ba12e70ed46df1aeb3d8febd9dea27ef24f78",
};
