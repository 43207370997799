import React from "react";

function DataInput({edit, className, title, inputType, text, placeholder, setRef, onChange, btnShow, btnText, onClick}) {
    return (
        <div className="data">
            <p className="title">{title}</p>
            {
                edit
                ?
                    <>
                        <input type={inputType ? inputType : "text"}
                               className={`${className} ${btnShow ? 'btn' : ''}`}
                               defaultValue={text}
                               placeholder={placeholder}
                               onChange={onChange}
                               data-name={title}
                               ref={setRef}
                        />
                        {
                            btnShow && <button onClick={onClick}>{btnText}</button>
                        }
                    </>
                : <p className="value">{text}</p>
            }
        </div>
    );
};

export default DataInput;
