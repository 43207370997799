import API from '../../util/api';

export default {
    addTradeMark(payload) {
        return API.post('/manager/trademark', payload);
    },
    getTradeMarkListCompany(param) {
        return API.get('/manager/trademark', {params: param});
    },
    getTradeMarkListAgent(company_idx, param) {
        return API.get(`/agent/company/${company_idx}/trademark`, {params: param});
    },
    getTradeMarkInfo(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}`);
    },
    getTradeMarkInfoAgent(company_idx, trademark_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}`);
    },
    getSortFieldCompany() {
        return API.get('/manager/trademark/field');
    },
    getSorFieldAgent(manage_company_idx) {
        return API.get(`/agent/company/${manage_company_idx}/trademark/field`);
    },
    updateTradeMarkCompany(trademark_idx, payload) {
        return API.patch(`/manager/trademark/${trademark_idx}`, payload);
    },
    updateTradeMarkAgent(trademark_idx, company_idx, payload) {
        return API.patch(`/agent/company/${company_idx}/trademark/${trademark_idx}`, payload);
    },
    // oa 생성
    createOa(trademark_idx) {
        return API.post(`/manager/trademark/${trademark_idx}/oa/create`);
    },
    createOaAgent(trademark_idx, company_idx) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/create`);
    },
    updateOa(trademark_idx, oa_idx, payload) {
        return API.patch(`/manager/trademark/${trademark_idx}/oa/${oa_idx}`, payload);
    },
    // 상표 이미지 다운
    downloadTradeMarkImage(trademark_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/download`);
    },
    downloadTradeMarkImageAgent(trademark_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/download`);
    },
    // 상표 선행기술 조사 의뢰
    getCompanySurveyFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/survey`);
    },
    getAgentSurveyFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/survey`);
    },
    uploadCompanySurveyFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/survey`, payload);
    },
    uploadAgentSurveyFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/survey`, payload);
    },
    downloadCompanySurveyFile(trademark_idx, survey_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/survey/${survey_idx}/download`);
    },
    downloadAgentSurveyFile(trademark_idx, survey_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/survey/${survey_idx}/download`);
    },
    deleteCompanySurveyFile(trademark_idx, survey_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/survey/${survey_idx}`);
    },
    deleteAgentSurveyFile(trademark_idx, company_idx, survey_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/survey/${survey_idx}`);
    },

    // 상표 출원
    getCompanyApplyFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/apply`);
    },
    getAgenApplyFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/apply`);
    },
    uploadCompanyApplyFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/apply`, payload);
    },
    uploadAgentApplyFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/apply`, payload);
    },
    downloadCompanyApplyFile(trademark_idx, survey_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/apply/${survey_idx}/download`);
    },
    downloadAgentApplyFile(trademark_idx, survey_idx, company_idx,) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/apply/${survey_idx}/download`);
    },
    deleteCompanyApplyFile(trademark_idx, survey_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/apply/${survey_idx}`);
    },
    deleteAgentApplyFile(trademark_idx, company_idx, survey_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/apply/${survey_idx}`);
    },

    // oa 발행일
    getCompanyOaContentFiles(trademark_idx, oa_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/content`);
    },
    getAgentOaContentFiles(trademark_idx, company_idx, oa_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content`);
    },
    uploadCompanyOaContentFile(trademark_idx, oa_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/content`, payload);
    },
    uploadAgentOaContentFile(trademark_idx, company_idx, oa_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content`, payload);
    },
    downloadCompanyOaContentFile(trademark_idx, file_idx, oa_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/content/${file_idx}/download`);
    },
    downloadAgentOaContentFile(trademark_idx, oa_idx, content_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}/download`);
    },
    deleteCompanyOaContentFile(trademark_idx, oa_idx, content_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}`);
    },
    deleteAgentOaContentFile(trademark_idx, company_idx, oa_idx, content_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}`);
    },
    // oa 의견서 접수
    getCompanyOaOpinionFiles(trademark_idx, oa_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion`);
    },
    getAgentOaOpinionFiles(trademark_idx, company_idx, oa_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion`);
    },
    uploadCompanyOaOpinionFile(trademark_idx, oa_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion`, payload);
    },
    uploadAgentOaOpinionFile(trademark_idx, company_idx, oa_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion`, payload);
    },
    downloadCompanyOaOpinionFile(trademark_idx, file_idx, oa_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${file_idx}/download`);
    },
    downloadAgentOaOpinionFile(trademark_idx, content_idx, oa_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${content_idx}/download`);
    },
    deleteCompanyOaOpinionFile(trademark_idx, oa_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${file_idx}`);
    },
    deleteAgentOaOpinionFile(trademark_idx, company_idx, oa_idx, opinion_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${opinion_idx}`);
    },
    // oa 출원서
    getCompanyOaApplyFiles(trademark_idx, oa_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply`);
    },
    getAgentOaApplyFiles(trademark_idx, company_idx, oa_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply`);
    },
    uploadCompanyOaApplyFile(trademark_idx, oa_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply`, payload);
    },
    uploadAgentOaApplyFile(trademark_idx, company_idx, oa_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply`, payload);
    },
    downloadCompanyOaApplyFile(trademark_idx, file_idx, oa_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply/${file_idx}/download`);
    },
    downloadAgentOaApplynFile(trademark_idx, oa_idx, content_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply/${content_idx}/download`);
    },
    deleteCompanyOaApplyFile(trademark_idx, oa_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply/${file_idx}`);
    },
    deleteAgentOaApplyFile(trademark_idx, company_idx, oa_idx, reply_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply/${reply_idx}`);
    },
    // 상표 이의신청
    getCompanyObjectionContentFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/objection/content`);
    },
    getAgentObjectionContentFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content`);
    },
    uploadCompanyObjectionContentFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/objection/content`, payload);
    },
    uploadAgentObjectionContentFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content`, payload);
    },
    downloadCompanyObjectionContentFile(trademark_idx, file_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/objection/content/${file_idx}/download`);
    },
    downloadAgentObjectionContentFile(trademark_idx, content_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content/${content_idx}/download`);
    },
    deleteCompanyObjectionContentFile(trademark_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/objection/content/${file_idx}`);
    },
    deleteAgentObjectionContentFile(trademark_idx, company_idx, content_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content/${content_idx}`);
    },
    // 상표 이의신청 의견서
    getCompanyObjectionOpinionFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/objection/opinion`);
    },
    getAgentObjectionOpinionFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion`);
    },
    uploadCompanyObjectionOpinionFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/objection/opinion`, payload);
    },
    uploadAgentObjectionOpinionFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion`, payload);
    },
    downloadCompanyObjectionOpinionFile(trademark_idx, file_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/objection/opinion/${file_idx}/download`);
    },
    downloadAgentyObjectionOpinionFile(trademark_idx, content_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion/${content_idx}/download`);
    },
    deleteCompanyObjectionOpinionFile(trademark_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/objection/opinion/${file_idx}`);
    },
    deleteAgentObjectionOpinionFile(trademark_idx, company_idx, content_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion/${content_idx}`);
    },
    // 상표 등록 자료
    getCompanyRegistFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/regist`);
    },
    getAgentRegistFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/regist`);
    },
    uploadCompanyRegistFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/regist`, payload);
    },
    uploadAgentRegistFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/regist`, payload);
    },
    downloadCompanyRegistFile(trademark_idx, file_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/regist/${file_idx}/download`);
    },
    downloadAgentyRegistFile(trademark_idx, content_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/regist/${content_idx}/download`);
    },
    deleteCompanyRegistFile(trademark_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/regist/${file_idx}`);
    },
    deleteAgentRegistFile(trademark_idx, company_idx, regist_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/regist/${regist_idx}`);
    },
    // 상표 1차 갱신 만료 자료
    getCompanyRenewalExpirationFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/renewal/expiration`);
    },
    getAgentRenewalExpirationFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration`);
    },
    uploadCompanyRenewalExpirationFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/renewal/expiration`, payload);
    },
    uploadAgentRenewalExpirationFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration`, payload);
    },
    downloadCompanyRenewalExpirationFile(trademark_idx, file_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/renewal/expiration/${file_idx}/download`);
    },
    downloadAgentRenewalExpirationFile(trademark_idx, expiration_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration/${expiration_idx}/download`);
    },
    deleteCompanyRenewalExpirationFile(trademark_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/renewal/expiration/${file_idx}`);
    },
    deleteAgentRenewalExpirationFile(trademark_idx, company_idx, expiration_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration/${expiration_idx}`);
    },
    // 상표 갱신 만료 자료
    getCompanyRenewalFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/renewal`);
    },
    getAgentRenewalFiles(trademark_idx, company_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal`);
    },
    uploadCompanyRenewalFile(trademark_idx, payload) {
        return API.post(`/manager/trademark/${trademark_idx}/renewal`, payload);
    },
    uploadAgentRenewalFile(trademark_idx, company_idx, payload) {
        return API.post(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal`, payload);
    },
    downloadCompanyRenewalFile(trademark_idx, file_idx) {
        window.$Global.download(`/manager/trademark/${trademark_idx}/renewal/${file_idx}/download`);
    },
    downloadAgentRenewalFile(trademark_idx, renewal_idx, company_idx) {
        window.$Global.download(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/${renewal_idx}/download`);
    },
    deleteCompanyRenewalFile(trademark_idx, file_idx) {
        return API.delete(`/manager/trademark/${trademark_idx}/renewal/${file_idx}`);
    },
    deleteAgentRenewalFile(trademark_idx, company_idx, renewal_idx) {
        return API.delete(`/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/${renewal_idx}`);
    },
    // 상표 연관 파일들 보기
    getTradeMarkRelativeFiles(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/relation_file`);
    },
    getTradeMarkRelativeFilesAsAgent(company_idx, trademark_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/relation_file`);
    },
    // 상표 진행 사항 보기
    getTradeMarkProgress(trademark_idx) {
        return API.get(`/manager/trademark/${trademark_idx}/progress`);
    },
    // 상표 진행 사항 보기
    getTradeMarkProgressAsAgent(company_idx, trademark_idx) {
        return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}/progress`);
    },
    getClass() {
        return API.get(`/manager/trademark/class`);
    },
    getSearchClass(page, count, td_class) {
        return API.get(`/manager/trademark/class_product`, {params: {page:page, count:count, td_class:td_class}});
    }
};
