export const groupWareEmpPath = [
  {
    name: "조직관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/emp?section=corp&command=lstCorp",
        name: "법인정보 관리",
      },
      {
        url: "/groupware/emp?section=branch&command=listBranch",
        name: "조직도 관리",
      },
      {
        url: "/groupware/emp?section=code&command=lstCode",
        name: "직위/직무 관리",
      },
    ],
  },
  {
    name: "인사관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/emp?section=empAdmin&command=lstEmp",
        name: "직원 조회",
      },
      //{
      //   url: "/groupware/emp?section=recruit&command=insRecruit",
      //   name: "직원 개별 등록",
      // },
      {
        url: "/groupware/emp?section=batchmain&command=writeForm",
        name: "직원 일괄 등록",
      },
      {
        url: "/groupware/emp?section=personnel&command=lstPersonnel",
        name: "인사이동 처리",
      },
      {
        url: "/groupware/emp?section=personnel&command=lstPersonnelWork",
        name: "인사이동 조회",
      },
    ],
  },
];

export const groupwareMypagePath = [
  {
    name: "마이페이지",
    isOpen: true,
    children: [
      {
        url: "/groupware/mypage?section=empAuth&command=myinfoFrm",
        name: "기본정보 수정",
      },
      {
        url: "/groupware/mypage?section=empAuth&command=passwdFrm",
        name: "비밀번호 변경",
      },
      /*
      {
        url: "/groupware/mypage?section=conAuth&command=lstCustomerContractor",
        name: "거래처 관리",
      },
      */
      /*
      {
        url:"/groupware/mypage?section=impInfo&command=showImportantList",
        name:"중요 업무",
      },
      */
      {
        name: "결재관리",
        isOpen: true,
        children: [
          {
            url: "/groupware/mypage?section=empAuth&command=alarmFrm",
            name: "결재알림설정",
          },
          /*
          {
            url: "/groupware/mypage?section=empAuth&command=approvalPasswdFrm",
            name: "결재비밀번호설정",
          },
          */
          {
            url: "/groupware/mypage?section=approvalPer&command=lstApproval",
            name: "개인결재선관리",
          },
          {
            url: "/groupware/mypage?section=approvalPer&command=lstRecipient",
            name: "개인수신자관리",
          },
        ],
      },
      {
        url: "/competitor/my/recent",
        name: "기업정보 관리",
        isOpen: true,
        children: [
          {
            url: "/competitor/my/recent",
            name: "최근 조회한 기업",
          },
          {
            url: "/competitor/my/interest",
            name: "관심기업",
          },
          {
            url: "/competitor/my/clt?type=1",
            name: "거래처 기업",
          },
          {
            url: "/competitor/my/clt?type=2",
            name: "경쟁사",
          },
        ],
      },
    ],
  },
];

export const groupwareAdminmenuPath = [
  {
    name: "관리자",
    isOpen: true,
    children: [
      {
        url: "/groupware/adminmenu?section=assignmenu&command=lstMenuAuth",
        name: "메뉴권한등록",
      },
      {
        url: "/groupware/adminmenu?section=service&command=getCapacitySetList",
        name: "용량 사용설정",
      },
      {
        url: "/groupware/payment/list",
        name: "결제내역 조회",
      },
      // {
      //   url: "/groupware/adminmenu?section=domains&command=logoConfig",
      //   name: "로고관리",
      // },
    ],
  },
  /**
  {
    url: "/groupware/adminmenu?section=reservation",
    name: "예약/신청 관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/adminmenu?section=reservation&command=conferenceReserve",
        name: "회의실예약",
      },
      {
        url: "/groupware/adminmenu?section=reservation&command=vehicleReserve",
        name: "배차관리",
      },
      {
        url: "/groupware/adminmenu?section=reservation&command=insBusinessCardForm",
        name: "명함신청",
      },
      {
        url: "/groupware/adminmenu?section=reservation&command=lstBusinessCard",
        name: "명함신청현황",
      },
    ],
  },
  */
];

export const groupWareWorksPath = {
  attend: [
    {
      name: "근태 신청하기",
      init: "/doc.auth.do?command=writeForm",
      url: "/groupware/attend/dashboard",
    },
    {
      name: "출퇴근관리",
      init: "/doc.auth.do?command=writeForm",
      isOpen: true,
      children: [
        {
          url: "/groupware/works?section=attend&command=myAttendList",
          name: "출퇴근 기록",
          /*
          isOpen: true,
          children: [
            {
              url: "/groupware/works?section=attend&command=myAttendList",
              name: "출퇴근 기록",
            },

            {
              url: "/groupware/works?section=attend&command=attendWorkInfo",
              name: "근무내역",
            },

          ],
          */
        },
        /**
        {
          url: "/groupware/attend/dashboard",
          name: "근태 신청하기",
          // isOpen: true,
          // children: [
          //   {
          //     url: "/groupware/works?section=vacation&command=writeForm&doc_type=100095",
          //     name: "외근/출장계 신청하기",
          //   },
          //   {
          //     url: "/groupware/works?section=vacation&command=writeForm&doc_type=100108",
          //     name: "조정계 신청하기",
          //   },
          // ],
        },
         */
        {
          url: "/groupware/works?section=attend&command=attendMonthStatusNew",
          name: "부서출근기록",
        },
        {
          url: "/groupware/works?section=attend&command=attendStatistics",
          name: "부서출근통계",
        },
        {
          url: "/groupware/works?section=attend&command=attendStatisticsNew",
          name: "부서근로시간 통계",
        },
      ],
    },
  ],
  vacation: [
    {
      name: "휴가관리",
      isOpen: true,
      children: [
        {
          url: "/groupware/vacation/dashboard",
          name: "휴가 신청하기",
        },
        {
          name: "휴가현황",
          isOpen: true,
          children: [
            {
              url: "/groupware/vacationWorks?section=vacation&command=vacationStats&doc_type=",
              name: "My 휴가사용현황",
            },
            {
              url: "/groupware/vacationWorks?section=vacation&moveUrl=vacation.admin.do&command=vacationStatusList&siteFlag=biznavi&suMenuCd=100010&menuTitle=관리자&menuCd=120002",
              name: "휴가자 현황",
            },
          ],
        },
      ],
    },
  ],
  vacationList: [
    {
      url: "/groupware/works",
      name: "전재결재 상신",
      init: "/doc.auth.do?command=writeForm",
      isOpen: true,
      children: [
        {
          url: "/groupware/works",
          src: "/doc.auth.do?command=writeForm",
          name: "일반결재",
          isOpen: false,
        },
        {
          url: "/groupware/works",
          src: "/doc.auth.do?command=expenseWriteForm",
          name: "지출결재",
          isOpen: false,
        },
        {
          url: "/groupware/works",
          src: "/vacation.auth.do?command=writeForm",
          name: "근태/휴가",
          isOpen: true,
        },
      ],
    },
  ],
  doc: [
    {
      url: "/groupware/works",
      name: "전재결재 상신",
      init: "/doc.auth.do?command=writeForm&doc_type=draft",
      isOpen: true,
      children: [
        {
          url: "/doc.auth.do?command=writeForm&doc_type=draft",
          src: "/doc.auth.do?command=writeForm&doc_type=draft",
          name: "일반결재",
          isOpen: false,
        },
        {
          url: "/doc.auth.do?command=expenseWriteForm&doc_type=expense",
          src: "/doc.auth.do?command=expenseWriteForm&doc_type=expense",
          name: "지출결재",
          isOpen: false,
        },
        {
          url: "/vacation.auth.do?command=writeForm&doc_type=100089",
          src: "/vacation.auth.do?command=writeForm&doc_type=100089",
          name: "근태/휴가",
          isOpen: false,
        },
      ],
    },
  ],
  approval: [
    {
      // url: "/groupware/approval",
      name: "전자결재",
      isOpen: true,
      children: [
        {
          name: "기안하기",
          url: "/groupware/works?mode=default",
          //url: "/groupware/doc/dashboard",
          //src: "/groupware/works?section=docList&command=dgiReceiveList",
        },
        {
          name: "받은 결재",
          isOpen: true,
          children: [
            {
              url: "/groupware/works?section=docList&command=approvalList&list_type=wait",
              name: "결재 대기함",
            },
            {
              url: "/groupware/works?section=docList&command=approvalList&list_type=end",
              name: "결재 완료함",
            },
            // {
            //   url: "/groupware/works?section=docList&command=approvalList&list_type=end",
            //   name: "반려함",
            // },
          ],
        },
        {
          name: "올린 결재",
          isOpen: true,
          children: [
            {
              url: "/groupware/works?section=docList&command=myList",
              name: "상신문서",
            },
            {
              url: "/groupware/works?section=docList&command=myList&status=900118",
              name: "결재 완료함",
            },
            // {
            //   url: "/groupware/works?section=docList&command=myList",
            //   name: "반려함",
            // },
          ],
        },
        {
          url: "/groupware/works?section=docList&command=dgiReceiveList",
          //src: "/groupware/works?section=docList&command=dgiReceiveList",
          name: "수신참조함",
        },
        {
          url: "/groupware/works?section=docList&command=approvalList&list_type=save",
          name: "임시저장함",
        },
        /*{
          url: "/groupware/works?section=approval&command=lstApproval",
          //src: "/groupware/works?section=docList&command=dgiReceiveList",
          name: "결재라인 관리",
        },*/
        /*{
          url: "/groupware/works?section=approval&command=lstApproval",
          //src: "/groupware/works?section=docList&command=dgiReceiveList",
          name: "결재자 찾기",
        },*/
      ],
    },
  ],
  extension: [
    {
      url: "/groupware/works?section=extension",
      name: "수당/지출내역 관리",
      isOpen: true,
      children: [
        {
          url: "/groupware/works?section=extension&command=expenseTotal",
          name: "지출내역",
        },
        {
          url: "/groupware/works?section=extension&command=lstCustomerContractor",
          name: "영업관리",
        },
        {
          url: "/groupware/works?section=extension&command=extensionStatus",
          name: "수당현황",
        },
        {
          url: "/groupware/works?section=extension&command=extensionMonth",
          name: "수당월별현황",
        },
      ],
    },
  ],
  reservation: [
    {
      url: "/groupware/works?section=reservation",
      name: "예약/신청 관리",
      isOpen: true,
      children: [
        {
          url: "/groupware/works?section=reservation&command=conferenceReserve",
          name: "회의실예약",
        },
        {
          url: "/groupware/works?section=reservation&command=vehicleReserve",
          name: "배차관리",
        },
        {
          url: "/groupware/works?section=reservation&command=insBusinessCardForm",
          name: "명함신청",
        },
        {
          url: "/groupware/works?section=reservation&command=lstBusinessCard",
          name: "명함신청현황",
        },
      ],
    },
  ],
  expense: [
    {
      init: "/doc.auth.do?command=expenseWriteForm&doc_type=expense",
      name: "지출결재관리",
      isOpen: true,
      children: [
        {
          url: "/groupware/works?section=expense&command=expenseWriteForm&doc_type=expense",
          name: "지출결의서작성",
        },
        // {
        //   url: "/groupware/works?section=expense&command=expenseWriteForm&doc_type=card",
        //   name: "법인카드사용신청",
        // },
        {
          url: "/groupware/works?section=extension&command=expenseTotal&doc_type=card",
          name: "지출내역 관리",
          isOpen: true,
          children: [
            {
              url: "/groupware/works?section=extension&command=expenseTotal",
              name: "지출내역",
            },
            {
              url: "/groupware/works?section=extension&command=lstCustomerContractor",
              name: "영업관리",
            },
            /*

            {
              url: "/groupware/works?section=extension&command=extensionStatus",
              name: "수당현황",
            },
            {
              url: "/groupware/works?section=extension&command=extensionMonth",
              name: "수당월별현황",
            },
            */
          ],
        },
      ],
    },
  ],
  board: [
    {
      url: "/groupware/works?section=board&command=list&doc_type=101",
      init: "/board.auth.do?command=list&boardno=101",
      name: "게시판",
      isOpen: true,
      children: [
        {
          url: "/groupware/works?section=board&command=list&doc_type=101",
          //src: "/board.auth.do?command=list&boardno=101",
          name: "공지사항",
          isOpen: false,
        },
      ],
    },
  ],
  // BIZ 업무 메뉴얼
  infoBoard1: [
    {
      url: "/groupware/infoBoard/main?section=infoBoard1",
      name: "BIZ 업무매뉴얼",
      isOpen: true,
      children: [
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=10",
          name: "마케팅팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=11",
          name: "영업팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=12",
          name: "인사팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=13",
          name: "경영관리팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=14",
          name: "디자인팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=15",
          name: "개발팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard1&category=16",
          name: "정부사업",
          isOpen: true,
        },
      ],
    },
  ],
  // BIZ 업무 TIP
  infoBoard2: [
    {
      url: "/groupware/infoBoard/main?section=infoBoard2",
      name: "BIZ 업무TIP",
      isOpen: true,
      children: [
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=20",
          name: "마케팅팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=21",
          name: "영업팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=22",
          name: "인사팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=23",
          name: "경영관리팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=24",
          name: "디자인팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=25",
          name: "개발팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard2&category=26",
          name: "정부사업",
          isOpen: true,
        },
      ],
    },
  ],
  // 비즈내비 활용 TIP
  infoBoard3: [
    {
      url: "/groupware/infoBoard/main?section=infoBoard3",
      name: "비즈내비 활용 TIP",
      isOpen: true,
      children: [
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=30",
          name: "마케팅팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=31",
          name: "영업팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=32",
          name: "인사팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=33",
          name: "경영관리팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=34",
          name: "디자인팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=35",
          name: "개발팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard3&category=36",
          name: "정부사업",
          isOpen: true,
        },
      ],
    },
  ],
  // IP관리의 모든 것
  infoBoard4: [
    {
      url: "/groupware/infoBoard/main?section=infoBoard4",
      name: "IP관리의 모든 것",
      isOpen: true,
      children: [
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=40",
          name: "마케팅팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=41",
          name: "영업팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=42",
          name: "인사팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=43",
          name: "경영관리팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=44",
          name: "디자인팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=45",
          name: "개발팀",
          isOpen: true,
        },
        {
          url: "/groupware/infoBoard/list?section=infoBoard4&category=46",
          name: "정부사업",
          isOpen: true,
        },
      ],
    },
  ],
  infoBoard5: [
    {
      url: "/groupware/infoBoard?section=infoBoard5&command=list&doc_type=1682321300347",
      init: "/board.auth.do?command=list&boardno=101",
      name: "개발팀 업무TIP",
      isOpen: true,
      children: [],
    },
  ],
  infoBoard6: [
    {
      url: "/groupware/infoBoard?section=infoBoard6&command=list&doc_type=1682321315585",
      init: "/board.auth.do?command=list&boardno=101",
      name: "정부사업 업무TIP",
      isOpen: true,
      children: [],
    },
  ],
  infoBoard7: [
    {
      url: "/groupware/infoBoard?section=infoBoard7&command=list&doc_type=1682403843616",
      init: "/board.auth.do?command=list&boardno=101",
      name: "IP관리의 모든것",
      isOpen: true,
      children: [],
    },
  ],
  infoBoard8: [
    {
      url: "/groupware/infoBoard?section=infoBoard8&command=list&doc_type=1682403859075",
      init: "/board.auth.do?command=list&boardno=101",
      name: "비즈내비 활용TIP",
      isOpen: true,
      children: [],
    },
  ],
  infoBoard9: [
    {
      url: "/groupware/infoBoard?section=infoBoard3&command=list&doc_type=1682403874526",
      init: "/board.auth.do?command=list&boardno=101",
      name: "중소기업 업무TIP",
      isOpen: true,
      children: [],
    },
  ],
  command: [
    {
      url: "/groupware/works",
      init: "/command.information.do?showCommandOrder",
      name: "업무공유",
      isOpen: true,
      children: [
        {
          url: "/groupware/works",
          src: "/command.information.do?showCommandOrder",
          name: "업무공유",
          isOpen: false,
        },
      ],
    },
  ],
};

export const groupWareAdminCommutePath = [
  {
    url: "/groupware/attend",
    name: "출퇴근 관리-관리자",
    isOpen: true,
    children: [
      {
        url: "/groupware/attend",
        name: "신청 휴가 현황",
        isOpen: false,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "출퇴근 현황",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "일별 출퇴근 현황 조회",
          },
          {
            url: "/groupware/attend",
            name: "월별 출퇴근 현황 조회",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "근태 환경설정",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
    ],
  },
];

export const groupWareApprovalPath = [
  {
    url: "/groupware/attend",
    name: "전자결재",
    isOpen: true,
    children: [
      {
        url: "/groupware/attend",
        name: "받은 결재",
        isOpen: true,
        children: [
          {
            url: "/groupware/works?command=approvalList&list_type=wait",
            name: "결재 대기함",
          },
          {
            url: "/groupware/attend",
            name: "결재 완료함",
          },
          {
            url: "/groupware/attend",
            name: "반려함",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "올린 결재",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "수신참조함",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
    ],
  },
];

export const groupWareVacationPath = [
  {
    url: "/groupware/attend",
    name: "휴가 관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/attend",
        name: "신청 휴가 현황",
        isOpen: false,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "휴가 사용 현황",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
      {
        url: "/groupware/attend",
        name: "휴가 환경설정",
        isOpen: true,
        children: [
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 샘플",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
          {
            url: "/groupware/attend",
            name: "3depth 메뉴 ",
          },
        ],
      },
    ],
  },
];

export const groupWareExpensionWorksPath = [
  {
    url: "/groupware/expension",
    name: "지출/수당관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/expension",
        name: "지출내역통계",
        isOpen: false,
        children: [
          {
            url: "/groupware/expension",
            name: "지출내역통계",
          },
        ],
      },
      {
        url: "/groupware/extension",
        name: "수당현황",
        isOpen: false,
        children: [
          {
            url: "/groupware/extension",
            name: "수당현황",
          },
        ],
      },
      {
        url: "/groupware/extension_month",
        name: "수당월별내역",
        isOpen: false,
        children: [
          {
            url: "/groupware/extension_month",
            name: "수당월별내역",
          },
        ],
      },
    ],
  },
];

export const groupWareReservaionWorksPath = [
  {
    url: "/groupware/reservaion",
    name: "예약/신청 관리",
    isOpen: true,
    children: [
      {
        url: "/groupware/reservation",
        name: "예약관리",
        isOpen: false,
        children: [
          {
            url: "/groupware/expension",
            name: "회의실예약",
          },
        ],
      },
      {
        url: "/groupware/extension",
        name: "법인차관리",
        isOpen: false,
        children: [
          {
            url: "/groupware/extension",
            name: "수당현황",
          },
        ],
      },
      {
        url: "/groupware/extension_month",
        name: "수당월별내역",
        isOpen: false,
        children: [
          {
            url: "/groupware/extension_month",
            name: "수당월별내역",
          },
        ],
      },
    ],
  },
];

export const groupWareCorporationCarPath = [
  {
    url: "/groupware/car",
    name: "법인차 예약",
    isOpen: true,
    children: [
      {
        url: "/groupware/car",
        name: "법인차 예약",
      },
      {
        url: "/groupware/car",
        name: "MY 예약 내역",
      },
    ],
  },
];

// 캐시플로우
export const groupCashflow = [
  {
    url: "/cashflow/intro",
    name: "캐시플로우",
    isOpen: true,
    children: [
      {
        url: "/groupware/cashflow/balanceIns",
        name: "작성하기",
      },
      {
        url: "/groupware/cashflow/history",
        name: "리포트",
      },
    ],
  },
];

// 캐시플로우 Test
export const groupWareCashFlowPath = [
  {
    url: "/groupware/cashflow/landing",
    name: "캐시플로우",
    isOpen: true,
    children: [
      {
        url: "/groupware/cashflow/landing",
        name: "랜딩페이지",
      },
      {
        url: "/groupware/cashflow/balance_ins",
        name: "STEP 1. 보유현금",
      },
      {
        url: "/groupware/cashflow/op_revenue",
        name: "STEP 2. 영업수입",
      },
      {
        url: "/groupware/cashflow/op_expense",
        name: "STEP 3. 영업지출",
      },
      {
        url: "/groupware/cashflow/finance",
        name: "STEP 4. 재무활동",
      },
      {
        url: "/groupware/cashflow/invest",
        name: "STEP 5. 투자활동",
      },
      {
        url: "/groupware/cashflow/report",
        name: "리포트",
      },
    ],
  },
];

export const groupWareOfficeRoom = [
  {
    url: "/groupware/office?section=command&command=showCommandList&method=receive_list",
    name: "프로젝트 관리", // 기존 오피스룸
    isOpen: true,
    children: [
      {
        url: "/groupware/office?section=emp&command=lstEmp",
        name: "조직도", // 기존 직원 검색
      },
      /*{
        url: "/groupware/office?section=command&command=showCommandList&method=receive_list",
        name: "프로젝트룸", // 기존 업무공유, Works Board
      },*/
      {
        url: "/groupware/office?section=command&command=showCommandList&method=receive_list&category=chat",
        name: "업무챗",
      },
      {
        url: "/groupware/todo",
        name: "TO DO LIST",
      },
    ],
  },
];

// 업무보고
export const groupWareReport = [
  {
    url: "/groupware/report/daily/ins",
    name: "업무보고",
    isOpen: true,
    children: [
      {
        url: "/groupware/report/daily/ins",
        name: "일일보고 쓰기",
      },
      {
        url: "/groupware/report/week/ins",
        name: "주간보고 쓰기",
      },
      {
        url: "/groupware/report/tWeek2/ins",
        name: "팀주간보고 쓰기",
      },
      {
        url: "/groupware/report/daily/send",
        name: "보낸 업무보고",
      },
      {
        url: "/groupware/report/daily/receive",
        name: "받은 업무보고",
      },
      {
        url: "/groupware/report/daily/temp",
        name: "업무보고 임시저장함",
      },
    ],
  },
];

// BIZ 업무 메뉴얼
export const bizManual = [
  {
    url: "",
    name: "BIZ 업무매뉴얼",
    isOpen: true,
    children: [
      {
        url: "",
        name: "마케팅팀",
        isOpen: true,
      },
      {
        url: "",
        name: "영업팀",
        isOpen: true,
      },
      {
        url: "",
        name: "인사팀",
        isOpen: true,
      },
      {
        url: "",
        name: "경영관리팀",
        isOpen: true,
      },
      {
        url: "",
        name: "디자인팀",
        isOpen: true,
      },
      {
        url: "",
        name: "개발팀",
        isOpen: true,
      },
      {
        url: "",
        name: "정부사업",
        isOpen: true,
      },
    ],
  },
];

// BIZ 업무 TIP
export const bizTip = [
  {
    url: "",
    name: "BIZ 업무TIP",
    isOpen: true,
    children: [
      {
        url: "",
        name: "마케팅팀",
        isOpen: true,
      },
      {
        url: "",
        name: "영업팀",
        isOpen: true,
      },
      {
        url: "",
        name: "인사팀",
        isOpen: true,
      },
      {
        url: "",
        name: "경영관리팀",
        isOpen: true,
      },
      {
        url: "",
        name: "디자인팀",
        isOpen: true,
      },
      {
        url: "",
        name: "개발팀",
        isOpen: true,
      },
      {
        url: "",
        name: "정부사업",
        isOpen: true,
      },
    ],
  },
];

// 비즈내비 활용 TIP
export const biznaviTip = [
  {
    url: "",
    name: "비즈내비 활용 TIP",
    isOpen: true,
  },
];

// IP관리의 모든 것
export const biznaviIP = [
  {
    url: "",
    name: "IP관리의 모든 것",
    isOpen: true,
  },
];

// export const holdings = [
//   {
//     url: "/holdings/project",
//     name: "지주사",
//     isOpen: true,
//     children: [
//       {
//         url: "/holdings/project",
//         name: "지주사 관리",
//       },
//       {
//         url: "/holdings/statistic",
//         name: "현황",
//       },
//       {
//         url: "/holdings/info",
//         name: "통계",
//       },
//     ],
//   },
// ];

export const holdings = [
  {
    url: "/holdings/project",
    name: "지주사",
    isOpen: true,
    children: [
      {
        url: "/holdings/statistic",
        name: "HOME",
        icon: "holdings_home",
      },
      {
        url: "/holdings/current",
        name: "기업별 현황",
        icon: "holdings_status",
      },
      {
        url: "/holdings/project",
        name: "네트워킹 룸",
        icon: "holdings_room",
      },
      {
        url: "/holdings/invest",
        name: "투자금 관리",
        icon: "holdings_invest",
      },
      {
        url: "/holdings/info",
        name: "통계",
        icon: "holdings_report",
      },
      // {
      //   url: "/holdings/info",
      //   name: "설정",
      //   icon: "holdings_set",
      // },
    ],
  },
];

export const competitorMy = [
  {
    url: "/competitor/my/recent",
    name: "My Page",
    isOpen: true,
    children: [
      {
        url: "/competitor/my/recent",
        name: "최근 조회한 기업",
        isOpen: true,
      },
      {
        url: "/competitor/my/interest",
        name: "관심기업",
        isOpen: true,
      },
      {
        url: "/competitor/my/clt",
        name: "거래처 기업",
        isOpen: true,
      },
    ],
  },
];
