import React, { useContext, useState } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";

function Footer() {
  const { isMobile } = useContext(CommonContext);
  const [show, setShow] = useState(false);
  const isViewTypeIpnow = window.$Global.isIpnow();

  return (
    <footer>
      {/* <iframe
        id="whoisTalkIframe"
        name="whoisTalkIframe"
        width="100%"
        src="/g2works/push/selectNotifyForm.do"
        height="100%"
        frameBorder="0"
        style={{
          position: "fixed",
          bottom: "31px",
          right: 0,
          zIndex: 9999,
          width: "410px",
          height: "0px",
        }}
      ></iframe>
      <iframe
        name="footerframe"
        width="0"
        height="0"
        frameborder="0"
        src="/g2works/main/footerPage.do"
        scrolling="no"
        id="footerWrap"
        style={{ display: "none" }}
      ></iframe> */}
      <div className="wrap_footer pc">
        <div className="info">
          <p className="company_name">주식회사 아이피나우</p>
          <p>법인등록번호: 180111-1360047 / 대표이사: 황차동</p>
          <p>사업자등록번호: 376-86-02011</p>
          <br />
          <p>본사: (48480) 부산광역시 남구 신선로 2, 410호(우암동)</p>
          <p>
            지사: (06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동,
            창덕빌딩)
          </p>
          <p>
            베트남: 7th floor, Kailash building B21 - D21, Dich Vong Hau ward,
            Cau Giay distric, Ha Noi, Viet Nam
          </p>
          <p>대표전화: 1551-8442 | 팩스: 02-3463-2200</p>
          <p>이메일: ipnow@ipnow.co.kr</p>
          <p>ⓒ Copyright © 2024 IPNOW. All rights reserved.</p>
        </div>
        <div>
          <ul className="links">
            <li>
              <Link to="/intro/main">기능소개</Link>
            </li>
            {isViewTypeIpnow && (
              <li>
                <Link to="/people">PEOPLE</Link>
              </li>
            )}
            <li>
              <Link to="/intro/price">이용가격</Link>
            </li>
            <li>
              <Link to="/intro/service">고객문의</Link>
            </li>
          </ul>
          <div className="list_link">
            <a href="https://www.facebook.com/biznavi.co.kr" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-facebook-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a
              href="https://www.instagram.com/biznavi__official"
              target="_blank"
            >
              <img
                src={
                  require("../../assets/images/footer/icon-instagram-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a href="https://blog.naver.com/biznavi_official" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-blog-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a href="https://media.biznavi.co.kr" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-media-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
          </div>
        </div>
        <p className="terms_link">
          <Link to="/terms/use">서비스 이용약관</Link>
          <span>|</span>
          <Link to="/terms/private">개인정보 처리방침</Link>
        </p>
      </div>
      <div className="wrap_footer mo">
        <div className="info">
          <p>법인명(단체명): 주식회사 아이피나우</p>
          <p>대표이사: 황차동</p>
          <p>법인등록번호: 180111-1360047</p>
          <p>사업자등록번호: 376-86-02011</p>
          <p>본사: (48480)부산광역시 남구 신선로 2, 410호(우암동)</p>
          {/* <p className="text_indent">피엔유 에이백 2층 비7호 (장전동)</p> */}
          <p>지사: (06744)서울특별시 서초구 바우뫼로 175, 4F/5F</p>
          <p className="text_indent">(양재동, 창덕빌딩)</p>
          <p>베트남: 7th floor, Kailash building B21 - D21,</p>
          <p className="text_indent">
            Dich Vong Hau ward, Cau Giay distric, Ha Noi, Viet Nam
          </p>
          <p>대표전화: 1551-8442 | 팩스: 02-3463-2200</p>
          <p>이메일: ipnow@ipnow.co.kr</p>
        </div>

        <p className="line" />

        <ul className="links">
          {/*<li>*/}
          {/*  <Link to="/intro/main">기능소개</Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link to="/intro/price">이용가격</Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link to="/intro/service">고객문의</Link>*/}
          {/*</li>*/}
          <li>
            <Link to="/terms/use">서비스 이용약관</Link>
          </li>
          <li>
            <Link to="/terms/private">개인정보 처리방침</Link>
          </li>
        </ul>

        <p className="copyright">
          ⓒ Copyright © 2024 IPNOW. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
