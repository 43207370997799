import React from 'react';
import DatePickerCustom from '../../datepicker/DatePickerCustom';


function DatePickerRow({title, titleClassName, rowClassName, condition, content, contentClassName, onChangeDatePicker, maxDate}) {

    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                <span className={contentClassName ? contentClassName : ''}>{content ? window.$Global.convertDate(content) : 'N/A'} </span>
                {
                    condition
                    &&
                    <DatePickerCustom data={content} onChangeDatePicker={onChangeDatePicker} maxDate={maxDate}/>
                }
            </div>
        </div>
    );
}

export default DatePickerRow;
