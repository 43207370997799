import React from "react";
import {Route} from "react-router-dom";
import EducationMain from "../components/education/EducationMain";
import EducationDetail from "../components/education/EducationDetail";
import CustomSwitch from "./common/CustomSwitch";

function EducationRoute({match}) {
	return (
		<CustomSwitch>
			<Route exact path={`${match.path}/main`} component={EducationMain}/>
			<Route exact path={`${match.path}/detail/:idx`} component={EducationDetail}/>
		</CustomSwitch>
	)
}

export default EducationRoute;