import React, {useContext, useRef} from "react";
import "./css/CyberLogin.scss";
import {Link} from "react-router-dom";
import Validator from "../../util/validator";
import CyberAPI from "../../API/cyber";
import MemberContext from "../../store/Member";
import config from "../../const/config";

function CyberLogin() {
    let idRef = useRef();
    let pwRef = useRef();

    const onClickLogin = () => {
        let id = idRef.current.value;
        let pw = pwRef.current.value;
        let refs = [idRef, pwRef];

        if (Validator.refValidator(refs)) {
            CyberAPI.login({id: id, password: pw}).then(async res => {
                const data = res.data;

                if (data.token) {
                    localStorage.setItem(config.keyToken, data.token);
                    window.location.href = "/cyber";
                } else {
                    alert("가입하지 않은 아이디이거나 잘못된 비밀번호입니다");
                }
            })
        }
    };

    return (
        <div id="CyberLogin">
            <div className="head">
                <img src={require('../../assets/images/cyber/oval-copy.png').default} alt="이미지"/>
                <div className="info">
                    <h2>기업을 추천해주세요</h2>
                </div>
            </div>

            <div className="form">
                <div className="field">
                    <h2>아이디</h2>
                    <input type="text" className="input_id" data-name="아이디" ref={idRef} placeholder="아이디" onKeyUp={(e) => {if(e.key === 'Enter') onClickLogin()}} autoFocus/>
                </div>
                <div className="field">
                    <h2>비밀번호</h2>
                    <input type="password" className="input_password" data-name="비밀번호" ref={pwRef} placeholder="비밀번호" onKeyUp={(e) => {if(e.key === 'Enter') onClickLogin()}}/>
                </div>
            </div>

            <button className="btn_login" onClick={onClickLogin}>로그인</button>

            <ul>
                <li>
                    <Link to="/cyber/signup">회원가입</Link>
                </li>
            </ul>
        </div>
    )
}

export default CyberLogin;