import { useEffect, useRef, useState } from "react";
import "./css/CompetitorAccount.scss";

const Folder = ({ folder, isFirstLevel, selectedIdx, checkFolder }) => {
  const hasChildren = folder.cnt > 0;
  const className = isFirstLevel ? "first-level-folder" : "";

  return (
    <div className="folder">
      <div
        className={className}
        onClick={() => checkFolder(folder.idx, folder.group_name)}
      >
        {className === "" ? (
          ""
        ) : selectedIdx.some((item) => item.idx === folder.idx) ? (
          <i className="icon_check_green fd_icon"></i>
        ) : (
          <i className="icon_folder_yellow fd_icon"></i>
        )}
        {folder.group_name}
        {className === "" ? "" : <span className="num">({folder.cnt})</span>}
      </div>
      {/* {hasChildren && isOpen && (
        <div className="sub">
          <FolderList folders={folder.children} isFirstLevel={false} selectedIdx={selectedIdx} checkFolder={checkFolder} />
        </div>
      )} */}
    </div>
  );
};

const FolderList = ({
  folders,
  isFirstLevel,
  selectedIdx,
  checkFolder,
  folderRef,
}) => {
  return (
    <div ref={folderRef} className="folder_list custom_scroll">
      {folders?.length
        ? folders.map((folder) => (
            <Folder
              key={folder.idx}
              folder={folder}
              isFirstLevel={isFirstLevel}
              selectedIdx={selectedIdx}
              checkFolder={checkFolder}
            />
          ))
        : "데이터가 없습니다."}
    </div>
  );
};

const NewFolder = ({
  addNewFolder,
  setIsAddingFolder,
  folderScrollToBottom,
}) => {
  const [folderName, setFolderName] = useState("");

  const handleAddFolder = () => {
    if (folderName.trim()) {
      addNewFolder(folderName);
      setFolderName("");
      setIsAddingFolder(false);
      // setTimeout(() => {
      //   folderScrollToBottom();
      // }, 300); // 0.3초 지연
    }
  };

  return (
    <div className="new_folder">
      <input
        type="text"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        placeholder="새 폴더 이름"
      />
      <div className="btn_box">
        <button onClick={handleAddFolder}>추가</button>
        <button className="cancel" onClick={() => setIsAddingFolder(false)}>
          취소
        </button>
      </div>
    </div>
  );
};

const CompetitorAccount = ({
  group,
  setIsAccountPopOpen,
  name,
  addClt,
  addCltGroup,
  flagType,
  competitorGroups,
}) => {
  const [selectedIdx, setSelectedIdx] = useState([]); // 기업이 저장될 거래처기업 그룹 배열
  const [isAddingFolder, setIsAddingFolder] = useState(false); // 새 폴더 추가 상태 관리
  const folderRef = useRef(null);

  const checkFolder = (idx, group_name) => {
    if (selectedIdx.some((item) => item.idx === idx)) {
      removeCheckedFolder(idx); // 배열에 있다면 삭제
    } else {
      addCheckedFolder(idx, group_name); // 배열에 없다면 추가
    }
  };

  const addCheckedFolder = (idx, group_name) => {
    setSelectedIdx((prevSelectedIdx) => {
      const newSelectedIdx = [...prevSelectedIdx, { idx, group_name }];
      return newSelectedIdx;
    });
  };

  const removeCheckedFolder = (idx) => {
    setSelectedIdx((prevSelectedIdx) => {
      const newSelectedIdx = prevSelectedIdx.filter((item) => item.idx !== idx);
      return newSelectedIdx;
    });
  };

  const addCltByGroupIdx = async () => {
    let errorArr = [];
    if (selectedIdx.length) {
      for (const { idx, group_name } of selectedIdx) {
        const result = await addClt(idx, group_name);
        if (result?.data?.code !== "00") {
          errorArr.push(group_name);
        }
      }
      //console.log("result:", result);
      setSelectedIdx([]);
      if (errorArr.length) {
        alert(
          `아래 거래처기업에 이미 저장되어 있습니다.\n${errorArr
            .map((name) => name + "\n")
            .join("")}`
        );
      }
    } else {
      alert("저장할 폴더를 선택해주세요.");
    }
  };

  const folderScrollToBottom = () => {
    folderRef.current.scrollTop = folderRef.current.scrollHeight;
  };

  return (
    <div id="CompetitorAccount">
      <div className="tit">
        <strong>{flagType === 1 ? "거래처기업" : "경쟁사"} 저장하기</strong>
      </div>
      <strong className="current_comp">{name}</strong>
      <div className="list">
        <FolderList
          folders={flagType === 1 ? group : competitorGroups}
          isFirstLevel={true}
          selectedIdx={selectedIdx}
          checkFolder={checkFolder}
          folderRef={folderRef}
        />
      </div>
      {isAddingFolder && (
        <NewFolder
          addNewFolder={addCltGroup}
          setIsAddingFolder={setIsAddingFolder}
          folderScrollToBottom={folderScrollToBottom}
        />
      )}
      <div className="btns">
        <button onClick={() => setIsAddingFolder(true)}>새 폴더</button>
        <button className="save" onClick={addCltByGroupIdx}>
          저장
        </button>
      </div>
      <button className="close" onClick={() => setIsAccountPopOpen(false)}>
        <i className="icon_exit_gray"></i>
      </button>
    </div>
  );
};

export default CompetitorAccount;
