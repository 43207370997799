import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom"
import "../css/MobileMyPage.scss";
import memberContext from "../../../store/Member";
import ProductAPI from "../../../API/product";
import AccountAPI from "../../../API/account";

function MobileMyPage() {
    const history = useHistory();
    const memberInfo = useContext(memberContext);
    const [productList, setProductList] = useState([]);
    const [expirationDate, setExpirationDate] = useState(0);
    const [useable, setUseable] = useState(false);
    const [code, setCode] = useState(null);

    const codeInput = React.useRef();

    const isViewTypeIpnow = window.$Global.isIpnow();
    const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
    const isAgent = window.$Global.checkAuth("대리인", memberInfo.auth);
    const isInvestor = window.$Global.checkAuth("투자자", memberInfo.auth);

    useEffect(() => {

        if (isCompany) {
            AccountAPI.getAccountInfo().then(res => setCode(res.data.code));
            ProductAPI.getProductListCompany().then(res => setProductList(res.data));
            AccountAPI.getAccountInfo().then(res => {
                let expiration_at = res.data.expiration_at;
                let result = window.$Global.getDday(expiration_at);

                if (result > 0) {
                    setExpirationDate(expiration_at);
                    setUseable(true);
                }
            });
        } else if (isAgent) {
            AccountAPI.getAccountCompanyInfoAsAgent().then(res => setCode(res.data.code));
        }
    }, []);

    return (
        <div id="MobileMyPage">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2>마이페이지</h2>
            </div>
            <div className="myinfo">
                <div className="access_account">
                    <h2>{memberInfo.name}님</h2>
                    {
                        memberInfo.company != "null" && <p>{memberInfo.company}</p>
                    }
                </div>
                <div className="link">
                    <Link to="/mypage/mobile/edit">
                        회원 정보 수정
                        <i className="icon_more_arrow_right_white"/>
                    </Link>
                </div>
            </div>
            {
                (!isInvestor && isViewTypeIpnow) &&
                <div className="company_code">
                    <div className="box my_code">
                        <div className="wrap_code">
                            <p>Code:</p>
                            <input ref={codeInput} value={code} readOnly/>
                        </div>
                        <button onClick={() => window.$Global.copy(codeInput)}>복사하기</button>
                    </div>
                    {
                        isCompany && <p className="box alert">해당 코드를 대리인이 관리자페이지에 등록하면 귀사와 연결됩니다.</p>
                    }
                    {
                        isAgent && <p className="box alert">해당 코드를 기업이 관리자페이지에 등록하면 귀사와 연결됩니다.</p>
                    }
                </div>
            }
            {
                (isCompany && isViewTypeIpnow) &&
                <>
                    <div className="product">
                        <h2 className="title">제품 리스트</h2>
                        <div className="alert">
                            <i className="icon_alert_circle"/>
                            <p>제품 추가 및 변경은 PC를 이용해주시기 바랍니다.</p>
                        </div>

                        <ul className="list">
                            {
                                productList.map((item, idx) => {
                                    return (
                                        <li key={idx}>
                                            <div className="item">
                                                <p className="idx">{idx + 1}</p>
                                                <p className="name">{item.name}</p>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                    {/*<div className="use_price">*/}
                    {/*    <h2 className="title">이용 요금제</h2>*/}

                    {/*    <div className="box">*/}
                    {/*        {*/}
                    {/*            useable*/}
                    {/*                ?*/}
                    {/*                <>*/}
                    {/*                    <div className="price_info">*/}
                    {/*                        <p className="price_type">E구간</p>*/}
                    {/*                        <p className="price"/>*/}
                    {/*                    </div>*/}
                    {/*                    <p className="price_content">특허 · 상표 · 디자인권  130 ~ 300건</p>*/}
                    {/*                    <p className="expire_date">*/}
                    {/*                        만료일*/}
                    {/*                        <span>{window.$Global.convertDate(expirationDate)}</span>*/}
                    {/*                    </p>*/}
                    {/*                </>*/}
                    {/*                :   <p className="not_price">이용하시는 요금제가 없습니다.</p>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>
            }
        </div>
    );
}

export default MobileMyPage;
