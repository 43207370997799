import React, { useRef, useState } from "react";
import "./css/TaskSearchIp.scss";
import InfoCompanyAPI from "../../API/infoCompany";
import CalcAnnualFeePopup from "../intro/ipnow/CalcAnnualFeePopup";
import SearchInterested from "../home/SearchInterested";

function TaskMain() {
    const input_ref = useRef();

    const [companyKeyword, setCompanyKeyword ] = useState('')
    const [keyword_list, setKeywordList] = useState('');
    const [keyword_list_show, setKeywordListShow] = useState(false);
    const [mouse, setMouse] = useState(false);
    const [popupShow, setPopupShow] = useState(false);
    const [arr, setArr] = useState([]);
    const [patent, setPatent] = useState({});
    const [companyName, setCompanyName] = useState('');
    const [apply_at, setApplyAt] = useState(0);

    const onChangeInput = (value) => {
        setCompanyKeyword(value);
        setKeywordList([]);
        setKeywordListShow(false);
    };

    const onBlur = () => {
        if (!mouse) {
            setKeywordListShow(false);
        }
    };

    const getSearchCompany = () => {
        if (!companyKeyword.length) {
            setKeywordList([]);
            return;
        }
        InfoCompanyAPI.getInfoCompany(companyKeyword).then(res => {
            if (res.data.length) {
                setKeywordList(
                    res.data.map((item, index) => {
                        return (
                            <li key={index} className="active" tabIndex={0} onClick={() => onClickCalcAnnualFee(item.name, item.company_code)} onBlur={onBlur}>
                                <div>
                                    <h2>{item.name}</h2>
                                    <p>
                                        {item.name_en && item.name_en.replace(/(\s*)/g, "") && `영문법인명 ${item.name_en}`}
                                        {item.corporate_number && item.corporate_number.replace(/(\s*)/g, "") && ` / 법인등록번호 ${item.corporate_number}`}
                                        {item.license_number && item.license_number.replace(/(\s*)/g, "") && ` / 사업자 등록번호 ${item.license_number}`}
                                        {item.representative && ` / 대표자 ${item.representative}`}
                                        {item.establishment_at && ` / 설립일 ${window.$Global.convertDate(item.establishment_at)}`}
                                    </p>
                                </div>
                            </li>);
                    })
                );
            } else {
                setKeywordList([<li>
                    <div>
                        <p>입력하신 회사가 등록되지 않았거나 등록한 특허가 없습니다.</p>
                    </div>
                </li>]);
            }
            setKeywordListShow(true);
            input_ref.current.focus();
        });
    };

    const onClickCalcAnnualFee = (name, company_code) => {
        if (!company_code) {
            alert("특허가 존재하지 않아 조회할수 없습니다");
            return;
        }
        InfoCompanyAPI.getInfoCompanyAnnual(company_code).then(res => {
            let data = res.data;
            let annual_pay = data.annual_pay;
            setPatent(data.patent);
            setArr(annual_pay.map(item => item.cost));
            setCompanyName(name);
            setApplyAt(annual_pay[annual_pay.length - 1].year);
            setPopupShow(true);
            setKeywordListShow(false);
        });
    };

    const onClickClose = () => {
        setPopupShow(false);
    };

    return (
        <div id="TaskSearchWarp">
            <SearchInterested all={true}/>
            <div className="ip_search">
                <div className="auto_calc_annual_fee">
                    <div className="dimmed" />
                    <div className="txt">
                        <p className="desc">
                            <span className="color_orange">1초</span>
                            안에 우리회사의<br />
                            <span className="color_purple"> 특허/상표 유지비용</span>
                            을 계산해 드립니다.
                        </p>
                    </div>
                    <div className="search">
                        <input type="text"
                               placeholder="회사명으로 만료일까지의 연차료 검색이 가능합니다."
                               ref={input_ref}
                               value={companyKeyword}
                               onChange={e => onChangeInput(e.target.value)}
                               onFocus={() => setKeywordListShow(true)}
                               onBlur={onBlur}
                               onKeyUp={(e) => { if (e.key === 'Enter') getSearchCompany() }}
                        />
                        {
                          (keyword_list_show && Boolean(keyword_list.length))
                          &&
                          <ul className="keyword_list custom_scroll no_focus_outline" onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                              {keyword_list}
                          </ul>
                        }
                        <button className="btn_search icon_search_gray" onClick={getSearchCompany} />
                    </div>
                </div>
                {
                  popupShow && <CalcAnnualFeePopup companyName={companyName} data={arr} apply_at={apply_at} patent={patent} onClickClose={onClickClose} />
                }
            </div>
        </div>
    );
}

export default TaskMain;
