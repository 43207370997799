import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeSampleRegisterFull.scss";
import DocTradeSampleRegisterAgree from './DocTradeSampleRegisterAgree';
import DocTradeSampleRegisterFullRow from './DocTradeSampleRegisterFullRow';
import _ from "lodash";
import TemplateAPI from "../../API/template";

function DocTradeSampleRegisterFull({history, category, onClickPopupShow}) {
    const [template, setTemplate] = useState([]);
    const fileRef = useRef();

    const onClickDelete = (idx) => {
        let copy = _.cloneDeep(template);
        copy.splice(idx, 1);
        setTemplate(copy);
    };

    const onChangeFile = e => {
        let addFiles = Array.from(e.target.files).map(item => {
            return {
                category_idx1: 1,
                title: item.name,
                content: "",
                price: 0,
                file: [item]
            };
        });
        setTemplate(template.concat(addFiles));
    }

    const onChangeInput = (idx, key, value) => {
        if (key == "title" && value.length > 200) {
            alert("200글자를 초과할 수 없습니다");
            return;
        }

        if (key == "content" && value.length > 1000) {
            alert("1000글자를 초과할 수 없습니다");
            return;
        }

        let copy = _.cloneDeep(template);
        copy[idx][key] = value;
        setTemplate(copy);
    };

    const uploadFileButton = () => {
        fileRef.current.click();
    };

    const onClickAdd = async () => {
        if (!template.length) {
            alert("자료를 등록해주세요");
            return;
        }
        for (let i = 0; i < template.length; i++) {
            let item = template[i];
            if (!item.title.length) {
                alert("제목을 입력해주세요");
                return;
            }

            if (!item.content.length) {
                alert("소개글을 입력해주세요");
                return;
            }
        }

        for (let i = 0; i < template.length; i++) {
            let formData = new FormData();
            let item = template[i];

            Object.keys(item).map(key => {
                if (key == "file") {
                    item[key].forEach(file => {
                        formData.append(key, file);
                    })
                } else {
                    formData.append(key, item[key]);
                }
            });
            await TemplateAPI.addSample(formData);
        }
        alert("정상적으로 등록되었습니다\n등록하신 파일은 관리자 승인 후 게시됩니다");
        history.push("/docTrade/list");
    };


    return (
        <div id="docTradeSampleRegisterFull">
			<div className="full-register">
                <div className="full-register_box">
                    한번에 최대 30개, 총 100MB 이하 까지 업로드 가능합니다.<br />자료의 개수나 용량이 초과할 경우 &#91;고객문의&#93;를 부탁드립니다.
                </div>
                <div className="full-register_table-top">
                    <span className="full-register_table-top_selectfile">파일 선택 <em>&#40;최대30개&#41;</em></span>
                    <span className="full-register_table-top_btn-wrap">
                        <button className="btn-registration open-pop" onClick={() => onClickPopupShow('addFileAlert')}>
                            <span className="icon_attention"></span>
                            <span className="text">등록파일 주의사항</span>
                        </button>
                        <button className="btm-regulation open-pop" onClick={() => onClickPopupShow('usableDataAgree')}>
                            <span className="icon_attention"></span>
                            <span className="text">판매 가능 자료 및 부정자료 규정</span>
                        </button>
                        <label for="sampleFileAdd" class="hidden">샘플 자료 파일 추가</label>
                        <input type="file" name="sampleFileAdd" id="sampleFileAdd" class="hidden" multiple ref={fileRef} onChange={onChangeFile}/>
                        <button type="button" className="sampleFileAdd" onClick={uploadFileButton}>파일 추가</button>
                    </span>
                </div>
                <div className="full-register_table-wrap">
                    <table className="full-register_table">
                        <colgroup>
                            <col width="40px" />
                            <col width="375px"/>
                            <col width="150px"/>
                            <col width="100px"/>
                            <col width="375px"/>
                            <col width="60px"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">순&nbsp;&nbsp;번</th>
                                <th className="style" scope="col">제목</th>
                                <th scope="col">판매가격 &#40;원&#41;</th>
                                <th scope="col">자료 구분</th>
                                <th className="style" scope="col">소개글</th>
                                <th scope="col">삭&nbsp;&nbsp;&nbsp;&nbsp;제</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DocTradeSampleRegisterFullRow data={template} category={category} onChangeInput={onChangeInput} onClickDelete={onClickDelete}/>
                        </tbody>
                    </table>
                </div>
                <DocTradeSampleRegisterAgree onClickAdd={onClickAdd}/>
            </div>
        </div>
    )
}

export default DocTradeSampleRegisterFull;