import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareOfficeRoom } from "./groupwarePath";
import IframeResizer from "iframe-resizer-react";

import "./css/GroupwareWorks.scss";

const GroupwareTodo = () => {
  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareOfficeRoom} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/todo.do?command=addTodo`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export default GroupwareTodo;
