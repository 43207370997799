import React, {useContext, useState, useEffect} from "react";
import './css/InventionListView.scss';
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import MemberContext from "../../store/Member";
import CheckBox from "../common/CheckBox";
import API from "../../util/api";
import PopupUpDownload from "./PopupUpDownload";

function InventionListView({history}) {
    let tab_tags = [];
    let table_head_tag = [];
    const {auth} = useContext(MemberContext);
    const [table_head, setTableHead] = useState([]); // 테이블 헤더값
    const [tbody, setTbody] = useState([]);
    const [tabState, setTabState] = useState(false); // Tab 설정 활성화 여부
    const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
    const [popupShow, setPopupShow] = useState(false);
    const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
    const [downloadFileViewList, setDownloadFileViewList] = useState([]); // 다운로드 파일 리스트
    const [showType, setShowType] = useState("");
    const isAdmin = window.$Global.checkAuth("특허회사_관리자", auth);

    const [btns_state_tab, setBtnsStateTab] = useState([
        {text:'건수',          key: 'number',  type: 1, checked: 1, defaultChecked: 1},
        {text:'발명의 명칭',    key: 'title', type: 0, checked: 1, defaultChecked: 1},
        {text:'발명자',        key: 'name', type: 0, checked: 1, defaultChecked: 1},
        {text:'현재 상태',      key: 'state', type: 0, checked: 1, defaultChecked: 1},
        {text:'발명의 공개여부', key: 'open', type: 0, checked: 1, defaultChecked: 1},
        {text:'공동 출원인',    key: 'joint_apply', type: 0, checked: 0, defaultChecked: 0},
        {text:'발명자 지분',    key: 'share', type: 0, checked: 0, defaultChecked: 0},
    ]);

    useEffect(() => getInventionFileList(), [popupShow]);

    useEffect(() => {
        let payload = {
            page:1,
            count:0,
            state:-1
        };
        API.get('manager/invention', {params: payload}).then(res => {
            let data = res.data.items;
            let selectedBasics_tab = btns_state_tab.filter((item) => item.checked);
            let arr = data.map((item) => {
                return (
                    <React.Fragment key={item.idx}>
                        <tr data-idx={item.idx} onClick={() => onClickDetail(item.idx)}>
                            {selectedBasics_tab.filter(obj => obj.key === 'number').length ?
                                <td className="number">{item.number}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'title').length ?
                                <td className="title">{item.title}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'name').length ?
                                <td className="name">{item.members}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'state').length ?
                                <td className="state">{window.$Global.convertInventionStateToStr(item.state)}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'open').length ?
                                <td className="open">{item.open === 0 ? '공개' : '비공개'}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'joint_apply').length ?
                                <td className="joint_apply">{item.joint_apply}</td> : null}
                            {selectedBasics_tab.filter(obj => obj.key === 'share').length ?
                                <td className="name">{item.members}</td> : null}
                        </tr>
                    </React.Fragment>
                )
            });
            if (arr.length) {
                setTbody(arr);
            } else {
                setTbody(window.$Global.notTd(6, '등록된 직무 발명신고서가 없습니다'));
            }
        });
    }, [btns_state_tab, selectedBasics]);

    const onChangeTabChecked = (input, text) => {
        var item = btns_state_tab.find(item => item.text === text);
        item.checked =  Number(input.target.checked);
        setBtnsStateTab([...btns_state_tab]);

        if (item.checked) {
            table_head_tag.push(<th>{item.text}</th>);
            setTableHead(table_head.concat([item.text]));
        } else {
            let findItem = table_head.find((item) => {
                return item === item.text;
            });
            let idx = table_head.indexOf(findItem);
            setTableHead(table_head.splice(idx, 1));
        }
    };

    const onClickToggleselectedBasics = () => {
        // 기본으로 설정 ON 일때 체크 값 기본으로 설정
        if (!selectedBasics) {
            btns_state_tab.forEach((item) => {
                item.checked = item.defaultChecked;
            });
            setBtnsStateTab(btns_state_tab);
        }
        setSelectedBasics(!selectedBasics);
    };

    const onClickDeleteFileInUpload = (idx) => {
        var copyArr = uploadFileViewList.slice(0);
        copyArr.splice(idx, 1);
        setUploadFileViewList(copyArr);
    };

    const onChangeFile = (e) => {
        let files = e.target.files;
        let arr = [];

        files.forEach((file, index) => {
            let name = file.name.substr(0, file.name.lastIndexOf('.'));
            let type = file.name.substr(file.name.lastIndexOf('.') + 1);
            let item = {
                idx: uploadFileViewList.length + index,
                file_name: name,
                file_type: type,
                created_at: file.lastModified,
                file: file
            };
            arr.push(item);
        });
        setUploadFileViewList(uploadFileViewList.concat(arr));

    };

    const onClickUpload = () => {
        var formData = new FormData();

        if (!uploadFileViewList.length) {
            alert("파일을 선택해주세요");
            return;
        }

        uploadFileViewList.forEach(item => {
            formData.append("file", item.file);
        });

        API.post('/manager/invention/form', formData).then(() => {
            alert("업로드되었습니다");
            onClickPopupToggle();
            setUploadFileViewList([]);
        });
    };

    const onClickDetail = (idx) => {
        history.push(`/invention/${idx}`);
    };

    const getInventionFileList = () => {
        API.get('/manager/invention/form').then(res => {
            setDownloadFileViewList(res.data);
        });
    };
    const onClickDownload = (idx) => {
        window.$Global.download(`/manager/invention/form/${idx}/download`);
    };

    const onClickDownloadDelete = (idx) => {
        API.delete(`/manager/invention/form/${idx}`).then(() => {
            alert('삭제되었습니다');
            getInventionFileList();
        });
    };

    const onClickPopupToggle = () => {
        setPopupShow(!popupShow);
    };

    btns_state_tab.forEach((item, i) => {
        if (item.checked) {
            table_head_tag.push(<th key={i}>{item.text}</th>);
        }
        tab_tags.push(
            <CheckBox key={i} text={item.text} checked={item.checked} onChangeChecked={onChangeTabChecked} disabled={selectedBasics}/>
        );
    });

    const onClickPopupUpShow = () => {
        setPopupShow(true);
        setShowType("upload");
    };

    const onClickPopupDownShow = () => {
        setPopupShow(true);
        setShowType("download");
    };

    return (
        <div id="InventionListView">
            <div className="header">
                <h2 className="title">직무발명신고서 목록 보기</h2>
                <div className="btns">
                    {
                        tabState
                        ?
                        <>
                            <ToggleButtonWithSpan text="Tab 기본 설정하기 " condition={{on: 'ON', off: 'OFF'}} selected={selectedBasics} toggleSelected={onClickToggleselectedBasics}/>
                            <button className="btn_tab_setting_save" onClick={() => setTabState(false)}>Tab 설정 저장</button>
                        </>
                        :
                        <>
                            {
                                isAdmin && <button className="btn_invention_form_upload" onClick={onClickPopupUpShow}>직무발명신고서 양식 등록/삭제</button>
                            }
                            <button className="btn_invention_form_download" onClick={onClickPopupDownShow}>직무발명신고서 양식 다운로드</button>
                            {
                                window.$Global.checkAuth('특허회사', auth) && <button className="btn_invention_add" onClick={() => onClickDetail('new')}>신규 발명신고서 등록</button>
                            }
                            <button className="btn_tab_setting" onClick={() => setTabState(true)}>Tab 설정</button>
                        </>
                    }
                </div>
            </div>
            <div className="wrap_tab_btns">
                {tabState && tab_tags}
            </div>
            <div className="table-box-wrap">
                <div className="wrap_list" id="scrollableDiv">
                    <table className="list">
                        <thead>
                        <tr>
                            {table_head_tag}
                        </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </div>
            </div>
            <PopupUpDownload show={popupShow}
                             showType={showType}
                             uploadFileViewList={uploadFileViewList}
                             downloadFileViewList={downloadFileViewList}
                             onClickClose={onClickPopupToggle}
                             onClickUpload={onClickUpload}
                             onClickDelete={onClickDeleteFileInUpload}
                             onChangeFile={onChangeFile}
                             onClickDownloadDelete={onClickDownloadDelete}
                             onClickDownload={onClickDownload}
            />
        </div>
    );
}

export default InventionListView;
