import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareAdminCommutePath } from "./groupwarePath";
import "./css/GroupwareAdminCommute.scss";

const GroupwareAdminCommute = () => {
  return (
    <div id="GroupwareAdminCommute">
      <GroupwareSideBar currentPath={groupWareAdminCommutePath} />
      <div className="commute_wrapper">
        <h2>출퇴근 관리</h2>
        <div>
          <h5>
            <span>오늘의 출퇴근 현황</span>
          </h5>
          <div className="commute_check">
            <div className="commute_check_items">
              <div>
                <p>출근 미체크</p>
                <p>
                  <span>1</span>명
                </p>
              </div>
              <div>
                <p>늦은 출근</p>
                <p>
                  <span>2</span>명
                </p>
              </div>
            </div>
            <div className="commute_check_items">
              <div>
                <p>전일 퇴근 미체크</p>
                <p>
                  <span>2</span>명
                </p>
              </div>
              <div>
                <p>휴가</p>
                <p>
                  <span>2</span>명
                </p>
              </div>
            </div>
            <div className="commute_check_items">
              <div>
                <p>전일 이른 퇴근</p>
                <p>
                  <span>0</span>명
                </p>
              </div>
              <div>
                <p>정상 출근</p>
                <p>
                  <span>32</span>명
                </p>
              </div>
            </div>
          </div>
          <button className="link_detail_btn">자세히 보기 &gt;</button>
        </div>
        <div>
          <h5>
            <span>근로시간 현황판</span>2022-12-01 ~ 2022-12-28 09:10 기준
          </h5>
          <div className="commute_check">
            <div className="commute_check_items">
              <div>
                <p>연장근무 신청자</p>
                <p>
                  <span>4</span>명
                </p>
              </div>
              <ul>
                <li>
                  <p>박OO 차장</p>
                  <p>
                    <span>50</span>시간<span>12</span>분
                  </p>
                </li>
                <li>
                  <p>이OO 대리</p>
                  <p>
                    <span>51</span>시간<span>01</span>분
                  </p>
                </li>
                <li>
                  <p>정OO 사원</p>
                  <p>
                    <span>46</span>시간<span>22</span>분
                  </p>
                </li>
                <li>
                  <p>최OO 대리</p>
                  <p>
                    <span>48</span>시간<span>17</span>분
                  </p>
                </li>
              </ul>
            </div>
            <div className="commute_check_items">
              <div>
                <p>52시간 초과위험</p>
                <p>
                  <span>2</span>명
                </p>
              </div>
              <ul>
                <li>
                  <p>박OO 차장</p>
                  <p>
                    <span>50</span>시간<span>12</span>분
                  </p>
                </li>
                <li>
                  <p>이OO 대리</p>
                  <p>
                    <span>51</span>시간<span>01</span>분
                  </p>
                </li>
              </ul>
            </div>
            <div className="commute_check_items">
              <div>
                <p>52시간 초과자</p>
                <p>
                  <span>1</span>명
                </p>
              </div>
              <ul>
                <li>
                  <p>박OO 차장</p>
                  <p>
                    <span>50</span>시간<span>12</span>분
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="commute_company_info">
          <h5>
            <span>우리회사 근태 설정현황</span>2022-12-01 ~ 2022-12-28 09:10
            기준
          </h5>
          <div>
            <div>
              <p>출근</p>
              <p>09:00</p>
            </div>
            <div>
              <p>퇴근</p>
              <p>18:00</p>
            </div>
            <div>
              <p>휴게시간</p>
              <p>12:00~13:00</p>
            </div>
          </div>
          <div>
            <div>
              <p>근무일</p>
              <p>월~금</p>
            </div>
            <div>
              <p>휴일</p>
              <p>주말,공휴일</p>
            </div>
            <div>
              <p>근무위치</p>
              <p>회사 주소지 반경 500m</p>
            </div>
          </div>
          <div>
            <div>
              <p>연장근무 신청 기준</p>
              <p>오후 18:30 ~ 22:00</p>
            </div>
          </div>
          <button className="link_detail_btn">모두 보기 &gt;</button>
        </div>
      </div>
    </div>
  );
};

export default GroupwareAdminCommute;
