import React, { useState } from "react";

function AnnualFeeBar({
  year,
  top,
  left,
  sum_height,
  cost_height,
  td_cost_height,
  single_data,
  cost_data,
  td_cost_data,
  redirect,
  apply_at,
}) {
  const [balloon, setBallon] = useState(0);
  let sum_data = 0;

  if (cost_data > -1) {
    sum_data = cost_data + td_cost_data;
  } else {
    sum_data = single_data;
  }

  return (
    <div
      className={`${apply_at < year ? "disable" : ""} bar`}
      data-year={year}
      style={{ top: top + "%", height: sum_height + "%" }}
    >
      {Boolean(sum_data) && (
        <>
          <p style={{ left: left }}>
            {window.$Global.numberWithCommas(sum_data)}
          </p>
          {Boolean(td_cost_data) && (
            <div
              className="data"
              style={{ background: "#ffa600", height: td_cost_height + "%" }}
              onClick={() => redirect(year)}
              onMouseOver={() => setBallon(td_cost_data)}
            />
          )}
          {Boolean(cost_data || single_data) && (
            <div
              className="data"
              style={{ background: "#4593f5", height: cost_height + "%" }}
              onClick={() => redirect(year)}
              onMouseOver={() => setBallon(cost_data || single_data)}
            />
          )}
          {Boolean(balloon) && (
            <div className="balloon">
              {window.$Global.numberWithCommas(balloon) + " 원"}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AnnualFeeBar;
