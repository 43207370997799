import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransactionUsageList.scss";

function DocTradeMyTransactionUsageList({data}) {
    // type 0: 적립, 1: 지출
    let items = data.items;

    return (
        <>
            {
                items.length
                ?
                    items.map((item, idx) => {
                        return (
                            <>
                                <tr key={idx} className="docTradeMyTransactionUsageList table_content_row">
                                    <td className="table_content_number">{data.total_count - idx}</td>
                                    <td className="table_content_title">
                                        <span className="table_content_title_text">{item.content}</span>
                                    </td>
                                    <td className="table_content_division">{Boolean(item.type) ? '지출' : '적립'}</td>
                                    <td className="table_content_holding-points">
                                        <span className="table_content_holding-points_text">{window.$Global.commaify(item.amount)}</span>
                                    </td>
                                    <td className="table_content_date">{window.$Global.convertDate(item.created_at)}</td>
                                </tr>
                            </>
                        );
                    })
                : window.$Global.notTd(5, "조회된 거래 내역이 없습니다")
            }
        </>
    )
}

export default DocTradeMyTransactionUsageList;