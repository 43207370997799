import API from '../../util/api';

export default {
    getList(params) {
        return API.get('/common/consortium', {params: params});
    },

    getInfo(consortium_idx) {
        return API.get(`/common/consortium/${consortium_idx}`);
    },

    add(payload) {
        return API.post(`/common/consortium`, payload);
    },

    update(consortium_idx, payload) {
        return API.patch(`/common/consortium/${consortium_idx}`, payload);
    },

    uploadIntroduce(consortium_idx, payload) {
        return API.post(`/common/consortium/${consortium_idx}/introduce`, payload);
    },

    download(consortium_idx, introduce_idx) {
        window.$Global.download(`/common/consortium/${consortium_idx}/introduce/${introduce_idx}/download`);
    },

    deletePost(consortium_idx) {
        return API.delete(`/common/consortium/${consortium_idx}`);
    },

    deleteIntroduceFile(consortium_idx, introduce_idx) {
        return API.delete(`/common/consortium/${consortium_idx}/introduce/${introduce_idx}`);
    }
};