import React from "react";
import "./css/ResearchTechInfoText.scss";
import session1 from "../../assets/images/common/technologytrend.png";
const ResearchTechInfoText = () => {
  return (
    <div id="ResearchTechInfoText">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>특허/기술트렌드 조사</h2>
        </div>
        <div className="content">
          <div className="title">
            <p>
              <span>
                비전문가도 간편하게 활용 가능한 특허/기술트렌드 조사 Tool
                입니다.
              </span>
              <br />
              <span>간단한 키워드 또는 특허권자 검색으로,</span>
              <br />
              <span>쉬운 기술동향 분석 그래프, 특허/기술트렌드 보고서, </span>
              <br />
              <span>간편한 요약본을 확인해 보세요.</span>
            </p>
          </div>
          <div className="subtitle">
            <p>Tip</p>
            <p>
              검색결과 유사도 범위를 조정해 보세요.
              <br /> 작성한 보고서는 사업계획서, 제안서, IR자료 제작에 활용해
              보세요.
            </p>
          </div>
          <img src={session1} alt="session1" />
        </div>
      </div>
    </div>
  );
};

export default ResearchTechInfoText;
