import React from "react";
import "./css/SignUpAlertAddSuccess.scss";

function SignupAlertAddSuccess({onClick}) {
    return (
        <div id="SignUpAlertAddSuccess">
            <i className="icon_bulb_check"/>
            <h2>자동 등록 완료!</h2>
            <div className="noti">
                <i className="icon_alert_circle"/>
                <p>
                    선택 한 IP(특허 · 상표 · 디자인)들이
                    <span> 가입 완료 후 IP별 상세 페이지에 자동 등록
                    </span>될 예정입니다.
                </p>
            </div>
            <button className='btn_confirm' onClick={onClick}>확인</button>
        </div>
    )
}

export default SignupAlertAddSuccess;