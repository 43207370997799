export const doughnutChartDataComp1 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "그 외",
  ],
  datasets: [
    {
      label: "Data",
      data: [
        1007942262160, 80180938000, 3278344000, 22747964408, 30948624444,
        18191849433, 265838526334,
      ],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataComp2 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [378, 130, 52, 71, 373, 85, 61],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataComp3 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [5, 9, 3, 7, 5, 6, 7],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataComp4 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataComp5 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};

export const doughnutChartDataTech1 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "그 외",
  ],

  datasets: [
    {
      label: "Data",
      data: [
        1007942262160, 80180938000, 3278344000, 22747964408, 30948624444,
        18191849433, 265838526334,
      ],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataTech2 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [378, 130, 52, 71, 373, 85, 61],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataTech3 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [5, 9, 3, 7, 5, 6, 7],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataTech4 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataTech5 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};

export const doughnutChartDataFinance1 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "그 외",
  ],
  datasets: [
    {
      label: "Data",
      data: [
        1007942262160, 80180938000, 3278344000, 22747964408, 30948624444,
        18191849433, 265838526334,
      ],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataFinance2 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [378, 130, 52, 71, 373, 85, 61],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataFinance3 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [5, 9, 3, 7, 5, 6, 7],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataFinance4 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataFinance5 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};

export const doughnutChartDataBiz1 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "그 외",
  ],
  datasets: [
    {
      label: "Data",
      data: [
        1007942262160, 80180938000, 3278344000, 22747964408, 30948624444,
        18191849433, 265838526334,
      ],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataBiz2 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [378, 130, 52, 71, 373, 85, 61],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataBiz3 = {
  labels: [
    "반도체디스플레이",
    "사이버보안",
    "수소",
    "우주항공해양",
    "인공지능",
    "첨단바이오",
    "차세대 통신",
  ],
  datasets: [
    {
      label: "Data",
      data: [5, 9, 3, 7, 5, 6, 7],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataBiz4 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
export const doughnutChartDataBiz5 = {
  labels: ["대기업", "중견기업", "소상공인", "중기업", "소기업", "미분류"],
  datasets: [
    {
      label: "Data",
      data: [4, 10, 30, 20, 30, 6],
      backgroundColor: [
        "#FED0EE",
        "#D0E8FF",
        "#FBE38E",
        "#A9F4D0",
        "#DBAEFF",
        "#FDD09F",
      ],
    },
  ],
};
