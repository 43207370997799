import links from "../../../const/links";

export default {
  match_admin: [
    { url: "/admin/check", name: "가입자 확인" },
    {
      url: "/admin/list/news",
      url2: "/admin/list/youtube",
      name: "IP담보대출",
    },
    // { url: '/admin/list/video', name: '동영상' },
    {
      url: "/admin/service",
      redirectURL: "/admin/service/list",
      name: "서비스",
    },
    {
      url: "/admin/manage",
      redirectURL: "/admin/manage/list",
      name: "관리메뉴",
    },
    // { url: '/admin/list/document', name: '문서' },
    { url: "/admin/list/edu", name: "특허교육자료" },
    {
      url: "/admin/community",
      redirectURL: "/admin/community/list",
      name: "커뮤니티",
    },
    { url: "/admin/invest", name: "특허투자자" },
    { url: "/admin/freeUs", name: "미국출원" },
    { url: "/admin/account", name: "계정생성" },
    { url: "/admin/annual", name: "연차료관리" },
    { url: "/admin/gptboard/list", name: "GPT게시판관리" },
    { url: "/admin/support", name: "고객문의" },
  ],

  match_intro_ipnow: [{ url: "/people", name: "People" }],

  match_intro_login: [
    { url: "/", name: "HOME" },
    { url: links.taskManage, name: "정부사업 찾기" },
    { url: links.marketInfo, name: "시장 정보" },
    { url: "/intro/page", name: "2022 지원사업 총정리" },
    {
      url: links.exposition,
      name: "정부사업 주요 소식",
      child_Tab: [
        {
          url: links.exposition,
          name: "행사정보",
        },
        {
          url: links.businessDescription,
          name: "사업설명회 영상",
        },
        {
          url: links.govSupport,
          name: "정부지원 정책",
        },
      ],
    },
  ],
  match_intro: [
    { url: "/intro/main", name: "서비스소개" },
    { url: "/intro/price", name: "가격안내" },
    { url: "/", name: "고객사례" },
    {
      url: "/intro/service",
      name: "도입문의",
    },
    { url: "/taskManage/page2", name: "2024 정부사업의 모든것" },
  ],

  match_openMarket: [{ url: "/trade/list", name: "상표거래" }],

  match_findx: [
    { url: links.taskManage, name: "사업 리스트" },
    // { url: '/consortium/list', name: '사업 파트너 기업' },
    { url: links.marketInfo, name: "시장 정보 보기" },
    { url: "/docTrade/list", name: "제안서 샘플마켓" },
    { url: "/exposition/list", name: "교육·행사·경진" },
    { url: "/businessDescription/list", name: "사업 설명회" },
    { url: "/govSupport/list", name: "정부지원 정책알림" },
    { url: "/consultCom/list", name: "컨설팅 기업" },
  ],

  match_ip_service: [
    { url: "/patent/list", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/list", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/list", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/list", redirectURL: "/deadline/list", name: "기한관리" },
    // { url: "/inventor/stepone", name: "발명자 찾기" },
    // { url: '/application/', redirectURL: '/application/list', name: '특허 활용 정보', activeTab: 3},
    // { url: '/trade/', redirectURL: '/trade/list', name: '상표거래', activeTab: 4},
  ],

  match_ip_service_login: [
    { url: "/patent/list", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/list", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/list", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/list", redirectURL: "/deadline/list", name: "기한관리" },
    { url: "/test", name: "발명자 찾기" },
  ],

  match_empty_menu: [],

  match_renewal_gov: [
    { url: "/taskManage/page2", name: "2024 정부사업의 모든것" },
    { url: "/taskManage/list", name: "정부사업 검색" },
    { url: "/taskManage/setting", name: "맞춤 정부 사업 알림" },
    // { url: "/taskManage/list", name: "정부사업 경쟁율 통계 분석" },
    {
      url: "/businessDescription/list",
      name: "정부사업 콘텐츠",
      child_Tab: [
        // {
        //   url: "/taskManage/page",
        //   name: "2023 부처별 통합공고",
        // },
        {
          url: "/businessDescription/list",
          name: "사업설명회 영상",
        },
        {
          url: "/exposition/list",
          name: "비즈니스 행사정보",
        },
        /*{
          url: "/taskManage/page",
          name: "2023 부처별 통합공고",
        },*/
      ],
    },
    {
      url: "/business/main",
      name: "사업계획서 작성",
      // child_Tab: [
      //   {
      //     url: "/business/main",
      //     name: "사업계획서 작성",
      //   },
      //   {
      //     url: "/business/main",
      //     name: "나의 사업계획서",
      //   },
      //   {
      //     url: "/business/main",
      //     name: "사업계획서 작성팁",
      //   },
      //   {
      //     url: "/business/main",
      //     name: "사업계획서 샘플 마켓",
      //   },
      // ],
    },
  ],
  match_renewal_market: [
    { url: "/marketInfo/category", name: "시장정보 검색" },
    // { url: "/marketInfo/category", name: "관심 시장 뉴스" },
    { url: "/marketInfo/request", name: "시장정보 요청" },
  ],

  match_renewal_groupware: [
    { url: `/groupware/mail`, name: "메일", isNew: true },
    {
      url: `/groupware/office?section=command&command=showCommandList&method=receive_list`,
      name: "프로젝트 관리",
    }, // 기존 오피스룸
    { url: `/cashflow/intro`, name: "캐시플로우" },
    { url: `/groupware/report/daily/ins`, name: "업무보고" },
    { url: "/groupware/approval", name: "전자결재", isNew: true },
    { url: `/groupware/schedule`, name: "일정관리" },
    { url: `/groupware/cloud`, name: "B-클라우드" },
    { url: "/groupware/attend", name: "근태관리" },
    { url: "/groupware/vacation", name: "휴가관리" },
    {
      url: "/groupware/works?section=expense&command=expenseWriteForm&doc_type=expense",
      name: "지출관리",
    },
    //{ url: `/groupware/office?section=emp&command=lstEmp`, name: "B-Works" }, // 기존 오피스룸
    {
      url: "/groupware/emp?section=empAdmin&command=lstEmp",
      name: "인사관리",
      isAdmin: true,
    },
    /**
    {
      //Admin용 메뉴
      url: "/groupware/adminmenu?section=assignmenu&command=lstMenuAuth",
      name: "관리자",
      isAdmin: true,
    },
    */
  ],

  match_renewal_ip: [
    { url: "/renewal/ip", name: "IP관리 HOME" },
    { url: "/renewal/groupware", name: "특허" },
    { url: "/renewal/groupware", name: "상표" },
    { url: "/renewal/groupware", name: "디자인" },
    { url: "/renewal/groupware", name: "기한관리" },
  ],

  match_company_info: [
    // { url: "/competitor/temp/first", name: "나의 경쟁사" },
    // { url: "/competitor/temp/first", name: "경쟁사 찾기" },
    // { url: "/competitor/temp/first", name: "경쟁사 NOW" },
  ],
};
