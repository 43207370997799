import React, { useEffect, useState } from "react";
import "../css/MarketInfoEditList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";
import CommonAPI from "../../../API/common";
import _ from "lodash";

function MarketInfoEditList() {
  const history = useHistory();
  const [curPage, setCurPage] = useState(1);
  const [tbody, setTbody] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [downloadCnt, setDownloadCnt] = useState(0);

  const count = 20;

  useEffect(() => {
    let params = {
      page: 1,
      count: count,
    };
    CommonAPI.getMarketDocumentList(params).then((res) => {
      let data = res.data;
      setTbody(data);
      checkHasMore(1, data.total_count);
    });
    getCompanyInfo();
  }, []);

  const checkHasMore = (page, total_count) => {
    if (page < total_count) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const nextList = () => {
    let params = {
      page: curPage + 1,
      count: count,
    };
    setCurPage(curPage + 1);
    CommonAPI.getMarketDocumentList(params).then((res) => {
      let data = res.data;
      data.items = tbody.items.concat(data.items);
      setTbody(data);
      checkHasMore(params.page, data.total_count);
    });
  };

  const download = (idx) => {
    CommonAPI.downloadMarketDocument(idx);
    getCompanyInfo();
  };

  const deleteDoc = (idx, index) => {
    CommonAPI.deleteMarketDocument(idx).then(() => {
      let copy = _.cloneDeep(tbody);
      copy.items.splice(index, 1);
      copy.total_count -= 1;
      setTbody(copy);
    });
  };

  const getCompanyInfo = () => {
    CommonAPI.getCompanyInfo().then((res) => {
      const data = res.data;
      setDownloadCnt(data.mi_download_cnt);
    });
  };

  return (
    <div className="marketInfoEditList">
      <div className="marketInfoEditList_content">
        <div className="header">
          <h2>시장 정보 작성 리스트</h2>
          <p>내가 작성한 시장정보를 수정하고 다운받아 보세요.</p>
        </div>
        <table>
          <colgroup>
            <col width={50} />
            <col width={220} />
            <col width={420} />
            <col width={80} />
            <col width={80} />
            <col width={60} />
            <col width={60} />
          </colgroup>
          <thead>
            <tr>
              <th>순번</th>
              <th style={{ textAlign: "left" }}>분&emsp;류</th>
              <th style={{ textAlign: "left" }}>제&emsp;목</th>
              <th>작성 날짜</th>
              <th>비 고</th>
              <th>다운로드</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {tbody.items?.map((item, idx) => {
              return (
                <tr key={item.idx}>
                  <td>{idx + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    {item.category1_name} &gt; {item.category2_name}
                  </td>
                  <td style={{ textAlign: "left" }}>{item.title}</td>
                  <td>{window.$Global.convertDate(item.created_at)}</td>
                  <td>
                    <button
                      className="btn_load"
                      onClick={() => history.push(`edit?idx=${item.idx}`)}
                    >
                      수정
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon_download_attached ir_txt"
                      onClick={() => download(item.idx)}
                    >
                      다운로드
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon_del_trashcan ir_txt"
                      onClick={() => deleteDoc(item.idx, idx)}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketInfoEditList;
