import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MyInfo from "../components/myPage/MyInfo";
import MobileMyPage from "../components/myPage/mobile/MobileMyPage";
import MobileInfoEdit from "../components/myPage/mobile/MobileInfoEdit";
import Withdrawal from "../components/myPage/Withdrawal";
import CustomSwitch from "./common/CustomSwitch";

function MyPageRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/info`} requireAuth={true} component={MyInfo}/>
			<UserAuthRoute exact path={`${match.path}/mobile`} requireAuth={true} component={MobileMyPage}/>
			<UserAuthRoute exact path={`${match.path}/mobile/edit`} requireAuth={true} component={MobileInfoEdit}/>
			<UserAuthRoute exact path={`${match.path}/withdrawal`} requireAuth={true} component={Withdrawal}/>
		</CustomSwitch>
	)
}

export default MyPageRoute;