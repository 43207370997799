import React, {useState} from "react";
import "../css/AdminMail.scss";
import AdminAPI from "../../../API/admin";
import InfoCompanyAPI from "../../../API/infoCompany";
import _ from "lodash";
import AdminMailingTemplate from "./AdminMailingTemplate";
import * as htmlToImage from "html-to-image";

function AdminMail({history}) {
    AdminAPI.getAPI().defaults.headers['x-token'] = "a99ff1fa413d55e0f0bc0aab5be3c557:f0b397cd658ccfcebb873f88fd36b374ed90bcdf20739798fc4ed2ade1fee1804f277a966622657b52e864604c9adb6572e56388ee01c83bda4fb2b13b8cebb85a065aed51e3a8c6d662f219ccc9f8989d09557eb6c92a05198844aa455d7774163cacb8cbf54f41f1444e4a470b2fb1127e6cd51418062e46e90c2452bb9a065572135980fbf4936e741c81280a47eab3f98f893c90dc3014547e302c0203526942e8985692e56d2e1725a3456dcf9761112fbd302aae774dd74008bd429ca4dcbac32eb5c06a14d389de7e302e64763402778ecb030a781bf1cfa9b5bcf01491bd57e67855eaa63864fa7154ae6717413306339883ce2b9fa14f7a73ca0d2ec7bde2a26c7775912333596e0f70a5a8";
    const [downloadType, setDownloadType] = useState(1); // 전체 1, ipnow만 0
    const [payload, setPayload] = useState({
        name: "",
        company_code: "",
    });
    const [selectCompanyList, setSelectCompanyList] = useState([]);
    const [mouse, setMouse] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [keywordList, setKeywordList] = useState([]);
    const show = mouse && Boolean(keywordList.length);

    const onClickSearch = () => {
        InfoCompanyAPI.getInfoCompany(keyword).then(res => {
            setMouse(true);
            setKeywordList(res.data);
        });
    };

    const choiceKeyword = (name, code) => {
        setMouse(false);
        setPayload({...payload, name: name, company_code: code});

        let copy = _.cloneDeep(selectCompanyList);
        copy.push({name: name, company_code: code});
        setSelectCompanyList(copy);
    };

    const onClickClose = () => {
        setMouse(false);
        setKeyword("");
        setKeywordList([]);
        setPayload({name: "", company_code: ""});
    };

    const downloadCompanyList = () => {
        let companyCodeList = selectCompanyList.map(item => item.company_code);
        AdminAPI.downloadCompanyListExcel({company_code_list: companyCodeList}).then(res => {
            window.$Global.dataDownload(res);
            console.log(res.data);
        });
    };

    const capture = (type) => {
        setDownloadType(type);

        setTimeout(() => {
            try {
                htmlToImage.toPng(document.getElementById('AdminMailingTemplate'))
                    .then(function (dataUrl) {
                        console.log(dataUrl);
                        let a = document.createElement("a"); //Create <a>
                        a.href = dataUrl; //Image Base64 Goes here
                        a.download = (payload.name || "Image") + ".png"; //File name Here
                        a.click(); //Downloaded file
                    });
            } catch (err) {
                alert("서버에서 불러올 데이터가 없어 다운할 수 없습니다");
            }
        }, 100);
    } ;

    return (
        <>
            <div id="AdminMail">
                <h2 className="page_title">메일링</h2>
                <div className="articles">
                    <div className="field">
                        <span>검색</span>
                        <input type="text"
                               value={keyword}
                               placeholder="회사명을 입력하여 검색하실 수 있습니다"
                               onChange={e => setKeyword(e.target.value)}
                               onKeyUp={e => e.key === 'Enter' && onClickSearch()}
                               onFocus={() => setMouse(true)}
                        />
                        {
                            show && <button className="icon_badge_del ir_txt" onClick={onClickClose}>닫기</button>
                        }
                        {
                            show
                            &&
                            <ul className="keyword_list">
                                {
                                    keywordList.map((item, idx) => {
                                        return (
                                            <li key={idx} onClick={() => choiceKeyword(item.name, item.company_code)}>
                                                <p>
                                                    <b>회사명:</b>{item.name}</p>
                                                <p>
                                                    <b>주소:</b>{item.location}
                                                </p>
                                                <p>
                                                    <b>법인번호:</b>{item.corporate_number}
                                                </p>
                                                <p>
                                                    <b>사업자 등록번호:</b>{item.license_number}
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                        <button className="btn_search" onClick={onClickSearch}>검색</button>
                    </div>
                    <div className="field">
                        <span>회사명</span>
                        <p>{payload.name}</p>
                    </div>
                    <div className="field">
                        <span>회사코드</span>
                        <p>{payload.company_code}</p>
                    </div>
                    <div className="field">
                        <span>선택 회사리스트</span>
                        <p>{selectCompanyList.map(item => item.name).join(", ")}</p>
                    </div>
                </div>
                <div className="ctl_btns">
                    <button className="btn" onClick={() => capture(1)}>전체 이미지 다운</button>
                    <button className="btn" onClick={() => capture(0)}>특허 이미지만 다운</button>
                    <button className="btn btn_excel" onClick={downloadCompanyList}>선택 회사 엑셀 다운</button>
                    <button className="btn btn_reset" onClick={() => setSelectCompanyList([])}>선택 회사 초기화</button>
                </div>
            </div>
            <AdminMailingTemplate history={history} company_code={payload.company_code} isAll={downloadType}/>
        </>
    )
}

export default AdminMail;