import React from "react";
import "../css/SignupInputHead.scss";

function SignupInputHead({ title }) {
  return (
    <div id="SignupInputHead">
      <div className="wrap">
        <h2>{title}</h2>
        <p className="pc">비즈내비에 오신 것을 환영합니다.</p>
      </div>
    </div>
  );
}

export default SignupInputHead;
