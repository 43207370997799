import React from "react";
import "../css/AdminService.scss";
import qs from "query-string";
import AdminLawfirm from "./AdminLawfirm";
import AdminMail from "./AdminMail";
import AdminConsulting from "./AdminConsulting";
import AdminStatistic from "./AdminStatistic";
import AdminCalcYearPayAdd from "./AdminCalcYearPayAdd";
import AdminMarketCategory from "./AdminMarketCategory";
import AdminMarketList from "./AdminMarketList";
import AdminRecommendTask from "./AdminRecommendTask";
import AdminIntegrationTask from "./AdminIntegrationTask";
import AdminTaskService from "./AdminTaskService";
import AdminIntegrationTaskList from "./AdminIntegrationTaskList";
import AdminManageResearch from "./AdminManageResearch";
import AdminManageCategory from "./AdminManageCategory";
import AdminTaskUpload from "../AdminTaskUpload";

function AdminService({ history, location }) {
  const category = qs.parse(location.search).category || 0;
  const menus = [
    {
      name: "해외 로펌",
      component: <AdminLawfirm />,
    },
    {
      name: "메일링",
      component: <AdminMail />,
    },
    {
      name: "컨설팅",
      component: <AdminConsulting />,
    },
    {
      name: "트래킹",
      component: <AdminStatistic />,
    },
    {
      name: "특허 연차료",
      component: <AdminCalcYearPayAdd />,
    },
    {
      name: "시장 정보",
      component: <AdminMarketList />,
    },
    {
      name: "시장 정보 분야 추가",
      component: <AdminMarketCategory />,
    },
    {
      name: "선행기술 조사 추가",
      component: <AdminManageResearch />,
    },
    {
      name: "선행기술 조사 분야 추가",
      component: <AdminManageCategory />,
    },
    {
      name: "추천 사업",
      component: <AdminRecommendTask />,
    },
    {
      name: "통합 공고 업로드",
      component: <AdminIntegrationTask />,
    },
    {
      name: "통합 공고 목록",
      component: <AdminIntegrationTaskList />,
    },
    {
      name: "사업 공고 업로드",
      component: <AdminTaskService />,
    },
    {
      name: "공고 업로드",
      component: <AdminTaskUpload />,
    },
  ];

  const onClickSelectCategory = (idx) => {
    history.push(`?category=${idx}`);
  };

  return (
    <div id="AdminService">
      <div className="category_tab">
        <h2>카테고리</h2>
        <ul>
          {menus.map((item, idx) => {
            return (
              <li
                key={idx}
                className={`${category == idx ? "active" : ""}`}
                onClick={() => onClickSelectCategory(idx)}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
      {menus.map((item, idx) => category == idx && item.component)}
    </div>
  );
}

export default AdminService;
