const AnnualMarketInfo = ({ item, showPopup }) => {
  let hasContent = Boolean(item.content || item.content_image_list.length);

  return (
    <div id="AnnualMarketInfo">
      <img
        src={
          item.image_key
            ? window.$Global.getCDN(item.image_key)
            : require("../../assets/images/common/no_image_md.png").default
        }
        alt="이미지"
        onContextMenu={window.$Global.blockRightClick}
        onClick={() => showPopup(item, "graph")}
      />
      <div className="cont">
        <strong>{item.title}</strong>
        <div className="btn_box">
          {hasContent && (
            <button onClick={() => showPopup(item, "content")}>
              <span>관련글보기</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnualMarketInfo;
