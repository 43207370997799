import API from "../../util/api";

export default {
  getProductListCompany() {
    return API.get("/manager/product");
  },
  updateProduct(product_idx, payload) {
    return API.patch(`/manager/product/${product_idx}`, payload);
  },
  deleteProduct(product_idx) {
    return API.delete(`/manager/product/${product_idx}`);
  },
  addProduct(payload) {
    return API.post(`/manager/product`, payload);
  },
  getProductListAsAgent() {
    return API.get("/agent/product");
  },
  updateProductAsAgent(product_idx, payload) {
    return API.patch(`/agent/product/${product_idx}`, payload);
  },
  deleteProductAsAgent(product_idx) {
    return API.delete(`/agent/product/${product_idx}`);
  },
  addProductAsAgent(payload) {
    return API.post(`/agent/product`, payload);
  },
  getProductListAsInvestor() {
    return API.get("/investor/product");
  },
  updateProductAsInvestor(product_idx, payload) {
    return API.patch(`/investor/product/${product_idx}`, payload);
  },
  deleteProductAsInvestor(product_idx) {
    return API.delete(`/investor/product/${product_idx}`);
  },
  addProductAsInvestor(payload) {
    return API.post(`/investor/product`, payload);
  },
  getProductMarketInfo(payload) {
    return API.get(`/info/ip/patent/getProductMarketInfo`, { params: payload });
  },
  getPatentProductList() {
    return API.get(`/info/ip/patent/getPatentProductList`);
  },
  getConnectedPatentList(payload) {
    return API.get(`/info/ip/patent/getConnectedPatentList`, {
      params: payload,
    });
  },
  insertPatentProduct(payload) {
    return API.post(`/info/ip/patent/insertPatentProduct`, payload);
  },
  deletePatentProduct(payload) {
    return API.delete(`/info/ip/patent/deletePatentProduct`, {
      params: payload,
    });
  },
  downloadExcel(payload) {
    return window.$Global.newDownload(`/manager/patent/excel/product`, payload);
  },
};
