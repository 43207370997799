import React, {useState, useEffect, useContext} from 'react';
import _ from "lodash";
import {Link} from 'react-router-dom';
import {useParams} from "react-router-dom";
import './css/Detail.scss';
import FieldOa from "./FieldOa";
import FormCompanyKr from "./FormCompanyKr";
import FormAgentKr from "./FormAgentKr";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import DetailPopupDownload from "../../common/DetailPopupDownload";
import DetailPopupUpload from "../../common/DetailPopupUpload";
import InnerAlertPopup from "./InnerAlertPopup";
import API from "../../../util/api";
import MemberContext from '../../../store/Member';
import PatentContext from '../../../store/Patent';
import InventionContext from '../../../store/Invention';
import typeName from "./constrant/typeName";
import FormPatentAddKr from "./FormPatentAddKr";
import FormCompanyUs from "./FormCompanyUs";
import FormAgentUs from "./FormAgentUs";
import FormCompanyCn from "./FormCompanyCn";
import FormAgentCn from "./FormAgentCn";
import FormCompanyPct from "./FormCompanyPct";
import FormAgentPct from "./FormAgentPct";
import FormCompanyEp from "./FormCompanyEp";
import NormalRow from "./common/NormalRow";
import FormAgentEp from "./FormAgentEp";
import SelectRow from "./common/SelectRow";
import ProductAndStateRowContainer from "./common/ProductAndStateRowContainer";
import AccountAPI from "../../../API/account";
import ProductAPI from "../../../API/product";
import PatentAPI from "../../../API/patent";
import CompanyAPI from "../../../API/company";
import FieldOaAgent from "./FieldOaAgent";
import InventorRow from "./common/InventorRow";
import ShareRow from "./common/ShareRow";
import FormCompanyJp from "./FormCompanyJp";
import FormAgentJp from "./FormAgentJp";
import PopupPatentApprovalApply from "./PopupPatentApprovalApply";
import FormCompanySP from "./FormCompanySP";
import FormAgentSP from "./FormAgentSP";
import FormCompanyTW from "./FormCompanyTW";
import FormAgentTW from "./FormAgentTW";
import FormCompanyRU from "./FormCompanyRU";
import FormAgentRU from "./FormAgentRU";
import FormCompanyNZ from "./FormCompanyNZ";
import FormAgentNZ from "./FormAgentNZ";
import FormCompanyIN from "./FormCompanyIN";
import FormAgentIN from "./FormAgentIN";
import FormAgentVN from "./FormAgentVN";
import FormCompanyVN from "./FormCompanyVN";
import FormAgentAU from "./FormAgentAU";
import FormCompanyAU from "./FormCompanyAU";
import FormAgentCA from "./FormAgentCA";
import FormCompanyCA from "./FormCompanyCA";
import FormCompanyBR from "./FormCompanyBR";
import FormAgentBR from "./FormAgentBR";
import FormCompanyMO from "./FormCompanyMO";
import FormAgentMo from "./FormAgentMO";
import FormCompanySG from "./FormCompanySG";
import FormAgentSG from "./FormAgentSG";
import FormCompanyMX from "./FormCompanyMX";
import FormAgentMX from "./FormAgentMX";
import FormCompanyIL from "./FormCompanyIL";
import FormAgentIL from "./FormAgentIL";
import FormCompanyPH from "./FormCompanyPH";
import FormAgentPH from "./FormAgentPH";
import FormAgentHK from "./FormAgentHK";
import FormCompanyHK from "./FormCompanyHK";
import FormAgentEA from "./FormAgentEA";
import FormCompanyEA from "./FormCompanyEA";
import FormAgentZA from "./FormAgentZA";
import FormCompanyZA from "./FormCompanyZA";

function Detail({history}) {
    let patent = useContext(PatentContext);
    let invention = useContext(InventionContext);
    let ListOaTag; // oa 리스트 tag
    let agentCompanyListTag = [<option key={0} value={"null"}>없음</option>]; // 대리인 리스트 tag

    const {auth, manage_company_idx} = useContext(MemberContext);
    const [agentCompanyList, setAgentCompanyList] = useState([]);
    const [managerSelectTag, setManagerSelectTag] = useState([]);
    const [inventorSelectTag, setInventorSelectTag] = useState([]); // 발명자 목록

    const {patent_idx} = useParams();
    const [patentInfo, setPatentInfo] = useState(patent);
    const [historyPatentInfo, setHistoryPatentInfo] = useState(patentInfo);
    const [inventionInfo, setInventionInfo] = useState(invention);

    const [companyMember, setCompanyMember] = useState([])
    const [editing, setEditng] = useState(false);
    const [jointApplyListTag, setJointApplyListTag] = useState([]);
    const [inventorListTag, setInventorListTag] = useState([]);
    const [innerPopupShow, setInnerPopupShow] = useState(false); // 내부기한 팝업 여부
    const [downPopupShow, setDownPopupShow] = useState(false); // 팝업 보여주기 여부
    const [upPopupShow, setUpPopupShow] = useState(false); // 팝업 보여주기 여부
    const [categoryShow, setCategoryShow] = useState(false); // 항목 추가 보여주기
    const [patentApprovalApplyPopupShow, setPatentApprovalApplyPopupShow] = useState(false);

    const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
    const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
    const [popupProp, setPopupProp] = useState({type: null, params: {}, oa_idx: null, ep_idx: null});
    const [productSelect, setProductSelect] = useState([]); // 제품 목록
    const [tooltipState, setTooltipState] = useState(true); // 도움말 설정
    // 신규 등록일 경우 사용하는 변수
    const [inventionStateMenu, setInventionStateMenu] = useState(false);
    const isNew = patent_idx == "new";
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isAgent = window.$Global.checkAuth("대리인", auth);

    useEffect(() => {
        if (!isNew) {
            window.$Global.updateToast();
        }
    }, []);

    useEffect(() => {
        setEditng(patentInfo.editing);
    }, [patentInfo.editing]);

    useEffect(() => {
        if (downPopupShow) {
            setUpPopupShow(false);
        }
    }, [downPopupShow]);

    useEffect(() => {
        if (upPopupShow) {
            setDownPopupShow(false);
        }
    }, [upPopupShow]);

    useEffect(() => {
        let productListTag = patentInfo.product?.map((item, index) => {
            if (index == 0) return;
            return (
                <div key={index} className="rows">
                    <div className="row no_data"/>
                    <ProductAndStateRowContainer index={index} patentInfo={patentInfo} setPatentInfo={setPatentInfo} productSelect={productSelect} onClickProductAdd={onClickProductAdd} onClickProductDelete={onClickProductDelete} />
                </div>
            );
        });
        setPatentInfo({...patentInfo, productListTag: productListTag});
    }, [patentInfo.investor, patentInfo.product, patentInfo.editing]);

    useEffect(async () => {
        if (isCompany) {
            await ProductAPI.getProductListCompany().then(res => setProductSelect(res.data.map(item => <option key={item.idx} value={item.idx}>{item.name}</option>)));
            await AccountAPI.getAgentCompanyList().then(res => setAgentCompanyList(res.data));
        }
        if (!isNew) {
            await setPatent();
        }
        await getAccount();
        let patent_tooltip = Number(window.localStorage.getItem("patent_tooltip"));
        setTooltipState(patent_tooltip == null ? false : patent_tooltip);
    }, [auth, isNew]);

    useEffect(() => {
        setJointApplyListTag(
            patentInfo.joint_apply?.map((item, index) => {
                return (
                    <div key={index} className="rows">
                        <div className="row no_data"/>
                        <div className={`row`}>
                            <div className="title">
                                <h2>공동출원인<i className="icon_info" data-content={window.$Global.getInfoMessage('공동출원인')}/></h2>
                                {
                                    patentInfo.editing &&
                                    (
                                        index == 0
                                            ? <button className="icon_plus" onClick={onClickJointApplyAdd}/>
                                            : <button className="icon_badge_del" onClick={() => onClickJointApplyDelete(index)}/>
                                    )
                                }
                            </div>
                            {
                                patentInfo.editing
                                ? <input type="text" value={item.name || ""} onChange={(e) => onChangeJointApplyInput(index, "name", e.target.value)} style={{width: 135}}/>
                                : <p>{item.name ? item.name : 'N/A'}</p>
                            }
                        </div>
                        <div className={`row`}>
                            <h2 className={`title`}>지분율<i className="icon_info" data-content={window.$Global.getInfoMessage('지분율')}/></h2>
                            {
                                patentInfo.editing
                                ? <input type="text" value={item.share || ""} onChange={(e) => onChangeJointApplyInput(index, "share", e.target.value)} style={{width: 135}}/>
                                : <p>{item.share ? item.share : 'N/A'}</p>
                            }
                        </div>
                    </div>
                );
            })
        );

        setInventorListTag(
            patentInfo.inventor.map((item, index) => {
                let selectRow = <SelectRow key={index} title="대리인" content={patentInfo.agent_company_name} onChange={(e) => setPatentInfo({...patentInfo, agent_company_idx: e.target.value, agent_company_name: e.target.options[e.target.selectedIndex].text})}
                                           condition={patentInfo.editing} contentIdx={patentInfo.agent_company_idx} selectTag={agentCompanyListTag}/>;
                if (isCompany && patentInfo.inventor.length == 1 && index == 0) {
                    return (
                        <div key={index} className="rows">
                            {selectRow}
                        </div>
                    );
                }
                if (index == 0) return;
                let str = [];
                let agentTag = [];

                if (isCompany && index == 1) {
                    agentTag.push(selectRow);
                }

                str.push(
                    <div key={index} className="rows" style={{justifyContent: isCompany ? "flex-end" : "center"}}>
                        {agentTag}
                        {
                            isCompany
                                ?
                                <>
                                    <InventorRow index={index} editing={patentInfo.editing} inventor={item} inventorSelectTag={inventorSelectTag} onClickEvent={() => onClickInventorDelete(index)}/>
                                    <ShareRow index={index} editing={patentInfo.editing} inventor={item}/>
                                </>
                                : <NormalRow title={`발명자 ${index + 1}`} content={patentInfo.inventor[index].name}/>
                        }
                    </div>
                );
                return str;
            })
        );
    }, [patentInfo]);

    const setPatent = async () => {
        if (isNew) return;

        let result;
        if (isCompany) {
            result = await PatentAPI.getInfo(patent_idx).then(res => res.data);
            result.apply_approval = Boolean(await CompanyAPI.getCompanyConfig().then(res => res.data?.apply_approval));
            result.joint_apply = Boolean(result.joint_apply.length) ? result.joint_apply : [{company_idx: null, manage_number: null, name: '', share: 0, state: 1}];
            console.log(result);
        } else {
            result = await PatentAPI.getInfoAsAgent(manage_company_idx, patent_idx).then(res => res.data);
        }
        initData(result);
    };

    const updateFileCntSetPatent = async () => {
        let result = {};
        if (isCompany) {
            result = await PatentAPI.getInfo(patent_idx).then(res => res.data);
        } else {
            result = await PatentAPI.getInfoAsAgent(manage_company_idx, patent_idx).then(res => res.data);
        }

        updateFileCntData(result);
    }

    const getAccount = () => {
        if (isCompany) {
            AccountAPI.getAccountCompany().then(res => {
                let data = res.data;
                if (data.manager) {
                    setCompanyMember(data.manager);
                    setManagerSelectTag(
                        data.manager.map(item =>
                            (<option key={item.idx} value={item.idx}>{item.name}</option>)
                    ));

                    setInventorSelectTag(
                        data.manager.concat(data.general).map(item =>
                            (<option key={item.idx} value={item.idx}>{item.name}</option>)
                    ));
                }
            });
        } else {
            AccountAPI.getAccountAgent().then(res => {
                let data = res.data;
                if (data.manager) {
                    setManagerSelectTag(
                        data.manager.map(item =>
                            (<option key={item.idx} value={item.idx}>{item.name}</option>)
                    ));
                }
            });
        }
    };

    const onClickToolTipState = () => {
        setTooltipState(!tooltipState);
        window.localStorage.setItem("patent_tooltip", String(Number(!tooltipState)));
    };

    const initData = (data) => {
        let initialState = {
            ...data,
            editing: editing, // 수정 활성화 여부
            editingClass: 'not_editing', // 수정 여부
            nation_string: window.$Global.convertNationCodeToStr(data.nation),
            inventor: data.members.length ? data.members : [{name: "", idx: "", share: 100}], // 발명자
            product: data.product.length ? data.product : [{name: "", idx: "", state: 0}],
            screen_req: data.screen_req || false, // 심사청구 여부
            type: window.$Global.convertTypeToText(data.type), // 출원 유형
            divideApplication: {
                value: data.division || false,
                date: data.division_apply_at
            }, // 분할 출원
            draftReceivingDate: {
                date: data.draft_upload_at,
                inner_date: data.draft_deadline,
            }, // 초안 접수일
            draftInventorsDate: {
                date: data.draft_researcher_confirm_at,
                inner_date: data.draft_researcher_confirm_deadline,
            }, // 초안 발명자 검토일
            draftManagerDate: {
                date: data.draft_manager_confirm_at,
                inner_date: data.draft_manager_confirm_deadline,
            }, // 초안 담당자 검토일
            draftModifiedUploadDate: {
                date: data.draft_modified_upload_at
            }, // 초안 수정본 전달일
            draftFinalDate: {
                date: data.draft_final_upload_at,
                inner_date: data.draft_final_deadline,
            }, // 초안 최종본 접수일
            claimCount: {independence: data.independent_item || 0, dependent: data.dependent_item || 0}, // 독립항/종속항
            ListOa: [], // OA 변수 리스트
            translateDraftReceivingDate: {
                date: data.draft_translation_upload_at,
                inner_date: data.draft_translation_deadline,
            }, // 번역문 초안 접수일
            translateDraftInventorsDate: {
                date: data.draft_translation_researcher_confirm_at,
                inner_date: data.draft_translation_researcher_deadline
            }, // 번역문 초안 발명자 검토일
            translateDraftManagerDate: {
                date: data.draft_translation_manager_confirm_at,
                inner_date: data.draft_translation_manager_deadline,
            }, // 번역문 초안 담당자 검토일
            translateDraftFinalDate: {
                date: data.draft_translation_final_upload_at,
                inner_date: data.draft_translation_final_deadline,
            }, // 번역문 초안 최종본 접수일
        };
        // json 구조 변경
        if (data.oa) {
            let ListOaTemp = [];
            data.oa.forEach(item => {
                let oaJson = {
                    idx: item.idx,
                    published_at: item.published_at, // oa 발행일
                    deadline: item.deadline, // oa기한
                    extension_cnt: item.extension_cnt, // oa연장횟수
                    opinion_deadline: item.opinion_deadline, // 대리인 대응안 기한
                    opinion_upload_at: item.opinion_upload_at,
                    opinion_researcher: {
                        date: item.opinion_researcher_confirm_at, // 대응안 발명자 검토 일
                        inner_date: item.opinion_researcher_confirm_deadline, // 대응안 발명자 검토 기한
                    },
                    opinion_manager: {
                        date: item.opinion_manager_confirm_at, // 대응안 담당자 검토 일
                        inner_date: item.opinion_manager_confirm_deadline // 대응안 담당자 검토 기한
                    },
                    opinion_modified_upload_at: item.opinion_modified_upload_at, // 대응안 수정본 업로드 일
                    opinion_final_upload_at: item.opinion_final_upload_at, // 대응안 최종본 업로드 일
                    draft_order_at: item.draft_order_at, // oa초안 작성 지시일
                    draft_upload_at: item.draft_upload_at, // oa초안 업로드 일
                    draft_modified_upload_at: item.draft_modified_upload_at, // oa초안 수정본 업로드 일
                    final_deadline: item.final_deadline, // oa초안 최종본 기한
                    final_upload_at: item.final_upload_at, // oa초안 최종본 업로드 일
                    submission_order_at: item.submission_order_at, // oa 제출 지시일
                    oa_detail: item.oa_detail,
                    oa_content_file_cnt: item.oa_content_file_cnt,
                    oa_draft_file_cnt: item.oa_draft_file_cnt,
                    oa_final_draft_file_cnt: item.oa_final_draft_file_cnt,
                    oa_final_opinion_file_cnt: item.oa_final_opinion_file_cnt,
                    oa_modified_draft_file_cnt: item.oa_modified_draft_file_cnt,
                    oa_modified_opinion_file_cnt: item.oa_modified_opinion_file_cnt,
                    oa_opinion_file_cnt: item.oa_opinion_file_cnt,
                    oa_opinion_reply_manager_file_cnt: item.oa_opinion_reply_manager_file_cnt,
                    oa_opinion_reply_researcher_file_cnt: item.oa_opinion_reply_researcher_file_cnt,
                };
                ListOaTemp.push(oaJson);
            });
            initialState.ListOa = ListOaTemp;
        }

        // json 구조 변경
        if (data.ep) {
            let ListEpTemp = [];
            data.ep.forEach(item => {
                let epJson = {
                    ...item,
                    draft_translation: {
                        date: item.draft_translation_upload_at,
                        inner_date: item.draft_translation_deadline
                    },
                    draft_translation_researcher: {
                        date: item.draft_translation_researcher_confirm_at,
                        inner_date: item.draft_translation_researcher_deadline,
                    },
                    draft_translation_manager: {
                        date: item.draft_translation_manager_confirm_at,
                        inner_date: item.draft_translation_manager_deadline
                    },
                    draft_translation_final: {
                        date: item.draft_translation_final_upload_at,
                        inner_date: item.draft_translation_final_deadline
                    }
                };
                ListEpTemp.push(epJson);
            });
            initialState.ep = ListEpTemp;
        }

        if (data.merge.length) {
            initialState.mergeTag =
                <div className="field">
                    {
                        data.merge.map((item, index) => {
                            return (
                                <div key={index} className="rows">
                                    <NormalRow title={`우선권 ${index + 1}`} titleClassName="color_blue" content={item.apply_number}/>
                                    <NormalRow title='출원번호' content={item.apply_number}/>
                                    <div className="row">
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>;
        }
        initialState.inventorListTag =
            initialState.inventor.map((item, index) => {
                if (index === 0) return;
                    return <InventorRow index={index} editing={patentInfo.editing} inventor={item} inventorSelectTag={inventorSelectTag} onClickEvent={onClickInventorAdd}/>;
             });
        initialState.setPatent = (obj) => setPatentInfo(obj);
        initialState.setPatentApprovalApplyPopupShow = (bool) => setPatentApprovalApplyPopupShow(bool);
        initialState.func = {saveProcess};
        setPatentInfo(initialState);
        setHistoryPatentInfo(initialState);
    };

    const updateFileCntData = (data) => {
        let initialState = {
            ...patentInfo,
            apply_file_cnt: data.apply_file_cnt,
            draft_file_cnt: data.draft_file_cnt,
            draft_reply_manager_file_cnt: data.draft_reply_manager_file_cnt,
            draft_reply_researcher_file_cnt: data.draft_reply_researcher_file_cnt,
            final_draft_file_cnt: data.final_draft_file_cnt,
            ids_file_cnt: data.ids_file_cnt,
            international_file_cnt: data.international_file_cnt,
            invention_file_cnt: data.invention_file_cnt,
            modified_draft_file_cnt: data.modified_draft_file_cnt,
            register_file_cnt: data.register_file_cnt,
            survey_file_cnt: data.survey_file_cnt,
            translation_draft_file_cnt: data.translation_draft_file_cnt,
            translation_draft_reply_manager_file_cnt: data.translation_draft_reply_manager_file_cnt,
            translation_draft_reply_researcher_file_cnt: data.translation_draft_reply_researcher_file_cnt,
            translation_final_draft_file_cnt: data.translation_final_draft_file_cnt,
            translation_modified_draft_file_cnt: data.translation_modified_draft_file_cnt
        };

        // json 구조 변경
        if (patentInfo.ListOa) {
            let ListOaTemp = [];
            patentInfo.ListOa.forEach((item, idx) => {
                let oaJson = {
                    ...item,
                    opinion_researcher: {
                        date: data.oa[idx].opinion_researcher_confirm_at, // 대응안 발명자 검토 일
                        inner_date: data.oa[idx].opinion_researcher_confirm_deadline, // 대응안 발명자 검토 기한
                    },
                    opinion_manager: {
                        date: data.oa[idx].opinion_manager_confirm_at, // 대응안 담당자 검토 일
                        inner_date: data.oa[idx].opinion_manager_confirm_deadline // 대응안 담당자 검토 기한
                    },
                    opinion_final_upload_at: data.oa[idx].opinion_final_upload_at,
                    draft_modified_upload_at: data.oa[idx].draft_modified_upload_at,
                    final_upload_at: data.oa[idx].final_upload_at,
                    oa_content_file_cnt: data.oa[idx].oa_content_file_cnt,
                    oa_draft_file_cnt: data.oa[idx].oa_draft_file_cnt,
                    oa_final_draft_file_cnt: data.oa[idx].oa_final_draft_file_cnt,
                    oa_final_opinion_file_cnt: data.oa[idx].oa_final_opinion_file_cnt,
                    oa_modified_draft_file_cnt: data.oa[idx].oa_modified_draft_file_cnt,
                    oa_modified_opinion_file_cnt: data.oa[idx].oa_modified_opinion_file_cnt,
                    oa_opinion_file_cnt: data.oa[idx].oa_opinion_file_cnt,
                    oa_opinion_reply_manager_file_cnt: data.oa[idx].oa_opinion_reply_manager_file_cnt,
                    oa_opinion_reply_researcher_file_cnt: data.oa[idx].oa_opinion_reply_researcher_file_cnt,
                };
                ListOaTemp.push(oaJson);
            });
            initialState.ListOa = ListOaTemp;
        }

        // json 구조 변경
        if (patentInfo.ep) {
            let ListEpTemp = [];
            patentInfo.ep.forEach((item, idx) => {
                let epJson = {
                    ...item,
                    register_file_cnt: data.ep[idx].register_file_cnt,
                    translation_draft_file_cnt: data.ep[idx].translation_draft_file_cnt,
                    translation_draft_reply_manager_file_cnt: data.ep[idx].translation_draft_reply_manager_file_cnt,
                    translation_draft_reply_researcher_file_cnt: data.ep[idx].translation_draft_reply_researcher_file_cnt,
                    translation_final_draft_file_cnt: data.ep[idx].translation_final_draft_file_cnt,
                    translation_modified_draft_file_cnt: data.ep[idx].translation_modified_draft_file_cnt
                };
                ListEpTemp.push(epJson);
            });
            initialState.ep = ListEpTemp;
        }

        if (patentInfo.merge.length) {
            initialState.mergeTag =
                <div className="field">
                    {
                        patentInfo.merge.map((item, index) => {
                            return (
                                <div key={index} className="rows">
                                    <NormalRow title={`우선권 ${index + 1}`} titleClassName="color_blue" content={item.apply_number}/>
                                    <NormalRow title='출원번호' content={item.apply_number}/>
                                    <div className="row">
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>;
        }

        initialState.inventorListTag =
            initialState.inventor.map((item, index) => {
                if (index === 0) return;
                return <InventorRow index={index} editing={patentInfo.editing} inventor={item} inventorSelectTag={inventorSelectTag} onClickEvent={onClickInventorAdd}/>;
            });
        initialState.setPatent = (obj) => setPatentInfo(obj);
        initialState.func = {saveProcess};
        setPatentInfo(initialState);
        setHistoryPatentInfo(initialState);
    };

    const onClickEditingStart = () => {
        setPatentInfo({...patentInfo, editing: true, editingClass: ""});
    };

    const onClickEditingCancel = async () => {
        let copy = _.cloneDeep(historyPatentInfo);
        copy.editing = false;
        setPatentInfo(copy);
        setInventionStateMenu(false);
    };

    const onClickEditingEnd = async () => {
        savePatent();
        setInventionStateMenu(false);
        setPatentInfo({...patentInfo, editing: false, editingClass: "not_editing"});
    };

    const onClickPatentAdd = () => {
        let formData = new FormData();

        formData.append("state", patentInfo.state);
        formData.append("invention_title", patentInfo.inventionName);
        formData.append("joint_apply", JSON.stringify(inventionInfo.applicantors));

        if (inventionInfo.inventors.filter(item => item.name).length) {
            formData.append("members", JSON.stringify(inventionInfo.inventors));
        }
        formData.append("open_list", JSON.stringify(inventionInfo.inventionPublish));
        formData.append("product_list", JSON.stringify(patentInfo.product.map(item => {return {...item, state: Number(item.state)}}).filter(item => item.idx != 0))); // 미입력
        formData.append("survey_req", patentInfo.survey_req);
        formData.append("draft_deadline", patentInfo.draftReceivingDate.inner_date);
        formData.append("draft_researcher_confirm_deadline", patentInfo.draftInventorsDate.inner_date);
        formData.append("draft_manager_confirm_deadline", patentInfo.draftManagerDate.inner_date);
        formData.append("draft_final_deadline", patentInfo.draftFinalDate.inner_date);
        formData.append("apply_deadline", patentInfo.apply_order_at);
        formData.append("apply_number", patentInfo.apply_number);
        formData.append("apply_at", patentInfo.apply_at);
        formData.append("screen_req", patentInfo.screen_req);
        formData.append("screen_req_at", patentInfo.screen_req_at);
        formData.append("priority_screen", patentInfo.priority_screen);
        formData.append("screen_deadline", patentInfo.screen_deadline);
        formData.append("priority_at", patentInfo.priority_at);
        formData.append("priority_claim_deadline_domestic", patentInfo.priority_claim_deadline_domestic);
        formData.append("priority_claim_deadline_foreign", patentInfo.priority_claim_deadline_foreign);
        formData.append("pct_apply_deadline", patentInfo.pct_apply_deadline);
        formData.append("register_decision_at", patentInfo.register_decision_at);
        formData.append("register_at", patentInfo.register_at);
        formData.append("register_number", patentInfo.register_number);
        formData.append("register_deadline", patentInfo.register_deadline);
        formData.append("annual_payment_deadline", patentInfo.annual_payment_deadline);
        formData.append("dependent_item", patentInfo.claimCount.dependent);
        formData.append("independent_item", patentInfo.claimCount.independence);
        formData.append("invention_name", patentInfo.invention_name);
        formData.append("invention_name_en", patentInfo.invention_name_en);
        formData.append("division_deadline", patentInfo.division_deadline);
        formData.append("division_apply_at", patentInfo.divideApplication.date);
        formData.append("division", patentInfo.divideApplication.value);
        formData.append("payment_year", patentInfo.payment_year);
        formData.append("expiration_at", patentInfo.expiration_at);
        formData.append("manage_number", patentInfo.manage_number);
        formData.append("agent_company_idx", patentInfo.agent_company_idx);
        formData.append("manager_idx", patentInfo.manager_idx);
        formData.append("apply_detail", Number(patentInfo.apply_detail));

        for (let i = 0; i < patentInfo.fileList.draft.length; i++) {
            formData.append("draft", patentInfo.fileList.draft[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.draft_researcher.length; i++) {
            formData.append("draft_researcher", patentInfo.fileList.draft_researcher[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.draft_manager.length; i++) {
            formData.append("draft_manager", patentInfo.fileList.draft_manager[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.draft_modified.length; i++) {
            formData.append("draft_modified", patentInfo.fileList.draft_modified[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.draft_final.length; i++) {
            formData.append("draft_final", patentInfo.fileList.draft_final[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.patent_apply.length; i++) {
            formData.append("patent_apply", patentInfo.fileList.patent_apply[i].file);
        }

        for (let i = 0; i < patentInfo.fileList.patent_register.length; i++) {
            formData.append("patent_register", patentInfo.fileList.patent_register[i].file);
        }

        for (let i = 0; i < inventionInfo.inventionUploadIFileViewList.length; i++) {
            formData.append("invention_file", inventionInfo.inventionUploadIFileViewList[i].file);
        }
        for (let i = 0; i < inventionInfo.descriptionUploadIFileViewList.length; i++) {
            formData.append("invention_description", inventionInfo.descriptionUploadIFileViewList[i].file)
        }

        for (let i = 0; i < inventionInfo.inventionPublish.length; i++) {
            for (let j = 0; j < inventionInfo.inventionPublish[i].fileList.length; j++) {
                formData.append("open" + i, inventionInfo.inventionPublish[i].fileList[j].file);
            }
        }

        let oa_list = [];
        for (let i = 0; i < patentInfo.ListOa.length; i++) {
            let oa = {
                published_at: patentInfo.ListOa[i].published_at,
                deadline: patentInfo.ListOa[i].deadline,
                extension_cnt: patentInfo.ListOa[i].extension_cnt,
                opinion_deadline: patentInfo.ListOa[i].opinion_deadline,
                opinion_researcher_confirm_deadline: patentInfo.ListOa[i].opinion_researcher.inner_date,
                opinion_manager_confirm_deadline: patentInfo.ListOa[i].opinion_manager.inner_date,
                draft_order_at: patentInfo.ListOa[i].draft_order_at,
                final_deadline: patentInfo.ListOa[i].final_deadline,
                submission_order_at: patentInfo.ListOa[i].submission_order_at,
                draft_order: patentInfo.ListOa[i].draft_order_at,
                oa_detail: Number(patentInfo.ListOa[i].oa_detail)
            };
            oa_list.push(oa);
        }
        formData.append("oa", JSON.stringify(oa_list));

        for (let i = 0; i < patentInfo.fileList.oa.length; i++) {
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_content.length; j++) {
                formData.append("oa_content" + j, patentInfo.fileList.oa[i].oa_content[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_opinion.length; j++) {
                formData.append("oa_opinion" + j, patentInfo.fileList.oa[i].oa_opinion[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_opinion_researcher.length; j++) {
                formData.append("oa_opinion_researcher" + j, patentInfo.fileList.oa[i].oa_opinion_researcher[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_opinion_manager.length; j++) {
                formData.append("oa_opinion_manager" + j, patentInfo.fileList.oa[i].oa_opinion_manager[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_opinion_modified.length; j++) {
                formData.append("oa_opinion_modified" + j, patentInfo.fileList.oa[i].oa_opinion_modified[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_opinion_final.length; j++) {
                formData.append("oa_opinion_final" + j, patentInfo.fileList.oa[i].oa_opinion_final[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_draft.length; j++) {
                formData.append("oa_draft" + j, patentInfo.fileList.oa[i].oa_draft[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_draft_modified.length; j++) {
                formData.append("oa_draft_modified" + j, patentInfo.fileList.oa[i].oa_draft_modified[j].file);
            }
            for(let j = 0; j < patentInfo.fileList.oa[i].oa_draft_final.length; j++) {
                formData.append("oa_draft_final" + j, patentInfo.fileList.oa[i].oa_draft_final[j].file);
            }
        }

        API.post('/manager/patent/insert', formData).then(res => {
            setEditng(false);
            history.replace(`/patent/detail/${res.data.patent_idx}`);
        });
    };

    const getTypeToFileSelectUrl = (type, oa_idx, ep_idx) => {
        let url = '';
        let oaIdx = oa_idx;

        switch (type) {
            case typeName.invent:
                url = `/manager/invention/${patentInfo.invention_idx}/file`;
                break;
            case typeName.agent_invent:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/invention`;
                break;
            case typeName.draft:
                url = `/manager/patent/${patent_idx}/draft`;
                break;
            case typeName.agent_draft:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft`;
                break;
            case typeName.draft_reply:
                url = `/manager/patent/${patent_idx}/draft/reply`;
                break;
            case typeName.oa_content:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/content`;
                break;
            case typeName.apply:
                url = `/manager/patent/${patent_idx}/apply`;
                break;
            case typeName.agent_apply:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/apply`;
                break;
            case typeName.register:
                url = `/manager/patent/${patent_idx}/regist`;
                break;
            case typeName.agent_register:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/regist`;
                break;
            case typeName.agent_oa_content:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/content`;
                break;
            case typeName.oa_draft:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/draft`;
                break;
            case typeName.agent_oa_draft:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/draft`;
                break;
            case typeName.oa_reply:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion/reply`;
                break;
            case typeName.oa_opinion:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion`;
                break;
            case typeName.agent_oa_opinion:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/opinion`;
                break;
            case typeName.survey:
                url = `/manager/patent/${patent_idx}/survey`;
                break;
            case typeName.agent_survey:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/survey`;
                break;
            case typeName.draft_translation:
                url = `/manager/patent/${patent_idx}/draft/translation`;
                break;
            case typeName.draft_translation_reply:
                url = `/manager/patent/${patent_idx}/draft/translation/reply`;
                break;
            case typeName.agent_draft_translation:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft/translation`;
                break;
            case typeName.ids:
                url = `/manager/patent/${patent_idx}/ids`;
                break;
            case typeName.agent_ids:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ids`;
                break;
            case typeName.report:
                url = `/manager/patent/${patent_idx}/report`;
                break;
            case typeName.agent_report:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/report`;
                break;
            case typeName.ep_register:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/regist`;
                break;
            case typeName.agent_ep_register:
                url = `/agent/company/${manage_company_idx}/patent/${ep_idx}/regist`;
                break;
            case typeName.ep_draft_translation:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/draft`;
                break;
            case typeName.agent_ep_draft_translation:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${ep_idx}/draft`;
                break;
            case typeName.ep_draft_translation_reply:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/draft/reply`;
                break;
            case typeName.agent_ep_draft_translation_reply:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${ep_idx}/draft/reply`;
                break;
        }
        return url;
    };

    const setFileList = (type, payload, oa_idx, callback, ep_idx) => {
        let url = getTypeToFileSelectUrl(type, oa_idx, ep_idx);
        setPopupProp({type: type, params: payload || {}, oa_idx: oa_idx, ep_idx: ep_idx});

        API.get(url, {params: payload}).then(res => {
            setFileViewList(res.data);
            callback(true);
        });
    };

    const onClickUpPopShow = (type, payload, oa_idx, ep_idx) => {
        let idx =  patentInfo.ListOa[oa_idx] && patentInfo.ListOa[oa_idx].idx;
        setFileList(type, payload, idx, setUpPopupShow, ep_idx);
    };

    const onClickUpload = () => {
        let url = getTypeToFileSelectUrl(popupProp.type, popupProp.oa_idx, popupProp.ep_idx);
        let formData = new FormData();

        if (popupProp.params) {
            formData.append('modified', popupProp.params.modified);
            formData.append('type', popupProp.params.type);
            formData.append('description', popupProp.params.description);
        }

        uploadFileViewList.forEach(item => formData.append("file", item.file));

        API.post(url, formData).then(() => {
            alert('업로드되었습니다');
            setUpPopupShow(false);
            setUploadFileViewList([]);
            updateFileCntSetPatent();
        });
    };

    const onClickDownload = (idx) => {
        let url = '';
        let oaIdx = popupProp.oa_idx;
        let ep_idx = popupProp.ep_idx;
        switch (popupProp.type) {
            case typeName.invent:
                url = `/manager/invention/${patentInfo.invention_idx}/file/${idx}/download`;
                break;
            case typeName.agent_invent:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/invention/${idx}/download`;
                break;
            case typeName.draft:
                url = `/manager/patent/${patent_idx}/draft/${idx}/download`;
                break;
            case typeName.draft_reply:
                url = `/manager/patent/${patent_idx}/draft/reply/${idx}/download`;
                break;
            case typeName.oa_content:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/content/${idx}/download`;
                break;
            case typeName.oa_draft:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/draft/${idx}/download`;
                break;
            case typeName.oa_reply:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion/reply/${idx}/download`;
                break;
            case typeName.survey:
                url = `/manager/patent/${patent_idx}/survey/${idx}/download`;
                break;
            case typeName.apply:
                url = `/manager/patent/${patent_idx}/apply/${idx}/download`;
                break;
            case typeName.agent_apply:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/apply/${idx}/download`;
                break;
            case typeName.register:
                url = `/manager/patent/${patent_idx}/regist/${idx}/download`;
                break;
            case typeName.agent_register:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/regist/${idx}/download`;
                break;
            case typeName.agent_oa_content:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/content/${idx}/download`;
                break;
            case typeName.agent_draft:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft/${idx}/download`;
                break;
            case typeName.agent_oa_draft:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/draft/${idx}/download`;
                break;
            case typeName.oa_opinion:
                url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion/${idx}/download`;
                break;
            case typeName.agent_oa_opinion:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/opinion/${idx}/download`;
                break;
            case typeName.agent_survey:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/survey/${idx}/download`;
                break;
            case typeName.draft_translation:
                url = `/manager/patent/${patent_idx}/draft/translation/${idx}/download`;
                break;
            case typeName.agent_draft_translation:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft/translation/${idx}/download`;
                break;
            case typeName.draft_translation_reply:
                url = `/manager/patent/${patent_idx}/draft/translation/reply/${idx}/download`;
                break;
            case typeName.ids:
                url = `/manager/patent/${patent_idx}/ids/${idx}/download`;
                break;
            case typeName.agent_ids:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ids/${idx}/download`;
                break;
            case typeName.report:
                url = `/manager/patent/${patent_idx}/report/${idx}/download`;
                break;
            case typeName.agent_report:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/report/${idx}/download`;
                break;
            case typeName.ep_register:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/regist/${idx}/download`;
                break;
            case typeName.agent_ep_register:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/regist/${idx}/download`;
                break;
            case typeName.ep_draft_translation:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/draft/${idx}/download`;
                break;
            case typeName.agent_ep_draft_translation:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${ep_idx}/draft/${idx}/download`;
                break;
            case typeName.ep_draft_translation_reply:
                url = `/manager/patent/${patent_idx}/ep/${ep_idx}/draft/reply/${idx}/download`;
                break;
            case typeName.agent_ep_draft_translation_reply:
                url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${ep_idx}/draft/reply/${idx}/download`;
                break;
        }
        window.$Global.download(url);
    };

    const onClickDeleteFileInDownload = (idx) => {
        if (idx) {
            let url = '';
            let oaIdx = popupProp.oa_idx;
            let epIdx = popupProp.ep_idx;
            switch (popupProp.type) {
                case typeName.invent:
                    url = `/manager/invention/${patentInfo.invention_idx}/file/${idx}`;
                    break;
                case typeName.draft:
                    url = `/manager/patent/${patent_idx}/draft/${idx}`;
                    break;
                case typeName.draft_reply:
                    url = `/manager/patent/${patent_idx}/draft/reply/${idx}`;
                    break;
                case typeName.oa_content:
                    url = `/manager/patent/${patent_idx}/oa/${oaIdx}/content/${idx}`;
                    break;
                case typeName.oa_draft:
                    url = `/manager/patent/${patent_idx}/oa/${oaIdx}/draft/${idx}`;
                    break;
                case typeName.apply:
                    url = `/manager/patent/${patent_idx}/apply/${idx}`;
                    break;
                case typeName.agent_apply:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/apply/${idx}`;
                    break;
                case typeName.register:
                    url = `/manager/patent/${patent_idx}/regist/${idx}`;
                    break;
                case typeName.agent_register:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/regist/${idx}`;
                    break;
                case typeName.oa_reply:
                    url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion/reply/${idx}`;
                    break;
                case typeName.oa_opinion: // 대응안
                    url = `/manager/patent/${patent_idx}/oa/${oaIdx}/opinion/${idx}`;
                    break;
                case typeName.survey:
                    url = `/manager/patent/${patent_idx}/survey/${idx}`;
                    break;
                case typeName.agent_oa_opinion:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/opinion/${idx}`;
                    break;
                case typeName.agent_survey:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/survey/${idx}`;
                    break;
                case typeName.agent_draft:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft/${idx}`;
                    break;
                case typeName.agent_oa_draft:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/draft/${idx}`;
                    break;
                case typeName.agent_oa_content:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oaIdx}/content/${idx}`;
                    break;
                case typeName.draft_translation:
                    url = `/manager/patent/${patent_idx}/draft/translation/${idx}`;
                    break;
                case typeName.agent_draft_translation:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/draft/translation/${idx}`;
                    break;
                case typeName.draft_translation_reply:
                    url = `/manager/patent/${patent_idx}/draft/translation/reply/${idx}`;
                    break;
                case typeName.ids:
                    url = `/manager/patent/${patent_idx}/ids/${idx}`;
                    break;
                case typeName.agent_ids:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ids/${idx}`;
                    break;
                case typeName.report:
                    url = `/manager/patent/${patent_idx}/report/${idx}`;
                    break;
                case typeName.agent_report:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/report/${idx}`;
                    break;
                case typeName.ep_register:
                    url = `/manager/patent/${patent_idx}/ep/${epIdx}/regist/${idx}`;
                    break;
                case typeName.agent_ep_register:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/regist/${idx}`;
                    break;
                case typeName.ep_draft_translation:
                    url = `/manager/patent/${patent_idx}/ep/${epIdx}/draft/${idx}`;
                    break;
                case typeName.agent_ep_draft_translation:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${epIdx}/draft/${idx}`;
                    break;
                case typeName.ep_draft_translation_reply:
                    url = `/manager/patent/${patent_idx}/ep/${epIdx}/draft/reply/${idx}`;
                    break;
                case typeName.agent_ep_draft_translation_reply:
                    url = `/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${epIdx}/draft/reply/${idx}`;
                    break;
                default:
                    alert('권한이 없습니다');
                    return;
            }
            API.delete(url, popupProp.params).then(() => {
                alert('삭제되었습니다');
                setFileViewList(fileViewList.filter(file => {
                    return file.idx !== idx;
                }));
                updateFileCntSetPatent();
            });
        }
    };

    const onClickDownPopShow = (type, payload, oa_idx, ep_idx) => {
        let idx =  patentInfo.ListOa[oa_idx] && patentInfo.ListOa[oa_idx].idx;
        setFileList(type, payload, idx, setDownPopupShow, ep_idx);
    };

    const onChangeDatePicker = (key, date) => {
        setPatentInfo({...patentInfo, [key]: new Date(date).getTime()});
    };

    const onChangeOaDetail = (idx, value) => {
        let copy = _.cloneDeep(patentInfo);
        copy.ListOa[idx].oa_detail = value;
        setPatentInfo(copy);
    };

    const onChangeDatePrckerOA = (idx, key, date) => {
        let copy = _.cloneDeep(patentInfo);
        copy.ListOa[idx][key] = new Date(date).getTime();
        setPatentInfo(copy);
    };

    const onChangeDatePickerInnerDate = async (key, date) => {
        let copy = _.cloneDeep(patentInfo);
        copy[key].inner_date = new Date(date).getTime();
        setPatentInfo(copy);
    };

    const onChangeOaInnerDate = (date, index, key) => {
        let copy = _.cloneDeep(patentInfo);
        copy.ListOa[index][key].inner_date = new Date(date).getTime();
        setPatentInfo(copy);
    };

    const onClickClaim = () => {
        let copy = _.cloneDeep(patentInfo);
        if (!copy.screen_req) {
            copy.screen_req_at = new Date().getTime();
        } else {
            copy.screen_req_at = null;
            copy.priority_screen = false;
        }
        copy.screen_req = !copy.screen_req;
        setPatentInfo(copy);
    };

    const onClickFirstClaim = () => {
        let copy = _.cloneDeep(patentInfo);
        if (!copy.priority_screen) {
            copy.screen_req = true;
            copy.screen_req_at = new Date().getTime();
        }
        copy.priority_screen = !copy.priority_screen;
        setPatentInfo(copy);
    };

    const onClickOaInstruction = (idx, key) => {
        let copy = _.cloneDeep(patentInfo);
        copy.ListOa[idx][key] = new Date().getTime();
        setPatentInfo(copy);
    };

    const onClickCategoryShow = () => {
        setCategoryShow(!categoryShow);
    };

    const onChangeInput = (key, value) => {
        setPatentInfo({...patentInfo, [key]: value});
    };

    const onChangeJointApplyInput = (idx, key, value) => {
        let copy = _.cloneDeep(patentInfo);
        copy.joint_apply[idx][key] = value;
        setPatentInfo(copy);
    };

    const onChangeClaimCount = (key, value) => {
        let copy = _.cloneDeep(patentInfo);
        copy.claimCount[key] = value;
        setPatentInfo(copy);
    };

    const checkAuth = (type) => {
        return window.$Global.checkAuth(type, auth);
    };

    const saveProcess = async (apply_detail, oa, patent) => {
        if (isCompany) {
            if (apply_detail != undefined) {
                await PatentAPI.updatePatent(patent_idx, {...patent, apply_detail: apply_detail});
            }
            if (oa != undefined) {
                await PatentAPI.updateOA(patent_idx, oa.idx, {...patent, oa_detail: oa.oa_detail});
            }
        }
        window.$Global.saveToast();
    };

    const savePatent = async () => {
        try {
            if (isCompany) {
                let payload = {
                    survey_req: Number(patentInfo.survey_req),
                    agent_company_idx: patentInfo.agent_company_idx == "0" ? null : patentInfo.agent_company_idx,
                    dependent_item: patentInfo.claimCount.dependent,
                    independent_item: patentInfo.claimCount.independence,
                    annual_payment_manage_idx: "", // patentInfo.annual_payment_manage_company,
                    invention_name: patentInfo.invention_name,
                    invention_name_en: patentInfo.invention_name_en,
                    annual_payment_deadline: new Date(patentInfo.annual_payment_deadline).getTime() || null, // 연차료 기한
                    pace_order_at: patentInfo.pace_order_at,
                    manage_number: patentInfo.manage_number,
                    state: patentInfo.state,
                    apply_detail: Number(patentInfo.apply_detail),
                    manager_idx: patentInfo.manager_idx,
                    open_number: null,
                    open_at: null,
                    members: patentInfo.inventor.filter(item => item.name.length > 0),
                    product_list: patentInfo.product.map(item => {
                        return {...item, state: Number(item.state)}
                    }).filter(item => item.idx != 0),
                    apply_number: patentInfo.apply_number,
                    register_number: patentInfo.register_number,
                    expiration_at: new Date(patentInfo.expiration_at).getTime() || null, // 존속기간 만료일
                    register_at: new Date(patentInfo.register_at).getTime() || null, // 등록일
                    register_deadline: new Date(patentInfo.register_deadline).getTime() || null, // 등록기한
                    register_decision_at: new Date(patentInfo.register_decision_at).getTime() || null, // 등록 결정일
                    apply_at: new Date(patentInfo.apply_at).getTime() || null, // 출원일
                    translation: Number(patentInfo.translation),
                    local_agent_company_idx: patentInfo.local_agent_company_idx === "0" ? null : patentInfo.local_agent_company_idx, // 현지 대리인
                    draft_req: patentInfo.draft_order_at ? 1 : 0,
                    apply_req: patentInfo.apply_order_at ? 1 : 0,
                    screen_req: Number(patentInfo.screen_req),
                    screen_req_at: Number(patentInfo.screen_req_at),
                    priority_screen_req: Number(patentInfo.priority_screen),
                    division: patentInfo.divideApplication.value ? 1 : 0,
                    translation_draft_req: patentInfo.draft_translation_order_at ? 1 : 0,
                    draft_deadline: patentInfo.draftReceivingDate.inner_date,
                    draft_researcher_confirm_deadline: patentInfo.draftInventorsDate.inner_date,
                    draft_manager_confirm_deadline: patentInfo.draftManagerDate.inner_date,
                    draft_final_deadline: patentInfo.draftFinalDate.inner_date,
                    draft_translation_deadline: patentInfo.translateDraftReceivingDate.inner_date,
                    draft_translation_researcher_deadline: patentInfo.translateDraftInventorsDate.inner_date,
                    draft_translation_manager_deadline: patentInfo.translateDraftManagerDate.inner_date,
                    draft_translation_final_deadline: patentInfo.translateDraftFinalDate.inner_date,
                    joint_apply: patentInfo.joint_apply.filter(item => item.name || item.share)
                };
                await PatentAPI.updatePatent(patent_idx, payload);

                // 번역문 초안 접수 내부기한 설정
                for (let i = 0; i < patentInfo.ep.length; i++) {
                    let ep = patentInfo.ep[i];
                    let payload = {
                        draft_translation_deadline: ep.draft_translation.inner_date,
                        draft_translation_researcher_deadline: ep.draft_translation_researcher.inner_date,
                        draft_translation_manager_deadline: ep.draft_translation_manager.inner_date,
                        draft_translation_final_deadline: ep.draft_translation_final.inner_date
                    };

                    let keys = Object.keys(payload);
                    for (let j = 0; j < keys.length; j++) {
                        if (payload[keys[j]]) {
                            await PatentAPI.updateDeadlineEP(patent_idx, ep.idx, payload);
                            break;
                        }
                    }
                }

                for (let j in patentInfo.ListOa) {
                    let oa = patentInfo.ListOa[j];
                    payload = {
                        published_at: oa.published_at, // oa 발행일
                        deadline : oa.deadline, // oa 기한
                        extension_cnt : oa.extension_cnt, // oa 연장횟수
                        oa_detail : oa.oa_detail,
                        opinion_upload_at: oa.opinion_upload_at,
                        opinion_researcher_confirm_deadline: oa.opinion_researcher_confirm_deadline,
                        opinion_manager_confirm_deadline: oa.opinion_manager.inner_date,
                        draft_order: oa.draft_order_at ? 1 : 0,
                        submission_order: oa.submission_order_at ? 1 : 0
                    };
                    // oa 정보 수정하기
                    await PatentAPI.updateOA(patent_idx, oa.idx, payload);
                }

                if (patentInfo.nation_string == "EP") {
                    for (let i = 0; i < patentInfo.ep.length; i++) {
                        let item = patentInfo.ep[i];

                        // 지정국 번역문 초안 작성 지시
                        if (item.draft_translation_order_at != null) {
                            await API.post(`/manager/patent/${patent_idx}/ep/${item.idx}/order`, {type: 0});
                        }

                        payload = {
                            dependent_item: item.dependent_item,
                            independent_item: item.independent_item,
                            register_number: item.register_number,
                            register_at: new Date(item.register_at).getTime() || null,
                            translation_deadline: new Date(item.translation_deadline).getTime() || null
                        };
                        await API.patch(`/manager/patent/${patent_idx}/ep/${item.idx}`, payload);
                    }
                }
            } else {
                let payload = {
                    agent_manage_number: patentInfo.agent_manage_number, // 대리인 관리번호
                    dependent_item: patentInfo.claimCount.dependent, // 종속항
                    independent_item: patentInfo.claimCount.independence, // 독립항
                    annual_payment_manage_idx: "", // 연차관리회사
                    invention_name: patentInfo.invention_name,  // 발명명칭
                    invention_name_en: patentInfo.invention_name_en, // 발명명칭
                    // annual_payment_deadline: new Date(patentInfo.annual_payment_deadline).getTime() || null, // 연차료 기한
                    pace_order_at : patentInfo.pace_order_at,
                    state : patentInfo.state,
                    agent_idx: patentInfo.agent_idx, // 대리인 담당자 idx
                    apply_number:patentInfo.apply_number, // 출원번호
                    register_number : patentInfo.register_number,
                    register_at : patentInfo.register_at,
                    register_deadline: new Date(patentInfo.register_deadline).getTime() || null, // 등록기한
                    register_decision_at : new Date(patentInfo.register_decision_at).getTime() || null, // 등록 결정일
                    apply_at: patentInfo.apply_at, // 출원일
                    // payment_deadline: new Date(patentInfo.payment_deadline).getTime() || null, // 관납료 납부 기한
                    // division_deadline: new Date(patentInfo.division_deadline).getTime() || null, // 분할 출원 기간
                    expiration_at: new Date(patentInfo.expiration_at).getTime() || null, // 존속기간 만료일
                    translation: patentInfo.translation
                };
                // 특허 정보 수정하기
                await API.patch(`/agent/company/${manage_company_idx}/patent/${patent_idx}`, payload);

                for (let i = 0; i < patentInfo.ListOa.length; i++) {
                    let oa = patentInfo.ListOa[i];
                    payload = {
                        published_at: oa.published_at, // oa 발행일
                        deadline : oa.deadline, // oa 기한
                        extension_cnt : oa.extension_cnt, // oa 연장횟수
                    };
                    // oa 정보 수정하기
                    await API.patch(`/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${oa.idx}`, payload);
                }

                if (patentInfo.nation_string == "EP") {
                    for (let i = 0; i < patentInfo.ep.length; i++) {
                        let item = patentInfo.ep[i];
                        payload = {
                            dependent_item: item.dependent_item,
                            independent_item: item.independent_item,
                            register_number: item.register_number,
                            register_at: new Date(item.register_at).getTime() || null,
                            translation_deadline: new Date(item.translation_deadline).getTime() || null
                        };
                        await API.patch(`/agent/company/${manage_company_idx}/patent/${patent_idx}/ep/${item.idx}`, payload);
                    }
                }
            }
            setHistoryPatentInfo({...patentInfo, editing: false, editingClass: "not_editing"});
            window.$Global.saveToast();

        } catch (err) {
            setPatentInfo(historyPatentInfo);
            return false;
        }
    };

    const epCreate = async (nationList) => {
        if (checkAuth("특허회사")) {
            for (let i = 0; i < nationList.length; i++) {
                await API.post(`/manager/patent/${patentInfo.idx}/ep/create`, {nation: nationList[i].code});
            }
        } else {
            for (let i = 0; i < nationList.length; i++) {
                await API.post(`/agent/company/${manage_company_idx}/patent/${patentInfo.idx}/ep/create`, {nation: nationList[i].code});
            }
        }
        await setPatent();
    };

    const createOa = () => {
        if (isCompany) {
            API.post(`/manager/patent/${patent_idx}/oa/create`).then(() => setPatent());
        } else {
            API.post(`/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/create`).then(() => setPatent());
        }
        setCategoryShow(false);
    };

    const onClickNewOaAdd = () => {
        let oaJson = {
            idx: null,
            published_at: new Date().getTime(), // oa 발행일
            deadline: null, // oa기한
            extension_cnt: 0, // oa연장횟수
            opinion: {
                date: null, // 대리인 대응안 파일 업로드일
                inner_date: null // 대리인 대응안 기한
            },
            opinion_researcher: {
                date: null, // 대응안 발명자 검토 일
                inner_date: null, // 대응안 발명자 검토 기한
            },
            opinion_manager: {
                date: null, // 대응안 담당자 검토 일
                inner_date: null // 대응안 담당자 검토 기한
            },
            opinion_modified_upload_at: null, // 대응안 수정본 업로드 일
            opinion_final_upload_at: null, // 대응안 최종본 업로드 일
            draft_order_at: null, // oa초안 작성 지시일
            draft_upload_at: null, // oa초안 업로드 일
            draft_modified_upload_at: null, // oa초안 수정본 업로드 일
            final_deadline: null, // oa초안 최종본 기한
            final_upload_at: null, // oa초안 최종본 업로드 일
            submission_order_at: null // oa 제출 지시일
        };

        let oa_fileList = {
            oa_content: [], // oa 발행일
            oa_opinion: [], // 대응안
            oa_opinion_researcher: [],
            oa_opinion_manager: [],
            oa_opinion_modified: [],
            oa_opinion_final: [],
            oa_draft: [], // 초안
            oa_draft_modified: [],
            oa_draft_final: []
        };

        let copy = _.cloneDeep(patentInfo);
        copy.ListOa.push(oaJson);
        copy.fileList.oa.push(oa_fileList);
        setPatentInfo(copy);
        setHistoryPatentInfo(copy);
        setCategoryShow(false);
    };

    const onClickInventionStateMenu = () => {
        setInventionStateMenu(!inventionStateMenu);
    };

    const onClickChangeInventionState = (state) => {
        setPatentInfo({...patentInfo, state: state});
        setInventionStateMenu(false);
    };

    const onClickInventorAdd = () => {
        setPatentInfo({...patentInfo, inventor: patentInfo.inventor.concat([{name: "", idx: ""}])});
    };

    const onClickProductAdd = () => {
        setPatentInfo({...patentInfo, product: patentInfo.product.concat([{name: "", idx: "", state: 0}])});
    };

    const onClickJointApplyAdd = () => {
        setPatentInfo({...patentInfo, joint_apply: patentInfo.joint_apply.concat([{name: "", idx: "", state: 0}])});
    };

    const onClickInventorDelete = (index) => {
        let copy = _.cloneDeep(patentInfo);
        copy.inventor.splice(index, 1);
        setPatentInfo(copy);
    };

    const onClickProductDelete = (index) => {
        let copy = _.cloneDeep(patentInfo);
        copy.product.splice(index, 1);
        setPatentInfo(copy);
    };

    const onClickJointApplyDelete = (index) => {
        let copy = _.cloneDeep(patentInfo);
        copy.joint_apply.splice(index, 1);
        setPatentInfo(copy);
    };

    const onClickOaRemove = async (index) => {
        if (patentInfo.ListOa[index].idx) {
            if (isCompany) {
                await API.delete(`/manager/patent/${patent_idx}/oa/${patentInfo.ListOa[index].idx}`);
            } else {
                await API.delete(`/agent/company/${manage_company_idx}/patent/${patent_idx}/oa/${patentInfo.ListOa[index].idx}`);
            }
        }
        let copyArr = patentInfo.ListOa.slice(0);
        copyArr.splice(index, 1);

        let copy = _.cloneDeep(patentInfo);
        copy.ListOa = copyArr;
        setPatentInfo(copy);
        setHistoryPatentInfo(copy);
    };

    const onChangeDatePickerInnerDateEP = async (ep_idx, index, key, date) => {
        let copy = _.cloneDeep(patentInfo);
        if (key == "draft_translation") {
            copy.ep[index].draft_translation.inner_date = new Date(date).getTime();
        } else if (key == "draft_translation_researcher") {
            copy.ep[index].draft_translation_researcher.inner_date = new Date(date).getTime();
        } else if (key == "draft_translation_manager") {
            copy.ep[index].draft_translation_manager.inner_date = new Date(date).getTime();
        } else if (key == "draft_translation_final") {
            copy.ep[index].draft_translation_final.inner_date = new Date(date).getTime();
        }
        setPatentInfo(copy);
    };

    const onClickNationDelete = async (idx) => {
        if (checkAuth("특허회사")) {
            await API.delete(`/manager/patent/${patentInfo.idx}/ep/${idx}`).then(() => {});
        } else {
            await API.delete(`/agent/company/${manage_company_idx}/patent/${patentInfo.idx}/ep/${idx}`).then(() => {});
        }
        let find_idx = patentInfo.ep.findIndex(item => item.idx === idx);
        patentInfo.ep.splice(find_idx, 1);
        setPatentInfo({...patentInfo, ep: patentInfo.ep.concat([])});
    };

    if (patentInfo.ListOa) {
        ListOaTag = patentInfo.ListOa.map((item, index) => {
            let common_func = {
                onClickOaRemove, onChangeOaInnerDate, onChangeDatePrckerOA,
                onClickOaInstruction, onClickDownPopShow, onClickUpPopShow
            };
            if (isCompany) {
                return <FieldOa key={index} index={index} {...common_func}/>;
            } else if (isAgent) {
                return <FieldOaAgent key={index} index={index} item={item} {...common_func}/>;
            }
        });
    }

    if (agentCompanyList.length) {
        agentCompanyListTag.push(agentCompanyList.map(item => <option key={item.company_idx} value={item.company_idx}>{item.name}</option>));
    }

    let common_func = {
        checkAuth,
        onClickDownPopShow, onClickUpPopShow, onClickClaim, onClickFirstClaim,
        onChangeInput, onChangeClaimCount, onChangeDatePicker
    };

    let common_company = {
        onClickInventorAdd, onClickProductAdd, onClickProductDelete, ...common_func
    };

    let props_patent_kr = {
        productSelect, inventorSelectTag, agentCompanyListTag, managerSelectTag, setInventionInfo, setPatentInfo,
        onChangeDatePrckerOA, onChangeOaDetail, ...common_company
    };

    let props_company = {
        productSelect, inventorSelectTag, agentCompanyListTag, managerSelectTag, jointApplyListTag, inventorListTag, ListOaTag,
        onChangeDatePickerInnerDate, ...common_company
    };

    let props_agent = {
        managerSelectTag, inventorListTag, ListOaTag,
        ...common_func
    };

    if (window.$Global.isEmptyObject(patentInfo)) return null;
    if (!isNew && !patentInfo.inventor.length) return null;
    if (isAgent && !manage_company_idx) return null;

    return (
        <PatentContext.Provider value={patentInfo}>
            <div id="Detail" className={`${(isCompany && tooltipState) ? '' : 'no_tip'} ${(isCompany && !isNew) ? 'company' : ''}${isNew ? 'isNew' : ''}`}>
                <div className="header">
                    <h2 className="title">{window.$Global.convertNationCodeToKoreanStr(patentInfo.nation)}{!isNew ? ' 특허 출원 상세 페이지' : ' 특허 출원 등록 페이지'}</h2>
                    <div className="info">
                        <div className="manage_no">출원번호 {patentInfo.apply_number || ''}<i className={`icon_flag_${window.$Global.convertNationCodeToStr(patentInfo.nation)}`}/></div>
                        <div className="apply_no">등록번호 {patentInfo.register_number}</div>
                        {
                            !isNew &&
                            <div className="option">
                                <ul className="link_tab">
                                    {
                                        isCompany &&
                                        <li>
                                            <Link to={`/patent/memo/${patent_idx}`}>
                                                <h2 className="title">특허 메모</h2>
                                                <i className="icon_pencil"/>
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link to={`/patent/relativeFile/${patent_idx}`}>
                                            <h2 className="title">관련파일</h2>
                                            <i className="icon_attached_file"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/patent/family/${patent_idx}`}>
                                            <h2 className="title">Family</h2>
                                            <i className="icon_home"/>
                                        </Link>
                                    </li>
                                    {
                                        isCompany &&
                                        <li>
                                            <Link className={`${patentInfo.register_at ? "" : "no_click"}`} to={`/annual/manage/${patent_idx}?nation=${window.$Global.convertNationCodeToStr(patentInfo.nation)}&manage_number=${patentInfo.manage_number || ""}&register_number=${patentInfo.register_number || ""}&deadline_at=${patentInfo.apply_at ? new Date(patentInfo.apply_at).getFullYear() + 20 : ""}&type=patent`}>
                                                <h2 className="title">연차료 관리</h2>
                                                <i className="icon_patent_payment"/>
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link to={`/patent/etc/${patent_idx}`}>
                                            <h2 className="title">기타 관련 파일</h2>
                                            <i className="icon_attached_file"/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                        {
                            (!isNew && !patentInfo.editing)
                                ? <button className="state">{window.$Global.convertStateToText(patentInfo.state)}</button>
                                : <button className="state" onClick={onClickInventionStateMenu}>
                                    {window.$Global.convertStateToText(patentInfo.state)}
                                    <i className="icon_more"/>
                                 </button>
                        }
                        {
                            inventionStateMenu
                            &&
                            <ul className="custom_scroll">
                                <li>
                                    <button onClick={() => onClickChangeInventionState(0)}>발명신고서 접수</button>
                                    <button onClick={() => onClickChangeInventionState(1)}>선행기술 조사 신청 상태</button>
                                    <button onClick={() => onClickChangeInventionState(2)}>선행기술 조사 완료</button>
                                    <button onClick={() => onClickChangeInventionState(3)}>초안 작성 요청</button>
                                    <button onClick={() => onClickChangeInventionState(4)}>초안 검토 대기</button>
                                    <button onClick={() => onClickChangeInventionState(5)}>초안 수정본 전달</button>
                                    <button onClick={() => onClickChangeInventionState(6)}>초안 최종본 검토 대기</button>
                                    <button onClick={() => onClickChangeInventionState(7)}>출원 지시</button>
                                    <button onClick={() => onClickChangeInventionState(8)}>출원 완료</button>
                                    <button onClick={() => onClickChangeInventionState(9)}>심사중</button>
                                    <button onClick={() => onClickChangeInventionState(10)}>등록 결정 상태 (NOA)</button>
                                    <button onClick={() => onClickChangeInventionState(11)}>분할 출원 상태</button>
                                    <button onClick={() => onClickChangeInventionState(12)}>등록 완료 상태</button>
                                    <button onClick={() => onClickChangeInventionState(13)}>심사 청구 거절 이유(OA)</button>
                                    <button onClick={() => onClickChangeInventionState(14)}>oa 대응안 검토 대기</button>
                                    <button onClick={() => onClickChangeInventionState(15)}>oa 대응안 수정본 업로드</button>
                                    <button onClick={() => onClickChangeInventionState(16)}>oa 대응한 최종본 접수</button>
                                    <button onClick={() => onClickChangeInventionState(17)}>oa 초안 작성 지시</button>
                                    <button onClick={() => onClickChangeInventionState(18)}>oa 초안 접수</button>
                                    <button onClick={() => onClickChangeInventionState(19)}>oa 초안 수정본 전달</button>
                                    <button onClick={() => onClickChangeInventionState(20)}>oa 초안 최종본 접수</button>
                                    <button onClick={() => onClickChangeInventionState(21)}>oa 제출 지시</button>
                                    <button onClick={() => onClickChangeInventionState(22)}>번역문 초안 작성 지시</button>
                                    <button onClick={() => onClickChangeInventionState(23)}>번역문 초안 검토 대기</button>
                                    <button onClick={() => onClickChangeInventionState(24)}>번역문 초안 수정본 전달</button>
                                    <button onClick={() => onClickChangeInventionState(25)}>번역문 최종본 검토 대기</button>
                                    <button onClick={() => onClickChangeInventionState(26)}>우선심사청구 요청</button>
                                    <button onClick={() => onClickChangeInventionState(99)}>종료</button>
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="btns">
                        {
                            !isNew
                            ?
                                (
                                    patentInfo.editing
                                    ?
                                        <>
                                            <button className="btn_save" onClick={onClickEditingEnd}>저장</button>
                                            <button className="btn_cancle" onClick={onClickEditingCancel}>취소</button>
                                        </>
                                    :   <button className="btn_edit" onClick={onClickEditingStart}>수정</button>
                                )
                            : <button className="btn_save" onClick={onClickPatentAdd}>등록</button>
                        }
                        <button className="btn_add" onClick={onClickCategoryShow}>항목 추가</button>
                        {
                            categoryShow &&
                            <ul>
                                <li>
                                    <button className="btn_add_category" onClick={isNew ? onClickNewOaAdd : createOa}>OA</button>
                                </li>
                            </ul>
                        }
                    </div>
                    {
                        isCompany
                        &&
                        <div className="tip">
                            <i className="icon_badge_question"/>
                            <ToggleButtonWithSpan text="도움말 설정" condition={{on: 'ON', off: 'OFF'}} selected={tooltipState} toggleSelected={onClickToolTipState}/>
                        </div>
                    }
                </div>
                {
                    !isNew ? patentInfo.nation_string === "KR" &&
                        (
                            isCompany
                            ? <FormCompanyKr {...props_company}/>
                            : <FormAgentKr {...props_agent}/>
                        )
                        :
                        <InventionContext.Provider value={inventionInfo}>
                            <FormPatentAddKr {...props_patent_kr} onClickOaRemove={onClickOaRemove}/>
                        </InventionContext.Provider>
                }

                {
                    patentInfo.nation_string === "US" &&
                    (
                        isCompany
                        ? <FormCompanyUs {...props_company}/>
                        : <FormAgentUs {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "CN" &&
                    (
                        isCompany
                        ? <FormCompanyCn {...props_company}/>
                        : <FormAgentCn {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "PCT" &&
                    (
                        isCompany
                        ? <FormCompanyPct {...props_company}/>
                        : <FormAgentPct {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "EP" &&
                    (
                        isCompany
                        ? <FormCompanyEp {...props_company} epCreate={epCreate} onClickNationDelete={onClickNationDelete} onChangeDatePickerInnerDateEP={onChangeDatePickerInnerDateEP}/>
                        : <FormAgentEp {...props_agent} epCreate={epCreate} onClickNationDelete={onClickNationDelete}/>
                    )
                }

                {
                    patentInfo.nation_string === "JP" &&
                    (
                        isCompany
                        ? <FormCompanyJp {...props_company}/>
                        : <FormAgentJp {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "SP" &&
                    (
                        isCompany
                            ? <FormCompanySP {...props_company}/>
                            : <FormAgentSP {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "TW" &&
                    (
                        isCompany
                            ? <FormCompanyTW {...props_company}/>
                            : <FormAgentTW {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "RU" &&
                    (
                        isCompany
                            ? <FormCompanyRU {...props_company}/>
                            : <FormAgentRU {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "NZ" &&
                    (
                        isCompany
                            ? <FormCompanyNZ {...props_company}/>
                            : <FormAgentNZ {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "IN" &&
                    (
                        isCompany
                            ? <FormCompanyIN {...props_company}/>
                            : <FormAgentIN {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "VN" &&
                    (
                        isCompany
                            ? <FormCompanyVN {...props_company}/>
                            : <FormAgentVN {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "AU" &&
                    (
                        isCompany
                            ? <FormCompanyAU {...props_company}/>
                            : <FormAgentAU {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "CA" &&
                    (
                        isCompany
                            ? <FormCompanyCA {...props_company}/>
                            : <FormAgentCA {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "BR" &&
                    (
                        isCompany
                            ? <FormCompanyBR {...props_company}/>
                            : <FormAgentBR {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "MO" &&
                    (
                        isCompany
                            ? <FormCompanyMO {...props_company}/>
                            : <FormAgentMo {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "SG" &&
                    (
                        isCompany
                            ? <FormCompanySG {...props_company}/>
                            : <FormAgentSG {...props_agent}/>
                    )
                }
                {
                    patentInfo.nation_string === "MX" &&
                    (
                        isCompany
                            ? <FormCompanyMX {...props_company}/>
                            : <FormAgentMX {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "IL" &&
                    (
                        isCompany
                            ? <FormCompanyIL {...props_company}/>
                            : <FormAgentIL {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "PH" &&
                    (
                        isCompany
                        ? <FormCompanyPH {...props_company}/>
                        : <FormAgentPH {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "HK" &&
                    (
                        isCompany
                        ? <FormCompanyHK {...props_company}/>
                        : <FormAgentHK {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "EA" &&
                    (
                        isCompany
                        ? <FormCompanyEA {...props_company}/>
                        : <FormAgentEA {...props_agent}/>
                    )
                }

                {
                    patentInfo.nation_string === "ZA" &&
                    (
                        isCompany
                        ? <FormCompanyZA {...props_company}/>
                        : <FormAgentZA {...props_agent}/>
                    )
                }

                <InnerAlertPopup show={innerPopupShow} onClickClose={() => setInnerPopupShow(false)}/>
                <DetailPopupDownload show={downPopupShow} setShow={setDownPopupShow} fileViewList={fileViewList} setFileList={setFileViewList} onClickDownload={onClickDownload} onClickDelete={onClickDeleteFileInDownload}/>
                <DetailPopupUpload show={upPopupShow} setShow={setUpPopupShow} uploadFileViewList={uploadFileViewList} setFileList={setUploadFileViewList} onClickUpload={onClickUpload}/>
                <PopupPatentApprovalApply show={patentApprovalApplyPopupShow} patentInfo={patentInfo} patent_idx={patent_idx} memberList={companyMember} approvalList={patentInfo.approval_list} onClickClose={() => setPatentApprovalApplyPopupShow(false)}/>
            </div>
        </PatentContext.Provider>
    );
}

export default Detail;
