import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransaction.scss";
import DocTradeMyTransactionSaleList from './DocTradeMyTransactionSaleList';
import DocTradeMyTransactionPurchaseList from './DocTradeMyTransactionPurchaseList';
import DocTradeMyTransactionSwitchPoint from './DocTradeMyTransactionSwitchPoint';
import DocTradeMyTransactionWithdrawal from './DocTradeMyTransactionWithdrawal';
import DocTradeMyTransactionUsage from './DocTradeMyTransactionUsage';
import qs from "query-string";

function DocTradeMyTransaction({history, location}) {
    const tab = qs.parse(location.search).tab || 0;
    const isSale = tab == 0;
    const isPurchase = tab == 1;
    const isSwitchPoint = tab == 2;
    const isWithdrawal = tab == 3;
    const isPointUsage = tab == 4;

    return (
        <div id="docTradeMyTransaction" >
            <div className="mytransaction">
                <div className="mytransaction_aside">
                    <ul className="mytransaction_aside_list-wrap">
                        <h2 className="mytransaction_aside_list-wrap_title">나의 거래</h2>
                        <li className={`mytransaction_aside_list ${isSale ? 'on' : ''}`} onClick={() => history.push('?tab=0')}>
                            <button className="mytransaction_aside_list_link">나의 판매 목록</button>
                        </li>
                        <li className={`mytransaction_aside_list ${isPurchase ? 'on' : ''}`} onClick={() => history.push('?tab=1')}>
                            <button className="mytransaction_aside_list_link">나의 구매 목록</button>
                        </li>
                        <li className={`mytransaction_aside_list ${isSwitchPoint ? 'on' : ''}`} onClick={() => history.push('?tab=2')}>
                            <button className="mytransaction_aside_list_link">포인트 전환</button>
                        </li>
                        <li className={`mytransaction_aside_list ${isWithdrawal ? 'on' : ''}`} onClick={() => history.push('?tab=3')}>
                            <button className="mytransaction_aside_list_link">포인트 출금 내역</button>
                        </li>
                        <li className={`mytransaction_aside_list ${isPointUsage ? 'on' : ''}`} onClick={() => history.push('?tab=4')}>
                            <button className="mytransaction_aside_list_link">포인트 거래 내역</button>
                        </li>
                    </ul>
                </div>
                <div className="mytransaction_section-wrap">
                    {/* 나의 판매목록 */}
                    {
                        isSale && <DocTradeMyTransactionSaleList />
                    }
                    {/* 나의 구매목록 */}
                    {
                        isPurchase && <DocTradeMyTransactionPurchaseList />
                    }
                    {/* 포인트 전환 */}
                    {
                        isSwitchPoint && <DocTradeMyTransactionSwitchPoint history={history}/>
                    }
                    {/* 포인트 출금 내역 */}
                    {
                        isWithdrawal && <DocTradeMyTransactionWithdrawal />
                    }
                    {/* 포인트 거래 내역 */}
                    {
                        isPointUsage && <DocTradeMyTransactionUsage />
                    }
                </div>
            </div>
        </div>
    )
}

export default DocTradeMyTransaction;