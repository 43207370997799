import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareWorksPath } from "./groupwarePath";
import "./css/GroupwareVacation.scss";
import GwAPI from "../../API/groupware";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import mt from "moment";
import { utils } from "xlsx";
import util from "../../util/util";
import { useHistory } from "react-router-dom";

const GroupwareVacation = () => {
  const history = useHistory();
  const [totDay, setTotDay] = useState("");
  const [dYear, setdYear] = useState(0);
  const [useDays, setUseDays] = useState(0);
  const [remindDays, setRemindDays] = useState(0);
  const [remindDay, setRemindDay] = useState("");
  const [useYear, setUseYear] = useState(0);
  const [useHalf, setUseHalf] = useState(0);
  const [vacList, setVacList] = useState([]);
  const [adminMenu, setAdminMenu] = useState([]);
  const [vacationerList, setVacationerList] = useState([]);

  // 휴가 통계 표기
  const[remindDaysD, remindDaysH] = remindDays.toString().split('.');
  const[useYearD, useYearH] = useYear.toString().split('.');
  const[useHalfD, useHalfH] = useHalf.toString().split('.');

  // 휴가종류 및 휴가 잔여일 저장
  const [vacaWithDays, setVacaWithDays] = useState([]);

  // 휴가 통계 percentage bar 저장 변수
  const [percentage, setPercentage] = useState(0);
  // 이번주 평일 날짜 구하는 코드
  let currentDate = new Date();
  let firstDayOfWeek = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay())
  );
  let daysOfWeek = [];
  for (let i = 1; i < 6; i++) {
    let day = new Date(firstDayOfWeek);
    day.setDate(firstDayOfWeek.getDate() + i);
    daysOfWeek.push(day);
  }

  useEffect(() => {
    GwAPI.getVacationDashBoradInfo().then((res) => {
      let vacInfo = res.data.dataVacationMap.vacInfo;
      let vacInfoList = res.data.dataVacationMap.vacList;
      setTotDay(vacInfo.h100089);
      setdYear(vacInfo.dYear);
      setUseDays(vacInfo.useDays_int);
      setRemindDays(vacInfo.remind_day_int);
      setUseYear(vacInfo.useUYear);
      setUseHalf(vacInfo.useYHalf);
      setVacList(vacInfoList);
      setRemindDay(vacInfo.remind_day);
      setPercentage((vacInfo.remind_day_int / vacInfo.dYear) * 100);
    });
    GwAPI.getVacationerList().then((res) => {
      let arr = [];
      let data = res.data.dataVacationMap.vacCompList;

      // 휴가 기간 구하는 코드
      data.map((vacation) => {
        var startDate = new Date(vacation.start_date);
        var endDate = new Date(vacation.end_date);
        var datesInRange = [];
        for (
          var date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          datesInRange.push(new Date(date));
        }
        vacation.duration = [...datesInRange];
      });

      // 이번주 [월,화,수,목,금] 휴가 인원 추가하는 코드
      daysOfWeek.map((day) => {
        let tmp = [];
        data.map((item) => {
          const chk = Boolean(
            item.duration.find((el) => el.toDateString() === day.toDateString())
          );
          if (chk) {
            tmp.push(item);
          }
        });
        arr.push([...tmp]);
      });

      setVacationerList(arr);
    });
  }, []);

  useEffect(() => {
    GwAPI.getVacKindWithDays().then((res) => {
      // console.log("vacation 정보: ", res.data);
      // console.log("vacaWithDays: ", res.data.vacationKindDay);
      setVacaWithDays(res.data.vacationKindDay);
    });
  }, []);

  // 아이콘 변경
  const sortingIconByCd = (holiday_cd, holiday_name) => {
    if(holiday_name.includes('생일')) {
      return 'icon_widget_cake'
    } else if(holiday_name.includes('여름')) {
      return 'icon_widget_parasol';
    }

    // 기본 휴가
    switch(holiday_cd) {
      // 병가
      case '100090':
        return 'icon_widget_medicine';
      
      // 조퇴
      case '100092' :
        return 'icon_widget_briefcase';

      // 기본 캘린더 아이콘
      default:
        return 'icon_widget_calendar';
    }
  };

  const leftDayInfo = (leftday) => {
    const day = Math.floor(leftday);
    const hours = Math.round((leftday - day) * 8);

    if(leftday == "한도없음") {
      return "한도없음";
    } else {
      return `${day}일 ${hours}시간`;
    } 
  };

  useEffect(() => {
    getAdminMenu();
  }, []);

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "100010",
      menuTitle: "관리자",
      isContentWrap: false,
      isOpen: false,
    };
    util.getGroupwareAdminMenu(setAdminMenu, param);
  };

  return (
    <div id="GroupwareVacation">
      <GroupwareSideBar
        currentPath={groupWareWorksPath["vacation"]}
        adminMenu={adminMenu}
        sidebar={"vacation"}
      />
      <div className="vacation_wrapper">
        <div>
          <h5>MY 휴가현황</h5>
          <div className="vacation_grid_my">
            <div className="vacation_stats">
              <p>휴가 통계</p>
              <div className="vacation_remain">
                <p>
                  <span>{remindDaysD}</span>일 <span style={{marginLeft: '13px'}}>{remindDaysH}</span>시간 남았어요
                </p>
                <p>
                  ~<Moment format="yyyy-MM-DD (ddd)" />
                </p>
              </div>
              <div className="percentage_bar">
                <div>
                  <div style={{ width: `${percentage}%` }}></div>
                </div>
                <p>
                  <span>{useDays}</span> / {dYear}
                </p>
              </div>
              <div className="vacation_usage">
                <div>
                  <p>사용 연차</p>
                  <p>
                    <span>{useYearD}</span>일
                  </p>
                </div>
                <div>
                  <p>사용 반차</p>
                  <p>
                    <span>{useHalfD}</span>일 <span style={{marginLeft: '5px'}}>{useHalfH}</span>시간
                  </p>
                </div>
                <div>
                  <p>잔여 휴가일</p>
                  <p>
                    <span>{remindDaysD}</span>일 <span style={{marginLeft: '5px'}}>{remindDaysH}</span>시간
                  </p>
                </div>
              </div>
              <button
                className="link_detail_btn"
                onClick={() =>
                  util.getGroupwareSrcPath("vacationStats", "", "vacation")
                }
              >
                자세히 보기 &gt;
              </button>
            </div>
            <div className="vacation_approval">
              <p>휴가 승인 현황</p>
              {vacList.map((item, index) => {
                let clsName = "ing";
                let statusName = "승인중";
                let strDate = mt(item.toDate).format("YY-MM-DD");
                if (item.status == "900118") {
                  clsName = "done";
                  statusName = "승인완료";
                } else if (item.status == "900759") {
                  clsName = "return";
                  statusName = "반려";
                }
                return (
                  <div>
                    <p>
                      {strDate}({item.strDay})
                    </p>
                    <p>{item.holiday_name}</p>
                    <p className={`approval_circle ${clsName}`}>
                      {" "}
                      {statusName}
                    </p>
                  </div>
                );
              })}
              <button
                className="link_detail_btn"
                onClick={() =>
                  util.getGroupwareSrcPath("myList", "holiday", "vacationList")
                }
              >
                모두보기 &gt;
              </button>
            </div>
            <div>
              <p>휴가자 현황</p>
              <div className="vacation_people custom_scroll">
                <div>
                  <p>이번 주</p>
                  {daysOfWeek.map((day, index) => {
                    return (
                      vacationerList[index]?.length !== 0 && (
                        <div>
                          <p>
                            {day.toLocaleDateString(undefined, {
                              weekday: "short",
                              month: "short",
                              day: "numeric",
                            })}
                          </p>
                          <div className="vacatior_list">
                            {vacationerList[index]?.map((item) => {
                              return (
                                <div>
                                  <p>
                                    <i className="icon_user_18px" />
                                    {item.name}
                                  </p>
                                  <p>{item.holiday_name}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              <button
                className="link_detail_btn"
                onClick={() =>
                  history.push(
                    "/groupware/vacationWorks?section=vacation&moveUrl=vacation.admin.do&command=vacationStatusList&siteFlag=biznavi&suMenuCd=100010&menuTitle=관리자&menuCd=120002"
                  )
                }
              >
                더 보기 &gt;
              </button>
            </div>
          </div>
        </div>
        <div>
          <h5>휴가 신청하기</h5>
          <div className="vacation_grid_apply">
            {
              vacaWithDays && vacaWithDays.map((vac) => (
                <div>
                <p>
                  <i className={sortingIconByCd(vac.holiday_cd, vac.holiday_name)} />
                  {vac.holiday_name}
                </p>
                <p className="item_sub_title">잔여일: {leftDayInfo(vac.leftday)}</p> 
                <button
                  onClick={() =>
                    util.getGroupwareSrcPath("writeForm", `${vac.holiday_cd}`, "vacation")
                  }
                >
                  신청하기
                </button>
              </div>
              ))
            }

            {/** 
            <div>
              <p>
                <i className="icon_widget_calendar" />
                연차
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100089", "vacation")
                }
              >
                신청하기
              </button>
            </div>
            <div>
              <p>
                <i className="icon_widget_calendar" />
                반차
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100099", "vacation")
                }
              >
                신청하기
              </button>
            </div>
            <div>
              <p>
                <i className="icon_widget_medicine" />
                병가
              </p>
              <p className="item_sub_title">잔여일: 3일 0시간</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100090", "vacation")
                }
              >
                신청하기
              </button>
            </div>
            <div>
              <p>
                <i className="icon_widget_briefcase" />
                조퇴
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100092", "vacation")
                }
              >
                신청하기
              </button>
            </div>

            <div>
              <p>
                <i className="icon_widget_calendar" />
                경조사
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100106", "vacation")
                }
              >
                신청하기
              </button>
            </div>

            <div>
              <p>
                <i className="icon_widget_parasol" />
                여름휴가
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100136", "vacation")
                }
              >
                신청하기
              </button>
            </div>

            <div>
              <p>
                <i className="icon_widget_cake" />
                생일반차
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100137", "vacation")
                }
              >
                신청하기
              </button>
            </div>

            <div>
              <p>
                <i className="icon_widget_calendar" />
                훈련
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100093", "vacation")
                }
              >
                신청하기
              </button>
            </div>

            <div>
              <p>
                <i className="icon_widget_calendar" />
                휴가취소
              </p>
              <p className="item_sub_title">잔여일: {remindDay}</p>
              <button
                onClick={() =>
                  util.getGroupwareSrcPath("writeForm", "100109", "vacation")
                }
              >
                신청하기
              </button>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupwareVacation;
