import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import "./css/MarketInfoHeader.scss";
import session1 from "../../assets/images/common/marketinfo.png";

const MarketInfoHeader = ({ category }) => {
  const [marketInfoCount, setMarketInfoCount] = useState(0);
  const [fieldCount, setFieldCount] = useState(0);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getMarketCategory().then((res) => {
      let data = res.data.category_list;
      getCount(data);
    });
  };

  const getCount = (data) => {
    data.map((el) => {
      setMarketInfoCount((prevState) => prevState + el.child.length);
      el.child.map((item) => {
        setFieldCount((prevState) => prevState + item.data_cnt);
      });
    });
  };

  return (
    <div id="MarketInfoHeader">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>시장정보 조사</h2>
        </div>
        <div className="content">
          <div className="title">
            <p>
              <span>전문가 집단의 시장보고서를 최적화한,</span>
              <br />
              <span>
                비전문가도 쉽게 활용할 수 있는 분야별 Biznavi만의 시장정보
              </span>
              <br />
              <span>
                {marketInfoCount}개의 분야, {fieldCount.toLocaleString("en-AU")}
                건의 시장정보<span>를 활용해 보세요.</span>
              </span>
            </p>
          </div>
          <div className="subtitle">
            <p>Tip</p>
            <p>
              차트 커스터마이징 기능과 보도자료 정보를 기능해 사업계획서,
              제안서, IR자료 제작에 활용해 보시고
              <br /> 간단한 시장보고서도 만들어 보세요.
            </p>
          </div>
          <img src={session1} alt="session1" />
        </div>
      </div>
    </div>
  );
};

export default MarketInfoHeader;
