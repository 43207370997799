const mobilePath = "/mobile";
const notifyPath = "/notify";
const introPath = "/intro";
const mypagePath = "/mypage";

export default {
  intro: {
    main: `${introPath}/main`,
    service: `${introPath}/service`,
    page: `${introPath}/page`,
  },
  mypage: {
    mobile: {
      home: `${mypagePath}/mobile`,
      edit: `${mypagePath}/mobile/edit`,
    },
  },
  notify: {
    list: `${notifyPath}/list`,
  },
  home: "/",
  docTrade: "/docTrade/list",
  consortium: "/consortium/list",
  exposition: "/exposition/list",
  businessDescription: "/businessDescription/list",
  govSupport: "/govSupport/list",
  consultCom: "/consultCom/list",
  marketInfo: "/marketInfo/category",
  taskManage: "/taskManage/list",
  taskManageInterest: "/taskManage/setting",
  researchTechCategory: "/researchTech/intro",
  matchingBiz: {
    pathname: "/taskManage/list",
    search: "?category=2",
    state: "delKeyword",
  },
  mobile: {
    path: mobilePath,
    service: `${mobilePath}/service`,
    more: `${mobilePath}/more`,
  },
};
