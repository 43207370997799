import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CompetitorStatisticList = ({ statisticList, num }) => {
  const history = useHistory();
  const goTrend = (cd) => {
    history.push("/competitor/trend", { cd: cd, totalNum: num });
  };
  const goStatistic = () => {
    history.push("/competitor/statistic", { totalNum: num });
  };
  return (
    <div id="CompetitorStatisticList">
      <ul className="tag_list mr10">
        <li className="tag bg_light_gray" onClick={() => goStatistic()}>
          #전체 랭킹
        </li>
        {statisticList.length
          ? statisticList.map((item, idx) => (
              <li
                key={idx}
                className="tag bg_light_gray"
                onClick={() => goTrend(item.cd)}
              >
                {item.name}
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
};

export default CompetitorStatisticList;
