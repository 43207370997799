import React from "react";
import "./css/DocTradeSampleList.scss";
import { Link } from 'react-router-dom';

function DocTradeSampleList({item}) {
    return (
        <>
            <li className="sample_list-contents_list pc">
                <div className="sample_list-contents_left">
                    <Link to={`view/${item.idx}`} className="sample_list-contents_img-link">
                        <img src={window.$Global.getCDN(item.preview_key)} alt={item.preview_name || "미리보기 없음"} className="sample_list-contents_img" />
                    </Link>
                </div>
                <div className="sample_list-contents_center">
                    <Link to={`view/${item.idx}`}>
                        <h4 className="sample_list-contents_list-title">
                            {item.title}
                        </h4>
                    </Link>
                    <dl className="sample_list-contents_list-format">
                        <dt className="formatTitle">파일 형식</dt>
                        <dd className="formatInfo">{item.file_type?.toUpperCase()}</dd>
                    </dl>
                    <p className="sample_list-contents_list-price">
                        <span className="priceInfo">{item.price ? `${window.$Global.commaify(item.price)}원` : '무료'}</span>
                        <Link to={`view/${item.idx}`} className="btn-trade">
                            거래하기
                        </Link>
                    </p>
                </div>
            </li>
            <li className="sample_list-contents_list mo">
                <div className="sample_list-contents_left">
                    <Link to={`view/${item.idx}`} className="sample_list-contents_img-link">
                        <img src={window.$Global.getCDN(item.preview_key)} alt={item.preview_name || "미리보기 없음"} className="sample_list-contents_img" />
                    </Link>
                </div>
                <div className="sample_list-contents_center">
                    <Link to={`view/${item.idx}`}  className="sample_list-contents_img-link">
                        <div className="formatInfo">{item.file_type?.toUpperCase()}</div>
                        <div className="listTitle">{item.title}</div>
                        <div className="priceInfo">{item.price ? `${window.$Global.commaify(item.price)}원` : '무료'}</div>
                    </Link>
                </div>
            </li>
        </>
    )
}

export default DocTradeSampleList;