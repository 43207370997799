import React, { useContext } from "react";
import CommonContext from "../../../store/Common";
import Const from "../const";

function TaskTableHead({
  showType,
  stateType,
  searchType,
  onChangeSearchType,
}) {
  const { isMobile } = useContext(CommonContext);
  // const bizAll = showType == Const.showType.bizAll;
  const bizOrganized = showType == Const.showType.bizOrganized;
  const bizMatching = showType == Const.showType.bizMatching;

  const bizAll = stateType === Const.stateType.all;
  const bizReceiving = stateType === Const.stateType.receiving;
  const bizScheduled = stateType === Const.stateType.scheduled;

  // 0 dday 내림차순
  // 1 dday 오름차순
  // 2 접수일 내림차순
  // 3 접수일 오름차순
  // 4 마감일 내림차순
  // 5 마감일 오름차순
  const obj_sort = {
    sort_day_down: 0,
    sort_day_up: 1,
    sort_reception_down: 2,
    sort_reception_up: 3,
    sort_deadline_down: 4,
    sort_deadline_up: 5,
  };

  const TagBiz = (
    <>
      {!isMobile && <th className="summary">요약</th>}
      <th className="table_title">사업명</th>
      {!isMobile && (
        <>
          <th>현황</th>
          <th>
            <div className="use_button">
              <p>접수일</p>
              <button
                className={
                  searchType.sort == obj_sort.sort_reception_down
                    ? "icon_arrow_up_gray"
                    : "icon_arrow_down_gray"
                }
                onClick={() =>
                  onChangeSearchType(
                    "sort",
                    searchType.sort == obj_sort.sort_reception_down
                      ? obj_sort.sort_reception_up
                      : obj_sort.sort_reception_down
                  )
                }
              />
            </div>
          </th>
          <th>
            <div className="use_button">
              <p>마감일</p>
              <button
                className={
                  searchType.sort == obj_sort.sort_deadline_down
                    ? "icon_arrow_up_gray"
                    : "icon_arrow_down_gray"
                }
                onClick={() =>
                  onChangeSearchType(
                    "sort",
                    searchType.sort == obj_sort.sort_deadline_down
                      ? obj_sort.sort_deadline_up
                      : obj_sort.sort_deadline_down
                  )
                }
              />
            </div>
          </th>
          <th className="money">사업 금액</th>
        </>
      )}

      <th className="day">
        <div className="use_button">
          <p>D-DAY</p>
          <button
            className={
              searchType.sort == obj_sort.sort_day_down
                ? "icon_arrow_up_gray"
                : "icon_arrow_down_gray"
            }
            onClick={() =>
              onChangeSearchType(
                "sort",
                searchType.sort == 0
                  ? obj_sort.sort_day_up
                  : obj_sort.sort_day_down
              )
            }
          />
        </div>
      </th>

      {!isMobile && (
        <>
          {/*<th>공고기관</th>*/}
          <th>첨부파일</th>
        </>
      )}
    </>
  );

  const TagBizMatching = (
    <>
      <th className="table_title">사업명</th>
      {!isMobile && (
        <>
          <th>공고 기관</th>
          <th className="money">사업 금액</th>
          <th>공고 등록일</th>
          <th>접수일</th>
          <th>마감일</th>
        </>
      )}
    </>
  );

  return (
    <thead>
      <tr className="no_focus_outline">
        <th className="interest">{!bizMatching ? "즐찾" : ""}</th>
        {(bizAll || bizOrganized || bizReceiving || bizScheduled) && TagBiz}
        {bizMatching && TagBizMatching}
        {!isMobile && <th>조회수</th>}
      </tr>
    </thead>
  );
}

export default TaskTableHead;
