import React, { useEffect, useRef, useState } from "react";
import "../css/ResearchTechEdit.scss";
import { Bar, Pie, Line } from "react-chartjs-2";
import CommonAPI from "../../../API/common";
import { useHistory, useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import _ from "lodash";
import message from "../../../const/message";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  applyNumberArray,
  categoryData,
  keywordIdx,
  researchTechHistory,
} from "../../../atom";
import ResearchTechEditSideBar from "./ResearchTechEditSideBar";
import ResearchTechEditHeader from "./ResearchTechEditHeader";

function ResearchTechEdit({ match, location, history }) {
  const { idx, doc_idx, keyword, address, year, company_code } = qs.parse(
    location.search
  );

  const [researchInfo, setResearchInfo] = useState({});
  const copyResearchInfo = _.cloneDeep(researchInfo);
  const [applicantRef, numberYearRef, IPCRef, lineRef, patentListRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const applyNumberArr = useRecoilValue(applyNumberArray);
  const keyword_idx = useRecoilValue(keywordIdx);
  const categoryObj = useRecoilValue(categoryData);
  const historyData = useRecoilValue(researchTechHistory);
  const isCompanyCodeSearch = company_code ? true : false;

  useEffect(() => {
    if (doc_idx) {
      CommonAPI.getResearchDocument(doc_idx).then((res) => {
        setResearchInfo(res.data);
      });
    }

    if (idx || company_code) {
      CommonAPI.getResearchKeyword({
        patentApplyNumberList: applyNumberArr,
      }).then((res) => {
        setResearchInfo({ ...categoryObj, data: res.data.data });
      });
    }

    if (keyword) {
      CommonAPI.getResearchKeyword({
        patentApplyNumberList: applyNumberArr,
        keyword_idx: keyword_idx,
      }).then((res) => {
        let str = `"${keyword}" 키워드로 검색된 특허 선행기술 분석 결과`;
        setResearchInfo({ title: str, data: res.data.data });
      });
    }

    if (address) {
      if (year === undefined) {
        alert("year를 입력해주세요.");
        return;
      }

      CommonAPI.getPatentDataAddress({ address, year }).then((res) =>
        setResearchInfo(res.data)
      );
    }
  }, []);

  if (window.$Global.isEmptyObject(researchInfo)) return null;

  copyResearchInfo.data.patent_grade_info?.items.reverse();

  const vertical_patent_list_data = {
    labels: copyResearchInfo.data.patent_grade_info?.items?.map(
      (item) => item.sm_grade
    ),
    datasets: [
      {
        label: "등록건수",
        data: copyResearchInfo.data.patent_grade_info?.items?.map(
          (item) => item.cnt
        ),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const applicant_count = copyResearchInfo.data.applicant_count.items
    .filter((item) => item.apply_cnt + item.register_cnt > 0)
    .splice(0, 6);
  const vertical_applicant_labels = applicant_count.map((item) => item.name);

  const vertical_applicant_data = {
    labels: vertical_applicant_labels,
    datasets: [
      {
        label: "출원건수",
        data: applicant_count.map((item) => item.apply_cnt),
        backgroundColor: "rgba(69,147,245,1)",
      },
      {
        label: "등록건수",
        data: applicant_count.map((item) => item.register_cnt),
        backgroundColor: "rgba(129,202,233,1)",
      },
    ],
  };

  const number_of_year_count =
    copyResearchInfo.data.number_of_year.items.splice(
      copyResearchInfo.data.number_of_year.items.length - 6
    );
  const vertical_number_of_year_labels = number_of_year_count.map(
    (item) => item.year
  );

  const vertical_number_of_year_data = {
    labels: vertical_number_of_year_labels,
    datasets: [
      {
        label: "출원건수",
        data: number_of_year_count.map(
          (item) => item.apply_count + item.f_apply_count
        ),
        backgroundColor: "rgba(69,147,245,1)",
      },
      {
        label: "등록건수",
        data: number_of_year_count.map(
          (item) => item.register_count + item.f_register_count
        ),
        backgroundColor: "rgba(129,202,233,1)",
      },
    ],
  };

  const vertical_options = {
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            callback: (value) => window.$Global.commaify(value),
          },
        },
      ],
    },
  };

  const ipc_number = copyResearchInfo.data.ipc_number.items
    .sort((a, b) => a.cnt - b.cnt)
    .splice(copyResearchInfo.data.ipc_number.items.length - 6);
  const vertical_ipc_number_labels = ipc_number.map((item) => item.ipc_code);

  const pie_data = {
    labels: vertical_ipc_number_labels,
    datasets: [
      {
        data: ipc_number.map((item) => item.cnt),
        backgroundColor: [
          "#826af9",
          "#ffe700",
          "#2d99ff",
          "#505d6f",
          "#2cd9c5",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "#826af9",
          "#ffe700",
          "#2d99ff",
          "#505d6f",
          "#2cd9c5",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],
  };

  const pie_options = {
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const line_data = {
    labels: vertical_number_of_year_labels,
    datasets: [
      {
        label: "국내",
        // data: number_of_year_count.map(
        //   (item) => item.apply_count + item.register_count
        // ),
        data: [1, 5, 2, 8, 2, 5],
        borderColor: " #ffa600",
        backgroundColor: " #ffa600",
        borderWidth: 4,
        lineTension: 0,
        radius: 0,
        fill: false,
      },
      {
        label: "해외",
        data: number_of_year_count.map(
          (item) => item.f_apply_count + item.f_register_count
        ),
        borderColor: "#81cae9",
        backgroundColor: "#81cae9",
        borderWidth: 4,
        lineTension: 0,
        fill: false,
        radius: 0,
      },
    ],
  };

  const line_options = {
    responsive: true,
    legend: {
      position: "bottom",
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const onClickSaveAndDownload = () => {
    const dataURI1 =
      applicantRef.current.chartInstance.canvas.toDataURL("image/png");
    const blob1 = window.$Global.dataURItoBlob(dataURI1);
    const dataURI2 =
      numberYearRef.current.chartInstance.canvas.toDataURL("image/png");
    const blob2 = window.$Global.dataURItoBlob(dataURI2);
    const dataURI3 = IPCRef.current.chartInstance.canvas.toDataURL("image/png");
    const blob3 = window.$Global.dataURItoBlob(dataURI3);
    const dataURI4 =
      lineRef.current.chartInstance.canvas.toDataURL("image/png");
    const blob4 = window.$Global.dataURItoBlob(dataURI4);
    const dataURI5 =
      patentListRef.current.chartInstance.canvas.toDataURL("image/png");
    const blob5 = window.$Global.dataURItoBlob(dataURI5);

    let formData = new FormData();

    formData.append("file", new File([blob1], `image1.${blob1.type}`));
    formData.append("file", new File([blob2], `image2.${blob2.type}`));
    formData.append("file", new File([blob3], `image3.${blob3.type}`));
    formData.append("file", new File([blob4], `image4.${blob4.type}`));
    formData.append("file", new File([blob5], `image5.${blob5.type}`));

    formData.append("title", researchInfo.title);
    formData.append("category1_idx", researchInfo.category1_idx);
    formData.append("category2_idx", researchInfo.category2_idx);
    formData.append("data", JSON.stringify(researchInfo.data));
    formData.append("research_idx", idx);

    if (address !== undefined) {
      formData.append("keyword_idx", "ZBLIE6ZABLF95E1L");
    } else {
      formData.append("keyword_idx", researchInfo?.keyword_idx);
    }

    let payload = {
      title: researchInfo.title,
      category1_idx: researchInfo.category1_idx,
      category2_idx: researchInfo.category2_idx,
      data: researchInfo.data,
      keyword_idx: idx,
    };

    if (idx || company_code) {
      CommonAPI.addResearchDoc(formData).then(() => {
        alert(message.successSave);
        history.push(`/researchTech/my/list`);
      });
    }

    if (doc_idx) {
      CommonAPI.updateResearchDoc(doc_idx, payload).then(() => {
        alert(message.successSave);
        history.push(`/researchTech/my/list`);
      });

      if (!researchInfo.grade_cnt_key) {
        CommonAPI.uploadResearchImage(doc_idx, formData);
      }
    }

    if (keyword || address) {
      CommonAPI.addResearchDoc(formData).then(() => {
        alert(message.successSave);
        history.push(`/researchTech/my/list`);
      });
    }
  };

  const onChangeTextEdit = (e) => {
    let { id, value } = e.target;
    let copy = _.cloneDeep(researchInfo);
    if (value.length > 1000) {
      alert(message.lengthOver);
    } else {
      copy.data.text_obj[id] = value;
    }
    setResearchInfo(copy);
  };

  return (
    <>
      <div id="ResearchTechEditHeader">
        <div>
          <p>특허 기술트렌드 리포트 작성하기</p>
          <button
            onClick={() => {
              if (idx) {
                history.goBack();
              } else {
                history.push({
                  pathname: "/researchTech/category",
                  state: {
                    ...historyData,
                  },
                });
              }
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
        <p>
          비즈내비에서 특허 기술트렌드 리포트를 작성 하여보세요, 저장하기를
          누르면 docx파일로 내려 받을 수 있습니다.
        </p>
      </div>
      <div id="ResearchTechEdit">
        <ResearchTechEditSideBar isCompanyCodeSearch={isCompanyCodeSearch} />
        <div className="edit_wrapper">
          <div id="step1">
            <h5>기본 정보</h5>
            <div className="category_title">
              {researchInfo?.category1_name ? (
                <div className="category_name">
                  <p>카테고리</p>
                  <input type="text" value={researchInfo.category1_name} />
                  <input type="text" value={researchInfo.category2_name} />
                </div>
              ) : (
                ""
              )}
              <div className="research_tech_name">
                <p>제목 작성하기</p>
                <input
                  type="text"
                  placeholder="제목을 입력해주세요"
                  data-name="제목"
                  value={researchInfo.title}
                  onChange={(e) =>
                    setResearchInfo({ ...researchInfo, title: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div id="step2">
            <h5>내용 작성하기</h5>
            <p className="semi_title" id="child1">
              주요 특허 리스트
              {/*<span>*/}
              {/*  {researchInfo.data?.total_count*/}
              {/*    ? ` (총 ${researchInfo.data.total_count}개의 특허중 중 5개)`*/}
              {/*    : ""}*/}
              {/*</span>*/}
            </p>

            <table>
              <colgroup>
                <col width="5%" />
                <col width="5%" />
                <col width="50%" />
                <col width="10%" />
                <col width="20%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>국가</th>
                  <th>특허 명</th>
                  <th>출원인</th>
                  <th>출원일자</th>
                  <th>등급</th>
                </tr>
              </thead>
              <tbody>
                {copyResearchInfo.data.patent_list.items
                  .filter((item) => item.invention_name)
                  .slice(0, 5)
                  .map((item, idx) => {
                    return (
                      <tr key={item.idx}>
                        <td>{idx + 1}</td>
                        <td>{item.nation}</td>
                        <td>{item.invention_name}</td>
                        <td>{item.applicant}</td>
                        <td>{`${item.apply_date?.substr(
                          0,
                          4
                        )}-${item.apply_date?.substr(
                          4,
                          2
                        )}-${item.apply_date?.substr(6, 2)}`}</td>
                        <td>{item.sm_grade}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="graph_item">
              <div className="description_area">
                <p className="semi_title" id="child2">
                  {isCompanyCodeSearch
                    ? "특허권자의 현재 특허보유자"
                    : "특허권자별 특허 건수"}
                </p>
                <p className="semi_title description_title">설명 란</p>
              </div>
              <div className="content_area">
                <div className="graph">
                  <Bar
                    ref={applicantRef}
                    height={250}
                    data={vertical_applicant_data}
                    options={vertical_options}
                  />
                </div>
                <textarea
                  placeholder="내용을 입력해주세요"
                  spellCheck="false"
                  id="applicant_count_text"
                  value={researchInfo.data?.text_obj?.applicant_count_text}
                  onChange={(e) => onChangeTextEdit(e)}
                />
              </div>
            </div>

            <div className="graph_item">
              <div className="description_area">
                <p className="semi_title" id="child3">
                  연도별 특허 건 수
                </p>
                <p className="semi_title description_title">설명 란</p>
              </div>
              <div className="content_area">
                <div className="graph">
                  <Bar
                    ref={numberYearRef}
                    height={250}
                    data={vertical_number_of_year_data}
                    options={vertical_options}
                  />
                </div>
                <textarea
                  placeholder="내용을 입력해주세요"
                  spellCheck="false"
                  id="count_of_year_text"
                  value={researchInfo.data?.text_obj?.count_of_year_text}
                  onChange={(e) => onChangeTextEdit(e)}
                />
              </div>
            </div>

            <div className="graph_item">
              <div className="description_area">
                <p className="semi_title" id="child4">
                  IPC 분포 현황
                </p>
                <p className="semi_title description_title">설명 란</p>
              </div>
              <div className="content_area">
                <div className="graph">
                  <Pie
                    ref={IPCRef}
                    height={250}
                    data={pie_data}
                    options={pie_options}
                  />
                </div>
                <textarea
                  placeholder="내용을 입력해주세요"
                  spellCheck="false"
                  id="ipc_text"
                  value={researchInfo.data?.text_obj?.ipc_text}
                  onChange={(e) => onChangeTextEdit(e)}
                />
              </div>
            </div>

            <div className="graph_item">
              <div className="description_area">
                <p className="semi_title" id="child5">
                  {isCompanyCodeSearch
                    ? "국내외 특허 이전 기업 현황"
                    : "연도별 국내&해외기업 특허 건수"}
                </p>
                <p className="semi_title description_title">설명 란</p>
              </div>
              <div className="content_area">
                <div className="graph">
                  <Line
                    ref={lineRef}
                    height={250}
                    data={line_data}
                    options={line_options}
                  />
                </div>
                <textarea
                  placeholder="내용을 입력해주세요"
                  spellCheck="false"
                  id="applicant_year_count_text"
                  value={researchInfo.data?.text_obj?.applicant_year_count_text}
                  onChange={(e) => onChangeTextEdit(e)}
                />
              </div>
            </div>

            <div className="graph_item">
              <div className="description_area">
                <p className="semi_title" id="child6">
                  등록건에 대한 특허 등급 정보
                </p>
                <p className="semi_title description_title">설명 란</p>
              </div>
              <div className="content_area">
                <div className="graph">
                  <Bar
                    ref={patentListRef}
                    height={250}
                    data={vertical_patent_list_data}
                    options={vertical_options}
                  />
                </div>
                <textarea
                  placeholder="내용을 입력해주세요"
                  spellCheck="false"
                  id="patent_grade_text"
                  value={researchInfo.data?.text_obj?.patent_grade_text}
                  onChange={(e) => onChangeTextEdit(e)}
                />
              </div>
            </div>
          </div>
          <div id="step3">
            {/*<h5>특허 요약 건수 설정하기</h5>*/}
            {/*<div className="choice_btn">*/}
            {/*  <p className="semi_title">특허 추출 갯수</p>*/}
            {/*  <div>*/}
            {/*    <input type="radio" id="all" name="choice" />*/}
            {/*    <label for="all">*/}
            {/*      전체 <span>유료</span>*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <input type="radio" id="fifty" name="choice" />*/}
            {/*    <label htmlFor="fifty">*/}
            {/*      상위 50건*/}
            {/*      <span>유료</span>*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <input type="radio" id="five" name="choice" defaultChecked />*/}
            {/*    <label htmlFor="five">상위 5건</label>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="check_list">
              <p className="semi_title">리스트 확인하기</p>
              <p>
                검색결과
                <span>{researchInfo.data?.total_count}</span>건
              </p>
            </div>
            <table>
              <colgroup>
                <col width="5%" />
                <col width="5%" />
                <col width="50%" />
                <col width="10%" />
                <col width="20%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>국가</th>
                  <th>특허 명</th>
                  <th>출원인</th>
                  <th>출원일자</th>
                  <th>등급</th>
                </tr>
              </thead>
              <tbody>
                {copyResearchInfo.data.patent_list.items
                  .filter((item) => item.invention_name)
                  .slice(0, 5)
                  .map((item, idx) => {
                    return (
                      <tr key={item.idx}>
                        <td>{idx + 1}</td>
                        <td>{item.nation}</td>
                        <td>{item.invention_name}</td>
                        <td>{item.applicant}</td>
                        <td>{`${item.apply_date?.substr(
                          0,
                          4
                        )}-${item.apply_date?.substr(
                          4,
                          2
                        )}-${item.apply_date?.substr(6, 2)}`}</td>
                        <td>{item.sm_grade}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <button
            className="btn_save"
            onClick={onClickSaveAndDownload}
            id="step4"
          >
            레포트 저장하기
          </button>
        </div>
      </div>
    </>
  );
}

export default ResearchTechEdit;
