import React, {useContext, useEffect, useRef, useState} from "react";
import "../css/MobileInfoEdit.scss";
import MemberAPI from "../../../API/member";
import Validator from "../../../util/validator";
import MemberContext from "../../../store/Member";
import {useHistory} from "react-router-dom";
import companyType from "../../../types/companyType";
import CommonContext from "../../../store/Common";

function MobileInfoEdit() {
    const history = useHistory();
    const {isMobile} = useContext(CommonContext);
    const memberContext = useContext(MemberContext);
    const isSns = Boolean(memberContext.sns_type);
    const [info, setInfo] = useState({});
    const isViewTypeIpnow = window.$Global.isIpnow();
    let emailRef = useRef();
    let nameRef = useRef();

    useEffect(() => {
        MemberAPI.getInfo().then(res => {
            setInfo(res.data);
        });
    }, []);

    const updateInfo = () => {
        let arr = [];
        if (emailRef.current) arr.push(emailRef);
        if (nameRef.current) arr.push(nameRef);

        if (Validator.refValidator(arr)) {
            let email = emailRef.current?.value;
            let name = nameRef.current?.value;

            MemberAPI.updateInfo({email: email, name: name}).then(() => {
                alert("수정되었습니다");
                history.go(0);
            });
        }
    };

    return (
        <div id="MobileInfoEdit">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2>회원 정보 수정</h2>
            </div>

            <div className="form">
                {
                    (isViewTypeIpnow  || info.company_type !== companyType.person)
                    &&
                    <div className="wrap_input">
                        <h2>소속기관</h2>
                        <input type="text" value={info.company_name || '소속 없음'} disabled/>
                    </div>
                }
                <div className="wrap_input">
                    <h2>가입 유형</h2>
                    <input type="text" value={window.$Global.convertAccountType(info.company_type)} disabled/>
                </div>
                <div className="wrap_input">
                    <h2>아이디</h2>
                    <input type="text" value={info.id} disabled/>
                </div>
                {
                    !isViewTypeIpnow
                    &&
                    <div className="wrap_input">
                        <h2>이름</h2>
                        <input type="text" value={info.name} disabled/>
                    </div>
                }
                <div className="wrap_input">
                    <h2>휴대폰</h2>
                    <input type="text" className={`has_btn`} defaultValue={info.phone} disabled/>
                    <button>{Boolean(info.is_authentication) ? '인증완료' : '미인증'}</button>
                </div>
                <div className="wrap_input">
                    <h2>이메일</h2>
                    <input type="text" defaultValue={info.email} data-name="이메일" ref={emailRef}/>
                </div>
                <div className="wrap_input">
                    <div className="alert">
                        <i className="icon_alert_circle"/>
                        {
                            isSns
                            ? <p>본인 인증과 비밀번호 변경, 회원 탈퇴는 PC에서만 가능합니다.</p>
                            : <p>회원 탈퇴 및 비밀번호 변경은 PC에서만 가능합니다.</p>
                        }
                    </div>
                </div>

                <button className="btn" onClick={updateInfo}>수정 완료</button>
            </div>
        </div>
    );
}

export default MobileInfoEdit;
