import { Link } from "react-router-dom";
import React from "react";
import "./css/MarketInfoSitemap.scss";

const MarketInfoSitemap = () => {
  return (
    <div className="category_wrapper">
      {/*<div className="category_item">*/}
      {/*  <p>*/}
      {/*    HOME <i className="icon_next_black" /> 시장 정보 보기*/}
      {/*    <i className="icon_next_black" /> 분야 선택하기*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div className="links">
        <Link to="my" className="btn_my">
          <span>나의 시장 정보 페이지</span>
          <i className="icon_notification_blue_full" />
        </Link>
        <Link to="request" className="btn_write">
          <span>시장정보 작성 요청</span>
          <i className="icon_pencil_outline" />
        </Link>
      </div>
    </div>
  );
};

export default MarketInfoSitemap;
