import React, {useEffect, useState} from "react";
import "../css/AdminServicePosting.scss";
import LawfirmAPI from "../../../API/lawfirm";
import qs from "query-string";
import _ from "lodash";

function AdminLawfirmPosting({history, location}) {
    const {idx, type, category} = qs.parse(location.search);
    const isView = (type == "view");
    const isEdit = (type == "edit");
    const [postInput, setPostInput] = useState({
        idx: "",
        address: "",
        email: "",
        file: null,
        introduce: [],
        img_key: "",
        nation: "",
        phone: "",
        title: "",
        url: ""
    });

    const path = '/admin/manage';
    const pathList = `${path}/list`;
    const commonParam = `${location.pathname}?idx=${idx}`;

    useEffect(() => {
        if (idx) {
            LawfirmAPI.getInfo(idx).then(res => {
                let data = res.data;
                let copy = _.cloneDeep(data);
                setPostInput(copy);
            });
        }
    }, [type]);

    const onClickEditGo = () => {
        history.push(`${commonParam}&type=edit`);
    };

    const onClickEdit = async () => {
        await onClickSuccess(true);
    };

    const onClickPost = async () => {
        await onClickSuccess(false);
    };

    const onClickSuccess = async (edit) => {
        let formData = new FormData();
        let introduceFormData = new FormData();

        let keys = Object.keys(postInput);

        keys.forEach(item => {
            if (item == "introduce") {
                if (isEdit) {
                    postInput[item].forEach(file => introduceFormData.append(item, file));
                } else {
                    postInput[item].forEach(file => formData.append(item, file));
                }
            }  else {
                formData.append(item, postInput[item])
            }
        });

        if (edit) {
            await LawfirmAPI.updatePost(idx, formData);
            await LawfirmAPI.updateIntroduceFile(idx, introduceFormData);
            alert("수정되었습니다");
            history.push(`${commonParam}&type=view`);
        } else {
            await LawfirmAPI.addPost(formData).then(() => {
                alert("등록되었습니다");
                history.push(pathList);
            });
        }
    };

    const onClickDelete = (file_idx) => {
        LawfirmAPI.deleteIntroduceFile(idx, file_idx).then(res => {
            let copy = _.cloneDeep(postInput);
            let index = copy.introduce.findIndex(item => item.idx === file_idx);
            copy.introduce.splice(index, 1);
            setPostInput(copy);
        });
    };

    return (
        <div id="AdminServicePosting">
            <h2 className="page_title">관리 게시글 등록</h2>
            <div className="articles">
                <div className="field">
                    <span>로펌명</span>
                    {
                        isView
                        ?   <p>{postInput.title}</p>
                        :   <input type="text" value={postInput.title} onChange={e => setPostInput({...postInput, title: e.target.value})} placeholder="제목"/>
                    }
                </div>
                <div className="field">
                    <span>로펌 로고</span>
                    {
                        isView
                            ?
                            <div>
                                {
                                        <p>
                                            {postInput.img_key.substr(postInput.img_key.lastIndexOf("/") + 1)}
                                        </p>
                                }
                            </div>

                            :   <input type="file" accept="image/*" onChange={e => setPostInput({...postInput, file: e.target.files[0]})}/>
                    }
                </div>
                <div className="field">
                    <span>소개자료</span>
                    {
                        isView
                            ?
                            <div>
                                {
                                    postInput.introduce?.map((item, idx) => {
                                        return (
                                            <p key={idx}>
                                                {item.file_name + "." + item.file_type}
                                                <button className="icon_badge_del ir_txt" onClick={() => onClickDelete(item.idx)}>삭제</button>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                            :   <input type="file" multiple onChange={e => setPostInput({...postInput, introduce: [...e.target.files]})}/>
                    }
                </div>
                <div className="field">
                    <span>국가</span>
                    {
                        isView
                            ?   <p>{postInput.nation}</p>
                            :   <input type="text" value={postInput.nation} onChange={e => setPostInput({...postInput, nation: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>주소</span>
                    {
                        isView
                            ?   <p>{postInput.address}</p>
                            :   <input type="text" value={postInput.address} onChange={e => setPostInput({...postInput, address: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>대표번호</span>
                    {
                        isView
                            ?   <p>{postInput.phone}</p>
                            :   <input type="text" value={postInput.phone} onChange={e => setPostInput({...postInput, phone: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>이메일</span>
                    {
                        isView
                            ?   <p>{postInput.email}</p>
                            :   <input type="text" value={postInput.email} onChange={e => setPostInput({...postInput, email: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>홈페이지</span>
                    {
                        isView
                            ?   <p>{postInput.url}</p>
                            :   <input type="text" value={postInput.url} onChange={e => setPostInput({...postInput, url: e.target.value})}/>
                    }
                </div>
            </div>

            <div className="btns">
                {
                    isView
                    ?   <button className="btn_post" onClick={onClickEditGo}>수정</button>
                    :
                        isEdit 
                        ?   <button className="btn_post" onClick={onClickEdit}>수정 완료</button>
                        :   <button className="btn_post" onClick={onClickPost}>등록</button>
                }
                <button className="btn_list" onClick={() => history.push(pathList)}>목록</button>
            </div>
        </div>
    )
};

export default AdminLawfirmPosting;