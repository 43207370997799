import GroupwareSideBar from "./GroupwareSideBar";
import { groupWareReport } from "./groupwarePath";
import IframeResizer from "iframe-resizer-react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import "./css/GroupwareWorks.scss";

export const GroupwareReportIns = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=insForm&siteFlag=biznavi&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareWeekReportIns = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=insForm&siteFlag=biznavi&work_type=weekly&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareTweekReport2Ins = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=insForm&siteFlag=biznavi&work_type=tWeekly2&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareReportSend = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=lstMyDailyWork&siteFlag=biznavi&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareReportReceive = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=lstDailyWork&siteFlag=biznavi&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};

export const GroupwareReportTemp = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dummy = params.get("dummy");

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupWareReport} />
      <div className="commute_wrapper">
        <IframeResizer
          id="contentWrap"
          src={`/dailywork.information.do?command=lstDailyWorkTemporarily&siteFlag=biznavi&dummy=${dummy}`}
          style={{ minWidth: "100%", minHeight: "100vh" }}
          heightCalculationMethod="bodyOffset"
          autoResize={true}
        />
      </div>
    </div>
  );
};
