import React, { useEffect, useState } from "react";

const HistoryAnalyze = ({ analyzeList = [] }) => {
  const [earliestDate, setEarliestDate] = useState(new Date());
  const [latestDate, setLatestDate] = useState(new Date());
  const [months, setmonths] = useState([]);
  const width = 60;
  const tit_width = 100;

  useEffect(() => {
    if (analyzeList.length > 0) {
      const minDate = new Date(
        Math.min(...analyzeList.map((item) => new Date(item.runway_period)))
      );
      const maxDate = getLatestDate(analyzeList);

      setmonths(getMonthsBetweenDates(minDate, maxDate));
      setEarliestDate(minDate);
      setLatestDate(maxDate);
    }
  }, [analyzeList]);

  function addMonthsToDate(dateStr, months) {
    // 'yyyy-mm-dd' 형식의 문자열을 Date 객체로 변환
    const date = new Date(dateStr);

    // 개월 수를 더함
    date.setMonth(date.getMonth() + months);

    return date;
  }

  function getLatestDate(analyzeList) {
    return analyzeList.reduce((latest, item) => {
      const runwayPeriodDate = new Date(item.runway_period);

      const runwayIdx =
        item.runway.runways.runway_idx === ""
          ? 0
          : item.runway.runways.runway_idx;

      // runway_period에 runway_idx 개월을 더한 날짜 계산
      const newDate = addMonthsToDate(item.runway_period, runwayIdx);

      // 기존의 가장 늦은 날짜와 현재 항목의 늦은 날짜 비교
      const currentLatest =
        runwayPeriodDate > newDate ? runwayPeriodDate : newDate;

      // 가장 늦은 날짜와 비교하여 더 늦은 날짜 반환
      return latest > currentLatest ? latest : currentLatest;
    }, new Date(0)); // 초기 값은 아주 옛날 날짜로 설정
  }

  function getMonthsBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    let end = new Date(endDate);
    end.setMonth(end.getMonth() + 11); // 11개월 더하기

    const months = [];

    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
    const endYear = end.getFullYear();
    const endMonth = end.getMonth();

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0;
      const monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        months.push({ year, month: String(month + 1).padStart(2, "0") }); // Store as object
      }
    }

    return months;
  }

  const groupedByYear = (months) => {
    return months.reduce((acc, { year, month }) => {
      if (!acc[year]) acc[year] = [];
      acc[year].push(month);

      return acc;
    }, {});
  };

  const getRunwaysPosition = (runwayData, type) => {
    // x축으로 얼마나 이동해야하나 구하기

    const runwayPeriod = runwayData.runway_period;
    const date = new Date(runwayPeriod);
    const runways = runwayData.runway.runways;
    let idx = "";
    let monthDifference = 0;
    let yearDifference = 0;

    if (type === "runway") {
      idx = runways.runway_idx;
    }

    if (type === "half") {
      idx = runways.half_idx;
    }

    if (type === "third") {
      idx = runways.third_idx;
    }

    if (runways.runway_idx === "") {
      return "over";
    } else {
      date.setMonth(date.getMonth() + idx);

      yearDifference = date.getFullYear() - earliestDate.getFullYear();
      monthDifference =
        date.getMonth() - earliestDate.getMonth() + yearDifference * 12;

      return width * monthDifference + tit_width;
    }
  };

  return (
    <div id="HistoryAnalyze">
      <div className="chart custom_scroll">
        {analyzeList.length > 0 ? (
          <>
            <div className="axisX" style={{ marginLeft: `${tit_width}px` }}>
              {Object.keys(groupedByYear(months)).length > 0 &&
                Object.keys(groupedByYear(months)).map((year) => (
                  <div key={year} className="year-container">
                    <div className="year-title">{year}년</div>
                    <div className="months">
                      {groupedByYear(months)[year].map((month) => (
                        <div
                          key={month}
                          className="month"
                          style={{ width: `${width}px` }}
                        >
                          {month}월
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            {analyzeList.map((list) => (
              <div key={list.report_id} className="analyze_list">
                <span
                  className="period_bg"
                  style={
                    getRunwaysPosition(list) !== "over"
                      ? {
                          width: `${
                            getRunwaysPosition(list, "runway") + 30 - tit_width
                          }px`,
                          left: `${tit_width}px`,
                        }
                      : { display: "none" }
                  }
                ></span>
                <div
                  className="runway_period"
                  style={{ width: `${tit_width}px` }}
                >
                  {list.runway_period}
                </div>
                {Object.keys(groupedByYear(months)).length > 0 &&
                  Object.keys(groupedByYear(months)).map((year) => (
                    <div key={year} className="sections">
                      <div className="months">
                        {groupedByYear(months)[year].map((month) => (
                          <div
                            key={month}
                            className={`month ${month}`}
                            style={{ width: `${width}px` }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  ))}
                <div className="marks">
                  <div
                    className="mark runway"
                    style={
                      getRunwaysPosition(list) !== "over"
                        ? {
                            left: `${
                              getRunwaysPosition(list, "runway") + 20
                            }px`,
                          }
                        : { display: "none" }
                    }
                  ></div>

                  <div
                    className="mark half"
                    style={
                      getRunwaysPosition(list) !== "over"
                        ? { left: `${getRunwaysPosition(list, "half") + 20}px` }
                        : { display: "none" }
                    }
                  ></div>

                  <div
                    className="mark third"
                    style={
                      getRunwaysPosition(list) !== "over"
                        ? {
                            left: `${getRunwaysPosition(list, "third") + 20}px`,
                          }
                        : { display: "none" }
                    }
                  ></div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="no_data">해당 기간의 히스토리가 없습니다.</p>
        )}
      </div>
      <div className="marks_info">
        <ul>
          <li>
            <div className="half"></div>
            <p>현금이 1/2 이하 남는 시점</p>
          </li>
          <li>
            <div className="third"></div>
            <p>현금이 1/3 이하 남는 시점</p>
          </li>
          <li>
            <div className="runway"></div>
            <p>런웨이 시점</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HistoryAnalyze;
