import "./css/Reference.scss";
import { useState } from "react";

const ReferenceIcon = () => {
  const [show, setShow] = useState(false);
  const ReferencePopup = () => {
    return (
      <div id="ReferencePopup">
        <div className="popup">
          <div className="header">
            <p>진출 목표시장 참고자료</p>
            <i className="icon_exit_gray" onClick={() => setShow(false)} />
          </div>
          {/*Nav Component (기존 제안서 <--> 추천 참고자료)*/}
          <div className="search">
            <div className="search_input">
              <input
                type="text"
                placeholder="더 자세한 참고자료를 원하시면 키워드를 추가해보세요."
              />
              <button>추가하기</button>
            </div>
            <div className="search_result">
              <div className="exist_word">
                <span>가나다라</span>
                <i className="icon_del_small_with_bg" />
              </div>
              <div className="new_word">
                <span>마바사</span>
                <i className="icon_del_small_with_bg_white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <i
          className="icon_reference"
          style={{ width: "75px" }}
          onClick={() => setShow(true)}
        />
      </div>
      {show && <ReferencePopup />}
    </>
  );
};

export default ReferenceIcon;
