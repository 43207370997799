import React, {useContext} from "react";
import NormalRow from "../row/NormalRow";
import FileRow from "../row/FileRow";
import ApplyField from "./common/ApplyField";
import ClaimField from "./common/ClaimField";
import InputRow from "../row/InputRow";
import BillingContext from "../../../store/Billing";
import CostField from "./common/CostField";
import BankField from "./common/BankField";

function CostDetailPatentOverseas({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    return (
        <div className="form">
            <div className="field">
                <div className="rows">
                    <NormalRow title="국가" content={window.$Global.convertNationCodeToStr(billObj.nation)}/>
                    <InputRow title="청구번호" titleClassName="color_blue" condition={edit} content={billObj.billing_number} onChange={e => billing.func.onChangeInput(e.target.value, "billing_number", billing)}/>
                    <FileRow title="첨부 파일" onClickUpPopShow={() => billing.func.onClickUpPopShow("attach_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("attach_file")}/>
                </div>
                <div className="rows">
                    <NormalRow title="대리인 관리번호" content={billObj.agent_manage_number}/>
                    <NormalRow title="출원인 관리번호" content={billObj.manage_number}/>
                    <NormalRow title="해외 대리인" titleClassName="color_blue" content={billObj.local_agent_company_name}/>
                </div>
                <div className="rows">
                    <NormalRow title="기업 담당자" content={billObj.manager_name}/>
                    <NormalRow title="대리인 담당자" titleClassName="color_blue" content={billObj.patent_agent_name}/>
                    <NormalRow title="고객명" content={billObj.company_info[0]?.name}/>
                </div>
            </div>
            <ApplyField/>
            <ClaimField edit={edit}/>
            <CostField edit={edit}/>
            <BankField edit={edit}/>
        </div>
    )
}

export default CostDetailPatentOverseas;
