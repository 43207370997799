/**
 zeplin: 해당 발명자 정보 페이지
 - 발명자의 특허 리스트를 보여주는 페이지
 **/

import Pagination from "../common/Pagination";
import "./css/InventorPatentList.scss";
import { useState } from "react";

const InventorPatentList = ({ history }) => {
  const [isEditing, setIsEditing] = useState(false);

  const GridInput = ({ titleText, placeholder, value, onChange }) => {
    return (
      <>
        <p>{titleText}</p>
        {isEditing ? (
          <input type="text" placeholder={placeholder} />
        ) : (
          <p>{value}</p>
        )}
      </>
    );
  };

  return (
    <div id="InventorPatentList">
      <div className="header">
        <h2>"{localStorage.getItem("company_name")}"</h2>
        {isEditing ? (
          <button onClick={() => setIsEditing(!isEditing)}>저장</button>
        ) : (
          <button onClick={() => setIsEditing(!isEditing)}>수정</button>
        )}
      </div>
      <div className="inventor_info_grid">
        <div>
          <GridInput titleText="발명자 이름" value="김고은" />
        </div>
        <div>
          <GridInput titleText="전화번호" value="01012345678" />
        </div>
        <div>
          <GridInput titleText="이메일" value="a1231@gooditl.com" />
        </div>
        <div>
          <GridInput
            titleText="주소"
            value="(우 46285)부산광역시 금정구 부산대학로 50번길 68, 피엔유 에이백 2층 비7호 (장전동)"
          />
        </div>
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="80px" />
            <col width="200px" />
            <col width="250px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>구분</th>
              <th>소속기관명</th>
              <th>특허번호</th>
              <th>발명의 명칭</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>대 학</td>
              <td>부산과학기술대학교 [본교]</td>
              <td>EP ITL14-0031-PC-EP-DE</td>
              <td>
                헤테로지니어스 네트워크의 허브 장치, 그리고 이의 부하 분산 방법
              </td>
              <td>2014-09-05</td>
            </tr>
            <tr>
              <td>1</td>
              <td>대 학</td>
              <td>부산과학기술대학교 [본교]</td>
              <td>EP ITL14-0031-PC-EP-DE</td>
              <td>
                헤테로지니어스 네트워크의 허브 장치, 그리고 이의 부하 분산 방법
              </td>
              <td>2014-09-05</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
};

export default InventorPatentList;
