import React, {useContext} from "react";
import InputRow from "../../row/InputRow";
import InputAndFileRow from "../../row/InputAndFileRow";
import NormalRow from "../../row/NormalRow";
import BillingContext from "../../../../store/Billing";
import {patentCostTypeValueInfo} from "../../../../types/patentCostType";

function reg_or_maker (list) {
    let reg = '';

    list.forEach((item, i, self) => {
        reg += `^${patentCostTypeValueInfo[item.toString()]}$`;
        if(parseInt(i)+1 !== self.length) reg += "|";
    });

    reg = reg.replace(/\(/g, '').replace(/\)/g, '');

    return new RegExp(reg);
}

function CostField({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;
    const sum_surtax = billObj.surtax + billObj.for_surtax;

    const { type } = billObj;
    const type_name = patentCostTypeValueInfo[type.toString()].replace(/\(/g, '').replace(/\)/g, '');

    let tag = [];
    let common_tag =
        <>
            <div className="rows">
                <InputRow title="대리인 수수료" titleClassName="color_blue"  content={billObj.agent_commission} type="number"
                          condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "agent_commission", billing)}
                />
                <InputAndFileRow title="관납료" content={billObj.gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                <NormalRow title="부가세" titleClassName="color_blue" content={billObj.surtax} txtType="number"/>
            </div>
            <div className="rows">
                <div className="row no_data"/>
                <div className="row no_data"/>
                <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
            </div>
        </>;

    if (reg_or_maker([1,2,3,4,40,42,44,46,47,49,51,53]).test(type_name)) {
        tag =
            <>
                <div className="field">
                    <div className="rows">
                        <InputRow title="전체 청구항 수" content={billObj.total_claim_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "total_claim_cnt", billing)}/>
                        <InputRow title="독립항 수" content={billObj.independent_claim_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "independent_claim_cnt", billing)}/>
                        <InputRow title="종속항 수" content={billObj.dependent_claim_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "dependent_claim_cnt", billing)}/>
                    </div>
                    <div className="rows">
                        <InputRow title="명세서 페이지 수" content={billObj.statement_page_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "statement_page_cnt", billing)}/>
                        <InputRow title="도면 수" content={billObj.drawing_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "drawing_cnt", billing)}/>
                    </div>
                </div>
                <div className="field">
                    <div className="rows">
                        <InputRow title="기본료" titleClassName="color_blue" content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                        <InputRow title="수수료 할인율 (%)" content={billObj.commission_dc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "commission_dc", billing)}/>
                    </div>
                    <div className="rows">
                        <InputRow title="전체 청구항 가산금" content={billObj.total_claim_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "total_claim_ec", billing)}/>
                        <InputRow title="독립항 가산금" content={billObj.independent_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "independent_ec", billing)}/>
                        <InputRow title="종속항 가산금" content={billObj.dependent_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "dependent_ec", billing)}/>
                    </div>
                    <div className="rows">
                        <InputRow title="명세서 페이지 가산금" content={billObj.statement_page_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "statement_page_ec", billing)}/>
                        <InputRow title="도면 가산금" content={billObj.drawing_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "drawing_ec", billing)}/>
                        <InputRow title="Time Charge 가산금" content={billObj.time_charge_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "time_charge_ec", billing)}/>
                    </div>
                </div>
                <div className="field">
                    <div className="rows">
                        <InputAndFileRow title="관납료" content={billObj.gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                        <InputAndFileRow title="기타 비용" content={billObj.etc_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "etc_fee", billing)} onClickUpPopShow={() => billing.func.onClickUpPopShow("etc_fee_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("etc_fee_file")}/>
                    </div>
                    <div className="rows">
                        <NormalRow title="수수료 합계" titleClassName="color_blue" content={billObj.sum_commission} contentClassName="color_blue" txtType="number"/>
                        <NormalRow title="부가세" titleClassName="color_blue" content={billObj.surtax} txtType="number"/>
                        <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    </div>
                </div>
            </>;
    } else if (reg_or_maker([5]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="심사 청구항 수" content={billObj.screen_claim_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "screen_claim_cnt", billing)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([6]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="대리인 수수료" titleClassName="color_blue"  content={billObj.agent_commission} type="number"
                              condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "agent_commission", billing)}
                    />
                    <InputAndFileRow title="관납료" content={billObj.gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={billObj.surtax} txtType="number"/>
                </div>
                <div className="rows">
                    <InputRow title="기타 비용" content={billObj.etc_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "etc_fee", billing)}/>
                    <div className="row no_data"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([8]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="기간 연장 차수" content={billObj.ed_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "ed_cnt", billing)}/>
                    <div className="row no_data"/>
                    <div className="row no_data"/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([9]).test(type_name)) {
        tag =
            <div className="field">
                {common_tag}
            </div>
    } else if (reg_or_maker([7, 10]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="OA 차수" content={billObj.oa_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "oa_cnt", billing)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([11]).test(type_name)) {
        tag =
            <div className="field">
                {common_tag}
            </div>
    } else if (reg_or_maker([12]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="심판 종류" content={billObj.referee_type} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_type", billing)}/>
                    <InputRow title="심판 번호" content={billObj.referee_number} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([13]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="심판 종류" content={billObj.referee_type} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_type", billing)}/>
                    <InputRow title="심판 번호" content={billObj.referee_number} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                    <InputRow title="의견서 제출 차수" content={billObj.submission_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "submission_cnt", billing)}/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([14]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="심판 종류" content={billObj.referee_type} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_type", billing)}/>
                    <InputRow title="심판 번호" content={billObj.referee_number} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                    <InputRow title="답변서 제출 차수" content={billObj.submission_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "submission_cnt", billing)}/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([15]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="심판 종류" content={billObj.referee_type} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_type", billing)}/>
                    <InputRow title="심판 번호" content={billObj.referee_number} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                    <InputRow title="판결일" content={billObj.judgment_at} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "judgment_at", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="판결 결과" content={billObj.judgement_result} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([16]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="납부 연차" content={billObj.pay_year} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "pay_year", billing)}/>
                    <InputRow title="전체 청구항 수" content={billObj.total_claim_cnt} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "total_claim_cnt", billing)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                <div className="rows">
                    <InputRow title="판결 결과" content={billObj.judgement_result} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "referee_number", billing)}/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([17]).test(type_name)) {
        tag =
            <div className="field">
                {common_tag}
            </div>
    } else if (reg_or_maker([18]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="변경 내용" content={billObj.change_description} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "change_description", billing)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([19]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="업무 내용" content={billObj.work_description} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "work_description", billing)}/>
                    <InputRow title="특허사무소 담당자" content={billObj.agent_name} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "agent_name", billing)}/>
                    <InputRow title="업무 시간" content={billObj.work_hour} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "work_hour", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="Hourly Rate 가산금" content={billObj.work_hour_rate} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "work_hour_rate", billing)}/>
                    <InputRow title="Time Charge 가산금" content={billObj.time_charge_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "time_charge_ec", billing)}/>
                </div>
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputAndFileRow title="기타 비용" content={billObj.etc_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "etc_fee", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                </div>
                <div className="rows">
                    <NormalRow title="수수료 합계" titleClassName="color_blue" content={billObj.sum_commission} contentClassName="color_blue" txtType="number"/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={billObj.surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([20,39,41,43,45,48,50,52,54,55]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="업무 내용" content={billObj.work_description} condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "work_description", billing)}/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                </div>
                {common_tag}
            </div>
    } else if (reg_or_maker([0, 21]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="선행기술 조사비용" content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([22]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`출원비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`출원비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="도면작성료" content={billObj.drawing_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "drawing_ec", billing)}/>
                    <InputRow title="번역료" content={billObj.for_translate_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_translate_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([23]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`심사청구비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`심사청구비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([24]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`우선심사 신청비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`우선심사 신청비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([25,26,27]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`출원비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`출원비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([28]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`개별국 진입비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`개별국 진입비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([29]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`OA대응 비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`OA대응 비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([30]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`출원 유지료\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`출원 유지료\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([31]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`IDS제출 비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`IDS제출 비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([32]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`자진보정 비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`자진보정 비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([33]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`등록 비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`등록 비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([34]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`기타 비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`기타 비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="해외송금수수료" content={billObj.for_remittance_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_remittance_fee", billing)}/>
                </div>
                <div className="rows">
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                    <NormalRow title="해외 비용 합계" titleClassName="color_blue" content={billObj.for_sum_total} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([35]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title={`기타 비용\n(국내대리인)(T/C)`} content={billObj.etc_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "etc_fee", billing)}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([36]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputRow title="선행기술 조사비용" content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([37]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`출원비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="도면작성료" content={billObj.drawing_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "drawing_ec", billing)}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    } else if (reg_or_maker([38]).test(type_name)) {
        tag =
            <div className="field">
                <div className="rows">
                    <InputAndFileRow title="관납료" content={billObj.for_gc} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_gc", billing)}  onClickUpPopShow={() => billing.func.onClickUpPopShow("gf_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("gf_file")}/>
                    <InputRow title={`출원비용\n(해외대리인)`} content={billObj.for_basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_basic_fee", billing)}/>
                    <InputRow title={`출원비용\n(국내대리인)`} content={billObj.basic_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "basic_fee", billing)}/>
                </div>
                <div className="rows">
                    <InputRow title="도면작성료" content={billObj.drawing_ec} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "drawing_ec", billing)}/>
                    <InputRow title="번역료" content={billObj.for_translate_fee} type="number" condition={edit} onChange={e => billing.func.onChangeInput(e.target.value, "for_translate_fee", billing)}/>
                    <NormalRow title="부가세" titleClassName="color_blue" content={sum_surtax} txtType="number"/>
                </div>
                <div className="rows">
                    <div className="row no_data"/>
                    <div className="row no_data"/>
                    <NormalRow title="국내 비용 합계" titleClassName="color_blue" content={billObj.sum_total_domestic} contentClassName="color_red" txtType="number"/>
                </div>
            </div>
    }
    return tag;
}

export default CostField;
