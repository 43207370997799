import React, {useState} from "react";
import qs from 'query-string';
import { Link } from 'react-router-dom';
import './css/SignupTerms.scss';
import CheckBox from "../common/CheckBox";
import Terms from "../../const/Terms";

function SignupTerms({location}) {

    const [agreeUse, setAgreeUse] = useState(false);
    const [agreePrivate, setAgreePrivate] = useState(false);
    const [agreeAll, setAgreeAll] = useState(false);

    const [moreType, setMoreType] = useState(0);

    const onChangeSortChecked = () => {
        setAgreeUse(!agreeAll);
        setAgreePrivate(!agreeAll);
        setAgreeAll(!agreeAll);
    };

    const onClickAgree = () => {
        if (moreType == 1) {
            setAgreeUse(true);
        } else if (moreType == 2) {
            setAgreePrivate(true);
        }
        setMoreType(0);
    };

    return (
        <div id="SignupTerms">
            <h2 className="page_title">약관동의</h2>
            <div className="pc">
                <div className="wrap_term">
                    <p className="desc">IPNOW 이용약관동의</p>
                    <div className="term" dangerouslySetInnerHTML={ {__html: Terms.useTxt} }/>
                    <CheckBox text="동의함" checked={agreeUse} onChangeChecked={() => setAgreeUse(!agreeUse)}/>
                </div>
                <div className="wrap_term">
                    <p className="desc">개인정보 수집 및 이용에 대한 안내</p>
                    <div className="term" dangerouslySetInnerHTML={ {__html: Terms.privateTxt} }/>
                    <CheckBox text="동의함" checked={agreePrivate} onChangeChecked={() => setAgreePrivate(!agreePrivate)}/>
                    <CheckBox text="모두 동의함" onChangeChecked={onChangeSortChecked}/>
                </div>
            </div>
            <div className="mo">
                <div className="tab">
                    <div className="tab_title">
                        {
                            (moreType == 0) &&
                            <>
                                <CheckBox checked={agreeAll} onChangeChecked={onChangeSortChecked}/>
                                <div className="title">
                                    <p>전체동의</p>
                                </div>
                            </>
                        }
                        {
                            (moreType == 1) &&
                            <div className="title">
                                <p>(필수)서비스 이용약관</p>
                            </div>
                        }
                        {
                            (moreType == 2) &&
                            <div className="title">
                                <p>(필수)개인정보 수집 및 이용에 대한 안내</p>
                            </div>
                        }
                    </div>
                    <ul className={`tab_content ${moreType > 0 ? 'agree' : ''}`}>
                        {
                            (moreType == 0) &&
                            <>
                                <li>
                                    <CheckBox checked={agreeUse} onChangeChecked={() => setAgreeUse(!agreeUse)}/>
                                    <p className="txt">(필수)서비스 이용약관</p>
                                    <button className="more" onClick={() => setMoreType(1)}>자세히</button>
                                </li>
                                <li>
                                    <CheckBox checked={agreePrivate} onChangeChecked={() => setAgreePrivate(!agreePrivate)}/>
                                    <p className="txt">(필수)개인정보 수집 및 이용에 대한 안내</p>
                                    <button className="more" onClick={() => setMoreType(2)}>자세히</button>
                                </li>
                            </>
                        }
                        {
                            (moreType == 1) &&
                            <li>
                                <p className="txt agree" dangerouslySetInnerHTML={ {__html: Terms.useTxt} }/>
                            </li>
                        }
                        {
                            (moreType == 2) &&
                            <li>
                                <p className="txt agree" dangerouslySetInnerHTML={ {__html: Terms.privateTxt} }/>
                            </li>
                        }
                    </ul>
                </div>

                {
                    moreType > 0 &&
                    <div className="btns">
                        <button className="btn btn_no" onClick={() => setMoreType(0)}>뒤로</button>
                        <button className="btn" onClick={onClickAgree}>동의</button>
                    </div>
                }
            </div>
            {
                moreType == 0 &&
                <div className="btns">
                    <Link className={`btn btn_prev`} to={`/signup/type`}>이전</Link>
                    <Link className={`btn btn_next_step ${(agreeUse && agreePrivate) ? '' : 'btn_no'}`} to={`/signup/input?type=${qs.parse(location.search).type}`}>다음</Link>
                </div>
            }
        </div>
    );
}

export default SignupTerms;
