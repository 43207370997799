import React from "react";
import CostList from "../components/costManage/CostList";
import UserAuthRoute from "./common/UserAuthRoute";
import BillingView from "../components/costManage/BillingView";
import CostDetail from "../components/costManage/CostDetail";
import CostInputList from "../components/costManage/CostInputList";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";

function CostRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`}
						render={(props) => window.$Global.renderFunc(props, CostList)}
						when="always"
			/>
			<UserAuthRoute exact path={`${match.path}/billing/:type/:type_idx/:idx`} requireAuth={true} component={BillingView}/>
			<UserAuthRoute exact path={`${match.path}/detail/:type/:type_idx/:cost_idx`} requireAuth={true} component={CostDetail}/>
			<CacheRoute exact path={`${match.path}/input`}
						render={(props) => window.$Global.renderFunc(props, CostInputList)}
						when="always"
			/>
		</CustomSwitch>
	)
}

export default CostRoute;
