import React, { useContext, useState } from "react";
import "./css/Chat.scss";
import ChatIcon from "./ChatIcon";
import ChatRoom from "./ChatRoom";
import memberContext from "../../store/Member";

function UserChat() {
  const { auth } = useContext(memberContext);
  const [chatRoomShow, setChatRoomShow] = useState(false);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isLogin = auth != null && auth != undefined;

  if (!isLogin || isViewTypeIpnow) return null;

  return (
    <div id="Chat">
      <div className="pc">
        {chatRoomShow ? (
          <ChatRoom onClickClose={() => setChatRoomShow(false)} />
        ) : (
          <ChatIcon notify={false} onClick={() => setChatRoomShow(true)} />
        )}
      </div>
    </div>
  );
}

export default UserChat;
