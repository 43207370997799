import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './css/BannerOneClick.scss';
import links from "../../const/links";

function BannerOneClick() {
	const history = useHistory();
	const [keyword, setKeyword] = useState('');

	const onClickSearch = () => {
        history.push(`taskManage/list?searchKeyword=${keyword}`);
    }
	return (
		<div id="BannerOneClick" className="oneclick">
			<h2 className="oneclick_title">모든 공공사업&middot;과제 ONE Click 찾기</h2>
			<p className="oneclick_text">
				Bizinfo, Smtech, NTIS, 조달청 포함 1500여 개 공공기관의<br />
				사업, 과제와 민간사업까지 모두 정리 해 드립니다. 
			</p>
			<div className="search">
				<div className="search_links">
					<Link to={links.taskManage} className="search_links_btn" alt="사업 리스트">
					</Link>
				</div>
				<div className="search_form">
					<label htmlFor="totalSearch" className="hidden">모든 공공사업&middot;과제 ONE Click 검색 입력</label>
					<input 
						type="text"
						id="totalSearch"
						className="search_form_input"
						name="totalSearch"
						placeholder="사업제목 + 사업내용을 검색하세요"
						onChange={e => setKeyword(e.target.value)}
						onKeyUp={(e) => e.key === 'Enter' && onClickSearch()}
					/>
					<button className="icon_search search_form_btn" onClick={onClickSearch}>
						모든 공공사업&middot;과제 ONE Click 검색하기
					</button>
				</div>
				<Link to="/taskManage/setting" className="search_notice"/>
			</div>
		</div>
	);
}

export default BannerOneClick;