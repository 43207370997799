import "./css/MarketInfoUpdateChart.scss";
import CommonAPI from "../../API/common";
import { useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { Bar, Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import _ from "lodash";
import MemberContext from "../../store/Member";
import AdminAPI from "../../API/admin";
import { useRecoilState, useRecoilValue } from "recoil";
import { marketInfoCartArrayLength } from "../../atom";
import qs from "query-string";

const baseChartData = {
  color: ["#000000", "#000000"],
  datasets: [
    ["12", "15"],
    ["10", "15"],
  ],
  labels: ["기업A", "기업B"],
  type: ["bar", "bar"],
  x: ["2019", "2020"],
};

const MarketInfoUpdateChart = () => {
  const location = useLocation();
  const { admin } = useContext(MemberContext);
  const { isPostType, idx } = qs.parse(location.search);
  const chartRef = useRef(); // 차트 update를 위한 Ref
  const imageCaptureRef = useRef(); // 이미지 캡쳐를 위한 Ref
  const [chartData, setChartData] = useState({}); // 수정할 차트 데이터
  const [drawChartData, setDrawChartData] = useState({}); // 차트 그릴때 필요한 데이터
  const [drawDefaultChartData, setDrawDefaultChartData] = useState({}); // 원본 차트를 그리기위한 데이터
  const [defaultChartData, setDefaultChartData] = useState({}); // 차트 원상복구를 위한 데이터
  const [defaultChartContent, setDefaultChartContent] = useState(""); // 그래프 관련글 복원을 위한 데이터
  const [displayColorPicker, setDisplayColorPicker] = useState([]); // 컬러변경 Button에서 상태관리를 위한 데이터
  const [cartArrLength, setCartArrLength] = useRecoilState(
    marketInfoCartArrayLength
  );
  const [isChecked, setIsChecked] = useState(false);
  const [defaultOption, setDefaultOption] = useState({});
  let customOption = {
    legend: {
      position: "bottom",
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      xAxes: [
        {
          stacked: isChecked,
        },
      ],
      yAxes: [
        {
          stacked: isChecked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  let baseOption = {
    legend: {
      position: "bottom",
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      xAxes: [
        {
          stacked: false,
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  //API를 호출하여 초기 값 세팅
  useEffect(() => {
    if (isPostType === "post") {
      getChartData();
    } else if (isPostType === "edit") {
      getEditChartData();
    }
  }, []);

  //chartData가 변경될때 마다 다시 그리기 위함
  useEffect(() => {
    drawChart();
  }, [chartData]);

  //defaultChartData가 들어오고 나서 원본 그래프에 그리기 위함
  useEffect(() => {
    drawDefaultChart();
  }, [defaultChartData]);

  const getEditChartData = () => {
    CommonAPI.getMarketImage(idx).then((res) => {
      const chartDataForCustom = _.cloneDeep(res.data);
      const chartDataForDefault = _.cloneDeep(res.data);
      customOption = chartDataForCustom.chart_option;
      setChartData(chartDataForCustom);
      setDefaultChartData(chartDataForDefault);
      setDefaultOption(chartDataForDefault.chart_option);
      displayColorPicker.fill(false, 0, res.data.chart_data.labels.length);
      setDisplayColorPicker(displayColorPicker);
      setDefaultChartContent(res.data.content);
      setIsChecked(chartDataForCustom.chart_option.scales.xAxes[0].stacked);
    });
  };

  const getChartData = () => {
    CommonAPI.getMarketInfo(idx).then((res) => {
      if (res.data.chart_data === null) {
        const chartDataForCustom = _.cloneDeep(baseChartData);
        const chartDataForDefault = _.cloneDeep(baseChartData);
        setChartData({
          ...res.data,
          chart_data: chartDataForCustom,
        });
        setDefaultChartData({
          ...res.data,
          chart_data: chartDataForDefault,
        });
        displayColorPicker.fill(false, 0, 2);
        setDisplayColorPicker(displayColorPicker);
        setDefaultOption(baseOption);
      } else {
        const chartDataForCustom = _.cloneDeep(res.data);
        const chartDataForDefault = _.cloneDeep(res.data);
        customOption = chartDataForCustom.chart_option;
        setDefaultOption(chartDataForDefault.chart_option);
        setChartData(chartDataForCustom);
        setDefaultChartData(chartDataForDefault);
        displayColorPicker.fill(false, 0, res.data.chart_data.labels.length);
        setDisplayColorPicker(displayColorPicker);
        setIsChecked(chartDataForCustom.chart_option.scales.xAxes[0].stacked);
      }
      setDefaultChartContent(res.data.content);
    });
  };

  const dataURLtoFile = (dataurl, fileName) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  const addCart = async () => {
    const canvas = await html2canvas(document.getElementById("screenShot"));
    const image = canvas.toDataURL("image/png", 1.0);

    let formData = new FormData();
    let imageFile = dataURLtoFile(image, "test.png");

    formData.append("file", imageFile);
    formData.append("chart_data", JSON.stringify(chartData.chart_data));
    formData.append("chart_option", JSON.stringify(customOption));
    formData.append("chart_type", chartData.chart_type);
    formData.append("row_name", "123");
    formData.append("column_name", "가나다");

    if (cartArrLength < 8) {
      let idx = isPostType === "post" ? chartData.idx : chartData.mi_idx;
      CommonAPI.addCustomChart(idx, formData).then(() => {
        setCartArrLength(() => cartArrLength + 1);
        alert("장바구니에 등록되었습니다.");
      });
    } else {
      alert("최대 8개까지 담을 수 있습니다.");
    }
  };

  const drawChart = () => {
    const xLabelArr = chartData?.chart_data?.x;
    const saveDataArr = [];
    chartData?.chart_data?.datasets.map((item, index) => {
      const obj = {
        label: chartData.chart_data.labels[index],
        data: [...item],
        fill: false,
        type: chartData.chart_data.type[index] || "bar",
        backgroundColor: chartData?.chart_data?.color[index],
        borderColor: chartData?.chart_data?.color[index],
      };
      saveDataArr.push(obj);
    });
    setDrawChartData({ labels: xLabelArr, datasets: saveDataArr });
    chartRef.current.chartInstance.chart.update();
  };

  const drawDefaultChart = () => {
    const xLabelArr = defaultChartData?.chart_data?.x;
    const saveDataArr = [];
    defaultChartData?.chart_data?.datasets.map((item, index) => {
      const obj = {
        label: defaultChartData.chart_data.labels[index],
        data: [...item],
        fill: false,
        type: chartData.chart_data.type[index] || "bar",
        backgroundColor: defaultChartData?.chart_data?.color[index],
        borderColor: defaultChartData?.chart_data?.color[index],
      };
      saveDataArr.push(obj);
    });
    setDrawDefaultChartData({ labels: xLabelArr, datasets: saveDataArr });
  };

  const handleContentChange = (e) => {
    const { name, value } = e.target;
    setChartData({ ...chartData, [name]: value });
  };

  const handleColorPickerClick = (idx) => {
    displayColorPicker[idx] = true;
    setDisplayColorPicker([...displayColorPicker]);
  };

  const handleColorPickerClose = () => {
    displayColorPicker.fill(false);
    setDisplayColorPicker([...displayColorPicker]);
  };

  const handleColorChange = (color, idx) => {
    chartData.chart_data.color[idx] = color.hex;
    setChartData({ ...chartData });
  };

  const handleDataChange = (e, idx, child_idx) => {
    const { name, value } = e.target;
    if (name === "x") {
      chartData.chart_data.x[child_idx] = value;
    } else if (name === "datasets") {
      chartData.chart_data.datasets[idx][child_idx] = value;
    } else if (name === "labels") {
      chartData.chart_data.labels[idx] = value;
    }
    setChartData({ ...chartData });
  };

  const handleTypeChange = (e, idx) => {
    chartData.chart_data.type[idx] = e.target.name;
    setChartData({ ...chartData });
  };

  const handleStackedChange = (e) => {
    setIsChecked((prev) => !prev);
  };
  const addDataSet = () => {
    const { color, datasets, labels, type } = chartData.chart_data;
    const randomNum = Math.floor(Math.random() * 1000);
    let arr = Array(datasets[0].length).fill("10");

    if (labels.length >= 8) {
      alert("최대 데이터셋의 개수는 8개 입니다.");
      return;
    }

    color.push("#000000");
    labels.push(`기업${randomNum}`);
    type.push(null);
    datasets.push(arr);
    setChartData({ ...chartData });
  };

  const addRow = () => {
    const { datasets, x } = chartData.chart_data;
    if (x.length >= 10) {
      alert("최대 10개 까지 추가 가능합니다.");
      return;
    }

    x.push("number");
    datasets.map((item, index) => {
      datasets[index].push("10");
    });
    setChartData({ ...chartData });
  };

  const deleteDataSet = (idx) => {
    const { datasets, labels } = chartData.chart_data;
    labels.splice(idx, 1);
    datasets.splice(idx, 1);
    setChartData({ ...chartData });
  };

  const deleteRow = (idx, child_idx) => {
    const { datasets, x } = chartData.chart_data;
    x.splice(child_idx, 1);
    datasets.map((item) => {
      item.splice(child_idx, 1);
    });
    setChartData({ ...chartData });
  };

  const restoreContent = () => {
    if (defaultChartContent === null) {
      chartData.content = "";
    } else {
      chartData.content = defaultChartContent;
    }
    setChartData({ ...chartData });
  };

  const restoreChart = () => {
    const chartDataForDefault = _.cloneDeep(defaultChartData.chart_data);
    setChartData({ ...chartData, chart_data: chartDataForDefault });
  };

  const copyDOM = async () => {
    const canvas = await html2canvas(document.getElementById("screenShot"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, chartData.title);
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  const saveChartData = () => {
    let copy = _.cloneDeep(chartData);
    let keys = Object.keys(copy);
    const formData = new FormData();

    keys.forEach((key) => {
      if (
        key === "category_list" ||
        key === "category1_idx" ||
        key === "category2_idx" ||
        key === "category3_idx"
      ) {
        return;
      }

      if (key === "chart_data") {
        formData.append(key, JSON.stringify(chartData.chart_data));
      } else if (key === "chart_option") {
        formData.append(key, JSON.stringify(customOption));
      } else if (key === "row_name") {
        formData.append(key, "123");
      } else if (key === "column_name") {
        formData.append(key, "가나다");
      } else if (copy[key] !== null) {
        formData.append(key, copy[key]);
      }
    });

    let list = [
      {
        category1_idx: copy.category_list[0].category1_idx,
        category2_idx: copy.category_list[0].category2_idx,
        category3_idx: copy.category_list[0].category3_idx,
      },
    ];
    formData.append("category_list", JSON.stringify(list));

    AdminAPI.updateMarketInfo(chartData.idx, formData).then((res) => {
      alert("차트 데이터가 저장되었습니다.");
      window.location.reload();
    });
  };

  return (
    <div id="MarketInfoUpdateChart">
      <div className="chart_section">
        <header>
          <h2>시장정보 차트 만들기</h2>
          <h5>시장정보 차트를 내 맘대로 만들어 보세요</h5>
        </header>
        <section>
          <article className="update_chart_wrapper">
            <div className="update_chart_header article_header">
              <p>시장정보 제목 및 그래프 수정하기</p>
              <p>행과 열 추가를 하여 차트를 자유롭게 만들어 보세요.</p>
            </div>
            {isPostType === "post" && (
              <div className="graph_input">
                <p>그래프 제목</p>
                <input
                  type="text"
                  name="title"
                  value={chartData.title}
                  onChange={handleContentChange}
                />
              </div>
            )}
            <div className="graph_input show_graph">
              <p>그래프 보기</p>
              <div
                ref={imageCaptureRef}
                id="screenShot"
                className="graph_section"
              >
                {chartData.chart_type === 0 || chartData.chart_type === 1 ? (
                  <Bar
                    ref={chartRef}
                    width={500}
                    height={500}
                    data={drawChartData}
                    options={customOption}
                  />
                ) : (
                  <Line
                    ref={chartRef}
                    width={500}
                    height={500}
                    data={drawChartData}
                    options={customOption}
                  />
                )}
              </div>
            </div>
          </article>
          <article className="chart_data_wrapper">
            <div className="chart_data_header article_header">
              <p>그래프 행과 열 추가</p>
              <div className="chart_add_btn_wrapper">
                <div className="chart_stacked_btn">
                  <label htmlFor="stacked">
                    <input
                      id="stacked"
                      type="checkbox"
                      checked={isChecked}
                      onClick={(e) => handleStackedChange(e)}
                    />
                    {isChecked ? (
                      <span>차트 분리하기</span>
                    ) : (
                      <span>차트 합치기</span>
                    )}
                  </label>
                </div>
                <button onClick={() => addDataSet()}>데이터 셋 추가</button>
                <button onClick={() => addRow()}>열 추가</button>
              </div>
            </div>
            <div className="chart_data_body">
              {chartData?.chart_data?.labels.map((item, idx) => {
                const { color, datasets, labels, type, x } =
                  chartData.chart_data;
                return (
                  <div className="chart_data_item">
                    <div className="chart_data_item_aside">
                      <div className="graph_title">
                        <input
                          type="text"
                          name="labels"
                          value={labels[idx]}
                          onChange={(e) => handleDataChange(e, idx)}
                        />
                        <i
                          className="icon_del_small_with_bg"
                          onClick={() => deleteDataSet(idx)}
                        />
                      </div>
                      <div
                        className="graph_color"
                        style={{ position: "relative" }}
                      >
                        <p>그래프 컬러:</p>
                        <div
                          className="show_color"
                          style={{
                            backgroundColor: color[idx],
                          }}
                        />
                        <button onClick={() => handleColorPickerClick(idx)}>
                          컬러 변경
                        </button>
                        {displayColorPicker[idx] && (
                          <div className="color_popover">
                            <div
                              className="color_cover"
                              onClick={handleColorPickerClose}
                            />
                            <SketchPicker
                              color={color[idx][0]}
                              onChange={(color) =>
                                handleColorChange(color, idx)
                              }
                            />
                          </div>
                        )}
                      </div>
                      {admin && (
                        <div className="graph_type">
                          <button
                            name="bar"
                            onClick={(e) => handleTypeChange(e, idx)}
                          >
                            Bar Type 변경
                          </button>
                          <button
                            name="line"
                            onClick={(e) => handleTypeChange(e, idx)}
                          >
                            Line Type 변경
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="chart_data_item_body">
                      {datasets[idx].map((child_item, child_idx) => {
                        return (
                          <div className="chart_data_item_body_list">
                            <div className="column_title">
                              <input
                                type="text"
                                name="x"
                                value={x[child_idx]}
                                onChange={(e) =>
                                  handleDataChange(e, idx, child_idx)
                                }
                              />
                              <i
                                className="icon_del_small_with_bg"
                                onClick={() => deleteRow(idx, child_idx)}
                              />
                            </div>
                            <input
                              type="text"
                              name="datasets"
                              className="column_data"
                              value={child_item}
                              onChange={(e) =>
                                handleDataChange(e, idx, child_idx)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </article>
          {isPostType === "post" && (
            <article className="graph_content_wrapper">
              <div className="graph_content_header article_header">
                <p>그래프 관련글 수정하기</p>
              </div>
              <div className="graph_content_body">
                <div>
                  <p>설명란</p>
                  <button onClick={() => restoreContent()}>복원하기</button>
                </div>
                <textarea
                  name="content"
                  value={chartData.content}
                  onChange={handleContentChange}
                />
              </div>
            </article>
          )}
        </section>
        <footer>
          <button
            className="graph_image"
            onClick={() => copyDOM(imageCaptureRef.current)}
          >
            수정한 그래프 이미지로 추출하기
          </button>
          <button className="graph_cart" onClick={() => addCart()}>
            수정한 그래프 장바구니에 담기
          </button>
        </footer>
      </div>
      <aside>
        <h2>그래프 확인하기</h2>
        <div className="aside_body">
          <div className="aside_body_title">
            <h5>원본 그래프</h5>
            <button onClick={() => restoreChart()}>
              원본 그래프로 복원하기
            </button>
          </div>
          <div className="default_graph">
            {chartData.chart_type === 0 || chartData.chart_type === 1 ? (
              <Bar
                width={200}
                height={300}
                data={drawDefaultChartData}
                options={defaultOption}
              />
            ) : (
              <Line
                width={200}
                height={300}
                data={drawDefaultChartData}
                options={defaultOption}
              />
            )}
          </div>
        </div>
        <div className="aside_footer">
          <button onClick={() => copyDOM(imageCaptureRef.current)}>
            수정한 그래프 이미지로 추출하기
          </button>
          <button onClick={() => addCart()}>장바구니에 담기</button>
          {admin && (
            <button onClick={() => saveChartData()}>
              차트 데이터 저장하기
            </button>
          )}
        </div>
      </aside>
    </div>
  );
};

export default MarketInfoUpdateChart;
