import React from "react";
import ExpositionMain from "../components/exposition/ExpositionMain";
import UserAuthRoute from "./common/UserAuthRoute";
import ExpositionSub from "../components/exposition/ExpositionSub";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";

function ExpositionRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`}
						component={ExpositionMain}
						when="always"
			/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={false} component={ExpositionSub}/>
		</CustomSwitch>
	)
}

export default ExpositionRoute;