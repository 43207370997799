import React, { useState, useEffect, useContext } from "react";
import "./UnEventView.scss";
import UnEventAPI from "../../API/unevent/index";
import _ from "lodash";
import UnEventContext from "../../store/UnEvent";
import memberContext from "../../store/Member";

function UnEventView({ history }) {
  const [responseData, setResponseData] = useState([]);
  const [toastStatus, setToastStatus] = useState(true);
  const memberInfo = useContext(memberContext);
  const isComapny = window.$Global.checkAuth("특허회사", memberInfo.auth);

  const UnEventToast = () => {
    return (
      <div className="event_toast" onClick={() => setToastStatus(false)}>
        <div className="event_toast_text">
          <i className="icon_badge_info_white" />
          <p>알림 이벤트를 클릭하시면, 읽음 처리됩니다.</p>
        </div>
        <div className="event_toast_del">
          <i className="icon_del_small_white" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setToastStatus(false);
    }, 3000);
    let idx = isComapny ? null : memberInfo.manage_company_idx;

    UnEventAPI.getNotification(idx).then((res) => {
      setResponseData(res.data.filter((item) => item.state != -1));
    });
  }, []);

  const onClickConfirm = (idx, index, state, callback) => {
    if (state == 1) return;
    UnEventAPI.notifyConfirm(idx).then(() => {
      let copy = _.cloneDeep(responseData);
      copy[index].state = 1;
      setResponseData(copy);
      callback.setUnEvent(copy.filter((item) => item.state == 0).length);
    });
  };

  const onClickDelete = (e, idx, index) => {
    e.stopPropagation();
    if (window.confirm("삭제하시겠습니까?")) {
      UnEventAPI.notifyDelete(idx).then(() => {
        let copy = _.cloneDeep(responseData);
        copy.splice(index, 1);
        setResponseData(copy);
      });
    }
  };

  const onClickAllConfirm = () => {
    let check = window.confirm("알림을 모두 읽음처리 하시겠습니까?");
    {
      check &&
        Promise.all(
          responseData.map((el, index) => {
            UnEventAPI.notifyConfirm(el.idx).then(() => {
              let copy = _.cloneDeep(responseData);
              copy[index].state = 1;
              setResponseData(copy);
            });
          })
        ).then(() => window.location.replace("/unEvent"));
    }
  };

  let event_length = responseData.filter((item) => item.state != -1).length;
  let no_read_event_length = responseData.filter(
    (item) => item.state == 0
  ).length;

  let tbody = responseData.map((item, index) => {
    return (
      <UnEventContext.Consumer>
        {(unEvent) => (
          <tr
            key={index}
            onClick={() => onClickConfirm(item.idx, index, item.state, unEvent)}
          >
            <td>
              <div>
                <p className={`circle ${item.state == 0 ? "new" : ""}`} />
                <span>
                  <b>
                    {Boolean(item.manage_number) && `[${item.manage_number}]`}
                  </b>
                  {`${item.etc} ${item.note ? `(${item.note})` : ""}`}
                </span>
              </div>
            </td>
            <td>
              <div>
                <span>{window.$Global.convertDate(item.created_at)}</span>
              </div>
            </td>
            <td>
              <button
                className="icon_del_small_with_bg"
                onClick={(e) => onClickDelete(e, item.idx, index)}
              />
            </td>
          </tr>
        )}
      </UnEventContext.Consumer>
    );
  });

  let mtbody = responseData.map((item, index) => {
    return (
      <UnEventContext.Consumer>
        {(unEvent) => (
          <tr
            className="item"
            key={index}
            onClick={() => onClickConfirm(item.idx, index, item.state, unEvent)}
          >
            <td>
              <p className={`circle ${item.state == 0 ? "new" : ""}`} />
              <div className="item_info">
                <h2>
                  <b>
                    {Boolean(item.manage_number) && `[${item.manage_number}]`}
                  </b>
                  {`${item.etc} ${item.note ? `(${item.note})` : ""}`}
                </h2>
                <p>{window.$Global.convertDate(item.created_at)}</p>
              </div>
            </td>
            <td>
              <button
                className="icon_del_small_with_bg"
                onClick={(e) => onClickDelete(e, item.idx, index)}
              />
            </td>
          </tr>
        )}
      </UnEventContext.Consumer>
    );
  });

  if (tbody.length == 0) {
    tbody = window.$Global.notTd(6, "신규 알림이 없습니다");
  }
  return (
    <div id="UnEventView">
      <div className="pc">
        {toastStatus && <UnEventToast />}
        <div className="area_box">
          <h2>비즈내비 알림</h2>
          <p>
            특허 직발서 등록, 비용 청구 등 확인해야 하는 알림을 한곳에 모아
            알려드립니다.
          </p>
          <p>
            확인한 알림은 클릭 한번으로 비활성화 시킬 수 있습니다. 다양한 돌발
            상황을 알림 페이지에서 확인하세요.
          </p>
        </div>
        <div className="header">
          <p>
            안 읽은 알림 <span>{no_read_event_length}</span>건 / 총{" "}
            {event_length}건
          </p>
          <button onClick={() => onClickAllConfirm()}>알림 모두읽음</button>
        </div>
        <div className="table-box-wrap">
          <div className="wrap_list hover">
            <table>
              <thead>
                <tr>
                  <th>내&emsp;용</th>
                  <th>발생 일자</th>
                  <th>삭&emsp;제</th>
                </tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mo">
        <div className="header">
          <button
            className="icon_more_arrow_left_black"
            onClick={() => history.go(-1)}
          />
          <h2>알림</h2>
        </div>
        <div className="read">
          <button onClick={() => onClickAllConfirm()}>모두읽음</button>
          <p>
            안 읽은 알림 <span>{no_read_event_length}</span>건 / 총{" "}
            {event_length}건
          </p>
        </div>
        <div className="list">
          <table>
            <thead>
              <tr>
                <th>알림 이벤트 내용</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>{mtbody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UnEventView;
