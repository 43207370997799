import "../competitor/css/CompetitorDetails.scss";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut, HorizontalBar, Line } from "react-chartjs-2";
import {
  analysisChartData,
  analysisChartOption,
  auctionCompanyChartData,
  auctionCompanyChartOption,
  employeeDoughnutChartData,
  employeeDoughnutChartOption,
  employeeMixedChartData,
  employeeMixedChartOption,
  growthChartOption,
  growthCompareChartData,
  growthCompareChartOption,
  growthFirstChartData,
  growthFourthChartData,
  growthSecondChartData,
  growthThirdChartData,
  profitMixedChartData,
  profitMixedChartOption,
} from "./competitorIpnowChartData";
import logo from "../../assets/images/common/logo_findBiz.svg";
import { useParams } from "react-router-dom";
import CommonAPI from "../../API/common";
import InfoAPI from "../../API/info";
import AdminAPI from "../../API/admin";

const CompetitorIpnowDetails = ({ history }) => {
  const { idx } = useParams();

  const [companyCountData, setCompanyCountData] = useState({});
  const [patentList, setPatentList] = useState([]);
  const [trademarkList, setTrademarkList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState(
    history.location.state?.company_data || {}
  );

  useEffect(() => {
    if (idx) {
      getPatentInfo();
    }
  }, []);

  const getPatentInfo = () => {
    InfoAPI.getCompanyPatent({ company_code: idx, page: 1, count: 5 }).then(
      (res) => {
        setPatentList(res.data.items);
      }
    );

    InfoAPI.getTradeMark({ company_code: idx }).then((res) => {
      res.data.map((item) => {
        let obj = {};
        let classArr = item.class.split("|");
        let productArr = item.product.split("|");
        for (let i = 0; i < classArr.length; i++) {
          obj[classArr[i]] = [
            ...Object.values(obj[classArr[i]] || {}),
            productArr[i],
          ];
        }
        item.classification = obj;
      });
      setTrademarkList(res.data);
    });

    InfoAPI.getDesign({ company_code: idx }).then((res) => {
      console.log(res.data);
      setDesignList(res.data);
    });
    CommonAPI.getIpInfo({ company_code: idx }).then((res) => {
      setCompanyCountData(res.data);
    });
  };

  const patentDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.patent_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const trademarkDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.trademark_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const patentDoughnutChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const annualFeeChartData = {
    labels: companyCountData?.annual_pay?.map((item) => item.year + "년"),
    datasets: [
      {
        label: "Data",
        data: companyCountData?.annual_pay?.map((item) => item.cost),
        borderColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
        backgroundColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
      },
    ],
  };

  const annualFeeChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: "end",
        align: "top",
        font: {
          size: 8,
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: window.$Global.calcGraphMaxNumber(
              companyCountData.annual_pay &&
                companyCountData.annual_pay.map((item) => item.cost)
            ),
            callback: function (value) {
              return window.$Global.commaify(value);
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };

  const yearlyChartData = {
    labels: Object.keys(companyCountData.annual_apply_cnt || {}).map(
      (item) => item.slice(2) + "년"
    ),
    datasets: [
      {
        label: "Data 1",
        type: "line",
        data: Object.values(companyCountData.annual_apply_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#bcec45",
        backgroundColor: "#0095ae",
        pointBackgroundColor: "#0095ae",
        lineTension: 0,
      },
      {
        label: "Data 2",
        type: "line",
        data: Object.values(companyCountData.annual_register_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#ffc500",
        backgroundColor: "#ffac12",
        pointBackgroundColor: "#ffac12",
        lineTension: 0,
      },
    ],
  };

  const yearlyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display:
          Math.max(
            ...Object.values(companyCountData.annual_apply_cnt || {})
              .map((item) => item.cnt)
              .concat(
                Object.values(companyCountData.annual_register_cnt || {}).map(
                  (item) => item.cnt
                )
              )
          ) === 0
            ? false
            : true,
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: window.$Global.calcGraphMaxNumber(
              Object.values(companyCountData.annual_apply_cnt || {})
                .map((item) => item.cnt)
                .concat(
                  Object.values(companyCountData.annual_register_cnt || {}).map(
                    (item) => item.cnt
                  )
                )
            ),
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div id="CompetitorInfo">
      <header>
        <div className="competitor_title">
          <div>
            <div className="oval" />
            <p>{companyCountData?.company_name || companyInfo.company_name}</p>
            <i className="icon_share" />
            <i className="icon_fav_on_tack" />
          </div>
          <div></div>
        </div>
        <div className="info_tab">
          <p className="active">기업정보</p>
          <p>사업목적</p>
          <p>조직분석·고용현황</p>
          <p>재무·손익</p>
          <p>핵심기술</p>
          <p>특허·상표·디자인</p>
          <p>경쟁현황 분석</p>
          <p>보도자료</p>
        </div>
      </header>
      <section>
        <article>
          <h5 className="active">기업정보</h5>
          <div className="competitor_info_grid">
            <div>
              <p>대표자</p>
              <p>{companyInfo.company_ceo}</p>
            </div>
            <div>
              <p>설립일자</p>
              <p></p>
            </div>
            <div>
              <p>고용인원</p>
              <p></p>
            </div>
            <div>
              <p>매출액</p>
              <p></p>
            </div>
            <div>
              <p>업종</p>
              <p></p>
            </div>
            <div>
              <p>기업규모</p>
              <p></p>
            </div>
            <div>
              <p>홈페이지</p>
              {/*<a href={companyInfo.homepage} target="_blank">*/}
              {/*  {companyInfo.homepage}*/}
              {/*</a>*/}
            </div>
            <div>
              <p>주소</p>
              <p>{companyInfo.company_addr}</p>
            </div>
            <div>
              <p>키워드</p>
              <div className="keyword_wrapper">
                {companyInfo.grouptags.map((keyword) => {
                  return <p>{keyword.company_group_name}</p>;
                })}
              </div>
            </div>
          </div>
        </article>
        <article>
          <h5>사업목적</h5>
          <div className="analysis">
            <p>전자부품 제조업</p>
            <p>전자상거래업</p>
            <p>연구개발서비스업</p>
            <p>통신판매업</p>
            <p>소프트웨어 개발업</p>
            <p>의료기기 제조 및 판매업</p>
          </div>
        </article>
        <article>
          <h5>조직분석·고용현황</h5>
          <div className="employee">
            <div>
              <Bar
                data={employeeMixedChartData}
                options={employeeMixedChartOption}
                width={310}
                height={180}
              />
            </div>
            <div className="employee_info">
              <div>
                <p>11월 전체 인원수</p>
                <p>
                  <span>1,298</span>명
                </p>
              </div>
              <div>
                <p>11월 퇴사자 수</p>
                <p>
                  <span>17</span>명
                </p>
              </div>
              <div>
                <p>11월 입사자 수</p>
                <p>
                  <span>63</span>명
                </p>
              </div>
              <div>
                <p>연간 퇴사율</p>
                <p>
                  <span>17</span>%
                </p>
              </div>
            </div>
            <div>
              <Doughnut
                data={employeeDoughnutChartData}
                options={employeeDoughnutChartOption}
                width={310}
                height={180}
              />
            </div>
          </div>
        </article>
        <article>
          <h5>재무·손익</h5>
          <div className="financial_wrapper">
            <div className="sub_title">
              <p>주요 재무 상태표</p>
              <p>단위 : 백만</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>구&emsp;분</th>
                  <th>2016</th>
                  <th>2017</th>
                  <th>2018</th>
                  <th>2019</th>
                  <th>2020</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>매출액</td>
                  <td>2,414</td>
                  <td>2,259</td>
                  <td>2,254</td>
                  <td>2,414</td>
                  <td>2,393</td>
                </tr>
                <tr>
                  <td>영업이익</td>
                  <td>1,691</td>
                  <td>1,640</td>
                  <td>1,561</td>
                  <td>1,902</td>
                  <td>1,524</td>
                </tr>
                <tr>
                  <td>당기순이익</td>
                  <td>614</td>
                  <td>693</td>
                  <td>423</td>
                  <td>832</td>
                  <td>896</td>
                </tr>
              </tbody>
            </table>
            <div className="chart_wrapper">
              <Bar
                data={profitMixedChartData}
                options={profitMixedChartOption}
                width={882}
                height={200}
              />
              <p>주요 재무 상태표</p>
            </div>
            <div>
              <div className="sub_title">
                <p>주요 재무 상태표</p>
                <p>단위 : 백만</p>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>구&emsp;분</th>
                    <th>2016</th>
                    <th>2017</th>
                    <th>2018</th>
                    <th>2019</th>
                    <th>2020</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>매출액</td>
                    <td>2,414</td>
                    <td>2,259</td>
                    <td>2,254</td>
                    <td>2,414</td>
                    <td>2,393</td>
                  </tr>
                  <tr>
                    <td>영업이익</td>
                    <td>1,691</td>
                    <td>1,640</td>
                    <td>1,561</td>
                    <td>1,902</td>
                    <td>1,524</td>
                  </tr>
                  <tr>
                    <td>당기순이익</td>
                    <td>614</td>
                    <td>693</td>
                    <td>423</td>
                    <td>832</td>
                    <td>896</td>
                  </tr>
                </tbody>
              </table>
              <div className="chart_wrapper">
                <Bar
                  data={profitMixedChartData}
                  options={profitMixedChartOption}
                  width={882}
                  height={200}
                />
                <p>주요 재무 상태표</p>
              </div>
            </div>
          </div>
        </article>
        <article>
          <h5>기업 기술력 평가</h5>
          <div className="tech_skills">
            <div>
              <p>종합 기술 경쟁력</p>
              <div className="skill_eval">
                <div>
                  <p>
                    종합평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
                <div>
                  <p>
                    정성평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
                <div>
                  <p>
                    정량평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
              </div>
              <p className="total_text">
                주식회사 에듀헬스퓨처의 종합기술 경쟁력은 <span>A+</span>{" "}
                입니다.
              </p>
            </div>
            <div>
              <p>동종산업 내 기술 경쟁력</p>
              <div className="skill_eval">
                <div>
                  <p>
                    종합평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
                <div>
                  <p>
                    정성평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
                <div>
                  <p>
                    정량평가
                    <i className="icon_question_mark_blue" />
                  </p>
                  <div className="oval">A+</div>
                  <span>백분율 6%</span>
                </div>
              </div>
              <p className="total_text">
                주식회사 에듀헬스퓨처의 종합기술 경쟁력은 <span>A+</span>
                입니다.
              </p>
            </div>
          </div>
        </article>
        <article>
          <h5>핵심 기술정보</h5>
          <div className="skill_info">
            <div>
              <p>요약</p>
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <p>기술명</p>
              <div>
                <p>배터리 잔량 경고/유지 방법</p>
                <p>
                  디지털 카메라를 구비하는 이동 단말기에서 미리보기모드를
                  설정하는 방법 및 장치
                </p>
              </div>
            </div>
            <div>
              <p>대표 청구항</p>
              <div>
                <p>
                  자율주행 차량 운용 관리는 자율주행 차량에 의해 차량 운송
                  네트워크를 횡단하는 것을 포함할 수 있다. 차량 운송 네트워크를
                  횡단하는 것은 자율주행 차량의 센서로부터, 자율주행 차량의
                  정의된 거리 내에 있는 외부 물체에 대응하는 센서 정보를
                  수신하는 것, 센서 정보를 수신하는 것에 응답하여 별개의 차량
                  운용 시나리오를 식별하는 것, 시나리오 특정 운용 제어 평가 모듈
                  인스턴스를 인스턴스화하는 것 - 시나리오 특정 운용 제어 평가
                  모듈 인스턴스는 별개의 차량 운용 시나리오를 모델링하는
                  시나리오 특정 운용 제어 평가 모듈의 인스턴스임 -,
                </p>
                <p>
                  본 발명은 디지털 카메라를 구비하는 이동 단말기에 관한 것으로,
                  보다 구체적으로 촬영하고자 하는 이미 지와 저장된 소정 수의
                  이미지를 각각 디스플레이부의 제1 부분과 제2 부분에 함께
                  디스플레이하는 미리보 기 모드를 설정할 수 있는 이동 단말기에
                  관한 것이다.
                </p>
              </div>
            </div>
          </div>
        </article>
        <article>
          <h5>특허·상표·디자인</h5>
          <div className="patent_chart_wrapper">
            <div className="chart patent_trade">
              <p className="chart_category_name">특&emsp;허</p>
              <p className="total_cnt">
                {companyCountData.patent_statistic?.all}건
              </p>
              <div className="add_label">
                등록 {companyCountData.patent_statistic?.register_cnt}건
              </div>
              <div className="apply_label">
                출원 {companyCountData.patent_statistic?.apply_cnt}건
              </div>
              <div className="giveup_label">
                포기 {companyCountData.patent_statistic?.give_up_cnt}건
              </div>
              <Doughnut
                data={patentDoughnutChartData}
                width={140}
                height={140}
                options={patentDoughnutChartOption}
              />
            </div>
            <div className="chart patent_trade">
              <p className="chart_category_name">상&emsp;표</p>
              <p className="total_cnt">
                {companyCountData.trademark_statistic?.all}건
              </p>
              <div className="add_label">
                등록 {companyCountData.trademark_statistic?.register_cnt}건
              </div>
              <div className="apply_label">
                출원 {companyCountData.trademark_statistic?.apply_cnt}건
              </div>
              <div className="giveup_label">
                포기 {companyCountData.trademark_statistic?.give_up_cnt}건
              </div>
              <Doughnut
                data={trademarkDoughnutChartData}
                width={140}
                height={140}
                options={patentDoughnutChartOption}
              />
            </div>
            <div className="chart annual_fee_and_year">
              <p>
                예상 연차료<span>(추천등급제공)</span>
              </p>
              <Bar
                data={annualFeeChartData}
                options={annualFeeChartOption}
                width={200}
                height={150}
              />
            </div>
            <div className="chart annual_fee_and_year">
              <p>연도별 데이터</p>
              <Bar
                data={yearlyChartData}
                options={yearlyChartOption}
                width={200}
                height={150}
              />
            </div>
          </div>

          <div className="patent_list">
            <p>특허 리스트</p>
            {patentList.length <= 0 ? (
              <p className="empty_data">데이터 없음</p>
            ) : (
              <table>
                <colgroup>
                  <col width={50} />
                  <col width={120} />
                  <col width={100} />
                  <col width={120} />
                  <col width={100} />
                  <col width={300} />
                  <col width={120} />
                  <col width={80} />
                </colgroup>
                <thead>
                  <tr>
                    <th>국가</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th style={{ textAlign: "left" }}>발명의 명칭</th>
                    <th>출원인</th>
                    <th>등급</th>
                  </tr>
                </thead>
                <tbody>
                  {patentList?.map((item) => {
                    return (
                      <tr>
                        <td>{item.nation}</td>
                        <td>{item.register_number}</td>
                        <td>
                          {window.$Global.convertDateDot(item.register_at)}
                        </td>
                        <td>{item.apply_no}</td>
                        <td>{window.$Global.convertDateDot(item.apply_at)}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.invention_title}
                        </td>
                        <td></td>
                        <td>
                          <div
                            className={`circle grade_${
                              item.sm_grade || "default"
                            }`}
                          >
                            {item.sm_grade || "N"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </article>
        <article>
          <h5>상표</h5>
          {trademarkList.length <= 0 ? (
            <p className="empty_data">데이터 없음</p>
          ) : (
            <div className="trademark_wrapper">
              {trademarkList?.slice(0, 5).map((item) => {
                return (
                  <div className="trademark_item">
                    <div className="trademark_name">
                      <h6>상표명</h6>
                      <p>{item.title}</p>
                    </div>
                    <div className="trademark_info">
                      <h6>지정상품 정보</h6>
                      {Object.keys(item.classification).map((key) => {
                        return (
                          <div>
                            <p>제 {key}류</p>
                            <div>
                              {Object.values(item.classification).map(
                                (value) => {
                                  return value.map((valueItem) => {
                                    return <p>{valueItem}</p>;
                                  });
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </article>
        <article>
          <h5>디자인</h5>
          <div className="design_wrapper">
            {designList.slice(0, 5).map((item) => {
              return (
                <div className="design_item">
                  <div className="design_title">
                    <div>
                      <h6>디자인 명</h6>
                      <p className="design_name">{item.article_name}</p>
                    </div>
                    <div className="design_category">
                      <p>제 24류</p>
                      <p>제 14류</p>
                    </div>
                  </div>
                  <div className="design_content">
                    <div>img</div>
                    <div>
                      1. 재질은 직물, 금속 또는 합성수지임.
                      <br /> 2. 사용자의 머리에 착용하여 뇌파를 측정 및
                      분석하고, 자극을 인가하여 스트레스를 케어하는 웨어러블
                      기기임.
                      <br /> 3. 본 디자인의 2개의 뇌파 자극을 위한 전극은
                      스트레스 케어 모드에 따라 회전이 되어 위치 조절이 가능한
                      구조를 가짐.
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="design_item">
              <div className="design_title">
                <div>
                  <h6>디자인 명</h6>
                  <p className="design_name">
                    다 감각 자극 스트레스 케어용 웨어러블 기기
                  </p>
                </div>
                <div className="design_category">
                  <p>제 24류</p>
                  <p>제 14류</p>
                </div>
              </div>
              <div className="design_content">
                <div>img</div>
                <div>
                  1. 재질은 직물, 금속 또는 합성수지임.
                  <br /> 2. 사용자의 머리에 착용하여 뇌파를 측정 및 분석하고,
                  자극을 인가하여 스트레스를 케어하는 웨어러블 기기임.
                  <br /> 3. 본 디자인의 2개의 뇌파 자극을 위한 전극은 스트레스
                  케어 모드에 따라 회전이 되어 위치 조절이 가능한 구조를 가짐.
                </div>
              </div>
            </div>
            <div className="design_item">
              <div className="design_title">
                <div>
                  <h6>디자인 명</h6>
                  <p className="design_name">
                    다 감각 자극 스트레스 케어용 웨어러블 기기
                  </p>
                </div>
                <div className="design_category">
                  <p>제 24류</p>
                  <p>제 14류</p>
                </div>
              </div>
              <div className="design_content">
                <div>img</div>
                <div>
                  1. 재질은 직물, 금속 또는 합성수지임.
                  <br /> 2. 사용자의 머리에 착용하여 뇌파를 측정 및 분석하고,
                  자극을 인가하여 스트레스를 케어하는 웨어러블 기기임.
                  <br /> 3. 본 디자인의 2개의 뇌파 자극을 위한 전극은 스트레스
                  케어 모드에 따라 회전이 되어 위치 조절이 가능한 구조를 가짐.
                </div>
              </div>
            </div>
          </div>
        </article>
        <article>
          <h5>정부사업 경쟁률 분석정보</h5>
          <div className="competition_rate">
            <p>동종업 내 정부사업 낙찰 현황</p>
            <div className="auction_chart_wrapper">
              <div>
                <HorizontalBar
                  data={auctionCompanyChartData}
                  options={auctionCompanyChartOption}
                />
                <p>주식회사 에듀헬스퓨처 낙찰현황</p>
              </div>
              <div>
                <HorizontalBar
                  data={auctionCompanyChartData}
                  options={auctionCompanyChartOption}
                />
                <p>헬스케어 산업 전체 평균 낙찰 현황</p>
              </div>
              <div>
                <HorizontalBar
                  data={auctionCompanyChartData}
                  options={auctionCompanyChartOption}
                />
                <p>헬스케어 산업 상위 10% 낙찰 현황</p>
              </div>
            </div>
          </div>
          <div className="competition_rate">
            <p>동종 산업 내 정부사업 종합분석</p>
            <div className="auction_chart_wrapper">
              <Bar
                data={analysisChartData}
                options={analysisChartOption}
                width={761}
                height={313}
              />
            </div>
          </div>
          <div className="auction_status">
            <p>동종업 내 정부사업 낙찰 현황</p>
            <div className="auction_status_text">
              <p>
                지원과제 <span>879</span>
                <span>건</span>
              </p>
              <p>
                낙찰과제 <span>9</span>
                <span>건</span>
              </p>
              <p>
                낙찰률<span>1.02</span>
                <span>%</span>
              </p>
            </div>
            <div className="auction_status_box">
              <div>
                <p>낙찰완료</p>
                <p>
                  기업지원 MEDBIZ플랫폼(헬스케어 IoT 플랫폼) 성능고도화 용역
                  모집
                </p>
                <p>10:1</p>
              </div>
              <div>
                <p>낙찰완료</p>
                <p>
                  기업지원 MEDBIZ플랫폼(헬스케어 IoT 플랫폼) 성능고도화 용역
                  모집
                </p>
                <p>10:1</p>
              </div>
              <div>
                <p>미낙찰</p>
                <p>
                  기업지원 MEDBIZ플랫폼(헬스케어 IoT 플랫폼) 성능고도화 용역
                  모집
                </p>
                <p>10:1</p>
              </div>
              <div>
                <p>미낙찰</p>
                <p>
                  기업지원 MEDBIZ플랫폼(헬스케어 IoT 플랫폼) 성능고도화 용역
                  모집
                </p>
                <p>10:1</p>
              </div>
            </div>
          </div>
          <div className="auction_status_table">
            <table>
              <colgroup>
                <col width={50} />
                <col width={50} />
                <col width={500} />
              </colgroup>
              <thead>
                <tr>
                  <th>즐찾</th>
                  <th>요약</th>
                  <th style={{ textAlign: "left" }}>사업명</th>
                  <th>현황</th>
                  <th>접수일</th>
                  <th>마감일</th>
                  <th>경쟁률</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon_fav_off_tack" />
                  </td>
                  <td>
                    <i className="icon_info_with_blue_bg" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    삼성서울병원 임상 기술자문 프로그램 (디지털헬스케어 생태계
                    구축사업) 공고 모집
                  </td>
                  <td>
                    <p>낙찰</p>
                  </td>
                  <td>2023-01-28</td>
                  <td>2023-02-01</td>
                  <td style={{ color: "#4593f5" }}>7:1</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon_fav_off_tack" />
                  </td>
                  <td>
                    <i className="icon_info_with_blue_bg" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    삼성서울병원 임상 기술자문 프로그램 (디지털헬스케어 생태계
                    구축사업) 공고 모집
                  </td>
                  <td>
                    <p>낙찰</p>
                  </td>
                  <td>2023-01-28</td>
                  <td>2023-02-01</td>
                  <td style={{ color: "#4593f5" }}>7:1</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon_fav_off_tack" />
                  </td>
                  <td>
                    <i className="icon_info_with_blue_bg" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    삼성서울병원 임상 기술자문 프로그램 (디지털헬스케어 생태계
                    구축사업) 공고 모집
                  </td>
                  <td>
                    <p>낙찰</p>
                  </td>
                  <td>2023-01-28</td>
                  <td>2023-02-01</td>
                  <td style={{ color: "#4593f5" }}>7:1</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon_fav_off_tack" />
                  </td>
                  <td>
                    <i className="icon_info_with_blue_bg" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    삼성서울병원 임상 기술자문 프로그램 (디지털헬스케어 생태계
                    구축사업) 공고 모집
                  </td>
                  <td>
                    <p>낙찰</p>
                  </td>
                  <td>2023-01-28</td>
                  <td>2023-02-01</td>
                  <td style={{ color: "#4593f5" }}>7:1</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon_fav_off_tack" />
                  </td>
                  <td>
                    <i className="icon_info_with_blue_bg" />
                  </td>
                  <td style={{ textAlign: "left" }}>
                    삼성서울병원 임상 기술자문 프로그램 (디지털헬스케어 생태계
                    구축사업) 공고 모집
                  </td>
                  <td>
                    <p>낙찰</p>
                  </td>
                  <td>2023-01-28</td>
                  <td>2023-02-01</td>
                  <td style={{ color: "#4593f5" }}>7:1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
        <article>
          <h5>경쟁현황 종합분석</h5>
          <div className="competitor_list">
            <p>주요 경쟁사</p>
            <div className="company_info_items">
              <div className="company_info_item">
                <img src={logo} />
                <div className="item_text">
                  <p>(주)유메딕스</p>
                  <p>
                    웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업
                  </p>
                  <div className="item_text_category">
                    <p>데이터</p>
                    <p>B2B</p>
                    <p>플랫폼</p>
                  </div>
                </div>
                <button>분석정보 보기</button>
              </div>
              <div className="company_info_item">
                <img src={logo} />
                <div className="item_text">
                  <p>(주)유메딕스</p>
                  <p>
                    웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업
                  </p>
                  <div className="item_text_category">
                    <p>데이터</p>
                    <p>B2B</p>
                    <p>플랫폼</p>
                  </div>
                </div>
                <button>분석정보 보기</button>
              </div>
              <div className="company_info_item">
                <img src={logo} />
                <div className="item_text">
                  <p>(주)유메딕스</p>
                  <p>
                    웨어러블 패치 형태의 심전도 모니터링 기기를 개발하는 기업
                  </p>
                  <div className="item_text_category">
                    <p>데이터</p>
                    <p>B2B</p>
                    <p>플랫폼</p>
                  </div>
                </div>
                <button>분석정보 보기</button>
              </div>
            </div>
          </div>
        </article>
        <article>
          <h5>기업가치 성장분석</h5>
          <div className="growth_rate">
            <p>지표별 성장률</p>
            <div>
              <div>
                <Bar
                  data={growthFirstChartData}
                  options={growthChartOption}
                  width={324}
                  height={256}
                />
                <p>연도별 재무 현황</p>
              </div>
              <div>
                <Bar
                  data={growthSecondChartData}
                  options={growthChartOption}
                  width={324}
                  height={256}
                />
                <p>연도별 기술성장률</p>
              </div>
              <div>
                <Bar
                  data={growthThirdChartData}
                  options={growthChartOption}
                  width={324}
                  height={256}
                />
                <p>연도별 영업이익 성장률</p>
              </div>
              <div>
                <Bar
                  data={growthFourthChartData}
                  options={growthChartOption}
                  width={324}
                  height={256}
                />
                <p>연도별 과제 낙찰률</p>
              </div>
            </div>
          </div>
          <div className="growth_compare_rate">
            <p>산업 내 기업가치 성장률 비교분석</p>
            <div>
              <Bar
                data={growthCompareChartData}
                options={growthCompareChartOption}
                width={766}
                height={230}
              />
            </div>
            <div>
              <p>
                (주)에듀헬스퓨쳐는 최근 4년간 매출에서 15%의 증가율을 보였고,
                2022년 3% 소폭 감소하였다.
              </p>
              <p>
                (주)에듀헬스퓨처는 최근 4년간 기술력에서 30%의 증가율을 보였다.
              </p>
              <p>
                (주)에듀헬스퓨처는 최근 4년간 영업이익에서 43.5%의 증가율을
                보였다.
              </p>
              <p>
                (주)에듀헬스퓨처는 최근 4년간 인력 비중이 8%의 감소율을 보였다.
              </p>
            </div>
          </div>
        </article>
        <article>
          <h5>보도자료</h5>
          <div className="competitor_news">
            <div>
              <p>
                '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래
                제시”
              </p>
              <p>
                디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서
                개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식
                초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데
                세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.
                이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한
                미래 헬스케어 혁신 솔루션을 설명할 예정이다.
              </p>
              <p>[매일경제 2023-01-16]</p>
            </div>
            <div>
              <p>
                '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래
                제시”
              </p>
              <p>
                디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서
                개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식
                초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데
                세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.
                이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한
                미래 헬스케어 혁신 솔루션을 설명할 예정이다.
              </p>
              <p>[매일경제 2023-01-16]</p>
            </div>
          </div>
        </article>
      </section>
    </div>
  );
};

export default CompetitorIpnowDetails;
