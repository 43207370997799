import React, {useContext, useState} from "react";
import "./css/Withdrawal.scss";
import CheckBox from "../common/CheckBox";
import MemberAPI from "../../API/member";
import memberContext from "../../store/Member";

function Withdrawal() {
    const [agree, setAgree] = useState(false);
    const memberInfo = useContext(memberContext);

    const onClickDel = () => {
        if (!agree) {
            alert("안내사항에 동의해주세요");
            return;
        }
        MemberAPI.deleteInfo().then(() => {
            alert("탈퇴하셨습니다.");
            window.$Global.deleteSession();
            window.location.href = "/";
        });
    };

    return (
        <div id="Withdrawal">
            <div className="header">
                <h2>회원 탈퇴하기</h2>
                <p>회원탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.</p>
            </div>
            <div className="notify">
                <p>사용하고 계신 아이디 <span>{memberInfo.id}</span> 는 탈퇴할 경우 재사용 및 복구가 불가능합니다.</p>
            </div>
            <p className="desc">
                탈퇴 후에는 아이디 <span>{memberInfo.id}</span>로 다시 가입할 수 없으며 아이디와 데이터는 복구할 수 없습니다.<br/>
                게시판형 서비스에 남아 있는 게시글은 탈퇴 후 삭제할 수 없습니다.<br/>
            </p>
            <CheckBox text="안내 사항을 모두 확인하였으며, 이에 동의합니다." checked={agree} onChangeChecked={() => setAgree(!agree)}/>
            <button className="btn_del" onClick={onClickDel}>회원탈퇴</button>
        </div>
    );
}

export default Withdrawal;
