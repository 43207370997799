import ibk from "../../assets/images/loan/bank1.png";
import kdb from "../../assets/images/loan/bank2.png";
import shinhan from "../../assets/images/loan/bank3.png";
import woori from "../../assets/images/loan/bank4.png";
import hana from "../../assets/images/loan/bank5.png";
import kb from "../../assets/images/loan/bank6.png";
import dgb from "../../assets/images/loan/bank7.png";
import bnk from "../../assets/images/loan/bank8.png";
import nh from "../../assets/images/loan/bank9.png";

export const logos = {
  ibk,
  kdb,
  shinhan,
  woori,
  hana,
  kb,
  dgb,
  bnk,
  nh,
};
