import "./css/InventorStepOne.scss";
import { useHistory } from "react-router-dom";

const InventorStepOne = () => {
  const history = useHistory();

  return (
    <div id="InventorStepOne">
      <div className="step_box">
        <div>
          <span>STEP 1</span>
          <p>
            발명자
            <br />
            정보 입력·조회
          </p>
        </div>
        <i className="icon_double_arrow_right" />
        <div>
          <span>STEP 2</span>
          <p>
            소속했던
            <br />
            기관 선택하기
          </p>
        </div>
        <i className="icon_double_arrow_right" />
        <div>
          <span>STEP 3</span>
          <p>
            기관 내
            <br />
            발명특허 선택
          </p>
        </div>
        <i className="icon_double_arrow_right" />
        <div>
          <span>STEP 4</span>
          <p>
            내가 선택 한
            <br />
            리스트 확인하기
          </p>
        </div>
        <i className="icon_double_arrow_right" />
        <div>
          <p>등록 완료</p>
        </div>
      </div>
      <div className="inventor_info_box">
        <div className="inventor_info_box_title">
          <h5>발명자 정보 입력·조회</h5>
          <p>
            <i className="icon_badge_info_gray" />
            나의 정보를 입력하고 조회해 보세요.
          </p>
        </div>
        <div className="input_box">
          <div>
            <p>발명자 이름</p>
            <input type="text" />
          </div>
          <div>
            <p>전화번호</p>
            <input type="text" />
          </div>
          <div>
            <p>이메일</p>
            <input type="text" />
          </div>
          <div>
            <p>주 소</p>
            <input type="text" />
          </div>
        </div>
      </div>
      <button onClick={() => history.push("/inventor/steptwo")}>
        인&emsp;증
      </button>
    </div>
  );
};

export default InventorStepOne;
