import React, {useEffect, useRef, useState} from "react";
import "./css/TradeNotifySetting.scss";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import TradeAPI from "../../API/trade";
import Validator from "../../util/validator";

function TradeNotifySetting({history}) {
    const [keyword, setKeyword] = useState('');
    const [keywordList, setKeywordList] = useState([]);
    const keywordRef = useRef();

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        TradeAPI.getKeywordList().then(res => {
            setKeywordList(res.data);
        });
    }

    const onClickAdd = () => {
        if (Validator.refValidator([keywordRef])) {
            TradeAPI.addKeyword({keyword: keyword}).then(res => {
                setKeyword('');
                getList();
            });
        }
    };

    const onClickDelete = (idx) => {
        TradeAPI.deleteKeyword(idx).then(res => getList());
    }

    const Empty = () => {
        return <div/>
    };

    const ComponentWithDimmed = WithPreventScroll(Empty, true);

    return (
        <div id="TradeNotifySetting">
            <ComponentWithDimmed/>
            <div className="header">
                <h2 className="page_title">관심 알림 설정</h2>
                <button className="icon_more_arrow_left_black" onClick={() => history.push("/trade/list")}/>
            </div>
            <div className="setting">
                <div className="s_head">
                    <h2 className="setting_title">알림 받을 키워드</h2>
                </div>
                <div className="area_search">
                    <input type="text"
                           placeholder="키워드를 입력해 주세요"
                           className="keyword_input"
                           value={keyword}
                           ref={keywordRef}
                           data-name="키워드"
                           onChange={e => setKeyword(e.target.value)}
                           onKeyUp={(e) => {
                               if (e.key === 'Enter') onClickAdd()
                           }}
                    />
                   <button className="icon_orange_add" onClick={onClickAdd}/>
                </div>
                <div className="keyword_list">
                    <h2>등록된 키워드</h2>
                    <ul>
                        {
                            keywordList.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <p>{item.keyword}</p>
                                        <button className="icon_exit" onClick={() => onClickDelete(item.idx)}/>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TradeNotifySetting;