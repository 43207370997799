import React from "react";
import "./css/InvestmentProcess.scss";
import {Link} from "react-router-dom";

function InvestmentProcess() {
    return (
        <div id="InvestmentProcess">
            <h2 className="page_title">특허 활용 투자 유치</h2>
            <div className="area_box">
                <img src={require('../../assets/images/patentIntro/bg_process.png').default} alt="이미지"/>
            </div>

            <Link to="/patentIntro/detail/self" className="btn_next">
                <span>투자 유치 신청하기</span>
                <i className="icon_more_arrow_right_white"/>
            </Link>
        </div>
    )
}

export default InvestmentProcess;