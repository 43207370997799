import React, { useContext } from "react";
import CommonContext from "../../store/Common";
import "./css/KeywordList.scss";

function KeywordListWithCompany({
  arr,
  signInfo,
  onClickManualInput,
  onClickAutoInput,
}) {
  const { isMobile } = useContext(CommonContext);
  return (
    <ul
      id="KeywordListWithPerson"
      className="keyword_list custom_scroll"
      style={{ display: "block" }}
    >
      <li key={0} onClick={onClickManualInput}>
        <h2>직접 입력하기(수동)</h2>
        <p>
          <i className="icon_alert_circle" />
          <span>
            기업명이 검색이 되지 않을 경우 직접입력으로 수동입력이 필요합니다.
          </span>
        </p>
      </li>
      {arr.map((item, index) => {
        return (
          <li
            key={index}
            onClick={() => {
              onClickAutoInput(item, isMobile, signInfo);
            }}
          >
            <h2>{item.name}</h2>
            <p className="pc">
              {item.name_en &&
                item.name_en.replace(/(\s*)/g, "") &&
                `영문법인명 ${item.name_en}`}
              {item.corporate_number &&
                item.corporate_number.replace(/(\s*)/g, "") &&
                ` / 법인등록번호 ${item.corporate_number}`}
              {item.license_number &&
                item.license_number.replace(/(\s*)/g, "") &&
                ` / 사업자 등록번호 ${item.license_number}`}
              {item.representative && ` / 대표자 ${item.representative}`}
              {item.establishment_at &&
                ` / 설립일 ${window.$Global.convertDate(
                  item.establishment_at
                )}`}
              {item.location && ` / 소재지 ${item.location}`}
            </p>
            <div className="mo">
              {item.name_en && item.name_en.replace(/(\s*)/g, "") && (
                <p>
                  <span>영문법인명:</span> {item.name_en}
                  <br />
                </p>
              )}
              {item.corporate_number &&
                item.corporate_number.replace(/(\s*)/g, "") && (
                  <p>
                    <span>법인등록번호:</span> {item.corporate_number}
                    <br />
                  </p>
                )}
              {item.license_number &&
                item.license_number.replace(/(\s*)/g, "") && (
                  <p>
                    <span>사업자 등록번호:</span> {item.license_number}
                    <br />
                  </p>
                )}
              {item.representative && (
                <p>
                  <span>대표자:</span> {item.representative}
                  <br />
                </p>
              )}
              {item.establishment_at && (
                <p>
                  <span>설립일:</span>{" "}
                  {window.$Global.convertDate(item.establishment_at)}
                  <br />
                </p>
              )}
              {item.location && (
                <p>
                  <span>소재지:</span> {item.location}
                </p>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default KeywordListWithCompany;
