import React, { useCallback, useContext, useEffect, useState } from "react";
import "./css/ResearchTechCategoryList.scss";
import CommonAPI from "../../API/common";
import _ from "lodash";
import CommonContext from "../../store/Common";
import { Link, useLocation } from "react-router-dom";
import ResearchTechKeywordSearch from "./ResearchTechKeywordSearch";
import ResearchTechExisting from "./ResearchTechExisting";
import ResearchTechApplicantSearch from "./ResearchTechApplicantSearch";
import ResearchTechHeader from "./ResearchTechHeader";
import ResearchTechNumberSearch from "./ResearchTechNumberSearch";
import ResearchTechMyPatentSearch from "./ResearchTechMyPatentSearch";

function ResearchTechCategoryList({ history }) {
  const { isMobile } = useContext(CommonContext);
  const location = useLocation();
  const [historyCategory, setHistoryCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [relateKeywordList, setRelateKeywordList] = useState([]);
  const [changeTab, setChangeTab] = useState(location.state || 1);

  const getRelateKeyword = async (value) => {
    const keywordList = value
      .replace(/ and | AND /g, "||")
      .replace(/ or | OR /g, "||")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .split("||");

    for (const k of keywordList) {
      if (k.toString().length < 2) return;
    }

    CommonAPI.getPatentRelateKeyword(value).then((res) => {
      setRelateKeywordList(res.data);
    });
  };

  const debounceHandler = useCallback(
    _.debounce(async (value) => {
      getRelateKeyword(value);
    }, 150),
    []
  );

  const onChangeSearchKeyword = (e) => {
    e.persist();
    const inputKeyword = e.target.value;
    setSearchKeyword(inputKeyword);

    if (inputKeyword.toString().length > 1) {
      debounceHandler(inputKeyword);
    }
  };

  useEffect(() => {
    if (location.state !== undefined) {
      window.scrollTo(0, 0);
      setChangeTab(location?.state?.isKeywordSearch || 1);
      setSearchKeyword(location?.state?.keyword);
    }
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getResearchCategory().then((res) => {
      let data = res.data.category_list;
      setCategory(data);
      setHistoryCategory(data);
    });
  };

  const onClickView = (idx1, idx2) => {
    history.push(`list?category_idx1=${idx1}&category_idx2=${idx2}`);
  };

  const onClickSearch = () => {
    let copy = _.cloneDeep(historyCategory);
    let result = copy.filter((item) => {
      let child = item.child.filter((item2) => {
        if (
          item2.name.indexOf(location?.state?.keyword.replace(/(\s*)/g, "")) >
          -1
        ) {
          return item2;
        }
      });

      item.child = child;
      if (child.length) return child;
    });
    setCategory(result);
    setSearchState(true);
  };

  const onClickKeywordSearch = () => {
    history.push(`patent?keyword=${searchKeyword}`);
  };

  const onClickRelateKeyword = (e) => {
    const val = e.target.innerText;
    setSearchKeyword(searchKeyword + " and " + val);
    debounceHandler(searchKeyword + " and " + val);
  };

  const onClickSearchCancel = () => {
    setSearchState(false);
    setCategory(historyCategory);
  };

  return (
    <>
      <ResearchTechHeader />
      <div id="ResearchTechCategoryList">
        <div className="research_wrapper">
          <div className="research_list">
            <div className="research_tab">
              <p
                className={changeTab === 1 && "active"}
                onClick={() => {
                  history.replace({ state: {} });
                  setChangeTab(1);
                }}
              >
                정부 R&D 선행기술 검색
              </p>
              <p
                className={changeTab === 2 && "active"}
                onClick={() => {
                  history.replace({ state: {} });
                  setChangeTab(2);
                }}
              >
                특허 키워드 검색
              </p>
              <p
                className={changeTab === 3 && "active"}
                onClick={() => {
                  history.replace({ state: {} });
                  setChangeTab(3);
                }}
              >
                출원인(특허권자) 검색
              </p>
              <p
                className={changeTab === 4 && "active"}
                onClick={() => {
                  history.replace({ state: {} });
                  setChangeTab(4);
                }}
              >
                출원·등록번호 검색
              </p>
              <p
                className={changeTab === 5 && "active"}
                onClick={() => {
                  history.replace({ state: {} });
                  setChangeTab(5);
                }}
              >
                우리회사 특허 검색
              </p>
            </div>
            {changeTab === 1 ? (
              <ResearchTechExisting />
            ) : changeTab === 2 ? (
              <ResearchTechKeywordSearch keyword={location?.state?.keyword} />
            ) : changeTab === 3 ? (
              <ResearchTechApplicantSearch
                company_keyword={location?.state?.companyKeyword}
                company_code={location?.state?.companyCode}
                company_name={location?.state?.companyName}
              />
            ) : changeTab === 4 ? (
              <ResearchTechNumberSearch />
            ) : (
              <ResearchTechMyPatentSearch changeTab={changeTab} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResearchTechCategoryList;
