import React from "react";
import {CacheSwitch} from "react-router-cache-route";
import NotFoundPage from "../../components/error/NotFoundPage";

function CustomSwitch(props) {
	const { children } = props;
	return (
		<CacheSwitch>
			{children}
			<NotFoundPage />
		</CacheSwitch>
	);
}

export default CustomSwitch;