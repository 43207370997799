import React, { useRef, useState } from "react";
import "./css/DocTradeSampleRegisterIndividual.scss";
import DocTradeSampleRegisterAgree from './DocTradeSampleRegisterAgree';
import DocTradeSampleRegisterIndividualList from './DocTradeSampleRegisterIndividualList';
import TemplateAPI from "../../API/template";
import _ from "lodash";

function DocTradeSampleRegisterIndividual({history, category, onClickPopupShow}) {
	const [template, setTemplate] = useState({
		category_idx1: 1,
		title: "",
		content: "",
		price: 0,
		file: []
	});
	const [freeType, setFreeType] = useState(true);
	const fileRef = useRef();

	const onClickDelete = (idx) => {
		let copy = _.cloneDeep(template);
		copy.file.splice(idx, 1);
		setTemplate(copy);
	};

	const onChangeTemplate = (key, value) => {
		if (key == "title" && value.length > 200) {
			alert("200글자를 초과할 수 없습니다");
			return;
		}

		if (key == "content" && value.length > 1000) {
			alert("1000글자를 초과할 수 없습니다");
			return;
		}

		if (key == "file") {
			value = [...value];
		}

		let copy = _.cloneDeep(template);
		copy[key] = value;
		setTemplate(copy);
	};

	const uploadFileButton = () => {
		fileRef.current.click();
	};

	const onClickAdd = () => {
		let formData = new FormData();

		if (!template.file.length) {
			alert("파일을 등록해주세요");
			return;
		}

		if (!template.title.length) {
			alert("제목을 입력해주세요");
			return;
		}

		if (!freeType && template.price == 0) {
			alert("유료 판매가격은 0원 이상이어야합니다");
			return;
		}

		if (!template.content.length) {
			alert("소개글을 입력해주세요");
			return;
		}

		Object.keys(template).map(item => {
			if (item == "content") {
				formData.append(item, template[item].replace(/(?:\r\n|\r|\n)/g, '<br />'));
			} else if (item == "file") {
				template[item].forEach(file => {
					formData.append(item, file);
				})
			} else {
				formData.append(item, template[item]);
			}
		});
		TemplateAPI.addSample(formData).then(() => {
			alert("정상적으로 등록되었습니다\n등록하신 파일은 관리자 승인 후 게시됩니다");
			history.push("/docTrade/list");
		});
	};

	return (
		<div id="docTradeSampleRegisterIndividual">
			<div className="individual-register">
				<div className="individual-register_table-section">
					<div className="individual-register_table-top">
						<h4 className="title emphasis">파일선택</h4>
						<div className="attention-wrap">
							<button className="btn-attention" type="button" onClick={() => onClickPopupShow('usableDataAgree')}>
								<span className="icon_attention"/>
								<span className="attention">판매 가능 자료 및 부정자료 규정</span>
							</button>
							<label htmlFor="sampleFileAdd" className="hidden">샘플 자료 파일 추가</label>
							<input type="file" name="sampleFileAdd" id="sampleFileAdd" className="hidden addFile" ref={fileRef} onChange={e => onChangeTemplate("file", e.target.files)}/>
							<button type="button" className="sampleFileAdd" onClick={uploadFileButton}>파일 선택</button>
						</div>
					</div>
					<table className="individual-register_table">
						<caption className="hidden">파일선택 목록표</caption>
						<colgroup>
							<col width="85%" />
							<col width="15%" />
						</colgroup>
						<thead className="individual-register_thead">
							<tr>
								<th scope="col">파일명</th>
								<th scope="col">삭&nbsp;&nbsp;제</th>
							</tr>
						</thead>
						<tbody className="individual-register_tbody">
							<DocTradeSampleRegisterIndividualList list={template.file} onClickDelete={onClickDelete}/>
						</tbody>
					</table>
					<p className="individual-register_table-attention-wrap">
						<span className="individual-register_table-attention">
							<button onClick={() => onClickPopupShow('addFileAlert')}>
								<span className="icon_attention"></span>
								<span className="text">등록파일 주의사항</span>
							</button>
						</span>
					</p>
				</div>
				<div className="individual-register_information-section">
					<div className="individual-register_information-top">
						<h4 className="title emphasis">기본 정보 입력</h4>
					</div>
					<div className="individual-register_information">
						<table className="information">
							<colgroup>
								<col width="85px" />
								<col width="200px" />
								<col width="" />
							</colgroup>
							<caption className="hidden">기본 정보 입력</caption>
							<tr>
								<th className="information_title-title" scope="row">
									<label htmlFor="information">제&nbsp;&nbsp;&nbsp;&nbsp;목</label>
								</th>
								<td className="information_title-data" colSpan='2'>
									<input className="informationTitle"
										   type="text"
										   name="informationTitle"
										   id="informationTitle"
										   placeholder="구체적으로 기재하시기 바랍니다. &#40;한글, 영문 포함 최대 200글자&#41;"
										   value={template.title}
										   onChange={e => onChangeTemplate("title", e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<th className="information_dataClass-title" scope="row">
									자료구분
								</th>
								<td className="information_dataClass-data">
									<select name="dataClassSel" id="dataClassSel" className="dataClassSel" onChange={e => onChangeTemplate("category_idx1", e.target.value)}>
										{
											category.map(item => <option key={item.idx} value={item.idx}>{item.name}</option>)
										}
									</select>
								</td>
								<td className="borderNone"/>
							</tr>
							<tr>
								<th className="information_payment-title" scope="row">
									판매가격 &#40;원&#41;
								</th>
								<td className="information_payment-data">
									<input type="radio" name="paymentSelect" id="free" className="payment-input hidden" defaultChecked onChange={e => e.target.checked && setFreeType(true)}/>
									<label htmlFor="free" className="paymentLabel">
										<span>무료&nbsp;&nbsp;<em>&#40;등록 후 유료 변경 불가&#41;</em></span>
									</label>
								</td>
								<td className="information_payment-data">
									<input type="radio" name="paymentSelect" id="pay" className="payment-input hidden" onChange={e => e.target.checked && setFreeType(false)}/>
									<label htmlFor="pay" className="paymentLabel"><span>유료</span></label>
									<label className="priceNumLabel hidden" htmlFor="priceNum">가격 입력</label>
									<input type="text"
										   name="priceNum"
										   id="priceNum"
										   className="priceNumInput"
										   placeholder="판매가격을 입력해주세요"
										   value={window.$Global.commaify(template.price || "")}
										   onChange={e => onChangeTemplate("price", window.$Global.numberRegx(e.target.value))}
									/>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div className="individual-register_introduce-section">
					<div className="individual-register_introduce-top">
						<h4 className="title emphasis">소개글</h4>
						<span className="text">자료에 대한 소개글을 입력해주세요. &#40;1,000자 내외&#41;</span>
					</div>
					<label htmlFor="introduceTextarea" className="hidden">소개글 텍스트 입력란</label>
					<textarea name="introduceTextarea"
							  id="introduceTextarea"
							  className="individual-register_introduce-textarea"
							  placeholder="자료에 대한 소개글을 입력해주세요. (1,000자 내외)"
							  value={template.content}
							  onChange={e => onChangeTemplate("content", e.target.value)}
					/>
					<span className="attention">* 입력된 소개글은 검색 값에 반영되어 상위에 노출되며 자료판매에 도움이 됩니다.</span>
				</div>
				<DocTradeSampleRegisterAgree onClickAdd={onClickAdd}/>
			</div>

		</div>
	)
}

export default DocTradeSampleRegisterIndividual;