import React, { useEffect, useState } from "react";
import ContentDetail from "../common/ContentDetail";
import CommonAPI from "../../API/common";
import { useParams } from "react-router-dom";

function GovSupportDetail({ history }) {
    const { idx } = useParams();
    const [postData, setPostData] = useState({});

    useEffect(() => {
        CommonAPI.getNoticeInfo(idx).then(res => {
            setPostData(res.data);
        });
    }, []);

    const fileDownload = (f_idx) => {
        CommonAPI.downloadNotice(idx, f_idx);
    };

    return (
        <div id="GovSupportDetail">
            <ContentDetail history={history}
                           path="/govSupport"
                           page_title="정부 지원 정책 알림"
                           data={postData}
                           title={postData.title}
                           update_at={window.$Global.convertDate(postData.created_at)}
                           content={postData.content}
                           files={postData.files}
                           onClickDownload={fileDownload}
                           preview={postData.preview}
            />
        </div>
    )
}

export default GovSupportDetail;