import React, { useEffect, useState } from "react";
import "./css/AnnualFeeChart.scss";
import AnnualFeeBar from "./AnnualFeeBar";

// arr_data 정수형 배열 또는 [{cost: 특허, td_cost: 상표}] 형식으로 사용
function AnnualFeeChart({ redirect, arr_data, callback, apply_at }) {
  let tag = [];
  let axes = [];
  let axes_line = [];
  let max = 0;
  let arr = arr_data || [];
  useEffect(() => {
    // y축 width 전달
    if (axes_ref.current && callback) {
      setTimeout(() => {
        callback(axes_ref.current.clientWidth);
      }, 150);
    }
  }, []);

  if (arr.length) {
    max = window.$Global.calcGraphMaxNumber(
      arr.map((item) => {
        return item.cost + item.td_cost + item.de_cost || item || 0;
      })
    );
  }

  const axes_count = 4;
  const y = Math.floor(max / axes_count);
  const axes_ref = React.useRef();

  for (let i = 4; i > -1; i--) {
    axes.push(<p key={i}>{window.$Global.numberWithCommas(y * i)}</p>);
  }

  for (let i = 0; i < arr.length; i++) {
    let sum_cost = 0;
    let sum_height = 0;
    let cost_height = 0;
    let td_cost_height = 0;

    sum_cost = arr[i].cost + arr[i].td_cost || arr[i];
    sum_height = (sum_cost / max) * 100;

    if (arr[i].cost === 0) {
      cost_height = 0;
    } else {
      cost_height = ((arr[i].cost || arr[i]) / sum_cost) * 100;
    }

    td_cost_height = (arr[i].td_cost / sum_cost) * 100;

    let top = 100 - sum_height;
    let left = 0;
    let year = new Date().getFullYear() + i;

    let digits = (sum_cost + "").length;

    if (digits == 5) {
      left = "-50%";
    } else if (digits == 6) {
      left = "-70%";
    } else if (digits < 8) {
      left = "-70%";
    } else if (digits < 10) {
      left = "-70%";
    }

    tag.push(
      <AnnualFeeBar
        key={i}
        single_data={arr[i].cost || arr[i]}
        cost_data={arr[i].cost}
        td_cost_data={arr[i].td_cost}
        sum_height={sum_height}
        cost_height={cost_height}
        td_cost_height={td_cost_height}
        year={year}
        top={top}
        left={left}
        redirect={redirect}
        apply_at={apply_at}
      />
    );
  }

  for (let i = 0; i < axes_count; i++) {
    let top = 0;

    if (i != 0) {
      top = Number(i * 25) + "%";
    }
    axes_line.push(<p key={i} style={{ top: top }} />);
  }

  return (
    <div id="AnnualFeeChart">
      <div className="axes_line">{axes_line}</div>
      <div className="axes" ref={axes_ref}>
        {axes}
      </div>
      <div className="bars">{tag}</div>
    </div>
  );
}

export default AnnualFeeChart;
