import React from "react";
import {Route} from "react-router-dom";
import TradeMain from "../components/trade/TradeMain";
import UserAuthRoute from "./common/UserAuthRoute";
import SearchResult from "../components/trade/SearchResult";
import TradePost from "../components/trade/TradePost";
import TradeClassification from "../components/trade/TradeClassification";
import TradeItemView from "../components/trade/TradeItemView";
import TradeNotifySetting from "../components/trade/TradeNotifySetting";
import MyTrade from "../components/trade/MyTrade";
import CustomSwitch from "./common/CustomSwitch";
import TaskMain from "../components/taskManage/TaskMain";
import CacheRoute from "react-router-cache-route";

function TradeRoute({match}) {
	return (
		<CustomSwitch>
			<Route exact path={`${match.path}/list`} component={TradeMain}/>
			<UserAuthRoute exact path={`${match.path}/result`} requireAuth={false} component={SearchResult}/>
			<UserAuthRoute exact path={`${match.path}/post`} requireAuth={true} component={TradePost}/>
			<UserAuthRoute exact path={`${match.path}/class/:idx`} requireAuth={false} component={TradeClassification}/>
			<UserAuthRoute exact path={`${match.path}/view/:idx`} requireAuth={false} component={TradeItemView}/>
			<UserAuthRoute exact path={`${match.path}/notify`} requireAuth={true} component={TradeNotifySetting}/>
			<CacheRoute exact path={`${match.path}/my`}
						render={(props) => window.$Global.renderFunc(props, MyTrade)}
						when="always"
			/>
		</CustomSwitch>
	)
}

export default TradeRoute;