import React, {useState} from "react";
import "./AllDownload.scss";
import dataVoucherAPI from "../../API/dataVoucher/dataVoucher";
import InfoCompanyAPI from "../../API/infoCompany";
import qs from "query-string";

let downloadStart = false;

function AllDownload() {
    const {isTest} = qs.parse(document.location?.search);

    const [keyword, setKeyword] = useState('');
    const [enKeyword, setEnKeyword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [enCompanyName, setEnCompanyName] = useState('');
    const [startPage, setStartPage] = useState(1);
    const [miDouble, setMiDouble] = useState(false);

    const [selectedCompanyList, setSelectedCompanyList] = useState([]);
    const [checkedCompanyList, setCheckedCompanyList] = useState([]);

    const [miDownloadStatus, setMiDownloadStatus] = useState('');
    const [researchDownloadStatus, setResearchDownloadStatus] = useState('');
    const [companyDownloadStatus, setCompanyDownloadStatus] = useState('');
    const [taskDownloadStatus, setTaskDownloadStatus] = useState('');

    function selectCompany(en) {
        const searchKeyword = en === 1 ? enCompanyName : companyName;

        InfoCompanyAPI.getInfoCompany(searchKeyword, en)
            .then(response => {
                response.data.forEach((v, i) => {
                    if (!v.company_code) response.data.splice(i, 1);
                });
                setSelectedCompanyList(response.data);
            });
    }

    function addCompany(item) {
        if (checkedCompanyList.findIndex(v => v.company_code === item.company_code) !== -1) {
            alert('이미 선택한 회사입니다.');
            return;
        }
        setCheckedCompanyList([item, ...checkedCompanyList]);
    }

    function deleteCompany(item) {
        checkedCompanyList.splice(checkedCompanyList.findIndex(v => v.company_code === item.company_code), 1)
        setCheckedCompanyList([...checkedCompanyList]);
    }

    let interval;

    async function textChanger(setter, text) {
        let cnt = 0;
        interval = setInterval(function () {
            setter(text + ('.'.repeat((cnt % 5) + 1)));
            cnt++;
        }, 1000);
    }

    function stop() {
        clearInterval(interval);
    }

    async function download(type) {
        if (isTest === undefined && (/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }

        if (downloadStart) {
            alert('이미 다운로드 진행중입니다.' +
                '\n다운로드가 완료됐음에도 이 문구가 보일경우' +
                '\n새로고침 해주세요.');
            return;
        }

        if (keyword.replace(/\s/g, '') === '') {
            alert('키워드를 입력해주세요');
            return;
        }

        if (type === 2 && checkedCompanyList.length === 0) {
            alert('경쟁사를 선택해주세요');
            return;
        }

        if (!parseInt(startPage)) {
            alert('시작 페이지를 입력해주세요.');
            return;
        }

        downloadStart = true;

        const companyCodeList = checkedCompanyList.map(v => v.company_code);

        if (type === 0) {
            setMiDownloadStatus('시장정보 다운로드 대기중');
            await textChanger(setMiDownloadStatus, '시장정보 다운로드중');
            try {
                await dataVoucherAPI.asyncDownload("test/dataVoucher/chartMi", {
                    keyword,
                    enKeyword,
                    startPage,
                    miDouble
                });
                stop();
                setMiDownloadStatus('시장정보 다운로드 완료');
            } catch (e) {
                console.log(e);
                stop();
                setMiDownloadStatus('시장정보 다운로드 실패');
            }

        } else if (type === 1) {
            setResearchDownloadStatus('선행기술 다운로드 대기중');
            await textChanger(setResearchDownloadStatus, '선행기술 다운로드중');
            try {
                await dataVoucherAPI.asyncDownload("test/dataVoucher/chartResearch", {keyword, enKeyword, startPage});
                stop();
                setResearchDownloadStatus('선행기술 다운로드 완료');
            } catch (e) {
                console.log(e);
                stop();
                setResearchDownloadStatus('선행기술 다운로드 실패');
            }

        } else if (type === 2) {
            setCompanyDownloadStatus('경쟁사 다운로드 대기중');
            await textChanger(setCompanyDownloadStatus, '경쟁사 다운로드중');
            try {
                await dataVoucherAPI.asyncDownload("test/dataVoucher/companyChart", {
                    companyCodeList,
                    keyword,
                    enKeyword,
                    startPage
                });
                stop();
                setCompanyDownloadStatus('경쟁사 다운로드 완료');
            } catch (e) {
                console.log(e);
                stop();
                setCompanyDownloadStatus('경쟁사 다운로드 실패');
            }

        } else if (type === 3) {
            setTaskDownloadStatus('정부과제 다운로드 대기중');
            await textChanger(setTaskDownloadStatus, '정부과제 다운로드중');
            try {
                await dataVoucherAPI.asyncDownload("test/dataVoucher/chartTask", {keyword, enKeyword, startPage});
                stop();
                setTaskDownloadStatus('정부과제 다운로드 완료');
            } catch (e) {
                console.log(e);
                stop();
                setTaskDownloadStatus('정부과제 다운로드 실패');
            }
        }

        downloadStart = false;
    }

    async function downloadExcel(type) {
        if (isTest === undefined && (/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }

        if (downloadStart) {
            alert('이미 다운로드 진행중입니다.' +
                '\n다운로드가 완료됐음에도 이 문구가 보일경우' +
                '\n새로고침 해주세요.');
            return;
        }

        if (keyword.replace(/\s/g, '') === '') {
            alert('키워드를 입력해주세요');
            return;
        }

        downloadStart = true;

        if (type === 0) {
            try {
                await dataVoucherAPI.asyncDownload('test/dataVoucher/excel/mi', {keyword});
            } catch (e) {
                alert('시장정보 엑셀 다운로드 실패');
            }
        } else if (type === 1) {
            try {
                await dataVoucherAPI.asyncDownload('test/dataVoucher/excel/taskOneKeyword', {keyword});
            } catch (e) {
                alert('정부과제 엑셀 다운로드 실패');
            }
        }

        downloadStart = false;
    }

    return (
        <div id="AllDownload">
            <div className="header_wrap">
                <div>
                    키워드
                    <input
                        type="text"
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        defaultValue={miDouble}
                        type="checkbox"
                        onChange={(e) => setMiDouble(e.target.checked)}
                    />
                    시장정보 두개씩 나오기

                </div>
            </div>
            <div>
                영어 키워드
                <input
                    type="text"
                    onChange={(e) => setEnKeyword(e.target.value)}
                />
                ※경쟁사 다운시 키워드와 연관된 특허 색칠해줌
            </div>
            <div>
                시작 페이지 번호
                <input
                    defaultValue={startPage}
                    type="number"
                    onChange={(e) => setStartPage(e.target.value)}
                />
            </div>

            <div>
                회사명
                <input
                    type="text"
                    onChange={(e) => setCompanyName(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') selectCompany()
                    }}
                />

                <button
                    onClick={() => selectCompany()}
                >
                    검색
                </button>
            </div>
            <div>
                영문 회사명
                <input
                    type="text"
                    onChange={(e) => setEnCompanyName(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') selectCompany(1)
                    }}
                />

                <button
                    onClick={() => selectCompany(1)}
                >
                    영문명 검색
                </button>
            </div>

            <div className="select_company">
                <div>
                    검색결과
                    <div className="company_list">
                        {
                            selectedCompanyList.map(v =>
                                <div
                                    key={"sc" + v.company_code}
                                    className="item"
                                    onClick={() => addCompany(v)}
                                >
                                    <div>{v.company_code}</div>
                                    <div>{v.name}</div>
                                    <div>{v.name_en}</div>
                                </div>
                            )
                        }

                    </div>
                </div>
            {/*</div>*/}

            {/*<div className="select_company">*/}
                <div className="checked_wrap">
                    선택 회사
                    <div className="checked_company_list">
                        {
                            checkedCompanyList.map(v =>
                                <div
                                    key={"cc" + v.company_code}
                                    className="item"
                                    onClick={() => deleteCompany(v)}
                                >
                                    <div>{v.company_code}</div>
                                    <div>{v.name}</div>
                                    <div>{v.name_en}</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="button_wrap">
                <button
                    onClick={() => download(0)}
                >
                    시장정보 다운로드
                </button>
                <button
                    onClick={() => download(1)}
                >
                    선행기술 다운로드
                </button>
                <button
                    onClick={() => download(2)}
                >
                    경쟁사 다운로드
                </button>
                <button
                    onClick={() => download(3)}
                >
                    정부과제 다운로드
                </button>
            </div>
            <div className="button_wrap">
                <button
                    onClick={() => downloadExcel(0)}
                >
                    시장정보 리스트 다운로드
                </button>
                <button
                    onClick={() => downloadExcel(1)}
                >
                    정부과제 리스트 다운로드
                </button>

            </div>

            <div>
                <div>{miDownloadStatus}</div>
                <div>{researchDownloadStatus}</div>
                <div>{companyDownloadStatus}</div>
                <div>{taskDownloadStatus}</div>
            </div>
        </div>
    );
}

export default AllDownload;
