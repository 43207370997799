import React, {useEffect, useState} from "react";
import "./css/MExpectationAnnual.scss";
import {HorizontalBar} from "react-chartjs-2";
import AnnualPayAPI from "../../../API/annualPay";
import CommonAPI from "../../../API/common";
import CheckBox from "../../common/CheckBox";
import WithPreventScroll from "../../../HOC/WithPreventScroll";
import _ from "lodash";

function MExpectationAnnual({history}) {
    const [moMenuShow, setMoMenuShow] = useState(false);

    const [responseData, setResponseData] = useState([]);
    const [sizeList, setSizeList] = useState([false, false, false, false]);
    const [personCountList, setPersonCountList] = useState([false, false]);
    const [etcList, setEtcList] = useState([false, false, false, false, false]);

    const year = new Date().getFullYear();
    let initPayload = {
        start_year: year,
        end_year: year + 15
    };
    const backgroundColor = responseData.map(() => '#ffa600');
    const max = window.$Global.calcGraphMaxNumber(responseData.map(item => item.cost + item.td_cost));
    const line_chart_width = Math.floor(window.$Global.getBrowserSize().width / 1.1);

    useEffect(async() => {
        let getOption = await CommonAPI.getOption(0).then(res => {
            let option = res.data[0]?.option || {};
            return option;
        });

        let payload = {...initPayload, ...getOption};

        await AnnualPayAPI.getAnnualPay(payload).then(res => setResponseData(res.data));

        let option = getOption;
        let copy_size = _.cloneDeep(sizeList);
        let copy_person = _.cloneDeep(personCountList);
        let copy_etc = _.cloneDeep(etcList);

        if (!window.$Global.isEmptyObject(option)) {
            copy_size[option.business] = true;
            copy_person[option.mem_cnt] = true;

            let index = 0;
            if (option.ec == 0) {
                index = 0;
            } else if (option.ip == 0) {
                index = 1;
            } else if (option.joint == 0) {
                index = 2;
            } else if (option.lg == 0) {
                index = 3;
            } else if (option.us_mc == 0) {
                index = 4;
            }
            copy_etc[index] = true;
        } else {
            copy_size[0] = true;
            copy_person[0] = true;
            copy_etc[0] = true;
        }
        setSizeList(copy_size);
        setPersonCountList(copy_person);
        setEtcList(copy_etc);
    }, []);

    const m_data = {
        labels: responseData.map(item => item.year),
        datasets: [{
            data: responseData.map(item => item.cost + item.td_cost),
            labels: [
                '등록',
                '출원',
                '포기'
            ],
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor,
            borderWidth: 0,
        }]
    };

    const m_annualFee_options = {
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                    return window.$Global.commaify(tooltipItem.value) + "원";
                }
            }
        },
        responsive: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            xAxes: [{
                ticks: {
                    display: false,
                    beginAtZero: true,
                    max : max,
                    stepSize: max / 4,
                    maxTicksLimit: 5,
                },
                gridLines: {
                    display : true,
                    borderDash: [4],
                    color: "#d8d8d8",
                    zeroLineColor: '#aaaaaa',
                    zeroLineWidth: 2,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: true
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor : '#959595',
                    fontSize: 10
                },

                gridLines: {
                    display : false,
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'right',
                color: '#959595',
                font: {
                    weight: 'bold',
                },
                formatter: function(value) {
                    return window.$Global.commaify(value);
                }
            }
        },
    };

    const onClickList = () => {
        let selectSize = sizeList.findIndex(item => item);
        let selectPerson = personCountList.findIndex(item => item);
        let selectEtc = etcList.findIndex(item => item);

        let payload = {
            business: selectSize,
            mem_cnt: selectPerson
        };

        switch (selectEtc) {
            case 0:
                payload.ec = 0;
                break;
            case 1:
                payload.ip = 0;
                break;
            case 2:
                payload.joint = 0;
                break;
            case 3:
                payload.lg = 0;
                break;
            case 4:
                payload.us_mc = 0;
                break;
        }

        payload = {...initPayload, ...payload};

        let add_option = {
            type: 0,
            option: {
                ...payload
            }
        };
        CommonAPI.addOption(add_option);
        AnnualPayAPI.getAnnualPay(payload).then(res => setResponseData(res.data));
        setMoMenuShow(false);
    };

    const onClickSelect = (e, index, prop, setProp) => {
        let list = prop.map(() => false);
        let checked = e.target.checked;

        if (checked) {
            list[index] = checked;
            setProp(list);
        }
    };

    const SettingByHOC = () => {
        return (
            <div className={`dimmed ${moMenuShow ? 'show' : 'hide'}`}/>
        )
    };

   const ComponentWithDimmed = WithPreventScroll(SettingByHOC, moMenuShow);

    return (
        <div id="MExpectationAnnual">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2 className="page_title">예상 연차료</h2>
            </div>
            <div className="wrap">
                <div className="w_title">
                    <h2>{year} ~ {year + 15}년</h2>
                    <button onClick={() => setMoMenuShow(true)}>
                        <i className="icon_condition"/>
                        연차료 조건 설정
                    </button>
                </div>
                <HorizontalBar data={m_data} width={line_chart_width} height={480} options={m_annualFee_options}/>
            </div>
            <div className="setting">
                <ComponentWithDimmed/>
                <div className={`menu ${moMenuShow ? 'menu_animation' : ''}`}>
                    <div className="m_head">
                        <h2 className="title">연차료 조건 설정</h2>
                        <button className="btn" onClick={onClickList}>적용</button>
                    </div>
                    <div className="list">
                        <div className="l_head">
                            <h2 className="l_name">기업규모</h2>
                        </div>
                        <ul>
                            <li className="item">
                                <h2>개인or스타트업</h2>
                                <CheckBox checked={sizeList[0]} onChangeChecked={(e) => onClickSelect(e, 0, sizeList, setSizeList)}/>
                            </li>
                            <li className="item">
                                <h2>소기업</h2>
                                <CheckBox checked={sizeList[1]} onChangeChecked={(e) => onClickSelect(e, 1, sizeList, setSizeList)}/>
                            </li>
                            <li className="item">
                                <h2>중기업</h2>
                                <CheckBox checked={sizeList[2]} onChangeChecked={(e) => onClickSelect(e, 2, sizeList, setSizeList)}/>
                            </li>
                            <li className="item">
                                <h2>중소벤처기업</h2>
                                <CheckBox checked={sizeList[3]} onChangeChecked={(e) => onClickSelect(e, 3, sizeList, setSizeList)}/>
                            </li>
                        </ul>
                    </div>
                    <div className="list">
                        <div className="l_head">
                            <h2 className="l_name">직원수</h2>
                        </div>
                        <ul>
                            <li className="item">
                                <h2>500명 미만</h2>
                                <CheckBox checked={personCountList[0]} onChangeChecked={(e) => onClickSelect(e, 0, personCountList, setPersonCountList)}/>
                            </li>
                            <li className="item">
                                <h2>500명 이상</h2>
                                <CheckBox checked={personCountList[1]} onChangeChecked={(e) => onClickSelect(e, 1, personCountList, setPersonCountList)}/>
                            </li>
                        </ul>
                    </div>
                    <div className="list">
                        <div className="l_head">
                            <h2 className="l_name">기타</h2>
                        </div>
                        <ul>
                            <li className="item">
                                <h2>직무발명 보상 우수기업</h2>
                                <CheckBox checked={etcList[0]} onChangeChecked={(e) => onClickSelect(e, 0, etcList, setEtcList)}/>
                            </li>
                            <li className="item">
                                <h2>지신재산 경영 인증</h2>
                                <CheckBox checked={etcList[1]} onChangeChecked={(e) => onClickSelect(e, 1, etcList, setEtcList)}/>
                            </li>
                            <li className="item">
                                <h2>대기업&중소기업 공동연구</h2>
                                <CheckBox checked={etcList[2]} onChangeChecked={(e) => onClickSelect(e, 2, etcList, setEtcList)}/>
                            </li>
                            <li className="item">
                                <h2>지방자치단체업</h2>
                                <CheckBox checked={etcList[3]} onChangeChecked={(e) => onClickSelect(e, 3, etcList, setEtcList)}/>
                            </li>
                            <li className="item">
                                <h2>
                                    전년도 소득 $206,109 미달<br/>
                                    & 누적 미국 출원건 4건 이하
                                </h2>
                                <CheckBox checked={etcList[4]} onChangeChecked={(e) => onClickSelect(e, 4, etcList, setEtcList)}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MExpectationAnnual;
