import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./css/AnnualFeeList.scss";
import AnnualPay from "../../API/annualPay";
import MemberContext from "../../store/Member";
import AnnualFeeReview from "./AnnualFeeReview";
import AnnualFeeReviewSecond from "./AnnualFeeReviewSecond";
import Pagination from "../common/Pagination";
import AnnualFeeReceipt from "./AnnualFeeReceipt";
import Loading from "../loading/Loading";
import PaymentState from "./PaymentState";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import CheckBox from "../common/CheckBox";
import CommonAPI from "../../API/common";

function AnnualFeeList({ history }) {
  let tab_tags = [],
    table_head_tag = [];

  const time = new Date().getTime();
  const { year } = useParams();
  const payload =
    JSON.parse(window.localStorage.getItem(idx + "_annualPay") || null) || {};
  payload.year = year;
  const { idx } = useContext(MemberContext);
  const [patentTag, setPatentTag] = useState([]);
  const [pastView, setPastView] = useState({
    patent: false,
    trademark: false,
  });
  const [tradeMarkTag, setTradeMarkTag] = useState([]);
  const [patentNationArr, setPatentNationArr] = useState([]);
  const [patentJointArr, setPatentJointArr] = useState([]);
  const [tradeMarkNationArr, setTradeMarkNationArr] = useState([]);
  const [selectNation, setSelectNation] = useState({
    patent_nation: "",
    tradeMark_nation: "",
  });
  const [selectJoint, setSelectJoint] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPatent, setShowPatent] = useState(
    window.localStorage.getItem("showPatent")
  );
  const [totalCount, setTotalCount] = useState({
    patent_count: 0,
    tradeMark_count: 0,
  });
  const [showPopup, setShowPopup] = useState({
    first: false,
    second: false,
    receipt: false,
  });
  const [annualFeeData, setAnnualFeeData] = useState({
    manage_number: "",
    annual_pay_idx: "",
    patent_idx: "",
    trademark_idx: "",
  });
  const [curPage, setCurPage] = useState(
    window.localStorage.getItem("curPage")
  );
  window.localStorage.setItem("curPage", curPage);
  //window.localStorage.setItem("showPatent", showPatent);

  const [last, setLast] = useState(1);
  const [tabState, setTabState] = useState(false);
  // tab 설정 state
  // text: checkbox 이름, key: api 통신할 때의 key 값, checked: true인 값이 table_head에 추가됨(초기 값이 기본 체크 값이 됨), defaultChecked: 초기화를 위해 기본 체크값이 어떤 거였는지 알기 위해 정의한 값,
  // 초기 checked 1인 값이 테이블 기본 head 값이 됨.
  const [btns_state_tab, setBtnsStateTab] = useState([]);
  const [table_head, setTableHead] = useState([]); // 테이블 헤더값
  const [data, setData] = useState({});
  const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
  const [isShowCompanyIdxList, setIsShowCompanyIdxList] = useState([]);
  const [isShowing, setIsShowing] = useState(false);

  const AnnualPayReceipt = ({ item }) => {
    return item.billing_idx !== null ? (
      <div className="annual_receipt">
        <p>비용청구</p>
        <button
          onClick={() => {
            setAnnualFeeData({
              annual_pay_idx: item.annual_pay_idx,
              ...(showPatent
                ? { patent_idx: item.idx }
                : { trademark_idx: item.idx }),
            });
            setShowPopup({ ...showPopup, receipt: true });
          }}
        >
          영수증확인하기
        </button>
      </div>
    ) : (
      <p>-</p>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    {
      showPatent ? getPatentList() : getTradeMarkList();
    }
  }, [
    idx,
    curPage,
    showPatent,
    selectNation,
    pastView,
    selectJoint,
    isShowing,
  ]);

  useEffect(() => {
    initData(data.items);
  }, [data, btns_state_tab]);

  const getPatentList = () => {
    if (!idx) return;

    CommonAPI.getAnnualPayUsableList().then((res) => {
      setIsShowCompanyIdxList(res.data.companyList);
      setIsShowing(
        res.data.companyList.findIndex(
          (item) =>
            item.company_idx === window.localStorage.getItem("company_idx")
        ) !== -1
      );
    });

    if (window.localStorage.getItem("annual_patent_tab_init")) {
      window.localStorage.setItem("annual_patent_total_number", "1");
      window.localStorage.setItem("annual_patent_manage_number", "1");
      window.localStorage.setItem("annual_patent_family", "1");
      window.localStorage.setItem("annual_patent_nation", "1");
      window.localStorage.setItem("annual_patent_apply_number", "1");
      window.localStorage.setItem("annual_patent_apply_at", "1");
      window.localStorage.setItem("annual_patent_register_number", "1");
      window.localStorage.setItem("annual_patent_register_at", "1");
      window.localStorage.setItem("annual_patent_annual_payment_deadline", "1");
      window.localStorage.setItem("annual_patent_cost", "1");
      window.localStorage.setItem("annual_patent_signal", "1");
      window.localStorage.setItem("annual_patent_review", "1");
      window.localStorage.setItem("annual_patent_payment", "1");
      window.localStorage.setItem("annual_patent_receipt", "1");
      window.localStorage.setItem("annual_patent_joint_applicant", "1");
    } else {
      setSelectedBasics(false);
    }

    let tab = [
      {
        text: "건수",
        key: "total_number",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_total_number") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_total_number")),
        defaultChecked: 1,
      },
      {
        text: "관리번호",
        key: "manage_number",
        type: 0,
        checked:
          window.localStorage.getItem("annual_patent_manage_number") === null
            ? 1
            : Number(
                window.localStorage.getItem("annual_patent_manage_number")
              ),
        defaultChecked: 1,
      },
      {
        text: "Family",
        key: "family",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_family") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_family")),
        defaultChecked: 1,
      },
      {
        text: "국가",
        key: "nation",
        type: 1,
        checked:
          window.localStorage.getItem("annual_patent_nation") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_nation")),
        defaultChecked: 1,
      },
      {
        text: "출원번호",
        key: "apply_number",
        type: 17,
        checked:
          window.localStorage.getItem("annual_patent_apply_number") === null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_apply_number")),
        defaultChecked: 1,
      },
      {
        text: "출원일",
        key: "apply_at",
        type: 5,
        checked:
          window.localStorage.getItem("annual_patent_apply_at") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_apply_at")),
        defaultChecked: 1,
      },
      {
        text: "등록번호",
        key: "register_number",
        type: 6,
        checked:
          window.localStorage.getItem("annual_patent_register_number") == null
            ? 1
            : Number(
                window.localStorage.getItem("annual_patent_register_number")
              ),
        defaultChecked: 1,
      },
      {
        text: "등록일",
        key: "register_at",
        type: 7,
        checked:
          window.localStorage.getItem("annual_patent_register_at") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_register_at")),
        defaultChecked: 1,
      },
      {
        text: "납부기한",
        key: "annual_payment_deadline",
        type: 8,
        checked:
          window.localStorage.getItem(
            "annual_patent_annual_payment_deadline"
          ) == null
            ? 1
            : Number(
                window.localStorage.getItem(
                  "annual_patent_annual_payment_deadline"
                )
              ),
        defaultChecked: 1,
      },
      {
        text: "금액",
        key: "cost",
        type: 9,
        checked:
          window.localStorage.getItem("annual_patent_cost") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_cost")),
        defaultChecked: 1,
      },
      {
        text: "공동출원",
        key: "joint_applicant",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_joint_applicant") == null
            ? 1
            : Number(
                window.localStorage.getItem("annual_patent_joint_applicant")
              ),
        defaultChecked: 1,
      },
      {
        text: "납부추천",
        key: "signal",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_signal") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_signal")),
        defaultChecked: 1,
      },
    ];

    if (isShowing) {
      tab.push(
        {
          text: "검토",
          key: "review",
          type: null,
          checked:
            window.localStorage.getItem("annual_patent_review") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_review")),
          defaultChecked: 1,
        },
        {
          text: "연차료 포기/납부",
          key: "payment",
          type: null,
          checked:
            window.localStorage.getItem("annual_patent_payment") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_payment")),
          defaultChecked: 1,
        },
        {
          text: "비용청구",
          key: "receipt",
          type: 11,
          checked:
            window.localStorage.getItem("annual_patent_receipt") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_receipt")),
          defaultChecked: 1,
        }
      );
    }
    setBtnsStateTab(tab);
    const no_data = window.$Global.notTd(14, "등록되지 않은 항목 입니다.");

    const config = {
      ...payload,
      count: 15,
      page: curPage,
      nation: selectNation.patent_nation,
      past: Number(pastView.patent),
      joint_applicant_idx: selectJoint,
    };

    AnnualPay.getPatentList(config).then((res) => {
      const timeCheck = time - res.data.started_at;

      setTotalCount({ ...totalCount, patent_count: res.data.total_count });
      setLast(res.data.last);
      patentNationArr.length === 0 && setPatentNationArr(res.data.nation_range);
      if (res.data === false || (res.data.done === 0 && timeCheck > 600000)) {
        setPatentTag(no_data);
        setTimeout(() => {
          AnnualPay.updatePatentList({ year: year }).then((res) => {
            console.log(res.data);
          });
          window.location.reload();
        }, 3000);
      } else {
        setIsLoading(false);
        setData(res.data);
      }
    });

    AnnualPay.getJointApplicantList().then((res) => {
      setPatentJointArr(res.data.joint_applicant_list);
    });
  };

  const getTradeMarkList = () => {
    if (!idx) return;

    const no_data = window.$Global.notTd(14, "등록되지 않은 항목 입니다.");

    const config = {
      ...payload,
      count: 15,
      page: curPage,
      nation: "",
      past: Number(pastView.trademark),
    };

    AnnualPay.getTradeMarkList(config).then((res) => {
      console.log(res.data);
      const timeCheck = time - res.data.started_at;
      const tradeMarkData = res.data.items;

      setTotalCount({ ...totalCount, tradeMark_count: res.data.total_count });
      setLast(res.data.last);
      tradeMarkNationArr.length === 0 &&
        setTradeMarkNationArr(res.data.nation_range);

      if (res.data === false || (res.data.done === 0 && timeCheck > 600000)) {
        setTradeMarkTag(no_data);
        setTimeout(() => {
          AnnualPay.updateTradeMarkList({ year: year }).then((res) => {
            console.log(res.data);
          });
          window.location.reload();
        }, 3000);
      } else {
        setIsLoading(false);
        if (tradeMarkData.length) {
          const patentArr = tradeMarkData.map((item, idx) => {
            let nation = window.$Global.convertNationCodeToStr(item.nation);
            let color =
              item.signal == 2
                ? "red"
                : item.signal == 1
                ? "yellow"
                : item.signal == 0
                ? "green"
                : "gray";
            const redirectFn = () => {
              return redirect(
                item.idx,
                nation,
                item.manage_number,
                item.register_number || item.regist_number,
                item.first_renewal_expiration_deadline,
                "tradeMark"
              );
            };
            return (
              <>
                <tr key={idx}>
                  <td onClick={redirectFn}>{(curPage - 1) * 15 + idx + 1}</td>
                  <td onClick={redirectFn}>{item.manage_number || ""}</td>
                  <td onClick={redirectFn}>{nation || ""}</td>
                  <td onClick={redirectFn}>{item.apply_number || ""}</td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.apply_at) || ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.register_number || "미등록"}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.register_at) === "N/A"
                      ? "미등록"
                      : window.$Global.convertDate(item.register_at)}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.annual_payment_deadline) ||
                      ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.cost !== null
                      ? window.$Global.numberWithCommas(item.cost) + "원"
                      : ""}
                  </td>
                  {isShowing && (
                    <>
                      <td>
                        <button
                          className="button_review"
                          onClick={() => {
                            setShowPopup({
                              first: true,
                              second: false,
                            });
                            setAnnualFeeData({
                              manage_number: item.manage_number,
                              trademark_idx: item.idx,
                            });
                          }}
                        >
                          검토하기
                        </button>
                      </td>
                      <td className="annual_payment">
                        <PaymentState
                          state={item.payment_state}
                          idx={item.idx}
                          annual_pay_idx={item.annual_pay_idx}
                          showPatent={showPatent}
                        />
                      </td>
                      <td>
                        <AnnualPayReceipt item={item} />
                      </td>
                    </>
                  )}
                </tr>
              </>
            );
          });
          setTradeMarkTag(patentArr);
        } else {
          setTradeMarkTag(no_data);
        }
      }
    });
  };

  // manage_number (관리번호) register_number (등록번호) deadline_at (만료일) type (특허, 상표)
  const redirect = (
    idx,
    nation,
    manage_number,
    register_number,
    deadline_at,
    apply_number,
    company_name,
    type
  ) => {
    let local_deadline_at = "";

    if (deadline_at) {
      local_deadline_at = new Date(deadline_at).getFullYear() + 20;
    } else {
      local_deadline_at = new Date().getFullYear() + 1000;
    }
    history.push(
      `/annual/manage/${idx}?nation=${
        nation || ""
      }&nation_string=${window.$Global.convertNationCodeToStr(
        nation
      )}&manage_number=${manage_number || ""}&register_number=${
        register_number || ""
      }&apply_number=${apply_number || ""}&companyName=${
        company_name || ""
      }&deadline_at=${local_deadline_at}&type=${type}`
    );
  };

  const onChangeNation = (e) => {
    setCurPage(1);
    if (e.target.name === "patent_nation") {
      if (e.target.value === "all") {
        setSelectNation({ ...selectNation, patent_nation: "" });
      } else {
        setSelectNation({ ...selectNation, patent_nation: e.target.value });
      }
    } else if (e.target.name === "trademark_nation") {
      if (e.target.value === "all") {
        setSelectNation({ ...selectNation, patent_nation: "" });
      } else {
        setSelectNation({ ...selectNation, patent_nation: e.target.value });
      }
    }
  };

  const onChangeJoint = (e) => {
    setCurPage(1);
    if (e.target.value === "all") {
      setSelectJoint("");
    } else {
      setSelectJoint(e.target.value);
    }
  };

  const saveTab = () => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);

    // 기본 탭 설정일때
    if (selectedBasics) {
      window.localStorage.setItem("annual_patent_tab_init", "1");
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
    } else {
      window.localStorage.removeItem("annual_patent_tab_init");
      copy_arr_tab.forEach((item) => {
        window.localStorage.setItem("annual_patent_" + item.key, item.checked);
      });
    }
    setTabState(false);
  };

  const onClickToggleSelectedBasics = () => {
    if (!selectedBasics) {
      let copy_tab = window.$Global.cloneObject(btns_state_tab);
      let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
      setBtnsStateTab(copy_arr_tab);
    }
    setSelectedBasics(!selectedBasics);
  };

  const onChangeTabChecked = (input, text) => {
    let copy_btns_state_tab = btns_state_tab.concat([]);
    var item = copy_btns_state_tab.find((item) => item.text === text);
    item.checked = Number(input.target.checked);
    setBtnsStateTab(copy_btns_state_tab);

    console.log(btns_state_tab);
    if (item.checked) {
      table_head_tag.push(<th>{item.text}</th>);
      setTableHead(table_head.concat([item.text]));
    } else {
      let findItem = table_head.find((item) => {
        return item === item.text;
      });
      let idx = table_head.indexOf(findItem);
      setTableHead(table_head.splice(idx, 1));
    }
  };

  btns_state_tab.forEach((item, i) => {
    if (item.checked) {
      if (item.key === "nation") {
        table_head_tag.push(
          <th>
            <select
              name="trademark_nation"
              className="nation_select"
              onChange={(e) => onChangeNation(e)}
            >
              <option value="all" defaultValue>
                국 가
              </option>
              {patentNationArr?.map((el) => {
                return (
                  <option value={el.nation}>
                    {window.$Global.convertNationCodeToStr(el.nation)}
                  </option>
                );
              })}
            </select>
          </th>
        );
      } else if (item.key === "joint_applicant") {
        table_head_tag.push(
          <th>
            <select
              name="trademark_nation"
              className="nation_select"
              onChange={(e) => onChangeJoint(e)}
            >
              <option value="all" defaultValue>
                공동출원
              </option>
              {patentJointArr?.map((el) => {
                return <option value={el.idx}>{el.name}</option>;
              })}
            </select>
          </th>
        );
      } else table_head_tag.push(<th key={i}>{item.text}</th>);
    }
    tab_tags.push(
      <CheckBox
        key={i}
        text={item.text}
        checked={item.checked}
        onChangeChecked={onChangeTabChecked}
        disabled={selectedBasics}
      />
    );
  });

  const initData = (data) => {
    if (!data) return;

    let selectedBasics_tab = btns_state_tab.filter((item) => item.checked);

    let total_number = selectedBasics_tab.filter(
      (obj) => obj.key === "total_number"
    ).length;
    let manage_number = selectedBasics_tab.filter(
      (obj) => obj.key === "manage_number"
    ).length;
    let family = selectedBasics_tab.filter(
      (obj) => obj.key === "family"
    ).length;
    let nation = selectedBasics_tab.filter(
      (obj) => obj.key === "nation"
    ).length;
    let apply_number = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_number"
    ).length;
    let apply_at = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_at"
    ).length;
    let register_number = selectedBasics_tab.filter(
      (obj) => obj.key === "register_number"
    ).length;
    let register_at = selectedBasics_tab.filter(
      (obj) => obj.key === "register_at"
    ).length;
    let annual_payment_deadline = selectedBasics_tab.filter(
      (obj) => obj.key === "annual_payment_deadline"
    ).length;
    let cost = selectedBasics_tab.filter((obj) => obj.key === "cost").length;
    let signal = selectedBasics_tab.filter(
      (obj) => obj.key === "signal"
    ).length;
    let joint_applicant = selectedBasics_tab.filter(
      (obj) => obj.key === "joint_applicant"
    ).length;
    let review, payment, receipt;
    if (isShowing) {
      review = selectedBasics_tab.filter((obj) => obj.key === "review").length;
      payment = selectedBasics_tab.filter(
        (obj) => obj.key === "payment"
      ).length;
      receipt = selectedBasics_tab.filter(
        (obj) => obj.key === "receipt"
      ).length;
    }

    if (data.length) {
      let arr_tbody = data.map((item, index) => {
        console.log(item);
        let color =
          item.signal == 2
            ? "red"
            : item.signal == 1
            ? "yellow"
            : item.signal == 0
            ? "green"
            : "gray";
        const redirectFn = () => {
          return redirect(
            item.idx,
            item.nation,
            item.manage_number,
            item.register_number || item.regist_number,
            item.first_renewal_expiration_deadline,
            item.apply_number,
            item.company_name,
            "patent"
          );
        };
        return (
          <React.Fragment key={index}>
            <tr data-idx={index} className={index === 0 ? "active" : ""}>
              {total_number ? (
                <td onClick={redirectFn}>{(curPage - 1) * 15 + index + 1}</td>
              ) : null}
              {manage_number ? (
                <td className="manage_number" onClick={redirectFn}>
                  {item.manage_number}
                </td>
              ) : null}
              {family ? (
                <td className="external_family" onClick={redirectFn}>
                  {item.family || ""}
                </td>
              ) : null}
              {nation ? (
                <td className="nation_string" onClick={redirectFn}>
                  {window.$Global.convertNationCodeToStr(item.nation) || ""}
                </td>
              ) : null}
              {apply_number ? (
                <td onClick={redirectFn}>{item.apply_number}</td>
              ) : null}
              {apply_at ? (
                <td className="apply_at" onClick={redirectFn}>
                  {window.$Global.convertDate(item.apply_at)}
                </td>
              ) : null}
              {register_number ? (
                <td className="register_number" onClick={redirectFn}>
                  {" "}
                  {item.register_number || "미등록"}
                </td>
              ) : null}
              {register_at ? (
                <td className="register_at" onClick={redirectFn}>
                  {window.$Global.convertDate(item.register_at) === "N/A"
                    ? "미등록"
                    : window.$Global.convertDate(item.register_at)}
                </td>
              ) : null}
              {annual_payment_deadline ? (
                <td className="annual_payment_deadline" onClick={redirectFn}>
                  {window.$Global.convertDate(item.annual_payment_deadline) ||
                    ""}
                </td>
              ) : null}
              {cost ? (
                <td className="cost" onClick={redirectFn}>
                  {item.cost !== null
                    ? window.$Global.numberWithCommas(item.cost) + "원"
                    : ""}
                </td>
              ) : null}
              {joint_applicant ? (
                <td className="joint_applicant" onClick={redirectFn}>
                  {item.joint_applicant_list.map((el, idx) => {
                    return (
                      <>
                        <p>{el}</p>
                        {idx !== item.joint_applicant_list.length - 1 && <br />}
                      </>
                    );
                  })}
                </td>
              ) : null}
              {signal ? (
                <td className="signal" onClick={redirectFn}>
                  <div className={`circle ${color}`} />
                </td>
              ) : null}
              {isShowing && review ? (
                <td className="review">
                  <button
                    className="button_review"
                    onClick={() => {
                      setShowPopup({
                        first: true,
                        second: false,
                      });
                      setAnnualFeeData({
                        manage_number: item.manage_number,
                        patent_idx: item.idx,
                      });
                    }}
                  >
                    검토하기
                  </button>
                </td>
              ) : null}
              {isShowing && payment ? (
                <td className="payment">
                  <PaymentState
                    state={item.payment_state}
                    companyList={isShowCompanyIdxList}
                    idx={item.idx}
                    annual_pay_idx={item.annual_pay_idx}
                    showPatent={showPatent}
                  />
                </td>
              ) : null}
              {isShowing && receipt ? (
                <td className="receipt">
                  <AnnualPayReceipt item={item} />
                </td>
              ) : null}
            </tr>
          </React.Fragment>
        );
      });
      setPatentTag(arr_tbody);
    } else {
      setPatentTag([window.$Global.notTd(20, "데이터가 없습니다")]);
    }
  };

  return (
    <div id="ExpectaionList">
      <div className="wrap">
        <h2 className="title">
          “{localStorage.getItem("company_name")}”의 {year}년 연차료
          지급리스트
        </h2>
        <div className="btns">
          {tabState ? (
            <>
              <ToggleButtonWithSpan
                text="Tab 기본 설정하기 "
                condition={{ on: "ON", off: "OFF" }}
                selected={selectedBasics}
                toggleSelected={onClickToggleSelectedBasics}
              />
              <button className="btn_tab_setting_save" onClick={saveTab}>
                Tab 설정 저장
              </button>
            </>
          ) : (
            <button
              className="btn_tab_setting"
              onClick={() => setTabState(true)}
            >
              Tab 설정하기
            </button>
          )}
          <button
            className="btn_back"
            onClick={() => history.push("/annual/graph")}
          >
            목록으로 돌아가기
          </button>
        </div>
      </div>
      <div className="wrap_tab_btns">{tabState && tab_tags}</div>
      <div className="annual_change_view">
        <p
          className="table_title"
          style={{ borderBottom: showPatent && "3px solid #4593f5" }}
          onClick={() => setShowPatent(true)}
        >
          특&nbsp; 허
        </p>
        <p
          className="table_title"
          style={{ borderBottom: !showPatent && "3px solid #4593f5" }}
          onClick={() => setShowPatent(false)}
        >
          상&nbsp; 표
        </p>
      </div>
      {showPatent ? (
        <div className="table-box-wrap">
          <div className="wrap_list custom_scroll">
            <table className="list">
              <thead>
                <tr className="no_focus_outline">{table_head_tag}</tr>
              </thead>
              <tbody>{patentTag}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="table-box-wrap">
          <div className="wrap_list custom_scroll">
            <table className="list">
              <thead>
                <tr>
                  <th>건수</th>
                  <th>관리번호</th>
                  <th>
                    <select
                      name="trademark_nation"
                      className="nation_select"
                      onChange={(e) => onChangeNation(e)}
                    >
                      <option value="all" defaultValue>
                        국 가
                      </option>
                      {tradeMarkNationArr?.map((el) => {
                        return (
                          <option value={el.nation}>
                            {window.$Global.convertNationCodeToStr(el.nation)}
                          </option>
                        );
                      })}
                    </select>
                  </th>
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      납부기한
                      <i
                        className={
                          pastView.trademark
                            ? "icon_arrow_up_gray"
                            : "icon_arrow_down_gray"
                        }
                        onClick={() => {
                          setPastView({
                            ...pastView,
                            trademark: !pastView.trademark,
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th>금액</th>
                  {isShowing && (
                    <>
                      <th>
                        검토
                        <i className="icon_badge_info_gray" />
                      </th>
                      <th>연차료 포기/납부</th>
                      <th>
                        비용청구
                        <i className="icon_badge_info_gray" />
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>{tradeMarkTag}</tbody>
            </table>
          </div>
        </div>
      )}
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      {showPopup.first && (
        <AnnualFeeReview
          annualFeeData={annualFeeData}
          setShowPopup={setShowPopup}
          showPatent={showPatent}
        />
      )}
      {showPopup.second && (
        <AnnualFeeReviewSecond
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
        />
      )}
      {showPopup.receipt && (
        <AnnualFeeReceipt
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
        />
      )}
      {isLoading && <Loading show={isLoading} />}
    </div>
  );
}

export default AnnualFeeList;
