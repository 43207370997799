import icon from "../../assets/images/businessPlan/button-bringing-up.svg";
import "./css/Icon.scss";

const BringingUpIcon = () => {
  return (
    <div id="BringingUpIcon" onClick={() => alert("준비중인 기능입니다.")}>
      <img src={icon} alt="bringingUpIcon" />
    </div>
  );
};

export default BringingUpIcon;
