import React, { useState } from "react";
import "./css/ResearchTechMySideBar.scss";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

function ResearchTechMySideBar() {
  const history = useHistory();
  const location = useLocation();
  console.log(location);
  return (
    <div id="ResearchTechMy" className="researchTechMy">
      <div className="researchTechMy_menu">
        <h2 className="category_title" style={{ fontSize: "16px" }}>
          <i className="icon_category" />
          나의 선행 기술 관리하기
        </h2>
        <ul>
          <li
            className={location.pathname.includes("list") && "active"}
            onClick={() => history.push("/researchTech/my/list")}
          >
            나의 선행기술 조사 리스트
          </li>
          <li
            className={location.pathname.includes("request") && "active"}
            onClick={() => history.push("/researchTech/my/request")}
          >
            선행기술 조사 요청
          </li>
          <li
            className={location.pathname.includes("keyword") && "active"}
            onClick={() => history.push("/researchTech/my/keyword")}
          >
            키워드 검색 내역 확인하기
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ResearchTechMySideBar;
