import React from "react";

function InputCustom({ able, title, objKey, signInfo, setSignInfo, required }) {
  return (
    <div className={`field ${able ? "" : "action_no"}`}>
      <div className="f_head">
        {required && <i className="icon_red_dot" />}
        {title}
      </div>
      <div className="f_content">
        <input
          type="text"
          placeholder={`${title}을(를) 입력해주세요`}
          value={signInfo[objKey]}
          onChange={(e) =>
            setSignInfo({ ...signInfo, [objKey]: e.target.value })
          }
        />
      </div>
    </div>
  );
}

export default InputCustom;
