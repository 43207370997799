import React from "react";
import "./css/InnerAlertPopup.scss";

function InnerAlertPopup({show, onClickClose}) {

    if (!show) return null;

    return (
        <div id="InnerAlertPopup">
            <div className="header">
                <h2>내부기한이란?</h2>
                <button className="icon_exit" onClick={onClickClose}/>
            </div>
            <p>발명자와 특허사무소에게 기한을 주는 기능입니다.</p>
            <button className="btn_success" onClick={onClickClose}>확인</button>
        </div>
    );
}

export default InnerAlertPopup;
