import React, {useEffect, useState} from "react";
import "../css/AdminIntegrationTaskList.scss";
import AdminAPI from "../../../API/admin";
import {useHistory} from "react-router-dom";

function AdminIntegrationTaskList() {
	const history = useHistory();
	const [data, setData] = useState([]);

	useEffect(() => {
		AdminAPI.getTaskPDFList().then(res => {
			setData(res.data.items.sort((a, b) => a.order - b.order));
		})
	}, []);

	const onClickDelete = (idx) => {
		if (window.confirm("삭제하시겠습니까?")) {
			AdminAPI.deleteTaskPDF(idx).then(() => {
				history.go(0);
			})
		}
	}

	const onChangeOrder = (item, order) => {
		let payload = {
			...item,
			order: order
		}
		AdminAPI.updateTaskPDF(item.idx, payload).then(() => {
			history.go(0);
		})
	}

	return (
		<div id="AdminIntegrationTaskList">
			<div className="head">
				<h2 className="title">통합 공고 목록</h2>
			</div>

			<table>
				<colgroup>
					<col width="10%"/>
					<col width="35%"/>
					<col width="35%"/>
					<col width="5%"/>
					<col width="15%"/>
				</colgroup>
				<thead>
					<tr>
						<th>순서</th>
						<th>제목</th>
						<th>파일명</th>
						<th>삭제</th>
						<th>순서변경</th>
					</tr>
				</thead>
				<tbody>
				{
					data.map(item => {
						return (
							<tr key={item.idx}>
								<td>{item.order}</td>
								<td>{item.title}</td>
								<td>{item.file_name}</td>
								<th><button onClick={() => onClickDelete(item.idx)}>삭제</button></th>
								<th>
									<div className="btns_order">
										{
											[1,2,3,4].map((item2,  idx) => {
												return <button className="btn_order" onClick={() => onChangeOrder(item, item2)}>{item2}</button>
											})
										}
									</div>
								</th>
							</tr>
						)
					})
				}
				</tbody>
			</table>
		</div>
	)
}

export default AdminIntegrationTaskList;