import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const applyNumberArray = atom({
  key: "applyNumberArray",
  default: [],
});

export const researchTechHistory = atom({
  key: "researchTechHistory",
  default: {},
});
export const keywordIdx = atom({
  key: "keywordIdx",
  default: "",
});

export const categoryData = atom({
  key: "categoryData",
  default: {},
});

export const marketInfoCartArrayLength = atom({
  key: "cartArrLength",
  default: 0,
});

export const businessPlanDefaultData = atom({
  key: "defaultData",
  default: {},
});

export const highlightText = atom({
  key: "highlightText",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const allFavoriteArray = atom({
  key: "allFavoriteArray",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const myFavoriteArray = atom({
  key: "myFavoriteArray",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const groupwareAdmin = atom({
  key: "groupwareAdmin",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

// MenuTab에 메일, 전자결재 New 표시를 위한 전역변수
export const tabNotice = atom({
  key: "tabNotice",
  default: {
    mail: 0,
    approval: 0,
  },
  effects_UNSTABLE: [persistAtom],
});

export const activeTab = atom({
  key: "activeTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
