import React from "react";

function TwoInRow({title1, value1, title2, value2}) {
    return (
        <div className="row">
            <div className="divide">
                <h2 className="title">{title1}</h2>
                <p className="value">{value1 || 'N/A'}</p>
            </div>
            <div className="divide">
                <h2 className="title">{title2}</h2>
                <p className="value">{value2 || 'N/A'}</p>
            </div>
        </div>
    );
}

export default TwoInRow;
