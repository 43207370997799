import React, {useContext} from "react"
import _ from "lodash";
import ToggleButtonWithSpan from "../../../common/ToggleButtonWithSpan";
import PatentContext from "../../../../store/Patent";

function ProcessManage({index, title, selected, isNew}) {
    const patentInfo = useContext(PatentContext);
    let copy = _.cloneDeep(patentInfo);

    const toggleSelected = async () => {
        if (title == "출원 상세 절차 관리") {
            copy.apply_detail = Number(!copy.apply_detail);
            if (!isNew) {
                await patentInfo.func.saveProcess(copy.apply_detail, undefined, patentInfo);
            } else {
                isNew("apply_detail", !selected);
            }
        } else if (title == "중간 사건 상세 절차 관리") {
            copy.ListOa[index].oa_detail = Number(!copy.ListOa[index].oa_detail);
            if (!isNew) {
                await patentInfo.func.saveProcess(undefined, copy.ListOa[index], patentInfo);
            } else {
                isNew(index, !selected);
            }
        }

        if (!isNew) {
            patentInfo.setPatent(copy);
        }
    };

    return (
        <div className="p_menu">
            <i className="icon_message"/>
            <p>{`${title} ${selected ? "ON" : "OFF"}`}</p>
            <ToggleButtonWithSpan text="" condition={{on: "", off: ""}} selected={selected} toggleSelected={toggleSelected}/>
        </div>
    );
}

export default ProcessManage;
