import React, {useEffect, useState} from "react";
import ContentTableList from "../common/ContentTableList";
import {useParams} from "react-router-dom";
import InfoAPI from "../../API/info";
import CommonAPI from "../../API/common";
import TutorialAPI from "../../API/tutorial";
import "./css/AdminContentList.scss";

function AdminContentList({history}) {
    let pageTitle = "";
    const {type} = useParams();
    const isNews = type == "news";
    const isYoutube = type == "youtube";
    const isNotify = type == "notify";
    const isQna = type == "qna";
    const isEdu = type == "edu";
    const isVideo = type == "video";
    const isDoc = type == "document";
    const isLove = type == "love";

    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [content, setContent] = useState([]);
    const [data, setData] = useState({items: []});

    useEffect(() => {
        let payload = {
            count: count,
            page: 1
        };
        getAPI(payload);
        setCurPage(1);
    }, [type]);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    if (isNews) {
        pageTitle = "뉴스";
    } else if (isYoutube) {
        pageTitle = "Youtube";
    } else if (isNotify) {
        pageTitle = "공지사항";
    } else if (isQna) {
        pageTitle = "Q&A";
    } else if (isEdu) {
        pageTitle = "특허교육자료";
    } else if (isVideo) {
        pageTitle = '튜토리얼 동영상';
    } else if (isDoc) {
        pageTitle = '튜토리얼 PPT';
    } else if (isLove) {
        pageTitle = 'NOW 사랑방';
    }

    const getAPI = async (payload, content_type) => {
        let result = {items: []};
        if (isNews) {
            result = await InfoAPI.getNews(payload).then(res => res.data);
        } else if (isYoutube) {
            result = await InfoAPI.getYoutube(payload).then(res => res.data);
        } else if (isNotify) {
            result = await CommonAPI.getNoticeList(payload).then(res => res.data);
        } else if (isQna) {
            result = await CommonAPI.getQnaList(payload).then(res => res.data);
        } else if (isEdu) {
            result = await CommonAPI.getEduList({...payload, type: 0}).then(res => res.data);
        } else if (isVideo || isDoc) {
            result = await TutorialAPI.getList({...payload, type: isVideo ? 0 : 1}).then(res => res.data);
        } else if (isLove) {
            payload.category = 1;
            result = await CommonAPI.getNoticeList(payload).then(res => res.data);
        }

        if (content_type == "next") {
            result.items = result.items.concat(data.items);
        }
        setData(result);
    };

    const drawTable = () => {
        setContent(data.items.map((item, index) => {
            return (
                <tr key={index} onClick={() => history.push(`/admin/posting/view/${type}/${item.idx}`)}>
                    <td>{data.total_count - index}</td>
                    <td>
                        <div className="content">
                            <img src={window.$Global.getCDN(item.thumbnail_key)} alt="이미지"/>
                            <div>
                                <h2>{item.title || item.question}</h2>
                                <p>{window.$Global.decode(window.$Global.removeHTML(item.content || item.answer || ""))}</p>
                            </div>
                        </div>
                    </td>
                    <td>{window.$Global.convertDate(item.created_at)}</td>
                </tr>
            );
        }));
        if (data.page == data.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    const nextList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getAPI(payload, "next");
    };

    return (
        <div id="AdminContentList">
            <div className="btns">
                {
                    (type == "news" || type == "youtube") &&
                    <button className="btn_toggle" onClick={() => history.push(`/admin/list/${type == "news" ? "youtube" : "news"}`)}>{type == "news" ? "youtube" : "News"} 보기</button>
                }
                <button className="btn_write" onClick={() => history.push(`/admin/posting/post/${type}/new`)}>글 작성하기</button>
            </div>
            <ContentTableList title={pageTitle} data={data.items || []} content={content} drawList={nextList} hasMore={hasMore}/>
        </div>
    )
}

export default AdminContentList;
