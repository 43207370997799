import "./css/SearchCrawledData.scss";
import { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import SunEditor from "suneditor-react";

const SearchCrawledData = ({ saveData, setData, data, isOpen, keyword }) => {
  const [searchKeyword, setSearchKeyword] = useState(keyword || "");
  const [dataList, setDataList] = useState();
  const [editData, setEditData] = useState("");

  useEffect(() => {
    if (keyword) {
      getCrawledDataList();
    }
  }, []);

  const getCrawledDataList = () => {
    let payload = {
      count: 9999,
      keyword: searchKeyword,
    };
    CommonAPI.getCrawledData(payload).then((res) => {
      //console.log(res.data);
      setDataList(res.data.items);
    });
  };

  const addData = (item) => {
    let { title, content } = item;
    title = "<b>" + title + "</b><br/>";
    content = content + "<br/>";
    setEditData((prev) => prev + title + content);
  };

  //console.log(saveData);
  return (
    <div id="SearchCrawledData">
      <header>
        <div className="header_text">
          <h2>크롤링 데이터 검색하기</h2>
          <i
            className="icon_exit_gray"
            onClick={() => {
              saveData.isOpen = false;
              setData({ ...data });
            }}
          />
        </div>
        <div className="search_wrapper">
          <div className="search_input">
            <input
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") getCrawledDataList();
              }}
            />
            {searchKeyword.length > 0 ? (
              <i
                className="icon_del_small_with_bg_gray"
                onClick={() => setSearchKeyword("")}
              />
            ) : (
              <i
                className="icon_search_gray"
                onClick={() => getCrawledDataList()}
              />
            )}
          </div>
        </div>
      </header>
      <section>
        <article>
          <h5>키워드 검색결과</h5>
          <div className="list_items custom_scroll">
            {dataList?.map((item) => {
              return (
                <div className="list_item" onClick={() => addData(item)}>
                  <p className="list_item_title">{item.title}</p>
                  <p className="list_item_content">{item.content}</p>
                  <a
                    href={item.url}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.url}
                  </a>
                </div>
              );
            })}
          </div>
        </article>
        <div className="arrow_icon_wrapper">
          <i className="icon_more_arrow_right" />
        </div>
        <article>
          <h5>수정하기</h5>
          <SunEditor
            setOptions={{ width: 480, height: 600 }}
            setContents={editData}
            onChange={(e) => setEditData(e)}
          />
        </article>
      </section>
      <footer>
        <button
          onClick={() => {
            saveData.value = editData;
            saveData.isOpen = false;
            setData({ ...data });
          }}
        >
          수정한 데이터 저장하기
        </button>
      </footer>
    </div>
  );
};

export default SearchCrawledData;
